<h2>System Administration Panel</h2>
<p style="color: red">(Stakeholders please leave all functions on this page alone. Later this page will be restricted to System Administrators
   only.)
</p>
<form style="background-color: white; color:black">
   <div class="form-group">
      <label>Api server status</label>
      <table class="table table-bordered" style="width:20%">
         <tr>
            <th>Status</th>
            <td>{{serverStatusReport?.status}}</td>
         <tr>
         <tr>
            <th>Mode</th>
            <td>{{serverStatusReport?.mode}}</td>
         <tr>
      </table>
   </div>

   <div class="form-group">
      <label>Database Status</label>
      <table class="table table-bordered" style="width:20%">
         <tr>
            <th>Table Name</th>
            <th>Data Rows</th>
         </tr>
         <tr *ngFor="let item of dbStatusReport" [ngClass]="{danger: item.rowCount === null}">
            <td>{{item.table}}</td>
            <td [style.font-style]="item.rowCount === null ? 'italic' : 'normal'">
               {{item.rowCount === null ? 'missing' : item.rowCount}}
            </td>
         </tr>
      </table>
   </div>

   <div class="form-group">
      <label>Creates all required database tables.</label><br/>
      <input type="button" class="btn btn-primary" value="Init DB" (click)="onInitDb()" [disabled]="uiDisabled" />
   </div>

   <div class="form-group">
      <label>Populates database tables with default values.</label><br/>
      <input type="button" class="btn btn-primary" value="Populate DB" (click)="onPopulateDb()" [disabled]="uiDisabled" />
   </div>

   <div class="form-group">
      <label>Deletes all database tables. (Are you really sure you want to do this?)</label><br/>
      <input type="button" class="btn btn-primary" value="Reset DB" (click)="onResetDb()" [disabled]="uiDisabled" />
   </div>

   <div class="form-group">
      <label>Generates a 404 error to test the error handling.</label><br/>
      <input type="button" class="btn btn-primary" value="Test 404" (click)="onTest404Error()" />
   </div>

   <div class="form-group">
      <label>Generates a server exception to test the error handling.</label><br/>
      <input type="button" class="btn btn-primary" value="Test Exception" (click)="onTestException()" />
   </div>

   <div class="form-group">
      <label>Sends a command to the back end telling it to generate multiple schedules.</label><br/>
      <input type="button" class="btn btn-primary" value="Generate multiple schedules" (click)="onTestMultipleSchedules()" />
   </div>

   <div class="form-group">
      <label>Turn off Google Map Marker optimisation for Leap Test tool</label><br/>
      <callsmart-inputswitch name="toggle1" [(ngModel)]="optimiseMapMarkers" onLabel="No" offLabel="Yes">
      </callsmart-inputswitch>
   </div>

   <div class="form-group">
         <label>Set the Max number of schedules to be cached for the session:</label><br/>
         <input type="text"  [value]="cachedSchedules" (input)="cachedSchedules = $event.target.value">
         <input type="button" class="btn btn-primary" value="Change" (click)="onChangeMaxCachedSchedules()" />
   </div>
   <div class="form-group">
         <label>Load all callers for selected project</label><br/>
         <input type="button" class="btn btn-primary" value="Load callers" (click)="onLoadAllCallers()" />
   </div>


   <br>
   <br>

</form>


<!-- Old dev code below this point. -->

<!--<div class="form-group">
   <input type="button" class="btn btn-primary" value="Test" (click)="testServerConnection()" />
   <div *ngIf="testMessage">{{testMessage}}</div>
</div>

<div class="form-group">
   <input type="button" class="btn btn-primary" value="Get Status Report" (click)="onGetStatusReport()" />
</div>-->

<!--<div class="form-group table-fit">
   <p-dataTable [value]="statusReport">
      <p-column field="table" header="Table Name"></p-column>
      <p-column field="rowCount" header="Row Count"></p-column>
   </p-dataTable>
</div>-->

<!--<div class="form-group" style="background-color: red"></div>-->

<!--<table style="table-layout: auto">-->
<!--
<table class="table">
   <tr>
      <th>Firstname</th>
      <th>Lastname</th>
      <th>Age</th>
   </tr>
   <tr>
      <td>Jill</td>
      <td>Smith</td>
      <td>50</td>
   </tr>
   <tr>
      <td>Eve</td>
      <td>Jackson</td>
      <td>94</td>
   </tr>
</table>
-->
<!--<div>DbAdminModel.Data: {{_dbAdminModel.DbTables}}</div>-->
