import { Injectable } from '@angular/core';
import {JsonObject, JsonProperty} from 'json2typescript';



/**
 * Model class representing a data sent using the heartbeat endpoint.
 */
@Injectable()
@JsonObject
export class HeartBeatData {

   @JsonProperty('projectId')
   public projectId: number = undefined;

   @JsonProperty('clearProjectHeartBeat')
   public clearProjectHeartBeat: boolean = undefined;


}
