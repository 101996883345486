import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

//Wait Spinner store is resonsiple for showing the a waiting box styled up and created on the app.compnent,html

@Injectable()
export class SpinnerStore {

   // Alerts.
   private _spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public spinner$: Observable<boolean> = this._spinner.asObservable();

   private _visibility: boolean = false;
   public get Visibility(): boolean {
      return this._visibility;
   }

   constructor() {
   }

   // Sends a alert to be displayed.
   public showSpinner() {
      this._visibility = true;
      this._spinner.next(this._visibility);
   }


   // Sends a alert to be displayed.
   public hideSpinner() {
      this._visibility = false;
      this._spinner.next(this._visibility);
   }
}
