import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { BrowserWindowService } from "app/services/browser-window.service";
import { ApplicationStore } from 'app/stores/application-store';
import { User } from 'app/models/user';

@Component({
   selector: 'callsmart-admin-workspace',
   templateUrl: './admin-workspace.component.html'
})
export class AdminWorkspaceComponent implements OnInit {

   public menu: MenuItem[];
   public menuHeight: number;
   public user: User;

   constructor(private windowService: BrowserWindowService,
      private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.menuHeight = value - 198;
      });
   }

   ngOnInit() {

      // only load the admin data as you need it
      this._applicationStore.sysAdminStore.loadCompanies();
      this._applicationStore.sysAdminStore.loadUsers();

      // only load the travel models if CACI admin
      if (this._applicationStore.authenticationStore.loggedInUser.isAdmin) {
         this._applicationStore.sysAdminStore.loadTravelModels();
      }

      this.user = this._applicationStore.authenticationStore.loggedInUser;

      this.menu = [{
         items: [
            { label: 'Travel models', routerLink: ['/admin/travelmodels'], visible: this.user.isAdmin },
            { label: 'Projects', routerLink: ['/admin/projects'], visible: this.user.isAdmin },
            { label: 'Companies', routerLink: ['/admin/companies'], visible: this.user.isAdmin },
            { label: 'Users', routerLink: ['/admin/users'] },
            { label: 'Audit reports', routerLink: ['/admin/audit'], visible: this.user.isAdmin  },
            { label: 'Maintenance', routerLink: ['/admin/maintenance'], visible: this.user.isAdmin  },
         ]
      }];
   }
}
