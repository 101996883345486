<div>

   <callsmart-growl [(value)]="msgs" [life]="lifetime" [sticky]="sticky"></callsmart-growl>

   <callsmart-navbar></callsmart-navbar>

   <router-outlet></router-outlet>

</div>

<p-confirmDialog header="Confirmation" icon="" [width]="confirmDialogWidth" #cd>
   <p-footer style="padding:10px">
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="cd.reject()" style="min-width: 100px;">Cancel</button>
      <button type="button" class="btn cs-btn-primary" (click)="cd.accept()" style="min-width: 100px;margin-left:5px;">Ok</button>
   </p-footer>
</p-confirmDialog>


<!-- Styled up dialog for alerts -->
<ndc-dynamic *ngIf="showAlertDialog" [ndcDynamicComponent]="alertDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showSpinnerDialog" [ndcDynamicComponent]="spinnerDialog" [ndcDynamicInputs]="spinnerDialogInput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showErrorDialog" [ndcDynamicComponent]="errorDialog" [ndcDynamicInputs]="errorDialogInput" [ndcDynamicOutputs]="errorDialogOutput">
</ndc-dynamic>
