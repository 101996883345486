import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment/moment';

import { CalendarDay } from 'app/models/calendarDay';

// This class represent the close calendar days component to
// select the open/closed day of a callpoint
@Component({
   selector: 'callsmart-close-calendar-days',
   templateUrl: './close-calendar-days.component.html'
})
export class CloseCalendarDaysComponent implements OnInit {

   @Input() calendarDates: CalendarDay[][] = [];

   // Notifies the client when the dialog was closed with save button.
   @Output() closedDatesChanged = new EventEmitter<CalendarDay[][]>();

   public momentStartCycleDate: moment.Moment;

   constructor() { }

   ngOnInit() {
      this.momentStartCycleDate = moment(this.calendarDates[0][0].date.toISOString());
   }

   // Handles the activation/deactivation of a calendar day Part 1 button
   public onPart1ButtonClick(day: CalendarDay) {
      day.isPart1Open = !day.isPart1Open
      // Notifies the change to the parent control
      this.closedDatesChanged.emit(this.calendarDates);
   }

   // Handles the activation/deactivation of a calendar day Part 1 button
   public onPart2ButtonClick(day: CalendarDay) {
      day.isPart2Open = !day.isPart2Open
      // Notifies the change to the parent control
      this.closedDatesChanged.emit(this.calendarDates);
   }
}
