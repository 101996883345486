import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateRangeSliderComponent } from "app/shared/date-range-slider/date-range-slider.component";

/**
 * Module for Date Range Slider component
 */
@NgModule({
   imports: [CommonModule],
   exports: [DateRangeSliderComponent],
   declarations: [DateRangeSliderComponent]
})
export class DateRangeSliderModule { }