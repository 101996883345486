import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { Callpoint } from 'app/models/callpoint';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

/**
 * View model class for the Callpoint settings dialog.
 * Provides presentation logic to allow the user to override the project default settings.
 * Also supports the editing of multiple callpoint settings.
 */
export class CallpointSettingsViewModel {

   ///////////////////////////////// Used only by the multiple callpoint settings dialog //////////////////////
   // Model properties for the multi edit checkboxes. The checkboxes will be unchecked everytime
   // the dialog box is displayed.
   public availabilityMultiEdit: boolean = false;
   public frequencyMultiEdit: boolean = false;
   public durationMultiEdit: boolean = false;
   public locationMultiEdit: boolean = false;
   public priorityMultiEdit: boolean = false;
   public criticalMultiEdit: boolean = false;

   ///////////////////////////////// Used only by the multiple callpoint settings dialog //////////////////////
   // Properties for determining whether each of the callpoint setting sections are the same
   // between all the selected callpoints. If they are same then display the properties, otherwise
   // display the 'Multiple values set' message.
   private _availabilitySameValuesSet: boolean = false;
   private _frequencySameValuesSet: boolean = false;
   private _durationSameValuesSet: boolean = false;
   private _locationSameValuesSet: boolean = false;
   private _prioritySameValuesSet: boolean = false;
   private _criticalSameValuesSet: boolean = false;

   // Properties to control the default/override toggle buttons in the dialog
   private _availabilityDisabled: boolean = true;
   private _allowAllDayCombinationDisabled: boolean = true;
   private _priorityDisabled: boolean = true;

   private _projectSettings: CallpointSettings;
   private _callpoint: Callpoint;

   constructor(projectSettings: CallpointSettings, callpoint: Callpoint) {
      // Create back ups of the settings so that they can be reverted if the user cancels.
      // MNF: This comment here states that _projectSettings and _callpoint are backups used to revert the settings if
      // they are cancelled. But the properties 'set availabilityDisabled', 'set allowAllDayCombinationDisabled',
      // 'set availability', 'set allowAllDayCombination', 'set frequency', 'set duration', 'set latitude' and
      // 'set longitude' all update these copies.
      // So they are not left in the state they had when the copies were made, so they cannot be used to revert the
      // settings to what they were before they were edited, so the comment above is incorrect and confusing.
      this._projectSettings = CallsmartUtils.deepClone<CallpointSettings>(projectSettings, CallpointSettings);
      this._callpoint = CallsmartUtils.deepClone<Callpoint>(callpoint, Callpoint);

      this.initialiseSettingsState();
   }

   public setAllPropertiesSame(newValue: boolean) {
      this.availabilitySameValuesSet = newValue;
      this.frequencySameValuesSet = newValue;
      this.durationSameValuesSet = newValue;
   }

   public getCallpoint(): Callpoint {
      return this._callpoint;
   }

   public getCallpointSettings(): CallpointSettings {
      return this._callpoint.callpointSettings;
   }

   ////////////////////////////////////// Multi edit properties ////////////////////////////////////////////
   // In each of the property set methods, if the parameter is false meaning that the properties are different
   // between all the callpoints then the override toggle switch has to be set to Default position
   public get availabilitySameValuesSet(): boolean {
      return this._availabilitySameValuesSet;
   }

   public set availabilitySameValuesSet(newValue: boolean) {
      this._availabilitySameValuesSet = newValue;
      if (!this._availabilitySameValuesSet) {
         this.availabilityDisabled = true;
      }
   }

   public get frequencySameValuesSet(): boolean {
      return this._frequencySameValuesSet;
   }

   public set frequencySameValuesSet(newValue: boolean) {
      this._frequencySameValuesSet = newValue;
   }

   public get durationSameValuesSet(): boolean {
      return this._durationSameValuesSet;
   }

   public set durationSameValuesSet(newValue: boolean) {
      this._durationSameValuesSet = newValue;
   }

   public get locationSameValuesSet(): boolean {
      return this._locationSameValuesSet;
   }

   public set locationSameValuesSet(newValue: boolean) {
      this._locationSameValuesSet = newValue;
   }

   public get prioritySameValuesSet(): boolean {
      return this._prioritySameValuesSet;
   }

   public set prioritySameValuesSet(newValue: boolean) {
      this._prioritySameValuesSet = newValue;
   }

   public get criticalSameValuesSet(): boolean {
      return this._criticalSameValuesSet;
   }

   public set criticalSameValuesSet(newValue: boolean) {
      this._criticalSameValuesSet = newValue;
   }

   ////////////////////////////////////// Override/Default toggle properties ////////////////////////////////////////////
   // In each of the property set methods, if the user has toggled to the Default settings, then reset the callpoint settings
   // incase the user did overrode them and then changed their mind.
   public get availabilityDisabled(): boolean {
      return this._availabilityDisabled;
   }

   public set availabilityDisabled(newValue: boolean) {
      this._availabilityDisabled = newValue;
      if (this._callpoint.callpointSettings !== null) {
         this._callpoint.callpointSettings.availability = undefined;
      }
   }

   public get allowAllDayCombinationDisabled(): boolean {
      return this._allowAllDayCombinationDisabled;
   }

   public set allowAllDayCombinationDisabled(newValue: boolean) {
      this._allowAllDayCombinationDisabled = newValue;
      if (this._callpoint.callpointSettings !== null) {
         this._callpoint.callpointSettings.allowAllDayCombination = undefined;
      }
   }
   

   ///////////////////////////// get/set methods for callpoint setting properties /////////////////////////////////
   // Get/Set methods for each of the input properties on CallpointSettings.
   // If any of the properties are overriden then return the value from CallpointSettings, otherwise return
   // the project default value.
   public get availability(): Date[] {
      if (this._availabilityDisabled) {
         return this._projectSettings.availability;
      }
      else {
         if (this._callpoint.callpointSettings !== null && this._callpoint.callpointSettings.availability !== undefined) {
            return this._callpoint.callpointSettings.availability;
         }
         else {
            return this._projectSettings.availability;
         }
      }
   }

   public set availability(newValue: Date[]) {
      if (!this._availabilityDisabled) {
         if (this._callpoint.callpointSettings === null) {
            this._callpoint.callpointSettings = new CallpointSettings();
         }
         this._callpoint.callpointSettings.availability = newValue;
      }
   }

   public get allowAllDayCombination(): boolean {
      if (this._allowAllDayCombinationDisabled) {
         return this._projectSettings.allowAllDayCombination;
      }
      else {
         if (this._callpoint.callpointSettings !== null && this._callpoint.callpointSettings.allowAllDayCombination !== undefined) {
            return this._callpoint.callpointSettings.allowAllDayCombination;
         }
         else {
            return this._projectSettings.allowAllDayCombination;
         }
      }
   }

   public set allowAllDayCombination(newValue: boolean) {
      if (!this._allowAllDayCombinationDisabled) {
         if (this._callpoint.callpointSettings === null) {
            this._callpoint.callpointSettings = new CallpointSettings();
         }
         this._callpoint.callpointSettings.allowAllDayCombination = newValue;
      }
   }

   public get dayCombinations(): string[] {
      return this._projectSettings.dayCombinations;
   }
   public set dayCombinations(dayCombinations: string[]) {
      this._projectSettings.dayCombinations = dayCombinations;
   }

   public get frequency(): any {
      return this._callpoint.frequency;
   }

   public set frequency(newValue: any) {
      this._callpoint.frequency = newValue;
   }

   public get duration(): any {
      return this._callpoint.duration;
   }

   public set duration(newValue: any) {
      this._callpoint.duration = newValue;
   }

   public get longitude(): any {
      return this._callpoint.longitude;
   }

   public set longitude(newValue: any) {
      this._callpoint.longitude = newValue;
   }

   public get latitude(): any {
      return this._callpoint.latitude;
   }

   public set latitude(newValue: any) {
      this._callpoint.latitude = newValue;
   }

   public get startDate(): any {
      return this._callpoint.startDate;
   }

   public set startDate(newValue: any) {
      this._callpoint.startDate = newValue;
   }

   public get endDate(): any {
      return this._callpoint.endDate;
   }

   public set endDate(newValue: any) {
      this._callpoint.endDate = newValue;
   }

   public get priority(): any {
      return this._callpoint.priority;
   }

   public set priority(newValue: any) {
      this._callpoint.priority = newValue;
   }

   public get critical(): boolean {
      return this._callpoint.critical === 'Y';
   }

   public set critical(newValue: boolean) {
      newValue ? this._callpoint.critical = 'Y'
         : this._callpoint.critical = '';
   }

   // Set the state of the override/default toggles based on the state of CallerSettings
   private initialiseSettingsState() {
      if (this._callpoint.callpointSettings != null) {
         if (this.isAvailabilityDisabled()) {
            this._availabilityDisabled = true;
         }
         else {
            this._availabilityDisabled = false;
         }

         if (this.isAllowAllDayCombinationDisabled()) {
            this._allowAllDayCombinationDisabled = true;
         }
         else {
            this._allowAllDayCombinationDisabled = false;
         }
      }
      else {
         this._allowAllDayCombinationDisabled = true;
         this._availabilityDisabled = true;
      }
   }

   ///////////  Methods for testing the state of each property and setting the override toggle inputs /////////////
   private isAvailabilityDisabled(): boolean {
      if (this._callpoint.callpointSettings.availability === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   private isAllowAllDayCombinationDisabled(): boolean {
      if (this._callpoint.callpointSettings.allowAllDayCombination === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   // MNF: Are the 36 lines of code above in 'initialiseSettingsState()', 'isAvailabilityDisabled()' and
   // 'isAllowAllDayCombinationDisabled()' not functionally equivalent to these 2 lines of code? If so I think this is
   // significantly more readable than the above, so please replace.
   private initialiseSettingsState2(): void {
      this._availabilityDisabled = !this._callpoint.callpointSettings || !this._callpoint.callpointSettings.availability;
      this._allowAllDayCombinationDisabled = !this._callpoint.callpointSettings || !this._callpoint.callpointSettings.allowAllDayCombination;
   }
}
