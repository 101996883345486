<p-dataTable 
   [value]="callers" 
   [resizableColumns]="true" 
   [reorderableColumns]="false" 
   selectionMode="multiple" 
   [(selection)]="selectedCallers"
   [rowTrackBy]="trackByFn"
   (onRowSelect)="onRowSelect($event)"  
   (onRowUnselect)="onRowUnselect($event)" 
   (onFilter)="onFilter($event)"
   [scrollHeight]="scrollHeight" 
   [scrollable]="true" 
   (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
   dataKey="territory" 
   virtualScroll="virtualScroll"
   [rows]="rows"
   [lazy]="true"
   [totalRecords]="totalRecords" 
   (onLazyLoad)="loadDataLazy($event)" 
   [loading]="loading"
   #callerdt>
   
   <p-header>
      <div style="float:left; margin-top: -4px;">
         <h4>{{gridTitle}}</h4>
      </div>

      <div style="float:left; height: 40px; padding-left: 10px;">
         <button id="clearFilter" type="button" class="btn cs-btn-secondary " (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
            <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
      </div>

      <div style="text-align:right">
         <callsmart-multi-select [options]="columnOptions" [(ngModel)]="cols" [selectedItemsLabel]="selectedItemsLabel" [displaySelectedLabel]="displaySelectedLabel"
            [maxSelectedLabels]="maxSelectedLabels" [defaultLabel]="defaultLabel" [style]="{'width':'120px'}" [panelTitle]="panelTitle"
            [scrollHeight] = "filterColumnPanelHeight"  (onChange)="setNewColumnSelection($event)"></callsmart-multi-select>
      </div>
   </p-header>

   <!-- check box for selection -->
   <p-column [style]="{'width':'40px', 'min-width':'35px','max-width':'40px', 'padding':'5px'}" selectionMode="multiple"></p-column>

   <!-- <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
      [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'min-width':'80px'}"> -->
      <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
      [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'width':'150px', 'min-width':'80px', 'overflow':'hidden'}">

      <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
         <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
            (onChange)="callerdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
         <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
            displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="callerdt.filter($event.value,col.field,col.filterMatchMode)"
            defaultLabel="All" appendTo="body"></p-multiSelect>
      </ng-template>

      <!-- Show progress indicator for all the following columns, doing the ngIf check on the
      ng-template tag doesn't work, hence why it is wrapped by the ng-container -->
      <ng-container *ngIf="col.field == 'scheduleMetrics.utilisationPc'">
         <ng-template let-caller="rowData" pTemplate="body">

            <!-- If block -->
            <div *ngIf="isOptimising(caller); else showUtilisationFigure" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>
            <!-- <img *ngIf="isOptimising(caller); else showUtilisationFigure"
               src="./assets/icons/bars.svg" height="20" width="20" alt="Optimising"> -->

            <!-- Else block -->
            <ng-template #showUtilisationFigure>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.utilisationPc : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduledVisitsPc'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else scheduledVisitsPc" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #scheduledVisitsPc>
               <!-- x !== x is way to check for NaN similar to isNaN(x) which doesn't seem to work here. -->
               <!-- scheduledVisitsPc will be NaN if there are no schedule metrics for the caller, this happens where a new caller has been
               added via an update -->
               <span>{{caller.scheduledVisitsPc !== caller.scheduledVisitsPc ? 0 : caller.scheduledVisitsPc}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.minCallsPerDay'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else minCallsPerDay" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #minCallsPerDay>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.minCallsPerDay : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.avgCallsPerDay'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else avgCallsPerDay" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #avgCallsPerDay>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.avgCallsPerDay : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.maxCallsPerDay'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else maxCallsPerDay" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #maxCallsPerDay>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.maxCallsPerDay : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.driveDistanceKm'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else driveDistanceKm" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #driveDistanceKm>
               <span>{{((caller.scheduleMetrics !== null )? caller.scheduleMetrics.driveDistanceKm : '') | distanceUnits:isDistanceUnitMiles |  number:'1.0-0'}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'numDeferredVisits'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else numDeferredVisits" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #numDeferredVisits>
               <span>{{caller.scheduleMetrics !== null ? caller.numDeferredVisits : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.driveTimeHrs'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else driveTimeHrs" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #driveTimeHrs>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.driveTimeHrs : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.numEmptyDays'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else numEmptyDays" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #numEmptyDays>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.numEmptyDays : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.spareTimeHrs'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else spareTimeHrs" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #spareTimeHrs>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.spareTimeHrs : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.numVisitsBooked'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else numVisitsBooked" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #numVisitsBooked>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.numVisitsBooked : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.timeInVisitsHrs'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else timeInVisitsHrs" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #timeInVisitsHrs>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.timeInVisitsHrs : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.shiftTimeInVisitPc'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else shiftTimeInVisitPc" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #shiftTimeInVisitPc>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.shiftTimeInVisitPc : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.avgDriveTimeBetweenVisitsMins'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else avgDriveTimeBetweenVisitsMins" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #avgDriveTimeBetweenVisitsMins>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.avgDriveTimeBetweenVisitsMins : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.numOvernights'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else numOvernights" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #numOvernights>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.numOvernights : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.maxConsecutiveOvernights'">
         <ng-template let-caller="rowData" pTemplate="body">
            <!-- If block -->
            <div *ngIf="isOptimising(caller); else maxConsecutiveOvernights" class="optimisation-spinner">
               <div class="bounce1"></div>
               <div class="bounce2"></div>
               <div class="bounce3"></div>
            </div>

            <!-- Else block -->
            <ng-template #maxConsecutiveOvernights>
               <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.maxConsecutiveOvernights : ''}}</span>
            </ng-template>

         </ng-template>
      </ng-container>
      <!-- End of columns that show progress image -->

      <ng-container *ngIf="col.field == 'scheduleMetrics.commuteTimeHrs'">
         <ng-template let-caller="rowData" pTemplate="body">
            <span>{{caller.scheduleMetrics.commuteTimeHrs | number:'1.0-0'}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'avgCommuteTime'">
         <ng-template let-caller="rowData" pTemplate="body">
            <span>{{caller.avgCommuteTime | number:'1.0-0'}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'avgCommuteDistance'">
         <ng-template let-caller="rowData" pTemplate="body">
               
            <span>{{((caller.avgCommuteDistance !== null )? caller.avgCommuteDistance : '') | distanceUnits:isDistanceUnitMiles |  number:'1.0-0'}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'datesClosed'">
         <ng-template let-caller="rowData" pTemplate="body">
            <span>{{formatDateClosed(caller)}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'scheduleMetrics.numDisabledCallpoints'">
         <ng-template let-caller="rowData" pTemplate="body">
            <span>{{caller.scheduleMetrics !== null ? caller.scheduleMetrics.numDisabledCallpoints : ''}}</span>
         </ng-template>
      </ng-container>
   </p-column>
</p-dataTable>
