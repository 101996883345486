import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationStore } from 'app/stores/application-store';

/*
  this is a service as it is injectable.
  it does not take the http service as it is not used to communicate with the web api.
  this to be used as a routing guard to provent the user from navigating to pages without logining on

*/
@Injectable()
export class AuthGuardService implements CanActivate {

   constructor(private router: Router, private _applicationStore: ApplicationStore) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // user has logged in
      if (this._applicationStore.authenticationStore.loggedInUser != null) {
         if (this._applicationStore.authenticationStore.loggedInUser.userId != 0) {
            return true;
         }
      }

      // if the user has cut and pasted the url and not gone to the login page cookie might still be valid
      // try log them in
      this.checkIfAlreadySignedIn(state.url);
   }

   private checkIfAlreadySignedIn(url) {
      let isAuth = this._applicationStore.authenticationStore.isAuthenticated();

      if (isAuth) {
         this.router.navigate([url]);
         return false;
      }

      // not logged in so redirect to login page with the return url and return false
      this.router.navigate(['/']);
      return false;

   }
}
