import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';
import { JsonObject, JsonProperty } from 'json2typescript';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ClosedDatesArrayConverter } from 'app/shared/json-converters/closed-dates-array-converter';
import { Identifiable } from 'app/shared/interfaces/Identifiable.interface';
import { DateConverter } from 'app/shared/date-converter/date-converter';

/**
 * Model class representing a Callpoint.
 */
@Injectable()
@JsonObject
export class Callpoint implements Identifiable {
   constructor() {
      //dont generate the guid here if you then use slice later he copy gets a new guid id fing up the map
      //this.guid = this.generateGUID();
   }

   // Values from server.

   @JsonProperty('callpointId')
   public callpointId: number = undefined;

   @JsonProperty('projectId')
   public projectId: number = undefined;

   @JsonProperty('callerId')
   public callerId: number = undefined;

   @JsonProperty('callpointSettings', CallpointSettings, true)
   public callpointSettings: CallpointSettings = undefined;

   @JsonProperty('reference')
   public reference: string = undefined;

   @JsonProperty('geocode')
   public geocode: string = undefined;

   @JsonProperty('tidyPostCode')
   public tidyPostCode: string = undefined;

   @JsonProperty('longitude')
   public longitude: number = undefined;

   @JsonProperty('latitude')
   public latitude: number = undefined;

   @JsonProperty('frequency')
   public frequency: number = undefined;

   @JsonProperty('scheduledVisits')
   public scheduledVisits: number = undefined;

   @JsonProperty('duration')
   public duration: number = undefined;

   @JsonProperty('priority')
   public priority: number = undefined;

   @JsonProperty('name')
   public name: string = undefined;

   @JsonProperty('address1')
   public address1: string = undefined;

   @JsonProperty('address2')
   public address2: string = undefined;

   @JsonProperty('address3')
   public address3: string = undefined;

   @JsonProperty('address4')
   public address4: string = undefined;

   @JsonProperty('address5')
   public address5: string = undefined;

   @JsonProperty('salesValue')
   public salesValue: number = undefined;

   @JsonProperty('grade')
   public grade: number = undefined;

   @JsonProperty('startDay')
   public startDay: number = undefined;

   @JsonProperty('endDay')
   public endDay: number = undefined;

   @JsonProperty('startBreak')
   public startBreak: number = undefined;

   @JsonProperty('endBreak')
   public endBreak: number = undefined;

   @JsonProperty('datesClosed', ClosedDatesArrayConverter, true)
   public datesClosed: string[] = undefined;

   @JsonProperty('driveTimeToCaller')
   public driveTimeToCaller: number = undefined;

   @JsonProperty('driveDistanceToCaller')
   public driveDistanceToCaller: number = undefined;

   @JsonProperty('other1')
   public other1: string = undefined;

   @JsonProperty('other2')
   public other2: string = undefined;

   @JsonProperty('other3')
   public other3: string = undefined;

   @JsonProperty('other4')
   public other4: string = undefined;

   @JsonProperty('other5')
   public other5: string = undefined;

   @JsonProperty('startDate', DateConverter, true)
   startDate: Date = undefined;

   @JsonProperty('endDate', DateConverter, true)
   endDate: Date = undefined;

   @JsonProperty('lockedState')
   public lockedState: string = undefined;

   @JsonProperty('isDisabled')
   public isDisabled: boolean = false;

   @JsonProperty('critical')
   public critical: string = undefined;

   // Derived or transformed values.

   // This comes from the Caller associated with this callpoint, the value for this
   // is set in the callpoint service when the callpoints are loaded.
   public callerTerritory: string = undefined;

   // fullyScheduled implemented as a lazy evaluated calculated property on the model.
   private _fullyScheduled: string;
   public get fullyScheduled(): string {
      this._fullyScheduled = this.scheduledVisits + '/' + this.frequency;
      return this._fullyScheduled;
   }

   private _driveDistanceToCallerStr: string;
   public get driveDistanceToCallerStr(): string {
      if (this._driveDistanceToCallerStr === undefined) {
         this._driveDistanceToCallerStr = this.driveDistanceToCaller.toFixed(2);
      }
      return this._driveDistanceToCallerStr;
   }

   private _driveTimeToCallerHms: string;
   public get driveTimeToCallerHms(): string {
      if (this._driveTimeToCallerHms === undefined) {
         let duration = <any>moment.duration(this.driveTimeToCaller, 'seconds');
         this._driveTimeToCallerHms = duration.format('HH:mm', {
            trim: false,
            forceLength: true
         });
      }
      return this._driveTimeToCallerHms;
   }

   public get isDisabledText(): string {
      if (!this.isDisabled) {
         return "Enabled";
      }
      return "Disabled";
   }
   

   // client side unique identifier (needed for map and grid selection synconisation)
   public guid: string;

   public generateGUID() {
      let d = new Date().getTime();
      let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
         /[xy]/g,
         function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
         }
      );
      return guid;
   }
}
