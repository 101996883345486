import { Component, OnInit, ChangeDetectorRef, Output, ViewChild, Input, EventEmitter, DoCheck, AfterViewInit, AfterContentChecked } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import * as moment from 'moment/moment';

import { Event } from 'app/models/diary/event';
import { EventTypes } from 'app/models/diary/event-types';
import { ApplicationStore } from 'app/stores/application-store';
import { VisitSelection } from 'app/models/visit-selection';
import { LockingTypes } from 'app/models/diary/locking-Types';

declare var jQuery: any;

@Component({
   selector: 'callsmart-calendar',
   templateUrl: './calendar.component.html',
})

// This class represents the calendar component which handles the graphic representation of
// all events.
export class CalendarComponent implements OnInit, DoCheck, AfterViewInit, AfterContentChecked {

   @Output() daySelected: EventEmitter<any> = new EventEmitter<any>();  // Must be output to allow other components to bind to it.
   @Output() visitSelected: EventEmitter<VisitSelection> = new EventEmitter<VisitSelection>();
   @Output() eventSelected: EventEmitter<Event> = new EventEmitter<Event>();
   @Output() calendarRendered: EventEmitter<any> = new EventEmitter<any>();
   @Output() externalVisitDropped: EventEmitter<any> = new EventEmitter<any>();
   @Output() internalVisitDropped: EventEmitter<any> = new EventEmitter<any>();
   @Output() showCalendarHeadersOnlyChanged: EventEmitter<any> = new EventEmitter<any>();

   // Gets and sets the events to be displayed
   private _events: any[];
   @Input()
   get scheduleEvents(): any[] {
      return this._events;
   }
   set scheduleEvents(theEvents: any[]) {
      this._events = theEvents;
      // Loads the calendar with the new events.
      this.loadSchedule(this._events);
      this.selectedDate = null;

      // Added this to simulate a day click so that the calendar has the correct event when 
      // the user selects a different day.
      this.selectDay(this._applicationStore.scheduleStore.selectedDiaryDay, false);

      // The first time the calendar is loaded to set the visits to 'draggable' is not needed 
      // since the canInternalDragDrop setter will do it 
      if (!this.firstTimeLoading) {
         // Sets only the visits to 'draggable' depending on the canInternalDragDrop property value
         this.setVisitsDraggable(this.canInternalDragDrop);
      }
   }

   // Gets and sets the number of weeks to be displayed in the calendar
   private _numberOfWeeks: number = 12;
   @Input()
   get numberOfWeeks(): number {
      return this._numberOfWeeks;
   }
   set numberOfWeeks(numberOfWeeks: number) {
      this._numberOfWeeks = numberOfWeeks;
      let cycleStartDate: moment.Moment = moment(this.options.views.cycleView.visibleRange.start, 'YYYY-MM-DD');
      this.options.views.cycleView.visibleRange.end = cycleStartDate.clone().add(this._numberOfWeeks, 'w').format('YYYY-MM-DD');
      if (numberOfWeeks === 1) {
         this.goToWeekView();
      }
   }

   // Gets and sets whether the select day of week buttons should be active or not.
   @Input() canSelectDayOfWeek: boolean = true;

   // Gets and sets the starting day of the cycle.
   private _startDate: Date;
   @Input()
   get startDate(): Date {
      return this._startDate;
   }
   set startDate(startDate: Date) {
      if (startDate) {
         this._startDate = new Date(startDate);
         // the 'startDate' property is a normal JS Date not a moment object.
         // sets the initial date for the calendar.
         this.initialDate = moment(this._startDate.getFullYear()
            + '-' + (this._startDate.getMonth() + 1)
            + '-' + this._startDate.getDate(), 'YYYY-MM-DD');

         this.currentDate = this.initialDate.clone();

         // Configures the cycleView start and end dates.
         // VisibleRange.end property must be bigger than visibleRange.start property.
         // Otherwise the JQuery fullcalendar blows up very badly.
         this.options.views.cycleView.visibleRange.start = this.currentDate.format('YYYY-MM-DD');
         this.options.views.cycleView.visibleRange.end = this.currentDate.clone().add(this.numberOfWeeks, 'w').format('YYYY-MM-DD');
         this.options.firstDay = this.currentDate.day();

         if (this.numberOfWeeks === 1) {
            this.goToWeekView();
         }
         else{
            this.goToCycleView();
         }
      }
      // The calendar is loaded if it is not initialized yet when adding the event collection.
      if (!this.events && this.events.length > 0) {
         this.loadSchedule(this.events)
      }
   }

   // Gets and sets the start working time (initial business time for the calendar component).
   private _startWorkingTime: string;
   @Input()
   get startWorkingTime(): string {
      return this._startWorkingTime;
   }
   set startWorkingTime(startWorkingTime: string) {
      this._inputStartTime = startWorkingTime;

      if(startWorkingTime.includes('|')) {
         let startTime: string[] = startWorkingTime.split('|');
         for (let index = 0; index < this.businessHours.length; index++) {
            this.businessHours[index].start = startTime[index];
         }
      }
      else {
         let regexp = new RegExp('([0-1]?[0-9]|2[0-3]):[0-5][0-9]');
         if (regexp.test(startWorkingTime)) {
            this._startWorkingTime = startWorkingTime;
            for (let index = 0; index < this.businessHours.length; index++) {
               this.businessHours[index].start = startWorkingTime;
            }
         }
      }
   }

   // Gets and sets the end working time (final business time for the calendar component).
   private _endWorkingTime: string;
   @Input()
   get endWorkingTime(): string {
      return this._endWorkingTime;
   }
   set endWorkingTime(endWorkingTime: string) {
      this._inputEndTime = endWorkingTime;
      if(endWorkingTime.includes('|')) {
         let endTime: string[] = endWorkingTime.split('|');
         for (let index = 0; index < this.businessHours.length; index++) {
            this.businessHours[index].end = endTime[index];
         }
      }
      else {
         let regexp = new RegExp('([0-1]?[0-9]|2[0-3]):[0-5][0-9]');
         if (regexp.test(endWorkingTime)) {
            this._endWorkingTime = endWorkingTime;
            for (let index = 0; index < this.businessHours.length; index++) {
               this.businessHours[index].end = endWorkingTime;
            }
         }
      }

      if (!this.firstTimeLoading) {
         // This code forces to perform the handleViewRender method.
         if (this.currentView === 'cycleView') {
            this.goToCycleView();
         }
         else {
            this.goToWeekView();
         }
      }
   }

   // Gets and sets available working days.
   private _activeWorkingDays: boolean[];
   @Input()
   get activeWorkingDays(): boolean[] {
      return this._activeWorkingDays;
   }
   // The callsmart setting for this property differs from the one
   // the calendar component uses. That requires some calculations here
   set activeWorkingDays(callSmartWorkingDays: boolean[]) {
      if (callSmartWorkingDays && callSmartWorkingDays.length === 7) {
         let calendarWorkingDays: number[] = [];
         let index: number = 1;
         callSmartWorkingDays.forEach(dayOfWeek => {
            let value: number = index % 7;
            if (dayOfWeek) {
               calendarWorkingDays.push(value);
            }
            index++;
         });
         this._activeWorkingDays = callSmartWorkingDays;

         if (this.firstTimeLoading && this.numberOfWeeks === 1) {
            this.goToWeekView();
         }
         if (!this.firstTimeLoading) {
            // This code forces to perform the handleViewRender method.
            if (this.currentView === 'cycleView') {
               this.goToCycleView();
            }
            else {
               this.goToWeekView();
            }
         }
      }
   }

   // Gets and sets dates closed.
   private _datesClosed: string[] = [];
   @Input()
   get datesClosed(): string[] {
      return this._datesClosed;
   }
   // The callsmart setting for this property differs from the one
   // the calendar component uses. That requires some calculations here
   set datesClosed(datesClosed: string[]) {
      this._datesClosed = datesClosed;
      if (!this.firstTimeLoading) {
         // This code forces to perform the handleViewRender method.
         if (this.currentView === 'cycleView') {
            this.goToCycleView();
         }
         else {
            this.goToWeekView();
         }
      }
   }
   // Gets and sets the end working time (final business time for the calendar component).
   @Input() calendarHeight: number;
   //Drag&Drop: Determines whether dragging calendar visits is allowed 
   private _canInternalDragDrop: boolean = false;
   @Input()
   get canInternalDragDrop(): boolean {
      return this._canInternalDragDrop;
   }
   set canInternalDragDrop(value: boolean) {
      this._canInternalDragDrop = value;
      if (this.events) {
         // Sets only the visits to draggable depending on the value passed as a parameter
         this.setVisitsDraggable(this._canInternalDragDrop);
      }
   }
   //Drag&Drop: Determines whether dragging external elements to the calendar is allowed
   @Input() public canExternalDragDrop: boolean = false;
   //Drag&Drop: Determines whether the events on the calendar can be modified
   @Input() public canEditEvents: boolean = false;
   // Hide/Show the slider contorl to minimise the calendar control
   @Input() public canMinimiseCalendar: boolean = false;
   // Sets enablement of the control to show headers only
   @Input() public showHeaderOnlyOptionDisabled: boolean = false;

   @Input() public diaryViewTime: number = 60;

   // JavaScript view object from Full Callendar Js.
   javaScriptFullCalendarView: any;
   // JavaScript calendar object from Full Callendar Js (This is one of the properties from javaScriptFullCalendarView).
   javaScriptCalendar: any;
   // jQuery element for the container of the calendar
   jQueryCalendarElement: any;

   //public calendarHeight = null;
   public scrollTime: string = '07:00:00';
   public scrollTop: number; // Used to bring the working hours into view when resizing the browser
   public aspectRatio = '3';
   public events: Event[];
   public header: any;
   public event: Event; // Selected event
   public initialDate: moment.Moment = null;
   public currentDate: moment.Moment = null;
   public selectedDate: moment.Moment = null;
   public availableViews: SelectItem[];
   public currentView: string = 'cycleView';
   public isWeekViewSelected: boolean = false;
   public selectedCycleWeek: number;
   public currentCycleWeek: number;

   public calendarTitle: string;
   public isVisible: boolean = false;
   public firstTimeLoading: boolean = true;
   public originalEventDragged: Event = null;
   public draggingRevertFunction: any = null
   public isFullCalendarMaximised: boolean = true;

   private _isDragging: boolean = false;
   private _highlightedColumn: HTMLElement;
   private _highlightedColumnHeader: HTMLElement;

   private _inputStartTime: string;
   private _inputEndTime: string;
   private _heightPerRow: number;
   
   @ViewChild('primeCalendar') jQueryCalendar; // Don't have to type this variable as ElementRef because I lose access to the JQuery calendar control

   businessHours = [
      {
         dow: [0], // Sunday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },
      {
         // days of week. an array of zero-based day of week integers (0=Sunday).
         dow: [1], // Monday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },{
         dow: [2], // Tuesday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },{
         dow: [3], // Wednesday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },{
         dow: [4], // Thursday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },{
         dow: [5], // Friday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      },
      {
         dow: [6], // Saturday
         start: '10:00', // a start time (10am in this example).
         end: '18:00', // an end time (6pm in this example).
      }
   ];

   options = {
      titleFormat: 'D MMMM YYYY',
      columnFormat: 'dddd D',

      views: {
         cycleView: {
            type: 'agenda',
            visibleRange: {
               //start: '2017-05-15',
               //end: '2017-08-07'
               start: 'null',
               end: 'null'
            },
            buttonText: 'Cycle',
            columnFormat: 'ddd D'
         }
      },
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      firstDay: 1,
      displayEventTime: false,
      eventOverlap: false, // Drag&Drop: Default value (Determines if events being dragged and resized are allowed to overlap each other)
      slotEventOverlap: false, // Default value (Determines if timed events in agenda view should visually overlap)        
      eventStartEditable: false, // Drag&Drop: Allow events’ start times to be editable through dragging.
      eventDurationEditable: false, // Allow events’ durations to be editable through resizing.
      eventRender: (event, element) => {
         // Adding extra UI information to the project events
         if (event && event.eventType === EventTypes.project) {
            element.find('.fc-content')
               .append("<br><div class='description fc-title'><i class='cs-icon icon-profile calendar-event-icon'></i><span> " + event.description + "</span>");
         }

         element.find('.fc-title').css({
            'min-height': '8px',
            'margin-top': '2px',
            'margin-left': '10px',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'overflow': 'hidden',
         });
         (element).css({
            'margin': 'auto',
            /*'margin-left': '1px',*/
         });
      },
      eventAfterAllRender: (view) => {
         // Adjusting the axis grid to avoid double lines
         let fc_axis: NodeList = document.querySelectorAll('.fc-content-skeleton .fc-axis');
         if (fc_axis) {
            jQuery(fc_axis).css({ 'width': '39px' });
         }
         let fc_axis2: NodeList = document.querySelectorAll('.fc-axis.ui-widget-content');
         if (fc_axis2) {
            Array.prototype.forEach.call(fc_axis2, element => {
               jQuery(element).css({ 'width': '39px' });
            });
         }
      },
     // windowResize: (view) => {
         //console.log('windowResize called');
         //if(window.devicePixelRatio !== 1) {
         //setTimeout(() => this.setScrollWithFixHeight(this.calendarHeight - 30), 200);
            //this.javaScriptFullCalendarView.updateSize(true);
         //}
      //},
      // Drag&Drop properties and events
      droppable: false,
      //dropAccept: '.deferral-item', // only elements with class='deferral' are allow to be drop into the calendar
      drop: (date, event, ui, view) => {
         let deferral: string = jQuery(event.target).data('deferral');
         let droppedVisit: any = JSON.parse(deferral);
         this.externalVisitDropped.emit({
            callpointId: droppedVisit.callpointId,
            droppedDate: date.toDate()
         });
      },
      eventReceive: (event) => {
         if (this.javaScriptCalendar) {
            // The event added automatically by the calendar control is deleted
            // since the new event is added reloading the event list
            this.javaScriptCalendar.deleteEvent(event);
         }
      },
      eventDragStart: (event, jsEvent, ui, view) => {
         this.originalEventDragged = this._events.find(visit => moment(visit.start).diff(event.start) === 0)
         this.draggingRevertFunction = null; // remove the old revert function stored for a previous drag&drop action
         this._isDragging = true;
         let listBgEvents = jQuery(this.jQueryCalendarElement).find('.fc-dragging');
         listBgEvents.toArray().forEach(bgEvent => {
            jQuery(bgEvent).hover(function () {
               let x = bgEvent;
               if (this._isDragging) {
                  jQuery(bgEvent).css({
                     'cursor': 'move',
                  })
               }
            });
         });
      },
      eventDragStop: (event, jsEvent, ui, view) => {
         let listBgEvents = jQuery(this.jQueryCalendarElement).find('.fc-dragging');
         listBgEvents.toArray().forEach(bgEvent => {
            jQuery(bgEvent).hover(function () {
               if (this._isDragging) {
                  jQuery(bgEvent).css({
                     'cursor': 'pointer',
                  })
               }
            });
         });
         // Resets the styles after stopping dragging calendar styles
         jQuery(this._highlightedColumn).removeAttr('style');
         this._highlightedColumn = null;
         jQuery(this._highlightedColumnHeader).css({
            'background-color': 'transparent',
            'color': '#515151'
         })

         this._isDragging = false;
      },
      eventDrop: (event, delta, revertFunc, jsEvent, ui, view) => {
         this.draggingRevertFunction = revertFunc;
         let date: moment.Moment = event.start;
         this.internalVisitDropped.emit({
            callpointReference: event.callpointId,
            originalDate: this.originalEventDragged.start,
            droppedDate: date.toDate()
         });
         this.originalEventDragged = null;
      },
   };

   constructor(private _applicationStore: ApplicationStore, private cd: ChangeDetectorRef) {
      this.availableViews = [];
      this.availableViews.push({ label: 'Week', value: 'agendaWeek' });
      this.availableViews.push({ label: 'Cycle', value: 'cycleView' });
   }

   ngOnInit() {
      this.firstTimeLoading = false;
   }

   ngAfterViewInit() {
      this.setScroll(null);
   }

   ngDoCheck() {
      // The selected day is highlighted based on the presence of highligthed events. There is not other way to do this,
      // since there is no relationship between the day columns where the events are located
      // and the column headers in the calendar itself (They belong to different internal tables).

      if (this.jQueryCalendar && document.getElementsByClassName('fc-time-grid').length > 0) {
         let calendarHeight: number = document.getElementsByClassName('fc-time-grid')[0].clientHeight;
         this.highlightDayColumn(this.jQueryCalendar.schedule, this.currentView, 0, -calendarHeight);

         /*************** Start Drag&Drop logic **********************/

         // Clears the highlighted style from all day column headers
         let hearderUlList: any = jQuery(this.jQueryCalendarElement).find('ul', '.fc-day-header');
         let blackCalendarElement: any = jQuery(this.jQueryCalendarElement).closest('callsmart-calendar.black-calendar');
         let headerUlListColour: string = '#515151';
         if (blackCalendarElement.length > 0) {
            headerUlListColour = 'white';
         }
         jQuery(hearderUlList).css({
            'background-color': 'transparent',
            'color': headerUlListColour
         })
         // Highlights the seleced day column header applying the selected day column header style
         // '.selectedDayUl' identifies the selected day column header
         let selectedHeaderUlList = jQuery(this.jQueryCalendarElement).find('.selectedDayUl');
         jQuery(selectedHeaderUlList).css({
            'background-color': '#34BF84',
            'color': 'white'
         })

         // *** Drag&Drop carried out FROM the VISIT LIST ***        
         let shadow = document.getElementsByClassName('fc-highlight')
         // '.fc-highlight' is the class used by the calendar to hightlight 
         // the column area when dragging and external element.

         if (shadow.length > 0) {
            // Highlights the day column background when dragging over it.            
            jQuery(shadow).css({
               'top': 0 + 'px',
               'bottom': -calendarHeight + 'px',
               'opacity': 0.4,
               //'background-color': '#34bf84'
               'background-color': '#6954c3'
            })

            // Highlights the day column header related to the highlighted column.
            // Searches the UL element to be highlighted navigating up from the '.fc-highlight' (our shadow Js object)
            let tdIndex: number = jQuery(shadow).closest('td').index();
            let th: Element = document.getElementsByClassName('fc-day-header')[tdIndex - 1];
            //let targetDate = jQuery(th).attr('data-date');
            let columnHeader = th.getElementsByTagName('ul')[0];
            jQuery(columnHeader).css({
               'background-color': '#6954c3',
               'color': 'white'
            })
         }

         // *** Drag&Drop inside the calendar itself  ***
         if (document.getElementsByClassName('fc-dragging').length > 0 /*&& !this._hoverEventAdded*/) {

            // Searches the temporary UI calendar event object create to follow the cursor when dragging.
            // Modifies the cursor to 'move'
            let dragging = jQuery(this.jQueryCalendarElement).find('.fc-dragging')[0];
            jQuery(dragging).css({
               'cursor': 'move',
            })

            // Resets the styles before highlighting the new day column
            let draggingParentElement = jQuery(dragging).parent()[0];

            // Checks the previous highlighted day column when dragging to reset its styles.
            if (this._highlightedColumn) {
               // if user is dragging an element over the same column, then nothing is done.
               // Otherwise the previous highlighted day column style will be reset
               let sameColumn = this._highlightedColumn.isSameNode(jQuery(draggingParentElement).siblings('.fc-highlight-container')[0]);
               if (!sameColumn) {
                  jQuery(this._highlightedColumn).removeAttr('style');
                  // if that column was previously selected then the selected day column header style must be reset
                  if (jQuery(this._highlightedColumnHeader).hasClass('selectedDayUl')) {
                     jQuery(this._highlightedColumnHeader).css({
                        'background-color': '#34BF84',
                        'color': 'white'
                     });
                  }
                  // Otherwise, the non-selected day column header style is also reset
                  else {
                     jQuery(this._highlightedColumnHeader).css({
                        'background-color': 'transparent',
                        'color': '#515151'
                     })
                  }
               }
            }

            // Highlights the day column where the visit is being dragging over.
            // Caches the new column where the user is drag over to our internal variable
            this._highlightedColumn = jQuery(draggingParentElement).siblings('.fc-highlight-container')[0];
            jQuery(this._highlightedColumn).css({
               'top': 0 + 'px',
               'bottom': -calendarHeight + 'px',
               'opacity': '0.4',
               'position': 'absolute',
               'width': '100%',
               //'background-color': '#34bf84'
               'background-color': '#6954c3',

            });

            // Highlights the day column header related to the highlighted column.
            // Searches the UL element to be highlighted navigating up from the '.fc-highlight' (our shadow Js object)
            let tdIndex: number = jQuery(this._highlightedColumn).closest('td').index();
            let th: Element = document.getElementsByClassName('fc-day-header')[tdIndex - 1];
            if (th) {
               this._highlightedColumnHeader = th.getElementsByTagName('ul')[0];
               jQuery(this._highlightedColumnHeader).css({
                  'background-color': '#6954c3',
                  'color': 'white'
               })
            }
         }
         // if there is no '.fc-dragging' Css class found that means that drop action has been performed 
         // and the highlighted column style must be reset to its original state
         else {
            jQuery(this._highlightedColumn).removeAttr('style');
            // if that column was previously selected then the selected style must be reset
            if (this._highlightedColumnHeader && jQuery(this._highlightedColumnHeader).hasClass('selectedDayUl')) {
               jQuery(this._highlightedColumnHeader).css({
                  'background-color': '#34BF84',
                  'color': 'white'
               });
            }
            else {
               if (shadow.length === 0) {
                  jQuery(this._highlightedColumnHeader).css({
                     'background-color': 'transparent',
                     'color': '#515151'
                  })
               }
            }
         }

         /*************** End Drag&Drop logic **********************/
      }
   }

   // Goes back to the previous date depending on the current calendar view.
   public back(fc) {
      fc.gotoDate(this.currentDate);
      fc.prev();
      if (this.currentView === 'agendaWeek') {
         this.currentDate.subtract(1, 'week');
      }
   }

   // Goes next to the previous date depending on the current calendar view.
   public next(fc) {
      fc.gotoDate(this.currentDate);
      fc.next();
      if (this.currentView === 'agendaWeek') {
         this.currentDate.add(1, 'week');
      }
   }

   // Goes to today's date.
   public today(fc) {
      fc.today();
      this.currentDate = moment();
   }


   public goToWeekView() {
      if (this.javaScriptCalendar) {
         // if the calendar is on 'Week View' mode and refreshing it is tried, then nothing happens.
         // We need to clear the view in order to refresh the Week View
         this.javaScriptCalendar.clearView();
      }
      this.gotoWeek(this.currentDate);
   }

   public goToCycleView() {
      this.currentView = 'cycleView';
      this.isWeekViewSelected = false;
      if (this.jQueryCalendar) {
         this.jQueryCalendar.changeView('cycleView');
      }
      this.setScroll(null);
   }

   // Goes to the specific date passed as a parameter using the agendaWeek view.
   public gotoWeek(initialDate) {
      this.currentView = 'agendaWeek';
      this.isWeekViewSelected = true;
      if (this.jQueryCalendar) {
         this.jQueryCalendar.changeView('agendaWeek');
         this.jQueryCalendar.gotoDate(initialDate);
      }
      if (this.javaScriptFullCalendarView) {
         // After going to the week view for that specific initialDate
         // the currentDate is updated to the first visible day of the week view
         
         // in order not to break the next()/back() method internal work outs
         this.currentDate = this.javaScriptFullCalendarView.start;
         this.selectedCycleWeek = this.javaScriptFullCalendarView.start.diff(this.initialDate, 'w') + 1;
      }
      this.setScroll(null);
   }

   public changeCurrentView(arg) {
      if (!arg.checked) {
         this.goToCycleView();
      }
      else {
         this.goToWeekView();
      }
   }

   // Triggered when a new date-range is rendered, or when the view type switches.
   public handleViewRender(arg) {
      //var t0 = performance.now();
      let element = arg.element;  // It's a jQuery element for the container of the new view.
      let view = arg.view; // current JavaScript view object from Full Callendar Js.
      this.javaScriptFullCalendarView = view;
      this.javaScriptCalendar = view.calendar;
      this.jQueryCalendarElement = element;
      this.calendarTitle = view.title;

      if (this.currentDate == null) {
         return;
      }

      this.setScroll(element);

      if (view.name === 'agendaWeek') {
         this.selectedCycleWeek = view.start.add(1, 'd').diff(this.initialDate, 'w') + 1;
      }

      let startDate: moment.Moment = this.currentView === 'cycleView'
         ? moment(this._startDate)
         : moment(this._startDate).add(this.selectedCycleWeek - 1, 'w');
      
      // Render the day header buttons.
      this.renderHeaderButtons(element, startDate, view);

      // Customises the cycleView modifing the calendar table width depending on the cycle length.
      this.renderCycleView(element, startDate, view);

      // Workout which day needs to be selected and add selection handlers.
      this.renderDaySelection(element, view);

      if (!this.isFullCalendarMaximised) {
         let calendarGrid: any = this.jQueryCalendarElement.find('.fc-body');
         if (calendarGrid) {
            let fcBodyDiv: HTMLElement = calendarGrid[0];
            calendarGrid[0].style.display = 'none';
            return;
         }
      }

      //Key method to resize the calendar
      view.updateSize(true);
      this.calendarRendered.emit();
      //var t1 = performance.now();
      //console.log("handleViewRender took " + (t1 - t0) + " milliseconds.");
   }

   public cycleWeekChanged(arg) {
      this.numberOfWeeks = arg.currentTarget.value;
      let cycleStartDate: moment.Moment = moment(this.options.views.cycleView.visibleRange.start, 'YYYY-MM-DD');
      this.options.views.cycleView.visibleRange.end = cycleStartDate.clone().add(this.numberOfWeeks, 'w').format('YYYY-MM-DD');
      this.goToCycleView();
   }

   // Resizes the calendar content according to a fixed height.
   public setScrollWithFixHeight(height: number) {
      this.redrawCalendar(null, height);
      if (this.javaScriptFullCalendarView) {
         this.javaScriptFullCalendarView.updateSize(true);
      }
      this.setClosedDatesInCalendar();
   }

   // Resizes the calendar content according to the available space.
   public setScroll(view) {
      this.redrawCalendar(view, -1)
      // Adjusting the axis grid to avoid double lines
      let fc_axis: NodeList = document.querySelectorAll('.fc-content-skeleton .fc-axis');
      if (fc_axis && fc_axis.length > 0) {
         (<HTMLElement>fc_axis[0]).style.width = '39px';
      }
      if (this.javaScriptFullCalendarView) {
         this.javaScriptFullCalendarView.updateSize(true);
      }
      this.setClosedDatesInCalendar();
   }

   public applyScrollTop() {
      jQuery(this.jQueryCalendar.schedule.find('.fc-scroller')).scrollTop(this.scrollTop);
   }

   // Handles the click event done on a visit. Highligth the day which the visit belongs to.
   // It also changes de style of the selected visit to be distinguish from the others.
   public handleEventClick(e) {
      // Gets the selected visit from the JQuery FullCalendar control.
      let selectedVisit: any = this.javaScriptCalendar.clientEvents().
         find(item => item.callpointId === e.calEvent.callpointId &&
            item.start >= e.calEvent.start && item.start <= e.calEvent.end && item.eventType == EventTypes.visit)

      if (selectedVisit) {
         this.selectFullCalendarVisit(selectedVisit, !selectedVisit.isSelected);
      }
      else if (e.calEvent.eventType === EventTypes.project) {
         this.event = Event.createProjectEvent(e);
         if (this.event) {
            this.eventSelected.emit(this.event);
         }
      }

   }

   public selectDay(date: Date, clearSelectedVisit?: boolean) {
      if (this.jQueryCalendarElement && date != null) {
         let dayDate = moment(date, moment.ISO_8601);
         let dayCalendarColumns = this.jQueryCalendarElement.find('.' + this.javaScriptFullCalendarView.start);
         // Gets the html day column for the selected event date
         let dayCalendarColumn = dayCalendarColumns.toArray().find(item => {
            let itemDate: moment.Moment = moment(item.Id, moment.ISO_8601);
            return (itemDate.date() === dayDate.date()) &&
               (itemDate.month() === dayDate.month()) &&
               (itemDate.year() === dayDate.year())
         });
         if (dayCalendarColumn) /* DRAG&DROP check*/ {
            this.selectCalendarDayColumn(this.jQueryCalendarElement, dayCalendarColumns, dayCalendarColumn, dayDate);
            if (clearSelectedVisit) {
               // Triggering the visitSelected event to notify the parent component
               this.visitSelected.emit(null);
            }
         }
      }
   }

   // This methods reselects the previously selected day after the user selects a different caller.
   // The selectDay method cannot be used for this since it contains the logic to de-highlight the day
   // if it is the same day for example when the user clicks on a day to highlight it and then clicks it again to
   // deselect it. 
   public reselectSameDay(date: Date, clearSelectedVisit?: boolean) {
      if (this.jQueryCalendarElement) {
         let dayDate = moment(date);
         let dayCalendarColumns = this.jQueryCalendarElement.find('.' + this.javaScriptFullCalendarView.start);
         // Gets the html day column for the selected event date
         let dayCalendarColumn = dayCalendarColumns.toArray().find(item => {
            let itemDate: moment.Moment = moment.utc(item.id);
            return (itemDate.date() === dayDate.date()) &&
               (itemDate.month() === dayDate.month()) &&
               (itemDate.year() === dayDate.year())
         });
         if (dayCalendarColumn) /* DRAG&DROP check*/ {
            this.selectSameCalendarDayColumn(this.jQueryCalendarElement, dayCalendarColumn, dayDate);
            if (clearSelectedVisit) {
               // Triggering the visitSelected event to notify the parent component
               this.visitSelected.emit(null);
            }
         }
      }
   }

   public selectVisit(selectedVisit: any, newValue: boolean) {
      if (this.javaScriptCalendar && selectedVisit) {
         // Gets the selected visit from the JQuery FullCalendar control.
         let calendarVisit: any = this.javaScriptCalendar.clientEvents().
            find(item => item.callpointId === selectedVisit.callpointId &&
               item.start >= selectedVisit.start && item.start <= selectedVisit.end && item.eventType == EventTypes.visit)

         if (calendarVisit) {
            this.selectFullCalendarVisit(calendarVisit, newValue);
         }
      }
   }

   // Minimases the calendar to its headers hiding the grid calendar
   public minimiseCalendarToHeaders(event: any) {
      this.isFullCalendarMaximised = event.checked;
      if (this.javaScriptFullCalendarView.name === 'agendaWeek') {
         this.goToWeekView();
      }
      else {
         this.goToCycleView();
      }
      this.showCalendarHeadersOnlyChanged.emit();
   }

   /** HELPER METHODS */
   private renderHeaderButtons(element: any, startDate: moment.Moment, view: any) {
      let spanColumHeaders = element.find('.fc-day-header').find('span');
      let columnIndex = 0;
      let dayButtonId = startDate.clone();

      // Add the buttons to the column headers.
      spanColumHeaders.toArray().forEach(columnHeader => {
         dayButtonId = startDate.clone().add(columnIndex, 'days');

         // Different elements are added to the header depending on the selected view.
         if (this.currentView !== 'cycleView') {
            if (this.canSelectDayOfWeek) {
               let style: string;
               this.selectedDate != null && this.selectedDate.isSame(dayButtonId)
                  ? style = "style='background-color: #34BF84; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'"
                  : style = "style='text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'";
               jQuery(columnHeader).
                  before(
                     `<div class='ui-widget'>
                        <table>
                           <tbody>
                              <tr>
                                 <td style='border:none'>
                                    <button type='button' id='${dayButtonId.toString()}' class='btn cs-btn-secondary callsmart-calendar-button-week-header ${view.start}' ${style} >
                                       ${jQuery(columnHeader).text()}
                                    </button>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>`
                  );
            }
            else {
               let style: string;
               jQuery(columnHeader).
                  before(
                     `<div class='ui-widget'>
                        <table>
                           <tbody>
                              <tr>
                                 <td style='border:none;text-align: left;'>
                                    <label id='${dayButtonId.toString()}' class='cs-calendar-day-of-week-header-label ${view.start}' ${style} >
                                       ${jQuery(columnHeader).text()}
                                    </label>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>`
                  );
            }
            jQuery(columnHeader).remove();
         }
         columnIndex++;
      });
      return columnIndex;
   }

   private renderCycleView(element: any, startDate: moment.Moment, view: any) {
      if (this.currentView === 'cycleView') {
         // cycles bigger than 12 week needs extra styling.
         if (this.numberOfWeeks > 12) {
            element[0].style.width = '100%';
            element[0].style.overflowX = 'scroll';
            element[0].style.overflowY = 'hidden';
            let numberOfWeeksOver12 = this.numberOfWeeks - 12;
            // Every extra column will be 8% wide regarding the 12 week cycle
            let newWidth = (numberOfWeeksOver12 * 8) + 100;
            element.find('table').first().css({
               'width': newWidth + '%'
            });
         }
         element.find('.fc-day-header').find('span').css({
            'font-size': '8px'
         });

         // Adds the weekly headers to group the cycle days.
         let cycleHeader: string;
         for (let week = 1; week <= this.numberOfWeeks; week++) {
            // Adds the row to the table.
            if (week === 1) {
               cycleHeader = "<tr><td style='width: 41px; border:none;'></td>";
            }
            // New table header is added with the weekly button inside.
            cycleHeader = cycleHeader.concat(
               `<th colspan='7' class='cycle-week-header-cell'>
                  <button type='button' class='btn cs-btn-secondary callsmart-calendar-button-cycle-header' id='week${week}' 
                     style='${(this.selectedCycleWeek === week && this.selectedDate) ? "background-color: #34BF84;" : "background-color: #E8E8E8;"}'>
                        Week ${week}
                  </button>
               </th>`);

            // Closes the new row.
            if (week === this.numberOfWeeks) {
               cycleHeader = cycleHeader.concat('</tr>');
            }
         }
         element.find('.fc-head').find('thead').find('tr').before(cycleHeader);

         // Adds the anchors to the headers which represent a week
         for (let week = 1; week <= this.numberOfWeeks; week++) {
            // Adds the click event to the weekly links
            element.find('#week' + week).click(function () {
               let weekToMove: moment.Moment = this.initialDate.clone().add(week - 1, 'w');
               //this.currentDate = weekToMove;
               this.gotoWeek(weekToMove);
               this.selectedCycleWeek = week;
            }.bind(this));
         }

         // Gets every column header text and splits it into 'day of the week' and 'day number',
         // then sets that values within an UL tag.
         let columnIndex = 0;
         let headers = element.find('.fc-day-header').find('span');
         headers.toArray().forEach(header => {
            // Builds the following structure.
            //    <ul>
            //       <li>Tuesday</li>
            //       <li>25</li>
            //    </ul>
            let text: string = header.innerHTML;
            // the current content of the <span> is emptied;
            jQuery(header).empty();
            let cycleDayLinkId = startDate.clone().add(columnIndex, 'days');
            //creates the <ul></ul> to be added with 2 elements <li>day of week</li><li>date</li>
            let style: string = '';
            let selectedUlClass: string = '';

            if (this.selectedDate && this.selectedDate.isSame(cycleDayLinkId)) {
               style = 'background-color : #34BF84;color: white';
               //DRAG&DROP: Adding '.selectedDayUl' to the header **********
               selectedUlClass = 'selectedDayUl';
            }
            let openingtag = this.canSelectDayOfWeek ? '<a' : '<label';
            let newHtmlContent: string = `${openingtag} id='${cycleDayLinkId.toString()}' class='week-header ${view.start}' ><ul style='margin: 0; padding: 0; ${style}' class='${selectedUlClass}'>`;

            let arrayTextColumn = text.split(' ');
            arrayTextColumn.forEach(word => {
               newHtmlContent = `${newHtmlContent}<li style='list-style-type: none; margin: 0; padding: 0;'>${word}</li>`;
            });
            let closingtag = this.canSelectDayOfWeek ? '</a>' : '</label>';
            newHtmlContent = `${newHtmlContent}</ul>${closingtag}`;
            // appends the new content to the <span></span>
            jQuery(header).append(newHtmlContent);
            columnIndex++;
         });

         // Scrolls the calendar horizontally to the previous selected cycle week.
         if (this.selectedCycleWeek) {
            let anchorItem = element.find('#week' + this.selectedCycleWeek);
            if (anchorItem) {
               // Gets the anchor item position in the table.
               let anchorPosition = anchorItem.position().left;
               // Gets the width of the calendar control.
               let controlWidth = element.width();
               // If the ancher position is beyond the middle point of the calendar...
               if (anchorPosition > (controlWidth / 2)) {
                  // the scroll is moved to reach the anchor item and is set
                  // in the middle point of the visible area of the calendar.
                  let scrollOffset = anchorPosition - (controlWidth / 2);
                  element.stop().animate({ scrollLeft: scrollOffset }, 1000);
               }
            }
         }
      }
   }

   private renderDaySelection(element: any, view: any) {
      let days = element.find('.' + view.start);
      // Updates/Initialises the event style for all days when users has made a selection previously
      // and the calendar is being rendered.
      days.toArray().forEach(day => {
         let dayDate: moment.Moment = moment(day.id).clone();

         // Gets the date range which covers a day selection.
         // Not using MomentJs objects because the event start/end dates are Date objects
         let iniDate: Date = new Date(day.id);
         let endDate: Date = new Date(iniDate);
         endDate.setDate(iniDate.getDate() + 1);

         //Resets the the styles for all events visible on the new view.
         let isSelectedDay: boolean = (this.selectedDate && this.selectedDate.isSame(iniDate, 'day'));
         this.setEventState(isSelectedDay, view.calendar, iniDate, endDate);

         // Onclick event definition for a header element which will cause the highlighting of that day.
         day.onclick = function () {
            if (!this.canSelectDayOfWeek) {
               return;
            }
            this.selectCalendarDayColumn(element, days, day, dayDate);
            this.visitSelected.emit(null);
         }.bind(this);
      });
   }

   private selectFullCalendarVisit(selectedVisit: any, newValue: boolean) {
      // If the selected event is not a Visit then nothing is done
      if (!selectedVisit) {
         return;
      }
      // Sets the isSelected property.
      selectedVisit.isSelected = newValue;
      // Sets the new Css class.
      this.setClassName(selectedVisit);
      // Updates the visit with the new Css class.
      this.javaScriptCalendar.updateEvent(selectedVisit);

      // Gets the selected Date in UTC format, which is the same format of the Id of a day column in the calendar
      let dayDate: moment.Moment = moment([selectedVisit.start.year(), selectedVisit.start.month(), selectedVisit.start.date()]).clone();
      // Gets all html day columns from the calendar
      let dayCalendarColumns = this.jQueryCalendarElement.find('.' + this.javaScriptFullCalendarView.start);
      // Gets the html day column for the selected event date
      let dayCalendarColumn = dayCalendarColumns.toArray().find(item => {
         let itemDate: moment.Moment = moment.utc(item.id);
         return (itemDate.date() === dayDate.date()) &&
            (itemDate.month() === dayDate.month()) &&
            (itemDate.year() === dayDate.year())
      });
      // Gets the date range which covers a day selection.
      // Not using MomentJs objects because the event start/end dates are Date objects
      let iniDate: Date
      if (dayCalendarColumn) {
         iniDate = new Date(dayCalendarColumn.id);
      }
      else {
         // The highlighted visit is not included in the current weekly view
         iniDate = dayDate.toDate();
      }
      let endDate: Date = new Date(iniDate);
      endDate.setDate(iniDate.getDate() + 1);

      // if a visit is selected when another visit is already selected within the same day,
      // then the hightlighted day doesn't change... only the old selected visit is deselected.
      let oldSelectedEvent: any =
         this.javaScriptCalendar.clientEvents().find(item => item.start.toDate() >= iniDate &&
            item.start.toDate() <= endDate &&
            item.isSelected &&
            !item.start.isSame(selectedVisit.start));
      if (oldSelectedEvent) {
         oldSelectedEvent.isSelected = false;
         this.setClassName(oldSelectedEvent);
         this.javaScriptCalendar.updateEvent(oldSelectedEvent);
      }
      else {
         if (!this.javaScriptCalendar.clientEvents().find(item => item.start.toDate() >= iniDate &&
            item.start.toDate() <= endDate &&
            item.isHighlighted)) {
            // Highligths the calendar day column.
            this.selectCalendarDayColumn(this.jQueryCalendarElement, dayCalendarColumns, dayCalendarColumn, dayDate);
         }
      }

      /** Working out the drive time duration **/

      // if the clicked event is also selected then the previous driving time is searched.
      // Gets the previous driving events to the selected visits in that day
      let travelEvents = this.javaScriptCalendar.clientEvents()
         .filter(item =>
            selectedVisit.start.diff(item.end) >= 0 &&
            item.start.toDate() > iniDate &&
            (item.eventType == EventTypes.travelTime || item.eventType == EventTypes.commute));

      let nearestDrivingEvent;
      let minDiffTime: number;
      // Gets the closest driving event to the selected visit
      travelEvents.forEach(drivingEvent => {
         let diff = selectedVisit.start.diff(drivingEvent.end);
         if (!minDiffTime || diff < minDiffTime) {
            minDiffTime = diff;
            nearestDrivingEvent = drivingEvent;
         }
      });
      // Gets the duration of that driving event
      let driveTimeDuration: number;
      if (nearestDrivingEvent) {
         driveTimeDuration = nearestDrivingEvent.end.diff(nearestDrivingEvent.start, 'm')
      }

      /** Working out the phase */
      let phase: number;
      let callpointVisits: any[] = this.javaScriptCalendar.clientEvents().filter(item => item.callpointId === selectedVisit.callpointId);
      let sortedCallpointVisits: any[] = callpointVisits.sort(this.compareMomentDates('start', 'asc'))
      phase = sortedCallpointVisits.findIndex(visit => visit.start.diff(selectedVisit.start) === 0);
      // Triggering the visitSelected event to notify the parent component
      let visitSelection: VisitSelection = new VisitSelection();

      // We dont use the FullCallendar JS native Event object... We use our Event entity
      let selectedEvent: Event = this._events.find(item => moment(item.start).diff(selectedVisit.start) === 0)
      selectedEvent.isSelected = selectedVisit.isSelected;
      visitSelection.selectedVisit = /*selectedVisit*/selectedEvent;
      visitSelection.driveTime = driveTimeDuration;
      visitSelection.phase = phase + 1;
      this.visitSelected.emit(visitSelection);
   }

   // Highlights the column day which contains selected events (already highlighted).
   private highlightDayColumn(calendar: any, viewName: string, top: number, bottom: number) {
      let borderWidth: number = viewName === 'cycleView' ? 1 : 2;

      let hightlightDay = jQuery(calendar.find('.highlight')[0]).parent().siblings('.fc-highlight-container');
      if (hightlightDay.length > 0) {
         hightlightDay.css({
            'border': borderWidth + 'px solid #34bf84',
            'top': top + 'px',
            'bottom': bottom + 'px',
            'position': 'absolute',
            'width': '100%'
         });
      }
   }

   // Removes (deselects) the selected style for all events contained by the calendar.
   private removeAllHighlightedDayColums(calendar: any) {
      let highlightelements = jQuery(calendar.find('.fc-highlight-container'));
      if (highlightelements) {
         highlightelements.css({
            'border-color': 'transparent',
            'top': '0 px',
            'bottom': '0 px',
            'position': 'absolute',
            'width': '100%'
         });
      }
   }

   // Retrieves the event collection between two dates and change the state of all items
   // based on the isSelected parameter. Changing the state of a event implies changing the
   // css class related.
   private setEventState(isSelected: boolean, calendar: any, ini: Date, end: Date) {

      let checkedEvents: any[] = calendar.clientEvents().filter(item => item.start >= ini && item.start <= end)
      checkedEvents.forEach(item => {
         // Sets event states
         item.isHighlighted = isSelected;
         // if the events are turned to normal state then we deselect the selected one in that day
         if (!isSelected) {
            item.isSelected = isSelected;
         }
         this.setClassName(item);
      });
      if (this.javaScriptCalendar) {
         this.javaScriptCalendar.updateEvents(checkedEvents);
      }
   }

   // Sets the css class related to an event depending on its Type and wether it is selected or not.
   private setClassName(event: any) {
      switch (event.eventType) {
         case EventTypes.visit: {
            if (event.isSelected) {
               switch (event.locking) {
                  case LockingTypes.day:
                     event.className = 'callsmart-visit-event-selected-lock-to-day';
                     break;
                  case LockingTypes.dayAndTime:
                     event.className = 'callsmart-visit-event-selected-lock-to-day-time';
                     break;
                  default:
                     event.className = 'callsmart-visit-event-selected';
                     break;
               }
            }
            else if (event.isHighlighted) {
               switch (event.locking) {
                  case LockingTypes.day:
                     event.className = 'callsmart-visit-event-highlight-lock-to-day';
                     break;
                  case LockingTypes.dayAndTime:
                     event.className = 'callsmart-visit-event-highlight-lock-to-day-time';
                     break;
                  default:
                     event.className = 'callsmart-visit-event-highlight';
                     break;
               }
            }
            else {
               switch (event.locking) {
                  case LockingTypes.day:
                     event.className = 'callsmart-visit-event-normal-lock-to-day';
                     break;
                  case LockingTypes.dayAndTime:
                     event.className = 'callsmart-visit-event-normal-lock-to-day-time';
                     break;
                  default:
                     event.className = 'callsmart-visit-event-normal';
                     break;
               }
            }
            break;
         }
         case EventTypes.commute: {
            event.className = event.isHighlighted ? 'callsmart-commute-event-highlight highlight' : 'callsmart-commute-event-normal';
            break;
         }
         case EventTypes.lunch: {
            event.className = event.isHighlighted ? 'callsmart-lunch-event-highlight highlight' : 'callsmart-lunch-event-normal';
            break;
         }
         case EventTypes.travelTime: {
            event.className = event.isHighlighted ? 'callsmart-traveltime-event-highlight highlight' : 'callsmart-traveltime-event-normal';
            break;
         }
         case EventTypes.project: {
            event.className = event.isHighlighted ? 'callsmart-project-event-highlight highlight' : 'callsmart-project-event-normal';
            break;
         }
         case EventTypes.overnight: {
            event.className = event.isHighlighted ? 'callsmart-overnight-event-highlight highlight' : 'callsmart-overnight-event-normal';
            break;
         }
      }
   }

   // Resizes the calendar content to have all the events visible.
   // fixHeight: This parameter set the calendar visible height area.
   //            -1 means the height is set when the calendar resizes itself.
   private redrawCalendar(view, fixHeight: number) {
      if ((this.jQueryCalendar && this.jQueryCalendar.schedule) || view) {

         // Set some default values as this method is called before the Inputs() have initialised.
         let earliestStartTime = "08:00";
         let latestEndTime = "18:00";

         // Determine the earliest start time and the latest end time and use those to resize the calendar grid.
         // Get the earliest start time.
         if(this._inputStartTime) {
            if(this._inputStartTime.includes('|')) {
               let startTime: string[] = this._inputStartTime.split('|');
               startTime.sort();
               earliestStartTime = startTime[0];
            }
            else {
               earliestStartTime = this._inputStartTime;
            }
         }
         
         // Get the latest end time.
         if(this._inputEndTime) {
            if(this._inputEndTime.includes('|')) {
               let endTime: string[] = this._inputEndTime.split('|');
               endTime.sort();
               latestEndTime = endTime[endTime.length-1];
            }
            else {
               latestEndTime = this._inputEndTime;
            }
         }
         
         // Works out the earliest time within the all events.
         let extraDuration = moment.duration({ minutes: this.diaryViewTime });
         // Gets the start working time.
         let earliestTime: moment.Moment = moment('2010-01-01 ' + earliestStartTime, 'YYYY-MM-DD HH:mm');
         let y: moment.Moment = earliestTime.subtract(extraDuration);
         let startHour = y.diff(moment('2010-01-01', 'YYYY-MM-DD'), 'hours', true);
         // Gets the end working time.
         let latestTime: moment.Moment = moment('2010-01-01 ' + latestEndTime, 'YYYY-MM-DD HH:mm');
         let y2: moment.Moment = latestTime.add(extraDuration);
         let lastHour = y2.hour();
         // Work out the visible time.
         let totalHoursVisible = y2.diff(y, 'hours', true)

         // Works out the height per row.
         let visibleCalendarHeight: number
         if (fixHeight === -1) {
            if (document.getElementsByClassName('fc-time-grid-container').length > 0) {
               visibleCalendarHeight = document.getElementsByClassName('fc-time-grid-container')[0].clientHeight;
            }
         }
         else {
            // Fixed height
            visibleCalendarHeight = fixHeight;
         }
         this._heightPerRow = (visibleCalendarHeight - 14) / (totalHoursVisible * 2);

         // Sets the scroolTop property to scroll down the calendar to the right position.
         this.scrollTop = this._heightPerRow * startHour * 2; // each row represents half an hour
         if (this.jQueryCalendar && this.jQueryCalendar.schedule) {
            setTimeout(() => {
               jQuery(this.jQueryCalendar.schedule.find('.fc-scroller')).scrollTop(this.scrollTop);
            }, 0.1);
         }
         else {
            view.find('.fc-scroller').scrollTop(this.scrollTop);
         }

         // Gets font size depending on the height per row.
         let fontSize;
         if (this._heightPerRow > 25) {
            fontSize = 12;
         }
         else if (this._heightPerRow < 6) {
            fontSize = 3;
         }
         else if (this._heightPerRow < 8) {
            fontSize = 4;
         }
         else if (this._heightPerRow < 10) {
            fontSize = 6;
         }
         else if (this._heightPerRow < 12) {
            fontSize = 7;
         }
         else if (this._heightPerRow < 15) {
            fontSize = 8;
         }
         else if (this._heightPerRow < 20) {
            fontSize = 10;
         }
         else if (this._heightPerRow < 25) {
            fontSize = 12;
         }

         // Sets the new style to each row of the calendar
         if (this.jQueryCalendar && this.jQueryCalendar.schedule) {
            let elements = jQuery(this.jQueryCalendar.schedule.find('.fc-slats').find('.ui-widget-content'))
            elements.css({
               'height': this._heightPerRow + 'px',
               'font-size': fontSize + 'px'
            });
            let timesCells = jQuery(this.jQueryCalendar.schedule.find('.fc-axis.fc-time.ui-widget-content').find('span'));
            timesCells.css({
               'width': 30 + 'px',
               'display': 'inline-block'
            });
         }
         else {
            view.find('.fc-slats').find('.ui-widget-content').css({
               'height': this._heightPerRow + 'px',
               'font-size': fontSize + 'px'
            })
            view.find('.fc-axis.fc-time.ui-widget-content').find('span').css({
               'width': 30 + 'px',
               'display': 'inline-block'
            })
         }
      }
   }

   // Gets the earliest and latest working time taking into account all events for an specific caller.
   // Not used since the visible time must be set according to the working hours values
   private getEarliestAndLatestWorkingTimeInSchedule() {
      let earliestTime: Date;
      let latestTime: Date;
      let index = 0;
      if (this.events.length > 0) {
         this.events.forEach(event => {
            let startHours = event.start.getHours();
            let startMinutes = event.start.getMinutes();
            let endHours = event.end.getHours();
            let endMinutes = event.end.getMinutes();
            if (index == 0) {
               earliestTime = new Date('Jan 1, 2010 ' + startHours + ':' + startMinutes);
               latestTime = new Date('Jan 1, 2010 ' + endHours + ':' + endMinutes);
            }
            else {
               // only interested in times... so the date is mocked
               let startEventTime = new Date('Jan 1, 2010 ' + startHours + ':' + startMinutes);
               let endEventTime = new Date('Jan 1, 2010 ' + endHours + ':' + endMinutes);

               earliestTime = startEventTime < earliestTime ? startEventTime : earliestTime;
               latestTime = endEventTime > latestTime ? endEventTime : latestTime;
            }
            index++;
         });

         return [earliestTime.getHours() + ':' + earliestTime.getMinutes(), latestTime.getHours() + ':' + latestTime.getMinutes()];
      }
      return null;
   }

   private clearCalendar() {
      // clear events
      if (!this._startDate) {
         this.initialDate = moment();
      }
      this.currentDate = this.initialDate.clone();
      // Configures the cycleView start and end dates for an empty calendar.
      this.options.views.cycleView.visibleRange.start = this.currentDate.format('YYYY-MM-DD');
      this.options.views.cycleView.visibleRange.end = this.currentDate.clone().add(this.numberOfWeeks, 'w').format('YYYY-MM-DD');
      this.options.firstDay = this.currentDate.day();
      this.events = [];
      this.header = false;
      this.isVisible = true;
   }

   // Initialises the whole calendar setting the new events and calendar properties.
   private loadSchedule(events: Event[]) {
      if (!events || events.length === 0) {
         // clear events
         this.clearCalendar();
         return;
      }

      this.events = null;
      this.isVisible = false;

      // Sets the css class to the displayed events
      let uiEvents: Event[] = events.slice();
      uiEvents.forEach(event => {
         this.setClassName(event);
      });

      // the 'Event.start' property is a normal JS Date not a moment object.
      // sets the initial date for the calendar.
      // checks if the startDate variable has been updated from the parent control...
      // if it has no value then the calendar starts showing the calendar with the first event start time in the list
      if (!this.startDate) {
         let iniDate = new Date(uiEvents[0].start);
         this.initialDate = moment(iniDate.getFullYear() + '-' +
            (iniDate.getMonth() + 1) + '-' +
            iniDate.getDate(), 'YYYY-MM-DD');
         this.currentDate = this.initialDate.clone();

         // Configures the cycleView start and end dates.
         // VisibleRange.end property must be bigger than visibleRange.start property.
         // Otherwise the JQuery fullcalendar blows up very badly.
         this.options.views.cycleView.visibleRange.start = this.currentDate.format('YYYY-MM-DD');
         this.options.views.cycleView.visibleRange.end = this.currentDate.clone().add(this.numberOfWeeks, 'w').format('YYYY-MM-DD');
         //this.options.firstDay = String(this.currentDate.day());
      }

      // Assigns the retrieved event collection from the server to the local property to populate the calendar.
      this.events = uiEvents;
      this.selectedCycleWeek = null;
      // The JQuery fullcalendar is build when all needed info is loaded,
      // then its visibility is set to true to be rendered correctly.
      this.isVisible = true;

      this.header = false;
      this.cd.detectChanges();
   }

   private isValidDate(date: Date) {
      if (Object.prototype.toString.call(date) === '[object Date]') {
         // it is a date
         if (isNaN(date.getTime())) {  // d.valueOf() could also work
            // date is not valid
            return false;
         }
         else {
            // date is valid
            return true;
         }
      }
      else {
         // not a date
         return false;
      }
   }

   private selectCalendarDayColumn(element: any, dayCalendarColumns: any, dayCalendarColumn: any, dayDate: moment.Moment) {
      if (!this.canSelectDayOfWeek) {
         return;
      }

      let ulDay = jQuery(dayCalendarColumn).find('ul');

      element.find('.callsmart-calendar-button-cycle-header').css({ 'background-color': '#E8E8E8' });

      let previousSelectedDate: moment.Moment;
      // Only one day can be selected at the same time...
      // So the highlighted style is removed from all visible days
      this.removeAllHighlightedDayColums(element);

      if (dayDate.isSame(this.selectedDate, 'day')) {
         // Clicking on a selected day will deselect it.
         this.selectedDate = null
         if (ulDay.length !== 0) {
            jQuery(ulDay).css({ 'background-color': '', 'color': '' });
            jQuery(ulDay).removeClass('selectedDayUl'); // Drag&Drop
         }
         else {
            jQuery(dayCalendarColumn).removeAttr('style');
         }

         let unselectedCycleWeek: number = dayDate.diff(this.initialDate, 'w') + 1;
         element.find('#week' + unselectedCycleWeek).css({ 'background-color': '#E8E8E8' });
      }
      else {
         // A new selection has been made.
         this.currentDate = moment(dayDate, moment.ISO_8601).startOf('day');
         if (this.selectedDate) {
            previousSelectedDate = this.selectedDate.clone().startOf('day');
         }
         this.selectedDate = this.currentDate.clone();
         // Adds the style attribute which contains the background color for a selected element header.
         if (ulDay.length !== 0) {
            jQuery(ulDay).css({ 'background-color': '#34BF84', 'color': 'white' });
            jQuery(ulDay).addClass('selectedDayUl'); // Drag&Drop
         }
         else {
            jQuery(dayCalendarColumn).css({ 'background-color': '#34BF84' })
         }

         this.selectedCycleWeek = this.selectedDate.diff(this.initialDate, 'w') + 1;
         element.find('#week' + this.selectedCycleWeek).css({ 'background-color': '#34BF84;' });
      }

      if (previousSelectedDate) {
         // Gets the previous selected date range which covers a day selection.
         // Not using MomentJs objects because the event start/end dates are Date objects
         let previousIniDate: Date = previousSelectedDate.toDate();
         let previousEndDate: Date = new Date(previousIniDate);
         previousEndDate.setDate(previousIniDate.getDate() + 1);
         this.setEventState(false, this.javaScriptCalendar, previousIniDate, previousEndDate);

         //let previousSelectedMomentJsDate: moment.Moment = moment(previousSelectedDate);
         let previousSelectedDay = dayCalendarColumns.toArray().filter(item => previousSelectedDate.isSame(moment(item.id), 'day'));
         if (previousSelectedDay.length === 1) {
            let ulElement = jQuery(previousSelectedDay).find('ul');
            if (ulElement.length !== 0) {
               jQuery(ulElement).css({ 'background-color': '', 'color': '' });
               jQuery(ulElement).removeClass('selectedDayUl'); // Drag&Drop
            }
            else {
               jQuery(previousSelectedDay).removeAttr('style');
            }

            let momentDayDate: moment.Moment = moment(previousSelectedDay[0].id).clone();
            let unselectedCycleWeek = momentDayDate.diff(this.initialDate, 'w') + 1;
            if (this.selectedCycleWeek != unselectedCycleWeek) {
               element.find('#week' + unselectedCycleWeek).css({ 'background-color': '#E8E8E8' });
            }
         }
      }

      // Gets the date range which covers a day selection.
      // Not using MomentJs objects because the event start/end dates are Date objects
      let iniDate: Date
      if (dayCalendarColumn) {
         iniDate = new Date(dayCalendarColumn.id);
      }
      else {
         // The highlighted visit is not included in the current weekly view
         iniDate = dayDate.toDate();
      }
      let endDate: Date = new Date(iniDate);
      endDate.setDate(iniDate.getDate() + 1);

      // Updates the state of the clicked day
      this.setEventState(this.selectedDate !== null, this.javaScriptCalendar, iniDate, endDate);

      // fire event with all the events for this day
      this.daySelected.emit({
         events: this.events.filter(item => item.start >= iniDate && item.start <= endDate),
         isSelected: this.selectedDate !== null,
         selectedDate: this.selectedDate ? this.selectedDate.toDate() : null
      });
   }

   private selectSameCalendarDayColumn(element: any, dayCalendarColumn: any, dayDate: moment.Moment) {
      if (!this.canSelectDayOfWeek) {
         return;
      }

      let ulDay = jQuery(dayCalendarColumn).find('ul');

      element.find('.callsmart-calendar-button-cycle-header').css({ 'background-color': '#E8E8E8' });

      // Only one day can be selected at the same time...
      // So the highlighted style is removed from all visible days
      this.removeAllHighlightedDayColums(element);

      // A new selection has been made.
      this.currentDate = moment(dayDate).startOf('day');
      this.selectedDate = this.currentDate.clone();
      // Adds the style attribute which contains the background color for a selected element header.
      if (ulDay.length !== 0) {
         jQuery(ulDay).css({ 'background-color': '#34BF84', 'color': 'white' });
         jQuery(ulDay).addClass('selectedDayUl'); // Drag&Drop
      }
      else {
         jQuery(dayCalendarColumn).css({ 'background-color': '#34BF84' })
      }

      this.selectedCycleWeek = this.selectedDate.diff(this.initialDate, 'w') + 1;
      element.find('#week' + this.selectedCycleWeek).css({ 'background-color': '#34BF84;' });

      // Gets the date range which covers a day selection.
      // Not using MomentJs objects because the event start/end dates are Date objects
      let iniDate: Date
      if (dayCalendarColumn) {
         iniDate = new Date(dayCalendarColumn.id);
      }
      else {
         // The highlighted visit is not included in the current weekly view
         iniDate = dayDate.toDate();
      }
      let endDate: Date = new Date(iniDate);
      endDate.setDate(iniDate.getDate() + 1);

      // Updates the state of the clicked day
      this.setEventState(this.selectedDate !== null, this.javaScriptCalendar, iniDate, endDate);

      // fire event with all the events for this day
      this.daySelected.emit({
         events: this.events.filter(item => item.start >= iniDate && item.start <= endDate),
         isSelected: this.selectedDate !== null,
         selectedDate: this.selectedDate ? this.selectedDate.toDate() : null
      });
   }

   private compareMomentDates(key, order = 'asc') {
      return function (a, b) {
         if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
         }

         const varA: moment.Moment = a[key];
         const varB: moment.Moment = b[key];

         let comparison = 0;
         if (varA.diff(varB) > 0) {
            comparison = 1;
         } else if (varA.diff(varB) < 0) {
            comparison = -1;
         }

         return (
            (order == 'desc') ? (comparison * -1) : comparison
         );
      };
   }

   // Sets the visits to 'draggable' depending on the value passed as a parameter
   private setVisitsDraggable(isDraggable: boolean) {
      // The collection of visits (Callsmart Event objects) is updated
      this.events.forEach(event => {
         if (event.eventType === EventTypes.visit) {
            event.startEditable = isDraggable;
         }
      })
      // The internal visits used by the JQuery FullCalendar are also updated.
      if (this.javaScriptCalendar) {
         // Gets the internal visits from the FullCalendar.
         let internalEvents: any[] = this.javaScriptCalendar.clientEvents().filter(fullCalendarEvent => fullCalendarEvent.eventType === EventTypes.visit);
         // Changes the startEditable property to all FullCalendar visits.
         internalEvents.forEach(visit => visit.startEditable = isDraggable);
         // Updates all modified visits.
         this.javaScriptCalendar.updateEvents(internalEvents);
      }
   }

   private setClosedDatesInCalendar() {
      let backgroundColumnDays: NodeList = document.querySelectorAll('.fc-business-container');
      let columnDays: NodeList = document.querySelectorAll('.fc-day');

      // Cycle view and number of columndays is different to number of datesClosed array items => stop
      if ((this.currentView === 'cycleView' && (!columnDays || !this.datesClosed || columnDays.length !== this.datesClosed.length)) ||
         (this.currentView === 'agendaWeek' && ( (this.datesClosed && this.datesClosed.length === 0) ||  !this.datesClosed) )) {
         return;
      }

      for (let index: number = 0; index < columnDays.length; index++) {
         let dayDate: moment.Moment = moment((<HTMLElement>columnDays[index]).dataset.date, 'YYYY-MM-DD');
         let indexInCurrentView: number = dayDate.diff(moment(this.startDate), 'days')

         if (indexInCurrentView >= 0) {
            switch (this.datesClosed[indexInCurrentView].toLowerCase()) {
               case 'x':
                  this.renderDayClosed(backgroundColumnDays, index);
                  break;
               case 'o':
                  this.renderDayOpen(indexInCurrentView, backgroundColumnDays, index);
                  break;
               case '1':
                  this.renderDayOpenPart(indexInCurrentView, backgroundColumnDays, index, true);
                  break;
               case '2':
                  this.renderDayOpenPart(indexInCurrentView, backgroundColumnDays, index, false);
                  break;
            }
         }
      }
   }
   
   private renderDayClosed(backgroundColumnDays: NodeList, index: number) {
      let nonWorkingTimeDivs = jQuery(backgroundColumnDays[index]).find('.fc-nonbusiness.fc-bgevent');
      if (nonWorkingTimeDivs.length > 0) {
         let newBottomSecondNonBusinessDiv = this.getCalendarScheduleHeight();
         // Add a new non working time divs at the top and at the bottom
         // leaving the working hours free of divs (in that way the dark gray backgroun is visible)
         let topDiv = `<div class='fc-nonbusiness fc-bgevent' style='top: 0px; bottom: -${newBottomSecondNonBusinessDiv}px'></div>`;
         jQuery(nonWorkingTimeDivs[0]).before(topDiv);
      }

      this.removeNonWorkingTimeDivs(nonWorkingTimeDivs);
   }

   private renderDayOpen(indexInCurrentView: number, backgroundColumnDays: NodeList, index: number) {
      let indexInWeek: number = indexInCurrentView % 7;
      let noOf30MinuteSlots = this.getNoOf30MinSlots(indexInWeek);
      let halfDayOffsetValue = this.getFullDayOffset(indexInWeek);

      // Rounding the heightPerRow here is very important, it gives the perfect alignment in the view when the diary is resized
      // by splitter.
      let bottomFirstNonBusinessDivValue = Math.round(this._heightPerRow) * noOf30MinuteSlots;
      let topSecondNonBusinessDivValue = bottomFirstNonBusinessDivValue + halfDayOffsetValue;
      let bottomSecondNonBusinessDiv = this.getCalendarScheduleHeight();

      this.renderNonWorkingTimeDivs(backgroundColumnDays, index, bottomFirstNonBusinessDivValue, topSecondNonBusinessDivValue, bottomSecondNonBusinessDiv);
   }

   private renderDayOpenPart(indexInCurrentView: number, backgroundColumnDays: NodeList, index: number, firstPartOpen: boolean) {
      let indexInWeek: number = indexInCurrentView % 7;
      let noOf30MinuteSlots = this.getNoOf30MinSlots(indexInWeek);
      let halfDayOffsetValue = this.getHalfDayOffset(indexInWeek);

      // Rounding the heightPerRow here is very important, it gives the perfect alignment in the view when the diary is resized
      // by splitter.
      let bottomFirstNonBusinessDivValue = Math.round(this._heightPerRow) * noOf30MinuteSlots;

      if (!firstPartOpen) {
         bottomFirstNonBusinessDivValue += halfDayOffsetValue;
      }
      let topSecondNonBusinessDivValue = bottomFirstNonBusinessDivValue + halfDayOffsetValue;
      let bottomSecondNonBusinessDiv = this.getCalendarScheduleHeight();

      this.renderNonWorkingTimeDivs(backgroundColumnDays, index, bottomFirstNonBusinessDivValue, topSecondNonBusinessDivValue, bottomSecondNonBusinessDiv);
   }

   private renderNonWorkingTimeDivs(backgroundColumnDays: NodeList, index: number, bottomFirstNonBusinessDivValue: number,
         topSecondNonBusinessDivValue: number, bottomSecondNonBusinessDiv: number) {
      let nonWorkingTimeDivs = jQuery(backgroundColumnDays[index]).find('.fc-nonbusiness.fc-bgevent');
      if (nonWorkingTimeDivs.length > 0) {
         // Add 2 new non working time divs at the top and at the bottom
         // leaving the working hours free of divs (in that way the dark gray background is visible)
         let topDiv = `<div class='fc-nonbusiness fc-bgevent' style='top: 0px; bottom: -${bottomFirstNonBusinessDivValue}px'></div>`;
         let bottomDiv = `<div class='fc-nonbusiness fc-bgevent' style='top: ${topSecondNonBusinessDivValue}px; bottom: -${bottomSecondNonBusinessDiv}px'></div>`;

         jQuery(nonWorkingTimeDivs[0]).before(topDiv);
         jQuery(nonWorkingTimeDivs[0]).before(bottomDiv);
      }

      this.removeNonWorkingTimeDivs(nonWorkingTimeDivs);
   }

   private removeNonWorkingTimeDivs(nonWorkingTimeDivs: any) {
      for (let i: number = 0; i < nonWorkingTimeDivs.length; i++) {
         nonWorkingTimeDivs[i].remove();
      }
   }

   // Workout the number of 30 min slots from 00:00 to till star of business hours.
   private getNoOf30MinSlots(indexInWeek: number) {
      let xStart = moment('00:00', 'hh:mm');
      let xEnd = moment(this.businessHours[indexInWeek].start, 'hh:mm');
      let xDuration = moment.duration(xEnd.diff(xStart));
      let startTimeAsMinutes = xDuration.as('minutes');
      let noOf30MinuteSlots = startTimeAsMinutes / 30;
      return noOf30MinuteSlots;
   }

   // Get the value in pixels for the half day that is required.
   private getHalfDayOffset(indexInWeek: number) {
      // Work out the duration between start and end of business hours.
      let startTime = moment(this.businessHours[indexInWeek].start, 'hh:mm');
      let endTime = moment(this.businessHours[indexInWeek].end, 'hh:mm');
      let duration = moment.duration(endTime.diff(startTime));
      let durationAsMinutes = duration.as('minutes');
      
      // Work out the size of the business hours for half day in pixels.
      let noOf30MinuteSlots = durationAsMinutes / 30;
      let workingHoursDivValue = Math.round(this._heightPerRow) * noOf30MinuteSlots;
      return workingHoursDivValue / 2;
   }

   // Get the value in pixels for the half day that is required.
   private getFullDayOffset(indexInWeek: number) {
      // Work out the duration between start and end of business hours.
      let startTime = moment(this.businessHours[indexInWeek].start, 'hh:mm');
      let endTime = moment(this.businessHours[indexInWeek].end, 'hh:mm');
      let duration = moment.duration(endTime.diff(startTime));
      let durationAsMinutes = duration.as('minutes');
      
      // Work out the size of the business hours for half day in pixels.
      let noOf30MinuteSlots = durationAsMinutes / 30;
      let workingHoursDivValue = Math.round(this._heightPerRow) * noOf30MinuteSlots;
      return workingHoursDivValue;
   }

   // Get the height of the diary view in pixels.
   private getCalendarScheduleHeight(): number {
      return jQuery(this.jQueryCalendarElement.find('.fc-slats'))[0].clientHeight;
   }

   public updateEvent(event: Event) {
      let e = this.javaScriptCalendar.clientEvents().find(item => item.id === event.id)
      e.title = event.title;
      e.start = event.start;
      e.end = event.end;
      e.isAllDay = event.isAllDay;
      e.description = event.description;
      e.attendeesIds = event.attendeesIds;
      e.canRoll = event.canRoll;
      this.jQueryCalendar.updateEvent(e);
   }

   handleDayClick(event) {
      // this.event = new Event();
      // this.event.start = event.date.toDate();
      // this.dialogVisible = true;

      // //trigger detection manually as somehow only moving the mouse quickly after click triggers the automatic detection.
      // this.cd.detectChanges();

   }

   ngAfterContentChecked() {
      this.cd.detectChanges();
  }
}
