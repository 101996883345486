import { throwError as observableThrowError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { ReportParameters } from 'app/models/report-parameters';

// this service is used purely for data acess CRUD operations to the data base
// the service is normally called from with in a store
@Injectable()
export class AuditService {

   auditUrl = `${environment.baseUrl}api/audit`;

   public constructor(private _http: HttpClient) { }

   // run a report that is exported to CSV for auditing users logins for a date raneg
   public exportUserAccessForDataRange(params: ReportParameters) {
      let url: string = `${this.auditUrl}/useraccess`;

      return this._http.post(url, params, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   // run a report that is exported to CSV for auditing executions of optimisations for a date raneg
   public exportOptimisationsLogForDataRange(params: ReportParameters) {
      let url: string = `${this.auditUrl}/optimisationslog`;

      return this._http.post(url, params, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   // run a report that is exported to CSV for auditing service request for a date raneg
   public exportServiceRequetsLogForDataRange(params: ReportParameters) {
      let url: string = `${this.auditUrl}/service-request-log`;

      return this._http.post(url, params, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }
}
