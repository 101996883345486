// this is a custom map marker that is used to hold all of the applications maping data
// this data will later be mapped onto a google marker or polygon route line
export class MapPoint {

   constructor(lat: number, lng: number, infoWndTitle: string, infoWndBody: string,
               icon:string , selectIcon:string, zoomIcon:string, guid:string, 
               labelContent: string) {

      this.lat = lat;
      this.lng = lng;
      this.infoWndTitle = infoWndTitle;
      this.infoWndBody = infoWndBody;
      this.icon = icon;
      this.selectIcon = selectIcon;
      this.orginalIcon = icon;
      this.zoomIcon = zoomIcon;
      this.guid = guid;
      this.labelContent = labelContent;
   }

   lat: number;
   lng: number;
   infoWndTitle: string;  // this can take html text <strong>
   infoWndBody: string;  // this can take html text <strong>
   selectIcon: string ;   // selection icon
   orginalIcon: string;  // used to store the icon used when the user moves to selected
   zoomIcon:string;  // used for a google zoom 5 to 1 zoomed out
   icon: string;   // intial icon
   guid: string  ; // unique identify for the map point. multiple data sets can be shown on a map such as callers and callpoints the identfier has to be unique
   labelContent: string // Used mostly by callpoints to display a frequency label

}
