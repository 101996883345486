<p-dialog header="Change Password" [(visible)]="display" modal="true" width="470" positionTop="50" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-left: 17px;" autocomplete="off">

      <div class="form-group row">
         <label class="control-label">{{username}}</label>
      </div>


      <div class="form-group row">
         <label class="control-label">Enter Current Password</label>
      </div>
      <div class="form-group row">
         <input required type="password" id="oldPassword" name="oldPassword" placeholder="Current password" ngModel class="form-control cs-form-control">
      </div>

      <div class="form-group row">
         <label class="control-label">Enter New Password</label>
      </div>
      <div class="form-group row">
         <input required type="password" id="newPassword" name="newPassword" placeholder="New password" ngModel
                (change)="analyze(form.value.newPassword)"  class="form-control cs-form-control">
      </div>

      <div class="form-group row">
         <label class="control-label">Re-enter New Password</label>
      </div>
      <div class="form-group row">
         <input required type="password" id="newPassword2" name="newPassword2" placeholder="Confirm new password" ngModel class="form-control cs-form-control">
      </div>

      <div class="form-group row">
         <div [ngStyle]="passwordStrength">{{passwordStrengthText}}</div>
      </div>

      <div ng-style="passwordStrength"></div>

      <div class="row">
         <div class="alert alert-danger fade in" *ngIf="showError">
            <strong>Error!  </strong>{{errorMsg}}
         </div>
      </div>

      <p-footer>
         <div style="padding-top: 10px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="submitForm(form)" [disabled]="!form.valid || passwordStrengthText != 'Strong'">Ok</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>


   </form>


</p-dialog>
