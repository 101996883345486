<p-dialog
   header="Export"
   [(visible)]="display"
   modal="true"
   width="600"
   positionTop="150"
   [draggable]="false"
   [resizable]="false"
   (onHide)="onCancel()"
>
   <form
      #form="ngForm"
      class="form-horizontal ed-form-horizontal"
      style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;"
   >
      <div style="margin-top:5px;margin-bottom:5px;">
         Select the data you would like to export
      </div>

      <div class="panel" style="border:none;">
         <div style="margin-top: 10px;">
            <div class="row">
               <div
                  [ngClass]="{
                     'form-group': true,
                     'ed-form-group': true,
                     'export-selected': exportOptions.exportSchedules
                  }"
               >
                  <div class="col-sm-10">
                     <label>
                        <p-checkbox
                           [(ngModel)]="exportOptions.exportSchedules"
                           binary="true"
                           name="exportSchedules"
                           label=""
                        ></p-checkbox>
                        <i
                           class="cs-icon icon-calendar-2 export-filter-text"
                           style="margin-left: 10px;"
                        ></i
                        >Schedules</label
                     >
                     <div>
                        <div>
                           <label>Output to</label>
                        </div>

                        <callsmart-inputswitch
                           [disabled]="!exportOptions.exportSchedules"
                           name="exportSchedulesInSingleFiles"
                           onLabel="Collate to a single file"
                           offLabel="Split to individual caller files"
                           [(ngModel)]="
                              exportOptions.exportSchedulesInSingleFiles
                           "
                           inverseColours="true"
                        >
                        </callsmart-inputswitch>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row ">
               <div
                  [ngClass]="{
                     'form-group': true,
                     'ed-form-group': true,
                     'export-selected': exportOptions.exportCallers
                  }"
               >
                  <div class="col-sm-10">
                     <label>
                        <p-checkbox
                           [(ngModel)]="exportOptions.exportCallers"
                           binary="true"
                           name="exportCallers"
                           label=""
                        ></p-checkbox>
                        <i
                           class="cs-icon icon-users export-filter-text"
                           style="margin-left: 10px;"
                        ></i
                        >Callers</label
                     >
                  </div>
               </div>
            </div>

            <div class="row">
               <div
                  [ngClass]="{
                     'form-group': true,
                     'ed-form-group': true,
                     'export-selected': exportOptions.exportCallpoints
                  }"
               >
                  <div class="col-sm-10">
                     <label>
                        <p-checkbox
                           [(ngModel)]="exportOptions.exportCallpoints"
                           binary="true"
                           name="exportCallpoints"
                           label=""
                        ></p-checkbox>
                        <i
                           class="cs-icon icon-placeholders export-filter-text"
                           style="margin-left: 10px;"
                        ></i
                        >Callpoints</label
                     >
                  </div>
               </div>
            </div>
         </div>
      </div>

      <p-footer>
         <hr />
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button
               type="button"
               class="btn cs-btn-primary-inverse pull-right"
               (click)="onExport(form)"
               [disabled]="
                  !exportOptions.exportSchedules &&
                  !exportOptions.exportCallers &&
                  !exportOptions.exportCallpoints
               "
            >
               Export
            </button>
            <button
               type="button"
               class="btn cs-btn-secondary-inverse pull-right"
               (click)="onCancel()"
               style="margin-right: 8px;"
            >
               Cancel
            </button>
         </div>
      </p-footer>
   </form>
</p-dialog>
