import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SysAdminService } from 'app/services/sys-admin.service';
import { Company } from 'app/models/company';
import { User } from 'app/models/user';
import { TravelModel } from 'app/models/travelModel';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { Project } from 'app/models/project';
import { ProjectsStore } from './projects-store';
import { Maintenance } from 'app/models/maintenance';

// this store is used by the admin screens the end points tend to need is admin poicy for the user
// such screens are lists of users and companies
@Injectable()
export class SysAdminStore {

   // Companies .
   private _companies: BehaviorSubject<ReadonlyArray<Company>> = new BehaviorSubject<ReadonlyArray<Company>>([]);
   public companies$: Observable<ReadonlyArray<Company>> = this._companies.asObservable();

   //users
   private _users: BehaviorSubject<ReadonlyArray<User>> = new BehaviorSubject<ReadonlyArray<User>>([]);
   public users$: Observable<ReadonlyArray<User>> = this._users.asObservable();

   // travel models .
   private _travelModels: BehaviorSubject<ReadonlyArray<TravelModel>> = new BehaviorSubject<ReadonlyArray<TravelModel>>([]);
   public travelModels$: Observable<ReadonlyArray<TravelModel>> = this._travelModels.asObservable();

   // All projects.
   private _projects: BehaviorSubject<ReadonlyArray<Project>> = new BehaviorSubject<ReadonlyArray<Project>>([]);
   public projects$: Observable<ReadonlyArray<Project>> = this._projects.asObservable();

   public _maintenance: BehaviorSubject<Maintenance> = new BehaviorSubject<Maintenance>(null);
   public maintenance$: Observable<Maintenance> = this._maintenance.asObservable();

   // return an array of selected callpoints
   public get users() {
      return this._users.getValue();
   }

   constructor(
      private _projectsStore: ProjectsStore,
      private _sysAdminService: SysAdminService,
      private _errorHandler: ErrorHandlerService) {
   }

   public get currentCompanies() {
      return this._companies.getValue();
   }

   public loadUsers() {
      this._sysAdminService.getUsers()
         .subscribe(
            (users: User[]) => {
               this._users.next(users);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public deleteUser(userId: number) {

      this._sysAdminService.deleteUser(userId)
         .subscribe((res: boolean) => {
            if (res) {
               //  save was successful
               // refresh the user data list
               this.loadUsers();
            }
         },
            (error) => this._errorHandler.handleError(error));
   }

   public createUser(user: User) {

      this._sysAdminService.createUser(user)
         .subscribe((user: User) => {
            // refresh the data
            this.loadUsers();
         },
            (error) => this._errorHandler.handleError(error));
   }

   public updateUser(user: User) {

      this._sysAdminService.updateUser(user)
         .subscribe((user: User) => {
            // refresh the data
            this.loadUsers();
         },
            (error) => this._errorHandler.handleError(error));
   }

   public loadTravelModels() {
      this._sysAdminService.getTravelModels()
         .subscribe(
            (companies: TravelModel[]) => {
               this._travelModels.next(companies);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public deleteTravelModel(travelModelId: number) {

      this._sysAdminService.deleteTravelModel(travelModelId)
         .subscribe((res: boolean) => {
            if (res) {
               //  save was successful
               // refresh the data list
               this.loadTravelModels();
               this.loadCompanies();
            }
         },
            (error) => this._errorHandler.handleError(error));
   }

   public createTravelModel(travelModel: TravelModel) {

      this._sysAdminService.createTravelModel(travelModel)
         .subscribe((travelModel: TravelModel) => {
            // refresh the data
            this.loadTravelModels();
         },
            (error) => this._errorHandler.handleError(error));
   }

   public updateTravelModel(travelModel: TravelModel) {

      this._sysAdminService.updateTravelModel(travelModel)
         .subscribe((travelModel: TravelModel) => {
            // refresh the data
            this.loadTravelModels();
         },
            (error) => this._errorHandler.handleError(error));
   }


   public loadCompanies() {
      this._sysAdminService.getCompanies()
         .subscribe(
            (companies: Company[]) => {
               this._companies.next(companies);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public deleteCompany(companyId: number) {

      this._sysAdminService.deleteCompany(companyId)
         .subscribe((res: boolean) => {
            if (res) {
               //  save was successful
               // refresh the company data list
               this.loadCompanies();
            }
         },
            (error) => this._errorHandler.handleError(error));
   }

   public createCompany(company: Company) {

      this._sysAdminService.createCompany(company)
         .subscribe((company: Company) => {
            // refresh the data
            this.loadCompanies();
         },
            (error) => this._errorHandler.handleError(error));
   }

   public updateCompany(company: Company) {

      this._sysAdminService.updateCompany(company)
         .subscribe((company: Company) => {
            // refresh the data
            this.loadCompanies();
         },
            (error) => this._errorHandler.handleError(error));
   }

   public get projects() {
      return this._projects.getValue();
   }

   public loadProjects(company: Company) {
      this._sysAdminService.getProjects(company.companyId).subscribe(
         (projects: Project[]) => {
            this._projects.next(projects);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public cloneProject(projectId: number,
      newProjectName: string,
      userId: number,
      company: Company) {
      this._sysAdminService
         .cloneProject(projectId, newProjectName, userId)
         .subscribe(
            () => {
               // refresh the project list
               this.loadProjects(company);
               this._projectsStore.refreshProjectData();
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public getMaintenance() {
      this._sysAdminService.getMaintenance().subscribe(
         (maintenance: Maintenance) => {
            this._maintenance.next(maintenance);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public updateMaintenance(maintenance: Maintenance) {
      this._sysAdminService.updateMaintenance(maintenance)
         .subscribe((company: Company) => { this._maintenance.next(maintenance); },
         (error) => this._errorHandler.handleError(error));
   }
}
