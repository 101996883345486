import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Company } from 'app/models/company';
import { TravelModelListComponent } from 'app/admin-workspace/travel-model-list/travel-model-list.component';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { CompanyTravelModel } from 'app/models/companyTravelModel';
import { TravelModel } from 'app/models/travelModel';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/components/common/selectitem';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { DataTable } from 'primeng/components/datatable/datatable';

/**
 * Add / edit company dialog
 */
@Component({
   selector: 'callsmart-add-edit-company-dialog',
   templateUrl: './add-edit-company-dialog.component.html'
})
export class AddEditCompanyDialogComponent implements OnInit, OnDestroy {

   // Header text for the dialog.
   @Input() headerText: string;

   // company model used by this dialog.
   @Input() company: Company;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Determines the mode this dialog is in.
   @Input() dialogMode: DialogMode;

   // Notifies the client when the dialog was closed with save button.
   @Output() save = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   public en: any;
   public showNameError;
   public showExpiryError;
   public licenseExpiryDateStr: string;

   @ViewChild(DataTable)
   private travelModelGrid: DataTable;

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   //travel models for the company
   public selectedCompanyTravelModels: CompanyTravelModel[] = [];
   // travel models available system wide
   public travelModels: ReadonlyArray<TravelModel>;
   public travelModelComboList: SelectItem[];
   public selectedTravelModelToAdd: any;
   public travelModelUsers: number = 1;
   public showLicenseError: boolean = false;

   public showTravelModal: boolean = false;
   public selectedTravelModelToEdit: CompanyTravelModel;
   public travelModalHasError: boolean = false;
   public travelModalError: string;

   private cloneCompanyTravelModels: CompanyTravelModel[] = [];

   // travel models subscription.
   private _travel_models_subscription: Subscription;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 280;
      });
   }

   ngOnInit() {
      // This property customises all calendar names and formats to be displayed according to the design.
      this.en = {
         firstDayOfWeek: 1,
         dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
         dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
         monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         today: 'Today',
         clear: 'Clear'
      };

      // set the selected travel models if in edit
      if (this.company.travelModels) {
         // needed if the user makes changes then cancels
         this.cloneCompanyTravelModels = CallsmartUtils.deepClone<CompanyTravelModel[]>(this.company.travelModels, CompanyTravelModel);
      }

      // get a list of the system travel models
      this._travel_models_subscription = this._applicationStore.sysAdminStore.travelModels$.subscribe(
         (travelModels: ReadonlyArray<TravelModel>) => {
            this.travelModels = travelModels;
            this.travelModelComboList = [];

            if (travelModels) {
               travelModels.forEach(t => {
                  this.travelModelComboList.push({ label: t.travelModelName, value: { id: t.travelModelId, name: t.travelModelName } });
               })

               this.selectedTravelModelToAdd = { id: travelModels[0].travelModelId, name: travelModels[0].travelModelName };
            }
         }
      );

   }

   public ngOnDestroy(): void {
      if (this._travel_models_subscription) {
         this._travel_models_subscription.unsubscribe();
      }
   }

   public onSave(form: NgForm) {
      // validate the form.

      this.company.companyName = form.value.name;
      this.company.concurrentUsers = form.value.concurrentUsers;
      this.company.licenseExpiryDate = form.value.licenseExpiryDate;
      this.company.trialLicense = form.value.trialLicense;
      this.company.trialLicenseCallerLimit = form.value.trialLicenseCallerLimit;

      if (this.company.trialLicenseCallerLimit == null) {
         this.company.trialLicenseCallerLimit = 0;
      }

      // do the save
      if (this.dialogMode === DialogMode.edit) {
         //edit
         this._applicationStore.sysAdminStore.updateCompany(this.company);
      }
      else {
         // create new
         this.company.companyId = 0;
         this._applicationStore.sysAdminStore.createCompany(this.company);
      }

      this.display = false;
      this.save.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.company.travelModels = this.cloneCompanyTravelModels;
      this.display = false;
      this.cancel.next();
   }

   public onDeleteTravelModel(row:any) {
      
      // if the travel model exists in the company aready do nothing
      let matchIndex = this.company.travelModels.findIndex(ct => ct.travelModelId == row.travelModelId);

      if (matchIndex > -1) {
         this.company.travelModels.splice(matchIndex, 1);
         this.travelModelGrid.updateDataToRender(this.company.travelModels);
      }

   }

   // add a travel model to the company
   public onAddTravelModel() {
      let t = this.travelModels.find(tm => tm.travelModelId == this.selectedTravelModelToAdd.id);

      // if the travel model exists in the company aready do nothing
      let match = this.company.travelModels.find(ct => ct.travelModelId == this.selectedTravelModelToAdd.id);
      if (match != null) {
         return;
      }

      let m = new CompanyTravelModel();
      m.travelModelId = t.travelModelId;
      m.travelModelName = t.travelModelName;
      m.travelModelDescription = t.travelModelDescription;
      m.geocodeData = t.geocodeData;
      m.concurrentUsers = this.travelModelUsers;

      if (this.company.travelModels) {
         this.company.travelModels.push(m)
      } else {
         this.company.travelModels = [];
         this.company.travelModels.push(m)
      }

      this.travelModelGrid.updateDataToRender(this.company.travelModels);

   }

   public onLicenseDateSelected() {
      this.validateLicenseDate(this.company.licenseExpiryDate);
   }

   private validateLicenseDate(licenseDate: Date) {
      let typedDate: moment.Moment = moment(licenseDate, "DD/MM/YYYY");
      let currentDate: moment.Moment = moment(new Date());

      if(typedDate.isBefore(currentDate)) {
         this.showLicenseError = true;
      }
      else {
         this.showLicenseError = false;
      }
   }

   public onEditTravelModel(row:any){
      this.selectedTravelModelToEdit = row;
      this.showTravelModal = true;
   }

   public onEditCancel(){
      this.selectedTravelModelToEdit = null;
      this.showTravelModal = false;
      this.travelModalHasError = false;
   }

   public onEditSave(form: NgForm) {

      if(this.selectedTravelModelToEdit == null) 
      {
         return;
      }

      let concurrentUsers = form.value.concurrentUsers;
      let maxUsers = this.company.concurrentUsers === undefined ? 10 : this.company.concurrentUsers;

      if(!(concurrentUsers > 0 && concurrentUsers <= maxUsers))
      {
         this.travelModalHasError = true;
         this.travelModalError = "should be between 1 and " + maxUsers;
         return;
      }

      let matchIndex = this.company.travelModels.findIndex(ct => ct.travelModelId == this.selectedTravelModelToEdit.travelModelId);

      if (matchIndex > -1) {
         this.company.travelModels[matchIndex].concurrentUsers = concurrentUsers;
         this.travelModelGrid.updateDataToRender(this.company.travelModels);
      }

      this.selectedTravelModelToEdit = null;
      this.showTravelModal = false;
      this.travelModalHasError = false;
   }
}
