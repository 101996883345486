import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ProjectSettings } from 'app/models/settings/project-settings';

// Component class for global caller settings view. Provides controller and view model functionality.
@Component({
   templateUrl: 'user-caller-settings.component.html'
})
export class UserCallerSettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate {

   private _settings_subscription: Subscription;
   private _project_settings_subscription: Subscription;

   @ViewChild('editCallerSettings') editCallerSettings;

   public callerSettingsModel: CallerSettings;

   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;
   public projectCycleLength: number;

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _router: Router) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 185;
      });
   }

   ngOnInit() {
      this.subscribeToUserDefaultProjectSettings();
      this._applicationStore.userProjectSettingsStore.loadUserProjectSettings();

      this.subscribeToUserDefaultCallerSettings();
      this._applicationStore.userCallerSettingsStore.loadDefaultCallerSettings();
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallerSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   ngOnDestroy() {
      if (this._settings_subscription) {
         this._settings_subscription.unsubscribe();
      }
      if (this._project_settings_subscription) {
         this._project_settings_subscription.unsubscribe();
      }
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editCallerSettings.callerSettingsForm.pristine;
   }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editCallerSettings.callerSettingsForm.form.markAsPristine();
      this._applicationStore.userCallerSettingsStore.updateUserCallerSettings(this.callerSettingsModel);
   }

   public onCancel() {
      if(this._applicationStore.projectsStore.selectedProject === null) {
         this._router.navigate(['/open-project']);
      }
      else {
         this._router.navigate(['/dashboard']);
      }
   }

   private subscribeToUserDefaultCallerSettings() {
      this._settings_subscription = this._applicationStore.userCallerSettingsStore.defaultCallerSettings$.subscribe(
         (settings: CallerSettings) => {
            if (settings !== null) {
               this.callerSettingsModel = CallsmartUtils.deepClone<CallerSettings>(settings, CallerSettings);
            }
         }
      );
   }

   private subscribeToUserDefaultProjectSettings() {
      this._settings_subscription = this._applicationStore.userProjectSettingsStore.defaultProjectSettings$.subscribe(
         (settings: ProjectSettings) => {
            if (settings !== null) {
               var projectSetting = CallsmartUtils.deepClone<ProjectSettings>(settings, ProjectSettings);
               this.projectCycleLength = projectSetting.callCycleLength;
            }
         }
      );
   }
}
