import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DeleteProjectDialogComponent } from 'app/open-project-workspace/delete-project-dialog/delete-project-dialog.component';
import { ProjectListComponent } from 'app/open-project-workspace/project-list/project-list.component';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { CopyProjectDialogComponent } from 'app/open-project-workspace/copy-project-dialog/copy-project-dialog.component';
import { RollProjectForwardDialogComponent } from 'app/open-project-workspace/roll-project-forward-dialog/roll-project-forward-dialog.component';
import { RenameProjectDialogComponent } from 'app/open-project-workspace/rename-project-dialog/rename-project-dialog.component';
import { ProjectStatus } from 'app/models/projectStatus';
import { Alert } from 'app/models/alert';
import { Company } from 'app/models/company';

/*
   The open project workspace provides the user the ablity to see all the projects in the current system
   The user can open a project, delete a project and later roll a project forward
*/
@Component({
   selector: 'callsmart-open-project-workspace',
   templateUrl: './open-project-workspace.component.html',
})
export class OpenProjectWorkspaceComponent implements OnInit, OnDestroy {
   public scrollHeight: string = '200px';
   public scrollWidth: string = '200px';
   public workspaceHeight: number;

   @ViewChild(ProjectListComponent)
   public projectList: ProjectListComponent;

   /////////////// dialogs //////////////////////////////
   // Determines whether to display the  dialog.
   public showDeleteProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public deleteProjectDialog = DeleteProjectDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      project: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      delete: () => this.onDeleteEvent(),
      cancel: () => this.onCancelEvent(),
   };

   ////////////////////////////////////////////////////////////////////

   // Determines whether to display the  dialog.
   public showCopyProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public copyProjectDialog = CopyProjectDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public copyDialogInput = {
      display: false,
      project: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public copyDialogOutput = {
      copy: () => this.onCopyEvent(),
      cancel: () => this.onCancelCopyEvent(),
   };

   ////////////////////////////////////////////////////////////////////

   // Determines whether to display the  dialog.
   public showRenameProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public renameProjectDialog = RenameProjectDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public renameDialogInput = {
      display: false,
      project: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public renameDialogOutput = {
      rename: () => this.onRenameEvent(),
      cancel: () => this.onCancelRenameEvent(),
   };

   /////////////////////////////////////////////////////////////////////////

   // Determines whether to display the  dialog.
   public showRollProjectFowardDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public rollProjectForwardDialog = RollProjectForwardDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public rollProjectForwardDialogInput = {
      display: false,
      project: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public rollProjectForwardDialogOutput = {
      roll: () => this.onRollFowardEvent(),
      cancel: () => this.onCancelRollFowardEvent(),
   };

   /////////////////////////////////////////////////////////////////////////

   private _project_status_subscription: Subscription;
   private _user_company_subscription: Subscription;
   private _userCompany: Company;

   constructor(
      private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private router: Router
   ) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
      this.subscribeToIsProjectAccessable();
      this.subscribeToUserCompany();
   }

   public ngOnInit() {}

   public ngOnDestroy() {
      if (this._project_status_subscription) {
         this._project_status_subscription.unsubscribe();
      }

      if (this._user_company_subscription) {
         this._user_company_subscription.unsubscribe();
      }
   }

   public onDeleteProject() {
      this.showDeleteProjectDialog = true;
      this.dialogInput.display = true;
      this.dialogInput.project = this.projectList.selectedProjects;
   }

   // cancel button from the delete project  dialog
   public onCancelEvent() {
      this.showDeleteProjectDialog = false;
      this.dialogInput.display = false;
   }

   // delete button from the delete project  dialog
   public onDeleteEvent() {
      this.showDeleteProjectDialog = false;
      this.dialogInput.display = false;
   }

   public onRenameProject() {
      this.showRenameProjectDialog = true;
      this.renameDialogInput.display = true;
      this.renameDialogInput.project = this.projectList.selectedProjects;
   }

   public onOpenProject() {
      // check the project status first if not in use load project
      if (this.projectList.selectedProjects) {
         this._applicationStore.projectsStore.isProjectAccessable(
            this.projectList.selectedProjects.projectId
         );
      }
   }

   public onRollProject() {
      if (this._userCompany.trialLicense) {
         // For a trial license, project cannot be rolled forward.
         this._applicationStore.alertStore.sendAlert(
            new Alert(
               'Warning',
               'Your licence does not allow for multiple projects.'
            )
         );
      } else {
         this.showRollProjectFowardDialog = true;
         this.rollProjectForwardDialogInput.display = true;
         this.rollProjectForwardDialogInput.project = this.projectList.selectedProjects;
         this._applicationStore.projectsStore.loadProjectWithStatusCheck = true;
      }
   }

   public onCopyProject() {
      if (this._userCompany.trialLicense) {
         // For a trial license, project cannot be copied.
         this._applicationStore.alertStore.sendAlert(
            new Alert(
               'Warning',
               'Your licence does not allow for multiple projects.'
            )
         );
      } else {
         this.showCopyProjectDialog = true;
         this.copyDialogInput.display = true;
         this.copyDialogInput.project = this.projectList.selectedProjects;
      }
   }

   // cancel button from the copy project  dialog
   public onCancelCopyEvent() {
      this.showCopyProjectDialog = false;
      this.copyDialogInput.display = false;
   }

   // delete button from the copy project  dialog
   public onCopyEvent() {
      this.showCopyProjectDialog = false;
      this.copyDialogInput.display = false;
   }

   // cancel button from the rename project  dialog
   public onCancelRenameEvent() {
      this.showRenameProjectDialog = false;
      this.renameDialogInput.display = false;
   }

   // delete button from the rename project  dialog
   public onRenameEvent() {
      this.showRenameProjectDialog = false;
      this.renameDialogInput.display = false;
   }

   // cancel button from the roll project  dialog
   public onCancelRollFowardEvent() {
      this.showRollProjectFowardDialog = false;
      this.rollProjectForwardDialogInput.display = false;
   }

   // delete button from the roll project  dialog
   public onRollFowardEvent() {
      this.showRollProjectFowardDialog = false;
      this.rollProjectForwardDialogInput.display = false;
   }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 69 - 50 - 30;
      this.scrollHeight = this.workspaceHeight * 0.8 + 'px';
   }

   private subscribeToIsProjectAccessable(): void {
      this._project_status_subscription = this._applicationStore.projectsStore.isProjectAccessable$.subscribe(
         (status: ProjectStatus) => {
            if (!status.travelModelValid && status.callerCount != -1) {
               this.showMissingTravelModelAlert(status);
               return;
            }

            if (status.projectInUse) {
               this.showProjectInUseAlert(status);
            }
            else {
               if (status.exceedsConcurrentTravelModelAccess) {
                  this.showExceedsConcurrentTravelModelAccessAlert(status);
               }
               else {
                  this.loadProject(status);
               }
            }
         }
      );
   }

   private loadProject(status: ProjectStatus) {
      if (status.callerCount != -1) {
         if (this.projectList) {
            //load project
            this._applicationStore.projectsStore.loadProjectAndCheckStatus(
               this.projectList.selectedProjects,
               this._applicationStore.authenticationStore
                  .loggedInUser.userId
            );
            this.router.navigate(['project-status']);
         }
      }
   }

   private showExceedsConcurrentTravelModelAccessAlert(status: ProjectStatus) {
      this._applicationStore.alertStore.sendAlert(
         new Alert(
            'Warning',
            `Number of concurrent users exceeds your company licence for project travel model.\nIn use by user(s) ${status.travelModelUsedBy}.`
         )
      );
   }

   private showProjectInUseAlert(status: ProjectStatus) {
      this._applicationStore.alertStore.sendAlert(
         new Alert(
            'Warning',
            `Project cannot be opened. In use by user ${status.projectInUseByUser}`
         )
      );
   }

   private showMissingTravelModelAlert(status: ProjectStatus) {
      this._applicationStore.alertStore.sendAlert(
         new Alert(
            'Warning',
            `Project cannot be opened.\nThere is no travel model associated with the project ${status.travelModelUsedBy}.`
         )
      );
   }

   private subscribeToUserCompany(): void {
      this._user_company_subscription = this._applicationStore.authenticationStore.userCompany$.subscribe(
         (company: Company) => {
            this._userCompany = company;
         }
      );
   }
}
