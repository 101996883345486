
import { Injectable } from '@angular/core';

import { ApplicationStore } from 'app/stores/application-store';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceAction } from 'app/models/workspace-action';
import { ActionGroupings } from 'app/models/action-groupings.enum';

// This is an attempt to encapsulate any logic for reloading of system data when a workspace is navigated away from.
// this is an attempt to not reload for exceample callers data after every vist drag in the visists workspace when doing it once at the end would be fine.
// some data loads are tricggered from actions in the contextual panel, and based on the workspace can be deferend or should be actioned strait away.
// nowing which workspace is currntly active is nessary
@Injectable()
export class WorkspaceNavigationLogic {
   public constructor(private _applicationStore: ApplicationStore) { }

   public navigatingAwayFromActiveWorkspace(activeWorkspace: WorkspaceViewType) {
      // check if any actions have been triggered for the workspace
      const workSpaceActions = this._applicationStore.uiStore.getWorkspaceToRefreshDataOnNavigateAway();

      const workspaceMatchedActions = workSpaceActions.filter(wa => wa.view === activeWorkspace);

      // Deterime the actions to take
      workspaceMatchedActions.forEach(action => {
         this.executeNavigatingWorkspaceAction(action);
      });

      // clear the actions in the ui state
      this._applicationStore.uiStore.clearWorkspaceToRefreshDataOnNavigateAway();

   }

   public navigatingToWorkspace(activeWorkspace: WorkspaceViewType) {
      // check if any actions have been triggered for the workspace
      const workSpaceActions = this._applicationStore.uiStore.getWorkspaceToRefreshDataOnNavigateTo();

      const workspaceMatchedActions = workSpaceActions.filter(wa => wa.view === activeWorkspace);

      // Deterime the actions to take
      workspaceMatchedActions.forEach(action => {
         this.executeNavigatingWorkspaceAction(action);
      });

      // clear the actions in the ui state
      this._applicationStore.uiStore.clearWorkspaceToRefreshDataOnNavigateTo();

   }

   private executeNavigatingWorkspaceAction(workspaceAction: WorkspaceAction) {

      switch (workspaceAction.view) {
         case WorkspaceViewType.Callers: {
            this.executeCallersAction(workspaceAction);
            break;
         }
         case WorkspaceViewType.Callpoints: {
            this.executeCallpointsAction(workspaceAction);
            break;
         }
         case WorkspaceViewType.Visits:
         case WorkspaceViewType.Schedule: {
            this.executeVisitsAction(workspaceAction);
            break;
         }

      }
   }

   private executeVisitsAction(workspaceAction: WorkspaceAction) {
      switch (workspaceAction.actionGroup) {
         case ActionGroupings.LoadAllCallersWithoutMapPoints: {
            this._applicationStore.callersStore.loadAllCallersWithoutMapPoints(this._applicationStore.projectsStore.selectedProject.projectId);
            break;
         }
         case ActionGroupings.LoadCallpointsForCaller: {
            this._applicationStore.callpointsStore.loadCallpointsForCaller(workspaceAction.data[0]);
            break;
         }
         case ActionGroupings.LoadVisitsForCaller: {
            this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId, workspaceAction.data[0]);
            break;
         }
      }
   }

   private executeCallersAction(workspaceAction: WorkspaceAction) {
      switch (workspaceAction.actionGroup) {
         case ActionGroupings.LoadCallpointsForCaller: {
            this._applicationStore.callpointsStore.loadCallpointsForCaller(workspaceAction.data[0]);
            break;
         }
         case ActionGroupings.LoadVisitsForCaller: {
            this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId, workspaceAction.data[0]);
            break;
         }
         case ActionGroupings.LoadDiaryEvents: {
            this._applicationStore.scheduleStore.loadDiaryEvents(workspaceAction.data[0], this._applicationStore.projectsStore.selectedProject.projectId, 
                                                                  workspaceAction.data[1]);
            break;
         }
      }
   }

   private executeCallpointsAction(workspaceAction: WorkspaceAction) {
      switch (workspaceAction.actionGroup) {
         case ActionGroupings.LoadAllCallersWithoutMapPoints: {
            this._applicationStore.callersStore.loadAllCallersWithoutMapPoints(this._applicationStore.projectsStore.selectedProject.projectId);
            break;
         }
         case ActionGroupings.LoadVisitsForCaller: {
            this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId, workspaceAction.data[0]);
            break;
         }
         case ActionGroupings.LoadCallpointsForCaller: {
            this._applicationStore.callpointsStore.loadCallpointsForCaller(workspaceAction.data[0]);
            break;
         }
         case ActionGroupings.LoadDiaryEvents: {
            this._applicationStore.scheduleStore.loadDiaryEvents(workspaceAction.data[0], this._applicationStore.projectsStore.selectedProject.projectId, 
                                                                  workspaceAction.data[1]);
            break;
         }
      }
   }
}
