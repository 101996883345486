<nav class="navbar navbar-inverse" *ngIf="showMenu">
   <div class="container-fluid">
      <div id="top-nav">
         <ul class="nav navbar-nav">
            <!--Drop down menu on the left side-->
            <li id="main-menu-left" class="dropdown">
               <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <i class="cs-icon icon-bars"></i>
                  <div>Menu</div>
               </a>
               <ul class="dropdown-menu">
                  <li id="main-menu-item-no-icon">
                     <div>Project</div>
                  </li>
                  <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                     <a (click)="onCreateNewProject()" style="cursor: pointer;">
                        <i class="cs-icon icon-new-document"></i>
                        <div>New</div>
                     </a>
                  </li>
                  <li  *ngIf="showOpenProject" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                     <!-- Navigating to the open project list will need to close the existing project first -->
                     <a [routerLink]="['/close-project']">
                        <i class="cs-icon icon-folder-6"></i>
                        <div>Open</div>
                     </a>
                  </li>
                  <li *ngIf="hasProjectLoaded">
                     <a (click)="rollForward()" style="cursor: pointer;">
                        <i class="cs-icon icon-calendar"></i>
                        <div>Roll forward</div>
                     </a>
                  </li>
                  <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
                     <a [routerLink]="['/project-settings']">
                        <i class="cs-icon icon-settings-3"></i>
                        <div>Properties</div>
                     </a>
                  </li>
                  <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
                     <a [routerLink]="['/project-settings/events']">
                        <i class="cs-icon icon-calendar-9"></i>
                        <div>Events</div>
                     </a>
                  </li>
                  <li *ngIf="hasProjectLoaded">
                     <a [routerLink]="['/close-project']" style="cursor: pointer;">
                        <i class="cs-icon icon-exit-1"></i>
                        <div>Close</div>
                     </a>
                  </li>
                  <li id="main-menu-item">
                     <a [routerLink]="['/settings']">
                        <i class="cs-icon icon-settings-5"></i>
                        <div>New project defaults</div>
                     </a>
                  </li>
                  <li id="main-menu-item" *ngIf="(user?.isAdmin || user?.isCompanyAdmin)">
                     <a [routerLink]="['/admin']">
                        <i class="cs-icon icon-settings-5"></i>
                        <div>System Admin</div>
                     </a>
                  </li>
               </ul>
            </li>

            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/dashboard']">
                  <i class="cs-icon icon-stopwatch-3"></i>
                  <div>Dashboard</div>
               </a>
            </li>

            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/callers']">
                  <i class="cs-icon icon-users"></i>
                  <div>Callers</div>
               </a>
            </li>
            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/callpoints']">
                  <i class="cs-icon icon-placeholders"></i>
                  <div>Callpoints</div>
               </a>
            </li>
            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/schedule']">
                  <i class="cs-icon icon-calendar-2"></i>
                  <div>Schedule</div>
               </a>
            </li>
            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/visits']">
                  <i class="cs-icon icon-briefcase"></i>
                  <div>Visits</div>
               </a>
            </li>
            <li [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" *ngIf="hasProjectLoaded">
               <a [routerLink]="['/notification-center']">
                  <i class="cs-icon icon-alarm"></i>
                  <div>Alerts</div>
               </a>
            </li>

            <!--Dev menu, to be removed in production-->
            <li class="dropdown" *ngIf="user?.isAdmin">
               <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <i class="glyphicon glyphicon-wrench" style="font-size: 18px; padding-top: 5px; padding-bottom: 5px;"></i>
                  <div>Dev</div>
               </a>
               <ul class="dropdown-menu">
                  <li id="main-menu-item"><a [routerLink]="['/diary']"><i class="cs-icon icon-settings-5"></i>
                        <div>Diary</div>
                     </a></li>
                  <li id="main-menu-item"><a [routerLink]="['/sysadmin']"><i class="cs-icon icon-settings-5"></i>
                        <div>Sys admin</div>
                     </a></li>
                  <li id="main-menu-item"><a [routerLink]="['/styleguide']"><i class="cs-icon icon-settings-5"></i>
                        <div>Style guide</div>
                     </a></li>
                  <li id="main-menu-item"><a [routerLink]="['/schedule-test']"><i class="cs-icon icon-settings-5"></i>
                        <div>Schedule Test</div>
                     </a></li>
                  <li id="main-menu-item"><a [routerLink]="['/wizard-test']"><i class="cs-icon icon-settings-5"></i>
                        <div>Wizard Test</div>
                     </a></li>
               </ul>
            </li>
         </ul>

         <!--Mini dash board-->
         <!--<callsmart-mini-dash *ngIf="hasProjectLoaded" ></callsmart-mini-dash>-->

         <!--Drop down menu on the right side-->
         <ul class="nav navbar-nav navbar-right">
            <li id="main-menu-right" class="dropdown">
               <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                  aria-expanded="false">
                  <i id="user-menu-dropdown" class="cs-icon icon-user-3"></i>
                  <i id="user-menu-chevron" class="cs-icon icon-arrow-down"></i>
               </a>
               <ul class="dropdown-menu">
                  <li id="main-menu-item-username">
                     <i class="user"> {{user?.fullname}}</i>
                  </li>
                  <li id="main-menu-item-username" style="padding-top:0px;">
                     <i class="user-company"> {{userCompany?.companyName}}</i>
                  </li>
                  <li id="main-menu-item">
                     <a (click)="changePassword()" style="cursor: pointer;">
                        <i class="cs-icon icon-settings-5"></i>
                        <div>Change Password</div>
                     </a>
                  </li>
                  <li id="main-menu-item">
                     <a (click)="showHelp()" style="cursor: pointer;">
                        <i class="cs-icon icon-info"></i>
                        <div>Help</div>
                     </a>
                  </li>
                  <li id="main-menu-item">
                     <a [routerLink]="['/logout']" style="cursor: pointer;">
                        <i class="cs-icon icon-exit-1"></i>
                        <div>Log out</div>
                     </a>
                  </li>
               </ul>
            </li>
         </ul>
         <img class="navbar-right cs-brand-name" src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
      </div>
      <!-- /.navbar-collapse -->
   </div>
   <!-- /.container-fluid -->
</nav>

<ndc-dynamic *ngIf="showChangePasswordDialog" [ndcDynamicComponent]="changePasswordDialog" [ndcDynamicInputs]="changePasswordDialogInput"
   [ndcDynamicOutputs]="changePasswordDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showHelpDialog" [ndcDynamicComponent]="helpDialog" [ndcDynamicInputs]="helpDialogInput"
   [ndcDynamicOutputs]="helpDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showRollProjectFowardDialog" [ndcDynamicComponent]="rollProjectForwardDialog" [ndcDynamicInputs]="rollProjectForwardDialogInput"
   [ndcDynamicOutputs]="rollProjectForwardDialogOutput">
</ndc-dynamic>
