<!--component html goes here -->
<div>
   <callsmart-wizard wizardTitle="Create new project"
                     [(activeIndex)]="activeIndex" 
                     (change)="onChange($event)"
                     (finish)="onFinish()" 
                     (cancel)="onCancel()"
                     [finishButtonLabel]="finishButtonLabel">
      
      <callsmart-step label="Settings" [formValid]="stepOne.valid">
         <div class="cs-step-panel" style="width:59%; display:inline-block; margin-right:5px;">
            <div class="panel-body">
               <h6>Settings page</h6>
               <form #stepOne="ngForm" autocomplete="off">
                  <label for="name">Please enter your name</label>
                  <input [(ngModel)]="model.name" name="name" #name="ngModel" required class="form-control cs-form-control">
                  <span *ngIf="name.touched && name.errors"> 
                     <label *ngIf="name.errors.required">Please insert name</label>
                  </span>
               </form>
            </div>
         </div>
         <!-- <div class="cs-step-panel" style="width: 40%;display: inline-block;">
            <div class="panel-body">
               <h6>Caller settings</h6>
               
            </div>
         </div> -->
      </callsmart-step>

      <callsmart-step label="Callers" [formValid]="stepTwo.valid">
         <div class="cs-step-panel" style="width:59%; display:inline-block; margin-right:5px;">
            <div class="panel-body">
               <h6>Callers page</h6>
               <form #stepTwo="ngForm" autocomplete="off">
                  <label for="name">Please enter your address</label>
                  <input [(ngModel)]="model.address" name="address" #address="ngModel" required class="form-control cs-form-control">
                  <span *ngIf="address.touched && address.errors"> 
                     <label *ngIf="address.errors.required">Please insert address</label>
                  </span>
               </form>
            </div>
         </div>
         <!-- <div class="cs-step-panel" style="width: 40%;display: inline-block;">
            <div class="panel-body">
               <h6>Caller settings</h6>
            </div>
         </div> -->
      </callsmart-step>

      <callsmart-step label="Callpoints" [formValid]="stepThree.valid">
         <div class="cs-step-panel" style="width:59%; display:inline-block; margin-right:5px;">
            <div class="panel-body">
               <h6>Callpoints page</h6>
               <form #stepThree="ngForm" autocomplete="off">
                  <label for="age">Please enter your age</label>
                  <input [(ngModel)]="model.age" name="age" #age="ngModel" required class="form-control cs-form-control">
                  <span *ngIf="age.touched && age.errors"> 
                     <label *ngIf="age.errors.required">Please insert age</label>
                  </span>
               </form>
            </div>
         </div>
         <!-- <div class="cs-step-panel" style="width: 40%;display: inline-block;">
            <div class="panel-body">
               <h6>Caller settings</h6>
            </div>
         </div> -->
      </callsmart-step>

      <callsmart-step label="Scheduling" [formValid]="stepFour.valid">
         <div class="cs-step-panel" style="width:59%; display:inline-block; margin-right:5px;">
            <div class="panel-body">
               <h6>Scheduling page</h6>
               <form #stepFour="ngForm" autocomplete="off">
                  <label for="age">Please enter your favourite food</label>
                  <input [(ngModel)]="model.food" name="food" #food="ngModel" required class="form-control cs-form-control">
                  <span *ngIf="food.touched && food.errors"> 
                     <label *ngIf="food.errors.required">Please insert food</label>
                  </span>
               </form>
            </div>
         </div>
         <!-- <div class="cs-step-panel" style="width: 40%;display: inline-block;">
            <div class="panel-body">
               <h6>Caller settings</h6>
            </div>
         </div> -->
      </callsmart-step>

      <callsmart-step label="Summary" [formValid]="stepSummary.valid">
         <div class="cs-step-panel" style="width:100%;">
            <div class="panel-body">
               <h6>Summary page</h6>
               <form #stepSummary="ngForm">
                  <button type="button" class="btn cs-btn-secondary-inverse"(click)="gotoSettings()" >Edit Settings</button>
                  <button type="button" class="btn cs-btn-secondary-inverse"(click)="gotoCallers()" >Edit Callers</button>
                  <button type="button" class="btn cs-btn-secondary-inverse"(click)="gotoCallpoints()" >Edit Callpoints</button>
                  <button type="button" class="btn cs-btn-secondary-inverse"(click)="gotoScheduling()" >Edit Scheduling</button>
               </form>
            </div>
         </div>
         
      </callsmart-step>
   </callsmart-wizard>

</div>

