import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { TravelModel } from 'app/models/travelModel';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';

/**
 * Component for the travel model creation and edit
 */

@Component({
   selector: 'callsmart-add-edit-travel-model-dialog',
   templateUrl: './add-edit-travel-model-dialog.component.html'
})
export class AddEditTravelModelDialogComponent implements OnInit {

     // Header text for the dialog.
     @Input() headerText: string;

     // company model used by this dialog.
     @Input() travelModel: TravelModel;

     // Controls the visibility of this dialog, clients can set this to true to display it.
     @Input() display: boolean = false;

     // Notifies the client when the dialog was closed with save button.
     @Output() save = new EventEmitter<any>();

     // Notifies the client when the dialog was closed with cancel button.
     @Output() cancel = new EventEmitter<void>();

     // Height of the scroll panel inside this dialog.
     public scrollHeight: number;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 380;
      });
   }

   ngOnInit() {
   }

   public onSave(form: NgForm) {

      this.travelModel.travelModelName = form.value.name;
      this.travelModel.travelModelDescription= form.value.travelModelDescription;
      this.travelModel.geocodeData = form.value.geocodeData;

      // do the save
      if (this.travelModel.travelModelId == 0 || this.travelModel.travelModelId == undefined || this.travelModel.travelModelId == null) {
         // create new
         this.travelModel.travelModelId = 0;
         //this.company.licensedCountries = countries;
         this._applicationStore.sysAdminStore.createTravelModel(this.travelModel);
      }
      else {
         //edit
         this._applicationStore.sysAdminStore.updateTravelModel(this.travelModel);
      }

      // do the save
      this.display = false;
      this.save.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

}
