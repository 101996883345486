import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
// this object is used to represent the travel models that hang off the company object theyhave company specific info on how mant users
// are licenced for each travel model
export class CompanyTravelModel {

   @JsonProperty('travelModelId')
   travelModelId: number = undefined;

   @JsonProperty('travelModelName')
   travelModelName: string = undefined;

   @JsonProperty('travelModelDescription')
   travelModelDescription: string = undefined;

   @JsonProperty('geocodeData')
   geocodeData: string = undefined;

   @JsonProperty('concurrentUsers')
   concurrentUsers: number = undefined;
}
