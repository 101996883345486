import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Project } from 'app/models/project';
import { ApplicationStore } from 'app/stores/application-store';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { DayCombination } from 'app/models/settings/day-combination';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ErrorDialogComponent } from 'app/shared/error-dialog/error-dialog.component';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';

/**
 * Callpoint settings component for the Project.
 */
@Component({
   templateUrl: 'project-properties-callpoints.component.html'
})
export class ProjectPropertiesCallpointsComponent implements OnInit, CanComponentDeactivate {

   @ViewChild('editCallpointSettings') editCallpointSettings;

   // Subscription for the project.
   private _project_subscription: Subscription;
   // Subscription for the day combinations.
   private _dayCombinations_subscription: Subscription;
   // Subscription for the recommended day combinations.
   private _recommendedDayCombinations_subscription: Subscription;
   // Subscription for the combinations with active working days
   private _combinationsWithActiveWorkingDays_subscription: Subscription;

   // Scroll height for the scroll panel.
   public scrollHeight: number;

   // Callpoint settings model.
   public callpointSettings = new CallpointSettings();

   // Drives the enablement of the save button
   public formValid: boolean = false;

   // Project available days
   public projectActiveWorkingDays: boolean[] = [];
   // All possible day combinations
   public allPossibleDayCombinations: DayCombination[] = [];
   // Recommended combinations (string value)
   public recommendedDayCombinations: string[] = [];

   public errorDialog = ErrorDialogComponent; // Dialog to display validation errors.
   public showErrorDialog: boolean = false; // Sets the visibility of the error dialog component.

   // Properties for the error dialog dynamic loading
   public dialogInput = { display: false, title: '', bodyText: '', validationErrors: [] };
   public dialogOutput = {
      cancel: () => this.onCancelAlertDialog()
   };

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 200;
      });
   }

   ngOnInit() {
      this.subscribeToProjectCallpointSettings();
      this.subscribeToAllPossibleDayCombination();
      this.subscribeToRecommendedDayCombination();
   }

   ngOnDestroy() {
      if (this._project_subscription) {
         this._project_subscription.unsubscribe();
      }
      if (this._dayCombinations_subscription) {
         this._dayCombinations_subscription.unsubscribe();
      }
      if (this._recommendedDayCombinations_subscription) {
         this._recommendedDayCombinations_subscription.unsubscribe();
      }
      if (this._combinationsWithActiveWorkingDays_subscription) {
         this._combinationsWithActiveWorkingDays_subscription.unsubscribe();
      }
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallpointSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editCallpointSettings.callpointSettingsForm.pristine;      
    }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editCallpointSettings.callpointSettingsForm.form.markAsPristine();
      if (this.callpointSettings.dayCombinations) {
         // Checks the number of active working days
         let numberOfWorkingDays = this.projectActiveWorkingDays.filter(item => item).length;
         let emptySelectionDayCombinationGroups: number[]
         if (numberOfWorkingDays > 1) {
            emptySelectionDayCombinationGroups =
               CallsmartUtils.getEmptyDayCombinationGroups(numberOfWorkingDays, this.callpointSettings.dayCombinations)
         }
         // Every DayCombination group has, at least, one selected item
         if (emptySelectionDayCombinationGroups.length === 0) {
            this._applicationStore.projectsStore.updateCallpointSettings(this.callpointSettings);
         }
         else {
            this.showDayCombinationsError(emptySelectionDayCombinationGroups);
         }
      }
   }

   public onCancel() {
      this._router.navigate(['/dashboard']);
   }

   // cancel button from the add\edit event dialog
   public onCancelAlertDialog() {
      this.showErrorDialog = false;
      this.dialogInput.display = false;
   }

   private subscribeToProjectCallpointSettings() {
      this._project_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            this.callpointSettings = CallsmartUtils.deepClone<CallpointSettings>(project.callpointSettings, CallpointSettings);
            if (project.callerSettings) {
               this.projectActiveWorkingDays = project.callerSettings.workingDayActive;
            }
         });
   }

   private subscribeToAllPossibleDayCombination() {
      this._dayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.allDayCombinations$.subscribe(
         (dayCombinations: DayCombination[]) => {
            this.allPossibleDayCombinations = dayCombinations;
         });
   }

   private subscribeToRecommendedDayCombination() {
      this._recommendedDayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.recommendedCombinations$.subscribe(
         (recommendedCombinations: string[]) => {
            this.recommendedDayCombinations = recommendedCombinations;
         });
   }

   // Displays the alert dialog with the dayCombinations error.
   private showDayCombinationsError(emptySelectionDayCombinationGroups: number[]) {
      this.showErrorDialog = true;
      this.dialogInput.title = 'Project properties - Callpoints';
      let validationErrors: string[] = [];
      if (emptySelectionDayCombinationGroups && emptySelectionDayCombinationGroups.length > 0) {
         emptySelectionDayCombinationGroups.forEach(groupNumber => {
            validationErrors.push(groupNumber + ' visits per week');
         })
      }
      this.dialogInput.bodyText = "Please, select at least one day combination per group. The following groups do not have any selected day combination:";
      this.dialogInput.validationErrors = validationErrors;
      this.dialogInput.display = true;
   }
}
