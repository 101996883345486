import { JsonObject, JsonProperty } from 'json2typescript';


@JsonObject
export class ScheduleMetrics {

   @JsonProperty('scheduleMetricsId')
   public scheduleMetricsId: number = undefined;

   @JsonProperty('callerId')
   public callerId: number = undefined;

   @JsonProperty('numDeferrals')
   public numDeferrals: number = undefined;

   @JsonProperty('minCallsPerDay')
   public minCallsPerDay: number = undefined;

   @JsonProperty('maxCallsPerDay')
   public maxCallsPerDay: number = undefined;

   @JsonProperty('avgCallsPerDay')
   public avgCallsPerDay: number = undefined;

   @JsonProperty('utilisationPc')
   public utilisationPc: number = undefined;

   @JsonProperty('driveDistanceKm')
   public driveDistanceKm: number = undefined;

   @JsonProperty('numAvailableDays')
   public numAvailableDays: number = undefined;

   @JsonProperty('numEmptyDays')
   public numEmptyDays: number = undefined;

   @JsonProperty('spareTimeHrs')
   public spareTimeHrs: number = undefined;

   @JsonProperty('numCallpoints')
   public numCallpoints: number = undefined;

   @JsonProperty('numVisitsToMake')
   public numVisitsToMake: number = undefined;

   @JsonProperty('numVisitsBooked')
   public numVisitsBooked: number = undefined;

   @JsonProperty('numDisabledCallpoints')
   public numDisabledCallpoints: number = undefined;

   @JsonProperty('numDisabledVisits')
   public numDisabledVisits: number = undefined;

   @JsonProperty('timeInVisitsHrs')
   public timeInVisitsHrs: number = undefined;

   @JsonProperty('shiftTimeInVisitPc')
   public shiftTimeInVisitPc: number = undefined;

   @JsonProperty('avgDriveTimeBetweenVisitsMins')
   public avgDriveTimeBetweenVisitsMins: number = undefined;

   @JsonProperty('numOvernights')
   public numOvernights: number = undefined;

   @JsonProperty('maxConsecutiveOvernights')
   public maxConsecutiveOvernights: number = undefined;

   @JsonProperty('driveTimeHrs')
   public driveTimeHrs: number = undefined;

   @JsonProperty('commuteTimeHrs')
   public commuteTimeHrs: number = undefined;

   @JsonProperty('commuteDistanceKm')
   public commuteDistanceKm: number = undefined;

   @JsonProperty('avgDriveDistanceBetweenVisitsKm')
   public avgDriveDistanceBetweenVisitsKm: number = undefined;
}
