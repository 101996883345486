import {
   Component,
   Input,
   Output,
   EventEmitter,
   OnInit,
   OnDestroy,
   OnChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { ApplicationStore } from 'app/stores/application-store';
import { Caller } from 'app/models/caller';
import { EditCallerSettingsDialogComponent } from 'app/shared/edit-caller-settings-dialog/edit-caller-settings-dialog.component';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { forEach } from '@angular/router/src/utils/collection';
import { ExportParameters } from 'app/models/export-parameters';
import { EditCallpointsDialogComponent } from 'app/shared/edit-callpoints-dialog/edit-callpoints-dialog.component';

// List of properties that exist in each of the caller setting sections.
const WORKING_HOURS_PROPERTIES: string[] = [
   'sameWorkingHoursAllDays',
   'workingDayActive',
   'contractedWorkingHoursWeek',
   'contractedWorkingHoursMonday',
   'contractedWorkingHoursTuesday',
   'contractedWorkingHoursWednesday',
   'contractedWorkingHoursThursday',
   'contractedWorkingHoursFriday',
   'contractedWorkingHoursSaturday',
   'contractedWorkingHoursSunday',
];
const WORKING_HOURS_FLEXIBILITY_PROPERTIES: string[] = [
   'workingHoursFlexibility',
];
const LUNCH_WINDOW_PROPERTIES: string[] = [
   'lunchPeriod',
   'floatingLunch',
   'lunchDuration',
];
const MAX_ONE_WAY_COMMUTE_TIME_PROPERTIES: string[] = [
   'noMaximum',
   'maxOneWayCommuteTime',
];
const MAX_VISITS_PER_DAY_PROPERTIES: string[] = [
   'maxVisitsPerDay',
   'visitsPerDayNoMaximum',
];
const COMMUTE_DURING_WORKING_HOURS_PROPERTIES: string[] = [
   'commuteWorkHrsToFirstVisit',
   'commuteWorkHrsFormLastVisit',
];
const OUT_OF_PHASE_VISITS_PROPERTIES: string[] = ['outOfPhaseVisitDays'];
const OVERNIGHTS_PROPERTIES: string[] = [
   'overnightsDisabled',
   'maxConsecutiveOvernights',
   'overnightsNoMaximum',
   'minCommuteTimeBeforeOvernight',
   'maxTravelTimeInEventOfOvernight',
   'eventOvernightNoMaximum',
];

/**
 * Contextual panel component for showing multiple selected callers. If the user has selected
 * up to 10 callers then lists them out and give the option to deselect them.
 *
 * If more than 10 are selected then the users are not listed out. Only options are listed.
 */
@Component({
   selector: 'callsmart-multi-selected-callers',
   templateUrl: 'multi-selected-callers.component.html',
})
export class MultiSelectedCallersComponent
   implements OnInit, OnDestroy, OnChanges {
   // show different typs of export
   @Input() showScheduleExport: boolean = true;
   @Input() showCallerExport: boolean = true;
   @Input() showCallpointExport: boolean = true;

   // List of selected callers from the callers workspace component.
   @Input() selectedCallers: Caller[];

   // Notify the parent component that a caller has been deselected so the map
   // can be updated.
   @Output() deselectCaller: EventEmitter<string> = new EventEmitter<string>();

   // Notify the parent component to single select the caller.
   @Output() selectCaller: EventEmitter<Caller> = new EventEmitter<Caller>();

   // Determines the state of the optimise button.
   public optimiseButtonDisabled: boolean = false;

   // Determine whether to display the edit caller dialog.
   public showCallerSettings: boolean = false;

   public showEditCallpoints: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallerSettingsDialog = EditCallerSettingsDialogComponent;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallpontsDialog = EditCallpointsDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callerName: 'Multiple caller',
      callerSettingsModel: null,
      editingMultipleCallers: true,
      projectCycleLength: null,
   };

   public editCallpointsDialogInput = {
      display: false,
      callerIds: [],
   };

   // Output parameters for the loaded component. This usually will be the
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      multiCallerSaved: (caller: Caller) =>
         this.onSaveEditMultipleCallers(caller),
      cancel: () => this.onCancelEditCaller(),
   };

   public editCallpointsDialogOutput = {
      saved: () => this.onSaveEditCallpoints(),
      cancel: () => this.onCancelEditCallpoints(),
   };

   // Caller settings used by this component.
   public callerSettingsViewModel: CallerSettingsViewModel;

   // Subscription for caller optimisation.
   private _callersOptimisingSubscription: Subscription;

   // List of optimising callers.
   private _cacheCallerIds: number[];

   constructor(private _applicationStore: ApplicationStore) {}

   public ngOnInit(): void {
      this.subscribetToCallersOptimising();
   }

   public ngOnChanges(): void {
      this.sortList();
   }

   public ngOnDestroy(): void {
      if (this._callersOptimisingSubscription) {
         this._callersOptimisingSubscription.unsubscribe();
      }
   }

   // Edit the settings of selected callers
   public editCallers(): void {
      this.initialiseCallerSettings(this.selectedCallers[0]);
      this.dialogInput.callerSettingsModel = this.callerSettingsViewModel;
      this.showCallerSettings = true;
      this.dialogInput.display = true;
      this.dialogInput.projectCycleLength = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;
   }

   // Set the caller setting dialog to be hidden and work out which properties need to be saved.
   public onSaveEditMultipleCallers(caller: Caller): void {
      this.showCallerSettings = false;
      this.dialogInput.display = false;

      // Loop through each selected callers settings and only update those properties for which the user
      // has selected the multi edit checkbox.
      this.selectedCallers.forEach((c) => {
         // If the user has selected a caller that has not overridden their caller settings then
         // the caller settings object will be null. Create one so that the relevant properties
         // can be set on it.
         if (c.callerSettings === null || c.callerSettings === undefined) {
            c.callerSettings = new CallerSettings();
         }

         // Always set the caller id since the CallerSettingsViewModel does not set this. When updating
         // the settings of a single caller the caller id is sent as part of the enpoint URL. But when editing
         // multiple caller settings, the caller id is set on the setting object.
         c.callerSettings.callerId = c.callerId;

         if (this.callerSettingsViewModel.workingHoursMultiEdit) {
            c.callerSettings.workingDayActive =
               caller.callerSettings.workingDayActive !== undefined
                  ? caller.callerSettings.workingDayActive.slice()
                  : undefined;
            c.callerSettings.sameWorkingHoursAllDays =
               caller.callerSettings.sameWorkingHoursAllDays;
            c.callerSettings.contractedWorkingHoursWeek =
               caller.callerSettings.contractedWorkingHoursWeek !== undefined
                  ? caller.callerSettings.contractedWorkingHoursWeek.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursMonday =
               caller.callerSettings.contractedWorkingHoursMonday !== undefined
                  ? caller.callerSettings.contractedWorkingHoursMonday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursTuesday =
               caller.callerSettings.contractedWorkingHoursTuesday !== undefined
                  ? caller.callerSettings.contractedWorkingHoursTuesday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursWednesday =
               caller.callerSettings.contractedWorkingHoursWednesday !==
               undefined
                  ? caller.callerSettings.contractedWorkingHoursWednesday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursThursday =
               caller.callerSettings.contractedWorkingHoursThursday !==
               undefined
                  ? caller.callerSettings.contractedWorkingHoursThursday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursFriday =
               caller.callerSettings.contractedWorkingHoursFriday !== undefined
                  ? caller.callerSettings.contractedWorkingHoursFriday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursSaturday =
               caller.callerSettings.contractedWorkingHoursSaturday !==
               undefined
                  ? caller.callerSettings.contractedWorkingHoursSaturday.slice()
                  : undefined;
            c.callerSettings.contractedWorkingHoursSunday =
               caller.callerSettings.contractedWorkingHoursSunday !== undefined
                  ? caller.callerSettings.contractedWorkingHoursSunday.slice()
                  : undefined;

            // sync the working days with the dates closed
            this.syncWithDatesClosed(caller, c);
         }

         if (this.callerSettingsViewModel.workingHoursFlexibilityMultiEdit) {
            c.callerSettings.workingHoursFlexibility =
               caller.callerSettings.workingHoursFlexibility;
         }

         if (this.callerSettingsViewModel.lunchWindowSwitchMultiEdit) {
            c.callerSettings.lunchPeriod =
               caller.callerSettings.lunchPeriod !== undefined
                  ? caller.callerSettings.lunchPeriod.slice()
                  : undefined;
            c.callerSettings.floatingLunch =
               caller.callerSettings.floatingLunch;
            c.callerSettings.lunchDuration =
               caller.callerSettings.lunchDuration;
         }

         if (this.callerSettingsViewModel.maxCommuteTimeMultiEdit) {
            c.callerSettings.noMaximum = caller.callerSettings.noMaximum;
            c.callerSettings.maxOneWayCommuteTime =
               caller.callerSettings.maxOneWayCommuteTime;
         }

         if (this.callerSettingsViewModel.maxVisitsPerDayMultiEdit) {
            c.callerSettings.maxVisitsPerDay =
               caller.callerSettings.maxVisitsPerDay;
            c.callerSettings.visitsPerDayNoMaximum =
               caller.callerSettings.visitsPerDayNoMaximum;
         }

         if (this.callerSettingsViewModel.commuteWorkHrsToFirstVisitMultiEdit) {
            c.callerSettings.commuteWorkHrsToFirstVisit =
               caller.callerSettings.commuteWorkHrsToFirstVisit;
            c.callerSettings.commuteWorkHrsFormLastVisit =
               caller.callerSettings.commuteWorkHrsFormLastVisit;
         }

         if (this.callerSettingsViewModel.outOfPhaseVisitsMultiEdit) {
            c.callerSettings.outOfPhaseVisitDays =
               caller.callerSettings.outOfPhaseVisitDays;
         }

         if (this.callerSettingsViewModel.overnightsMultiEdit) {
            c.callerSettings.overnightsDisabled =
               caller.callerSettings.overnightsDisabled;
            c.callerSettings.maxConsecutiveOvernights =
               caller.callerSettings.maxConsecutiveOvernights;
            c.callerSettings.overnightsNoMaximum =
               caller.callerSettings.overnightsNoMaximum;
            c.callerSettings.minCommuteTimeBeforeOvernight =
               caller.callerSettings.minCommuteTimeBeforeOvernight;
            c.callerSettings.maxTravelTimeInEventOfOvernight =
               caller.callerSettings.maxTravelTimeInEventOfOvernight;
            c.callerSettings.eventOvernightNoMaximum =
               caller.callerSettings.eventOvernightNoMaximum;
         }

         if (this.callerSettingsViewModel.locationMultiEdit) {
            c.latitude = caller.latitude;
            c.longitude = caller.longitude;
         }
      });

      this._applicationStore.callersStore.updateCallers(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this.selectedCallers
      );
   }

   public onSaveEditCallpoints(): void {
      this.showEditCallpoints = false;
      this.editCallpointsDialogInput.display = false;
      // clear potentially a large amount of data in the browsers memory
      this._applicationStore.callpointsStore.clearCallpointsForMultipleCallers();

      this.refeshScheduleAndVisits();
   }

   // Set the caller setting dialog to be hidden and revert any changes made by the user.
   public onCancelEditCaller(): void {
      this.showCallerSettings = false;
      this.dialogInput.display = false;
   }

   public onCancelEditCallpoints(): void {
      this.showEditCallpoints = false;
      this.editCallpointsDialogInput.display = false;
      // clear potentially a large amount of data in the browsers memory
      this._applicationStore.callpointsStore.clearCallpointsForMultipleCallers();
   }

   // When the user click on a single caller from the list, select that caller in the
   // data grid and the map and also display that caller's summary in the contextual
   // panel.
   public selectSingleCaller(caller: Caller) {
      this.selectCaller.next(caller);
      this._applicationStore.callersStore.setSelectedCaller(caller);
   }

   // Remove the caller from datagrid selection and from the map selection.
   public removeCallerFromSelection(caller: Caller) {
      let index = this.selectedCallers.findIndex((c) => c.guid === caller.guid);
      let callers: Caller[] = this.selectedCallers.splice(index, 1);
      this.deselectCaller.next(callers[0].guid);

      this.sortList();
   }

   // Generate a schedule for each selected caller.
   public onFastOptimise(): void {
      // proactively diable the optimise buttons.
      this.optimiseButtonDisabled = true;

      this.selectedCallers.forEach((caller) => {
         this._applicationStore.scheduleStore.generateSchedule(
            caller.callerId,
            'Quick'
         );
      });
   }

   // Generate a schedule for each selected caller.
   public onFullOptimise(): void {
      // proactively diable the optimise buttons.
      this.optimiseButtonDisabled = true;

      this.selectedCallers.forEach((caller) => {
         this._applicationStore.scheduleStore.generateSchedule(
            caller.callerId,
            'Full'
         );
      });
   }

   // Clear all vists except the locked visits for each caller.
   public clearScheduleUnlocked(): void {
      this.selectedCallers.forEach((caller) => {
         this._applicationStore.scheduleStore.clearScheduleUnlocked(
            caller.callerId
         );
      });
   }

   // Clear schedule for each selected caller.
   public clearSchedule(): void {
      this.selectedCallers.forEach((caller) => {
         this._applicationStore.scheduleStore.clearSchedule(caller.callerId);
      });
   }

   // Export schedule for each selected caller.
   public exportSchedule(): void {
      this.selectedCallers.forEach((c) => {
         this._applicationStore.callersStore.exportCallerSchedule(
            this._applicationStore.projectsStore.selectedProject.projectId,
            c.callerId,
            c.name
         );
      });
   }

   public editCallpointLocking(): void {
      // weird bug if the contextual panel is scrolling due to lots of accordians open.
      // mouse wheel does not work in grid, it scrolls the contextal panel behind modal
      // hack collapse all bat caller accordion
      this._applicationStore.contextualPanelStore.setSelectedTabs([0]);

      this.editCallpointsDialogInput.callerIds = this.selectedCallers.map(
         (c) => c.callerId
      );
      this.showEditCallpoints = true;
      this.editCallpointsDialogInput.display = true;
   }

   // Export caller data for each selected caller.
   public exportCallersData(): void {
      let callerIds = this.selectedCallers.map(({ callerId }) => callerId);
      let exportParameters = new ExportParameters(
         Array.from(this._applicationStore.callersStore.callerExportColumns),
         callerIds
      );
      this._applicationStore.callersStore.exportSelectedCallersData(
         this._applicationStore.projectsStore.selectedProject.projectId,
         exportParameters
      );
   }

   // Export callpoint data for each selected callpoint.
   public exportCallpointsData(): void {
      let territory = this.selectedCallers[0].territory;
      let callpointIds = this._applicationStore.callpointsStore.selectedCallpoints.map(
         ({ callpointId }) => callpointId
      );
      let exportParameters = new ExportParameters(
         Array.from(
            this._applicationStore.callpointsStore.callpointExportColumns
         ),
         callpointIds
      );
      this._applicationStore.callpointsStore.exportSelectedCallpointsData(
         this._applicationStore.projectsStore.selectedProject.projectId,
         exportParameters,
         territory
      );
   }

   private refeshScheduleAndVisits() {
      // reload the current callers visits and schedule as locking status  might have changed
      this._applicationStore.visitsStore.loadVisitsForCaller(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this._applicationStore.callersStore.selectedCaller.callerId
      );

      this._applicationStore.scheduleStore.loadDiaryEvents(
         this._applicationStore.callersStore.selectedCaller.callerId,
         this._applicationStore.projectsStore.selectedProject.projectId,
         false
      );
   }

   private syncWithDatesClosed(caller: Caller, c: Caller) {
      let workingDays: boolean[];

      if (caller.callerSettings && caller.callerSettings.workingDayActive) {
         workingDays = caller.callerSettings.workingDayActive;
      } else {
         workingDays = this._applicationStore.projectsStore.selectedProject
            .callerSettings.workingDayActive;
      }

      let numOfWeeks = this._applicationStore.projectsStore.selectedProject
         .projectSettings.callCycleLength;
      let formattedClosedDates: string[] = CallsmartUtils.formatClosedDates(
         numOfWeeks,
         workingDays
      );
      c.datesClosed = formattedClosedDates;
   }

   private sortList() {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
      // Sort the list alphabetically.
      // The localeCompare function deals with extended characters - for example names in foreign languages.
      this.selectedCallers.sort((a, b) => a.name.localeCompare(b.name));
   }

   private subscribetToCallersOptimising() {
      this._callersOptimisingSubscription = this._applicationStore.scheduleStore.callersOptimising$.subscribe(
         (callerIds: number[]) => {
            this._cacheCallerIds = callerIds;
            this.checkIfCallerOptimising();
         }
      );
   }

   private checkIfCallerOptimising() {
      // super quick check if array empty nothing optimising
      if (this._cacheCallerIds.length == 0) {
         this.optimiseButtonDisabled = false;
         return;
      }

      // if caller id in the array then disable the buttons
      this.selectedCallers.forEach((caller) => {
         if (this._cacheCallerIds.find((c) => c === caller.callerId)) {
            this.optimiseButtonDisabled = true;
         } else {
            this.optimiseButtonDisabled = false;
         }
      });
   }

   private initialiseCallerSettings(caller: Caller) {
      let model: CallerSettingsViewModel = new CallerSettingsViewModel(
         this._applicationStore.projectsStore.selectedProject.callerSettings,
         caller.callerSettings,
         caller
      );

      this.callerSettingsViewModel = model;
      this.compareCallerSettings();
   }

   private compareCallerSettings() {
      // check to see if ALL caller settings are null (have not been overridden), if they are then
      // there is no need to compare the individual properties.
      if (this.selectedCallers.every((c) => c.callerSettings === null)) {
         // console.log('All caller settings are null');

         // None of the settings are overridden so the dialog needs to display all the settings.
         this.callerSettingsViewModel.setAllPropertiesSame(true);
      } else {
         // console.log('Some or All caller settings are overridden');

         // compare all sections of the caller settings.
         this.compareAllSections();
      }
      this.compareCallerCalendarState();
   }

   private compareCallerCalendarState() {
      let calendarsMatch: boolean = true;
      let amberDaysCallers: boolean[][] = [];

      for (let i = 0; i < this.selectedCallers.length; i++) {
         amberDaysCallers.push(
            CallsmartUtils.getAmberStateForWorkingDays(
               this.selectedCallers[i].datesClosed,
               this._applicationStore.projectsStore.selectedProject
                  .projectSettings.callCycleLength
            )
         );
      }

      for (let i = 0; i < amberDaysCallers.length - 1; i++) {
         calendarsMatch =
            calendarsMatch &&
            JSON.stringify(amberDaysCallers[i]) ===
               JSON.stringify(amberDaysCallers[i + 1]);
      }

      this.callerSettingsViewModel.workingHoursSameValuesSet = calendarsMatch;
     //  console.log('Comparing all caller calendars:', amberDaysCallers);
     //  console.log('matching:', calendarsMatch);
   }

   private compareAllSections() {
      let workingHoursMatch: boolean = true;
      let workingHoursFlexibilityMatch: boolean = true;
      let lunchWindowMatch: boolean = true;
      let maxOneWayCommuteTimeMatch: boolean = true;
      let maxVisitsPerDayMatch: boolean = true;
      let commuteDuringWorkingHoursOnlyMatch: boolean = true;
      let outOfPhaseVisitsMatch: boolean = true;
      let overnightsMatch: boolean = true;

      let firstCallerSettings = this.selectedCallers[0].callerSettings;

      if (firstCallerSettings === null || firstCallerSettings === undefined) {
         firstCallerSettings = new CallerSettings();
      }

      // Loop index starts at 1, because we don't want to compare the first caller setting with itself.
      // Loop through all the callers and compare their properties with each other.
      for (let i = 1; i < this.selectedCallers.length; i++) {
         // Compare working hours section.
         workingHoursMatch =
            workingHoursMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               WORKING_HOURS_PROPERTIES
            );

         // Compare working hours flexibility section.
         workingHoursFlexibilityMatch =
            workingHoursFlexibilityMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               WORKING_HOURS_FLEXIBILITY_PROPERTIES
            );

         // Compare lunch window section.
         lunchWindowMatch =
            lunchWindowMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               LUNCH_WINDOW_PROPERTIES
            );

         // Compare maximum one way commute section.
         maxOneWayCommuteTimeMatch =
            maxOneWayCommuteTimeMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               MAX_ONE_WAY_COMMUTE_TIME_PROPERTIES
            );

         // Compare Maximum visits allowed per day section.
         maxVisitsPerDayMatch =
            maxVisitsPerDayMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               MAX_VISITS_PER_DAY_PROPERTIES
            );

         // Compare commute during working hours only section.
         commuteDuringWorkingHoursOnlyMatch =
            commuteDuringWorkingHoursOnlyMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               COMMUTE_DURING_WORKING_HOURS_PROPERTIES
            );

         // Compare out of phase visits section.
         outOfPhaseVisitsMatch =
            outOfPhaseVisitsMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               OUT_OF_PHASE_VISITS_PROPERTIES
            );

         // Overnights section.
         overnightsMatch =
            overnightsMatch &&
            this.compareProperties(
               firstCallerSettings,
               this.selectedCallers[i].callerSettings,
               OVERNIGHTS_PROPERTIES
            );
      }

      this.callerSettingsViewModel.workingHoursSameValuesSet = workingHoursMatch;
      this.callerSettingsViewModel.workingHoursFlexibilitySameValuesSet = workingHoursFlexibilityMatch;
      this.callerSettingsViewModel.lunchWindowSwitchSameValuesSet = lunchWindowMatch;
      this.callerSettingsViewModel.maxCommuteTimeSameValuesSet = maxOneWayCommuteTimeMatch;
      this.callerSettingsViewModel.maxVisitsPerDaySameValuesSet = maxVisitsPerDayMatch;
      this.callerSettingsViewModel.commuteWorkHrsToFirstVisitSameValuesSet = commuteDuringWorkingHoursOnlyMatch;
      this.callerSettingsViewModel.outOfPhaseVisitsSameValuesSet = outOfPhaseVisitsMatch;
      this.callerSettingsViewModel.overnightsSameValuesSet = overnightsMatch;
   }

   private compareProperties(
      value: CallerSettings,
      other: CallerSettings,
      propertyNames: string[]
   ): boolean {
      let match: boolean = true;

      // Create a dummy object to make comparison easier
      if (other === null || other === undefined) {
         other = new CallerSettings();
      }

      for (let property of propertyNames) {
         if (value[property] instanceof Date) {
            match =
               match &&
               CallsmartUtils.isEqualDates(value[property], other[property]);
         } else {
            match =
               match &&
               JSON.stringify(value[property]) ===
                  JSON.stringify(other[property]);
         }

         if (!match) {
            // console.log( 'match failed:' + property,  value[property],  other[property] );

            // The properties don't match so return the result as there is no need to continue
            // testing other properties.
            return match;
         }
      }

      return match;
   }
}
