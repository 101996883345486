import { fromEvent as observableFromEvent, Observable } from 'rxjs';
import { refCount, publishReplay, startWith, map, pluck, distinctUntilChanged } from 'rxjs/operators';

/**
 * Service class that publishes certain properties of the browser window.
 * Additional properties can be exposed as needed.
 * 
 * Any component wishing to get notification of such changes will need to
 * subscribe to the observable properties. Example:
 * 
 * constructor(private windowService: BrowserWindowService) {
 *     //subscribe to the window resize event
 *     windowService.height$.subscribe((value: number) => {
 *        //do something.
 *     });
 */
export class BrowserWindowService {
   public width$: Observable<number>;
   public height$: Observable<number>;

   constructor() {
      let windowSize$ = this.createWindowSize();
      this.width$ = (windowSize$.pipe(pluck('width')) as Observable<number>).pipe(distinctUntilChanged());
      this.height$ = (windowSize$.pipe(pluck('height')) as Observable<number>).pipe(distinctUntilChanged());
   }

   // Hook on to the browser resize event and create and observable
   // that gets its data from getWindowSize, additional properties can be
   // retrieved in the same call.
   private createWindowSize(): Observable<{ height: number, width: number }> {
      return observableFromEvent(window, 'resize').pipe(
         map(this.getWindowSize),
         startWith(this.getWindowSize()),
         // the following two calls will ensure that only one handler is used
         // for all subscribers
         publishReplay(1),
         refCount());
   }

   // Get the inner height and width of the browser window
   private getWindowSize() {
      return {
         height: window.innerHeight,
         width: window.innerWidth
      }
   }
}



