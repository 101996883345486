import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Caller } from 'app/models/caller';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Callpoint } from 'app/models/callpoint';
import { Project } from 'app/models/project';
import { ApplicationStore } from 'app/stores/application-store';
import { Event } from 'app/models/diary/event';
import { VisitSelection } from 'app/models/visit-selection';

/**
 * Contextual panel component.
 * Caller, Callpoint, Schedule and Visit workspaces make use of this component to show contextual information. 
 * Uses the store classes for each of the workspaces to stay up to date.
 */
@Component({
   selector: 'contextual-panel',
   templateUrl: 'contextual-panel.component.html'
})
export class ContextualPanelComponent implements OnInit, OnDestroy {
   @Input() showScheduleExport: boolean;
   @Input() showCallerExport: boolean;
   @Input() showCallpointExport: boolean;

   // Selected callers, either single or multiple.
   public selectedCallers: ReadonlyArray<Caller> = [];

   // Selected callpoints, either single or multiple
   public selectedCallpoints: ReadonlyArray<Callpoint> = [];

   // Selected day in the diary.
   public selectedDiaryDay: Date;

   // Project cycle start date.
   public startCycleDate: Date;

   // Selected visit in the diary.
   public selectedVisit: VisitSelection;

   // Contracted working hours.
   public selectedContractedWorkingTime: ReadonlyArray<Date> = [];

   // All events for a selected day.
   public selectedDayEvents: ReadonlyArray<Event> = [];

   // Height of the concertina scroll panel.
   public scrollHeight: number;

   // Indexes of open tabs, tab 0 is the callers tab which is open by default.
   public tabIndexes: number[] = [];

   private _selectedCallers_subscription: Subscription;
   private _selectedCallpoints_subscription: Subscription;
   private _selectedDiaryDay_subscription: Subscription;
   private _selectedProject_subscription: Subscription;
   private _selectedDiaryEvent_subscription: Subscription;
   private _selectedContractedWorkingTime_subscription: Subscription;
   private _selectedDayDiaryEvents_subscription: Subscription;
   private _selectedTabs_subscription: Subscription;

   constructor(private _windowService: BrowserWindowService,
               private _applicationStore: ApplicationStore,
               private _router: Router) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 60;
      });
   }

   public ngOnInit() {
      this.subscribeToSelectedCallers();
      this.subscribeToSelectedCallpoints();
      this.subscribeToSelectedDiaryDay();
      this.subscribeToSelectedProjectSettings();
      this.subscribeToSelectedDiaryVisit();
      this.subscribeToSelectedContractedWorkingTime();
      this.subscribeToSelectedDayEvents();
      this.subscribeToSelectedTabs();

      // Store the routing URL of the workspace that loaded this context panel, this is useful for
      // components to determine where the initial request came from in order to return the
      // user to right place after a redirect (e.g. like updating drivetimes after caller location
      // change).
      this._applicationStore.contextualPanelStore.setRequestingUrl(this._router.url);
   }

   public ngOnDestroy() {
      if (this._selectedCallers_subscription) {
         this._selectedCallers_subscription.unsubscribe();
      }

      if (this._selectedCallpoints_subscription) {
         this._selectedCallpoints_subscription.unsubscribe();
      }

      if (this._selectedDiaryDay_subscription) {
         this._selectedDiaryDay_subscription.unsubscribe();
      }

      if (this._selectedProject_subscription) {
         this._selectedProject_subscription.unsubscribe();
      }

      if (this._selectedDiaryEvent_subscription) {
         this._selectedDiaryEvent_subscription.unsubscribe();
      }

      if (this._selectedContractedWorkingTime_subscription) {
         this._selectedContractedWorkingTime_subscription.unsubscribe();
      }

      if (this._selectedDayDiaryEvents_subscription) {
         this._selectedDayDiaryEvents_subscription.unsubscribe();
      }

      if (this._selectedTabs_subscription) {
         this._selectedTabs_subscription.unsubscribe();
      }
   }

   // Event handler called when the tab is opened. Update the store with current selection of open tabs.
   public onTabOpen(event) {
      this.tabIndexes.push(event.index);
      this._applicationStore.contextualPanelStore.setSelectedTabs(this.tabIndexes);
   }

   // Event handler called when the tab is closed. Update the store with current selection of open tabs.
   public onTabClose(event) {
      this.tabIndexes.splice(this.tabIndexes.indexOf(event.index), 1);
      this._applicationStore.contextualPanelStore.setSelectedTabs(this.tabIndexes);
   }

   // Update the callers workpspace with the single selected caller.
   public setSingleSelectedCaller(caller: Caller) {
      this._applicationStore.callersStore.singleSelectCaller(caller);
   }

   // Remove the caller from the map.
   public deselectCallerFromMap(guid: string) {
      this._applicationStore.callersStore.deselectCaller(guid);
   }

   // Update the callpoints workpspace with the single selected callpoint.
   public setSingleSelectedCallpoint(callpoint: Callpoint) {
      this._applicationStore.callpointsStore.singleSelectCallpoint(callpoint);
   }

   // Remove the callpoint from the map.
   public deselectCallpointFromMap(guid: string) {
      this._applicationStore.callpointsStore.deselectCallpoint(guid);
   }

   private subscribeToSelectedCallers() {
      this._selectedCallers_subscription = this._applicationStore.callersStore.selectedCallers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            this.selectedCallers = callers;
         }
      );
   }

   private subscribeToSelectedCallpoints() {
      this._selectedCallpoints_subscription = this._applicationStore.callpointsStore.selectedCallpoints$.subscribe(
         (callpoints: ReadonlyArray<Callpoint>) => {
            this.selectedCallpoints = callpoints;
         }
      );
   }

   private subscribeToSelectedDiaryDay() {
      this._selectedDiaryDay_subscription = this._applicationStore.scheduleStore.selectedDiaryDay$.subscribe(
         (selectedDate: Date) => {
            this.selectedDiaryDay = selectedDate;
         }
      );
   }

   private subscribeToSelectedProjectSettings() {
      this._selectedProject_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project) {
               this.startCycleDate = new Date(project.scheduleStartDate);
            }
         }
      );
   }

   private subscribeToSelectedDiaryVisit() {
      this._selectedDiaryEvent_subscription = this._applicationStore.scheduleStore.selectedVisit$.subscribe(
         (visit: VisitSelection) => {
            this.selectedVisit = visit;
         }
      );
   }

   private subscribeToSelectedContractedWorkingTime() {
      this._selectedContractedWorkingTime_subscription = this._applicationStore.scheduleStore.selectedContractedWorkingTime$.subscribe(
         (workingTime: Date[]) => {
            this.selectedContractedWorkingTime = workingTime;
         }
      );
   }

   private subscribeToSelectedDayEvents() {
      this._selectedDayDiaryEvents_subscription = this._applicationStore.scheduleStore.selectedDayDiaryEvents$.subscribe(
         (events: Event[]) => {
            this.selectedDayEvents = events;
         }
      );
   }

   private subscribeToSelectedTabs() {
      this._selectedTabs_subscription = this._applicationStore.contextualPanelStore.selectedTabs$.subscribe(
         (tabs: number[]) => {
            this.tabIndexes = tabs;
         }
      );
   }

}
