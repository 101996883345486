import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';

import { Event } from 'app/models/diary/event';
import { ApplicationStore } from 'app/stores/application-store';
import { Caller } from 'app/models/caller';
import { VisitSelection } from 'app/models/visit-selection';
import { LockingTypes } from '../../models/diary/locking-Types';

@Component({
   selector: 'callsmart-caller-visits-summary',
   templateUrl: './caller-visits-summary.component.html'
})
export class CallerVisitsSummaryComponent implements OnInit, OnDestroy {

   private _callerDiaryEventsSubscription: Subscription;
   private _selectedCallerSubscription: Subscription;
   private _visitsLockedSubscription: Subscription;
   // checks whether all callpoint visits are locked to day and time.
   public areAllVisitsUnlocked: boolean = false;

   public phase: number;
   public week: number;
   public day: string;
   public date: moment.Moment;
   public startTime: string;
   public duration: number;
   public drivingTime: number;
   public selectedVisitTitle: string = '';
   public areAllVisitsLockedToDay: boolean = false;
   public areAllVisitsLockedToDayTime: boolean = false;

   private _startCycleDate: Date;
   @Input()
   get startCycleDate(): Date {
      return this._startCycleDate;
   }
   set startCycleDate(startCycleDate: Date) {
      this._startCycleDate = startCycleDate;
      if (this._startCycleDate && this.date) {
         let momentStartCycleDate = moment(this.startCycleDate);
         this.week = this.date.diff(momentStartCycleDate, 'w') + 1;;
      }
   }

   // Gets and sets the selected visits collection.
   private _selectedVisit: VisitSelection;
   @Input()
   get selectedVisit(): VisitSelection {
      return this._selectedVisit;
   }
   set selectedVisit(visitSelection: VisitSelection) {
      this._selectedVisit = visitSelection;
      if (this._selectedVisit && this._selectedVisit.selectedVisit) {
         if (this._selectedVisit.selectedVisit.start) {
            let startDate: moment.Moment = moment(this._selectedVisit.selectedVisit.start);
            let endDate: moment.Moment = moment(this._selectedVisit.selectedVisit.end);
            // duration
            this.duration = endDate.diff(startDate, 'm');
            // date
            this.date = startDate;
            // week
            if (this._startCycleDate && this.date) {
               let momentStartCycleDate = moment(this.startCycleDate);
               this.week = this.date.diff(momentStartCycleDate, 'w') + 1;;
            }
            // day
            this.day = this.getDayOfWeek(moment(this._selectedVisit.selectedVisit.start).isoWeekday());
            // title
            this.selectedVisitTitle = this._selectedVisit.selectedVisit.title + ', '
               + this._selectedVisit.selectedVisit.callpointId + '_' + this._selectedVisit.phase;
            // start time
            this.startTime = moment(this._selectedVisit.selectedVisit.start).format('HH:mm');
            // driving time
            this.drivingTime = this._selectedVisit.driveTime;
            // phase
            this.phase = this._selectedVisit.phase;

            this._selectedVisits = [this.selectedVisit.selectedVisit];
            this.setLockingMenuItemStates();
            // In preparation for multiselection
         }
         else {
            // title
            this.selectedVisitTitle = this._selectedVisit.selectedVisit.title + ', '
               + this._selectedVisit.visitId;
            // duration
            this.duration = null;
            // start time
            this.startTime = null;
            // driving time
            this.drivingTime = null;
            // phase
            this.phase = null;
         }
      }
   }


   private _selectedCaller: Caller;

   // Gets and sets the selected visits collection.
   private _selectedVisits: Event[] = [];

   get selectedVisits(): Event[] {
      return this._selectedVisits;
   }

   public get isVisitLocked(): boolean {
      return this.areAllVisitsLockedToDay || this.areAllVisitsLockedToDayTime;
   }


   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      //this.subscribeToSelectedVisits();
      this.subscribeToSelectedCaller();
      this.subscribeToVisitsLocked();
      this.subscribeToCallerDiaryEvents();
   }

   ngOnDestroy() {
      if (this._callerDiaryEventsSubscription) {
         this._callerDiaryEventsSubscription.unsubscribe();
      }
      if (this._selectedCallerSubscription) {
         this._selectedCallerSubscription.unsubscribe();
      }
      if (this._visitsLockedSubscription) {
         this._visitsLockedSubscription.unsubscribe();
      }
   }


   public getFormattedDate() {
      if (this.date) {
         return this.date.format('DD MMM YYYY');
      }
   }

   public unscheduleVisit() {
      if (this.selectedVisit) {
         this._applicationStore.scheduleStore.unscheduleVisit({ callerId: this._selectedCaller.callerId, dayIndex: this.selectedVisit.dayIndex, visitIndex: this.selectedVisit.eventIndex,
            date: this.date.toDate(), callpointId: this.selectedVisit.selectedVisit.callpointId , view: this._applicationStore.uiStore.getActiveView()});
      }
   }

   public lockVisitToDay() {
      if (this.selectedVisit && this.selectedVisit.selectedVisit.locking !== LockingTypes.day) {
         this.lockVisit(LockingTypes.day);
      }
   }

   public lockVisitToDayTime() {
      if (this.selectedVisit && this.selectedVisit.selectedVisit.locking !== LockingTypes.dayAndTime) {
         this.lockVisit(LockingTypes.dayAndTime);
      }
   }

   public unlockVisit(): void {
      this.lockVisit(LockingTypes.none);
   }

   private subscribeToSelectedCaller() {
      this._selectedCallerSubscription = this._applicationStore.callersStore.selectedCaller$.subscribe(
         (caller: Caller) => {
            this._selectedCaller = caller;
         }
      );
   }

   private subscribeToCallerDiaryEvents() {
      this._callerDiaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         () => {
            // Eihter after locking the callpoint visits or after optimising,
            // diary events are refreshed so the menu items must recalculate their states.
            this.setLockingMenuItemStates();
         });
   }

   private subscribeToVisitsLocked(): void {
      this._visitsLockedSubscription = this._applicationStore.scheduleStore.visitLocked.subscribe(
         () => {
            this.setLockingMenuItemStates();
         }
      );
   }


   // Locks/unlocks all the visits for the selected callpoint.
   private lockVisit(lockingType: LockingTypes) {
      this._applicationStore.scheduleStore.lockVisit(this._selectedCaller.callerId,
         this.selectedVisit.dayIndex,
         this.selectedVisit.eventIndex,
         this.date.toDate(),
         lockingType ,
         this._applicationStore.uiStore.getActiveView());
   }

   // Works out the new menu items state after performing a locking action.
   private setLockingMenuItemStates() {

      let visitStartDates: Date[] = this.selectedVisits.map(calendarEvent => calendarEvent.start);

      // Checks and stores whether all visits for the selected callpoints are locked to day.
      this.areAllVisitsLockedToDay = this._applicationStore.scheduleStore.
         currentCallerDiaryEvents.filter(event => visitStartDates.some(dateItem => dateItem.getTime() == event.start.getTime())).
         every(event => event.locking == LockingTypes.day);
      // Checks and stores whether all visits for the selected callpoints are locked to day and time.
      this.areAllVisitsLockedToDayTime = this._applicationStore.scheduleStore.
         currentCallerDiaryEvents.filter(event => visitStartDates.some(dateItem => dateItem.getTime() == event.start.getTime())).
         every(event => event.locking == LockingTypes.dayAndTime);
      // Checks and stores whether all visits for the selected visits are unlocked.
      this.areAllVisitsUnlocked = this._applicationStore.scheduleStore.
         currentCallerDiaryEvents.filter(event => visitStartDates.some(dateItem => dateItem.getTime() == event.start.getTime())).
         every(event => event.locking == LockingTypes.none);
         
   }

   private getDayOfWeek(isoDayOfWeek: number): string {
      switch (isoDayOfWeek) {
         case 1:
            return 'Monday';
         case 2:
            return 'Tuesday';
         case 3:
            return 'Wednesday';
         case 4:
            return 'Thursday';
         case 5:
            return 'Friday';
         case 6:
            return 'Saturnday';
         case 7:
            return 'Sunday';
         default:
            return '';
      }
   }
}
