import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CallpointsListComponent } from 'app/callpoint-workspace/callpoints-list/callpoints-list.component';
import { Caller } from 'app/models/caller';
import { Callpoint } from 'app/models/callpoint';
import { MapPoint } from 'app/models/map-point';
import { CallerCarouselComponent } from 'app/shared/caller-carousel/caller-carousel.component';
import { GoogleMapComponent } from 'app/shared/google-map/google-map.component';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceNavigationLogic } from 'app/shared/optimisation-logic/workspace-navigation-logic';

// The call point work space is used to present map and grid information to the user.
// it uses both the call point list control and map control to do this.
// additional information is shown using the contextual panel, showing and hiding of this is triggered through
// events fired from the other controls
@Component({
   selector: 'callsmart-callpoint-workspace',
   templateUrl: './callpoint-workspace.component.html',
   host: {
      '(document:keydown)': 'onKeyDown($event)',
      '(document:keyup)': 'onKeyUp($event)',
   }
})
export class CallpointWorkspaceComponent implements OnInit, OnDestroy {

   private _callpointsSubscription: Subscription;
   private _callpointsMapPointsSubscription: Subscription;

   @ViewChild(GoogleMapComponent)
   private map: GoogleMapComponent;

   @ViewChild(CallpointsListComponent)
   public callPointsList: CallpointsListComponent;

   // map properties
   public mapHeight: number = 250;
   public mapLastSyncSelection: any[] = [];

   public gridHeight: number = 250;
   public scrollHeight: string = '200px';
   public workspaceHeight: number;
   public scrollWidth: string = "1200px"
   public marginTop: number = 18;
   private resizing: boolean = false; // Needed when resizing the workspace to workaround a bug when releasing the splitter.
   private initialising: boolean = true; // Needed when resizing the workspace to workaround a bug when releasing the splitter.

   public callPoints: Callpoint[] = [];
   public caller: Caller;
   public mySwiper;
   public isCtrlKeyPressed: boolean = false;

   /*************** Hide/Show  Map variables********************/

   @ViewChild('box1') splitterComponent;
   @ViewChild('hideMapButton') hideMapButton;

   public isMapHidden: boolean = false;
   public isChangingMapVisiblity: boolean = false;
   public minMapSize: number = 0;
   public isCollapseMapButtonVisible: boolean = true;
   public clusterImagePath: string;

   private horizontalSeparator = null;
   private firstLoad: boolean = true;

   /************************************************************/

   private firstIdle: boolean = true;
   private firstTilesLoaded: boolean = true;
   private _selectedCallpoints_subscription: Subscription;
   private _deselectCallpoint_subscription: Subscription;
   private _singleSelectCallpoint_subscription: Subscription;

   constructor(
      private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _workspaceNavigationLogic: WorkspaceNavigationLogic) {

      this.clusterImagePath = this._applicationStore.mapsStore.clusterMarkerImagePathPurple;

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
      //subscribe to the window resize event
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth = (value - 260) + "px"; // 260 is the contextual panel width
      });
   }

   get optimiseMapMarkers(): boolean {
      return this._applicationStore.mapsStore.OptimiseMapMarkers;
   }

   ngOnInit() {

      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Callpoints);
      this._workspaceNavigationLogic.navigatingToWorkspace(WorkspaceViewType.Callpoints);

      this.horizontalSeparator = document.getElementsByTagName('horizontal-split-separator')[0];
      this.isMapHidden = this._applicationStore.mapsStore.isCallPointsMapHidden;
      this.subscribeToDeselectCallpoint();
      this.subscribeToSingleSelectCallpoint();

      this.horizontalSeparator = document.getElementsByTagName("horizontal-split-separator")[0];

      // The 'hide map' button located under the splitter is problematic when resizing.
      // If you are going at the right speed you'll find that instead of releasing the splitter,
      // the mouse still has hold of the splitter. This is because the cursor on release was over the up ^ arrow
      // and this prevents the drop notification from firing.
      // WORKAROUND:
      // * When user presses the mouse down the 'hide map' button is not displayed.
      // * When user releases the mouse (mouse up) the 'hide map' button is displayed in the right position.
      this.horizontalSeparator.onmousedown = (() => {
         this.hideMapButton.nativeElement.style.display = "none";
         this.marginTop = 0;
         this.resizing = true;
      });
      // The mouse can be released on any place inside the browser. That's the reason
      // to handle the 'mouseup' event of the document element
      document.onmouseup  = ( () => {
         if (this.resizing) {
            this.marginTop = 18;
            this.hideMapButton.nativeElement.style.display = "block";
            this.resizeMappingArea();
            this.resizing = false;
            // Internal funtion belonging to the SplitterComponent itself.
            this.splitterComponent.stopResizing();
         }
      });

      this.showHideMappingArea(this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight);
   }

   ngOnDestroy() {

      this._workspaceNavigationLogic.navigatingAwayFromActiveWorkspace(this._applicationStore.uiStore.getActiveView());

      if (this._callpointsSubscription) {
         this._callpointsSubscription.unsubscribe();
      }

      if (this._callpointsMapPointsSubscription) {
         this._callpointsMapPointsSubscription.unsubscribe();
      }

      if (this._selectedCallpoints_subscription) {
         this._selectedCallpoints_subscription.unsubscribe();
      }

      if (this._deselectCallpoint_subscription) {
         this._deselectCallpoint_subscription.unsubscribe();
      }

      if (this._singleSelectCallpoint_subscription) {
         this._singleSelectCallpoint_subscription.unsubscribe();
      }
   }

   public onGoogleMapIdle(event) {
      // Sets selection on the  list and map on load.
      if (this.callPointsList.callpoints.length > 0 && !this.firstTilesLoaded && this.firstIdle) {
         this.setSelectedCallpointsOnMapLoad();
         this.firstIdle = false;
      }

      this.firstIdle = false;
   }

   public onGoogleMapTilesLoaded(event) {
      // Sets selection on the caller list and map on load.
      if (this.callPointsList.callpoints.length > 0 && !this.firstIdle && this.firstTilesLoaded) {
         this.setSelectedCallpointsOnMapLoad();
         this.firstTilesLoaded = false;
      }

      this.firstTilesLoaded = false;
   }

   public setMap(event) {
      this.map = event;
      this.subscribeToCallpoints();
      this.subscribeToCallpointsMapPoints();
      this.subscribeToSelectedCallpoints();

      if (this.map) {
         this.map.resizeMap();
      }
   }

   public onMarkerClicked(event) {

      let matchCallpoint = this._applicationStore.callpointsStore.callpoints.find(c => c.guid == event.point.guid);

      if (this.isCtrlKeyPressed) {
         if (event.selected) {
            this.callPointsList.selectedCallPoints.push(matchCallpoint);
         } else {
            var index = this.callPointsList.selectedCallPoints.findIndex(x => x == matchCallpoint);
            this.callPointsList.selectedCallPoints.splice(index, 1);
         }
      } else {

         // only allowing one map selection at a  time clear grid selections
         this.callPointsList.selectedCallPoints = [];
         this.callPointsList.selectedCallPoints.push(matchCallpoint);
      }

      this.mapLastSyncSelection = this.callPointsList.selectedCallPoints.slice(); // clone the array
      this.syncSelectedCallpointsGlobally();
   }

   // Called from the datagrid to deselect the callpoint from the map.
   public onRowUnSelected(event) {
      this.deselectFromMap(event.data.guid);
      this.map.focusMapOnMarkers();
   }

   // Called from the multi select callpoints component to deselect the callpoint from the map.
   public deselectFromMap(guid: string) {
      if (!this.isMapHidden && this.map) {
         this.map.SetMapSelectionByGuid(guid, false);
      }

      //the deselected item in the grid will still show in the map selction untill the event has run its couse through the grid
      let selection = this.callPointsList.selectedCallPoints.slice();
      // delete the unselected item from the selection if it is still there
      let deSelectedIndex = selection.findIndex(x => x.guid == guid);

      if (deSelectedIndex > -1) {
         selection.splice(deSelectedIndex, 1);
      }

      this._applicationStore.callpointsStore.setSelectedCallpoints(selection);

   }

   public onRowSelected(event) {
      if (!this.isMapHidden) {
         // bug in prime NG they are leaving a property on the data
         //https://github.com/primefaces/primeng/issues/1937
         delete event.data._$visited;

         //check if the last map selection is out of sync with current selection if out of sync then deselect this missing points
         this.updateMapWithCurrentSelection(event.data);
      }

      this.syncSelectedCallpointsGlobally();
   }

   public onRowMultiSelected(event) { }

   public allRowsSelected(event) {
      if (!this.isMapHidden) {
         //check if the last map selection is out of sync with current selection if out of sync then deselect this missing points
         let missing = this.mapLastSyncSelection.filter(item => this.callPointsList.selectedCallPoints.indexOf(item) < 0);

         missing.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, false);
         });

         // either all or nothing from the selection not the event like row selecion
         this.callPointsList.selectedCallPoints.forEach(cp => {
            this.map.SetMapSelectionByGuid(cp.guid, true);
         });

         this.mapLastSyncSelection = this.callPointsList.selectedCallPoints.slice(); // clone the array
         this.map.focusMapOnSelection();
      }

      this.syncSelectedCallpointsGlobally();
   }

   public onListColumnHeaderChanged(event) {
      let headerHeight: number = event.headerHeight;
      let tableHeaderHeight: number = event.tableHeaderHeight;

      let secondaryClientHeight = this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight;
      this.gridHeight = secondaryClientHeight;
      this.scrollHeight = secondaryClientHeight - (headerHeight + tableHeaderHeight) + 13 + "px";
      this.mapHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
      setTimeout(() => this.map.resizeMap());
   }

   private syncSelectedCallpointsGlobally() {
      let selection = this.callPointsList.selectedCallPoints.slice();
      this._applicationStore.callpointsStore.setSelectedCallpoints(selection);
   }

   public onGoogleMapResize(event) {
      if (this.map) {
         this.map.focusMapOnSelection();
      }
   }

   public onHChange(event) {
      // Resize the map to fit new area the user has specified with the splitter.
      if (this.isChangingMapVisiblity && !this.isMapHidden) {
         this.mapHeight = this.splitterComponent.outerContainer.nativeElement.clientHeight - event.secondary;
      }
      else {
         this.mapHeight = event.primary;
      }

      if (this.map) {
         this.map.resizeMap();
      }

      this.gridHeight = event.secondary;
      if(this.callPointsList){
         let headerHeightOffset = 140;
         if(this.callPointsList.headerHeight && this.callPointsList.tableHeaderHeight){
            headerHeightOffset = (this.callPointsList.headerHeight + this.callPointsList.tableHeaderHeight);
         }
         this.scrollHeight = event.secondary - headerHeightOffset + 13 + "px";
      }

      // this point can be reached because either:
      // -. user is showing/hidding the mapping area or
      // -. user is performing a resizing action (which also includes loading this control the first time when the workspace is created).
      if (this.isChangingMapVisiblity) {
         // User is showing/hiding the mapping area
         this.showHideMappingArea(event.secondary);
      }
      else {
         if (this.initialising && this.hideMapButton) {
            this.hideMapButton.nativeElement.style.display = "block";
            this.initialising = false;
         }
         // Either user is resizing the mapping area using the splitter or
         // the splitter control is being loaded for the first time.
         this.resizeMappingArea();
      }

      // isChangingMapVisibility flag is reset to false.
      if (this.isChangingMapVisiblity) {
         this.isChangingMapVisiblity = false;
      }
   }


   // Called by the multi select component to single select the callpoint
   // in the data grid and the map.
   public setSingleSelectedCallpoint(callpoint: Callpoint) {
      this.callPointsList.selectedCallPoints = [];
      this.map.selectedPoints = [];
      this.callPointsList.selectedCallPoints.push(callpoint);
      this.updateMapWithCurrentSelection(callpoint);
   }

   public onShowFrequencySelected(event) {
      this.RefreshMapSettings();

      // RefreshMapSetting will cause a redraw of the markers on the map using a timeout, so one is needed
      // here to focus on the selection after that.
      setTimeout(() => {
         let selected = this._applicationStore.callpointsStore.selectedCallpoints.slice();

         // map selection
         selected.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, true);
         });

         this.map.focusMapOnSelection();
      }, 1000);
   }

   // method to rebuild the map with different settings but keep the existing data
   private RefreshMapSettings() {
      if (this.map) {
         this.map.clear();
         this.ShowAllCallersCallpointsOnMap();
      }
   }

   //check if the last map selection is out of sync with current selection if out of sync then deselect this missing points
   private updateMapWithCurrentSelection(callpoint: Callpoint) {
      let missing = this.mapLastSyncSelection.filter(item => this.callPointsList.selectedCallPoints.indexOf(item) < 0);

      missing.forEach(point => {
         this.map.SetMapSelectionByGuid(point.guid, false);
      });

      this.map.SetMapSelectionByGuid(callpoint.guid, true);
      this.mapLastSyncSelection = this.callPointsList.selectedCallPoints.slice(); // clone the array
      this.map.focusMapOnSelection();
   }

   private ShowAllCallersCallpointsOnMap() {
      let points: MapPoint[] = [];
      this.map.selectedPoints = [];

      points = this._applicationStore.callpointsStore.callpointsMapPoints;

      // caller is the start point
      if (this.caller != undefined) {
         let startPoint = new MapPoint(this.caller.latitude, this.caller.longitude, this.caller.name, this.caller.postcode,
            this.map.mapIconCallerSelected, this.map.mapIconCallerSelected, this.map.mapIconCallerSelected, this.caller.guid, undefined)
         this.map.addMarkersWithoutLabel([startPoint]);
      }

      this.map.addMarkers(points);
   }

   private subscribeToCallpointsMapPoints() {
      this._callpointsMapPointsSubscription = this._applicationStore.callpointsStore.callpointsMapPoints$.subscribe(
         (callpointsMapPoints: MapPoint[]) => {
            this.map.clear();
            if (callpointsMapPoints) {
               if (callpointsMapPoints.length > 0) {
                  this.ShowAllCallersCallpointsOnMap();
                  if (this.map) {
                     this.map.resizeMap();
                  }
               }
            }
         });
   }

   private subscribeToCallpoints() {
      this._callpointsSubscription = this._applicationStore.callpointsStore.callpoints$.subscribe(
         (callpoints: Callpoint[]) => {
            this.callPoints = callpoints;
         });
   }

   // Changes the visibility of the Map.
   public displayMap() {
      this.isMapHidden = !this.isMapHidden;
      this._applicationStore.mapsStore.isCallPointsMapHidden = this.isMapHidden;
      this.isChangingMapVisiblity = true;
   }

   // Adjust the height of the map and data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 69 - 50;
      this.calculateMapAndGridHeightsBasedonSplitter(this.splitterComponent);
   }

   private calculateMapAndGridHeightsBasedonSplitter(splitter){
      if (this.splitterComponent) {
         let topPanelHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
         let bottomPanelHeight = this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight;

         // set the grid height
         this.gridHeight = bottomPanelHeight;
         this.scrollHeight = this.calculateGridScrollHeight(bottomPanelHeight)

         // set the Map height
         this.mapHeight = topPanelHeight
         setTimeout(() => this.map.resizeMap());
      }
   }

   private calculateGridScrollHeight(panelHeight){
      let scrollheight = panelHeight - 140;
      if (this.callPointsList) {
         scrollheight = panelHeight - (this.callPointsList.headerHeight + this.callPointsList.tableHeaderHeight) + 15;
      }
      return scrollheight + "px";
   }


   // Collapses/Displays the mapping area.
   private showHideMappingArea(secondaryHeight: number) {
      // The collapse map button visibility changes.
      this.isCollapseMapButtonVisible = !this.isMapHidden;
      // Map is hidden.
      if (this.isMapHidden) {
         this.marginTop = 0;
         this.scrollHeight = this.calculateGridScrollHeight(secondaryHeight);
      }
      // Map is visible.
      else {
         // This setTimeout function is performed when the splitter becomes visible
         // since the "hideMapButton" element is still null... so need some time to be rendered
         this.marginTop = 18;
         setTimeout(() => {
            this.hideMapButton.nativeElement.style.display = "block"
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 17) + "px";
         }, 250);

         setTimeout(() => this.map.resizeMap(), 500);
      }
   }

   // Resizes the mapping area using the splitter.
   private resizeMappingArea() {
      if (this.hideMapButton) {
         // this.hideMapButton.nativeElement.style.display = "block"
         // this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 17) + "px";
         // User is using the splitter to resize the workspace
         if (this.resizing) {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 36) + "px";
         }
         else {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 18) + "px";
         }
      }

      if (this.firstLoad) {
         setTimeout(() => {
            if (!this.isMapHidden) {
               if (this.map) {
                  this.map.resizeMap();
               }
            }
         }, 500);
         this.firstLoad = false;
      }
   }


   // set the selected callpoints for first use when map loads
   private setSelectedCallpointsOnMapLoad() {
      if (this._applicationStore.callpointsStore.selectedCallpoints) {
         this.map.selectedPoints = [];

         //clone the service selected callpoints
         let selected = this._applicationStore.callpointsStore.selectedCallpoints.slice();

         // map selection
         selected.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, true);
         });

         // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
         this.mapLastSyncSelection = this.callPointsList.selectedCallPoints.slice(); // clone the array
         this.map.focusMapOnSelection();
      }
   }


   private subscribeToSelectedCallpoints() {
      this._selectedCallpoints_subscription = this._applicationStore.callpointsStore.selectedCallpoints$.subscribe(
         (callpoints: Array<Callpoint>) => {
            this.callPointsList.selectedCallPoints = callpoints
         });
   }

   // check which key pressed down
   private onKeyDown(e) {
      // check if control is pressed needed for multi selecting points on the map
      this.isCtrlKeyPressed = ((e.keyIdentifier == 'Control') || (e.ctrlKey == true));
   }

   // check which key pressed up
   private onKeyUp(e) {
      // check if control is pressed needed for multi selecting points on the map
      this.isCtrlKeyPressed = ((e.keyIdentifier == 'Control') || (e.ctrlKey == true));
   }

   private subscribeToDeselectCallpoint() {
      this._deselectCallpoint_subscription = this._applicationStore.callpointsStore.deselectCallpoint$.subscribe(
         (guid: string) => {
            this.deselectFromMap(guid);
         });
   }

   private subscribeToSingleSelectCallpoint() {
      this._singleSelectCallpoint_subscription = this._applicationStore.callpointsStore.singleSelectCallpoint$.subscribe(
         (callpoint: Callpoint) => {
            if (callpoint !== null) {
               this.setSingleSelectedCallpoint(callpoint);
            }
         });
   }
}
