import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Project } from 'app/models/project';


/*
   dialog to copy a project get the name for the new project
*/

@Component({
   selector: 'callsmart-copy-project-dialog',
   templateUrl: './copy-project-dialog.component.html'
})
export class CopyProjectDialogComponent implements OnInit {

   public copyProjectName:string;

   // Project model used by this dialog.
   @Input() project: Project;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() copy = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   public constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   public ngOnInit() {
      this.copyProjectName = this.project.name + ' - copy'
   }

   public onCopy(form) {

      let newProjectName = form.value.name;
      this._applicationStore.projectsStore.copyProject(this.project.projectId, newProjectName,this._applicationStore.authenticationStore.loggedInUser.userId,);
      this.display = false;
      this.copy.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

}
