import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BrowserWindowService } from 'app/services/browser-window.service';

@Component({
   selector: 'callsmart-new-folder-dialog',
   templateUrl: './new-folder-dialog.component.html'
})
export class NewFolderDialogComponent {

   @Input() public title: string = "Create new folder";
   public folder: string = "";

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<string>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   public scrollHeight: number;
   public scrollWidth: number;

   constructor(private windowService: BrowserWindowService) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value * 0.15;
      });
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth = value * 0.30;
      });
   }



   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   public onSave(form: NgForm) {
      this.display = false;
      this.saved.next(this.folder);
   }

}
