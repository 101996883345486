import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SettingsService } from 'app/services/settings.service';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { ErrorHandlerService } from 'app/services/error-handler.service';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class UserCallerSettingsStore {

   // Default caller settings
   private _defaultCallerSettings: BehaviorSubject<CallerSettings> = new BehaviorSubject<CallerSettings>(new CallerSettings());
   public defaultCallerSettings$: Observable<CallerSettings> = this._defaultCallerSettings.asObservable();
   private _defaultSettings: BehaviorSubject<CallerSettings> = new BehaviorSubject<CallerSettings>(new CallerSettings());
   public defaultSettings$: Observable<CallerSettings> = this._defaultSettings.asObservable();

   constructor(private _settingsService: SettingsService,
      private _errorHandler: ErrorHandlerService) {
      this._defaultCallerSettings.next(null);
   }

   public loadDefaultCallerSettings() {
      this._settingsService.getUserCallerSettings()
         .subscribe((settings: CallerSettings) => {
            this._defaultCallerSettings.next(settings);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   public updateUserCallerSettings(callerSettings: CallerSettings) {
      this._settingsService.updateUserCallerSettings(callerSettings)
         .subscribe(() => {

            //refresh the settings
            // TODO: What is the point of retrieving from the server the data that we just sent to the server? Isn't
            // that just going to create two server roundtrips for no good reason?
            this.loadDefaultCallerSettings();
         },
            (error) => this._errorHandler.handleError(error));
   }
   
   public loadDefaultSettings() {
      this._settingsService.getDefaultCallerSettings()
         .subscribe((settings: CallerSettings) => {
            this._defaultSettings.next(settings);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

}
