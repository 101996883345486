<div >
<p-dataTable [value]="companies" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="single" [(selection)]="selectedCompanies"
   (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onFilter)="onFilter($event)" [scrollHeight]="scrollHeight"
   [scrollable]="true" #projectdt>
   <p-header>
      <div style="float:left; margin-top: -18px;">
         <h3>{{gridTitle}}</h3>
      </div>

      <div style="float:left; height: 40px; padding-left: 10px;">
         <button id="clearFilter" type="button" class="btn cs-btn-secondary " (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
            <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
      </div>

      <div style="text-align:right; height: 40px;">
         <button type="button" class="btn cs-btn-primary-inverse" style="height:32px;" (click)="onCreateCompany()"> + Add </button>
      </div>
   </p-header>


   <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
      [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'overflow':'hidden'}">

      <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
         <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
            (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
         <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
            displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)"
            defaultLabel="All" appendTo="body"></p-multiSelect>
      </ng-template>

   </p-column>

   <p-column field="licenseExpiryDate" header="Licence Expiry Date" [sortable]="true" [filter]="true" filterMatchMode="contains"
      filterPlaceholder="contains" [style]="{'overflow':'hidden'}">
      <ng-template let-row="rowData" pTemplate="body">
         {{row.licenseExpiryDate | date:'dd/MM/y'}}
      </ng-template>
   </p-column>

   <p-column field="trialLicense" header="Trial license" [sortable]="true" [filter]="true" filterMatchMode="contains"
      filterPlaceholder="contains" [style]="{'overflow':'hidden'}">
      <ng-template let-row="rowData" pTemplate="body">
         {{row.trialLicense}}
      </ng-template>
   </p-column>

</p-dataTable>

</div>



