
<div class="contextual-panel">
   <div style="margin:20px">
      <input type="text" class="form-control" [(ngModel)]="callerId">
   </div>
   <div style="margin:20px">
      <button type="button" class="btn cs-btn-primary" (click)="getSchedule()">Load Schedule</button>
   </div>
   <div style="margin:20px">
      <button type="button" class="btn cs-btn-primary" (click)="clearSchedule()">Clear Schedule all</button>
   </div>

   <div style="margin:20px">
      <button type="button" class="btn cs-btn-primary" (click)="clearScheduleUnlocked()">Clear Schedule unlocked</button>
   </div>
   <div style="margin:20px">
      <button type="button" class="btn cs-btn-primary" (click)="quickOptimise()">Quick Optimise</button>
   </div>
   <div style="margin:20px">
      <button type="button" class="btn cs-btn-primary" (click)="fullOptimise()">Full Optimise</button>
   </div>
</div>
