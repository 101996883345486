import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { Callpoint } from 'app/models/callpoint';
import { EditCallpointSettingsDialogComponent } from 'app/shared/edit-callpoint-settings-dialog/edit-callpoint-settings-dialog.component';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { CallpointSettingsViewModel } from 'app/models/view-models/callpoint-settings-view';
import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { Caller } from 'app/models/caller';
import { DayCombination } from 'app/models/settings/day-combination';
import { LockingTypes } from '../../models/diary/locking-Types';
import { Visit } from 'app/models/visit';

const AVAILABILITY_PROPERTIES: string[] = ['availability'];
const FREQUENCY_PROPERTIES: string[] = ['frequency'];
const DURATION_PROPERTIES: string[] = ['duration'];
const LOCATION_PROPERTIES: string[] = ['latitude', 'longitude'];
const PRIORITY_PROPERTIES: string[] = ['priority'];
const CRITICAL_PROPERTIES: string[] = ['critical'];

/**
 * Contextual panel component for showing multiple selected callpoints. If the user has selected
 * up to 10 callpoints then lists them out and give the option to deselect them.
 *
 * If more than 10 are selected then the callpoints are not listed out. Only options are listed.
 */
@Component({
   selector: 'callsmart-multi-selected-callpoints',
   templateUrl: 'multi-selected-callpoints.component.html'
})
export class MultiSelectedCallpointsComponent implements OnChanges, OnInit, OnDestroy {

   // List of selected callpoints from the callpoints workspace component.
   private _selectedCallpoints: Callpoint[];
   @Input()
   public get selectedCallpoints(): Callpoint[]{
      return this._selectedCallpoints;
   }
   public set selectedCallpoints(callpoints: Callpoint[]){
      this._selectedCallpoints = callpoints;
      this.setLockingMenuItemStates();
   }

   // Notify the parent component that a callpoint has been deselected so the map
   // can be updated.
   @Output() deselectCallpoint: EventEmitter<string> = new EventEmitter<string>();

   // Notify the parent component single select this callpoint.
   @Output() selectCallpoint: EventEmitter<Callpoint> = new EventEmitter<Callpoint>();

   // Determine whether to display the edit caller dialog.
   public showCallpointSettings: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallpointSettingsDialog = EditCallpointSettingsDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callpointSettingsModel: null,
      callpointHeading: 'Multiple callpoint',
      projectActiveWorkingDays: null,
      allPossibleDayCombinations: [],
      recommendedDayCombinations: [],
      editingMultipleCallpoints: true,
      projectCycleLength: null
   };

   // Output parameters for the loaded component. This usually will be the
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      multiCallpointsSaved: (callpoint: Callpoint) => this.onSaveEditMultipleCallpoints(callpoint),
      cancel: () => this.onCancelEditCallpoint()
   };

   // Caller settings used by this component.
   public callpointSettingsViewModel: CallpointSettingsViewModel;

   // All possible day combinations.
   public allPossibleDayCombinations: DayCombination[] = [];

   // Recommended combinations (string value).
   public recommendedDayCombinations: string[] = [];

   // checks whether all callpoint visits are locked to day.
   public areAllVisitsLockedToDay: boolean = false;

   // checks whether all callpoint visits are locked to day and time.
   public areAllVisitsLockedToDayTime: boolean = false;

   // checks whether all callpoint visits are locked to day and time.
   public areAllVisitsUnlocked: boolean = false;

   // checks whether all callpoint visits are locked to day and time.
   public fullyDeferredCallpoints: boolean = false;

   // checks whether all callpoint are disabled.
   public areAllCallpointsDisabled: boolean = false;

   // checks whether all callpoint are enabled.
   public areAllCallpointsEnabled: boolean = false;

   // Subscription for the day combinations.
   private _dayCombinations_subscription: Subscription;

   // Subscription for the recommended day combinations.
   private _recommendedDayCombinations_subscription: Subscription;

   // Subscritpion to be informed about diary events.
   private _callerDiaryEventsSubscription: Subscription;

   // Subscritpion to be informed about the locking callpoint visits.
   private _callpointVisitsSubscription: Subscription;
   private _callpointVisitsLockedSubscription: Subscription;

   // Subscription for the combinations with active working days
   //private _combinationsWithActiveWorkingDays_subscription: Subscription;;

   constructor(private _applicationStore: ApplicationStore) { }

   public ngOnChanges(): void {
      this.sortList();
   }

   ngOnInit() {
      this.subscribeToAllPossibleDayCombination();
      this.subscribeToRecommendedDayCombination();
      this.subscribeToCallpointVisits();
      this.subscribeToCallpointVisitsLocked();
      this.subscribeToCallerDiaryEvents();
   }

   ngOnDestroy() {
      if (this._dayCombinations_subscription) {
         this._dayCombinations_subscription.unsubscribe();
      }
      if (this._recommendedDayCombinations_subscription) {
         this._recommendedDayCombinations_subscription.unsubscribe();
      }
      if (this._callerDiaryEventsSubscription) {
         this._callerDiaryEventsSubscription.unsubscribe();
      }
      if (this._callpointVisitsSubscription) {
         this._callpointVisitsSubscription.unsubscribe();
      }
      if (this._callpointVisitsLockedSubscription) {
         this._callpointVisitsLockedSubscription.unsubscribe();
      }
   }

   // When the user click on a single caller from the list, select that caller in the
   // data grid and the map and also display that caller's summary in the contextual
   // panel.
   public selectSingleCallpoint(callpoint: Callpoint) {
      this.selectCallpoint.next(callpoint);
   }

   // Remove the callpoint from datagrid selection and from the map selection.
   public removeCallpointFromSelection(callpoint: Callpoint) {
      let index = this.selectedCallpoints.findIndex(c => c.guid === callpoint.guid)
      let callpoints: Callpoint[] = this.selectedCallpoints.splice(index, 1);
      this.deselectCallpoint.next(callpoints[0].guid);

      this.sortList();
   }

   public editCallpoints() {
      this.initialiseCallpointSettings(this.selectedCallpoints[0])
      this.dialogInput.callpointSettingsModel = this.callpointSettingsViewModel;
      this.dialogInput.projectActiveWorkingDays = this._applicationStore.projectsStore.selectedProject.callerSettings.workingDayActive;
      this.dialogInput.projectCycleLength = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;
      this.dialogInput.allPossibleDayCombinations = this.allPossibleDayCombinations;
      this.dialogInput.recommendedDayCombinations = this.recommendedDayCombinations;

      this.showCallpointSettings = true;
      this.dialogInput.display = true;
   }

   // Set the callpoint setting dialog to be hidden and work out which properties need to be saved.
   public onSaveEditMultipleCallpoints(callpoint: Callpoint): void {
      this.showCallpointSettings = false;
      this.dialogInput.display = false;

      // Loop through each selected callpoints settings and only update those properties for which the user
      // has selected the multi edit checkbox.
      this.selectedCallpoints.forEach(c => {

         if (this.callpointSettingsViewModel.availabilityMultiEdit) {
            c.startDay = callpoint.startDay;
            c.endDay = callpoint.endDay;

            if (!c.callpointSettings) {
               c.callpointSettings = new CallpointSettings();
               c.callpointSettings.callpointId = c.callpointId;
            }

            c.callpointSettings.availability = callpoint.callpointSettings.availability !== undefined
               ? callpoint.callpointSettings.availability.slice()
               : undefined;
           
         }

         if (this.callpointSettingsViewModel.durationMultiEdit) {
            c.duration = callpoint.duration;
         }

         if (this.callpointSettingsViewModel.frequencyMultiEdit) {

            if(c.frequency != callpoint.frequency){
               this._applicationStore.scheduleStore.generateScheduleWithUpdatedFrequency(this._applicationStore.callersStore.selectedCaller.callerId);
            }

            c.frequency = callpoint.frequency;
         }

         if (this.callpointSettingsViewModel.locationMultiEdit) {
            c.latitude = callpoint.latitude;
            c.longitude = callpoint.longitude;
         }

         if (this.callpointSettingsViewModel.priorityMultiEdit) {
            c.priority = callpoint.priority;
         }

         if (this.callpointSettingsViewModel.criticalMultiEdit) {
            c.critical = callpoint.critical;
         }
      });

      this._applicationStore.callpointsStore.updateCallpoints(this.selectedCallpoints);
   }

   // Set the callpoint setting dialog to be hidden and revert any changes made by the user.
   public onCancelEditCallpoint(): void {
      this.showCallpointSettings = false;
      this.dialogInput.display = false;
   }

   // Locks all callpoint visits to day.
   public lockCallpointVisitsToDay() {
      this.lockVisits(LockingTypes.day);
   }

   // Locks all callpoint visits to day and time.
   public lockCallpointVisitsToDayTime() {
      this.lockVisits(LockingTypes.dayAndTime);
   }

   // Unlocks all callpoint visits.
   public unlockCallpointVisits(): void {
      this.lockVisits(LockingTypes.none);
   }

   // Disables all callpoints i.e. excluded from optimizer.
   public disableCallpoints(): void {
      this.setCallpointsDisabledState(true);
   }

   // Enables all callpoints i.e. included in optimizer.
   public enableCallpoints(): void{
      this.setCallpointsDisabledState(false);
   }

   private initialiseCallpointSettings(callpoint: Callpoint) {
      let model: CallpointSettingsViewModel = new CallpointSettingsViewModel(
         this._applicationStore.projectsStore.selectedProject.callpointSettings,
         callpoint);

      this.callpointSettingsViewModel = model;
      this.compareCallpointSettings();
   }

   private subscribeToAllPossibleDayCombination() {
      this._dayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.allDayCombinations$.subscribe(
         (dayCombinations: DayCombination[]) => {
            this.allPossibleDayCombinations = CallsmartUtils.deepClone<DayCombination[]>(dayCombinations, DayCombination);
         });
   }

   private subscribeToRecommendedDayCombination() {
      this._recommendedDayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.recommendedCombinations$.subscribe(
         (recommendedCombinations: string[]) => {
            this.recommendedDayCombinations = recommendedCombinations;
         });
   }

   private subscribeToCallerDiaryEvents() {
      this._callerDiaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         () => {
            // After locking the callpoint visits or after optimising,
            // diary events are refreshed so the menu items must recalculate their states.
            this.setLockingMenuItemStates();
         });
   }

   private subscribeToCallpointVisits() {
      this._callpointVisitsSubscription = this._applicationStore.visitsStore.visits$.subscribe(
         () => {
            // After locking the callpoint visits the menu items must refresh their states.
            this.setLockingMenuItemStates();
         });
   }

   private subscribeToCallpointVisitsLocked(): void {
      this._callpointVisitsLockedSubscription = this._applicationStore.scheduleStore.callpointVisitsLocked.subscribe(
         () =>{
            this.setLockingMenuItemStates();
         }
      );
   }

   private sortList() {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
      // Sort the list alphabetically.
      // The localeCompare function deals with extended characters - for example names in foreign languages.
      this.selectedCallpoints.sort((a, b) => a.name.localeCompare(b.name));
   }

   private compareCallpointSettings() {
      let availabilityMatch: boolean = true;
      let frequencyMatch: boolean = true;
      let durationMatch: boolean = true;
      let locationMatch: boolean = true;
      let priorityMatch: boolean = true;
      let criticalMatch: boolean = true;

      let firstCallpoint = this.selectedCallpoints[0];
      let firstCallpointSettings = this.selectedCallpoints[0].callpointSettings;

      if (firstCallpointSettings === null || firstCallpointSettings === undefined) {
         firstCallpointSettings = new CallpointSettings();
      }

      // Loop index starts at 1, because we don't want to compare the first callpoint setting with itself.
      // Loop through all the callpoints and compare their properties with each other.
      for (let i = 1; i < this.selectedCallpoints.length; i++) {

         // Compare availability section.
         availabilityMatch = availabilityMatch && this.compareSettingProperties(firstCallpointSettings,
            this.selectedCallpoints[i].callpointSettings, AVAILABILITY_PROPERTIES);

         // Compare frequency section.
         frequencyMatch = frequencyMatch && this.compareCallpointProperties(firstCallpoint,
            this.selectedCallpoints[i], FREQUENCY_PROPERTIES);

         // Compare duration section.
         durationMatch = durationMatch && this.compareCallpointProperties(firstCallpoint,
            this.selectedCallpoints[i], DURATION_PROPERTIES);

         // Compare location section.
         locationMatch = locationMatch && this.compareCallpointProperties(firstCallpoint,
            this.selectedCallpoints[i], LOCATION_PROPERTIES);

         // Compare priority section.
         priorityMatch = priorityMatch && this.compareCallpointProperties(firstCallpoint,
            this.selectedCallpoints[i], PRIORITY_PROPERTIES);
         
         // Compare critical section.
         criticalMatch = criticalMatch && this.compareCallpointProperties(firstCallpoint,
            this.selectedCallpoints[i], CRITICAL_PROPERTIES);
      }

      this.callpointSettingsViewModel.availabilitySameValuesSet = availabilityMatch;
      this.callpointSettingsViewModel.frequencySameValuesSet = frequencyMatch;
      this.callpointSettingsViewModel.durationSameValuesSet = durationMatch;
      this.callpointSettingsViewModel.locationSameValuesSet = locationMatch;
      this.callpointSettingsViewModel.prioritySameValuesSet = priorityMatch;
      this.callpointSettingsViewModel.criticalSameValuesSet = criticalMatch;
   }

   private compareSettingProperties(value: CallpointSettings, other: CallpointSettings, propertyNames: string[]): boolean {
      let match: boolean = true;

      // Create a dummy object to make comparison easier
      if (other === null || other === undefined) {
         other = new CallpointSettings();
      }

      for (let property of propertyNames) {
         if (value[property] instanceof Date) {
            match = match && CallsmartUtils.isEqualDates(value[property], other[property]);
         }
         else {
            match = match && JSON.stringify(value[property]) === JSON.stringify(other[property]);
         }

         if (!match) {
            // console.log('match failed:' + property, value[property], other[property]);

            // The properties don't match so return the result as there is no need to continue
            // testing other properties.
            return match;
         }
      }

      return match;
   }

   private compareCallpointProperties(value: Callpoint, other: Callpoint, propertyNames: string[]): boolean {
      let match: boolean = true;

      for (let property of propertyNames) {
         if (value[property] instanceof Date) {
            match = match && CallsmartUtils.isEqualDates(value[property], other[property]);
         }
         else {
            match = match && JSON.stringify(value[property]) === JSON.stringify(other[property]);
         }

         if (!match) {
            // console.log('match failed:' + property, value[property], other[property]);
            // The properties don't match so return the result as there is no need to continue
            // testing other properties.
            return match;
         }
      }

      return match;
   }

   // Locks/unlocks all the visits for the selected callpoints.
   private lockVisits(lockingType: LockingTypes){
      if (this.selectedCallpoints && this.selectedCallpoints.length > 0 && !this.fullyDeferredCallpoints) {
         let callpointReferences: string[] = this.selectedCallpoints.map(callpoint => callpoint.reference);
         this._applicationStore.scheduleStore.lockCallpointVisits(this.selectedCallpoints[0].callerId, callpointReferences, lockingType, this._applicationStore.uiStore.getActiveView());
      }
   }

   // Enables / disables all selected callpoints.
   private setCallpointsDisabledState(disable: boolean){
      if (this.selectedCallpoints && this.selectedCallpoints.length > 0) {
         this._applicationStore.callpointsStore.setCallpointsDisabledState(this.selectedCallpoints, disable);

         // After enabling / disabling callpoints the menu items must refresh their states.
         this.setLockingMenuItemStates();     
      }
   }

   private refeshScheduleAndVisits() {
      // reload the current callers visits and schedule as locking status  might have changed
      this._applicationStore.visitsStore.loadVisitsForCaller(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this._applicationStore.callersStore.selectedCaller.callerId
      );

      this._applicationStore.scheduleStore.loadDiaryEvents(
         this._applicationStore.callersStore.selectedCaller.callerId,
         this._applicationStore.projectsStore.selectedProject.projectId,
         false
      );
   }

   // Works out the new menu items state after performing a locking action.
   private setLockingMenuItemStates() {
      if (this.selectedCallpoints && this.selectedCallpoints.length > 0) {

         let callpointReferences: string[] = this.selectedCallpoints.map(callpoint => callpoint.reference);
         // Checks and stores whether all visits for the selected callpoints are locked to day.
         this.areAllVisitsLockedToDay = this._applicationStore.scheduleStore.
            currentCallerDiaryEvents.filter(event => callpointReferences.some(ref => ref === event.callpointId)).
            every(event => event.locking == LockingTypes.day);
         // Checks and stores whether all visits for the selected callpoints are locked to day and time.
         this.areAllVisitsLockedToDayTime = this._applicationStore.scheduleStore.
            currentCallerDiaryEvents.filter(event => callpointReferences.some(ref => ref === event.callpointId)).
            every(event => event.locking == LockingTypes.dayAndTime);
         // Checks and stores whether all visits for the selected callpoints are unlocked.
         this.areAllVisitsUnlocked = this._applicationStore.scheduleStore.
            currentCallerDiaryEvents.filter(event => callpointReferences.some(ref => ref === event.callpointId)).
            every(event => event.locking == LockingTypes.none);
         // Checks and stores whether the selected callpoints are fully deferred.
         this.fullyDeferredCallpoints = this.areAllDeferrals();

         // Checks and stores whether the selected callpoints are disabled.
         this.areAllCallpointsDisabled = this.selectedCallpoints.every(c => c.isDisabled);
         this.areAllCallpointsEnabled = this.selectedCallpoints.every(c => !c.isDisabled);
      }
   }

   // Checks whether all selected callpoints are fully deferred.
   private areAllDeferrals(): boolean {
      let callpointIds: number[] = this.selectedCallpoints.map(callpoint => callpoint.callpointId);
      let callpointVisits: Visit[] = this._applicationStore.visitsStore.getCallpointVisits(callpointIds);
      return callpointVisits.every(visit => !visit.isScheduled);
   }
}
