import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// This store is responsible for syncing the open tabs across workspaces and storing the route URL of the
// workspace the contextual panel has been loaded for.

@Injectable()
export class ContextualPanelStore {

   // Selected tabs in the contextual panel.
   private _selectedTabs: BehaviorSubject<ReadonlyArray<number>> = new BehaviorSubject<ReadonlyArray<number>>([0]);
   public selectedTabs$: Observable<ReadonlyArray<number>> = this._selectedTabs.asObservable();

   // The route URL the contextual panel belongs to.
   private _requestingUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public _requestingUrl$: Observable<string> = this._requestingUrl.asObservable();

   public get requestingUrl() {
      return this._requestingUrl.getValue();
   }

   constructor() { }

   public setSelectedTabs(tabs: number[]) {
      this._selectedTabs.next(tabs);
   }

   public setRequestingUrl(url: string) {
      this._requestingUrl.next(url);
   }
}
