<form novalidate #summaryForm="ngForm" class="form-horizontal" autocomplete="off">
   <div class="panel">
      <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
         <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-33}">
            <div style="display: -webkit-box; display: -ms-flexbox; width:auto;">
               <!-- Settings summary -->
               <div class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box;display: -ms-flexbox; overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Settings</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                 aria-hidden="true" (click)="goToSettings()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Project name:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9">
                              <label class="control-label-bold">{{projectWizardModel?.projectName}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Call cycle length:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.numberOfWeeks}} weeks</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Call cycle start date:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.startCycleDate | date:'d MMM y'}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Call cycle end date:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.endCycleDate | date:'d MMM y'}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Optimiser iterations:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label">Fast:</label> <label class="control-label-bold">{{projectWizardModel?.quickOptimiserIteration}}</label>
                              <label class="control-label" style="margin-left:10px">Full:</label> <label class="control-label-bold">{{projectWizardModel?.fullOptimiserIteration}}</label>
                           </div>
                        </div>
                        <div class="row">
                              <div class="col-md-4 col-lg-3 col-xl-3">
                                 <label class="control-label">Travel model:</label>
                              </div>
                              <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                                 <label class="control-label-bold">{{projectWizardModel?.travelModel}}</label>
                              </div>
                           </div>
                     </div>
                  </div>
               </div>
               <div class="display-flex-column" style="width: 2px"></div>
               <!-- Callers summary -->
               <div class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box;display: -ms-flexbox;overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Callers</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                 aria-hidden="true" (click)="goToCallers()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">File imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.callersFileName}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callers imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.numberOfCallers}}</label>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Uploading to server:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left;padding-left: 5px;">
                              <p-progressBar [value]="callerProgressValue" [showValue]="false" ></p-progressBar>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Max. visits per day:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold" *ngIf="projectWizardModel?.visitsPerDayNoMaximum; else maxVisitsPerDay">
                                 No maximum
                              </label>
                              <ng-template #maxVisitsPerDay>
                                 <label class="control-label-bold">{{projectWizardModel?.maxVisitsPerDay}}</label>
                              </ng-template>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>

            <div style="display: -webkit-box; display: -ms-flexbox; width: auto;">
               <!-- Callpoints summary -->
               <div class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box; display: -ms-flexbox;overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Callpoints</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                 aria-hidden="true" (click)="goToCallpoints()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">File imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.callpointsFileName}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callpoints imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.numberOfCallpoints}}</label>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Uploading to server:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left; padding-left: 5px;">
                              <p-progressBar [value]="callpointProgressValue" [showValue]="false" ></p-progressBar>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
               <div class="display-flex-column" style="width: 2px"></div>
               <!-- Scheduling summary -->
               <div class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box;display: -ms-flexbox; overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Events</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                       aria-hidden="true" (click)="goToEvents()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Events added:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.projectEvents.length}}</label>
                           </div>
                        </div>


                     </div>
                  </div>
               </div>
            </div>
         </perfect-scrollbar>
      </div>
   </div>
</form>
