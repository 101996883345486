<!--Taken from https://github.com/primefaces/primeng/blob/master/src/app/components/dropdown/dropdown.ts and modified-->
<div #container [ngClass]="{'ui-dropdown ui-widget ui-state-default ui-corner-all ui-helper-clearfix':true,'ui-state-disabled':disabled,'ui-dropdown-open':panelVisible,'ui-state-focus':focus}" 
   (click)="onMouseclick($event)"
   [ngStyle]="style" [class]="styleClass">
   <div class="ui-helper-hidden-accessible" *ngIf="autoWidth">
      <select [required]="required" name="options" [attr.aria-label]="selectedOption ? selectedOption.label : ' '" tabindex="-1" aria-hidden="true">
         <option *ngFor="let option of options" [value]="option.value" [selected]="selectedOption == option">{{option.label}}</option>
      </select>
   </div>
   <div class="ui-helper-hidden-accessible">
      <input #in [attr.id]="inputId" type="text" [attr.aria-label]="selectedOption ? selectedOption.label : ' '" readonly (focus)="onInputFocus($event)" role="listbox"
         (blur)="onInputBlur($event)" (keydown)="onKeydown($event)" [disabled]="disabled" [attr.tabindex]="tabindex" [attr.autofocus]="autofocus">
   </div>
   <!-- Custom change to user innerHTML attribute for correct HTML encoding of extended characters-->
   <label [ngClass]="{'ui-dropdown-label ui-inputtext ui-corner-all':true,'ui-dropdown-label-empty':(label === null)}" *ngIf="!editable&&(label !== null)" [innerHTML]="label||'empty'"></label>
   <!-- End change -->
   <label [ngClass]="{'ui-dropdown-label ui-inputtext ui-corner-all ui-placeholder':true}" *ngIf="!editable&&(label === null)">{{placeholder}}</label>
   <input type="text" [attr.aria-label]="selectedOption ? selectedOption.label : ' '" class="ui-dropdown-label ui-inputtext ui-corner-all"
      *ngIf="editable" [value]="editableLabel" [disabled]="disabled" [attr.placeholder]="placeholder" (click)="onEditableInputClick($event)"
      (input)="onEditableInputChange($event)" (focus)="onEditableInputFocus($event)" (blur)="onInputBlur($event)">
   <div class="ui-dropdown-trigger ui-state-default ui-corner-right">
      <span class="fa fa-fw fa-caret-down ui-c"></span>
   </div>
   <div #panel [ngClass]="'ui-dropdown-panel ui-widget-content ui-corner-all ui-helper-hidden ui-shadow'" [@panelState]="panelVisible ? 'visible' : 'hidden'"
      [style.display]="panelVisible ? 'block' : 'none'" [ngStyle]="panelStyle" [class]="panelStyleClass">
      <div *ngIf="filter" class="ui-dropdown-filter-container" (input)="onFilter($event)" (click)="$event.stopPropagation()">
         <input #filter type="text" autocomplete="off" class="ui-dropdown-filter ui-inputtext ui-widget ui-state-default ui-corner-all"
            [attr.placeholder]="filterPlaceholder" (keydown.enter)="$event.preventDefault()" (keydown)="onKeydown($event)">
         <span class="fa fa-search"></span>
      </div>
      <div #itemswrapper class="ui-dropdown-items-wrapper" [style.max-height]="scrollHeight||'auto'">
         <ul class="ui-dropdown-items ui-dropdown-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" *ngIf="lazy ? panelVisible : true">
            <li *ngFor="let option of optionsToDisplay;let i=index" [ngClass]="{'ui-dropdown-item ui-corner-all':true, 'ui-state-highlight':(selectedOption == option), 
                            'ui-dropdown-item-empty':!option.label||option.label.length === 0}" (click)="onItemClick($event, option)">
               <!-- Custom change to user innerHTML attribute for correct HTML encoding of extended characters-->
               <span *ngIf="!itemTemplate" [innerHTML]="option.label||'empty'"></span>
               <!-- End change -->
               <ng-template [pTemplateWrapper]="itemTemplate" [item]="option" *ngIf="itemTemplate"></ng-template>
            </li>
         </ul>
      </div>
   </div>
</div>