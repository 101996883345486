import {
   Component,
   Input,
   Output,
   EventEmitter,
   OnDestroy,
   ElementRef} from '@angular/core';

import { MapPoint } from 'app/models/map-point';
import { ApplicationStore } from 'app/stores/application-store';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';

declare var google; // allows google api namespace to work
declare function require(name: string): any; // requires is a way to reference javascript files
var MarkerWithLabel = require('markerwithlabel')(google.maps); // use requires to reference the marker with label class in the js file

declare var MarkerClusterer;

@Component({
   selector: 'callsmart-google-map',
   templateUrl: './google-map.component.html'
})

// This class uses ng prime g-map to show a google map. markers are shown on the map as layers representing callers and call points
// Addtional layer, types can be applied such as kml files or Polygon layers
// Google API reference
// https://developers.google.com/maps/documentation/javascript/3.exp/reference
//
// G-map CSS class bug
// G-map will not respect the class = "CSS"  that might define map dimensions.
// To get round this there is a conatiner div that gets its height set from @media
// then the gmap element has its inline styles set to heigh=100% meaning it inherits from parent div
//
// Extending Google Map Marker
// I tried to extend this but ran into trouble as it is dynamically loaded, based on when the api script is loaded.
// see http://stackoverflow.com/questions/40338662/how-do-you-extend-a-class-in-typescript-that-is-loaded-dynamically
// I went down another route of utilising javascripts ablity to dunamically add properties onto any object,
//
// additional properties tagged onto the google markers and polylines
// google.maps.Polyline I have added the following properties
// isMarker: false,
// identifier:  'lat' + startLat.toString() + 'lng' + startLng.toString() + 'lat' + endLat.toString() +'lng' + endLng.toString()
//
// google.maps.Marker & MarkerWithLabel I have added the following properties
// point: p,
// icon: undefined,
// selectIcon: this.icons[1],  //blue for selected icon
// selected: false,
// orginalIcon: undefined,
// isMarker: true
//
// Marker with label
// Marker with label comes from the npm install --save  markerwithlabel
// https://www.npmjs.com/package/markerwithlabel
// This is a third party javascript libary, to reference it in type script, we need to use the "requires""
// var MarkerWithLabel = require('markerwithlabel')(google.maps);
// Available properties for configuring marker with label examples
// https://github.com/nmccready/google-maps-utility-library-v3-markerwithlabel/blob/master/docs/examples.html
// labelClass: "labels", // the CSS class for the label css class is not being obeyed
// use the labelStyle property instead to manipulate the marler with label's label.
//            labelStyle: {
//               'opacity': '0.75',
//               'color': 'white',
//               'background-color': 'red',
//               'font-size': '10px',
//               'text-align': 'center',
//               'white-space': 'nowrap',
//            }
//
// articles on how to change the google map colours
// https://www.absolute-design.co.uk/services/web-design/how-to-create-a-black-and-white-google-map/
// like insite we can change individual features such as roads, water exctra
// https://developers.google.com/maps/documentation/static-maps/styling
// stewartt found a cool style builder for map colours
// https://snazzymaps.com/style/15/subtle-grayscale
//
// Marker clustering
// the javascript file is in assets/js for this project. It is referenced in the index.html file
// Documentation can be found here
// http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/markerclusterer/docs/reference.html
// events and methods
// http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/markerclusterer/docs/reference.html
// Options on the clustering
// gridSize is defualted to 60 this value determines how close points must be before they are clustered
// maxZoom sets the level where clustering will stop
//
// Google zoom levels
// 1: World
// 5: Landmass/continent
// 10: City
// 15: Streets
// 20: Buildings
//
// Documentation on using the google direction service to generate travel route lines
// https://developers.google.com/maps/documentation/javascript/directions
export class GoogleMapComponent implements OnDestroy {
   // cluster marker options
   private clusterMaxZoom: number = 12;
   private clusterGridSize: number = 20;

   // map icons
   public mapIconCallPoint: string = 'assets/icons/CP.png';
   public mapIconCallPointSelected: string = 'assets/icons/CP_Selected.png';
   public mapIconCallPointHighlighted: string =
      'assets/icons/CP_Highlighted.png'; // used for visit
   public mapIconCallPointOverNight: string = 'assets/icons/CP_OverNight.png'; // used for visit ending in overnight
   public mapIconVisitSmall: string = 'assets/icons/Visit_Small.png';
   public mapIconCaller: string = 'assets/icons/Caller.png';
   public mapIconCallerSelected: string = 'assets/icons/Caller_Selected.png';
   public mapIconCallerSmall: string = 'assets/icons/Caller_small.png';
   public mapIconCallerSelectedSmall: string =
      'assets/icons/Caller_small_selected.png';

   public mapIconFrequencyLabelBase: string = 'assets/icons/frequency-labels/';

   // map custom settings
   public floatMapSettingsButtonsTop = 0;
   public showMapSettingsConfig = false;
   public mouseMapSettingsConfig = false;

   // map custom settings
   public floatButtonsTop = 0;
   public floatButtonsLeft = 0;
   public floatConfigLeft = 0;

   public markerCluster: any;

   public get isAutoZoom() {
      return this._applicationStore.mapsStore.isMapAutoZoom;
   }

   public set isAutoZoom(isAuto) {
      this._applicationStore.mapsStore.isMapAutoZoom = isAuto;
   }

   public get isMapBlackAndWhite() {
      return this._applicationStore.mapsStore.isBlackAndWhite;
   }

   public set isMapBlackAndWhite(isAuto) {
      this._applicationStore.mapsStore.isBlackAndWhite = isAuto;
      this.setMapStyle();
   }

   //product owner wants to reverse the toggle button on this so standard is on left real pain
   public get useMapClusterPoints() {
      return !this._applicationStore.mapsStore.useMapClusterPoints;
   }

   //product owner wants to reverse the toggle button on this so standard is on left real pain
   public set useMapClusterPoints(isAuto) {
      this._applicationStore.mapsStore.useMapClusterPoints = !isAuto;
      if (!isAuto) {
         this.enableClustering();
         this.clusteringModeSelected.emit(true);
      } else {
         this.disableClustering();
         this.clusteringModeSelected.emit(false);
      }
   }

   public set useDirectRoutes(isAuto) {
      this._applicationStore.mapsStore.useDirectRoutes = isAuto;

      if (isAuto) {
         this.removeTravelLines();
      }

      this.directLinesModeSelected.emit(isAuto);
   }

   public get useDirectRoutes() {
      return this._applicationStore.mapsStore.useDirectRoutes;
   }

   public set useCallerDotIcon(isAuto) {
      this._applicationStore.mapsStore.useCallerDotIcon = isAuto;
      this.useCallerDotIconSelected.emit(isAuto);
   }

   public get useCallerDotIcon() {
      return this._applicationStore.mapsStore.useCallerDotIcon;
   }

   public set useVisitDotIcon(isAuto) {
      this._applicationStore.mapsStore.useVisitDotIcon = isAuto;
      this.useVisitDotIconSelected.emit(isAuto);
   }

   public get useVisitDotIcon() {
      return this._applicationStore.mapsStore.useVisitDotIcon;
   }

   public set showFrequency(isAuto) {
      this._applicationStore.mapsStore.showFrequency = isAuto;
      this.showFrequencySelected.emit(isAuto);
   }

   public get showFrequency() {
      return this._applicationStore.mapsStore.showFrequency;
   }

   // map properties
   public infoWindow: any; // single info window shared between markers to improve speed
   public options: any; // map options
   public mapStyleGrey: any; // google map style black and white
   public mapTypeGrey: any; // google map type black and white
   public mapStyleOrignal: any; // google map style black and white
   public mapTypeOrignal: any; // google map type black and white
   public overlays: any[] = []; //array of google layer types, markers and polylines
   public map: any; // underling map object that is wrapped by g-map
   public selectedPoints: MapPoint[] = []; // list of all the selected map points
   public directionsDisplay: any; // experiment with google direction service
   public directionsService: any; // experiment with google direction service

   // keep track of what the parent has set the map height to be
   @Input() containerHeight: number;

   // Control input/output propteries
   @Output() clusteringModeSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() directLinesModeSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() useCallerDotIconSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() useVisitDotIconSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() showFrequencySelected: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() googleMapTilesLoaded: EventEmitter<any> = new EventEmitter<any>();
   @Output() googleMapResize: EventEmitter<any> = new EventEmitter<any>();
   @Output() googleMapIdle: EventEmitter<any> = new EventEmitter<any>();
   @Output() onMapReady: EventEmitter<any> = new EventEmitter<any>();
   @Output() googleDirectionServiceError: EventEmitter<any> = new EventEmitter<any>();
   @Output() markerClicked: EventEmitter<MapPoint> = new EventEmitter<MapPoint>();
   @Input() optimiseMarker: Boolean = true; // by defualt google does optimise the markers but to help alan wen need to set it false some times
   @Input() clusterMarkerImagePath: string =
      'assets/icons/clustermarkers/orginals/m'; // changing the path changes the directory that holds the coloured cluster markers
   @Input() disableMapMarkerClick: boolean = false;
   @Input() isCtrlKeyPressed: boolean = false;
   @Input() displayFrequencyOption: boolean = true;
   @Input() defaultLocation = { lat: 51.491378, lng: -0.195999 }; // default location for London.

   constructor(
      private componentElement: ElementRef,
      private _applicationStore: ApplicationStore
   ) {
      // set single info window for the map markers, they all reuse the same window
      this.infoWindow = new google.maps.InfoWindow();

      // set the colour of google map layers one custom one orginal
      this.setMapGreyStyle();
      this.mapTypeGrey = new google.maps.StyledMapType(this.mapStyleGrey, {
         name: 'Grayscale'
      });
      this.mapStyleOrignal = [];
      this.mapTypeOrignal = new google.maps.StyledMapType(
         this.mapStyleOrignal,
         { name: 'Orginal' }
      );

      // set up the custom map buttons
      this.setFloatingButtonsPosition();
   }

   public ngOnInit(): void {
      // configure the google map options.
      
      this.options = {
         center: this.defaultLocation, //start position
         zoom: 13, // defualt zoom level
         mapTypeControl: false,
         backgroundColor: '#eeeeee', // set background colour,
         streetViewControl: false
      };
   }

   public ngOnDestroy() {
      // clear the google map
      this.map = null;
   }

   public resizeMap() {
      if (this.map) {
         google.maps.event.trigger(this.map, 'resize');
      }

      this.setFloatingButtonsPosition();
   }

   //Changes the center of the map by the given distance in pixels.
   //If the distance is less than both the width and height of the map, the transition will be smoothly animated.
   //Note that the map coordinate system increases from west to east (for x values) and north to south (for y values).
   public panBy(x: number, y: number) {
      this.map.panBy(x, y);
   }

   public getZoom() {
      return this.map.getZoom();
   }

   public setZoom(zoom: number) {
      this.map.setZoom(zoom);
   }

   // toggle the display map settings
   public toggleMapSettings() {
      this.showMapSettingsConfig = !this.showMapSettingsConfig;

      if (this.showMapSettingsConfig) {
         // give it 5 seconds to auto close
         setTimeout(() => this.autoCloseMapSettingsConfigPanel(), 5000);
      }
   }

   public handleMouseOverMapSettingsConfig(event) {
      this.mouseMapSettingsConfig = true;
   }

   public handleMouseLeaveMapSettingsConfig(event) {
      this.mouseMapSettingsConfig = false;
   }

   public handleMapClick(event) {
      this.closeConfigPanels();
   }

   public handleOverlayClick(event) {
      this.closeConfigPanels();

      if (!this.disableMapMarkerClick) {
         let isMarker = event.overlay.getTitle != undefined;

         if (isMarker) {
            // based on the selected marker build up the info for the popup window
            let marker = event.overlay;
            let title = event.overlay.getTitle();
            let isSelected = event.overlay.selected;
            let pointInfo: MapPoint = event.overlay.point;
            this.infoWindow.setContent(pointInfo.infoWndBody);

            // disable the info window currently not required
            //this.infoWindow.open(event.map, event.overlay);

            // single selection mode of map markers
            if (this.isCtrlKeyPressed) {
               if (isSelected) {
                  this.deselectMarker(marker);
               } else {
                  this.selectMarker(marker);
               }
            } else {
               this.singleSelectionMapMarkerMode(marker);
            }

            this.markerClicked.emit(marker); // fire event
         }
      }
   }

   //callers icon not showing selected sometimes add a belt and braces check
   public checkSelectedIconCorrect() {
      this.selectedPoints.forEach(point => {
         let marker = this.overlays.find(
            m => m.isMarker == true && m.point.guid == point.guid
         );
         marker.setMap(null);
         marker.setMap(this.map);
      });
   }

   // any object using the selection code must have a GUID property
   public SetMapSelectionByGuid(guid: string, isSelected: boolean) {
      /// no true object matching in javascript,
      // Client side all objects being mapped must now have a GUID
      // below was the old way
      //var marker = this.overlays.find(m => (m.isMarker == true && JSON.stringify(m.point.data) === JSON.stringify(data)));

      let marker = this.overlays.find(
         m => m.isMarker == true && m.point.guid == guid
      );

      if (marker != undefined) {
         if (!isSelected) {
            // update new state info
            marker.icon = marker.orginalIcon;
            marker.setIcon(marker.orginalIcon);
            marker.selected = false;

            let index = this.selectedPoints.findIndex(
               x => x.guid == marker.point.guid
            );
            this.selectedPoints.splice(index, 1);

            // refesh is done bu clearing and re-adding the map to the marker
            //marker.setMap(null);
            //marker.setMap(this.map);
         } else {
            // update new state info
            marker.selected = true;
            marker.icon = marker.selectIcon;
            marker.setIcon(marker.selectIcon);

            let match = this.selectedPoints.findIndex(
               x => x.guid == marker.point.guid
            );

            if (match == -1) {
               this.selectedPoints.push(marker.point);
            }

            // refesh is done bu clearing and re-adding the map to the marker
            //marker.setMap(null);
            //marker.setMap(this.map);
         }
      }
   }

   public focusMapOnSelection() {
      // only do this in auto zoom mode
      if (this._applicationStore.mapsStore.isMapAutoZoom) {
         if (this.selectedPoints.length == 1) {
            // if single selection re-center to point
            if (this.map) {
               this.map.panTo({
                  lat: this.selectedPoints[0].lat,
                  lng: this.selectedPoints[0].lng
               });
               // zoom to street level
               this.map.setZoom(this.clusterMaxZoom + 1);
            }
         }
         else {
            // else use selected markers to fit to bounds
            this.viewSelectedItems();
         }
      }
      else {
         // goto last logged center of the map if in maanual mode
         this.map.setCenter(this._applicationStore.mapsStore.manualMapCenter);
         this.map.setZoom(this._applicationStore.mapsStore.manualMapZoom);
      }
   }

   public focusMapOnMarkers() {
      if (this.selectedPoints.length === 0) {
         this.viewWholelayer(this.overlays);
      }
      else {
         this.focusMapOnSelection();
      }
   }

   // set the top and left postion of the floating map buttons for things like auto focus
   private setFloatingButtonsPosition() {
      this.floatButtonsTop = this.componentElement.nativeElement.offsetTop + 5;
      //this.floatStyleButtonsTop = this.componentElement.nativeElement.offsetTop + 45;
      this.floatMapSettingsButtonsTop =
         this.componentElement.nativeElement.offsetTop + 5;

      this.floatButtonsLeft =
         this.componentElement.nativeElement.offsetLeft + 5;
      this.floatConfigLeft =
         this.componentElement.nativeElement.offsetLeft + 44;
   }

   // it is quicker to generate some dummy markers for view whole layer to set the bounds
   // than to find the real markers selected markers in the overlays array
   private viewSelectedItems() {
      let dummyMarkers = [];
      for (let p of this.selectedPoints) {
         let marker = this.overlays.find(m => m.point == p);
         dummyMarkers.push(marker);
      }

      if (dummyMarkers.length > 1) {
         this.viewWholelayer(dummyMarkers);
      }
   }

   //Add google markers to the map
   public addMarkers(points: MapPoint[] = []) {
      if (this.showFrequency && this.displayFrequencyOption) {
         // this.addMarkersWithLabel(points);
         this.addMarkersWithFrequecyLabel(points);
      } else {
         this.addMarkersWithoutLabel(points);
      }
   }

   public addMarkersWithoutLabel(points: MapPoint[] = []) {
      for (let p of points) {
         let marker = new google.maps.Marker({
            position: { lat: p.lat, lng: p.lng },
            title: p.infoWndTitle,
            icon: p.icon,
            point: p,
            optimized: this.optimiseMarker,
            selectIcon: p.selectIcon,
            selected: false,
            orginalIcon: p.orginalIcon,
            isMarker: true,
            usedInRoue: false
         });

         // check if  exists
         let exists = this.overlays.find(m => m == marker);

         if (exists == undefined) {
            this.overlays.push(marker);
         }
      }

      this.enableClustering();

      // The caller workspace auto selects the first caller in the list and the map
      // needs to focus on that so don't show the whole layer.
      if (this._applicationStore.uiStore.getActiveView() !== WorkspaceViewType.Callers) {
         this.viewWholelayer(this.overlays);
      }
   }

   // In an attempt to speed up markers with frquency the following was attempted wand failed
   // https://stackoverflow.com/questions/20636943/how-to-optimize-markerwithlabel-on-google-maps-when-having-too-many-markers
   // What is working but limited for future label fixes is using  an icon that combines both the callpoint and the frequecy number
   public addMarkersWithFrequecyLabel(points: MapPoint[] = []) {
      for (let p of points) {
         let freqLabelIcon =
            this.mapIconFrequencyLabelBase + p.labelContent + '-unselected.png';

         let freqLabelIconSelected =
            this.mapIconFrequencyLabelBase + p.labelContent + '-selected.png';

         let marker = new google.maps.Marker({
            position: { lat: p.lat, lng: p.lng },
            title: p.infoWndTitle,
            icon: freqLabelIcon,
            point: p,
            optimized: this.optimiseMarker,
            selectIcon: freqLabelIconSelected,
            selected: false,
            orginalIcon: freqLabelIcon,
            isMarker: true,
            usedInRoue: false
         });

         // check if  exists
         let exists = this.overlays.find(m => m == marker);

         if (exists == undefined) {
            this.overlays.push(marker);
         }
      }

      this.enableClustering();

      this.viewWholelayer(this.overlays);
   }

   // add third party marker that has a label below it for text
   // these markers are not optimised like a google marker and can be slow
   private addMarkersWithLabel(points: MapPoint[] = []) {
      for (let p of points) {
         let marker = new MarkerWithLabel({
            position: { lat: p.lat, lng: p.lng },
            title: p.infoWndTitle,
            point: p,
            icon: p.icon,
            selectIcon: p.selectIcon,
            selected: false,
            orginalIcon: p.orginalIcon,
            zoomIcon: p.zoomIcon,
            isMarker: true,
            usedInRoue: false,
            optimized: this.optimiseMarker,
            labelContent: p.labelContent,
            labelAnchor: new google.maps.Point(10, 0),
            //labelClass: "labels", // the CSS class for the label css class is not being obeyed
            labelStyle: {
               opacity: '1',
               color: 'black',
               'background-color': 'white',
               'font-size': '10px',
               'text-align': 'center',
               'white-space': 'nowrap',
               border: '1px',
               'border-style': 'solid',
               'padding-top': '2px',
               'padding-bottom': '2px',
               'padding-left': '5px',
               'padding-right': '5px'
            }
         });

         // check if  exists
         let exists = this.overlays.find(m => m == marker);

         if (exists == undefined) {
            this.overlays.push(marker);
         }
      }

      this.enableClustering();
      this.viewWholelayer(this.overlays);
   }

   //Build  a route between a subs set of points using a polyline. start point can be distinquished with a different icon
   public buildRoute(
      startPoint: MapPoint,
      routePoints: MapPoint[],
      IncludeStartPointInBounds: Boolean,
      addLabel: Boolean,
      includeReturnToStartLine: Boolean,
      excludeStartLine
   ) {
      //this.overlays = []; // dont clear the map
      let routePointMarkers = [];

      // remove any markers that may exist for this point
      this.removePointsFromOverlay(routePoints);

      routePointMarkers = this.addRouteMarkersToOverlay(routePoints, addLabel);

      // if you dont remove the route lines they will just get bolder and bolder as more andmore are added
      this.removeRouteLines(startPoint, routePoints);

      if (this._applicationStore.mapsStore.useDirectRoutes) {
         this.buildRouteLines(
            startPoint,
            routePoints,
            true,
            includeReturnToStartLine,
            excludeStartLine
         );
      } else {
         this.buildRouteDirections(startPoint, routePoints);
      }

      //make sure the start point is not in the cluster
      this.removePointFromCluster(startPoint);

      if (IncludeStartPointInBounds) {
         this.viewWholelayer(this.overlays);
      } else {
         this.viewWholelayer(routePointMarkers);
      }
   }

   //Build  a route between a subs set of points using a polyline. start point can be distinquished with a different icon
   public buildRouteNoComuteLines(
      routePoints: MapPoint[],
      addLabel: Boolean,
      includeReturnToStartLine: Boolean
   ) {
      //this.overlays = []; // dont clear the map
      let routePointMarkers = [];

      // remove any markers that may exist for this point
      this.removePointsFromOverlay(routePoints);

      routePointMarkers = this.addRouteMarkersToOverlay(routePoints, addLabel);

      // first point is  the start point
      let startPoint: MapPoint = routePoints[0];

      // if you dont remove the route lines they will just get bolder and bolder as more andmore are added
      this.removeRouteLines(startPoint, routePoints);

      if (this._applicationStore.mapsStore.useDirectRoutes) {
         this.buildRouteLines(
            startPoint,
            routePoints,
            false,
            includeReturnToStartLine,
            false
         );
      } else {
         this.buildRouteDirections(startPoint, routePoints);
      }

      // make sure the start point is not in the cluster
      this.removePointFromCluster(startPoint);

      this.viewWholelayer(routePointMarkers);
   }

   public clearRoute(startPoint: MapPoint, routePoints: MapPoint[]) {
      // remove any markers that may exist for this point
      this.removePointsFromOverlay(routePoints);
      this.removePointsFromOverlay([startPoint]);
      this.removeRouteLines(startPoint, routePoints);
      this.removeTravelLines();
   }

   public clear() {
      this.overlays = [];
      if (this.markerCluster) {
         this.markerCluster.clearMarkers();
      }
      this.removeTravelLines();
   }

   // used to get the underlying google map after it has initialised
   public setMap(event) {
      this.map = event.map;

      // set the map style black or white
      this.setMapStyle();

      // set clustering
      this.enableClustering();

      this.onMapReady.emit(this); // fire event

      //add event listener for zoom
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
         this.mapZoomChanged();
      });
      google.maps.event.addListener(this.map, 'idle', () => {
         this.onGoogleMapIdle();
      });
      google.maps.event.addListener(this.map, 'tilesloaded', () => {
         this.onGoogleMapTilesLoaded();
      });
      google.maps.event.addListener(this.map, 'resize', () => {
         this.onGoogleMapResize();
      });
      google.maps.event.addListener(this.map, 'center_changed', () => {
         this.onGoogleMapCenterChanged();
      });
      google.maps.event.addListener(this.map, 'bounds_changed', () => {
         this.onGoogleMapBoundsChanged();
      });

      //experiment with google direction service
      this.directionsDisplay = new google.maps.DirectionsRenderer({
         suppressMarkers: true,
         preserveViewport: this._applicationStore.mapsStore.isMapAutoZoom
      });
      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay.setMap(this.map);
   }

   // remove a specific point from the list of points being clustered
   public removePointFromCluster(point: MapPoint) {
      let index = this.overlays.findIndex(
         m => m.isMarker == true && m.point.guid === point.guid
      );

      if (index != -1) {
         //remove from any cluster
         if (this.markerCluster) {
            this.markerCluster.removeMarker(this.overlays[index]);
         }
      }
   }

   private onGoogleMapTilesLoaded() {
      this.googleMapTilesLoaded.emit(null);
   }

   private onGoogleMapResize() {
      this.googleMapResize.emit(null);
   }

   private onGoogleMapIdle() {
      this.googleMapIdle.emit(null);
   }

   private onGoogleMapCenterChanged() {
      this._applicationStore.mapsStore.manualMapCenter = this.map.getCenter();
   }

   private onGoogleMapBoundsChanged() {
      this._applicationStore.mapsStore.mapBounds = this.map.getBounds();
   }

   private mapZoomChanged() {
      this._applicationStore.mapsStore.manualMapZoom = this.map.getZoom();

      // test log the states to conole
      /* if (this._applicationStore.mapsStore.useMapClusterPoints) {
          if (this.markerCluster) {
             console.log("zoom-level = " + this.getZoom());
             console.log("cluster max zoom = " + this.markerCluster.getMaxZoom());
             console.log("cluster grid size = " + this.markerCluster.getGridSize());
          }
       }*/

      //the lower the zoom level the more zoomed out 1 being map of world
      /*if (this.map.getZoom() > 4) {

         this.overlays.forEach(marker => {
            if (marker.isMarker) {
               if (!marker.isSelected) {
                  marker.setIcon(marker.orginalIcon);
               }
            }
         });
      }else{
         this.overlays.forEach(marker => {
            if (marker.isMarker) {
               if (!marker.isSelected) {
                  marker.setIcon(marker.zoomIcon);
               }
            }
         });
      }*/
   }

   private setMapStyle() {
      if (this._applicationStore.mapsStore.isBlackAndWhite) {
         this.map.mapTypes.set('grey', this.mapTypeGrey);
         this.map.setMapTypeId('grey');
      } else {
         this.map.mapTypes.set('orginal', this.mapTypeOrignal);
         this.map.setMapTypeId('orginal');
      }
   }

   private autoCloseMapSettingsConfigPanel() {
      if (this.showMapSettingsConfig && !this.mouseMapSettingsConfig) {
         this.showMapSettingsConfig = false;
      } else {
         // still open wait before trying again
         if (this.showMapSettingsConfig) {
            setTimeout(() => this.autoCloseMapSettingsConfigPanel(), 5000);
         }
      }
   }

   private multiSelectionMapMarkerMode(marker, isSelected) {
      // allowing multi selection of map markers
      if (isSelected) {
         this.deselectMarker(marker);
      } else {
         this.selectMarker(marker);
      }
   }

   private singleSelectionMapMarkerMode(marker) {
      // find all markers currently selected and deselect them
      let selectedMarkers = this.overlays.filter(
         item => item.selected === true
      );

      selectedMarkers.forEach(m => {
         this.deselectMarker(m);
      });
      // select the clicked on marker
      this.selectMarker(marker);
   }

   private selectMarker(marker) {
      // Select the marker -- change the icon, change the property and remove from array of selected
      this.selectedPoints.push(marker.point);

      // update new state info
      marker.selected = true;
      marker.icon = marker.selectIcon;

      // refesh is done bu clearing and re-adding the map to the marker
      marker.setMap(null);
      marker.setMap(this.map);
   }

   private deselectMarker(marker) {
      // deselect the marker -- change the icon, change the property and remove from array of selected
      let index = this.selectedPoints.findIndex(
         x => x.guid == marker.point.guid
      );
      this.selectedPoints.splice(index, 1);

      // update new state info
      marker.icon = marker.orginalIcon;
      marker.selected = false;

      // refesh is done bu clearing and re-adding the map to the marker
      marker.setMap(null);
      marker.setMap(this.map);
   }

   private removeTravelLines() {
      if (this.directionsDisplay) {
         this.directionsDisplay.setDirections({ routes: [] }); // clear the travel route
      }
   }

   private removeRouteLines(startPoint: MapPoint, points: MapPoint[] = []) {
      let startLat;
      let startLng;
      let endLat;
      let endLng;
      let colour;
      let firstLine = true;
      // start with caller
      startLat = startPoint.lat;
      startLng = startPoint.lng;

      for (let p of points) {
         endLat = p.lat;
         endLng = p.lng;

         if (firstLine) {
            colour = '#0026FF';
         } else {
            colour = '#FF0000';
         }

         let dummy = new google.maps.Polyline({
            path: [
               { lat: startLat, lng: startLng },
               { lat: endLat, lng: endLng }
            ],
            geodesic: true,
            strokeColor: colour,
            strokeOpacity: 0.5,
            strokeWeight: 2,
            isMarker: false,
            usedInRoue: false,
            identifier:
               'lat' +
               startLat.toString() +
               'lng' +
               startLng.toString() +
               'lat' +
               endLat.toString() +
               'lng' +
               endLng.toString()
         });

         // remove the line
         let index = this.overlays.findIndex(
            m =>
               m.isMarker == false &&
               m.geodesic == true &&
               m.identifier == dummy.identifier
         );
         if (index != -1) {
            this.overlays.splice(index, 1);
         }

         firstLine = false;
         startLat = p.lat;
         startLng = p.lng;
      }

      let dummy = new google.maps.Polyline({
         path: [
            { lat: startLat, lng: startLng },
            { lat: startPoint.lat, lng: startPoint.lng }
         ],
         geodesic: true,
         strokeColor: '#0026FF',
         strokeOpacity: 0.5,
         strokeWeight: 2,
         isMarker: false,
         usedInRoue: false,
         identifier:
            'lat' +
            startLat.toString() +
            'lng' +
            startLng.toString() +
            'lat' +
            endLat.toString() +
            'lng' +
            endLng.toString()
      });

      // remove the line
      let index = this.overlays.findIndex(
         m =>
            m.isMarker == false &&
            m.geodesic == true &&
            m.identifier == dummy.identifier
      );
      if (index != -1) {
         this.overlays.splice(index, 1);
      }
   }

   private buildRouteDirections(startPoint: MapPoint, points: MapPoint[] = []) {
      let wpoints = [];

      for (let p of points) {
         wpoints.push({
            location: new google.maps.LatLng(p.lat, p.lng),
            stopover: true
         });
      }

      //if the map height is tiny dont build the route could end up in loop
      // with a servcice error and splitter getting stuck tracking the mouse
      // this triggers another rote draw and error
      if (this.containerHeight > 100) {
         this.directionsService.route(
            {
               origin: { lat: startPoint.lat, lng: startPoint.lng },
               destination: { lat: startPoint.lat, lng: startPoint.lng },
               waypoints: wpoints,
               travelMode: 'DRIVING'
            },
            (response, status) => {
               if (status == 'OK') {
                  this.directionsDisplay.setDirections(response);
               } else {
                  // console.log('Directions request failed due to ' + status);
                  this.googleDirectionServiceError.emit(status);
               }
            }
         );
      }
   }

   private buildRouteLines(
      startPoint: MapPoint,
      points: MapPoint[] = [],
      colourCommuteLines: Boolean,
      includeReturnToStart: Boolean,
      excludeStart: Boolean
   ) {
      let startLat;
      let startLng;
      let endLat;
      let endLng;
      let colour;
      let strokeWeight;
      let firstLine = true;

      // start with caller
      startLat = startPoint.lat;
      startLng = startPoint.lng;

      for (let p of points) {
         endLat = p.lat;
         endLng = p.lng;

         if (firstLine && colourCommuteLines) {
            if (excludeStart) {
               // dont show the first line from start point
               colour = 'transparent';
               strokeWeight = 0;
            } else {
               colour = '#417dbc';
               strokeWeight = 3;
            }
         } else {
            colour = '#1eb4fa';
            strokeWeight = 6;
         }

         this.overlays.push(
            new google.maps.Polyline({
               path: [
                  { lat: startLat, lng: startLng },
                  { lat: endLat, lng: endLng }
               ],
               geodesic: true,
               strokeColor: colour,
               strokeOpacity: 0.8,
               strokeWeight: strokeWeight,
               isMarker: false,
               usedInRoue: true,
               identifier:
                  'lat' +
                  startLat.toString() +
                  'lng' +
                  startLng.toString() +
                  'lat' +
                  endLat.toString() +
                  'lng' +
                  endLng.toString()
            })
         );

         firstLine = false;
         startLat = p.lat;
         startLng = p.lng;
      }

      // do we draw a line from the last point back to the start point
      if (includeReturnToStart) {
         // end with the caller
         if (colourCommuteLines) {
            colour = '#417dbc';
            strokeWeight = 3;
         } else {
            colour = '#1eb4fa';
            strokeWeight = 6;
         }

         this.overlays.push(
            new google.maps.Polyline({
               path: [
                  { lat: startLat, lng: startLng },
                  { lat: startPoint.lat, lng: startPoint.lng }
               ],
               geodesic: true,
               strokeColor: colour,
               strokeOpacity: 0.8,
               strokeWeight: strokeWeight,
               isMarker: false,
               usedInRoue: true,
               identifier:
                  'lat' +
                  startLat.toString() +
                  'lng' +
                  startLng.toString() +
                  'lat' +
                  endLat.toString() +
                  'lng' +
                  endLng.toString()
            })
         );
      }
   }

   private removePointsFromOverlay(points: MapPoint[] = []) {
      points.forEach(p => {
         let index = this.overlays.findIndex(
            m => m.isMarker == true && m.point.guid === p.guid
         );

         if (index != -1) {
            //remove from any cluster
            if (this.markerCluster) {
               this.markerCluster.removeMarker(this.overlays[index]);
            }

            this.overlays.splice(index, 1);
         }
      });
   }

   private addRouteMarkersToOverlay(
      points: MapPoint[] = [],
      addLabel: Boolean
   ) {
      if (this.showFrequency && this.displayFrequencyOption) {
         return this.addRouteMarkersWithLabel(points, addLabel);
      } else {
         return this.addRouteMarkersWithoutLabel(points, addLabel);
      }
   }

   public addRouteMarkersWithoutLabel(
      points: MapPoint[] = [],
      addLabel: Boolean
   ) {
      let routePointMarkers = [];
      let count = 1;

      for (let p of points) {
         let marker = new google.maps.Marker({
            label: {
               text: addLabel ? count.toString() : '',
               color: addLabel ? 'white' : 'transparent'
            },
            position: { lat: p.lat, lng: p.lng },
            title: p.infoWndTitle,
            icon: p.icon,
            point: p,
            selectIcon: p.selectIcon,
            selected: false,
            orginalIcon: p.orginalIcon,
            zoomIcon: p.zoomIcon,
            isMarker: true,
            usedInRoue: true
         });

         // check if  exists
         let exists = this.overlays.find(m => m == marker);

         if (exists == undefined) {
            this.overlays.push(marker);
         }

         count = count + 1;
         routePointMarkers.push(marker);
      }

      return routePointMarkers;
   }

   // add third party marker that has a label below it for text
   private addRouteMarkersWithLabel(
      points: MapPoint[] = [],
      addLabel: Boolean
   ) {
      let routePointMarkers = [];
      let count = 1;

      for (let p of points) {
         let divElement = this.buildLabelHtmlElement(
            p,
            count.toString(),
            addLabel
         );

         let marker = new MarkerWithLabel({
            position: { lat: p.lat, lng: p.lng },
            title: p.infoWndTitle,
            /*label: ((addLabel) ? cnt.toString() : ''),*/
            point: p,
            icon: p.icon,
            selectIcon: p.selectIcon,
            selected: false,
            orginalIcon: p.orginalIcon,
            zoomIcon: p.zoomIcon,
            isMarker: true,
            usedInRoue: true,
            labelContent: divElement,
            labelAnchor: new google.maps.Point(10, 32)
         });

         // check if  exists
         let exists = this.overlays.find(m => m == marker);

         if (exists == undefined) {
            this.overlays.push(marker);
         }

         count = count + 1;
         routePointMarkers.push(marker);
      }

      return routePointMarkers;
   }

   private buildLabelHtmlElement(
      point: MapPoint,
      labelText: string,
      addLabel: Boolean
   ) {
      // this bit of HTML for the label will display the sequence number in the middle of the marker and the frequency below it.
      let topMargin = '20';
      if (this._applicationStore.mapsStore.useVisitDotIcon) {
         topMargin = '37';
      }
      let divElement = document.createElement('div');
      divElement.innerHTML =
         "<div style='color: white;padding-left:6px; font-size:14px;'>" +
         (addLabel ? labelText : '') +
         "</div> <div style='margin-top:" +
         topMargin +
         "px; opacity:1; color:black; background-color: white; font-size:10px; text-align: center; white-space: nowrap; border: 1px; border-style: solid; padding-top: 2px; padding-bottom: 2px; padding-left: 5px; padding-right: 5px;'>" +
         point.labelContent +
         '</div>';

      return divElement;
   }

   // there is a problem toggling clustering on an off when routes are enavled
   // this method with the use of the new property on the marker will allow all route components to be
   // deleted from the overlays
   private removeRouteComponentsFromOverlay() {
      let routeComponents = this.overlays.filter(m => m.usedInRoue == true);

      routeComponents.forEach(p => {
         let index = this.overlays.findIndex(m => m.usedInRoue == true);

         if (index != -1) {
            this.overlays.splice(index, 1);
         }
      });
   }

   // used this method to zoom the map to a scale that can show all the points on the map
   private viewWholelayer(markers: any[]) {
      // only do this if in auto mode
      if (this._applicationStore.mapsStore.isMapAutoZoom) {
         let bounds = new google.maps.LatLngBounds();

         markers.forEach(marker => {
            let isMarker = marker.isMarker;
            if (isMarker) {
               bounds.extend(marker.getPosition());
            }
         });

         setTimeout(() => {
            // map will need some time to load
            if (this.map) {
               this.map.fitBounds(bounds); // Map object used directly
            }
         }, 1000);
      }
   }

   public disableClustering() {
      this.markerCluster.setMaxZoom(1);
      this.markerCluster.setGridSize(1);
      //this.markerCluster.redraw();
      this.markerCluster.repaint();
   }

   public enableClustering() {
      if (this._applicationStore.mapsStore.useMapClusterPoints) {
         if (this.overlays.length > 0) {
            if (this.markerCluster) {
               if (this.markerCluster.getMaxZoom() == 1) {
                  this.setClusterOptions();
               }

               this.markerCluster.clearMarkers();
               // clustering has issues with the route components
               this.removeRouteComponentsFromOverlay();
               this.markerCluster.addMarkers(this.overlays);
            } else {
               if (this.map) {
                  this.markerCluster = new MarkerClusterer(
                     this.map,
                     this.overlays,
                     {
                        imagePath: this.clusterMarkerImagePath,
                        maxZoom: this.clusterMaxZoom, // this is the zoom level where clustering stops
                        gridSize: this.clusterGridSize // this is the grid size that determines how close points are before they are clustered defualt is 60
                     }
                  );
                  this.markerCluster.setCalculator(this.noCountInCluster);
               }
            }
         }
      }
   }

   // disable the count values in the center of the cluster icons
   private noCountInCluster(markers, numStyles) {
      let index = 0;
      let count = markers.length;
      let dv = count;
      while (dv !== 0) {
         dv = parseInt((dv / 10).toString(), 10);
         index++;
      }

      index = Math.min(index, numStyles);

      return {
         text: '',
         index: index
      };
   }

   private setClusterOptions() {
      this.markerCluster.setMaxZoom(this.clusterMaxZoom);
      this.markerCluster.setGridSize(this.clusterGridSize);
      this.markerCluster.repaint();
   }

   private closeConfigPanels() {
      // ensue the config panel is closed
      if (this.showMapSettingsConfig) {
         this.showMapSettingsConfig = false;
      }
   }

   private setMapGreyStyle() {
      // using the style from https://snazzymaps.com/style/15/subtle-grayscale
      this.mapStyleGrey = this._applicationStore.mapsStore.customMapStyle;
   }
}
