<div class="row top-content-border bottom-content-border" [ngStyle]="{'height.px': workspaceHeight}"
  style="margin-left:30px;margin-right:30px ;">

  <form #form="ngForm" class="form-horizontal ed-form-horizontal;" autocomplete="off">
    <div class="black-workspace col-md-12" style="padding: 10px;">
      <h3>{{gridTitle}}</h3>
      <hr />
      <div class="row" style="height: 100px; margin-top: 5%;">
        <div class="col-md-4 col-md-offset-4">
          <h6>Web Service(s)</h6>
          <h4>
            <callsmart-inputswitch name="webServiceOverrideSwitch" [(ngModel)]="!maintenance.WebServiceInMaintenance"
              onLabel="Off" offLabel="On">
            </callsmart-inputswitch>
          </h4>
        </div>
      </div>

      <div class="row" style="height: 100px; margin-top: 5%;">
        <div class="col-md-4 col-md-offset-4">
          <h6>Database</h6>
          <h4>
            <callsmart-inputswitch name="databaseOverrideSwitch" [(ngModel)]="!maintenance.DatabaseInMaintenance"
              onLabel="Off" offLabel="On">
            </callsmart-inputswitch>
          </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="inMaintenance">
        <marquee behavior="scroll" direction="left" scrollamount="20" class="login-maintenance" bgcolor="#6954c3">
          Maintenance in progress...</marquee>
      </div>
    </div>
  </form>
</div>


<div class="row">
  <div style="float:right; margin-left:30px;margin-right:30px;margin-top: 20px;">
    <button type="button" (click)="onSave(form)" class="btn cs-btn-primary-inverse">Save</button>
  </div>
</div>
