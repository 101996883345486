<form novalidate #settingsForm="ngForm" class="form-horizontal" autocomplete="off">
   <div class="panel">
      <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
         <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-33}">
            <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <h6>Project name</h6>
                     <div class="form-group">
                        <div class="col-sm-5">
                           <input required [(ngModel)]="projectWizardModel.projectName" name="project_name" id="project_name"
                           #project_name="ngModel" class="form-control cs-form-control" focusDirective cssSelector="#project_name">
                           <span *ngIf="project_name.touched && project_name.errors">
                              <label *ngIf="project_name.errors.required">Please insert project name</label>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <h6>Folder</h6>
                     <div class="form-group" style="display:flex;align-items:center;">
                        <div class="col-sm-5">
                           <callsmart-dropdown name="projectFolders" [options]="projectFolders" [(ngModel)]="selectedFolder" [style]="{'width':'100%'}"
                              (onChange)="folderChanged()">
                           </callsmart-dropdown>
                        </div>
                        <a class="white-link-underline " (click)="createFolder()"> Create new folder</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <h6>Call cycle length</h6>
                     <div class="form-group">
                        <label for="numberOfWeeksInput" class="col-sm-1 control-label"> Number of weeks:</label>
                        <div class="col-sm-1">
                           <input required [(ngModel)]="projectWizardModel.numberOfWeeks" type="number" pattern="^[1-4][0-9]?$|^5[0-2]$|^[5-9]$" class="form-control cs-form-control"
                              (blur)="onNumberOfWeeksLostFocus($event)" style="width: 50px" name="numberOfWeeksInput" #numberOfWeeksInput="ngModel"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                           <span *ngIf="numberOfWeeksInput.touched && numberOfWeeksInput.errors">
                              <label *ngIf="numberOfWeeksInput.errors.required">Please insert number of weeks</label>
                              <label *ngIf="numberOfWeeksInput.errors.pattern">Please insert a value between 1 and 52</label>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <h6>Call cycle start date</h6>
                     <div class="form-group">
                        <div class="col-sm-1">
                           <input required [(ngModel)]="cycleStartDateStr" pattern="^(?:(?:(?:0[1-9]|1\d|2[0-8])/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31/(?:0[13578]|1[02]))/[1-9]\d{3}|29/02/(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$"
                              class="form-control cs-form-control" placeholder="dd/mm/yyyy" size="1" (blur)="onCallCycleStartDateLostFocus($event)"
                              name="callCycleStartDateInput" #callCycleStartDateInput="ngModel" style="width: 110px">
                           <span *ngIf="callCycleStartDateInput.touched && callCycleStartDateInput.errors">
                              <label *ngIf="callCycleStartDateInput.errors.required">Please insert cycle start date</label>
                              <label *ngIf="callCycleStartDateInput.errors.pattern">Cycle start date (dd/mm/yyyy)</label>
                           </span>
                        </div>
                        <div class="col-sm-2">
                           <label for="callCycleStartDateInput" class="control-label">
                              <b>To:</b> {{cycleEndDateStr}}</label>
                        </div>

                        <div class="col-sm-8">
                           <p-calendar dateFormat="yy-mm-dd" [inline]="true" [(ngModel)]="projectWizardModel.startCycleDate" name="cycleCalendar" selectionMode="single"
                              selectOtherMonths="true" [style]="{'border':'none'}" (onSelect)="onDateSelected(projectWizardModel.startCycleDate)"
                              [locale]="en">

                              <ng-template pTemplate="date" let-date>
                                 <span [ngStyle]="{backgroundColor: isCalendarDateInRange(date.day, date.month, date.year) ? '#34bf84' : 'inherit', color:isCalendarDateInRange(date.day, date.month, date.year) ? '#222222' : '#d1d1d1', border: isCalendarDateInRange(date.day, date.month, date.year) ? 'inherit' : '1px solid #d1d1d1'}">{{date.day}}</span>
                              </ng-template>


                           </p-calendar>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <input type="button" class="btn cs-btn-tertiary pull-right" (click)="createProject()" name="createProject" value="Create Project"
                        [disabled]="!projectName || !numberOfWeeks || !cycleStartDate">
                  </div>
               </div>
            </div> -->
         </perfect-scrollbar>
      </div>
   </div>
</form>

<ndc-dynamic *ngIf="showNewFolderDialog" [ndcDynamicComponent]="newFolderDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
