import * as moment from 'moment';

export class Notification {
   id: string;
   severity: string;
   summary: string;
   detail: string;
   isMouseOver:boolean=false;
   timeout: any;
   createDate:Date;
   errorMessage:string;
   public get relativeCreateDate(): string {

      return moment(this.createDate.toString()).fromNow();
   }
}
