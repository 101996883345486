import { Component, OnInit, OnDestroy } from '@angular/core';
import { Caller } from "app/models/caller";
import { Subscription } from "rxjs";
import { ApplicationStore } from "app/stores/application-store";
import { Event } from "../models/diary/event";
import { EventTypes } from "../models/diary/event-types";

@Component({
   selector: 'callsmart-diary-workspace',
   templateUrl: './diary-workspace.component.html'
})

// This class represents the parent component which will hots all the functionality
// regarding the diary.
export class DiaryWorkspaceComponent implements OnInit, OnDestroy{
   private _diaryEventsSubscription: Subscription;

   diaryEvents:Event[] = [];
   caller: Caller;

   constructor(private _applicationStore: ApplicationStore) {

      /*this.caller = new Caller();
      this.caller.callerId = 1;
      this.caller.territory = 'T01';
      this.caller.name = 'Shauna Coxsey';
      this.caller.address1 = 'Shauna Coxsey';
      this.caller.address2 = '435 Rickmansworth Road';
      this.caller.address3 = 'Aldenham';
      this.caller.postcode = 'WD25 7QR'
      this.caller.latitude = 51.692911;
      this.caller.longitude = -0.400962;
      this.caller.workingDays = 19;
      this.caller.role = 'FSR';*/

   }

   ngOnInit() {

      this.subscribeToDiaryEvents();
   }

   ngOnDestroy(){

      if(this._diaryEventsSubscription){
         this._diaryEventsSubscription.unsubscribe();
      }
   }

   private subscribeToDiaryEvents() {

      this._diaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (events: Event[]) => {
            let testEvents: Event[]=[];
            events.forEach(item=>{
               let testEvent : Event= new Event();
               testEvent.isAllDay  = item.isAllDay;
               testEvent.description  = item.description;
               testEvent.end  = item.end;
               //testEvent.isSelected  = item.isSelected;
               testEvent.start  = item.start;
               testEvent.title  = item.title;
               testEvent.eventType = EventTypes.project
               testEvents.push(testEvent)});
            this.diaryEvents = testEvents;
         });
   }

}
