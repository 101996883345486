import {Event} from 'app/models/diary/event';

export class NewProjectWizard {
   projectName: string;
   folder: string;
   startCycleDate: Date;
   endCycleDate: Date;
   numberOfWeeks: number;
   projectId: number;
   hasStartedCallersUploadedToServer: boolean;
   hasCompletedCallersUploadedToServer: boolean;
   hasStartedCallpointsUploadedToServer: boolean;
   hasCompletedCallpointsUploadedToServer: boolean;
   callerUploadedToServerError: boolean;
   callpointsUploadedToServerError: boolean;
   fileMode:string;
   callersFileName: string;
   callpointsFileName: string;
   numberOfCallersWithDrivetimes: number;
   numberOfCallpoints: number;
   numberOfCallers: number;
   delimiter:string;
   projectEvents: Event[] = []; // Events added to the new project.
   quickOptimiserIteration: number;
   fullOptimiserIteration: number;
   maxVisitsPerDay: number;
   visitsPerDayNoMaximum: boolean;
   travelModel:string;
   // Calendar must be loaded when the event step is visible,
   // otherwise the 'firstDay' property would be initialised
   // when the wizard is loaded for the first time. This value cannot be changed afterwards.
   // That would mean that the 'firstDay' property couldn't be modified dinamically
   // since at that point the start cycle date is unknown.
   // (This is a known bug from the JQuery FullCalendar)
   isCalendarVisible: boolean=false;
   activeWorkingDays: boolean[];
   datesClosed: string[];
   startWorkingTime: string;
   endWorkingTime: string;
}
