
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "primeng/primeng";
import { CSGrowlComponent } from "app/shared/growl/growl.component";


@NgModule({
   imports: [CommonModule, SharedModule],
   exports: [CSGrowlComponent, SharedModule],
   declarations: [CSGrowlComponent]
})
export class CSGrowlModule { }
