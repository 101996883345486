<p-dialog header="Edit closed dates - {{description}}" [(visible)]="display"
   modal="true" width="800" positionTop="15" [draggable]="false" [resizable]="false" (onHide)="onCancel()">
   <form #form="ngForm" class="form-horizontal" autocomplete="off">
      <span style="font-size:13px">Unselected days will be unavailable for visits </span>
     <div class="panel" style="margin-top:20px">
         <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
            <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">

               <div class="cs-setting-panel">
                     <callsmart-close-calendar-days [calendarDates]="calendarDates"
                                                    (closedDatesChanged)="onClosedDatesChanged($event)">
                     </callsmart-close-calendar-days>
               </div>

            </perfect-scrollbar>
         </div>
      </div>


      <p-footer>
         <div style="padding-top: 10px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!isValid" (click)="onSave()">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>
</p-dialog>
