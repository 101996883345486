import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';
import * as moment from 'moment/moment';
/**
 * Custom converter used by the json2typescript mapping library to
 * map date values to Javascript Date type. See caller-settings.ts for usage example.
 */
@JsonConverter
export class ShortDateConverter implements JsonCustomConvert<string> {
   serialize(date: string): any {
      return date;
   }
   deserialize(date: any): string {
      if (date == null) {
         return undefined;
      }
      else {
         let auxDate : moment.Moment = moment(date);
         return auxDate.format("DD/MM/YYYY");         
      }
   }
}
