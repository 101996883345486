<p-dialog header="Callpoint settings" 
   [(visible)]="display" 
   modal="true" 
   width="1000" 
   positionTop="15"
   [draggable]="false" 
   [resizable]="false"
   (onHide)="onCancel()">

<form #form="ngForm" class="form-horizontal" autocomplete="off">

   <edit-callpoint-settings name="editCallpointSettings" 
      [(ngModel)]="callpointSettingsModel" 
      [scrollHeight]="scrollHeight" 
      [displayHeading]="false"      
      [allPossibleDayCombinations]="allPossibleDayCombinations"
      [recommendedDayCombinations]="recommendedDayCombinations"
      [projectActiveWorkingDays]="projectActiveWorkingDays"
      [projectCycleLength]="projectCycleLength"
      #editCallpointSettings>
   </edit-callpoint-settings>

   <p-footer>
      <div style="padding-top: 10px;">
         <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!formValid" (click)="onSave(form)">Ok</button>
         <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" 
            style="margin-right: 8px;">Cancel</button>
      </div>
   </p-footer>

</form>
</p-dialog>
