<div>
   <p-dataTable [value]="users" resizableColumns="true" [reorderableColumns]="false" selectionMode="single" [(selection)]="selectedUsers"
      (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onFilter)="onFilter($event)" [scrollHeight]="scrollHeight"
      [scrollable]="true" #projectdt>
      <p-header>
         <div style="float:left; margin-top: -18px;">
            <h3>{{gridTitle}}</h3>
         </div>

         <div style="float:left; height: 40px; padding-left: 10px;">
            <button id="clearFilter" type="button" class="btn cs-btn-secondary " (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
               <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
         </div>

         <div style="text-align:right; height: 40px;">
            <button type="button" class="btn cs-btn-primary-inverse" style="height:32px;" (click)="onCreateUser()"> + Add </button>
         </div>
      </p-header>


      <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
         [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'overflow':'hidden'}">

         <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
            <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
               (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
         </ng-template>

         <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
            <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
               displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)"
               defaultLabel="All" appendTo="body"></p-multiSelect>
         </ng-template>

         <!-- Nice to have - show ticks and crosses instead of true and false -->
         <!-- Commented out for now -->
         <!-- <ng-container *ngIf="col.field == 'isAdmin'">
            <ng-template let-user="rowData" pTemplate="body">
               <img *ngIf="user.isAdmin===false" src='assets/icons/Cross.png' style='width:14px;height:14px;'>
               <img *ngIf="user.isAdmin===true" src='assets/icons/Check.png' style='width:14px;height:14px;'>
            </ng-template>
         </ng-container>

         <ng-container *ngIf="col.field == 'isCompanyAdmin'">
            <ng-template let-user="rowData" pTemplate="body">
               <img *ngIf="user.isCompanyAdmin===false" src='assets/icons/Cross.png' style='width:14px;height:14px;'>
               <img *ngIf="user.isCompanyAdmin===true" src='assets/icons/Check.png' style='width:14px;height:14px;'>
            </ng-template>
         </ng-container> -->

      </p-column>

   </p-dataTable>

</div>
