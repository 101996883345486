import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { Callpoint } from 'app/models/callpoint';
import { Caller } from 'app/models/caller';
import { ApplicationStore } from 'app/stores/application-store';
import { EditCallpointSettingsDialogComponent } from 'app/shared/edit-callpoint-settings-dialog/edit-callpoint-settings-dialog.component';
import { CallpointSettingsViewModel } from 'app/models/view-models/callpoint-settings-view';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { DayCombination } from 'app/models/settings/day-combination';
import { EditCalendarDialogComponent } from 'app/shared/edit-calendar-dialog/edit-calendar-dialog.component';
import { LockingTypes } from 'app/models/diary/locking-Types';
import { Visit } from 'app/models/visit';
import { ReassignCallpointDialogComponent } from 'app/shared/reassign-callpoint-dialog/reassign-callpoint-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

/**
 * Component class for displaying the caller and callpoint summaries in the
 * Callpoint workspace.
 */
@Component({
   selector: 'callsmart-callpoint-summary',
   templateUrl: './callpoint-summary.component.html'
})
export class CallpointSummaryComponent implements OnInit, OnDestroy {
 
   // The selected callpoint
   private _callpoint: Callpoint;

   // Subscription for the day combinations.
   private _dayCombinations_subscription: Subscription;

   // Subscription for the recommended day combinations.
   private _recommendedDayCombinations_subscription: Subscription;

   // Subscritpion to be informed about diary events.
   private _callerDiaryEventsSubscription: Subscription;

   // Subscritpion to be informed about the locking callpoint visits.
   private _callpointVisitsSubscription: Subscription;

   private _visitsLockedSubscription: Subscription;

   private _callers_subscription:Subscription;

   private _callpointsSubscription: Subscription;

   public projectCallers: ReadonlyArray<Caller> = [];

   // Caller settings used by this component.
   public callpointSettingsViewModel: CallpointSettingsViewModel;

    // Determines whether to display the  dialog.
    public showReassignCallpointDialog: boolean = false;

    // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
    public reassignCallpointDialogComponent = ReassignCallpointDialogComponent;

   // Determine whether to display the edit caller dialog.
   public showCallpointSettings: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallpointSettingsDialog = EditCallpointSettingsDialogComponent;

   // All possible day combinations
   public allPossibleDayCombinations: DayCombination[] = [];

   // Recommended combinations (string value)
   public recommendedDayCombinations: string[] = [];

   // Determine whether to display the edit calendar dialog.
   public showEditCalendar: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCalendarDialog = EditCalendarDialogComponent;

   public alertDialog = AlertDialogComponent;

   // checks whether all callpoint visits are locked to day.
   public areAllVisitsLockedToDay: boolean = false;

   // checks whether all callpoint visits are locked to day and time.
   public areAllVisitsLockedToDayTime: boolean = false;

   // checks whether all selected callpoints are fully deferred.
   public fullyDeferredCallpoints: boolean = false;

    // checks whether all callpoint are disabled.
    public areAllCallpointsDisabled: boolean = false;

    // checks whether all callpoint are enabled.
    public areAllCallpointsEnabled: boolean = false;

    public totalCallPoints: number = 0;

    public showAlertDialog: boolean = false;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callpointSettingsModel: null,
      callpointHeading: null,
      projectActiveWorkingDays: null,
      allPossibleDayCombinations: null,
      recommendedDayCombinations: null,
      projectCycleLength: null
   };

   public editCalendarDialogInput = {
      display: false,
      datesClosed: null,
      scheduleStartDate: null,
      description: null
   }


   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public reassignCallpointDialogInput = {
      display: false,
      callpoint: null,
      projectCallers: this.projectCallers,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public reassignCallpointDialogOutput = {
      save: () => this.onSaveReassignCallpoint(),
      cancel: () => this.onCancelReassignCallpoint()
   };

   ////////////////////////////////////////////////////////////////////
   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (callpoint: Callpoint) => this.onSaveEditCallpoint(callpoint),
      cancel: () => this.onCancelEditCallpoint()
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public editCalendarDialogOutput = {
      saved: (closedDates: string[]) => this.onSaveEditCalendar(closedDates),
      cancel: () => this.onCancelEditCalendar()
   };

      // Properties for the error dialog dynamic loading
   public alertDialogInput = { display: false, title: '', bodyText: '' };
   public alertDialogOutput = {
       cancel: () => this.onCancelAlertEvent()
   };

   @Input()
   get callpoint(): Callpoint {
      return this._callpoint;
   }
   set callpoint(theCallpoint: Callpoint) {
      this._callpoint = theCallpoint;
      this.setLockingMenuItemStates();
      if (theCallpoint !== null) {
         this.initialiseCallpointSettings(theCallpoint);
      }
   }

   get caller(): Caller {
      return this._applicationStore.callersStore.selectedCaller;
   }

   public get areAllVisitsLocked(): boolean {
      return this.areAllVisitsLockedToDay || this.areAllVisitsLockedToDayTime;
   }

   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      this.subscribeToAllPossibleDayCombination();
      this.subscribeToRecommendedDayCombination();
      this.subscribeToCallpointVisits();
      this.subscribeToVisitsLocked();
      this.subscribeToCallerDiaryEvents();
      this.subscribeToCallers();
      this.subscribeToCallPoints();
   }

   ngOnDestroy() {
      if (this._dayCombinations_subscription) {
         this._dayCombinations_subscription.unsubscribe();
      }
      if (this._recommendedDayCombinations_subscription) {
         this._recommendedDayCombinations_subscription.unsubscribe();
      }
      if (this._callerDiaryEventsSubscription) {
         this._callerDiaryEventsSubscription.unsubscribe();
      }
      if (this._callpointVisitsSubscription) {
         this._callpointVisitsSubscription.unsubscribe();
      }
      if (this._visitsLockedSubscription) {
         this._visitsLockedSubscription.unsubscribe();
      }
      if(this._callers_subscription){
         this._callers_subscription.unsubscribe()
      }
      if (this._callpointsSubscription) {
         this._callpointsSubscription.unsubscribe();
      }
   }

   public editCallpoint() {

      this.dialogInput.callpointHeading = this._callpoint.name + ', ' + this._callpoint.reference;

      this.dialogInput.projectActiveWorkingDays = this._applicationStore.projectsStore.selectedProject.callerSettings.workingDayActive;
      this.dialogInput.allPossibleDayCombinations = this.allPossibleDayCombinations;
      this.dialogInput.recommendedDayCombinations = this.recommendedDayCombinations;
      this.dialogInput.projectCycleLength = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;

      if(this.callpointSettingsViewModel.startDate === null || this.callpointSettingsViewModel.startDate === undefined) {
         this.callpointSettingsViewModel.startDate = this._applicationStore.projectsStore.selectedProject.scheduleStartDate;
      }

      if(this.callpointSettingsViewModel.endDate === null || this.callpointSettingsViewModel.endDate === undefined) {
         this.callpointSettingsViewModel.endDate = this._applicationStore.projectsStore.selectedProject.scheduleEndDate;
      }

      this.dialogInput.callpointSettingsModel = this.callpointSettingsViewModel;

      this.showCallpointSettings = true;
      this.dialogInput.display = true;
   }

   public editCalendar() {
      this.editCalendarDialogInput.datesClosed = this.callpoint.datesClosed;
      this.editCalendarDialogInput.scheduleStartDate = this._applicationStore.projectsStore.selectedProject.scheduleStartDate;
      this.editCalendarDialogInput.description = this.callpoint.name + ', ' + this.callpoint.reference;

      this.showEditCalendar = true;
      this.editCalendarDialogInput.display = true;
   }

   public reassignCaller(){

      if (this.totalCallPoints === 1) {
         // 20-Oct-22, caller has just one call point and hence cannot be reassigned
         let alertMessage: string = this.caller.name + " has only one Call Point.\n\n"
         alertMessage +="Each Caller must have at least 1 Call Point to reassign Call Point to a different Caller.";
         
         this.showAlertDialog = true;
         this.alertDialogInput.title = this.callpoint.name + ", " + this.callpoint.reference ;
         this.alertDialogInput.bodyText = alertMessage;
         this.alertDialogInput.display = true;
      }
      else {
         this.reassignCallpointDialogInput.callpoint = this.callpoint;
         this.reassignCallpointDialogInput.projectCallers = this.projectCallers;
         this.showReassignCallpointDialog = true;
         this.reassignCallpointDialogInput.display = true;
      }
   }

   public onSaveReassignCallpoint(){
      this.showReassignCallpointDialog = false;
      this.reassignCallpointDialogInput.display = false;
   }

   public onCancelReassignCallpoint(){
      this.showReassignCallpointDialog = false;
      this.reassignCallpointDialogInput.display = false;
   }

   // Set the caller setting dialog to be hidden.
   public onSaveEditCallpoint(callpoint: Callpoint): void {
      this.showCallpointSettings = false;
      this.dialogInput.display = false;

      if(this._callpoint.frequency != callpoint.frequency){
         this._applicationStore.scheduleStore.generateScheduleWithUpdatedFrequency(this._applicationStore.callersStore.selectedCaller.callerId);
      }
      this._callpoint.duration = callpoint.duration
      this._callpoint.frequency = callpoint.frequency
      this._callpoint.latitude = callpoint.latitude;
      this._callpoint.longitude = callpoint.longitude;
      this._callpoint.priority = callpoint.priority;
      this._callpoint.startDate = callpoint.startDate;
      this._callpoint.endDate = callpoint.endDate;
      this._callpoint.callpointSettings = this.callpointSettingsViewModel.getCallpointSettings();

      this._callpoint.startDay = callpoint.startDay;
      this._callpoint.endDay = callpoint.endDay;
   }

   // Updates the new selected closed days.
   public onSaveEditCalendar(closedDates: string[]): void {
      this.showEditCalendar = false;
      this.editCalendarDialogInput.display = false;
      // Updating the calendar closed days to the Server
      this._applicationStore.callpointsStore.
         updateClosedDates(this.callpoint, closedDates);
   }

   public onCancelEditCalendar(): void {
      this.showEditCalendar = false;
      this.editCalendarDialogInput.display = false;
   }

   // Set the callpoint setting dialog to be hidden and revert any changes made by the user.
   public onCancelEditCallpoint(): void {
      this.showCallpointSettings = false;
      this.dialogInput.display = false;

      this.initialiseCallpointSettings(this._callpoint);
   }

   // Locks all callpoint visits to day.
   public lockCallpointVisitsToDay() {
      this.lockVisits(LockingTypes.day);
   }

   // Locks all callpoint visits to day and time.
   public lockCallpointVisitsToDayTime() {
      this.lockVisits(LockingTypes.dayAndTime);
   }

   // Unlocks all callpoint visits.
   public unlockCallpointVisits(): void {
      this.lockVisits(LockingTypes.none);
   }

   // Disables all callpoints i.e. excluded from optimizer.
   public disableCallpoints(): void {
      this.setCallpointsDisabledState(true);
   }

   // Enables all callpoints i.e. included in optimizer.
   public enableCallpoints(): void{
      this.setCallpointsDisabledState(false);
   }
   
   public isAvailabilityOverriden() {
      if (this.callpointSettingsViewModel) {
         if (this.callpointSettingsViewModel.getCallpointSettings() !== null) {
            return this.callpointSettingsViewModel.getCallpointSettings().availability !== undefined;
         }
      }
      return false;
   }

   // Angular 4.x and IE 11 do not show the date in the correct format when using the DatePipe from Angular.
   // This has been fixed in Angular 5 but for now using moment library to do the formatting in code to get the
   // desired result.
   public getHoursAndMinutes(date: Date): string {
      return CallsmartUtils.formatDate(date, "HH:mm");
   }

   private initialiseCallpointSettings(callpoint: Callpoint) {
      let model: CallpointSettingsViewModel = new CallpointSettingsViewModel(
         this._applicationStore.projectsStore.selectedProject.callpointSettings,
         callpoint);

      this.callpointSettingsViewModel = model;
   }

   private subscribeToAllPossibleDayCombination() {
      this._dayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.allDayCombinations$.subscribe(
         (dayCombinations: DayCombination[]) => {
            this.allPossibleDayCombinations = CallsmartUtils.deepClone<DayCombination[]>(dayCombinations, DayCombination);
         });
   }

   private subscribeToRecommendedDayCombination() {
      this._recommendedDayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.recommendedCombinations$.subscribe(
         (recommendedCombinations: string[]) => {
            this.recommendedDayCombinations = recommendedCombinations;
         });
   }

   private subscribeToCallerDiaryEvents() {
      this._callerDiaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         () => {
            // After locking the callpoint visits or after optimising,
            // diary events are refreshed so the menu items must recalculate their states.
            this.setLockingMenuItemStates();
         });
   }

   private subscribeToCallpointVisits() {
      this._callpointVisitsSubscription = this._applicationStore.visitsStore.visits$.subscribe(
         () => {
            // After locking the callpoint visits the menu items must refresh their states.
            this.setLockingMenuItemStates();
         });
   }

   private subscribeToVisitsLocked(): void {
      this._visitsLockedSubscription = this._applicationStore.scheduleStore.visitLocked.subscribe(
         () =>{
            this.setLockingMenuItemStates();
         }
      );
   }

   private subscribeToCallers() {
      // Get the callers list from the callers service.
      this._callers_subscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            this.projectCallers = callers;
         }
      );
   }

   private subscribeToCallPoints() {
      this._callpointsSubscription = this._applicationStore.callpointsStore.callpoints$.subscribe(
         (callpoints: Callpoint[]) => {
            this.totalCallPoints = callpoints.length;
         }
      );
   }

   // Locks/unlocks all the visits for the selected callpoint.
   private lockVisits(lockingType: LockingTypes) {
      if (this.callpoint && !this.fullyDeferredCallpoints) {
         this._applicationStore.scheduleStore.lockCallpointVisits(this.callpoint.callerId, [this.callpoint.reference], lockingType, this._applicationStore.uiStore.getActiveView());
      }
   }

    // Enables / disables all selected callpoints.
    private setCallpointsDisabledState(disable: boolean){
      if (this.callpoint) {
         this._applicationStore.callpointsStore.setCallpointsDisabledState([this.callpoint], disable);

          // After enabling / disabling callpoint the menu items must refresh their states.
          this.setLockingMenuItemStates();

          if (disable) {
            this.refeshScheduleAndVisits()
          }
       }
   }

   private refeshScheduleAndVisits() {
      // reload the current callers visits and schedule as locking status  might have changed
      this._applicationStore.visitsStore.loadVisitsForCaller(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this._applicationStore.callersStore.selectedCaller.callerId
      );

      this._applicationStore.scheduleStore.loadDiaryEvents(
         this._applicationStore.callersStore.selectedCaller.callerId,
         this._applicationStore.projectsStore.selectedProject.projectId,
         false
      );
   }

   // Works out the new menu items state after performing a locking action.
   private setLockingMenuItemStates() {
      if (this._callpoint) {
         // Checks and stores whether all visits for the selected callpoints are locked to day.
         this.areAllVisitsLockedToDay = this._applicationStore.scheduleStore.
            currentCallerDiaryEvents.filter(event => event.callpointId === this._callpoint.reference).
            every(event => event.locking == LockingTypes.day);
         // Checks and stores whether all visits for the selected callpoints are locked to day and time.
         this.areAllVisitsLockedToDayTime = this._applicationStore.scheduleStore.
            currentCallerDiaryEvents.filter(event => event.callpointId === this._callpoint.reference).
            every(event => event.locking == LockingTypes.dayAndTime);
         // Checks and stores whether the selected callpoints are fully deferred.
         this.fullyDeferredCallpoints = this.areAllDeferrals();
      }
      
      this.areAllCallpointsDisabled = this._callpoint && this._callpoint.isDisabled;
      this.areAllCallpointsEnabled = this._callpoint && !this._callpoint.isDisabled;
   }

   // Checks whether the selected callpoint are fully deferred.
   private areAllDeferrals(): boolean {
      let callpointVisits: Visit[] = this._applicationStore.visitsStore.getCallpointVisits([this.callpoint.callpointId]);
      return callpointVisits.every(visit => !visit.isScheduled);
   }

   onCancelAlertEvent() {
      this.showAlertDialog = false;
      this.alertDialogInput.display = false; 
   }

}
