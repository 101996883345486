import { Component, OnInit, Input } from '@angular/core';
import { BrowserWindowService } from 'app/services/browser-window.service';

@Component({
  selector: 'callsmart-spinner-dialog',
  templateUrl: './spinner-dialog.component.html'
})
export class SpinnerDialogComponent implements OnInit {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;
   
   public scrollHeight: number;
   public scrollWidth: number;

   constructor(private windowService: BrowserWindowService) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value * 0.15;
      });
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth = value * 0.25;
      });
   }


   ngOnInit() {
   }
}
