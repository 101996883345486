import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DayCombination } from 'app/models/settings/day-combination';

type DayToDisplay = { name: string; isEnabled: boolean; combination: string };

@Component({
   selector: 'callsmart-day-combination',
   templateUrl: './day-combination.component.html'
})
export class DayCombinationComponent implements OnInit {

   @Output() selectedDayCombinationChanged: EventEmitter<DayCombination> = new EventEmitter<DayCombination>(); 

   @Input() isHidden: boolean = false;   
   @Input() dayCombination: DayCombination;
   // Gets and sets available working days.   
   private _activeWorkingDays: boolean[];
   @Input()
   get activeWorkingDays(): boolean[] {
      return this._activeWorkingDays;
   }
   // The callsmart setting for this property differs from the one 
   // this component uses. That requires some calculations here
   set activeWorkingDays(callSmartWorkingDays: boolean[]) {
      if (callSmartWorkingDays && callSmartWorkingDays.length === 7) {
         this.availableDays = [];
         let calendarWorkingDays: number[] = [];
         let index: number = 0;
         callSmartWorkingDays.forEach(dayOfWeek => {
            let value: number = index % 7;
            if (dayOfWeek) {
               this.availableDays.push(value);
            }
            index++;
         });
         this._activeWorkingDays = callSmartWorkingDays;
      }
   }

   private dayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

   public daysToDisplay: DayToDisplay[] = []
   public availableDays: number[] = [0, 1, 2, 3, 4, 5, 6];

   constructor() { }

   ngOnInit() {
      this.availableDays.forEach(dayNumber => {
         let dayToDisplay: DayToDisplay = { name: this.dayNames[dayNumber], isEnabled: this.isEnabled(dayNumber), combination: this.dayCombination.combination };
         this.daysToDisplay.push(dayToDisplay);
      })
   }

   public toggleCheckbox(event) {
      if (event.target) {         
         this.dayCombination.isSelected = event.target.checked;
         this.selectedDayCombinationChanged.emit(this.dayCombination);
      }
   }

   private isEnabled(dayNumber: number): boolean {
      switch (dayNumber) {
         case 0:
            return this.dayCombination.mon;
         case 1:
            return this.dayCombination.tue;
         case 2:
            return this.dayCombination.wed;
         case 3:
            return this.dayCombination.thu;
         case 4:
            return this.dayCombination.fri;
         case 5:
            return this.dayCombination.sat;
         case 6:
            return this.dayCombination.sun;
      }
   }
}
