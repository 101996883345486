import { NgZone, Renderer2, Directive, Input } from '@angular/core';

/*
  Directive to allow focus to be set on component using the css-selector syntax
  <input id="new-email" focusDirective cssSelector="#new-email"
  formControlName="email" placeholder="Email" type="email" email>

  Since the only thing I'm doing is setting the focus on an element,
  I don't need to concern myself with change detection,
  so I can actually run the call to renderer.selectRootElement outside of Angular.
  Because I need to give the new sections time to render,
  the element section is wrapped in a timeout to allow the rendering threads time to catch up before the element
  selection is attempted. Once all that is setup, I can simply call the element using basic CSS selectors.

*/
@Directive({
    selector: '[focusDirective]'
})
export class FocusDirective {
    @Input() cssSelector: string

    constructor(
        private ngZone: NgZone,
        private renderer: Renderer2
    ) { }

    ngOnInit() {
        // console.log(this.cssSelector);
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.renderer.selectRootElement(this.cssSelector).focus();
            }, 0);
        });
    }
}
