import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';

import { Caller } from 'app/models/caller';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { Callpoint } from 'app/models/callpoint';

/**
 * Dialog component for displaying a list of callers to choose from when reassigning
 * a callpoint.
 */
@Component({
   selector: 'callsmart-reassign-callpoint-dialog',
   templateUrl: './reassign-callpoint-dialog.component.html'
})
export class ReassignCallpointDialogComponent implements OnInit {

   // Project model used by this dialog.
   @Input() public callpoint: Callpoint;
   @Input() public projectCallers: ReadonlyArray<Caller>;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() save = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   @ViewChild('form') form: NgForm;

   public isCallerSelectionEmpty: boolean = false;

   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];

   public cols: any[];
   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedCaller: Caller;
   public gridScrollHeight: string;

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService) {

      // subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 170;
         this.gridScrollHeight = (this.scrollHeight * 0.70) + 'px';
      });
   }

   ngOnInit() {
      this.configureTableColumns();

      // default to existing caller
      let caller: Caller = this.projectCallers.find(c => c.callerId == this.callpoint.callerId);
      this.selectedCaller = caller;
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      return this.form.valid;
   }

   public onSave() {

      this.isCallerSelectionEmpty = false;

      if (this.selectedCaller == null) {
         this.isCallerSelectionEmpty = true;
         return;
      }

      // do the save
      // check if the existing caller has changed if not just close the window
      if (this.callpoint.callerId != this.selectedCaller.callerId) {
         this.callpoint.callerId = this.selectedCaller.callerId;
         this.callpoint.callerTerritory = this.selectedCaller.territory;
         this.callpoint.scheduledVisits = 0;
         this._applicationStore.callpointsStore.updateCallpoints([this.callpoint]);
      }

      this.display = false;
      this.save.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      this.cols = [
         { field: 'territory', header: 'Code', disabled: true, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'name', header: 'Name', disabled: false, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false }
      ]

      this.allCols = [
         { field: 'territory', header: 'Code', disabled: true, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'name', header: 'Name', disabled: false, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false }

      ];

      this.columnOptions = [];

      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({ label: this.allCols[i].header, value: this.allCols[i] });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems)
      }
   }
}
