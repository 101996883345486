// model for dispalying information in the alert box
export class Alert {

   constructor(title: string, body: string) {

      this.title = title;
      this.body = body;
   }

   title: string;
   body: string

}
