import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { Caller } from 'app/models/caller';

/**
 * View model class for the Caller settings dialog.
 * Provides presentation logic to allow the user to override the project default settings for
 * single and multiple callers. See edit-caller-settings.component HTML to get a better
 * understanding of how the properties are used.
 */
export class CallerSettingsViewModel {

   ///////////////////////////////// Used only by the multiple caller settings dialog //////////////////////
   // Model properties for the multi edit checkboxes. The checkboxes will be unchecked everytime
   // the dialog box is displayed.
   public workingHoursMultiEdit: boolean = false;
   public workingHoursFlexibilityMultiEdit: boolean = false;
   public lunchWindowSwitchMultiEdit: boolean = false;
   public maxCommuteTimeMultiEdit: boolean = false;
   public maxVisitsPerDayMultiEdit: boolean = false;
   public commuteWorkHrsToFirstVisitMultiEdit: boolean = false;
   public outOfPhaseVisitsMultiEdit: boolean = false;
   public overnightsMultiEdit: boolean = false;
   public locationMultiEdit: boolean = false;

   ///////////////////////////////// Used only by the multiple caller settings dialog //////////////////////
   // Properties for determining whether each of the caller setting sections are the same
   // between all the selected callers. If they are same then display the properties, otherwise
   // display the 'Multiple values set' message.
   private _workingHoursSameValuesSet: boolean = false;
   private _workingHoursFlexibilitySameValuesSet: boolean = false;
   private _lunchWindowSwitchSameValuesSet: boolean = false;
   private _maxCommuteTimeSameValuesSet: boolean = false;
   private _maxVisitsPerDaySameValuesSet: boolean = false;
   private _commuteWorkHrsToFirstVisitSameValuesSet: boolean = false;
   private _outOfPhaseVisitsSameValuesSet: boolean = false;
   private _overnightsSameValuesSet: boolean = false;
   private _locationSameValuesSet: boolean = false;

   // Properties to control the default/override toggle buttons in the dialog
   // TODO: Fine, but what does 'disabled' mean? Does that mean a property is overridden or not overridden?
   // Also using 'disabled' instead of 'enabled' for a boolean property creates confusing double negatives.
   // - Use of 'disabled' is from the UI point of view. When the override toggle is at default position, then the
   // input fields are disabled and this is the default state. Its not confusing when looking at the usage in HTML.
   private _workingHoursDisabled: boolean = true;
   private _workingHoursFlexibilityDisabled: boolean = true;
   private _lunchWindowSwitchDisabled: boolean = true;
   private _maxCommuteTimeDisabled: boolean = true;
   private _maxVisitsPerDayDisabled: boolean = true;
   private _commuteWorkHrsToFirstVisitDisabled: boolean = true;
   private _outOfPhaseVisitsDisabled: boolean = true;
   private _overnightsAllowedDisabled: boolean = true;

   private _projectSettings: CallerSettings;
   private _callerSettings: CallerSettings
   private _caller: Caller;

   constructor(projectSettings: CallerSettings, callerSettings: CallerSettings, caller: Caller) {
      // Create back ups of the settings so that they can be reverted if the user cancels.
      this._projectSettings = CallsmartUtils.deepClone<CallerSettings>(projectSettings, CallerSettings);
      if (caller) {
         this._caller = CallsmartUtils.deepClone<Caller>(caller, Caller);
      }

      if (callerSettings !== null || callerSettings !== undefined) {
         this._callerSettings = CallsmartUtils.deepClone<CallerSettings>(callerSettings, CallerSettings);
      }

      this.initialiseSettingsState();
   }

   // Handy method to set all the proeprties to true or false when comparing callers.
   public setAllPropertiesSame(newValue: boolean) {
      this.workingHoursSameValuesSet = newValue;
      this.workingHoursFlexibilitySameValuesSet = newValue;
      this.lunchWindowSwitchSameValuesSet = newValue;
      this.maxCommuteTimeSameValuesSet = newValue;
      this.maxVisitsPerDaySameValuesSet = newValue;
      this.commuteWorkHrsToFirstVisitSameValuesSet = newValue;
      this.outOfPhaseVisitsSameValuesSet = newValue;
      this.overnightsSameValuesSet = newValue;
   }

   public getCallerSettings(): CallerSettings {
      return this._callerSettings;
   }

   public getCaller(): Caller {
      return this._caller;
   }

   /**
    * This is a hack, workingDayActive is not being set the usual way (ngModel) so it has
    * to be set here manually. edit-caller-settings.component calls this when the
    * user toggles the day buttons.
    */
   public setWorkingDayActive(newValue: boolean[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.workingDayActive = newValue;
      }
   }

   ////////////////////////////////////// Multi edit properties ////////////////////////////////////////////
   // In each of the property set methods, if the parameter is false meaning that the properties are different
   // between all the callers then the override toggle switch has to be set to Default position
   public get workingHoursSameValuesSet(): boolean {
      return this._workingHoursSameValuesSet;
   }

   public set workingHoursSameValuesSet(newValue: boolean) {
      this._workingHoursSameValuesSet = newValue;
      if (!this._workingHoursSameValuesSet) {
         this.workingHoursDisabled = true;
      }
   }

   public get workingHoursFlexibilitySameValuesSet(): boolean {
      return this._workingHoursFlexibilitySameValuesSet;
   }

   public set workingHoursFlexibilitySameValuesSet(newValue: boolean) {
      this._workingHoursFlexibilitySameValuesSet = newValue;
      if (!this._workingHoursFlexibilitySameValuesSet) {
         this.workingHoursFlexibilityDisabled = true;
      }
   }

   public get lunchWindowSwitchSameValuesSet(): boolean {
      return this._lunchWindowSwitchSameValuesSet;
   }

   public set lunchWindowSwitchSameValuesSet(newValue: boolean) {
      this._lunchWindowSwitchSameValuesSet = newValue;
      if (!this._lunchWindowSwitchSameValuesSet) {
         this.lunchWindowSwitchDisabled = true;
      }
   }

   public get maxCommuteTimeSameValuesSet(): boolean {
      return this._maxCommuteTimeSameValuesSet;
   }

   public set maxCommuteTimeSameValuesSet(newValue: boolean) {
      this._maxCommuteTimeSameValuesSet = newValue;
      if (!this._maxCommuteTimeSameValuesSet) {
         this.maxCommuteTimeDisabled = true;
      }
   }

   public get maxVisitsPerDaySameValuesSet(): boolean {
      return this._maxVisitsPerDaySameValuesSet;
   }

   public set maxVisitsPerDaySameValuesSet(newValue: boolean) {
      this._maxVisitsPerDaySameValuesSet = newValue;
      if (!this._maxVisitsPerDaySameValuesSet) {
         this.maxVisitsPerDayDisabled = true;
      }
   }

   public get commuteWorkHrsToFirstVisitSameValuesSet(): boolean {
      return this._commuteWorkHrsToFirstVisitSameValuesSet;
   }

   public set commuteWorkHrsToFirstVisitSameValuesSet(newValue: boolean) {
      this._commuteWorkHrsToFirstVisitSameValuesSet = newValue;
      if (!this._commuteWorkHrsToFirstVisitSameValuesSet) {
         this.commuteWorkHrsToFirstVisitDisabled = true;
      }
   }

   public get outOfPhaseVisitsSameValuesSet(): boolean {
      return this._outOfPhaseVisitsSameValuesSet;
   }

   public set outOfPhaseVisitsSameValuesSet(newValue: boolean) {
      this._outOfPhaseVisitsSameValuesSet = newValue;
      if (!this._outOfPhaseVisitsSameValuesSet) {
         this.outOfPhaseVisitsDisabled = true;
      }
   }

   public get overnightsSameValuesSet(): boolean {
      return this._overnightsSameValuesSet;
   }

   public set overnightsSameValuesSet(newValue: boolean) {
      this._overnightsSameValuesSet = newValue;
      if (!this._overnightsSameValuesSet) {
         this.overnightsAllowedDisabled = true;
      }
   }

   public get locationSameValuesSet(): boolean {
      return this._locationSameValuesSet;
   }

   public set locationSameValuesSet(newValue: boolean) {
      this._locationSameValuesSet = newValue;
   }

   ////////////////////////////////////// Override/Default toggle properties ////////////////////////////////////////////
   // In each of the property set methods, if the user has toggled to the Default settings, then reset the caller settings
   // incase the user did overrode them and then changed their mind.
   public get workingHoursDisabled(): boolean {
      return this._workingHoursDisabled;
   }

   public set workingHoursDisabled(newValue: boolean) {
      this._workingHoursDisabled = newValue;
      if (this._workingHoursDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.workingDayActive = undefined;
            this._callerSettings.sameWorkingHoursAllDays = undefined;
            this._callerSettings.contractedWorkingHoursWeek = undefined;
            this._callerSettings.contractedWorkingHoursMonday = undefined;
            this._callerSettings.contractedWorkingHoursTuesday = undefined;
            this._callerSettings.contractedWorkingHoursWednesday = undefined;
            this._callerSettings.contractedWorkingHoursThursday = undefined;
            this._callerSettings.contractedWorkingHoursFriday = undefined;
            this._callerSettings.contractedWorkingHoursSaturday = undefined;
            this._callerSettings.contractedWorkingHoursSunday = undefined;
         }
      }
   }

   public get workingHoursFlexibilityDisabled(): boolean {
      return this._workingHoursFlexibilityDisabled;
   }

   public set workingHoursFlexibilityDisabled(newValue: boolean) {
      this._workingHoursFlexibilityDisabled = newValue;
      if (this._workingHoursFlexibilityDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.workingHoursFlexibility = undefined;
         }
      }
   }

   public get lunchWindowSwitchDisabled(): boolean {
      return this._lunchWindowSwitchDisabled;
   }

   public set lunchWindowSwitchDisabled(newValue: boolean) {
      this._lunchWindowSwitchDisabled = newValue;
      if (this._lunchWindowSwitchDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.lunchPeriod = undefined;
            this._callerSettings.floatingLunch = undefined;
            this._callerSettings.lunchDuration = undefined;
         }
      }
   }

   public get maxCommuteTimeDisabled(): boolean {
      return this._maxCommuteTimeDisabled;
   }

   public set maxCommuteTimeDisabled(newValue: boolean) {
      this._maxCommuteTimeDisabled = newValue;
      if (this._maxCommuteTimeDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.noMaximum = undefined;
            this._callerSettings.maxOneWayCommuteTime = undefined;
         }
      }
   }

   public get maxVisitsPerDayDisabled(): boolean {
      return this._maxVisitsPerDayDisabled;
   }

   public set maxVisitsPerDayDisabled(newValue: boolean) {
      this._maxVisitsPerDayDisabled = newValue;
      if (this._maxVisitsPerDayDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.maxVisitsPerDay = undefined;
            this._callerSettings.visitsPerDayNoMaximum = undefined;
         }
      }
   }

   public get commuteWorkHrsToFirstVisitDisabled(): boolean {
      return this._commuteWorkHrsToFirstVisitDisabled;
   }

   public set commuteWorkHrsToFirstVisitDisabled(newValue: boolean) {
      this._commuteWorkHrsToFirstVisitDisabled = newValue;
      if (this._commuteWorkHrsToFirstVisitDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.commuteWorkHrsToFirstVisit = undefined;
            this._callerSettings.commuteWorkHrsFormLastVisit = undefined;
         }
      }
   }

   public get outOfPhaseVisitsDisabled(): boolean {
      return this._outOfPhaseVisitsDisabled;
   }

   public set outOfPhaseVisitsDisabled(newValue: boolean) {
      this._outOfPhaseVisitsDisabled = newValue;
      if (this._outOfPhaseVisitsDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.outOfPhaseVisitDays = undefined;
         }
      }
   }

   public get overnightsAllowedDisabled(): boolean {
      return this._overnightsAllowedDisabled;
   }

   public set overnightsAllowedDisabled(newValue: boolean) {
      this._overnightsAllowedDisabled = newValue;
      if (this._overnightsAllowedDisabled) {
         if (this._callerSettings !== null) {
            this._callerSettings.overnightsDisabled = undefined;
            this._callerSettings.maxConsecutiveOvernights = undefined;
            this._callerSettings.overnightsNoMaximum = undefined;
            this._callerSettings.minCommuteTimeBeforeOvernight = undefined;
            this._callerSettings.maxTravelTimeInEventOfOvernight = undefined;
            this._callerSettings.eventOvernightNoMaximum = undefined;
         }
      }
   }

   ///////////////////////////// get/set methods for caller setting properties /////////////////////////////////
   // Get/Set methods for each of the input properties on CallerSettings.
   // If any of the properties are overriden then return the value from CallerSettings, otherwise return
   // the project default value.
   public get workingDayActive(): boolean[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.workingDayActive;
      }
      else {
         if (this._callerSettings !== null && 
               this._callerSettings.workingDayActive !== undefined && 
               this._callerSettings.workingDayActive.length > 0) {
            return this._callerSettings.workingDayActive;
         }
         else {
            return this._projectSettings.workingDayActive;
         }
      }
   }

   // This is commented out because workingDayActive is not being updated by the form model
   // setWorkingDayActive() replaces this.
   /* public set workingDayActive(newValue: boolean[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.workingDayActive = newValue;
      }
   } */

   public get sameWorkingHoursAllDays(): boolean {
      if (this._workingHoursDisabled) {
         return this._projectSettings.sameWorkingHoursAllDays;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.sameWorkingHoursAllDays !== undefined) {
            return this._callerSettings.sameWorkingHoursAllDays;
         }
         else {
            return this._projectSettings.sameWorkingHoursAllDays;
         }
      }
   }

   public set sameWorkingHoursAllDays(newValue: boolean) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.sameWorkingHoursAllDays = newValue;
      }
   }

   public get contractedWorkingHoursWeek(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursWeek;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursWeek !== undefined) {
            return this._callerSettings.contractedWorkingHoursWeek;
         }
         else {
            return this._projectSettings.contractedWorkingHoursWeek;
         }
      }
   }

   public set contractedWorkingHoursWeek(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursWeek = newValue;
      }
   }

   public get contractedWorkingHoursMonday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursMonday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursMonday !== undefined) {
            return this._callerSettings.contractedWorkingHoursMonday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursMonday;
         }
      }
   }

   public set contractedWorkingHoursMonday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursMonday = newValue;
      }
   }

   public get contractedWorkingHoursTuesday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursTuesday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursTuesday !== undefined) {
            return this._callerSettings.contractedWorkingHoursTuesday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursTuesday;
         }
      }
   }

   public set contractedWorkingHoursTuesday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursTuesday = newValue;
      }
   }

   public get contractedWorkingHoursWednesday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursWednesday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursWednesday !== undefined) {
            return this._callerSettings.contractedWorkingHoursWednesday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursWednesday;
         }
      }
   }

   public set contractedWorkingHoursWednesday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursWednesday = newValue;
      }
   }

   public get contractedWorkingHoursThursday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursThursday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursThursday !== undefined) {
            return this._callerSettings.contractedWorkingHoursThursday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursThursday;
         }
      }
   }

   public set contractedWorkingHoursThursday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursThursday = newValue;
      }
   }

   public get contractedWorkingHoursFriday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursFriday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursFriday !== undefined) {
            return this._callerSettings.contractedWorkingHoursFriday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursFriday;
         }
      }
   }

   public set contractedWorkingHoursFriday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursFriday = newValue;
      }
   }

   public get contractedWorkingHoursSaturday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursSaturday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursSaturday !== undefined) {
            return this._callerSettings.contractedWorkingHoursSaturday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursSaturday;
         }
      }
   }

   public set contractedWorkingHoursSaturday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursSaturday = newValue;
      }
   }

   public get contractedWorkingHoursSunday(): Date[] {
      if (this._workingHoursDisabled) {
         return this._projectSettings.contractedWorkingHoursSunday;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.contractedWorkingHoursSunday !== undefined) {
            return this._callerSettings.contractedWorkingHoursSunday;
         }
         else {
            return this._projectSettings.contractedWorkingHoursSunday;
         }
      }
   }

   public set contractedWorkingHoursSunday(newValue: Date[]) {
      if (!this._workingHoursDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.contractedWorkingHoursSunday = newValue;
      }
   }

   public get workingHoursFlexibility(): number {
      if (this.workingHoursFlexibilityDisabled) {
         return this._projectSettings.workingHoursFlexibility;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.workingHoursFlexibility !== undefined) {
            return this._callerSettings.workingHoursFlexibility;
         }
         else {
            return this._projectSettings.workingHoursFlexibility;
         }
      }
   }

   public set workingHoursFlexibility(newValue: number) {
      if (!this.workingHoursFlexibilityDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.workingHoursFlexibility = newValue;
      }
   }

   public get lunchPeriod(): Date[] {
      if (this._lunchWindowSwitchDisabled) {
         return this._projectSettings.lunchPeriod;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.lunchPeriod !== undefined) {
            return this._callerSettings.lunchPeriod;
         }
         else {
            return this._projectSettings.lunchPeriod;
         }
      }
   }

   public set lunchPeriod(newValue: Date[]) {
      if (!this._lunchWindowSwitchDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.lunchPeriod = newValue;
      }
   }

   public get floatingLunch(): boolean {
      if (this._lunchWindowSwitchDisabled) {
         return this._projectSettings.floatingLunch;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.floatingLunch !== undefined) {
            return this._callerSettings.floatingLunch;
         }
         else {
            return this._projectSettings.floatingLunch;
         }
      }
   }

   public set floatingLunch(newValue: boolean) {
      if (!this._lunchWindowSwitchDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.floatingLunch = newValue;
      }
   }

   public get lunchDuration(): number {
      if (this._lunchWindowSwitchDisabled) {
         return this._projectSettings.lunchDuration;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.lunchDuration !== undefined) {
            return this._callerSettings.lunchDuration;
         }
         else {
            return this._projectSettings.lunchDuration;
         }
      }
   }

   public set lunchDuration(newValue: number) {
      if (!this._lunchWindowSwitchDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.lunchDuration = newValue;
      }
   }

   public get noMaximum(): boolean {
      if (this._maxCommuteTimeDisabled) {
         return this._projectSettings.noMaximum;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.noMaximum !== undefined) {
            return this._callerSettings.noMaximum;
         }
         else {
            return this._projectSettings.noMaximum;
         }
      }
   }

   public set noMaximum(newValue: boolean) {
      if (!this._maxCommuteTimeDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.noMaximum = newValue;
      }
   }

   public get maxOneWayCommuteTime(): number {
      if (this._maxCommuteTimeDisabled) {
         return this._projectSettings.maxOneWayCommuteTime;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.maxOneWayCommuteTime !== undefined) {
            return this._callerSettings.maxOneWayCommuteTime;
         }
         else {
            return this._projectSettings.maxOneWayCommuteTime;
         }
      }
   }

   public set maxOneWayCommuteTime(newValue: number) {
      if (!this._maxCommuteTimeDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.maxOneWayCommuteTime = newValue;
      }
   }

   public get maxVisitsPerDay(): number {
      if (this._maxVisitsPerDayDisabled) {
         return this._projectSettings.maxVisitsPerDay;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.maxVisitsPerDay !== undefined) {
            return this._callerSettings.maxVisitsPerDay;
         }
         else {
            return this._projectSettings.maxVisitsPerDay;
         }
      }
   }

   public set maxVisitsPerDay(newValue: number) {
      if (!this._maxVisitsPerDayDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.maxVisitsPerDay = newValue;
      }
   }

   public get visitsPerDayNoMaximum(): boolean {
      if (this._maxVisitsPerDayDisabled) {
         return this._projectSettings.visitsPerDayNoMaximum;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.visitsPerDayNoMaximum !== undefined) {
            return this._callerSettings.visitsPerDayNoMaximum;
         }
         else {
            return this._projectSettings.visitsPerDayNoMaximum;
         }
      }
   }

   public set visitsPerDayNoMaximum(newValue: boolean) {
      if (!this._maxVisitsPerDayDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.visitsPerDayNoMaximum = newValue;
      }
   }

   public get commuteWorkHrsToFirstVisit(): boolean {
      if (this._commuteWorkHrsToFirstVisitDisabled) {
         return this._projectSettings.commuteWorkHrsToFirstVisit;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.commuteWorkHrsToFirstVisit !== undefined) {
            return this._callerSettings.commuteWorkHrsToFirstVisit;
         }
         else {
            return this._projectSettings.commuteWorkHrsToFirstVisit;
         }
      }
   }

   public set commuteWorkHrsToFirstVisit(newValue: boolean) {
      if (!this._commuteWorkHrsToFirstVisitDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.commuteWorkHrsToFirstVisit = newValue;
      }
   }

   public get commuteWorkHrsFormLastVisit(): boolean {
      if (this._commuteWorkHrsToFirstVisitDisabled) {
         return this._projectSettings.commuteWorkHrsFormLastVisit;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.commuteWorkHrsFormLastVisit !== undefined) {
            return this._callerSettings.commuteWorkHrsFormLastVisit;
         }
         else {
            return this._projectSettings.commuteWorkHrsFormLastVisit;
         }
      }
   }

   public set commuteWorkHrsFormLastVisit(newValue: boolean) {
      if (!this._commuteWorkHrsToFirstVisitDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.commuteWorkHrsFormLastVisit = newValue;
      }
   }

   public get outOfPhaseVisitDays(): number {
      if (this._outOfPhaseVisitsDisabled) {
         return this._projectSettings.outOfPhaseVisitDays;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.outOfPhaseVisitDays !== undefined) {
            return this._callerSettings.outOfPhaseVisitDays;
         }
         else {
            return this._projectSettings.outOfPhaseVisitDays;
         }
      }
   }

   public set outOfPhaseVisitDays(newValue: number) {
      if (!this._outOfPhaseVisitsDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.outOfPhaseVisitDays = newValue;
      }
   }

   public get maxConsecutiveOvernights(): number {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.maxConsecutiveOvernights;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.maxConsecutiveOvernights !== undefined) {
            return this._callerSettings.maxConsecutiveOvernights;
         }
         else {
            return this._projectSettings.maxConsecutiveOvernights;
         }
      }
   }

   public set maxConsecutiveOvernights(newValue: number) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.maxConsecutiveOvernights = newValue;
      }
   }

   public get overnightsNoMaximum(): boolean {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.overnightsNoMaximum;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.overnightsNoMaximum !== undefined) {
            return this._callerSettings.overnightsNoMaximum;
         }
         else {
            return this._projectSettings.overnightsNoMaximum;
         }
      }
   }

   public set overnightsNoMaximum(newValue: boolean) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.overnightsNoMaximum = newValue;
      }
   }

   public get minCommuteTimeBeforeOvernight(): number {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.minCommuteTimeBeforeOvernight;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.minCommuteTimeBeforeOvernight !== undefined) {
            return this._callerSettings.minCommuteTimeBeforeOvernight;
         }
         else {
            return this._projectSettings.minCommuteTimeBeforeOvernight;
         }
      }
   }

   public set minCommuteTimeBeforeOvernight(newValue: number) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.minCommuteTimeBeforeOvernight = newValue;
      }
   }

   public get maxTravelTimeInEventOfOvernight(): number {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.maxTravelTimeInEventOfOvernight;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.maxTravelTimeInEventOfOvernight !== undefined) {
            return this._callerSettings.maxTravelTimeInEventOfOvernight;
         }
         else {
            return this._projectSettings.maxTravelTimeInEventOfOvernight;
         }
      }
   }

   public set maxTravelTimeInEventOfOvernight(newValue: number) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.maxTravelTimeInEventOfOvernight = newValue;
      }
   }

   public get eventOvernightNoMaximum(): boolean {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.eventOvernightNoMaximum;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.eventOvernightNoMaximum !== undefined) {
            return this._callerSettings.eventOvernightNoMaximum;
         }
         else {
            return this._projectSettings.eventOvernightNoMaximum;
         }
      }
   }

   public set eventOvernightNoMaximum(newValue: boolean) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }
         this._callerSettings.eventOvernightNoMaximum = newValue;
      }
   }

   public get overnightsDisabled(): boolean {
      if (this._overnightsAllowedDisabled) {
         return this._projectSettings.overnightsDisabled;
      }
      else {
         if (this._callerSettings !== null && this._callerSettings.overnightsDisabled !== undefined) {
            return this._callerSettings.overnightsDisabled;
         }
         else {
            return this._projectSettings.overnightsDisabled;
         }
      }
   }

   public set overnightsDisabled(newValue: boolean) {
      if (!this._overnightsAllowedDisabled) {
         if (this._callerSettings === null) {
            this._callerSettings = new CallerSettings();
         }

         this._callerSettings.overnightsDisabled = newValue;
      }
   }

   public get longitude(): any {
      return this._caller.longitude;
   }

   public set longitude(newValue: any) {
      this._caller.longitude = newValue;
   }

   public get latitude(): any {
      return this._caller.latitude;
   }

   public set latitude(newValue: any) {
      this._caller.latitude = newValue;
   }


   // Set the state of the override/default toggles based on the state of CallerSettings
   private initialiseSettingsState() {
      let notOverridden = this._callerSettings == null;
      this._workingHoursDisabled = notOverridden || this.isWorkingHoursDisabled();
      this._workingHoursFlexibilityDisabled = notOverridden || this.isWorkingHoursFlexibilityDisabled();
      this._lunchWindowSwitchDisabled = notOverridden || this.isLunchWindowSwitchDisabled();
      this._maxCommuteTimeDisabled = notOverridden || this.isNoMaximumDisabled();
      this._maxVisitsPerDayDisabled = notOverridden || this.isMaxVisitsPerDayDisabled();
      this._commuteWorkHrsToFirstVisitDisabled = notOverridden || this.isCommuteWorkHrsToFirstVisitDisabled();
      this._outOfPhaseVisitsDisabled = notOverridden || this.isOutOfPhaseVisitsDisabled();
      this._overnightsAllowedDisabled = notOverridden || this.isOvernightsDisabled();
   }

   private isWorkingHoursDisabled(): boolean {
      if (this._callerSettings.workingDayActive === undefined || this._callerSettings.workingDayActive.length == 0 &&
         this._callerSettings.sameWorkingHoursAllDays === undefined &&
         this._callerSettings.contractedWorkingHoursWeek === undefined &&
         this._callerSettings.contractedWorkingHoursMonday === undefined &&
         this._callerSettings.contractedWorkingHoursTuesday === undefined &&
         this._callerSettings.contractedWorkingHoursWednesday === undefined &&
         this._callerSettings.contractedWorkingHoursThursday === undefined &&
         this._callerSettings.contractedWorkingHoursFriday === undefined &&
         this._callerSettings.contractedWorkingHoursSaturday === undefined &&
         this._callerSettings.contractedWorkingHoursSunday === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   ///////////  Methods for testing the state of each property and setting the override toggle inputs /////////////

   private isWorkingHoursFlexibilityDisabled(): boolean {
      return this._callerSettings.workingHoursFlexibility === undefined;
   }

   private isLunchWindowSwitchDisabled(): boolean {
      if (this._callerSettings.lunchPeriod === undefined &&
         this._callerSettings.floatingLunch === undefined &&
         this._callerSettings.lunchDuration === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   private isNoMaximumDisabled(): boolean {
      if (this._callerSettings.noMaximum === undefined &&
         this._callerSettings.maxOneWayCommuteTime === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   private isMaxVisitsPerDayDisabled(): boolean {
      if (this._callerSettings.maxVisitsPerDay === undefined &&
         this._callerSettings.visitsPerDayNoMaximum === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   private isCommuteWorkHrsToFirstVisitDisabled(): boolean {
      if (this._callerSettings.commuteWorkHrsToFirstVisit === undefined &&
         this._callerSettings.commuteWorkHrsFormLastVisit === undefined) {
         return true;
      }
      else {
         return false;
      }
   }

   private isOutOfPhaseVisitsDisabled(): boolean {
      return this._callerSettings.outOfPhaseVisitDays === undefined;
   }

   private isOvernightsDisabled(): boolean {
      if (this._callerSettings.overnightsDisabled === undefined &&
         this._callerSettings.maxConsecutiveOvernights === undefined &&
         this._callerSettings.overnightsNoMaximum === undefined &&
         this._callerSettings.minCommuteTimeBeforeOvernight === undefined &&
         this._callerSettings.maxTravelTimeInEventOfOvernight === undefined &&
         this._callerSettings.eventOvernightNoMaximum === undefined) {
         return true;
      }
      else {
         return false;
      }
   }
}
