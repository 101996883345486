<p-dataTable [value]="projects" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="single" [(selection)]="selectedProjects"
   (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onFilter)="onFilter($event)" [scrollHeight]="scrollHeight"
   [scrollable]="true" #projectdt>
   <p-header>
      <div style="float:left; margin-top: -18px;">
         <h3>{{gridTitle}}</h3>
      </div>

      <div style="float:left; height: 40px; padding-left: 10px;">
         <button id="clearFilter" type="button" class="btn cs-btn-secondary " (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
            <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
      </div>

      <div style="text-align:right; height: 40px;">
         <button type="button" class="btn cs-btn-primary-inverse" style="height:32px;" (click)="onCreateNewProject()"> Create a new project </button>
      </div>
   </p-header>

   <p-column field="modifiedDate" header="Last opened" [sortable]="true" [filter]="true" filterMatchMode="contains" filterPlaceholder="contains"       
             filterField="modifiedDateFilter"  [style]="{'overflow':'hidden'}">
      <ng-template let-row="rowData" pTemplate="body">
         {{row.modifiedDate | mDate:'YYYY-MM-DD H:mm'}}
      </ng-template>
   </p-column>

   <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
      [filterMatchMode]="col.filterMatchMode" [sortable]="true" 
      [style]="{'text-overflow':'ellipsis', 'white-space':'nowrap', 'overflow':'hidden'}">

      <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
         <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
            (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
         <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
            displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="projectdt.filter($event.value,col.field,col.filterMatchMode)"
            defaultLabel="All" appendTo="body"></p-multiSelect>
      </ng-template>

   </p-column>

   <p-column field="scheduleStartDate" header="Cycle start" [sortable]="true" [filter]="true" filterMatchMode="contains" filterPlaceholder="contains"
      filterField="scheduleStartDateFilter" [style]="{'overflow':'hidden'}">
      <ng-template let-row="rowData" pTemplate="body">
         {{row.scheduleStartDate | date:'y-MM-dd'}}
      </ng-template>
   </p-column>

   <p-column field="scheduleEndDate" header="Cycle end" [sortable]="true" [filter]="true" filterMatchMode="contains" filterPlaceholder="contains"
      filterField="scheduleEndDateFilter" [style]="{'overflow':'hidden'}">
      <ng-template let-row="rowData" pTemplate="body">
         {{row.scheduleEndDate | date:'y-MM-dd'}}
      </ng-template>
   </p-column>


   <!--<p-column header="" [sortable]="false" [style]="{'width':'10%', 'overflow':'visible'}">
      <ng-template pTemplate type="body" let-row="rowData">
         <button type="button" class="btn cs-btn-secondary-inverse" (click)="onRollProject(row)">Roll forward</button>
      </ng-template>
   </p-column>

   <p-column header="" [sortable]="false" [style]="{'width':'10%', 'overflow':'visible'}">
      <ng-template pTemplate type="body" let-row="rowData">
         <button type="button" class="btn cs-btn-primary-inverse" (click)="onOpenProject(row)">Open</button>
      </ng-template>
   </p-column>-->


</p-dataTable>
