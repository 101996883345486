import {
   Component,
   OnInit,
   OnDestroy,
   EventEmitter,
   Output,
   Input,
   ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { DataTable } from 'primeng/components/datatable/datatable';

import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';
import { Alert } from 'app/models/alert';
import { generateUniqueItems } from 'app/shared/utils/callsmart-grid-util';

// Excellent resource on type script MAP (dictionaries)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map/delete

// this component shows all the projects in the data base that can be opened by the users.
// the data is deisplayed using the data grid. NG prime data grid is used along with its inbuilt filtering

@Component({
   selector: 'callsmart-project-list',
   templateUrl: './project-list.component.html'
})
export class ProjectListComponent implements OnInit, OnDestroy {
   @ViewChild(DataTable)
   private projectList: DataTable;

   @Output() rowUnSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.

   @Input() scrollHeight = '500px';

   //Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];

   public hasFilters: boolean;
   public projects: ReadonlyArray<Project>;
   public cols: any[];
   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedProjects: Project;
   public selectedItemsLabel: string = 'Columns'; // shows the count '{0} columns displayed';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns'; //'0 columns displayed'
   public panelTitle: string = 'Columns';

   public gridTitle: string = 'Projects';

   private _projects_subscription: Subscription;

   constructor(
      private _applicationStore: ApplicationStore,
      private _router: Router
   ) {}

   public ngOnInit(): void {
      this.configureTableColumns();
      this.subscribeToProjects();
   }

   public ngOnDestroy(): void {
      if (this._projects_subscription) {
         this._projects_subscription.unsubscribe();
      }
   }

   public onRowSelect(event) {
      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }

      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }

      this.projectList.reset();

      // have to clear all the custom filters (combos and multi selects)
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.projectList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // this keeps the orgingal sort
      this.projectList.updateDataToRender(this.projects);
      this.hasFilters = false;
      this.gridTitle = 'Projects';
   }

   public onFilter(event) {
      if (Object.keys(this.projectList.filters).length > 0) {
         this.buildStandardGridTitleWithBuiltInFilters();
      } else {
         this.gridTitle = 'Projects';
         this.hasFilters = false;
      }
   }

   public onCreateNewProject() {
      if (this._applicationStore.authenticationStore.userCompany.trialLicense) {
         if (this.projects.length == 1) {
            // For a trial license, only one project can be created.
            this._applicationStore.alertStore.sendAlert(
               new Alert(
                  'Warning',
                  'Your licence does not allow for multiple projects.'
               )
            );
         } else {
            this._router.navigate(['new-project']);
         }
      } else {
         this._router.navigate(['new-project']);
      }
   }

   public onOpenProject(project) {
      this._applicationStore.projectsStore.setSelectedProject(
         project,
         this._applicationStore.authenticationStore.loggedInUser.userId
      );
      this._router.navigate(['dashboard']);
   }

   public onRollProject(project) {}

   // builds up the data to be used in the combos for the columns that require combos to filter the data
   private configureProjectsData(projects: ReadonlyArray<Project>) {
      this.projects = projects;
      this.buildOwnerComboData(projects);
      this.buildFolderComboData(projects);

      // defualt selection to first in the list
      if (projects.length > 0) {
         this.selectedProjects = projects[0];
      }
   }

   // when using a multi select dont and a value for all pass an empty label ''
   private buildOwnerComboData(projects: ReadonlyArray<Project>) {
      if (projects.length > 0) {
         // get list of all unique roles
         let owners = projects.map(function(p) {
            if (p.author) {
               return p.author.fullname;
            } else {
               return '';
            }
         });
         let data = generateUniqueItems('', owners);
         this.comboFilterDataMap.set('author.fullname', data);
      }
   }

   private buildFolderComboData(projects: ReadonlyArray<Project>) {
      if (projects.length > 0) {
         // get list of all unique folders
         let folders = projects.map(function(p) {
            return p.folder;
         });
         let data = generateUniqueItems('All', folders);
         this.comboFilterDataMap.set('folder', data);
      }
   }

   private buildStandardGridTitleWithBuiltInFilters() {
      let filterNum = 0;
      if (this.projectList.filteredValue === null) {
         filterNum = this.projects.length;
      } else {
         filterNum = this.projectList.filteredValue.length;
      }

      this.gridTitle =
         'Projects (Showing ' +
         filterNum +
         ' of ' +
         this.projects.length +
         ' total)';
      this.hasFilters = true;
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      this.cols = [
         {
            field: 'folder',
            header: 'Folder',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: true,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'name',
            header: 'Name',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'author.fullname',
            header: 'Owner',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
            isButton: false
         }
      ];

      this.allCols = [
         {
            field: 'folder',
            header: 'Folder',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: true,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'name',
            header: 'Name',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'author.fullname',
            header: 'Owner',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
            isButton: false
         }
      ];

      //{ field: 'projectId', header: '', disabled: false, filter: false, filterPlaceholder: 'equals', filterMatchMode: 'equals', hasCombo: false, hasMulti: false, isButton:true  },

      this.comboFilterDataMap = new Map<string, any[]>();
      this.columnOptions = [];

      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i]
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }
   }

   private subscribeToProjects() {
      this._projects_subscription = this._applicationStore.projectsStore.projects$.subscribe(
         (projects: ReadonlyArray<Project>) => {
            this.configureProjectsData(projects);
         }
      );
   }
}
