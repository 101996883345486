import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/components/common/menuitem';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';

@Component({
   selector: 'callsmart-project-settings-workspace',
   templateUrl: './project-settings-workspace.component.html'
})

// The project settings workspace allows users to modify the current project settings
// (callers, callpoints, scheduling)
export class ProjectSettingsWorkspaceComponent implements OnInit {

   // Menu model used to determine navigation for p-steps.
   public items: MenuItem[] = [];

   // Index for the PrimeNG steps components.
   public activeIndex: number = 0;

   // Label for the current page.
   public activeStepLabel: string;

   constructor(private _router: Router) {
      // Only listen for NavigationEnd and NavigationCancel events.
      // NavigationEnd is triggered when the router has fi
      this._router.events.pipe(filter(e => (e instanceof NavigationEnd || e instanceof NavigationCancel)))
         .subscribe((event: any): void => {
            this.mapActiveIndexToRoute();
         });
   }

   ngOnInit() {
      this.items = [
         { label: 'Settings', routerLink: ['/project-settings/settings'] },
         { label: 'Callers', routerLink: ['/project-settings/callers'] },
         { label: 'Callpoints', routerLink: ['/project-settings/callpoints'] },
         { label: 'Events', routerLink: ['/project-settings/events'] },
      ];
   }

   private mapActiveIndexToRoute() {
      if (this._router.isActive("/project-settings/settings", true)) {
         this.activeIndex = 0;
         this.activeStepLabel = 'Settings';
      }
      else if (this._router.isActive("/project-settings/callers", true)) {
         this.activeIndex = 1;
         this.activeStepLabel = 'Callers';
      }
      else if (this._router.isActive("/project-settings/callpoints", true)) {
         this.activeIndex = 2;
         this.activeStepLabel = 'Callpoints';
      }
      else if (this._router.isActive("/project-settings/events", true)) {
         this.activeIndex = 3;
         this.activeStepLabel = 'Events';
      }
   }

}
