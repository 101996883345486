import { JsonObject, JsonProperty } from 'json2typescript';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { NullConverter } from 'app/shared/json-converters/null-converter';
import { ScheduleMetrics } from 'app/models/scheduleMetrics';
import { ClosedDatesArrayConverter } from 'app/shared/json-converters/closed-dates-array-converter';
import { Identifiable } from 'app/shared/interfaces/Identifiable.interface';

/**
 * Model class representing a Caller.
 */
@JsonObject
export class Caller implements Identifiable {
   constructor() {
      this.guid = this.generateGUID();
   }

   @JsonProperty('callerId')
   public callerId: number = undefined;

   // Values from server.
   @JsonProperty('projectId')
   public projectId: number = undefined;

   @JsonProperty('callerSettings', CallerSettings, true)
   public callerSettings: CallerSettings = undefined;

   @JsonProperty('scheduleMetrics', ScheduleMetrics, true)
   public scheduleMetrics: ScheduleMetrics = undefined;

   @JsonProperty('name')
   public name: string = undefined;

   @JsonProperty('role')
   public role: string = undefined;

   @JsonProperty('address1')
   public address1: string = undefined;

   @JsonProperty('address2')
   public address2: string = undefined;

   @JsonProperty('address3')
   public address3: string = undefined;

   @JsonProperty('address4')
   public address4: string = undefined;

   @JsonProperty('address5')
   public address5: string = undefined;

   @JsonProperty('postcode')
   public postcode: string = undefined;

   @JsonProperty('tidyPostCode')
   public tidyPostCode: string = undefined;

   @JsonProperty('territory')
   public territory: string = undefined;

   @JsonProperty('team')
   public team: string = undefined;

   @JsonProperty('latitude')
   public latitude: number = undefined;

   @JsonProperty('longitude')
   public longitude: number = undefined;

   @JsonProperty('datesClosed', ClosedDatesArrayConverter, true)
   public datesClosed: string[] = undefined;

   @JsonProperty('optimisationDurationSec')
   public optimisationDurationSec: number = undefined;

   @JsonProperty('drivetimeGenerationDurationSec')
   public drivetimeGenerationDurationSec: number = undefined;

   @JsonProperty('optimisationStatus')
   public optimisationStatus: string = undefined;

   @JsonProperty('other1')
   public other1: string = undefined;

   @JsonProperty('other2')
   public other2: string = undefined;

   @JsonProperty('other3')
   public other3: string = undefined;

   @JsonProperty('other4')
   public other4: string = undefined;

   @JsonProperty('other5')
   public other5: string = undefined;
   
   /*@JsonProperty('numDeferrals')

   public numDeferrals: number = undefined;

   @JsonProperty('minCallsPerDay')
   public minCallsPerDay: number = undefined;

   @JsonProperty('maxCallsPerDay')
   public maxCallsPerDay: number = undefined;

   @JsonProperty('avgCallsPerDay')
   public avgCallsPerDay: number = undefined;

   @JsonProperty('utilisationPc')
   public utilisationPc: number = undefined;

   @JsonProperty('driveDistanceKm')
   public driveDistanceKm: number = undefined;

   @JsonProperty('numAvailableDays')
   public numAvailableDays: number = undefined;

   @JsonProperty('numEmptyDays')
   public numEmptyDays: number = undefined;

   @JsonProperty('spareTimeHrs')
   public spareTimeHrs: number = undefined;

   @JsonProperty('numCallpoints')
   public numCallpoints: number = undefined;

   @JsonProperty('numVisitsToMake')
   public numVisitsToMake: number = undefined;

   @JsonProperty('numVisitsBooked')
   public numVisitsBooked: number = undefined;

   @JsonProperty('numDisabledCallpoints')
   public numDisabledCallpoints: number = undefined;

   @JsonProperty('timeInVisitsHrs')
   public timeInVisitsHrs: number = undefined;

   @JsonProperty('shiftTimeInVisitPc')
   public shiftTimeInVisitPc: number = undefined;

   @JsonProperty('avgDriveTimeBetweenVisitsMins')
   public avgDriveTimeBetweenVisitsMins: number = undefined;

   @JsonProperty('numOvernights')
   public numOvernights: number = undefined;

   @JsonProperty('name')
   public maxConsecutiveOvernights: number = undefined;

   @JsonProperty('driveTimeHrs')
   public driveTimeHrs: number = undefined;*/

   // Derived or transformed values.
   public numDeferredVisits: number;
   public scheduledVisitsPc: number;

   // this property is needed in the map and grid synchronisation
   public isActive: boolean;

   // client side unique identifier (needed for map and grid selection synconisation)
   public guid: string;

   public avgCommuteTime: number;

   public avgCommuteDistance: number;

   // Caller settings for this caller.
   //public callerSettingsViewModel: CallerSettingsViewModel;

   private generateGUID() {
      let d = new Date().getTime();
      let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
         /[xy]/g,
         function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
         }
      );
      return guid;
   }
}
