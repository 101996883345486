import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { DataTable } from 'primeng/components/datatable/datatable';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { TravelModel } from 'app/models/travelModel';

/**
 * Component for the travel model list. displays all the travel models configured for the system
 */
@Component({
   selector: 'callsmart-travel-model-list',
   templateUrl: './travel-model-list.component.html'
})
export class TravelModelListComponent implements OnInit, OnDestroy {

   @ViewChild(DataTable)
   private travelModelList: DataTable;

   @Output() rowUnSelected: EventEmitter<any> = new EventEmitter<any>();  // Must be output to allow other components to bind to it.
   @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();  // Must be output to allow other components to bind to it.
   @Output() allRowsSelected: EventEmitter<any> = new EventEmitter<any>();
   @Output() createTravelModel: EventEmitter<any> = new EventEmitter<any>();

   @Input() scrollHeight = '200px';
   @Input() showGridCustomHeader: Boolean = true;
   @Input() allowMultipleSelection: Boolean = false;

   public travelModels: ReadonlyArray<TravelModel>;

   public allCols: any[];
   public selectedTravelModel: TravelModel;

   public gridSelectionMode = "single";

   private _cols: any;
   get cols(): any[] {
      return this._cols;
   }
   set cols(selectedCols: any[]) {
      this._cols = selectedCols;
   }

   public gridTitle: string = 'Travel Models';
   public hasFilters: boolean;

   // travel models subscription.
   private _travel_models_subscription: Subscription;

   constructor(public _applicationStore: ApplicationStore) {
   }

   public ngOnInit() {
      // configure the selection mode
      this.gridSelectionMode = (this.allowMultipleSelection) ? this.travelModelList.selectionMode = "multiple" : this.travelModelList.selectionMode = "single";

      this.configureTableColumns();

      // Get the callers list from the callers service.
      this._travel_models_subscription = this._applicationStore.sysAdminStore.travelModels$.subscribe(
         (travelModels: ReadonlyArray<TravelModel>) => {
            this.configureData(travelModels);
         }
      );
   }

   public onHeaderCheckboxToggle(event) {
      this.allRowsSelected.emit(event);
   }

   public onRowSelect(event) {
      // the checkbox selection doesnt seem to be adding the data to the selectedarray
      if (event.type == "checkbox") {
         this.selectedTravelModel = event.data;
      }
      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onCreateTravelModel() {
      this.createTravelModel.emit(event);
   }

   public ngOnDestroy(): void {
      if (this._travel_models_subscription) {
         this._travel_models_subscription.unsubscribe();
      }
   }

   public onFilterReset() {
      this.travelModelList.reset();

      // have to clear all the custom filters (combos and multi selects)
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.travelModelList.filter(null, col.field, col.filterMatchMode)
         }
      });

      // this keeps the orginal sort
      this.travelModelList.updateDataToRender(this.travelModels);
      this.hasFilters = false;
      this.gridTitle = 'Travel Models'
   }

   public onFilter(event) {
      if (Object.keys(this.travelModelList.filters).length > 0) {
         this.buildStandardGridTitleWithBuiltInFilters();
      } else {
         this.gridTitle = 'Travel Models'
         this.hasFilters = false;
      }
   }

   private buildStandardGridTitleWithBuiltInFilters() {
      let filterNum = 0;
      if (this.travelModelList.filteredValue === null) {
         filterNum = this.travelModels.length;
      } else {
         filterNum = this.travelModelList.filteredValue.length;
      }

      this.gridTitle = 'Travel Models (Showing ' + filterNum + ' of ' + this.travelModels.length + ' total)'
      this.hasFilters = true;
   }

   private configureTableColumns() {
      this.cols = [
         { field: 'travelModelName', header: 'Travel Model Name', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'travelModelDescription', header: 'Travel Model Description', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'geocodeData', header: 'Geocode Data', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      ]

      this.allCols = [
         { field: 'travelModelName', header: 'Travel Model Name', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'travelModelDescription', header: 'Travel Model Description', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'geocodeData', header: 'Geocode Data', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      ];
   }

   private configureData(travelModels: ReadonlyArray<TravelModel>) {
      this.travelModels = travelModels;
   }
}
