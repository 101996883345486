import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RangeSliderComponent } from "app/shared/range-slider/range-slider.component";

/**
 * Module for  Range Slider component
 */
@NgModule({
   imports: [CommonModule],
   exports: [RangeSliderComponent],
   declarations: [RangeSliderComponent]
})
export class RangeSliderModule { }
