import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { Caller } from 'app/models/caller';

/**
 * Dialog box component containing the caller settings.
 */
@Component({
   selector: 'edit-caller-settings-dialog',
   templateUrl: 'edit-caller-settings-dialog.component.html'
})
export class EditCallerSettingsDialogComponent {

   @ViewChild('editCallerSettings') editCallerSettings;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Name of the caller to display in the dialog header.
   @Input() callerName: string = 'Caller';

   // Settings model used by this dialog.
   @Input() callerSettingsModel: CallerSettingsViewModel;

   // Determine if editing multiple callers.
   @Input() editingMultipleCallers: boolean = false;

   @Input() projectCycleLength: number;
   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<{caller: Caller, daysChanged: boolean[]}>();

   // Notifies the client when the dialog was closed with save button when editing multiple callers.
   @Output() multiCallerSaved = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   constructor(
      private _applicationStore: ApplicationStore,
      private _windowService: BrowserWindowService) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallerSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Show the save button if a single caller is being edited or if multiple callers are being edited
   // and the user has selected at least one multi edit checkbox.
   public showSaveButton(): boolean {
      if (this.editingMultipleCallers) {
         return this.callerSettingsModel.workingHoursMultiEdit ||
            this.callerSettingsModel.workingHoursFlexibilityMultiEdit ||
            this.callerSettingsModel.lunchWindowSwitchMultiEdit ||
            this.callerSettingsModel.maxCommuteTimeMultiEdit ||
            this.callerSettingsModel.maxVisitsPerDayMultiEdit ||
            this.callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit ||
            this.callerSettingsModel.outOfPhaseVisitsMultiEdit ||
            this.callerSettingsModel.overnightsMultiEdit ||
            this.callerSettingsModel.locationMultiEdit;
      }
      else {
         return true;
      }
   }

   // Called when the user clicks on the Save button.
   public onSave(form: NgForm) {
      this.display = false;

      let caller: Caller = this.callerSettingsModel.getCaller();
      let callerSettings: CallerSettings = this.callerSettingsModel.getCallerSettings();

      if (callerSettings) {
         caller.callerSettings = callerSettings;
         caller.callerSettings.callerId = caller.callerId;
      }

      let daysChanged = this.editCallerSettings.daysChanged;

      //if (callerSettings !== undefined && callerSettings !== null) {

         if (this.editingMultipleCallers) {
            // Multiple caller settings have been edited, let the parent component handle the
            // saving task.
            this.multiCallerSaved.next(caller);
         }
         else {

            // Single caller setting needs updating.
            this.saved.next({caller, daysChanged});
         }
     // }
   }
}
