import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Callpoint } from 'app/models/callpoint';
import { CalendarDay } from 'app/models/calendarDay';

import * as moment from 'moment';

// This class represents the component dialog which hosts the
// close-calendar-dates-component. It also validates the selected closed dates
@Component({
   selector: 'callsmart-edit-calendar-dialog',
   templateUrl: './edit-calendar-dialog.component.html'
})
export class EditCalendarDialogComponent implements OnInit {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;
   //@Input() selectedCallpoint: Callpoint = null;
   @Input() datesClosed = [];
   @Input() scheduleStartDate: Date;
   @Input() description: string;
   
   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<string[]>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;   
   public calendarDates: CalendarDay[][] = [];
   public isValid = false;

   constructor(private _windowService: BrowserWindowService) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 220;
      });
   }

   public ngOnInit() {      
      this.calculateClosedDates();
   }
   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.emit();
   }

   // Called when the user clicks on the Save button.
   public onSave() {
      this.display = false;
      let closedDates: string[] = this.convertCalendarDates(this.calendarDates);
      this.saved.emit(closedDates);
   }

   // Keeps the calendar closed dates in synch with the child component
   public onClosedDatesChanged(event: CalendarDay[][]) {
      this.calendarDates = event;      
      this.isValid = true;
   }   

   // Updates the new selected closed dates
   private convertCalendarDates(calendarDate: CalendarDay[][]): string[] {
      // Convert the calendarDates matrix [week][dayofweek] to a flat array [1 (1st day open), 0(2nd day closed), ...]
      let formattedClosedDates: string[] = [];
      calendarDate.forEach(calendarWeek => {
         let week = calendarWeek.map(calendarDay => {
            if (calendarDay.isPart1OpenOnly()) return '1';
            else if (calendarDay.isPart2OpenOnly()) return '2';
            else if (calendarDay.isOpen()) return 'o';
            else if (calendarDay.isClosed()) return 'x';
         });
         formattedClosedDates = formattedClosedDates.concat(week);
      })
      return formattedClosedDates;
   }

   // Populates the calendarDates property where each week will be an entry in the array.
   private calculateClosedDates() {
      if (this.datesClosed) {         
         let closedDatesInWeek: CalendarDay[] = []
         this.datesClosed.forEach((value, index) => {
            closedDatesInWeek.push(this.createCalendarDay(index, value));
            if ((index + 1) % 7 === 0 && index !== 0) {               
               this.calendarDates.push(closedDatesInWeek);
               closedDatesInWeek = [];
            }            
         });
      }
   }

   // Build a CalendarDay object to be used by the closed dates selection component
   private createCalendarDay(index: number, value: string): CalendarDay {
      let momentStartCycleDate: moment.Moment = moment(this.scheduleStartDate);
      let momentDayDate: moment.Moment = momentStartCycleDate.clone().add(index, 'days')

      let dayDate: Date = momentDayDate.toDate();
      let calendarDay: CalendarDay = new CalendarDay();
      calendarDay.date = dayDate;
      calendarDay.content = momentDayDate.format('DD ddd')

      // Setting the availability of a day based on the available dates collection for the selected caller      
      //calendarDay.isOpen = value.toLowerCase() === 'o';    
      if(value.toLowerCase() === 'o') {
         calendarDay.isPart1Open = true;
         calendarDay.isPart2Open = true;
      }
      else if(value.toLowerCase() === 'x') {
         calendarDay.isPart1Open = false;
         calendarDay.isPart2Open = false;
      }    
      else if(value.toLowerCase() === '1') {
         calendarDay.isPart1Open = true;
         calendarDay.isPart2Open = false;
      }    
      else if(value.toLowerCase() === '2') {
         calendarDay.isPart1Open = false;
         calendarDay.isPart2Open = true;
      }    
      return calendarDay;
   }
}
