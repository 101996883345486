import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Company } from 'app/models/company';

/*
   Delete company  dialog is a model window that displays the company the user has selected and confirms they want to delete the company.
*/

@Component({
  selector: 'callsmart-delete-company--dialog',
  templateUrl: './delete-company--dialog.component.html'
})
export class DeleteCompanyDialogComponent implements OnInit {

   companies: Company[] = [];

   // company model used by this dialog.
   @Input() company: Company;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() delete = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngOnInit() {
      this.companies.push(this.company);
   }

   public onDelete() {
      // do the delete
      this._applicationStore.sysAdminStore.deleteCompany(this.company.companyId);
      this.display = false;
      this.delete.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}
