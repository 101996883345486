import {timer as observableTimer,  Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationStore } from 'app/stores/application-store';
import { ProjectStatus } from 'app/models/projectStatus';
import { ImportOptions } from 'app/models/import-options';
import { ProjectStatusDetail } from 'app/models/projectStatusDetail';
import { RouteInfillDialogComponent } from 'app/shared/route-infill-dialog/route-infill-dialog.component';

// the project satus work space is directed to after the new project wizard and after a project is opened, before it is
// fully loaded the staus of the project is queried and displayed, are all drive times generated, are all fast
// optimisations complete
@Component({
   selector: 'callsmart-project-status-workspace',
   templateUrl: './project-status-workspace.component.html'
})
export class ProjectStatusWorkspaceComponent implements OnInit, OnDestroy {
   public title: string;
   public initProjectUi: boolean;

   public callerCount: number;
   public drivetimeCount: number;
   public scheduleCount: number;
   public totalQueuedDrivetimesCount: number;
   public totalPendingDrivetimesCount: number;
   public queuedProjectDrivetimesCount: number;
   public totalQueuedOptimisationsCount: number;
   public totalPendingOptimisationsCount: number;
   public queuedProjectOptimisationsCount: number;
   public pendingProjectDrivetimesCount: number;
   public pendingProjectOptimisationsCount: number;
   public errorMessage: string = '';
   public travelModel: string = '';

   public drivetimeProgress: number = 50;
   public optimiseProgress: number = 50;
   public detail: ProjectStatusDetail[];

   public drivetimeIconClass: string = ProjectStatusWorkspaceComponent._initialDrivetimeIconClass;
   public optimisingIconClass: string = ProjectStatusWorkspaceComponent._initialOptimiseIconClass;

   // Determine whether to display the route infill dialog.
   public showRouteInfillDialog: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public routeInfillDialog = RouteInfillDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      displayCancelButton: true,
      routeInfillData: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      continue: () => this.onContinueWithRouteInfill(),
      cancel: () => this.onDeleteProject()
   };

   private static readonly _initialDrivetimeIconClass: string = 'cs-initialise-project-progress-icon icon-map-1';
   private static readonly _initialOptimiseIconClass: string = 'cs-initialise-project-progress-icon icon-magic-wand';

   private _subscriptions: Subscription = new Subscription();
   private _firstStatusEvent: boolean = true;
   private _projectId: number;
   private _copyProjectId: number = -1;
   private _originalProjectId: number = -1;
   private _tempProjectId: number = -1;

   public constructor(private _applicationStore: ApplicationStore, private _router: Router,
      private _location: Location) {
   }

   public ngOnInit(): void {
      if (this._router.url == '/project-status') {
         this.title = 'Creating project...';
         this.initProjectUi = true;
      }
      else if (this._router.url == '/updating-callpoints' || this._router.url == '/merging-callpoints') {
         this.title = 'Updating callpoint(s)...';
         this.initProjectUi = false;
      }
      else if (this._router.url == '/updating-callers' || '/updating-callpoints-callers' || this._router.url == '/merging-callers') {
         this.title = 'Updating caller(s)...';
         this.initProjectUi = false;
      }
      else if (this._router.url == '/merging-callers-callpoints') {
         this.title = 'Updating caller(s) and callpoint(s)...';
         this.initProjectUi = false;
      }
      else {
         throw 'Unexpected route';
      }

      this.subscribeToProjectStatus();
      this.subscribeToMergeImportLocationChanged();
      this.subscribeToInfillRoutes();
   }

   ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
   }

   public onCancelProject() {
      // console.log('Cancelling project, copyProjectId', this._copyProjectId);

      // We don't have a valid copy project id, this means that the cancel is being done from the
      // create new wizard, delete the project and clean up
      if (this._copyProjectId == -1) {
         if (this._applicationStore.projectsStore.selectedProject) {
            if (this._applicationStore.projectsStore.selectedProject.projectId == this._projectId) {
               this._applicationStore.projectsStore.setSelectedProject(null, this._applicationStore.authenticationStore.loggedInUser.userId);
            }
         }
         // The user may have updated the caller or callpoint location which triggers the drivetime service
         // to recalculate the new routes. This can result in an error which gets displayed on the 
         // summary screen. In this scenario, the user has pressed the Cancel button so the project 
         // must not be delted.
         if(!this._applicationStore.hasLocationChanged()) {
            this._applicationStore.projectsStore.deleteProject(this._projectId);
         }
      }
      else {
         // Cancel is being done from a project update, need to roll back changes from the import
         // and restore the original project.
         this._applicationStore.projectsStore.rollbackProjectImportChanges(this._originalProjectId, this._copyProjectId);
      }

      // Pause to let delete take effect then nave to open project page
      observableTimer(2000).subscribe(() => this._router.navigate(['/open-project']));
   }

   public onDeleteProject() {
      this.showRouteInfillDialog = false;
      this.dialogInput.display = false;
      this.onCancelProject();
   }

   public onContinueWithRouteInfill() {
      this.showRouteInfillDialog = false;
      this.dialogInput.display = false;
      this.navigateToDestination();
   }

   private navigateToDestination() {
      observableTimer(1000).subscribe(() => {
         if (this._router.url == '/updating-callpoints-callers'
            || this._router.url == '/updating-callers'
            || this._router.url == '/updating-callpoints') {
            this._router.navigate([this._applicationStore.contextualPanelStore.requestingUrl]);
         }
         else {
            this._router.navigate(['/dashboard']);
         }
      });
   }

   private showInfillDialog(infillRoutes: string[]) {
      this.dialogInput.routeInfillData = infillRoutes;
      
      if (this._router.url == '/updating-callpoints-callers'
            || this._router.url == '/updating-callers'
            || this._router.url == '/updating-callpoints'
            || this._router.url == '/merging-callpoints'
            || this._router.url == '/merging-callers'
            || this._router.url == '/merging-callers-callpoints') {
            this.dialogInput.displayCancelButton = false;
         }
         else {
            this.dialogInput.displayCancelButton = true;
         }
      this.showRouteInfillDialog = true;
      this.dialogInput.display = true;
   }

   private subscribeToProjectStatus(): void {
      this._subscriptions.add(this._applicationStore.projectsStore.projectStatus$
         .subscribe((status: ProjectStatus) => {
            this._projectId = status.projectId;
            this.checkNavigation(status);
            this.updateView(status);
         }));
   }

   private subscribeToInfillRoutes(): void {
      this._subscriptions.add(this._applicationStore.callersStore.infillRoutes$
         .subscribe((infillRoutes: string[]) => {
            if(infillRoutes && infillRoutes.length > 0) {
               this.showInfillDialog(infillRoutes);
            }
            else {
               this.navigateToDestination();
            }
         }));
   }

   // Checks whether project initialisation has completed and navigates back to the correct display.
   private checkNavigation(status: ProjectStatus): void {

      let projectInitialised: boolean = status.drivetimeCount == status.callerCount
         && status.scheduleCount == status.callerCount
         && status.isProjectInitialising == false
         && status.errorMessage == ''
         && status.queuedProjectOptimisationsCount == 0
         && status.pendingProjectOptimisationsCount == 0;

      // do nothing for a -1 in the count as it is not the true first cycle
      if (status.callerCount !== -1) {
         if (this._firstStatusEvent || !projectInitialised) {
            if (projectInitialised) {

               if (this._firstStatusEvent) {
                  // if user opening a completed project dont delay just goto dash
                  this._router.navigate(['/dashboard']);
               }
               else {
                  // When project initialisation completes pause for a few seconds so that the user can see the
                  // progress bar hit 100% then load the Dashboard.
                  if (status.drivetimeCount == status.callerCount && status.callerCount == status.scheduleMetricCount) {
                     observableTimer(4000).subscribe(() => this._router.navigate(['/dashboard']));
                  } else {
                     observableTimer(7000).subscribe(() => this._router.navigate(['/dashboard']));
                  }
               }

            }
            this._firstStatusEvent = false;
         }
         else {
            // console.log(this._router.url);

            if (this._router.url == '/project-status'
               || this._router.url == '/merging-callpoints'
               || this._router.url == '/merging-callers'
               || this._router.url == '/merging-callers-callpoints') {

               // A copy of the original project is taken when the user imports callers or callpoints for
               // back up purposes. If the import succeeds then the copy needs to be deleted.
               if (this._copyProjectId != -1) {
                  this._applicationStore.projectsStore.deleteProject(this._copyProjectId, false);

                  //Also delete the temp project that the import was done in to
                  this._applicationStore.projectsStore.deleteProject(this._tempProjectId, false);
                  this._applicationStore.projectsStore.resetImportOptions();
               }

               // Refresh the callpoints
               if (this._router.url == '/merging-callpoints') {
                  // console.log("reloading callpoints for selected caller from the project status workspace", status);
                  this._applicationStore.callpointsStore.loadCallpointsForCaller(this._applicationStore.callersStore.selectedCaller);

                  this._applicationStore.scheduleStore.loadDiaryEvents(this._applicationStore.callersStore.selectedCaller.callerId,
                     this._applicationStore.projectsStore.selectedProject.projectId, false);

                  this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId,
                        this._applicationStore.callersStore.selectedCaller.callerId);
               }
               // Refresh the caller data, this will also trigger the dashboard calculations.
               else if (this._router.url == '/merging-callers') {
                  if (this._projectId) {
                     // console.log("reloading the callers from the project status workspace");
                     this._applicationStore.callersStore.loadAllCallers(this._projectId);
                     
                     this._applicationStore.scheduleStore.loadDiaryEvents(this._applicationStore.callersStore.selectedCaller.callerId,
                        this._applicationStore.projectsStore.selectedProject.projectId, false);
   
                     this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId,
                           this._applicationStore.callersStore.selectedCaller.callerId);
                  }
               }
               // Updating both callers and callpoints, /merging-callers-callpoints)
               else {
                  if (this._projectId) {
                     // console.log("reloading callers and callpoints for selected caller from the project status workspace");
                     this._applicationStore.callersStore.loadAllCallers(this._projectId);

                     // A new project will not have a selected caller.
                     if(this._applicationStore.callersStore.selectedCaller !== null) {
                        this._applicationStore.callpointsStore.loadCallpointsForCaller(this._applicationStore.callersStore.selectedCaller);
                     }
                  }
               }

               // When project initialisation completes pause for a few seconds so that the user can see the
               // progress bar hit 100% then load the Dashboard.
               if (status.drivetimeCount == status.callerCount && status.callerCount == status.scheduleMetricCount) {
                  this.checkAndNavigateInfillRoutes([]);
                  //Observable.timer(4000).subscribe(() => this._router.navigate(['/dashboard']));
               } else {
                  //Observable.timer(7000).subscribe(() => this._router.navigate(['/dashboard']));
                  this.checkAndNavigateInfillRoutes([]);
               }
            }
            else if (this._router.url == '/updating-callpoints-callers') {
               this._applicationStore.callpointsStore.loadCallpointsForCaller(this._applicationStore.callersStore.selectedCaller)
               // clear the selected callpoints
               this._applicationStore.callpointsStore.setSelectedCallpoints([]);
               // clear the selected diary event in the schedule
               this._applicationStore.scheduleStore.setSelectedDiaryEvent(null);
               // clear the selected diary day in the schedule
               this._applicationStore.scheduleStore.setSelectedDiaryDay(null);
               // clear the selected visit in the schedule
               this._applicationStore.scheduleStore.setSelectedVisit(null);
               //this._location.back();
               this.checkAndNavigateInfillRoutes(this._applicationStore.callpointsStore.callpointCallerChangedIds);
            }
            else if (this._router.url == '/updating-callers') {
               this._applicationStore.scheduleStore.clearSchedule(this._applicationStore.callersStore.selectedCaller.callerId);
               this._applicationStore.callersStore.setSelectedCaller(this._applicationStore.callersStore.selectedCaller);

               this.checkAndNavigateInfillRoutes(this._applicationStore.callersStore.callerLatLngChangedIds);
               //this._applicationStore.callersStore.getInfillRoutesForCallers(this._projectId, )
               // Observable.timer(1000).subscribe(() => {
               //    this._router.navigate([this._applicationStore.contextualPanelStore.requestingUrl])
               // });

            }
            else {
               // Router url '/updating-callpoints' is used to display the status page when regenerating the
               // drivetimes after changing a callpoint or callerlocation. In this case go back to the last screen the
               // User was using.
               //this._location.back();
               this._applicationStore.scheduleStore.loadDiaryEvents(this._applicationStore.callersStore.selectedCaller.callerId,
                  this._applicationStore.projectsStore.selectedProject.projectId, false);

               this._applicationStore.visitsStore.loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId,
                  this._applicationStore.callersStore.selectedCaller.callerId);
               
               this.checkAndNavigateInfillRoutes(this._applicationStore.callpointsStore.callpointLatLngChangedIds);
               // Observable.timer(1000).subscribe(() => {
               //    this._router.navigate([this._applicationStore.contextualPanelStore.requestingUrl])
               // });
            }
         }
      }
   }

   // Updates the view with the current project initialisation status.
   private updateView(status: ProjectStatus): void {
      this.callerCount = status.callerCount;
      this.drivetimeCount = status.drivetimeCount;
      this.scheduleCount = status.scheduleCount;
      this.totalQueuedDrivetimesCount = status.totalQueuedDrivetimesCount;
      this.totalPendingDrivetimesCount = status.totalPendingDrivetimesCount;
      this.queuedProjectDrivetimesCount = status.queuedProjectDrivetimesCount;
      this.pendingProjectDrivetimesCount = status.pendingProjectDrivetimesCount;
      this.totalQueuedOptimisationsCount = status.totalQueuedOptimisationsCount;
      this.totalPendingOptimisationsCount = status.totalPendingOptimisationsCount;
      this.queuedProjectOptimisationsCount = status.queuedProjectOptimisationsCount
      this.pendingProjectOptimisationsCount = status.pendingProjectOptimisationsCount
      this.errorMessage = status.errorMessage;
      this.travelModel = status.travelModelUsed;

      this.drivetimeProgress = this.drivetimeCount / this.callerCount * 100;
      this.optimiseProgress = this.scheduleCount / this.callerCount * 100;

      if (this.drivetimeIconClass == ProjectStatusWorkspaceComponent._initialDrivetimeIconClass
         && this.drivetimeProgress == 100) {
         this.drivetimeIconClass = 'cs-initialise-project-progress-icon-green icon-check';
      }

      if (this.optimisingIconClass == ProjectStatusWorkspaceComponent._initialOptimiseIconClass
         && this.optimiseProgress == 100) {
         this.optimisingIconClass = 'cs-initialise-project-progress-icon-green icon-check';
      }

      // Sort the status list so that Queued and Pending callers appear at the top and the Success
      // at the bottom.
      let sortedList = status.projectStatusDetail.sort((a,b) => {
         return a.drivetimeStatus.localeCompare(b.drivetimeStatus);

      });

      sortedList.forEach(statusDetail => {
         if(statusDetail.drivetimeStatus == 'Success') {
            statusDetail.drivetimeStatus = 'Complete';
         }
         if(statusDetail.scheduleStatus == 'Success') {
            statusDetail.scheduleStatus = 'Complete';
         }

         if(statusDetail.drivetimeStatus == 'Pending') {
            statusDetail.drivetimeStatus = 'Calculating';
         }
         if(statusDetail.scheduleStatus == 'Pending') {
            statusDetail.scheduleStatus = 'Calculating';
         }
      })
      this.detail = sortedList;
   }

   private subscribeToMergeImportLocationChanged(): void {
      this._subscriptions.add(this._applicationStore.projectsStore.mergeImportLocationChanged$.subscribe(
         (importOptions: ImportOptions) => {
            if (importOptions) {
               //console.log('Got importOptions', JSON.stringify(importOptions));
               this._copyProjectId = importOptions.originalProjectCopyId;
               this._originalProjectId = importOptions.originalProjectId;
               this._tempProjectId = importOptions.tempProjectId;
            }
            else {
               this._copyProjectId = -1;
               this._originalProjectId = -1;
               this._tempProjectId = -1;
            }
         }));
   }

   private checkAndNavigateInfillRoutes(callerIdsChanged: number[]) {
      this._applicationStore.callersStore.getInfillRoutesForCallers(this._projectId, callerIdsChanged);
   }
}
