import { Event } from 'app/models/diary/event';

/**
 * Represents a visit in the diary.
 */
export class VisitSelection {
   public selectedVisit: Event; 
   public driveTime: number; 
   public phase: number; 
   public dayIndex: number; 
   public eventIndex: number;
   public visitId:string;
}
