import { CsDropdownComponent } from 'app/shared/dropdown/dropdown.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "primeng/primeng";

@NgModule({
    imports: [CommonModule, SharedModule],
    exports: [CsDropdownComponent, SharedModule],
    declarations: [CsDropdownComponent]
})
export class CsDropdownModule { }