import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { DayCombination } from 'app/models/settings/day-combination';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ErrorDialogComponent } from 'app/shared/error-dialog/error-dialog.component';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';
import { CallerSettings } from 'app/models/settings/caller-settings';

@Component({
   templateUrl: './user-callpoint-settings.component.html'
})
export class UserCallpointSettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate, AfterContentInit {

   // Subscription for the user default callpoint settings.
   private _default_user_callpointsettings_subscription: Subscription;
   // Subscription for the default callpoint settings.
   private _settings_subscription: Subscription;
   // Subscription for the day combinations.
   private _dayCombinations_subscription: Subscription;
   // Subscription for the recommended day combinations.
   private _recommendedDayCombinations_subscription: Subscription;

   private _userCallPointSettingsUpdated: Subscription;

   public scrollHeight: number;
   public callpointSettings = new CallpointSettings();

   // Project available days
   public defaultProjectActiveWorkingDays: boolean[] = [];
   // All possible day combinations
   public allPossibleDayCombinations: DayCombination[] = [];
   // Recommended combinations (string value)
   public recommendedDayCombinations: string[] = [];

   public errorDialog = ErrorDialogComponent; // Dialog to display validation errors.
   public showErrorDialog: boolean = false; // Sets the visibility of the error dialog component.

   // Drives the enablement of the save button
   public formValid: boolean = false;

   @ViewChild('editCallpointSettings') editCallpointSettings;

   // Properties for the error dialog dynamic loading
   public dialogInput = { display: false, title: '', bodyText: '', validationErrors: [] };
   public dialogOutput = {
      cancel: () => this.onCancelAlertDialog()
   };

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 185;
      });
   }

   ngOnInit() {
      this.subscribeToUserDefaultCallpointSettings();
      this.subscribeToUserDefaultCallerSettings();
      this.subscribeToAllPossibleDayCombination();
      this.subscribeToRecommendedDayCombination();
      this.subscribeToCallPointSettingsUpdated();

      this.loadCallPointData();
   }

   loadCallPointData() {
      this._applicationStore.userCallpointSettingsStore.loadUserCallPointSettings();
      this._applicationStore.defaultDayCombinationsStore.loadAllDayCombinations();
      this._applicationStore.defaultDayCombinationsStore.loadRecommendedDayCombinations();
      this._applicationStore.userCallerSettingsStore.loadDefaultCallerSettings();
   }

   ngOnDestroy() {
      if (this._default_user_callpointsettings_subscription) {
         this._default_user_callpointsettings_subscription.unsubscribe();
      }
      if (this._settings_subscription) {
         this._settings_subscription.unsubscribe();
      }
      if (this._dayCombinations_subscription) {
         this._dayCombinations_subscription.unsubscribe();
      }
      if (this._recommendedDayCombinations_subscription) {
         this._recommendedDayCombinations_subscription.unsubscribe();
      }
      if (this._userCallPointSettingsUpdated) {
         this._userCallPointSettingsUpdated.unsubscribe();
      }
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallpointSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editCallpointSettings.callpointSettingsForm.pristine;
   }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editCallpointSettings.callpointSettingsForm.form.markAsPristine();

      if (this.callpointSettings.dayCombinations) {
         // Checks the number of active working days
         let numberOfWorkingDays = this.defaultProjectActiveWorkingDays.filter(item => item).length;
         let emptySelectionDayCombinationGroups: number[]
         if (numberOfWorkingDays > 1) {
            emptySelectionDayCombinationGroups =
               CallsmartUtils.getEmptyDayCombinationGroups(numberOfWorkingDays, this.callpointSettings.dayCombinations)
         }
         // Every DayCombination group has, at least, one selected item
         if (emptySelectionDayCombinationGroups.length === 0) {
            this._applicationStore.userCallpointSettingsStore.updateUserCallPointSettings(this.callpointSettings);
         }
         else {
            this.showDayCombinationsError(emptySelectionDayCombinationGroups);
         }
      }
   }

   public onCancel() {
      if (this._applicationStore.projectsStore.selectedProject === null) {
         this._router.navigate(['/open-project']);
      }
      else {
         this._router.navigate(['/dashboard']);
      }
   }

   // cancel button from the add\edit event dialog
   public onCancelAlertDialog() {
      this.showErrorDialog = false;
      this.dialogInput.display = false;
   }

   private subscribeToUserDefaultCallpointSettings(): void {
      this._default_user_callpointsettings_subscription = this._applicationStore.userCallpointSettingsStore.defaultCallpointSettings$.subscribe(
         (callpointSettings: CallpointSettings) => {
            if (callpointSettings !== null) {
               this.callpointSettings = callpointSettings;
            }
         });
   }

   private subscribeToUserDefaultCallerSettings() {
      this._settings_subscription = this._applicationStore.userCallerSettingsStore.defaultCallerSettings$.subscribe(
         (callerSettings: CallerSettings) => {
            if (callerSettings !== null) {
               this.defaultProjectActiveWorkingDays = callerSettings.workingDayActive.slice();
            }
         }
      );
   }

   private subscribeToAllPossibleDayCombination() {
      this._dayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.allDayCombinations$.subscribe(
         (dayCombinations: DayCombination[]) => {
            this.allPossibleDayCombinations = dayCombinations;
         });
   }

   private subscribeToRecommendedDayCombination() {
      this._recommendedDayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.recommendedCombinations$.subscribe(
         (recommendedCombinations: string[]) => {
            this.recommendedDayCombinations = recommendedCombinations;
         });
   }

   private subscribeToCallPointSettingsUpdated() {
      this._userCallPointSettingsUpdated = this._applicationStore.userCallpointSettingsStore.userCallPointSettingsUpdated$.subscribe(
         (isUpdated: boolean) => {
            if (isUpdated) {
               this.loadCallPointData();
            }
         });
   }
   
   // Displays the alert dialog with the dayCombinations error.
   private showDayCombinationsError(emptySelectionDayCombinationGroups: number[]) {
      this.showErrorDialog = true;
      this.dialogInput.title = 'Callpoint defaults';
      let validationErrors: string[] = [];
      if (emptySelectionDayCombinationGroups && emptySelectionDayCombinationGroups.length > 0) {
         emptySelectionDayCombinationGroups.forEach(groupNumber => {
            validationErrors.push(groupNumber + ' visits per week');
         })
      }
      this.dialogInput.bodyText = 'Please, select at least one day combination per group. The following groups do not have any selected day combination:';
      this.dialogInput.validationErrors = validationErrors;
      this.dialogInput.display = true;
   }
}
