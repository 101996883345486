import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';

/**
 * Custom converter used by the json2typescript mapping library to
 * map date array values to Javascript Date[] type. See caller-settings.ts for usage example.
 */
@JsonConverter
export class DateArrayConverter implements JsonCustomConvert<Date[]> {
   serialize(date: Date[]): any {
      return date;
   }
   deserialize(dateStrings: string[]): Date[] {
      if (dateStrings === null) {
         return undefined;
      } else {
         let dateObjects: Date[] = dateStrings.map((date) => new Date(date));

         // null dateime arrays are going to the database as empty array cater for this
         if (dateObjects.length === 0) {
            return undefined;
         }

         return dateObjects;
      }
   }
}
