import { Injectable } from '@angular/core';
import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Observer, Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/primeng';

/** 
 * Generic interface defines the canDeactivate() method that must be implemented by any class that wants
 * to use the CanDeactive guard. This method can be used to perform logic before the
 * user is navigated away from the current page. For example to warn the user if they have made changes and 
 * decide to navigate away.
 * 
 * Usage example:
 * 
 * export class UserSettings implements CanComponentDeactivate {
 *    constructor(private dialogService: DialogService){}
 * 
 *    canDeactivate(): Observable<boolean> | boolean {
 *       if(form.dirty) {
 *          // Form is dirty then display warning to user
 *          return this.dialogService.confirm('Discard changes?');
 *       }
 *       else {
 *          return true;
 *       }
 *    }
 * }
 * 
 * Once you have implemented the method, don't forget to add the PendingChangesGuard class below to 
 * the app-routing-module against the route that needs this functionality. For example:
 * 
 * const appRoutes: Routes = [
 *    {
         path: 'settings',
         component: ProjectPropertiesSettingsComponent, 
         canActivate: [AuthGuardService], 
         canDeactivate: [PendingChangesGuard]
      }
 * ]
 * 
 * 
 * See project-properties-settings.component for detailed example.
*/
export interface CanComponentDeactivate {
   canDeactivate: () => boolean;
}

/**
 * Routing guard class for when the route needs to deactivate when the user navigats away from current page
 * without first saving changes. If the user has unsaved changes, then the guard will display a confirmation
 * dialog box warning the user.
 * 
 * This guard must be specified on the route in the app-routing-module.ts that needs this functionality.
 * See comments above.
 */
@Injectable()
export class PendingChangesGuard implements CanDeactivate<CanComponentDeactivate> {

   // Let the subscriber know what choice the user has made.
   private _confirmDialogResponse = new Subject<boolean>();
   public confirmDialogResponse$ = this._confirmDialogResponse.asObservable();

   constructor(private _confirmationService: ConfirmationService) { }

   canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot,
      currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
      if (component.canDeactivate) {
         let canDeactivate = component.canDeactivate();

         if (canDeactivate) {
            return true;
         }
         else {
            // Here an Observable is used to wrap the primeng dialog box because without it 
            // the routing won't be blocked until the user had made a choice.
            return Observable.create((observer: Observer<boolean>) => {
               this._confirmationService.confirm({
                  message: 'You have unsaved changes. Are you sure you want to leave this page?',
                  accept: () => {
                     observer.next(true);
                     observer.complete();
                     this._confirmDialogResponse.next(true);
                  },
                  reject: () => {
                     observer.next(false);
                     observer.complete();
                     this._confirmDialogResponse.next(false);
                  }
               });
            });
         }
      }
      else return false;
   }
}