<div class="panel">
   <div class="panel-body">
      <div class="row">
         <div class="col-md-12">
            <edit-callpoint-settings name="editCallpointSettings" 
               [(ngModel)]="callpointSettings" 
               [scrollHeight]="scrollHeight" 
               [displayHeading]="false"
               [allPossibleDayCombinations]="allPossibleDayCombinations"                  
               [recommendedDayCombinations]="recommendedDayCombinations"
               [projectActiveWorkingDays]="projectActiveWorkingDays"
               #editCallpointSettings>
            </edit-callpoint-settings>
         </div>
      </div>

      <div class="row">
         <div class="col-md-12">
            <div class="btn-toolbar">
               <input type="submit" [disabled]="!formValid || canDeactivate()" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save"
                  (click)="submitForm()">
               <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel"
                  (click)="onCancel()">
            </div>
         </div>
      </div>
      <ndc-dynamic *ngIf="showErrorDialog" [ndcDynamicComponent]="errorDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
      </ndc-dynamic>
   </div>
</div>
