<div class="small-text cs-color-white contextualPanel-background-color">
   <div *ngIf="caller !== null; else nothingSelected">
      <h2
         class="contextualPanel-header-color"
         style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
         pTooltip="{{ caller?.name }}, {{ caller?.territory }}"
      >
         {{ caller?.name }}
      </h2>
   </div>
   <ng-template #nothingSelected>
      <h2
         class="contextualPanel-header-color"
         style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
      >
         No Caller selected
      </h2>
   </ng-template>
   <div [ngClass]="{ 'cs-block-ui': caller === null }">
      <div *ngIf="caller !== null">
         <span
            >{{ caller?.territory }} - {{ caller?.address1 }},
            {{ caller?.postcode }}</span
         >
      </div>
      <div style="margin-top: 10px; margin-bottom: 10px;">Optimise</div>
      <div style="display: block; padding-bottom: 45px;">
         <button
            type="button"
            class="btn cs-btn-primary-inverse context-panel-optimise-buttons"
            style="float: left;"
            (click)="onFastOptimise()"
            [disabled]="optimiseButtonDisabled"
         >
            <i class="cs-icon icon-hourglass-3" style="font-size: 15px;"></i>
            Fast
         </button>
         <button
            type="button"
            class="btn cs-btn-primary-inverse context-panel-optimise-buttons"
            style="float: right;"
            (click)="onFullOptimise()"
            [disabled]="optimiseButtonDisabled"
         >
            <i class="cs-icon icon-hourglass" style="font-size: 15px;"></i> Full
         </button>
      </div>
      <div
         class="btn-group"
         style="
            margin-top: 10px;
            margin-bottom: 10px;
            display: table;
            overflow: auto;
            width: 100%;
         "
      >
         <button
            type="button"
            class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
         >
            <span style="float: left;">Actions</span>
            <span class="cs-icon icon-arrow-down dropdown-button-icon"></span>
         </button>
         <ul class="dropdown-menu cs-dropdown-inverse" style="width: 220px;">
            <li>
               <a (click)="editCaller()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-edit"
                  ></span>
                  Edit Caller</a
               >
            </li>
            <li>
               <a (click)="editCalendar()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-circle-backslash"
                  ></span>
                  Edit calendar</a
               >
            </li>

            <li>
               <a (click)="clearScheduleUnlocked()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-calendar-8"
                  ></span>
                  Clear schedule: Unlocked
               </a>
            </li>
            <li>
               <a (click)="clearSchedule()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-calendar-9"
                  ></span>
                  Clear schedule: All
               </a>
            </li>
            <li *ngIf="showScheduleExport">
               <a (click)="exportSchedule()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-upload"
                  ></span>
                  Export schedule</a
               >
            </li>
            <li *ngIf="showCallerExport">
               <a (click)="exportCallerData()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-upload"
                  ></span>
                  Export Caller(s)</a
               >
            </li>
            <li
               *ngIf="showCallpointExport"
               [ngClass]="{ disabled: disableCallpointExport }"
            >
               <a
                  (click)="exportCallpointData()"
                  [ngClass]="{
                     'callsmart-link-no-active': disableCallpointExport
                  }"
               >
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-upload"
                  ></span>
                  Export Callpoint(s)</a
               >
            </li>
            <li>
               <a (click)="editCallpointLocking()">
                  <span
                     style="margin-right: 5px;"
                     class="cs-icon icon-edit"
                  ></span>
                  Mulitple Callpoints</a
               >
            </li>
         </ul>
      </div>

      <div class="no-padding contextual-panel-list">
         <div
            class="no-padding contextualPanel-list-items"
            pTooltip="Overriden from project default"
            tooltipPosition="top"
            [tooltipDisabled]="!isContractedWorkingHoursOverriden()"
         >
            <div class="contextualPanel-list-items-firstline">
               Contracted working hours:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noContractedHours"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{
                     'contextualPanel-overriden-color': isContractedWorkingHoursOverriden()
                  }"
               >
                  {{
                     getHoursAndMinutes(
                        (callerSettingsViewModel?.contractedWorkingHoursWeek)[0]
                     )
                  }}
                  -
                  {{
                     getHoursAndMinutes(
                        (callerSettingsViewModel?.contractedWorkingHoursWeek)[1]
                     )
                  }}
               </div>
               <ng-template #noContractedHours>
                  - -
               </ng-template>
            </div>
         </div>
         <div
            class="no-padding contextualPanel-list-items"
            pTooltip="Overriden from project default"
            tooltipPosition="top"
            [tooltipDisabled]="!isMaxOneWayCommuteTimeOverriden()"
         >
            <div class="contextualPanel-list-items-firstline">
               Maximum one way commute:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noMaximum"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{
                     'contextualPanel-overriden-color': isMaxOneWayCommuteTimeOverriden()
                  }">
                  <div *ngIf="callerSettingsViewModel.noMaximum; else commuteTime">
                     No maximum
                  </div>
                  <ng-template #commuteTime>
                     {{ callerSettingsViewModel.maxOneWayCommuteTime }} mins
                  </ng-template>
               </div>
               <ng-template #noMaximum>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
      <div class="no-padding contextual-panel-list">
         <div
            class="no-padding contextualPanel-list-items"
            pTooltip="Overriden from project default"
            tooltipPosition="top"
            [tooltipDisabled]="!isMaxVisitsPerDayOverriden()"
         >
            <div class="contextualPanel-list-items-firstline">
               Maximum visits allowed per day:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noMaxCallsPerDay"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{
                     'contextualPanel-overriden-color': isMaxVisitsPerDayOverriden()
                  }">
                  <div *ngIf="callerSettingsViewModel.visitsPerDayNoMaximum; else maxVisits">
                     No maximum
                  </div>
               <ng-template #maxVisits>
                  {{ callerSettingsViewModel.maxVisitsPerDay }}
               </ng-template>
            </div>
            <ng-template #noMaxCallsPerDay>
               - -
            </ng-template>
            </div>
         </div>
         <div
            class="no-padding contextualPanel-list-items"
            pTooltip="Overriden from project default"
            >
            <div class="contextualPanel-list-items-firstline">
               Average visits per day:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noAvgVisits"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{
                     'contextualPanel-overriden-color': false
                  }"
               >
                  {{ scheduleMetrics?.avgCallsPerDay }}
               </div>
               <ng-template #noAvgVisits>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">
               Utilisation %:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noUtilisation"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{
                     'contextualPanel-overriden-color': false
                  }">
                  <br>
                  {{ scheduleMetrics?.utilisationPc }}
               </div>
               <ng-template #noUtilisation>
                  - -
               </ng-template>
            </div>
         </div>
         <div
            class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">
               Scheduled visits %:
            </div>
            <div class="contextualPanel-list-items-secondline">
               <div
                  *ngIf="caller !== null; else noUtilisationPc"
                  class="display-flex-column display-flex-first-column"
                  [ngClass]="{ 'contextualPanel-overriden-color': false }"
               >
                  {{ scheduledVisitsPc }}
               </div>
               <ng-template #noUtilisationPc>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- This is where caller settings dialog component will be loaded dynamically -->
<ndc-dynamic
   *ngIf="showCallerSettings"
   [ndcDynamicComponent]="editCallerSettingsDialog"
   [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput"
>
</ndc-dynamic>

<!-- This is where callpoint settings dialog component will be loaded dynamically -->
<ndc-dynamic
   *ngIf="showEditCalendar"
   [ndcDynamicComponent]="editCalendarDialog"
   [ndcDynamicInputs]="editCalendarDialogInput"
   [ndcDynamicOutputs]="editCalendarDialogOutput"
>
</ndc-dynamic>

<!-- This is where edit callpoint locking dialog component will be loaded dynamically -->
<ndc-dynamic
   *ngIf="showEditCallpoints"
   [ndcDynamicComponent]="editCallpontsDialog"
   [ndcDynamicInputs]="editCallpointsDialogInput"
   [ndcDynamicOutputs]="editCallpointsDialogOutput"
>
</ndc-dynamic>
