<h6 *ngIf="callpointSettings == null">Loading...</h6>
<div *ngIf="callpointSettings">
   <form #callpointSettingsForm="ngForm" class="form-horizontal" autocomplete="off">
      <div class="panel">
         <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
            <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
               <div *ngIf="!showOverrideButtons && displayHeading">
                  <h3>Callpoint defaults</h3>
               </div>

               <!-- Callpoint availability -->
               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.availabilityMultiEdit,
                              'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.availabilityMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallpoints" name="availabilityMultiEdit"
                           [(ngModel)]="callpointSettings.availabilityMultiEdit"
                           binary="true"
                           label="">
                        </p-checkbox>
                        Callpoint availability
                     </h6>
                     <p>The hours the callpoints are available for visits.</p>
                     <!-- This panel is greyed out based on the availabilityMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                        otherwise display the 'Multiple values set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.Availability); else availabilityMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.availabilityMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="availabilityOverrideSwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callpointSettings.availabilityDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the availabilityOverrideSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callpointSettings.availabilityDisabled}">
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:15px">
                              <div style="padding-top:10px; width:500px">
                                 <callsmart-date-range-slider name="availability" #availability="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                    [(ngModel)]="callpointSettings.availability" (userValueChanged)="onCheckCallpointAvailability($event)">
                                 </callsmart-date-range-slider>
                              </div>
                              <label *ngIf="!isAvailabilityValid">Invalid time entered. The availability start time must be earlier then availability end time</label>
                           </div>

                        </div><!-- multi edit cs-block-ui -->
                        <div *ngIf="displayCallpointSpecificSettings" class="form-group" [ngClass]="{'cs-block-ui': editingMultipleCallpoints}">
                           <div>
                              <label style="padding-left: 15px; padding-top: 15px; padding-bottom: 15px;">
                                 Only dates that are within the Call cycle ({{minDate | date:'dd/MM/y'}} to {{maxDate | date:'dd/MM/y'}}) can be selected.
                              </label>
                           </div>                                
                           <div class="col-sm-12">
                              <label for="startDateCalendarInput" class="control-label"  style="padding: 0px; margin-right: 10px">Earliest visit date</label>
                              <p-calendar 
                                 name="startDateCalendarInput" 
                                 dateFormat="dd/mm/yy" 
                                 selectionMode="single"
                                 selectOtherMonths="true" 
                                 [locale]="en"
                                 [showIcon]="true" 
                                 [(ngModel)]="callpointSettings.startDate" 
                                 [style]="{'border':'none'}" 
                                 [minDate]="minDate"
                                 [maxDate]="maxDate"
                                 [readonlyInput]="true"
                                 (onSelect)="onStartDateSelected($event)"
                                 >
                              </p-calendar>
                              
                              <label for="endDateCalendarInput" class="control-label"  style="padding: 0px; margin-left: 100px; margin-right: 10px">Latest visit date</label>
                              <p-calendar 
                                 name="endDateCalendarInput" 
                                 dateFormat="dd/mm/yy" 
                                 selectionMode="single"
                                 selectOtherMonths="true" 
                                 [locale]="en"
                                 [showIcon]="true" 
                                 [(ngModel)]="callpointSettings.endDate" 
                                 [style]="{'border':'none'}" 
                                 [minDate]="minDate"
                                 [maxDate]="maxDate"
                                 [readonlyInput]="true"
                                 (onSelect)="onEndDateSelected($event)"
                                 >
                              </p-calendar>
                           </div>
                           <div>
                              <label *ngIf="!isCallpointStartDateValid" style="padding-left: 15px; padding-top: 15px;">
                                 Earliest Visit Date must be at least 1 day earlier than the call cycle end date
                              </label>
                           </div>
                           <div>
                              <label *ngIf="!isCallpointEndDateValid" style="padding-left: 15px; padding-top: 15px;">
                                 Latest Visit Date must be at least 1 day later than the Earliest Visit Date
                              </label>
                           </div>
                           
                        </div>
                     </div>
                     <!-- Else block -->
                     <ng-template #availabilityMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <!-- Duration -->
               <div *ngIf="displayCallpointSpecificSettings">
                     <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.durationMultiEdit,
                                 'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.durationMultiEdit}">
                        <div class="panel-body">
                           <h6>
                              <p-checkbox *ngIf="editingMultipleCallpoints" name="durationMultiEdit"
                                 [(ngModel)]="callpointSettings.durationMultiEdit"
                                 binary="true"
                                 label=""
                                 (onChange)="setDurationPropertyToEmpty($event)">
                              </p-checkbox>
                              Duration
                           </h6>
                           <!-- This panel is greyed out based on the durationMultiEdit checkbox above -->
                           <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                              otherwise display the 'Multiple values set' message-->
                           <div *ngIf="canShowInputs(mySettingSection.Duration); else durationMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.durationMultiEdit}">
                              <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                                    <label for="durationInput" class="control-label"  style="padding: 0px; margin-right: 10px">Duration</label>
                                    <input name="durationInput" #durationInput="ngModel" required
                                          class="form-control cs-form-control" [(ngModel)]="callpointSettings.duration"
                                          size="1" style="width: 50px;"
                                          type="number" (input)="onDurationInputValueChanged($event);"
                                          onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                    <label for="durationInput" class="control-label"  style="padding: 0px; margin-left: 10px">Minutes</label>
                              </div>
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                                 <span *ngIf="durationInput.touched && durationInput.errors">
                                    <label *ngIf="durationInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert Duration</label>
                                 </span>
                                 <!-- If any of the previous errors are displayed then this error is not display -->
                                 <label *ngIf="durationInput.touched && isDurationFormatValid &&  !isDurationValid"
                                       style="padding: 0px;margin-bottom: 0px">
                                       Please insert a value that is within callpoint availability ({{callpointAvailabilityDuration}} minutes)
                                 </label>
                              </div>
                           </div><!-- multi edit cs-block-ui -->
                           <!-- Else block -->
                           <ng-template #durationMultiValueMsg>
                              <span class="multiple-values-set">Multiple values set</span>
                           </ng-template>
                        </div>
                     </div>
               </div>

               <!-- Frequency -->
               <div *ngIf="displayCallpointSpecificSettings">
                  <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.frequencyMultiEdit,
                              'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.frequencyMultiEdit}">
                     <div class="panel-body">
                        <h6>
                           <p-checkbox *ngIf="editingMultipleCallpoints" name="frequencyMultiEdit"
                              [(ngModel)]="callpointSettings.frequencyMultiEdit"
                              binary="true"
                              label=""
                              (onChange)="setFrequencyPropertyToEmpty($event)">
                           </p-checkbox>
                           Frequency
                        </h6>
                        <!-- This panel is greyed out based on the frequencyMultiEdit checkbox above -->
                        <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple values set' message-->
                        <div *ngIf="canShowInputs(mySettingSection.Frequency); else frequencyMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.frequencyMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                                 <label for="frequencyInput" class="control-label"  style="padding: 0px; margin-right: 10px">Frequency</label>
                                 <input name="frequencyInput" #frequencyInput="ngModel" required
                                       class="form-control cs-form-control" [(ngModel)]="callpointSettings.frequency"
                                       size="1" style="width: 50px;"
                                       type="number" (input)="onFrequencyInputValueChanged($event);"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                 <label for="frequencyInput" class="control-label"  style="padding: 0px; margin-left: 10px">Visit(s) per call cycle</label>
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="frequencyInput.touched && frequencyInput.errors">
                                 <label *ngIf="frequencyInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert Frequency</label>
                              </span>
                              <!-- If any of the previous errors are displayed then this error is not display -->
                              <label *ngIf="frequencyInput.touched && !isFrequencyValid && isFrequencyFormatValid"
                                    style="padding: 0px;margin-bottom: 0px">
                                    Please insert a value between 1 and the number of calendar days in the call cycle length ({{projectCycleLength * 7}} days)
                              </label>
                           </div>
                        </div><!-- multi edit cs-block-ui -->
                        <!-- Else block -->
                        <ng-template #frequencyMultiValueMsg>
                           <span class="multiple-values-set">Multiple values set</span>
                        </ng-template>
                     </div>
                  </div>
               </div>

               <div *ngIf="displayCallpointSpecificSettings">
                  <!-- Priority -->
                  <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.priorityMultiEdit,
                  'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.priorityMultiEdit}">
                     <div class="panel-body">
                        <h6>
                           <p-checkbox *ngIf="editingMultipleCallpoints" name="priorityMultiEdit"
                              [(ngModel)]="callpointSettings.priorityMultiEdit"
                              binary="true"
                              label=""
                              (onChange)="setPriorityPropertyToEmpty($event)">
                           </p-checkbox>
                           Priority
                        </h6>
                        <!-- This panel is greyed out based on the priorityMultiEdit checkbox above -->
                        <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple values set' message-->
                        <div *ngIf="canShowInputs(mySettingSection.Priority); else priorityMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.priorityMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                                 <label for="priorityInput" class="control-label"  style="padding: 0px; margin-right: 10px">Priority</label>
                                 <input name="priorityInput" #priorityInput="ngModel" required
                                       class="form-control cs-form-control" [(ngModel)]="callpointSettings.priority"
                                       size="1" style="width: 50px;"
                                       type="number" (input)="onPriorityInputValueChanged($event);"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                 <label for="priorityInput" class="control-label"  style="padding: 0px; margin-left: 10px">0 = Off 100 = Highest prioritisation</label>
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="priorityInput.touched && priorityInput.errors">
                                 <label *ngIf="priorityInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert priority</label>
                              </span>
                              <!-- If any of the previous errors are displayed then this error is not display -->
                              <label *ngIf="priorityInput.touched && !isPriorityValid && isPriorityFormatValid"
                                    style="padding: 0px;margin-bottom: 0px">
                                    Please insert a value between 0 and 100
                              </label>
                           </div>
                        </div>
                        
                        <!-- multi edit cs-block-ui -->
                        <!-- Else block -->
                        <ng-template #priorityMultiValueMsg>
                           <span class="multiple-values-set">Multiple values set</span>
                        </ng-template>
                     </div>
                  </div>
                  <!-- Critical -->
                  <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.criticalMultiEdit,
                              'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.criticalMultiEdit}">
                     <div class="panel-body">
                        <h6>
                           <p-checkbox *ngIf="editingMultipleCallpoints" name="criticalMultiEdit"
                              [(ngModel)]="callpointSettings.criticalMultiEdit"
                              binary="true"
                              label=""
                              (onChange)="setCriticalProperty($event)">
                           </p-checkbox>
                           Critical
                        </h6>
                        <!-- This panel is greyed out based on the criticalMultiEdit checkbox above -->
                        <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple values set' message-->
                        <div *ngIf="canShowInputs(mySettingSection.Critical); else criticalMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.criticalMultiEdit}">

                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                              <div class="form-group">
                                 <label for="criticalInput" class="col-sm-1 control-label" style="padding-right: 41px; padding-top:10px;">Critical:</label>
                                 <callsmart-inputswitch name="criticalInput" #criticalInput="ngModel" [(ngModel)]="callpointSettings.critical" onLabel="Yes" offLabel="No"
                                    inverseColours="true" (onChange)="onCriticalInputSwitchChanged($event)">
                                 </callsmart-inputswitch>
                              </div>
                           </div>
                        </div>
                        
                        <!-- multi edit cs-block-ui -->
                        <!-- Else block -->
                        <ng-template #criticalMultiValueMsg>
                           <span class="multiple-values-set">Multiple values set</span>
                        </ng-template>
                     </div>
                  </div>
               </div>

               <!-- Latitude / Longitude -->
               <div *ngIf="displayCallpointSpecificSettings">
                  <div [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.locationMultiEdit,
                              'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.locationMultiEdit}">
                     <div class="panel-body">
                        <h6>
                           <p-checkbox *ngIf="editingMultipleCallpoints" name="locationMultiEdit"
                              [(ngModel)]="callpointSettings.locationMultiEdit"
                              binary="true"
                              label=""
                              (onChange)="setLocationPropertyToEmpty($event)">
                           </p-checkbox>
                           Location
                        </h6>
                        <!-- This panel is greyed out based on the frequencyMultiEdit checkbox above -->
                        <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple values set' message-->
                        <div *ngIf="canShowInputs(mySettingSection.Location); else locationMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.locationMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;; margin-bottom:10px">
                              <label for="latitudeInput" class="control-label"  style="padding: 0px; margin-right: 20px">Latitude</label>
                              <input name="latitudeInput" #latitudeInput="ngModel" required tabindex="4"
                                    class="form-control cs-form-control" [(ngModel)]="callpointSettings.latitude"
                                    size="10" style="width: 150px;"
                                    type="number" (input)="onLatitudeInputValueChanged($event);"
                                    pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)">
                              
                              <label for="searchPlace" class="control-label"  style="padding: 0px; margin-right: 10px; margin-left:70px">Place</label>
                              <input name="searchPlace" id="searchPlace"  tabindex="6"
                                       class="form-control cs-form-control"
                                       size="10" style="width: 280px;"
                                       type="text" 
                                       (input)="onSearchPlaceInputValueChanged($event);">
                              <button type="button" class="btn cs-btn-tertiary pull-right" (click)="applyLocation()" tabindex="7"
										         [disabled]="searchPlaceApplyButtonDisabled"
                                       style="margin-right: 8px; margin-left:10px">Update Coordinates</button>
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px;">
                              <span *ngIf="latitudeInput.touched && latitudeInput.errors">
                                 <label *ngIf="latitudeInput.errors.required" >Please insert Latitude</label>
                                 <label *ngIf="latitudeInput.errors.pattern">Please insert a valid Latitude value between -90 and 90</label>
                              </span>
                           </div>
                        </div><!-- multi edit cs-block-ui -->
                        <div *ngIf="canShowInputs(mySettingSection.Location);" [ngClass]="{'cs-block-ui': editingMultipleCallpoints && !callpointSettings.locationMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left; margin-bottom:10px">
                              <label for="longitudeInput" class="control-label"  style="padding: 0px; margin-right: 10px">Longitude</label>
                              <input name="longitudeInput" #longitudeInput="ngModel" required tabindex="5"
                                     pattern="\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
                                     class="form-control cs-form-control" [(ngModel)]="callpointSettings.longitude"
                                     size="10" style="width: 150px;"
                                     type="number" (input)="onLongitudeInputValueChanged($event);">
                              
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px;">
                              <span *ngIf="longitudeInput.touched && longitudeInput.errors">
                                 <label *ngIf="longitudeInput.errors.required">Please insert Longitude</label>
                                 <label *ngIf="longitudeInput.errors.pattern">Please insert a valid Longitude value between -180 and 180</label>
                              </span>
                           </div>
                        </div><!-- multi edit cs-block-ui -->
                        <!-- Else block -->
                        <ng-template #locationMultiValueMsg>
                           <span class="multiple-values-set">Multiple values set</span>
                        </ng-template>
                     </div>
                  </div>
               </div>

               <!-- Day combinations are only displayed when editing single callpoint settings -->
               <div *ngIf="canShowInputs(mySettingSection.DayCombination); else dayCombinationMultiValueMsg">
                  <!-- <div class="cs-setting-panel" *ngIf="!showOverrideButtons || (showOverrideButtons && !isDayCombinationsHidden)"> -->
                  <div class="cs-setting-panel">

                     <div *ngIf="!showOverrideButtons">
                        <div class="panel-body">
                           <h6>Day combinations</h6>
                           <p>Allowable day combinations for multiple visits per week.</p>

                           <callsmart-inputswitch name="allowAllDayCombination" #allowAllDayCombination="ngModel" [(ngModel)]="isDayCombinationsHidden" onLabel="Allow all" offLabel="Allow specific"
                              inverseColours="true">
                           </callsmart-inputswitch>
                           <hr *ngIf="!isDayCombinationsHidden">
                           <div style="display: inline-block;width:100%" *ngIf="!isDayCombinationsHidden">
                              <div *ngFor="let dayCombinationgroup of allPossibleDayCombinationsGrouped">
                                 <h6>{{dayCombinationgroup[0]?.visitsperWeek}} visits per week</h6>
                                 <ul class="dayCombination" style="margin-top: 20px; margin-bottom: 20px">
                                    <li *ngFor="let dayCombination of dayCombinationgroup" style="padding-right:50px">
                                       <div style="display: inline-flex;">
                                          <callsmart-day-combination name="callsmartDayCombination" [activeWorkingDays]="projectActiveWorkingDays"
                                                                     [dayCombination]="dayCombination"
                                                                     [isHidden]="showOverrideButtons"
                                             (selectedDayCombinationChanged)="OnSelectedDayCombinationChanged($event)"></callsmart-day-combination>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div *ngIf="showOverrideButtons && displayReadOnlyDayCombinationsBasedOnFrequency && !isDayCombinationsHidden">
                        <div class="panel-body">
                           <h6>Day combinations</h6>
                           <p>Allowable day combinations for multiple visits per week.</p>

                           <hr *ngIf="!isDayCombinationsHidden">
                           <div style="display: inline-block;width:100%" *ngIf="!isDayCombinationsHidden">
                              <div *ngFor="let dayCombinationgroup of allPossibleDayCombinationsGrouped">
                                 <div *ngIf="dayCombinationgroup[0]?.visitsperWeek * projectCycleLength === callpointSettings.frequency ">
                                    <h6>{{dayCombinationgroup[0]?.visitsperWeek}} visits per week</h6>
                                    <ul class="dayCombinationReadOnly" style="margin-top: 20px; margin-bottom: 20px">
                                       <li *ngFor="let dayCombination of dayCombinationgroup" style="padding-right:50px">
                                          <div style="display: inline-flex;">
                                             <callsmart-day-combination name="callsmartDayCombination" [activeWorkingDays]="projectActiveWorkingDays"
                                                                        [dayCombination]="dayCombination"
                                                                        [isHidden]="showOverrideButtons"
                                                (selectedDayCombinationChanged)="OnSelectedDayCombinationChanged($event)"></callsmart-day-combination>
                                          </div>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- Else block -->
               <ng-template #dayCombinationMultiValueMsg>
                  <div class="cs-setting-panel-no-bg" >
                     <div class="panel-body">
                        <h6>Day combinations</h6>
                        <p>Allowable day combinations for multiple visits per week.</p>
                        <span class="multiple-values-set">Multiple values set</span>
                     </div>
                  </div>
               </ng-template>

               <!-- Prioritisation -->
               <div *ngIf="!displayCallpointSpecificSettings" [ngClass]="{'cs-setting-panel': !editingMultipleCallpoints || callpointSettings.priorityMultiEdit,
                                 'cs-setting-panel-no-bg': editingMultipleCallpoints && !callpointSettings.priorityMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        Prioritisation
                     </h6>
                     <!-- This panel is greyed out based on the priorityMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple valuese set' message-->
                     <div>
                        <p>Higher priority visits are more likely to be scheduled, and scheduled earlier in the cycle than lower priority visits.</p>
                        <div class="col-xs-12" style="padding-bottom: 20px;">
                           <callsmart-inputswitch name="allowPrioritySwitch" onLabel="Off" offLabel="On"
                              [(ngModel)]="callpointSettings.priorityDisabled"
                              inverseColours="true"
                              (onChange)="onPriorityChanged($event)">
                           </callsmart-inputswitch>
                        </div>

                        <!-- This panel is greyed out based on the allowPrioritySwitch above -->
                        <div [ngClass]="{'cs-block-ui': callpointSettings.priorityDisabled}">
                           <p>Priority amplifier - the higher the value the stronger the effect of prioritisation.</p>
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                              <input name="prio" #priorityAmplifierInput="ngModel" required
                                       class="form-control cs-form-control" [(ngModel)]="callpointSettings.priorityAmplifier"
                                       disabled="{{callpointSettings.priorityDisabled}}"
                                       size="1" style="width: 50px;"
                                       type="number" (input)="onPriorityAmplifierInputValueChanged($event);"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="priorityAmplifierInput.touched && priorityAmplifierInput.errors">
                                 <label *ngIf="priorityAmplifierInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert priority amplifier</label>
                              </span>
                              <!-- If any of the previous errors are displayed then this error is not display -->
                              <label *ngIf="priorityAmplifierInput.touched && !isPriorityAmplifierValid && isPriorityAmplifierFormatValid"
                                    style="padding: 0px;margin-bottom: 0px">
                                    Please insert a value between 2 and 50
                              </label>
                           </div>

                           <p>Temporal prioritisation - the higher the value the more likely that prioritised visits will schedule earlier in the call cycle.</p>
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                              <input name="temporalPrioritisationInput" #temporalPrioritisationInput="ngModel" required
                                    class="form-control cs-form-control" [(ngModel)]="callpointSettings.temporalPrioritisation"
                                    size="1" disabled="{{callpointSettings.priorityDisabled}}"
                                    style="width: 70px;" type="number"
                                    (input)="onTemporalPrioritisationInputValueChanged($event);"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="temporalPrioritisationInput.touched && temporalPrioritisationInput.errors">
                                 <label *ngIf="temporalPrioritisationInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert temporal prioritisation</label>
                              </span>
                              <!-- If any of the previous errors are displayed then this error is not display -->
                              <label *ngIf="temporalPrioritisationInput.touched && !isTemporalPrioritisationValid && isTemporalPrioritisationFormatValid"
                                    style="padding: 0px;margin-bottom: 0px">
                                    Please insert a value between 0 and 1440
                              </label>
                           </div>
                        </div>

                     </div>
                     <!-- multi edit cs-block-ui -->
                  </div>
               </div>
            </perfect-scrollbar>
         </div>
      </div>
   </form>
</div>
