import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Http } from '@angular/http';
import { Subscription } from 'rxjs';

import { BrowserWindowService } from 'app/services/browser-window.service';
import { environment } from 'environments/environment';
import { ApplicationStore } from 'app/stores/application-store';
import { ErrorHandlerService } from 'app/services/error-handler.service';

@Component({
   selector: 'help-dialog',
   templateUrl: 'help-dialog.component.html'
})
export class HelpDialogComponent implements OnInit, OnDestroy {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // Help content link and files
   public gitBookHelp = 'https://help.callsmart.caci.co.uk/T4Mqv8mpch9kncmki7D8';
   public userGuideHelp = environment.clientBaseUrl + 'help/CallSmart User Guide.pdf';
   public gettingToGripsVideo = 'https://youtu.be/VawKyASpOhY';
   public dataTemplate = environment.clientBaseUrl + 'data-templates/DataTemplates.xlsx';

   public drivetimeVersion: string;
   public optimiserVersion: string;

   private _drivetimeVersion_subscription: Subscription;
   private _optimiserVersion_subscription: Subscription;
   private _gitVersion: string;

   constructor(private http: Http,
      private _applicationStore: ApplicationStore,
      private _errorHandler: ErrorHandlerService,
      private _windowService: BrowserWindowService) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   public ngOnInit(): void {
      this.subscribeToDrivetimeVersion();
      this.subscribeToOptimiserVersion();
      this._applicationStore.scheduleStore.getDrivetimeVersion();
      this._applicationStore.scheduleStore.getOptimiserVersion();
      this.readVersionFromFile();
   }

   
   public get appVersion(): string {
      return environment.appVersion + '.' + this._gitVersion;
   }
   
   public ngOnDestroy(): void {
      if (this._drivetimeVersion_subscription) {
         this._drivetimeVersion_subscription.unsubscribe();
      }
      
      if (this._optimiserVersion_subscription) {
         this._optimiserVersion_subscription.unsubscribe();
      }
   }
   
   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
   
   private readVersionFromFile() {
      let headers = new Headers();
      headers.append('Content-Type', 'application/text;');
      this.http.get('assets/git-version.txt')
         .subscribe(data => {
            this._gitVersion = data.text().substring(1, data.text().length);
         }, 
         error => {
            this._errorHandler.handleError(error);
         });
   }

   private subscribeToDrivetimeVersion() {
      this._drivetimeVersion_subscription = this._applicationStore.scheduleStore.drivetimeVersion$.subscribe(
         (version: string) => {
            this.drivetimeVersion = version;
         });
   }

   private subscribeToOptimiserVersion() {
      this._optimiserVersion_subscription = this._applicationStore.scheduleStore.optimiserVersion$.subscribe(
         (version: string) => {
            this.optimiserVersion = version;
         });
   }
}
