<div class="panel">
   <div class="panel-body">
      <div class="row">
         <div class="col-md-12">
            <edit-project-settings name="editProjectSettings"
               [(ngModel)]="projectSettings" 
               [scrollHeight]="scrollHeight" 
               [displayHeading]="false"
               [disableTravelModel]="true" 
               [displayCallCycleLength]="false" 
               [displayProjectSpecificProperties]="true"
               #editProjectSettings>
            </edit-project-settings>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <div class="btn-toolbar">
               <input type="submit" [disabled]="!formValid || canDeactivate()" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save"
                  (click)="submitForm()">
               <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel"
                  (click)="onCancel()">
            </div>
         </div>
      </div>
   </div>
</div>
