import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Day combinatione model object
 */

@JsonObject
export class DayCombination {

   @JsonProperty('combination')
   public combination: string= undefined;
   @JsonProperty('visitsperWeek')
   public visitsperWeek: number = undefined;
   @JsonProperty('visitsperWeekDescription')
   public visitsperWeekDescription: string = undefined;
   @JsonProperty('mon')
   public mon: boolean = false;
   @JsonProperty('tue')
   public tue: boolean = false;
   @JsonProperty('wed')
   public wed: boolean = false;
   @JsonProperty('thu')
   public thu: boolean = false;
   @JsonProperty('fri')
   public fri: boolean = false;
   @JsonProperty('sat')
   public sat: boolean = false;
   @JsonProperty('sun')
   public sun: boolean = false;
   
   //Not JSON mapped
   public isSelected: boolean = false;
   public isRecommended: boolean = false;
}
