import { throwError as observableThrowError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { environment } from 'environments/environment';
import { Caller } from 'app/models/caller';
import { ExportParameters } from 'app/models/export-parameters';
import { ImportData } from 'app/models/import-data';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// this service is used purely for data acess CRUD operations to the data base
// the service is normally called from with in a store

@Injectable()
export class CallerService {

   callersUrl = (projectId: number) => `${environment.baseUrl}api/projects/${projectId}/callers`;

   public constructor(private _http: HttpClient) { }

   public getCallers(projectId: number) {
      return this._http.get<Caller[]>(this.callersUrl(projectId))
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callerObjects: Caller[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  callerObjects = jsonConvert.deserialize(response, Caller);
               } catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callerObjects;
            }),
            catchError(error => observableThrowError(error)));
   }

   public deleteCallersByProjectId(projectId: number) {
      return this._http.delete(this.callersUrl(projectId))
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public exportAllCallersSchedules(projectId: number) {
      let url: string = `${this.callersUrl(projectId)}/exportschedules`;
      return this._http.get(url, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public exportCallerSchedule(projectId: number, callerId: number) {
      let url: string = `${this.callersUrl(projectId)}/${callerId}/exportschedule`;
      return this._http.get(url, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   // usedto export all and selection of caller data for all set export paremeters property of selected ids to null
   public exportCallerData(projectId: number, exportParamters: ExportParameters) {
      let url: string = `${this.callersUrl(projectId)}/exportdata`;
      return this._http.post(url, exportParamters, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateCallers(projectId: number, callers: Caller[]) {
      // json2typescript.JsonConvert is used to create an object that consists only of the Callpoint properties that
      // are attributed for serialisation using 'JsonProperty'.
      // MNF: However JsonConvert.serialize returns an Object of type 'any', not a Callpoint. So this code is misleading
      // as serializedCallpoints is not in fact a Callpoint[] but an Object[].
      let jsonConvert: JsonConvert = new JsonConvert();
      let serializedCallers: Caller[] = callers.map(cs =>
         jsonConvert.serialize(cs)
      );

      // Transform each of the callers and return a new array with the transformed data.
      // MNF: What this actually does is adjust all dates in the object graph. However doing this by calling
      // transformSettingsObject copies the entire object graph again, which seems redundant as a copy has already just
      // been made. If transformObjectDates were called directly that would have the same effect without creating a
      // redundant copy.
      let transformedData: Caller[] = serializedCallers.map(cs =>
         this.transformSettingsObject<Caller>(cs)
      );
      // console.log('Sending to back end:', transformedData);

      return this._http.put(this.callersUrl(projectId), transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getCallerMergeCounts(currentProjectId: number, tempProjectId: number) {
      let dto = new ImportData();
      dto.originalProjectId = currentProjectId;
      dto.tempProjectId = tempProjectId;

      // console.log('sending data', dto);

      let url: string = `${environment.baseUrl}api/callers/mergecounts`;
      return this._http.put(url, dto)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response === null) {
                  return observableThrowError('Returned data was null');
               }
               return response;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getInfillRoutesForCallers(projectId: number, callerIds: number[]) {
      let url: string = `${environment.baseUrl}api/callers/${projectId}/drivetimeinfillroutes`;

      return this._http.post(url, callerIds)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   private transformSettingsObject<T>(modelClass: T): T {
      // This is the most efficient way to deep clone an object in JavaScript
      // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      let clonedObject: T = JSON.parse(JSON.stringify(modelClass));

      CallsmartUtils.transformObjectDates(clonedObject);
      return clonedObject;
   }

   
}
