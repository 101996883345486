<p-dialog header="Travel Model" 
   [(visible)]="display" 
   modal="true"
   width="500" 
   positionTop="200" 
   [draggable]="false" 
   [resizable]="false"
   id="travelModelDialog"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal ed-form-horizontal">
      <p>This project does not have a travel model assigned, please select one from the list below 
      in order to continue using this project.</p>
      <div style="margin-top:5px;margin-bottom:5px;">
         Select a travel model
      </div>

      <div class="panel" style="border:none;">
         <div style="margin-top: 10px;">

            <div class="row">
               <div class="form-group">
                  <div class="col-sm-11">
                     <callsmart-dropdown name="travelModelFile"  [options]="travelModelList" [(ngModel)]="selectedTravelModel" [style]="{'width':'80%'}"
                          >
                     </callsmart-dropdown>
                  </div>
               </div>
            </div>
            
         </div>
      </div>

      <p-footer>
         <hr>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onSave(form)" [disabled]="selectedTravelModel == null">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>
</p-dialog>
