<div class="panel">
   <div class="panel-body">
      <div class="top-content-border bottom-content-border" style="margin: 0px 16px 0px 16px">
         <perfect-scrollbar [ngStyle]="{'height.px':workspaceHeight-20}">

            <div class="row">
               <div class="col-md-12 cs-setting-panel">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="panel-body" style="padding:0px;">
                           <callsmart-calendar class="black-calendar date-combo" #calendar [scheduleEvents]="projectEvents" 
                              [numberOfWeeks]="callCycleLength" 
                              [canSelectDayOfWeek]="false"
                              [startDate]="startCycleDate" 
                              (eventSelected)="onEventSelected($event)"
                              [datesClosed]="datesClosed"
                              [startWorkingTime]="startWorkingTime" 
                              [endWorkingTime]="endWorkingTime"
                              [activeWorkingDays]="activeWorkingDays"
                              >
                           </callsmart-calendar>
                        </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-md-12" style="padding: 0px">
                        <div class="panel-body">
                           <button type="button" class="btn cs-btn-secondary-inverse pull-right" style="margin-top:10px; display: flex;flex-direction: row;"
                              (click)="onAddEvent()">
                              <div class="black calendar add-icon"></div> Add Event</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </perfect-scrollbar>
      </div>
      <div class="row">
         <div class="col-12">
            <div class="btn-toolbar panel-body">
               <input type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!hasEventChanged" name="submit" value="Save" (click)="onSave()">
               <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel" (click)="onCancel()">
            </div>
         </div>
      </div>
   </div>
</div>


<ndc-dynamic *ngIf="showEventDialog" [ndcDynamicComponent]="projectCalendarDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
