import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from 'app/shared/date-converter/date-converter';

export class ReportParameters {

   @JsonProperty('startDate' , DateConverter, true)
   startDate: Date = undefined;

   @JsonProperty('endDate' , DateConverter, true)
   endDate: Date = undefined;

}
