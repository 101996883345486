import { forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Set up a new provider to tell angular that this component supports 
// ControlValueAccessor methods and forms
const DATE_RANGE_PICKER_VALUE_ACCESSOR = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => DateRangeSliderComponent),
   multi: true
};

// Declare jQuery reference variable here since Typescript doesn't know about the 
// JQuery object and will throw compiler errors.
declare var jQuery: any;
declare var $: any;

/**
 * Angular wrapper for JQuery jQEditRangeSlider plugin. This slider is actually for displaying
 * and editing number values via the input fields in the labels. The original source for this
 * control has been modified to work with time format by converting the date to milliseconds
 * and passing that in to the control. The control supports basic input validation where if the 
 * input does not match HH:MM format then it simply redisplay's the previous correct value.
 * 
 * Supports two way data binding and forms.
 */
@Component({
   selector: 'callsmart-date-range-slider',
   template: `<div #csSlider></div>`,
   providers: [DATE_RANGE_PICKER_VALUE_ACCESSOR]
})
export class DateRangeSliderComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {

   private onTouched = () => { };
   private onChange: (value: any[]) => {};

   // Default values for inputs
   @Input() stepInMinutes: number = 60000;
   @Input() boundMinDate: Date = new Date('2011-04-20T05:00:00.00');
   @Input() boundMaxDate: Date = new Date('2011-04-20T23:00:00.00');
   @Input() disabled: boolean;

   @Output() userValueChanged: EventEmitter<Date[]> = new EventEmitter();

   // Reference to the DOM node that contains the jquery range slider.
   // The ViewChild attribute maps the '#csSlider' element from the template to this property.
   // The ElementRef type is a DOM node wrapper from Angular.
   @ViewChild('csSlider') rangeSlider: ElementRef;

   // Angular lifecycle method called after the DOM has been initialised.
   // Creates the range slider using JQuery and intialises it with constructor
   // arguments.
   ngAfterViewInit() {
      jQuery(this.rangeSlider.nativeElement).editRangeSlider({
         bounds: {
            min: this.boundMinDate.valueOf(), // valueOf returns milliseconds
            max: this.boundMaxDate.valueOf()
         },
         step: this.stepInMinutes * 60000, // Since we are working in milliseconds, 1 minute steps equate to 60000
         range: {
            min: this.stepInMinutes * 60000
         },
         outerLabelLeft: 'From',
         outerLabelRight: 'To',
         enabled: !this.disabled,
         arrows: false,
         formatter: function (val) {
            var formattedDate = new Date(val);
            // return only the hours:minutes of the time value
            return formattedDate.toTimeString().substring(0, 5);
         },
         myValueChanged: (data) => {
            let fromDate: Date = new Date(data.min);
            let toDate: Date = new Date(data.max);
            let dateData: Date[] = [fromDate, toDate];
            this.onChange(dateData);
            this.onTouched();
            this.userValueChanged.emit(dateData);
         }
      });
   }

   // Callback method to sync model values with the JQuery range slider.
   writeValue(dates: Date[]): void {

      if (dates) {
         let minDate = new Date(dates[0]);
         let maxDate = new Date(dates[1]);
         jQuery(this.rangeSlider.nativeElement).editRangeSlider('values', minDate.valueOf(), maxDate.valueOf());
      }
   }

   // Callback method to tell Angular that component value has changed.
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   // Callback method to sync the component enable/disable state.
   setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;

      if (this.disabled) {
         jQuery(this.rangeSlider.nativeElement).editRangeSlider('disable');
      }
      else {
         jQuery(this.rangeSlider.nativeElement).editRangeSlider('enable');
      }

   }

   // Clean up JQuery resources when this component is removed from DOM.
   ngOnDestroy(): void {
      jQuery(this.rangeSlider.nativeElement).editRangeSlider('destroy');
   }
}
