<p-dialog header="Having trouble logging in?" 
   [(visible)]="display" 
   modal="true" 
   width="500" 
   positionTop="50" 
   [draggable]="false"
   [resizable]="false" 
   (onHide)="onCancel()">

   <div class="password-reset-container">
      <form #form="ngForm" class="form-horizontal" autocomplete="off">
         <p>Double check that the email address you are using is the one your organisation used to create your profile. Ask
            your company CallSmart administrator if you have any doubts around your email address.
         </p>
         <p>If you need to reset your password you can do so by following the instructions below.</p>
         <div class="cs-setting-panel">
            <div class="panel-body">
               <h6>Reset password</h6>
               <p>Please provide the email address you use to log in to CallSmart. We will send you an email with a new password.
                  Feel free to change your password via your profile once you have logged in.
               </p>

               <div class="form-group row">
                  <label class="control-label">Email address</label>
               </div>

               <div class="form-group row">
                  <input type="email" id="email" name="email" required ngModel class="form-control cs-form-control">
               </div>
               <div>
                  <div class="alert alert-danger fade in" *ngIf="showError">
                     Invalid email address format. Please provide a valid email address format.
                  </div>
                  <div class="alert alert-success fade in" *ngIf="showSuccess">
                     Email sent. If you do not receive the email within 30 minutes please contact your
                     CallSmart administrator.   
                  </div>
               </div>
            </div>
         </div>
         <p-footer>
            <div style="padding-top: 10px;">
               <button type="button" *ngIf="!disableButton" class="btn cs-btn-primary-inverse pull-right" [disabled]="!form.valid" (click)="onResetPassword(form)">Send new password</button>
               <button type="button" *ngIf="disableButton" class="btn cs-btn-primary-inverse pull-right" (click)="onCancel()">Return to Log in</button>
            </div>
         </p-footer>

      </form>
   </div>
</p-dialog>