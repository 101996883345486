import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Project } from 'app/models/project';

@Component({
  selector: 'callsmart-clone-project-dialog',
  templateUrl: './clone-project-dialog.component.html'
})
export class CloneProjectDialogComponent implements OnInit {

   public cloneProjectName:string;

   // Project model used by this dialog.
   @Input() project: Project;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() clone = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   public constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   public ngOnInit() {
      this.cloneProjectName = this.project.name + ' - clone'
   }

   public onClone(form) {
      let newProjectName = form.value.name;
      this._applicationStore.sysAdminStore.cloneProject(
          this.project.projectId, 
          newProjectName,
          this._applicationStore.authenticationStore.loggedInUser.userId,
          this._applicationStore.authenticationStore.userCompany);
      this.clone.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}

