import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTable } from 'primeng/components/datatable/datatable';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Company } from 'app/models/company';
import { CompanyListComponent } from 'app/admin-workspace/company-list/company-list.component';
import { DeleteCompanyDialogComponent } from 'app/admin-workspace/delete-company--dialog/delete-company--dialog.component';
import { AddEditCompanyDialogComponent } from 'app/admin-workspace/add-edit-company-dialog/add-edit-company-dialog.component';
import { Alert } from 'app/models/alert';
import { DialogMode } from 'app/models/diary/event-dialog-modes';

/**
 * Panel component for hosting the company list and buttons.
 */
@Component({
   selector: 'callsmart-admin-company-panel',
   templateUrl: './admin-company-panel.component.html'
})
export class AdminCompanyPanelComponent implements OnInit {

   public scrollHeight: string = "200px";
   public workspaceHeight: number;

   @ViewChild(CompanyListComponent)
   public companyList: CompanyListComponent;

   ///--------------------delete dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showDeleteCompanyDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public deleteCompanyDialog = DeleteCompanyDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      company: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      delete: () => this.onDeleteEvent(),
      cancel: () => this.onCancelEvent()
   };

   ///--------------------add edit dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showAddEditCompanyDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public addEditCompanyDialog = AddEditCompanyDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public addEditDialogInput = {
      display: false,
      company: null,
      headerText: null,
      dialogMode: DialogMode.add
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public addEditDialogOutput = {
      save: () => this.onSaveAddEditEvent(),
      cancel: () => this.onCancelAddEditEvent()
   };

   constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService, private router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
   }

   public ngOnInit() {
   }

   public onDeleteCompany() {
      // add a check only can delete a company when all the users have been deleted
      let companyUsers = this._applicationStore.sysAdminStore.users.filter(u => u.companyId == this.companyList.selectedCompanies.companyId);
      if (companyUsers.length > 0) {
         this._applicationStore.alertStore.sendAlert(new Alert('Warning', "All users for a company must be deleted, before a company can be deleted."));

      } else {
         this.showDeleteCompanyDialog = true;
         this.dialogInput.display = true;
         this.dialogInput.company = this.companyList.selectedCompanies;
      }
   }

   // cancel button from the delete project  dialog
   public onCancelEvent() {
      this.showDeleteCompanyDialog = false;
      this.dialogInput.display = false;
   }

   // delete button from the delete project  dialog
   public onDeleteEvent() {
      this.showDeleteCompanyDialog = false;
      this.dialogInput.display = false;
   }

   // save button from the delete project  dialog
   public onSaveAddEditEvent() {
      this.showAddEditCompanyDialog = false;
      this.dialogInput.display = false;
   }

   // cancel button from the addedit project  dialog
   public onCancelAddEditEvent() {
      this.showAddEditCompanyDialog = false;
      this.dialogInput.display = false;
   }

   public onEditCompany() {
      this.showAddEditCompanyDialog = true;
      this.addEditDialogInput.display = true;
      this.addEditDialogInput.company = this.companyList.selectedCompanies;
      this.addEditDialogInput.headerText = "Edit Company";
      this.addEditDialogInput.dialogMode = DialogMode.edit;
   }

   public onCreateCompany() {
      var newCompany = new Company();
      newCompany.travelModels = [];

      this.showAddEditCompanyDialog = true;
      this.addEditDialogInput.display = true;
      this.addEditDialogInput.company = newCompany;
      this.addEditDialogInput.headerText = "Add Company";
      this.addEditDialogInput.dialogMode = DialogMode.add;
   }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 220;
      this.scrollHeight = this.workspaceHeight * 0.80 + 'px';
   }
}
