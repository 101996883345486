import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { JsonConvert } from 'json2typescript';

import { Project } from 'app/models/project';
import { ApplicationStore } from 'app/stores/application-store';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { EditProjectSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-settings-dialog/edit-project-settings-dialog.component';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

/**
 * Project setting component for the settings shown in the Create New wizard.
 * Also allows the settings to be edited.
 */
@Component({
   selector: 'callsmart-new-project-settings-setting',
   templateUrl: 'new-project-settings-setting.component.html'
})
export class NewProjectSettingsSettingComponent implements OnInit, OnDestroy {

   // Height of the component used by the scrollbar.
   @Input() componentHeight: number;

   // Notifies the client when the project settings have been updated.
   @Output() projectSettingsUpdated = new EventEmitter<ProjectSettings>();

   // Project settings model object.
   public projectSettings: ProjectSettings

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editProjectSettingsDialog = EditProjectSettingsDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      projectSettingsModel: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (settings: ProjectSettings) => this.onSaveEdit(settings),
      cancel: () => this.onCancelEdit()
   };

   // Determine whether to display the edit dialog.
   public showSettings: boolean = false;

   // Subscribe to the project created event.
   private _project_created_subscription: Subscription;

   // The new project that needs to be updated with these settings.
   private _project: Project;

   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      this.subscribeToProjectCreated();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }
   }

   // Clone the project settings for the dialog and show it.
   public editSettings() {
      let projectSetting = CallsmartUtils.deepClone<ProjectSettings>(this.projectSettings, ProjectSettings);

      projectSetting.utilisationDefinition = [];
      projectSetting.utilisationDefinition[0] = projectSetting.underUtilisation;
      projectSetting.utilisationDefinition[1] = projectSetting.overUtilisation;

      this.dialogInput.projectSettingsModel = projectSetting;
      this.showSettings = true;
      this.dialogInput.display = true;
   }

   // Set the caller setting dialog to be hidden and assign the changed project settings
   // to the project settings model. When the user clicks on the Next button, the Project 
   // model class with the project settings will be saved to the database.
   public onSaveEdit(savedSettings: ProjectSettings): void {
      this.showSettings = false;
      this.dialogInput.display = false;
      this.projectSettings = savedSettings;
      this._project.projectSettings = savedSettings;
      this._applicationStore.projectsStore.updateCreateNewProjectSettingSettings(this._project.projectId, 
         savedSettings);
      
      // Update the main page with the new settings. This is call cycle length at the moment.      
      this.projectSettingsUpdated.next(savedSettings);
   }

   // Set the caller setting dialog to be hidden.
   public onCancelEdit(): void {
      this.showSettings = false;
      this.dialogInput.display = false;
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this._project = project;
               this.projectSettings = project.projectSettings;
            }
         }
      );
   }
}
