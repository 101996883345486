import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';

import { TravelModel } from 'app/models/travelModel';

/**
 * Component for the travel model deletion
 */

@Component({
  selector: 'callsmart-delete-travel-model-dialog',
  templateUrl: './delete-travel-model-dialog.component.html'
})
export class DeleteTravelModelDialogComponent implements OnInit {

   travelModels: TravelModel[] = [];

   //  model used by this dialog.
   @Input() travelModel: TravelModel;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() delete = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngOnInit() {
      this.travelModels.push(this.travelModel);
   }

   public onDelete() {
      // do the delete
      this._applicationStore.sysAdminStore.deleteTravelModel(this.travelModel.travelModelId);
      this.display = false;
      this.delete.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}

