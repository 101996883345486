import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { Caller } from 'app/models/caller';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { ApplicationStore } from 'app/stores/application-store';
import { EditCallerSettingsDialogComponent } from 'app/shared/edit-caller-settings-dialog/edit-caller-settings-dialog.component';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ExportParameters } from 'app/models/export-parameters';
import { EditCalendarDialogComponent } from 'app/shared/edit-calendar-dialog/edit-calendar-dialog.component';
import * as moment from 'moment/moment';
import { EditCallpointsDialogComponent } from '../edit-callpoints-dialog/edit-callpoints-dialog.component';
import { ScheduleMetrics } from 'app/models/scheduleMetrics';

/**
 * Caller settings panel component.
 */
@Component({
   selector: 'callsmart-caller-settings-summary',
   templateUrl: './caller-settings-summary.component.html',
})
export class CallerSettingsSummaryComponent implements OnInit, OnDestroy {
   // set which export options can be used
   @Input() showScheduleExport: boolean = true;
   @Input() showCallerExport: boolean = true;
   @Input() showCallpointExport: boolean = true;
   @Input() disableCallpointExport: boolean = true;

   // Determines the state of the optimise button.
   public optimiseButtonDisabled: boolean = false;

   // Determine whether to display the edit caller dialog.
   public showCallerSettings: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallerSettingsDialog = EditCallerSettingsDialogComponent;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCalendarDialog = EditCalendarDialogComponent;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallpontsDialog = EditCallpointsDialogComponent;

   // Determine whether to display the edit calendar dialog.
   public showEditCalendar: boolean = false;

   public showEditCallpoints: boolean = false;

   public scheduledVisitsPc: number = 0;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callerName: null,
      callerSettingsModel: null,
      projectCycleLength: null,
   };

   public editCalendarDialogInput = {
      display: false,
      datesClosed: null,
      scheduleStartDate: null,
      description: null,
   };

   public editCallpointsDialogInput = {
      display: false,
      callerIds: [],
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (saveEvent) => this.onSaveEditCaller(saveEvent.caller, saveEvent.daysChanged),
      cancel: () => this.onCancelEditCaller(),
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public editCalendarDialogOutput = {
      saved: (closedDates: string[]) => this.onSaveEditCalendar(closedDates),
      cancel: () => this.onCancelEditCalendar(),
   };

   public editCallpointsDialogOutput = {
      saved: () => this.onSaveEditCallpoints(),
      cancel: () => this.onCancelEditCallpoints(),
   };

   // Caller settings used by this component.
   public callerSettingsViewModel: CallerSettingsViewModel;

   private _selectedCallerMetrics: Subscription;
   private _callersOptimisingSubscription: Subscription;
   private _selectedCallerSubscription: Subscription;
   private _cacheCallerIds: number[];
   private _selectedCaller: Caller;

   constructor(private _applicationStore: ApplicationStore) {}

   public ngOnInit(): void {
      this.subscribeToCallerMetrics();
      this.subscribetToCallersOptimising();
      this.subscribeToSelectedCaller();
   }

   public ngOnDestroy(): void {
      if (this._callersOptimisingSubscription) {
         this._callersOptimisingSubscription.unsubscribe();
      }

      if (this._selectedCallerSubscription) {
         this._selectedCallerSubscription.unsubscribe();
      }

      if (this._selectedCallerMetrics) {
         this._selectedCallerMetrics.unsubscribe();
      }
   }

   get caller(): Caller {
      return this._applicationStore.callersStore.selectedCaller;
   }

   get scheduleMetrics(): ScheduleMetrics {
      return this._applicationStore.callersStore.selectedCallerMetrics;
   }

   public onFastOptimise(): void {
      // proactively diable the optimise buttons
      this.optimiseButtonDisabled = true;
      this._applicationStore.scheduleStore.generateSchedule(this._applicationStore.callersStore.selectedCaller.callerId, 'Quick');
   }

   public onFullOptimise(): void {
      this.optimiseButtonDisabled = true;
      this._applicationStore.scheduleStore.generateSchedule(this._applicationStore.callersStore.selectedCaller.callerId, 'Full');
   }

   // clear all vists except the locked visits
   public clearScheduleUnlocked(): void {
      this._applicationStore.scheduleStore.clearScheduleUnlocked(this._applicationStore.callersStore.selectedCaller.callerId);
   }

   //clear all the visists
   public clearSchedule(): void {
      this._applicationStore.scheduleStore.clearSchedule(this._applicationStore.callersStore.selectedCaller.callerId);
   }

   public exportSchedule(): void {
      this._applicationStore.callersStore.exportCallerSchedule(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this._applicationStore.callersStore.selectedCaller.callerId,
         this._applicationStore.callersStore.selectedCaller.name
      );
   }

   public exportCallerData(): void {
      let callerIds = [];
      callerIds.push(this._applicationStore.callersStore.selectedCaller.callerId);
      let exportParameters = new ExportParameters(
         Array.from(this._applicationStore.callersStore.callerExportColumns),
         callerIds
      );
      this._applicationStore.callersStore.exportSelectedCallersData(
         this._applicationStore.projectsStore.selectedProject.projectId,
         exportParameters
      );
   }

   public exportCallpointData(): void {
      let territory = this._applicationStore.callersStore.selectedCaller.territory;
      let callpointIds = this._applicationStore.callpointsStore.selectedCallpoints.map(
         ({ callpointId }) => callpointId
      );
      let exportParameters = new ExportParameters(
         Array.from(this._applicationStore.callpointsStore.callpointExportColumns),
         callpointIds
      );
      this._applicationStore.callpointsStore.exportSelectedCallpointsData(
         this._applicationStore.projectsStore.selectedProject.projectId,
         exportParameters,
         territory
      );
   }

   // Set the caller setting dialog to be visible.
   public editCaller(): void {
      this.dialogInput.callerSettingsModel = this.callerSettingsViewModel;
      this.dialogInput.projectCycleLength = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;
      this.showCallerSettings = true;
      this.dialogInput.callerName = this.caller.name;
      this.dialogInput.display = true;
   }

   public editCalendar() {
      this.editCalendarDialogInput.datesClosed = this.caller.datesClosed;
      this.editCalendarDialogInput.scheduleStartDate = this._applicationStore.projectsStore.selectedProject.scheduleStartDate;
      this.editCalendarDialogInput.description = this.caller.name;

      this.showEditCalendar = true;
      this.editCalendarDialogInput.display = true;
   }

   public editCallpointLocking(): void {
      // weird bug if the contextual panel is scrolling due to lots of accordians open.
      // mouse wheel does not work in grid, it scrolls the contextal panel behind modal
      // hack collapse all bat caller accordion
      this._applicationStore.contextualPanelStore.setSelectedTabs([0]);

      this.editCallpointsDialogInput.callerIds = [
         this._selectedCaller.callerId,
      ];
      this.showEditCallpoints = true;
      this.editCallpointsDialogInput.display = true;
   }

   // Set the caller setting dialog to be hidden.
   public onSaveEditCaller(caller: Caller, daysChanged: boolean[]): void {
      this.showCallerSettings = false;
      this.dialogInput.display = false;

      this.calculateClosedDates(caller, daysChanged);

      this._applicationStore.callersStore.updateCallers(
         this._applicationStore.projectsStore.selectedProject.projectId,
         [caller]
      );

      this._applicationStore.callersStore.selectedCaller.latitude = caller.latitude;
      this._applicationStore.callersStore.selectedCaller.longitude = caller.longitude;
      this._applicationStore.callersStore.selectedCaller.callerSettings = this.callerSettingsViewModel.getCallerSettings();

      if(this.callerSettingsViewModel.sameWorkingHoursAllDays){
         this._applicationStore.callersStore.selectedCaller.callerSettings.workingDayActive = this.callerSettingsViewModel.workingDayActive ;
      }
   }

   // Updates the new selected closed days.
   public onSaveEditCalendar(closedDates: string[]): void {
      this.showEditCalendar = false;
      this.editCalendarDialogInput.display = false;
      this.callerSettingsViewModel.getCaller().datesClosed = closedDates;
      this.calculateWorkingDays(closedDates);
      // Updating the calendar closed days to the Server (this method also updates the caller settings for the caller)
      this._applicationStore.callersStore.updateClosedDates(this.caller, closedDates);
   }

   public onSaveEditCallpoints(): void {
      this.showEditCallpoints = false;
      this.editCallpointsDialogInput.display = false;

      // clear potentially a large amount of data in the browsers memory
      this._applicationStore.callpointsStore.clearCallpointsForMultipleCallers();

      this.refeshScheduleAndVisits();
   }

   // Set the caller setting dialog to be hidden and revert any changes made by the user.
   public onCancelEditCaller(): void {
      this.showCallerSettings = false;
      this.dialogInput.display = false;
      this.initialiseCallerSettings(this._selectedCaller);
   }

   public onCancelEditCalendar(): void {
      this.showEditCalendar = false;
      this.editCalendarDialogInput.display = false;
   }

   public onCancelEditCallpoints(): void {
      this.showEditCallpoints = false;
      this.editCallpointsDialogInput.display = false;
      // clear potentially a large amount of data in the browsers memory
      this._applicationStore.callpointsStore.clearCallpointsForMultipleCallers();
   }

   public isOutOfPhaseVisitDaysOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings()
                  .outOfPhaseVisitDays !== undefined
            );
         }
      }
      return false;
   }

   public isContractedWorkingHoursOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings()
                  .contractedWorkingHoursWeek !== undefined
            );
         }
      }
      return false;
   }

   public isLunchPeriodOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings().lunchPeriod !==  undefined
            );
         }
      }
      return false;
   }

   public isLunchDurationOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings().lunchDuration !== undefined
            );
         }
      }
      return false;
   }

   public isMaxOneWayCommuteTimeOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings().maxOneWayCommuteTime !== undefined
            );
         }
      }
      return false;
   }

   public isMaxVisitsPerDayOverriden() {
      if (this.callerSettingsViewModel) {
         if (this.callerSettingsViewModel.getCallerSettings() !== null) {
            return (
               this.callerSettingsViewModel.getCallerSettings().maxVisitsPerDay !== undefined
            );
         }
      }
      return false;
   }

   // Angular 4.x and IE 11 do not show the date in the correct format when using the DatePipe from Angular.
   // This has been fixed in Angular 5 but for now using moment library to do the formatting in code to get the
   // desired result.
   public getHoursAndMinutes(date: Date): string {
      return CallsmartUtils.formatDate(date, 'HH:mm');
   }

   private handleError(error) {
      // console.log(error);
   }

   private refeshScheduleAndVisits() {
      // reload the current callers visits and schedule as locking status  might have changed
      this._applicationStore.visitsStore.loadVisitsForCaller(
         this._applicationStore.projectsStore.selectedProject.projectId,
         this._applicationStore.callersStore.selectedCaller.callerId
      );

      this._applicationStore.scheduleStore.loadDiaryEvents(
         this._applicationStore.callersStore.selectedCaller.callerId,
         this._applicationStore.projectsStore.selectedProject.projectId,
         false
      );
   }

   private subscribetToCallersOptimising() {
      this._callersOptimisingSubscription = this._applicationStore.scheduleStore.callersOptimising$.subscribe(
         (callerIds: number[]) => {
            this._cacheCallerIds = callerIds;
            this.checkIfCallerOptimising();
         }
      );
   }

   private checkIfCallerOptimising() {
      // super quick checl if array empty nothing optimising
      if (this._cacheCallerIds.length == 0) {
         this.optimiseButtonDisabled = false;
         return;
      }

      // if caller id in the array then disable the buttons
      if (this._cacheCallerIds.find((c) => c === this.caller.callerId)) {
         this.optimiseButtonDisabled = true;
      } else {
         this.optimiseButtonDisabled = false;
      }
   }

   private subscribeToSelectedCaller() {
      this._selectedCallerSubscription = this._applicationStore.callersStore.selectedCaller$.subscribe(
         (caller: Caller) => {
            this._selectedCaller = caller;
            this._cacheCallerIds = this._applicationStore.scheduleStore.callersOptimising;
            this.checkIfCallerOptimising();
            this.initialiseCallerSettings(this._selectedCaller);
         }
      );
   }

   private initialiseCallerSettings(caller: Caller) {
      if (caller !== null) {
         let model: CallerSettingsViewModel = new CallerSettingsViewModel(
            this._applicationStore.projectsStore.selectedProject.callerSettings,
            caller.callerSettings,
            caller
         );

         this.callerSettingsViewModel = model;
      } else {
         this.callerSettingsViewModel = null;
      }
   }

   private calculateClosedDates(caller: Caller, daysChanged: boolean[]): void {
      let workingDays: boolean[];

      if (caller.callerSettings && caller.callerSettings.workingDayActive) {
         workingDays = caller.callerSettings.workingDayActive;
      } 
      else {
         workingDays = this._applicationStore.projectsStore.selectedProject.callerSettings.workingDayActive;
      }

      let numOfWeeks = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;

      for (let weekIndex = 0; weekIndex < numOfWeeks; weekIndex++) {
         for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            if (daysChanged[dayIndex]) {
               let dateIndex = dayIndex + 7 * weekIndex;
               caller.datesClosed[dateIndex] = workingDays[dayIndex]
                  ? 'o'
                  : 'x';
            }
         }
      }

      this.caller.datesClosed = caller.datesClosed;
   }

   // Works out and modifies the working active days for the selected caller
   // based on the selected closed dates.
   private calculateWorkingDays(closedDates: string[]): void {
      let numOfWeeks = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;
      let workingDayActive: boolean[] = [
         false,
         false,
         false,
         false,
         false,
         false,
         false,
      ];

      if (this.caller.callerSettings && this.caller.callerSettings.workingDayActive) {
         workingDayActive = this.caller.callerSettings.workingDayActive;
      } 
      else {
         workingDayActive = this._applicationStore.projectsStore.selectedProject.callerSettings.workingDayActive.slice();
      }

      // Checks every day in a week (from Monday to Sunday)
      for (let index: number = 0; index < 7; index++) {
         let allDaysOfWeekSameValue: boolean = true;
         let dayOfWeekValue: string;

         // Checks the selected day for every week in the call cycle
         for (let weekIndex: number = 0; weekIndex < numOfWeeks; weekIndex++) {
            if (weekIndex === 0) {
               // Gets the closedDates value of the first week (for this specific day)
               dayOfWeekValue = closedDates[7 * weekIndex + index];
            } 
            else {
               // Compares the closedDated values for the rest of the weeks
               if (closedDates[7 * weekIndex + index] != dayOfWeekValue) {
                  // Both values are different so we break this loop and go on
                  // with the next day of the week
                  allDaysOfWeekSameValue = false;
                  break;
               }
            }
         }
         // All selected day of the week values for all call cycle  have the same value.
         // We update the workingDayActive array.
         // If not all days have the same value then we leave the false value set when creating the array.
         if (allDaysOfWeekSameValue) {
            let cycleStartDate: moment.Moment = moment(
               this._applicationStore.projectsStore.selectedProject.scheduleStartDate
            );
            let dayOfWeek = cycleStartDate.clone().add(index, 'd').day();
            let workingDayActiveIndex: number;

            // dayOfWeek starts withe 0=Sunday /  Working day active indexes Monday(0), Tuesday(1), ... , Sunday(6)
            if (dayOfWeek === 0) {
               workingDayActiveIndex = 6;
            } 
            else {
               workingDayActiveIndex = dayOfWeek - 1;
            }
            workingDayActive[workingDayActiveIndex] =
               dayOfWeekValue.toLocaleLowerCase() == 'o' ||
               dayOfWeekValue.toLocaleLowerCase() == '1' ||
               dayOfWeekValue.toLocaleLowerCase() == '2'
                  ? true
                  : false;
         }
      }

      // CallerSettings property might be null since this caller inherits the all caller settings from the current project.
      // For that reason we have to create a new CallerSettings object and set the workingDayActive property with the new value
      if (!this.caller.callerSettings) {
         this.caller.callerSettings = new CallerSettings();
      }
      this.caller.callerSettings.workingDayActive = workingDayActive;
   }

   // schedule metrics are updated but not callers data, hence setting this data here
   private subscribeToCallerMetrics() {
      this._selectedCallerMetrics = this._applicationStore.callersStore.selectedCallerMetrics$.subscribe(
         (scheduleMetrics: any) => {
            this.updateMetrics(scheduleMetrics);
         }
      );
   }

   private updateMetrics(metrics: any){

      if (metrics != null) {
         this.scheduleMetrics.avgCallsPerDay = metrics.AvgCallsPerDay != null ? metrics.AvgCallsPerDay : metrics.avgCallsPerDay;
         this.scheduleMetrics.utilisationPc = metrics.UtilisationPc != null ? metrics.UtilisationPc : metrics.utilisationPc;

         let numDeferrals = metrics.NumDeferrals != null ? metrics.NumDeferrals : metrics.numDeferrals;
         let numDisabledVisits = metrics.NumDisabledVisits != null ? metrics.NumDisabledVisits : metrics.numDisabledVisits;
         let numVisitsToMake = metrics.NumVisitsToMake != null ? metrics.NumVisitsToMake : metrics.numVisitsToMake;

         // copied the below logic from caller-store.ts/refreshCallersCalculations
         let numDeferredVisits = Math.max(0, numDeferrals - numDisabledVisits);

         let pc = numVisitsToMake === 0 ? 0 :
            (numVisitsToMake - numDisabledVisits - numDeferredVisits) / (numVisitsToMake - numDisabledVisits) * 100;

         let numCallpoints = metrics.NumCallpoints != null ? metrics.NumCallpoints : metrics.numCallpoints;
         let numDisabledCallpoints = metrics.NumDisabledCallpoints != null ? metrics.NumDisabledCallpoints : metrics.numDisabledCallpoints;

         // If all callpoints are disabled then pc must be 0;
         if (numCallpoints === numDisabledCallpoints) {
            pc = 0;
         }

         // Round to 1 decimal place.
         this.scheduledVisitsPc = Math.round(pc * 10) / 10;
      }
   }
}
