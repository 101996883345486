<!-- <div style="background-color: #575757"> -->
<div class="black-list">
   <label class="cs-checkbox-datatable" style="margin-right: 16px; margin-left: 16px; margin-top: 8px" 
          [ngStyle]="{'display': isHidden ? 'none': 'initial'}">
      <input type="checkbox" [id]="dayCombination.combination" (change)="toggleCheckbox($event)" [(ngModel)]="dayCombination.isSelected">
      <span></span>
   </label>
   <div *ngFor="let dayToDisplay of daysToDisplay;" class="dayCombinationBox" [ngClass]="{'selectedEnabledDayCombination': dayToDisplay.isEnabled && dayCombination.isSelected, 
                    'selectedDisabledDayCombination': !dayToDisplay.isEnabled && dayCombination.isSelected, 
                    'deselectedEnabledDayCombination': dayToDisplay.isEnabled && !dayCombination.isSelected, 
                    'deselectedDisabledDayCombination': !dayToDisplay.isEnabled && !dayCombination.isSelected}">
      {{dayToDisplay.name}}
   </div>
   <label *ngIf="dayCombination.isRecommended" style="margin-left: 10px; font-weight:400">
      <span [style.color]="dayCombination.isSelected ? 'white' : '#5c5c5c'">(Recommended)</span></label>
</div>
