import {
   Component,
   OnInit,
   EventEmitter,
   Output,
   Input,
   ViewChild,
   AfterViewInit,
   DoCheck
} from '@angular/core';
import { DataTable } from 'primeng/components/datatable/datatable';
import { SelectItem, LazyLoadEvent } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { Caller } from 'app/models/caller';
import { ApplicationStore } from 'app/stores/application-store';
import { OptimisationStatus } from 'app/models/optimisationStatus';
import {
   compareValues,
   filter,
   getComboDataForSpecifiedProperty
} from 'app/shared/utils/callsmart-grid-util';

// Excellent resource on type script MAP (dictionaries)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map/delete

@Component({
   selector: 'callsmart-callers-list',
   templateUrl: './callers-list.component.html'
})
export class CallersListComponent implements OnInit, AfterViewInit, DoCheck {
   private _sortedAndFilteredData: Caller[];
   private _currentSortField: string;
   private _currentSortOrder: string;
   private _filterNum: number;
   public allCallers: Caller[] = [];

   @ViewChild(DataTable)
   private callerList: DataTable;

   @Output() rowUnSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() allRowsSelected: EventEmitter<any> = new EventEmitter<any>();
   @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();
   @Output() headerHeight: number;
   @Output() tableHeaderHeight: number;
   @Output() tableHeight: number;

   private _scrollHeight = '200px';
   @Input()
   public get scrollHeight(): string {
      return this._scrollHeight;
   }
   public set scrollHeight(scrollHeight: string) {
      this._scrollHeight = scrollHeight;
      let scrollHeightStringValue: string = this._scrollHeight.substr(
         0,
         this._scrollHeight.length - 2
      );

      let scrollHeightValue: number;
      if (
         Number(scrollHeightStringValue) >= 400 &&
         Number(scrollHeightStringValue) <= 600
      ) {
         scrollHeightValue = Number(scrollHeightStringValue);
      } else if (Number(scrollHeightStringValue) < 400) {
         scrollHeightValue = 400;
      } else {
         scrollHeightValue = 600;
      }
      this.filterColumnPanelHeight = Number(scrollHeightValue) - 72 + 'px'; // 116px is the height of the header of the filter column panel;
   }

   @Input() scrollWidth: string = '200px';

   public filterColumnPanelHeight: string;

   //Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];

   public hasFilters: boolean;
   public callers: ReadonlyArray<Caller>;

   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedCallers: Caller[] =
      this._applicationStore.callersStore.selectedCaller != null
         ? [this._applicationStore.callersStore.selectedCaller]
         : [];

   public selectedItemsLabel: string = 'Columns'; // shows the count '{0} columns displayed';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns'; //'0 columns displayed'
   public panelTitle: string = 'Columns';

   public gridTitle: string = 'Callers';

   public isDistanceUnitMiles: boolean = false;

   public loading: boolean;
   public rows = 30;
   public totalRecords: number;

   private _cols: any;
   get cols(): any[] {
      return this._cols;
   }
   set cols(selectedCols: any[]) {
      this._cols = selectedCols;
      this.buildExportColumnsDictionary();
   }

   // Caller subscription.
   private _callers_subscription: Subscription;

   // Caller optimisation subscription.
   private _callersOptimisingSubscription: Subscription;

   // Caller optimisation status subscription.
   private _callersOptimisingStatusSubscription: Subscription;

   private distanceHeader: string = '';
   private callersOptimisingIds: number[];

   constructor(public _applicationStore: ApplicationStore) {}

   public ngOnInit(): void {
      // check what distance unit to use for the distance column
      this.isDistanceUnitMiles = this._applicationStore.projectsStore.selectedProject.projectSettings.distanceUnitMiles;
      this.distanceHeader = this.isDistanceUnitMiles
         ? 'Total driving distance (mi)'
         : 'Total driving distance (km)';

      this.configureTableColumns();

      // Get the callers list from the callers service.
      this.subscribeToCallers();
      this.checkForUtilisationFilters();
      this.subscribeToCallersOptimising();
      this.subscribeToCallerOptimisingStatus();
   }

   public ngAfterViewInit() {
      this.getTableHeaderHeightValues();

      /* WORKAROUND TO DISPLAY THE CORRECT COLUMNS WIDTH AFTER RESIZING
         NOT THE BEST WAY TO DO IT, BUT THE ONLY ONE WE FOUND TO RESOLVE THIS PROBLEM  */
      let colsAux = this._cols.slice();
      this.cols = this._cols.slice(0, this._cols.length - 1);

      setTimeout(() => {
         this.cols = colsAux;
      }, 500);
   }

   public ngDoCheck() {
      let tableDivContainer = this.callerList.el.nativeElement
         .firstElementChild;
      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      if (tableHeaderlement) {
         if (tableHeaderlement.clientHeight !== this.tableHeaderHeight) {
            this.getTableHeaderHeightValues();
            this.columnChanged.emit({
               headerHeight: this.headerHeight,
               tableHeaderHeight: this.tableHeaderHeight,
               tableHeight: this.tableHeight
            });
         }
         //this.scrollWidth2 = this.scrollWidth;
      }

      // let scrollableTableWrapper = tableDivContainer.getElementsByClassName("ui-datatable-scrollable-header-box")[0];
      // if(scrollableTableWrapper){
      // let bodyTable = scrollableTableWrapper.getElementsByTagName("table")[0]
      // bodyTable.style.width = (tableDivContainer.clientWidth - 17) + "px";
      // }
      // // if(scrollableTableWrapper){
      // //    scrollableTableWrapper.style.marginRight = 0 + "px";
      // // }
   }

   public ngOnDestroy(): void {
      if (this._callers_subscription) {
         this._callers_subscription.unsubscribe();
      }

      if (this._callersOptimisingSubscription) {
         this._callersOptimisingSubscription.unsubscribe();
      }

      if (this._callersOptimisingStatusSubscription) {
         this._callersOptimisingStatusSubscription.unsubscribe();
      }
   }

   // the lazy loading is typically used handle large datasets by going to the server and retrieving paged data.
   // this can be done for virtual scroll or paginated tables.
   // in our case we have all the callpoints in memory, so we are not going to the server but splicing the array to only
   // return small numbers of rows, to speed up the grid.
   // as we are using lazy loading we need to hand roll our own sorting and filtering.
   // typically we would do this on the server with custom SQL commands
   public loadDataLazy(event: LazyLoadEvent) {
      //if (this.caller) {
      this.loading = true;
         let order = event.sortOrder > 0 ? 'asc' : 'desc';

         // only re-order or sort if the event values have changed
         if (
            this._currentSortField != event.sortField ||
            this._currentSortOrder != order
         ) {
            this._sortedAndFilteredData = this.allCallers.slice();

            //this._sortedAndFilteredData.sort(this.compareValues(event.sortField, order))
            // if (event.sortField == 'fullyScheduled') {
            //    let xxxxx = compareFullyScheduled(
            //       order,
            //       this._sortedAndFilteredData
            //    );
            //    let yyyyy = Array.prototype.concat.apply([], xxxxx);
            //    this._sortedAndFilteredData = yyyyy;
            //    //this._sortedAndFilteredData.sort(this.compareValues('date', order))
            // } else {
            if (event.sortField != null) {
               this._sortedAndFilteredData.sort(
                  compareValues(event.sortField, order)
               );
            }

            this._currentSortField = event.sortField;
            this._currentSortOrder = order;
         }

         if (Object.keys(event.filters).length > 0) {
            let filteredResults = filter(
               this._sortedAndFilteredData,
               event.filters,
               this.callerList,
               this.allCols,
               this.totalRecords
            );
            this._filterNum = filteredResults.length;
            if (filteredResults.length > 0) {
               this.callers = filteredResults.slice(
                  event.first,
                  event.first + event.rows
               );
               this.totalRecords = filteredResults.length;
            } else {
               this.callers = filteredResults;
               this.totalRecords = filteredResults.length;
            }
         } else {
            this.callers = this._sortedAndFilteredData.slice(
               event.first,
               event.first + event.rows
            );
            this.totalRecords = this._sortedAndFilteredData.length;
         }
      //}
      this.loading = false;
   }

   public onHeaderCheckboxToggle(event) {
      this.allRowsSelected.emit(event);
   }

   public onRowSelect(event) {
      // the checkbox selection doesnt seem to be adding the data to the selectedarray
      if (event.type == 'checkbox') {
         this.selectedCallers.push(event.data);
      }

      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }

      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }

      this.callerList.reset();

      // have to clear all the custom filters (combos and multi selects)
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.callerList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // if the mini dash has set the filter then reset the underlying data
      if (
         this._applicationStore.callersStore.filterCallersByFullyUtilisation ||
         this._applicationStore.callersStore.filterCallersByUnderUtilisation ||
         this._applicationStore.callersStore.filterCallersByOverUtilisation
      ) {
         this._applicationStore.callersStore.resetUtilisationFilter();
      }

      // this keeps the orgingal sort
      this.callerList.updateDataToRender(this.callers);
      this.hasFilters = false;
      this.gridTitle = 'Callers';
   }

   public onFilter(event) {
      if (Object.keys(this.callerList.filters).length > 0) {
         if (
            this._applicationStore.callersStore
               .filterCallersByFullyUtilisation ||
            this._applicationStore.callersStore
               .filterCallersByUnderUtilisation ||
            this._applicationStore.callersStore.filterCallersByOverUtilisation
         ) {
            this.buildGridTitleForUtilisationFilters();
         } else {
            this.buildStandardGridTitleWithBuiltInFilters();
         }
      } else {
         if (
            this._applicationStore.callersStore
               .filterCallersByFullyUtilisation ||
            this._applicationStore.callersStore
               .filterCallersByUnderUtilisation ||
            this._applicationStore.callersStore.filterCallersByOverUtilisation
         ) {
            this.buildGridTitleForUtilisationFilters();
         } else {
            this.gridTitle = 'Callers';
            this.hasFilters = false;
         }
      }

      this.checkForUtilisationFilters();
   }

   // This is referenced in the grid and is improtant to speeding up selection in the grid
   public trackByFn(index, item) {
      return item.guid; // index or item.id
   }

   // Check to see if the caller is being optimised. It is being optimised if the caller
   // optimisation status is queued, pending or caculating.
   public isOptimising(caller: Caller) {
      if (caller) {
         return (
            caller.optimisationStatus === 'Queued' ||
            caller.optimisationStatus === 'Pending' ||
            caller.optimisationStatus === 'Calculating'
         );
      }
   }

   // Saves the selected visible grid colunms on the caller store to be retrieved the next time the grid is loaded
   public setNewColumnSelection(event: any) {
      this._applicationStore.callersStore.selectedCols = this.cols;

      // save the selected columns to local storage
      localStorage.setItem('callersSelectedCols', JSON.stringify(this.cols));
   }

   public formatDateClosed(caller: Caller): string {
      return caller.datesClosed.join(',').toUpperCase();
   }

   // builds up the data to be used in the combos for the columns that require combos to filter the data
   private delayDataBinding(callers: Caller[]) {
      this.buildFirstPage(callers);

      this.comboFilterDataMap.set(
         'role',
         getComboDataForSpecifiedProperty(
            callers,
            caller => caller.role,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'numDeferredVisits',
         getComboDataForSpecifiedProperty(
            callers,
            caller => caller.numDeferredVisits,
            false,
            ''
         )
      );

      this.comboFilterDataMap.set(
         'scheduleMetrics.minCallsPerDay',
         getComboDataForSpecifiedProperty(
            callers,
            caller => caller.scheduleMetrics.minCallsPerDay,
            false,
            ''
         )
      );

      this.comboFilterDataMap.set(
         'scheduleMetrics.maxCallsPerDay',
         getComboDataForSpecifiedProperty(
            callers,
            caller => caller.scheduleMetrics.maxCallsPerDay,
            false,
            ''
         )
      );

      this.comboFilterDataMap.set(
         'scheduleMetrics.numDisabledCallpoints',
         getComboDataForSpecifiedProperty(
            callers,
            caller => caller.scheduleMetrics.numDisabledCallpoints,
            false,
            ''
         )
      );
   }

   private buildFirstPage(callers: Caller[]) {
      this.totalRecords = callers.length;
      this.callerList.reset();
      this.callerList.updateTotalRecords();

      this.callers = callers.slice(0, this.rows);
   }

   // the mini dash can filter the under lying caller array when clicked on
   private checkForUtilisationFilters() {
      if (
         this._applicationStore.callersStore.filterCallersByFullyUtilisation ||
         this._applicationStore.callersStore.filterCallersByUnderUtilisation ||
         this._applicationStore.callersStore.filterCallersByOverUtilisation
      ) {
         this.hasFilters = true;

         this.buildGridTitleForUtilisationFilters();
      }
   }

   private buildGridTitleForUtilisationFilters() {
      let filterNum = 0;
      if (
         this.callerList.filteredValue === undefined ||
         this.callerList.filteredValue === null
      ) {
         filterNum = this.callers.length;
      } else {
         filterNum = this.callerList.filteredValue.length;
      }

      this.gridTitle =
         'Callers (Showing ' +
         filterNum +
         ' of ' +
         this._applicationStore.callersStore.totalCallersCount +
         ' total)';
   }

   private buildStandardGridTitleWithBuiltInFilters() {
      let filterNum = 0;
      if (this.callerList.filteredValue === null) {
         filterNum = this.callers.length;
      } else {
         filterNum = this.callerList.filteredValue.length;
      }

      this.gridTitle =
         'Callers (Showing ' +
         filterNum +
         ' of ' +
         this.allCallers.length +
         ' total)';
      this.hasFilters = true;
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      // Retrieves the selected columns from the callers store
      this.cols = this._applicationStore.callersStore.selectedCols;

      // The stored columns have stored the old 'code' header name for the first column,
      // change it to match the new header name.
      this.cols[0].header = 'Key';

      this.allCols = [
         {
            field: 'territory',
            header: 'Key',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'name',
            header: 'Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduledVisitsPc',
            header: 'Scheduled visits (%)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'numDeferredVisits',
            header: 'Deferred visits',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'scheduleMetrics.minCallsPerDay',
            header: 'Min visits per day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'scheduleMetrics.avgCallsPerDay',
            header: 'Avg visits per day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.maxCallsPerDay',
            header: 'Max visits per day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'scheduleMetrics.utilisationPc',
            header: 'Utilisation (%)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.driveDistanceKm',
            header: this.distanceHeader,
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.driveTimeHrs',
            header: 'Total driving time (hr)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.commuteTimeHrs',
            header: 'Total commute time (hr)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'avgCommuteTime',
            header: 'Average commute time (m)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'avgCommuteDistance',
            header: 'Average commute distance',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address1',
            header: 'Address 1',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address2',
            header: 'Address 2',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address3',
            header: 'Address 3',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address4',
            header: 'Address 4',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address5',
            header: 'Address 5',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'postcode',
            header: 'Postal Code',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'latitude',
            header: 'Latitude',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'longitude',
            header: 'Longitude',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'role',
            header: 'Role',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'team',
            header: 'Team',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numAvailableDays',
            header: 'Available days',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numEmptyDays',
            header: 'Empty days',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.spareTimeHrs',
            header: 'Spare time (hr)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numCallpoints',
            header: 'Callpoints',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numVisitsToMake',
            header: 'Total visits',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.timeInVisitsHrs',
            header: 'Time in visits (hr)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.shiftTimeInVisitPc',
            header: 'Shift time in visits (%)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.avgDriveTimeBetweenVisitsMins',
            header: 'Avg Drive time between visit (m)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numOvernights',
            header: 'Total overnights',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.maxConsecutiveOvernights',
            header: 'Consecutive overnights',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'datesClosed',
            header: 'Dates Closed',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'optimisationDurationSec',
            header: 'Optimisation Duration (Sec)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'drivetimeGenerationDurationSec',
            header: 'Drivetime Generation Duration (Sec)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'optimisationStatus',
            header: 'Optimisation status',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other1',
            header: 'Other 1',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other2',
            header: 'Other 2',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other3',
            header: 'Other 3',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other4',
            header: 'Other 4',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other5',
            header: 'Other 5',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numDisabledCallpoints',
            header: 'Disabled Callpoints',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'scheduleMetrics.numDisabledVisits',
            header: 'Disabled visits',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
      ];

      // create dictionary/hash map
      this.comboFilterDataMap = new Map<string, any[]>();
      this.columnOptions = [];

      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i]
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset
      // this back to the defualt value of all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }
   }

   // Display a progress animation in the cell while the utilisation is being calculated. The data table will
   // only update when the data changes, so the utilisation value is set to -1 (non valid value) for any callers that are being
   // optimised. This will force the data table to refresh. The HTML will look for the -1 value
   // and display the progress animation.
   private subscribeToCallersOptimising() {
      this._callersOptimisingSubscription = this._applicationStore.scheduleStore.callersOptimising$.subscribe(
         (callerIds: number[]) => {
            this.callersOptimisingIds = callerIds;
         }
      );
   }

   private subscribeToCallerOptimisingStatus() {
      this._callersOptimisingStatusSubscription = this._applicationStore.scheduleStore.callersOptimisingStatus$.subscribe(
         (status: OptimisationStatus) => {
            if (status) {
               let caller = this.callers.find(
                  caller => status.callerId === caller.callerId
               );
               if (caller) {
                  if (status.status == 'Pending') {
                     caller.optimisationStatus = 'Calculating';
                  } else {
                     caller.optimisationStatus = status.status;
                  }
               }
            }
         }
      );
   }

   private subscribeToCallers() {
      this._callers_subscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: Caller[]) => {
            this.totalRecords = callers.length;
            this.allCallers = callers;
            this._sortedAndFilteredData = this.allCallers.slice();

            setTimeout(() => this.delayDataBinding(callers), 30);
            this.delayDataBinding(callers);
         }
      );
   }

   // store the users selected columns, dictionary of field & description needed for export
   private buildExportColumnsDictionary() {
      if (this._cols) {
         let exportColumns = new Map<string, string>();
         this._cols.forEach(c => {
            exportColumns.set(c.field, c.header);
         });

         this._applicationStore.callersStore.callerExportColumns = exportColumns;
      }
   }

   private getTableHeaderHeightValues() {
      let tableDivContainer = this.callerList.el.nativeElement
         .firstElementChild;
      let headerElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-header ui-widget-header'
      )[0];
      this.headerHeight = headerElement.clientHeight;

      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      this.tableHeaderHeight = tableHeaderlement.clientHeight;

      let tableElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-scrollable-view'
      )[0];
      this.tableHeight = tableElement.clientHeight;
   }
}
