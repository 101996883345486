<div class="row">
   <div class="standard-workspace col-md-10" style="width: calc(100% - 260px);">

      <callsmart-caller-carousel></callsmart-caller-carousel>

      <!-- <div style="height:7px; width:100%; background-color: #ccc; cursor:not-allowed; margin:none"
           *ngIf="isMapHidden"> -->
      <div class="collapsed-disabled-splitter-bar" *ngIf="isMapHidden">
         <div class="expand-splitter">
            <button type="button" (click)="displayMap()" class="expand-splitter-button" pTooltip="Show map" tooltipPosition="top">
               <i class="cs-icon icon-arrow-down callsmart-link-active show-hide-arrow-splitter" aria-hidden="true"></i>
            </button>
         </div>
      </div>

      <div class="disabled-splitter-bar" style="display: none" #disabledSplitterBar>
      </div>

      <!-- Button attached to the splitter -->
      <div class="collapse-splitter" #hideMapButton *ngIf="isCollapseMapButtonVisible">
         <button type="button" (click)="displayMap()" class="collapse-splitter-button" pTooltip="Hide map" tooltipPosition="top">
            <i class="cs-icon icon-arrow-up callsmart-link-active show-hide-arrow-splitter" aria-hidden="true"></i>
         </button>
      </div>

      <div [ngStyle]="{'height.px': workspaceHeight, 'margin-top.px': marginTop}">
         <horizontal-split-pane #box1 [primary-component-minsize]="minMapSize" [secondary-component-minsize]="calendarMinSize" local-storage-key="split-pane"
            (on-change)="onHChange($event, calendar)" [secondary-component-toggled-off]="false" [primary-component-toggled-off]="isMapHidden">
            <div class="split-pane-content-primary" #googleMapPane>
               <div class="upper">
                  <div [style.height.px]="mapHeight">
                     <callsmart-google-map #callsmartMap [optimiseMarker]="optimiseMapMarkers" (onMapReady)="setMap($event)" (googleMapIdle)="onGoogleMapIdle($event)"
                        (googleMapTilesLoaded)="onGoogleMapTilesLoaded($event)" (googleMapResize)="onGoogleMapResize($event)"
                        (googleDirectionServiceError)="onGoogleDirectionServiceError($event)" (clusteringModeSelected)="onClusteringModeSelected($event)"
                        (useVisitDotIconSelected)="onUseVisitDotIconSelected($event)" (useCallerDotIconSelected)="onUseCallerDotIconSelected($event)"
                        (directLinesModeSelected)="onDirectLinesModeSelected($event)" 
                        (showFrequencySelected)="onShowFrequencySelected($event)" 
                        [clusterMarkerImagePath]="clusterImagePath"
                        [disableMapMarkerClick]="true" 
                        [containerHeight]="mapHeight" 
                        [displayFrequencyOption]="true"
                        *ngIf="!isMapHidden"></callsmart-google-map>
                  </div>
               </div>
            </div>
            <div class="split-pane-content-secondary">
               <div class="lower">
                  <callsmart-calendar class="white-calendar" #calendar
                     [scheduleEvents]="diaryEvents"
                     [numberOfWeeks]="scheduleCycleLength"
                     [startWorkingTime]="startWorkingTime"
                     [endWorkingTime]="endWorkingTime"
                     [activeWorkingDays]="activeWorkingDays"
                     [startDate]="startCycleDate"
                     [datesClosed]="datesClosed"
                     canMinimiseCalendar="true"
                     [canInternalDragDrop]="true"
                     [diaryViewTime]="diaryViewTime"
                     (daySelected)="onDaySelected($event)"
                     (visitSelected)="onVisitSelected($event)"
                     (calendarRendered)="onCalendarRendered($event)"
                     (showCalendarHeadersOnlyChanged)="onShowCalendarHeadersOnly($event)"
                     (internalVisitDropped)="onInternalVisitDropped($event)"></callsmart-calendar>
               </div>
            </div>
         </horizontal-split-pane>

      </div>
   </div>
   <div class="contextual-panel col-md-2">
      <contextual-panel [showScheduleExport]="true" [showCallerExport]="false" [showCallpointExport]="false">
      </contextual-panel>
   </div>
</div>
