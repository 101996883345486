import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { NullConverter } from 'app/shared/json-converters/null-converter';
import * as moment from 'moment/moment';

import { EventTypes } from "app/models/diary/event-types";
import { LockingTypes } from "app/models/diary/locking-Types";


// This entity class represents a single event in a calendar
@JsonObject
export class Event {

   @JsonProperty('id', NullConverter, true)
   public id: string = undefined;

   @JsonProperty('title', NullConverter, true)
   public title: string = undefined;

   @JsonProperty('description', NullConverter, true)
   public description: string = undefined;

   @JsonProperty('callpointId', NullConverter, true)
   public callpointId: string = undefined;

   @JsonProperty('start', NullConverter, true)
   public start: Date = undefined;

   @JsonProperty('end', NullConverter, true)
   public end: Date = undefined;

   @JsonProperty('isAllDay', NullConverter, true)
   public isAllDay: boolean = false;

   @JsonProperty('canRoll', NullConverter, true)
   public canRoll: boolean = false;

   @JsonProperty('eventType', NullConverter, true)
   public eventType: EventTypes = undefined;

   @JsonProperty('attendeesIds', NullConverter, true)
   public attendeesIds: number[] = undefined;

   @JsonProperty('overnight', NullConverter, true)
   public overnight: boolean = false;

   @JsonProperty('locking', NullConverter, true)
   public locking: LockingTypes = LockingTypes.none;

   public isHighlighted: boolean = false;
   public isSelected: boolean = false;
   public startEditable: boolean = false;

   // The event id is generated client side and stored in the data base as xml on save, used for projectcalendar events.
   // public get eventId(): string{
   //    return this.id;
   // }


   // create a constructor passing default parameters

   public static create(date: Date, appointment: any): Event {
      let event: Event = new Event();
      // Set the start and end date.
      event.start = new Date(date + ' ' + appointment.startTime);
      let duration: moment.Duration = moment.duration(appointment.duration);
      event.end = moment(event.start).add(duration).toDate();

      if (appointment.description) {
         event.description = appointment.description;
      }

      if (appointment.callpointId) {
         event.callpointId = appointment.callpointId
      }

      if (appointment.driveDistance) {
         // TODO.
      }

      event.overnight = appointment.overnightStay || false;

      switch (appointment.type.toLowerCase()) {
         case 'callpoint':
            event.eventType = EventTypes.visit;            
            event.title = event.description;
            break;
         case 'event':
            event.eventType = EventTypes.project;
            event.title = event.description;
            break;
         case 'lunch':
            event.eventType = EventTypes.lunch;
            event.title = 'Lunch';
            break;
         case 'drive':
            event.eventType = EventTypes.travelTime;
            event.title = '';
            break;
         case 'commute':
            event.eventType = EventTypes.commute;
            event.title = '';
            break;
         default:
      }

      if (appointment.lockType) {
         switch (appointment.lockType.toLowerCase()) {
            case 'locked':
               event.locking = LockingTypes.dayAndTime;
               break;
            case 'lockedtoday':
               event.locking = LockingTypes.day;
               break;
         }
      }

      event.isSelected = false;
      event.isHighlighted = false;
      return event;
   }

   public static createOvernight(dateIni: Date, dateEnd: Date): Event {
      let event = new Event();
      event.start = new Date(dateIni);
      event.end = new Date(dateEnd);
      event.description = 'Overnight';
      event.title = 'Overnight';
      event.eventType = EventTypes.overnight;

      return event;
   }

   public static createProjectEvent(calendarEvent: any): Event {
      if (calendarEvent && calendarEvent.calEvent && calendarEvent.view) {
         let event: Event = new Event();
         event.id = calendarEvent.calEvent.id;
         event.title = calendarEvent.calEvent.title;

         let start = calendarEvent.calEvent.start;
         let end = calendarEvent.calEvent.end;
         if (calendarEvent.view.name === 'month') {
            start.stripTime();
         }
         if (end) {
            if (calendarEvent.view.name === 'month') {
               end.stripTime();
            }
            event.end = end.toDate();
         }
         event.start = start.toDate();
         event.isAllDay = calendarEvent.calEvent.isAllDay;
         return event
      }
      else {
         return null;
      }
   }
}
