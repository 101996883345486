// model for dispalying information in the custom error box on the app component
export class ErrorData {

   constructor(title: string, bodyText: string, errors: string[], bottomLink: string, errorSource: string) {

      this.title = title;
      this.errors = errors;
      this.bodyText = bodyText;
      this.bottomLink = bottomLink;
      this.errorSource = errorSource;
   }

   title: string;
   errors: string[];
   bodyText: string;
   bottomLink: string;
   errorSource: string;

}
