import { throwError as observableThrowError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { CallersStore } from 'app/stores/callers-store';
import { Callpoint } from 'app/models/callpoint';
import { environment } from 'environments/environment';
import { ExportParameters } from 'app/models/export-parameters';
import { ImportData } from 'app/models/import-data';
import { LockingTypes } from 'app/models/diary/locking-Types';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// this service is used purely for data acess CRUD operations to the data base
// the serbice is normally called from with in a store
@Injectable()
export class CallpointService {
   callpointsUrl = (projectId) => `${environment.baseUrl}api/projects/${projectId}/callpoints`;

   // really shouldnt inject the callers store here we are trying to keep the services clean and only data access
   constructor(
      private _http: HttpClient,
      private _callersStore: CallersStore
   ) { }

   public getCallpointsCountForProject(projectId: number) {
      let url: string = `${this.callpointsUrl(projectId)}/count`;

      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public deleteCallpointsByProjectId(projectId: number) {
      return this._http.delete(this.callpointsUrl(projectId))
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getCallpointsAssignedToPerson(callerId: number) {
      let url: string = `${environment.baseUrl}/api/caller/${callerId}/callpoints`;

      return this._http.get<Callpoint[]>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callpoints: Callpoint[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callpoints in to Typescript objects
               try {
                  callpoints = jsonConvert.deserialize(response, Callpoint);
                  // Hack - callerTerritory is set on the callpoint object here instead of the back end. This
                  // property cannot be made in to a derived property since this requires the injection of the
                  // caller store, which breaks JSON serialisation when this object cloned.
                  callpoints.forEach(callpoint => {
                     let caller = this._callersStore.callers.find(
                        caller => caller.callerId == callpoint.callerId
                     );
                     callpoint.callerTerritory = caller.territory;
                     callpoint.guid = callpoint.generateGUID();
                  });
               } catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callpoints;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getCallpointsAssignedToMultiplePeople(projectId: number, callerIds: number[]) {
      let url: string = `${environment.baseUrl}/api/projects/${projectId}/multiple/caller/callpoints`;

      return this._http.post<Callpoint[]>(url, callerIds)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callpoints: Callpoint[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callpoints in to Typescript objects
               try {
                  callpoints = jsonConvert.deserialize(response, Callpoint);
                  // Hack - callerTerritory is set on the callpoint object here instead of the back end. This
                  // property cannot be made in to a derived property since this requires the injection of the
                  // caller store, which breaks JSON serialisation when this object cloned.
                  callpoints.forEach(callpoint => {
                     let caller = this._callersStore.callers.find(
                        caller => caller.callerId == callpoint.callerId
                     );
                     callpoint.callerTerritory = caller.territory;
                     callpoint.guid = callpoint.generateGUID();
                  });
               } catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callpoints;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateCallpointsLockedStateForMultipleCallers(projectId: number, callerIds: number[], callpointRefs: string[],
      lockingType: LockingTypes) {
      let url: string = `${environment.baseUrl}/api/projects/${projectId}/multiple/caller/callpoints/lockedstate`;

      let lockState = 'None';
      switch (lockingType) {
         case LockingTypes.day:
            lockState = 'LockedToDay';
            break;
         case LockingTypes.dayAndTime:
            lockState = 'Locked';
            break;
         case LockingTypes.none:
            lockState = 'None';
            break;
      }

      const data = {
         callerIds: callerIds,
         callpointReferences: callpointRefs,
         lockState: lockState
      };

      return this._http.put(url, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public exportCallpointsData(projectId: number, exportParamters: ExportParameters) {
      let url: string = `${this.callpointsUrl(projectId)}/exportdata`;
      return this._http.post(url, exportParamters, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateCallpoints(callpoints: Callpoint[]) {
      let url: string = `${environment.baseUrl}/api/callpoints`;

      callpoints.forEach(callpoint => {
         if (
            callpoint.callpointSettings &&
            callpoint.callpointSettings.dayCombinations &&
            callpoint.callpointSettings.dayCombinations.length === 0
         ) {
            callpoint.callpointSettings.dayCombinations = [];
         }
      });

      // json2typescript.JsonConvert is used to create an object that consists only of the Callpoint properties that
      // are attributed for serialisation using 'JsonProperty'.
      // MNF: However JsonConvert.serialize returns an Object of type 'any', not a Callpoint. So this code is misleading
      // as serializedCallpoints is not in fact a Callpoint[] but an Object[].
      let jsonConvert: JsonConvert = new JsonConvert();
      let serializedCallpoints: Callpoint[] = callpoints.map(cs =>
         jsonConvert.serialize(cs)
      );

      // Transform each of the callers and return a new array with the transformed data.
      // MNF: What this actually does is adjust all dates in the object graph. However doing this by calling
      // transformSettingsObject copies the entire object graph again, which seems redundant as a copy has already just
      // been made. If transformObjectDates were called directly that would have the same effect without creating a
      // redundant copy.
      let transformedData: Callpoint[] = serializedCallpoints.map(cs =>
         this.transformSettingsObject<Callpoint>(cs)
      );

      return this._http.put(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getCallpointMergeCounts(currentProjectId: number, tempProjectId: number) {
      let dto = new ImportData();
      dto.originalProjectId = currentProjectId;
      dto.tempProjectId = tempProjectId;

      let url: string = `${environment.baseUrl}/api/callpoints/mergecounts`;

      return this._http.put(url, dto)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response === null) {
                  return observableThrowError('Returned data was null');
               }
               return response;
            }),
            catchError(error => observableThrowError(error)));
   }

   private transformSettingsObject<T>(modelClass: T): T {
      // This is the most efficient way to deep clone an object in JavaScript
      // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      let clonedObject: T = JSON.parse(JSON.stringify(modelClass));

      CallsmartUtils.transformObjectDates(clonedObject);
      return clonedObject;
   }
}
