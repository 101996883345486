import { Component, OnChanges, SimpleChanges, AfterContentInit, Input, Output, EventEmitter, ContentChildren, QueryList, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/components/common/api';

import { BrowserWindowService } from 'app/services/browser-window.service';
import { StepComponent } from 'app/shared/wizard/step/step.component';

/**
 * Component that represents a multi step wizard. Navigation of each step is
 * controlled by the next and previous buttons. Form validation support is
 * included to disable the next button if the form in the current step
 * is invalid. Makes use of the PrimeNG p-steps component to provide
 * breadcrumb navigation above the wizard.
 *
 * Example usage:
 * HTML
 * <callsmart-wizard wizardTitle="Wizard title goes here"
 *                       [(activeIndex)]="activeIndex"
 *                       (change)="onChange($event)"
 *                       (finish)="onFinish()"
 *                       (cancel)="onCancel()"
 *                       [finishButtonLabel]="finishButtonLabel">
 *    <callsmart-step label="Settings" [formValid]="stepOne.valid">
 *       <form #stepOne="ngForm">
 *          <!-- Step content -->
 *       </form>
 *    </callsmart-step>
 *    .
 *    .
 *    .
 * </callsmart-wizard>
 *
 * callsmart-wizard parameters:
 *    wizardTitle - Title for the wizard, will be displayed as {wizardTitle} - {step label}.
 *    activeIndex - controls which step to display, 0 based.
 *    finishButtonLabel - last button label.
 *
 * callsmart-wizard events:
 *    change - gets triggered when the step changes, the label for the step
 *             is available in the $event object.
 *    finish - gets triggered when the finish button is clicked in the last step.
 *    cancel - gets triggered when the cancel button is clicked.
 *
 * callsmart-step parameters:
 *    label - label for the step.
 *    formValid - this is used to determine if the form in this step is valid or not.
 *                Pass in the form.valid property here to bind to it. The form tag must
 *                use a reference variable that can be used here (#stepOne for example).
 *
 * TYPESCRIPT
 * Provide handler methods for each of the events defined in callsmart-wizard:
 *
 *   onChange(label: string) {
 *      console.log(label);
 *   }
 *
 *   onFinish() {
 *      console.log("Finish button pressed");
 *   }
 *
 *   onCancel() {
 *      console.log("Cancel button pressed");
 *   }
 *
 * CSS
 * The icons for the breadcrumb buttons can be controlled using the following:
 *
 * body .ui-steps .ui-steps-item:nth-child(1) .ui-menuitem-link .ui-steps-title:before
 *
 * Note the use of the nth-child selector, above is the example for the first button,
 * using this you can have different icons for each of the buttons.
 *
 * For detailed example see wizard-test.component
 */
@Component({
   selector: 'callsmart-wizard',
   templateUrl: 'wizard.component.html'
})
export class WizardComponent implements AfterContentInit, OnChanges {

   // The index of the active step, used by p-steps to drive the
   // functionality of the breadcrumb buttons. Also can be used by the client
   // to make any step active. This is useful when the summary page provides
   // buttons to be able to navigate back to any step from outside the wizard.
   @Input() activeIndex: number = 0;

   // Style property for this component.
   @Input() styleClass: string;

   // Style property for each of the step components.
   @Input() stepClass: string;

   // Label for the finish button, used on the last page of the wizard.
   @Input() finishButtonLabel: string = 'Finish';

   // Title for this wizard.
   @Input() wizardTitle: string = 'Wizard Title';

   // This fires an event for p-steps to update itself.
   @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

   // Notifies the client that wizard step has changed.
   @Output() change = new EventEmitter<any>();

   // Notifies the client when the finish button of the last step has been clicked.
   @Output() finish = new EventEmitter<void>();

   // Notifies the client when the cancel button has been clicked.
   @Output() cancel = new EventEmitter<void>();

   // Reference to all the step components used by this wizard
   @ContentChildren(StepComponent) steps: QueryList<StepComponent>;

   // Menu model used to determine navigation and event handling for each step.
   public items: MenuItem[] = [];

   // Used to determine the state of the next button.
   public disabled: boolean = false;

   // Used to determine the height of the wizard panel.
   public workspaceHeight: number;

   // Label for the active step.
   public activeStepLabel: string;

   constructor(private windowService: BrowserWindowService) {
      // Subscribe to the window resize event.
      windowService.height$.subscribe((value: number) => {
         this.workspaceHeight = value - 200;
      });
   }

   /**
    * Initialise the menu model for each step.
    */
   ngAfterContentInit() {
      let disableStep = false;
      this.steps.forEach((step: StepComponent, index: number) => {
         if (!step.styleClass) {
            // Set style class if it was not set on step component directly.
            step.styleClass = this.stepClass;
         }

         // Subscribe to the form state change event for each step.
         step.formState$.subscribe((state: boolean) => {
            this.onFormStateChange(state);
         });

         if (index === this.activeIndex) {
            // Show this step on init.
            step.active = true;
            step.visited = true;
            disableStep = false;
            this.activeStepLabel = step.label;
         }
         else {
            disableStep = true;
         }

         this.items[index] = {
            label: step.label,
            command: (event: any) => {
               // Hide all steps.
               this.steps.forEach((s: StepComponent) => {
                  s.active = false;
                  s.visited = false;
               });

               // show the step the user has clicked on.
               step.active = true;
               step.visited = true;

               // Emitting this event here cause the WizardComponent.ngOnChanges() to trigger
               // which will then update the button states.
               this.activeIndexChange.emit(index);
               this.change.next({label:step.label, previouseIndex:-1, activeIndex:this.activeIndex });

               // Emit currently selected label.
               this.change.next(step.label);

            },
            disabled: disableStep
         };
      });
   }

   /**
    * Change detection for the previous and next buttons.
    * @param changes
    */
   ngOnChanges(changes: SimpleChanges) {
      if (!this.steps) {
         // we could also check changes['activeIndex'].isFirstChange()
         return;
      }

      for (let prop in changes) {
         if (prop === 'activeIndex') {
            let curIndex = changes[prop].currentValue;
            this.steps.forEach((step: StepComponent, index: number) => {
               // Show / hide the step.
               let selected = index === curIndex;
               step.active = selected;

               if (selected) {
                  step.visited = true;

                  // Emit currently selected label.
                  this.change.next({label:step.label, previouseIndex:changes.activeIndex.previousValue, activeIndex:changes.activeIndex.currentValue });

                  // Update the wizard title with the current step label.
                  this.activeStepLabel = step.label;

                  // Determine if the next button needs to be disabled or not.
                  this.disabled = !step.formValid;

                  // Enable the breadcrumb button for this step so that the user
                  // can revisit it.
                  this.items[curIndex].disabled = false;
               }

            });

         }
      }
   }

   private onPrevious() {
      this.activeIndex--;
      // Emit currently selected index (two-way binding) for the p-steps component.
      this.activeIndexChange.emit(this.activeIndex);

      // Show / hide steps and emit selected label.
      this.ngOnChanges({
         activeIndex: {
            currentValue: this.activeIndex,
            previousValue: this.activeIndex + 1,
            firstChange: false,
            isFirstChange: () => false
         }
      });
   }

   private onNext() {
      this.activeIndex++;
      // Emit currently selected index (two-way binding).
      this.activeIndexChange.emit(this.activeIndex);

      // Show / hide steps and emit selected label.
      this.ngOnChanges({
         activeIndex: {
            currentValue: this.activeIndex,
            previousValue: this.activeIndex - 1,
            firstChange: false,
            isFirstChange: () => false
         }
      });
   }

   /**
    * Emit the cancel event so that the client can handle it.
    */
   public onCancel() {
      this.cancel.next();
   }

   /**
    * Emit the finish event so that the client can handle it.
    */
   public onFinish() {
      this.finish.next();
   }

   private onFormStateChange(state: boolean) {
      this.disabled = state;

      if (this.disabled) {
         // If the current step is invalid then all subsequent breadcrumb buttons
         // need to be disabled.
         for (let i = this.activeIndex + 1; i < this.items.length; i++) {
            this.items[i].disabled = true;
         }
      }
      else {
         // If the current step is now valid, then only enable any breadcrumb buttons
         // that have been visited before.
         this.steps.forEach((step: StepComponent, index: number) => {
            if (step.visited) {
               this.items[index].disabled = false;
            }
         });
      }
   }
}
