
/**
 * Model class for encapsulating the error properties when an error occurs.
 */
export class CallsmartError {
   public status: number;
   public statusText: string;
   public url: string;
   public stackTraceMsg: string;
   public stackTraceDetail: string;
}