import { of, timer, BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeWhile, concatMap, switchMap, catchError, concat } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Event } from 'app/models/diary/event';
import { ProjectStatus } from 'app/models/projectStatus';
import { Project } from 'app/models/project';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { ProjectService } from 'app/services/project.service';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { ImportOptions } from 'app/models/import-options';
import { SpinnerStore } from 'app/stores/spinner-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// History
// How does the application handle opening projects.
// The code base has moved and evolved this is now getting out of sync and needs to be tidied up.
// Previously all that needed to hapen was the setSelected project would update the selected project observabe,
// subscriptions to this observalble within the application store and  sub components would drive the refreshing of the project data
// Project status was later introduced and built on.
// Now all projects when opened have to check there project status, to see if they are locked or still building or have errors
// one call goes to project status from the open project workspace
// if the project status is good then projectsStore.loadProjectAndCheckStatus is called
// this will trigger a change of the project  this._selectedProject.next(project);
// the key project change subscription is application store subscribeToProjectChanged
// as well as as a change to code that subscribes to the Project Status the key subscription is the application store subscribeToProjectStatus

// Solution
// All projects on creation or opening or roll forward have to have there project status checked for accessablity.
// Once the status is deemed ok an projectOpen event is thrown.
// the application store listens for the projectOpen event and orchestrates the project store and other stores
// closing a project works in a similar manner. project store raises a project close event.
// the application store listens for the projectClose event and orchestrates the project store and other stores
// in the application store the subscription to selectedproject and project status have been removed.
// PROJECT ORCHESTRATION IS DONE ON THE OPEN AND CLOSE EVENTS

// TODO bring setting of the project change into single place for Open project
// TODO why does the setSelectedProject set the project status may no longer be needed
// TODO closeProject works with new merged project change notification
// TODO investicate how the copy and roll work in relation to change project and status

@Injectable()
export class ProjectsStore {
   private _pollProjectStatusSubscription: Subscription = new Subscription();

   // events used in the aplication-store to orchestrate the interaction of stores
   public ProjectCreated: Subject<any> = new Subject<any>();

   public reloadCallerData: Subject<any> = new Subject<any>();

   // All projects.
   private _projects: BehaviorSubject<ReadonlyArray<Project>> = new BehaviorSubject<ReadonlyArray<Project>>([]);
   public projects$: Observable<ReadonlyArray<Project>> = this._projects.asObservable();

   private _projectEvents: BehaviorSubject<ReadonlyArray<Event>> = new BehaviorSubject<ReadonlyArray<Event>>([]);
   public projectEvents$: Observable<ReadonlyArray<Event>> = this._projectEvents.asObservable();

   // All project folders
   private _projectFolders: BehaviorSubject<ReadonlyArray<string>> = new BehaviorSubject<ReadonlyArray<string>>([]);
   public projectFolders$: Observable<ReadonlyArray<string>> = this._projectFolders.asObservable();

   // The currently selected project.
   private _selectedProject: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
   public selectedProject$: Observable<Project> = this._selectedProject.asObservable();

   private _projectStatus: BehaviorSubject<ProjectStatus> = new BehaviorSubject<ProjectStatus>({
      callerCount: -1,
      drivetimeCount: 0,
      scheduleCount: 0,
      scheduleMetricCount: 0,
      errorMessage: '',
      isProjectInitialising: false,
      projectInUse: false,
      projectInUseByUser: '',
      travelModelValid: false,
      exceedsConcurrentTravelModelAccess: false,
      travelModelUsedBy: '',
      projectId: 0,
      pendingProjectDrivetimesCount: 0,
      pendingProjectOptimisationsCount: 0,
      queuedProjectDrivetimesCount: 0,
      queuedProjectOptimisationsCount: 0,
      totalQueuedDrivetimesCount: 0,
      totalQueuedOptimisationsCount: 0,
      totalPendingOptimisationsCount: 0,
      totalPendingDrivetimesCount: 0,
      loadingProjectNeverOpened: false,
      travelModelUsed: '',
      projectStatusDetail: [],
   });
   public projectStatus$: Observable<ProjectStatus> = this._projectStatus.asObservable();

   private _isProjectAccessable: BehaviorSubject<ProjectStatus> = new BehaviorSubject<ProjectStatus>({
      callerCount: -1,
      drivetimeCount: 0,
      scheduleCount: 0,
      scheduleMetricCount: 0,
      errorMessage: '',
      isProjectInitialising: false,
      projectInUse: false,
      projectInUseByUser: '',
      travelModelValid: false,
      exceedsConcurrentTravelModelAccess: false,
      travelModelUsedBy: '',
      projectId: 0,
      pendingProjectDrivetimesCount: 0,
      pendingProjectOptimisationsCount: 0,
      queuedProjectDrivetimesCount: 0,
      queuedProjectOptimisationsCount: 0,
      totalQueuedDrivetimesCount: 0,
      totalQueuedOptimisationsCount: 0,
      totalPendingOptimisationsCount: 0,
      totalPendingDrivetimesCount: 0,
      loadingProjectNeverOpened: false,
      travelModelUsed: '',
      projectStatusDetail: [],
   });
   public isProjectAccessable$: Observable<ProjectStatus> = this._isProjectAccessable.asObservable();

   private _mergeImportLocationChanged: BehaviorSubject<ImportOptions> = new BehaviorSubject<ImportOptions>(null);
   public mergeImportLocationChanged$: Observable<ImportOptions> = this._mergeImportLocationChanged.asObservable();

   public mergeImportUpdateFrequencyCallerIds: Subject<any> = new Subject<any>();

   public projectClosed: Subject<any> = new Subject<any>();

   public projectOpen: Subject<any> = new Subject<any>();

   public navigateToDashboard: Subject<any> = new Subject<any>();

   public importCompleted: Subject<any> = new Subject<any>();

   public eventsUpdated: Subject<any> = new Subject<any>();

   public get projectEvents(): ReadonlyArray<Event> {
      return this._projectEvents.getValue();
   }

   public get projects() {
      return this._projects.getValue();
   }

   public get projectFolders() {
      return this._projectFolders.getValue();
   }

   public get selectedProject() {
      return this._selectedProject.getValue();
   }

   public resetImportOptions() {
      this._mergeImportLocationChanged.next(null);
   }

   public loadProjectWithStatusCheck: boolean = false;

   // In order to determine the status poll interval, we need to capture the start time
   // of the schedule generation.
   private _scheduleStartTime: moment.Moment;

   // Number of milliseconds to wait for the status update poll. This defaults to 5 seconds
   // but will be adjusted based on the duration of the schedule generation of which ever
   // caller completes first. This hopefully will stop the client hammering the server
   // with status polls if the schedule generation takes a very long time.
   private _statusPollInterval: number =
      CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
   private _pollingFirstAttempt: boolean = true;

   constructor(
      private _projectService: ProjectService,
      private _errorHandler: ErrorHandlerService,
      private _spinnerStore: SpinnerStore,
      private _router: Router
   ) { }

   // sets the selected project and starts the loading of the calendar data and markes the last updated field for the project
   public setSelectedProject(project: Project, userId: number) {
      //console.log("project store - set selected project to ", project);
      this._selectedProject.next(project);

      // When the user logs out, the current project is set to null so this check is required here.
      if (this.selectedProject != null) {
         this.loadProjectCalendar(this.selectedProject.projectId);
         this.updateProjectModifiedDate(project, userId);
      } else {
         this._projectEvents.next([]);
      }
   }

   // Create an empty project for the Create New wizard.
   public createEmptyProject(userId: number, folder: string) {
      let project = new Project();
      project.projectId = 0;
      project.userId = userId;
      project.scheduleStartDate = new Date();
      project.folder = folder;
      // wile gathering data for the new project set it to be in temporary state
      project.temporary = true;
      project.fromCreateNew = true;

      this.createProject(project);
   }

   // Create a temp project for the import wizard.
   public createTemporaryProject(userId: number, folder: string) {
      let project = new Project();
      project.projectId = 0;
      project.userId = userId;
      project.scheduleStartDate = new Date();
      project.folder = folder;
      project.temporary = true;
      project.fromCreateNew = false;
      project.scheduleStartDate = this.selectedProject.scheduleStartDate;
      project.travelModelId = this.selectedProject.projectSettings.travelModel.travelModelId;
      project.callerSettings = this.selectedProject.callerSettings;
      project.callpointSettings = this.selectedProject.callpointSettings;
      project.projectSettings = this.selectedProject.projectSettings;

      this.createProject(project);
   }

   private createProject(project: Project) {
      this._projectService.createProject(project).subscribe(
         (project: Project) => {
            if (project) {
               this.ProjectCreated.next(project);
               this.refreshProjectData();
            } else {
               this._errorHandler.handleError(
                  'Failed to create empty project, returned: ' + project
               );
            }
         },
         (error) => this._errorHandler.handleError(error)
      );
   }

   // Polls project initialisation status. Status is returned as a count of the drivetimes and schedules generated.
   // Initialisation is complete when the number of drivetimes and schedules generated both match the number of callers
   // in the project.
   private pollProjectStatus(projectId: number): Observable<ProjectStatus> {
      // Poll using a recursive concatenation algorithm. The next poll does not start until the response to the
      // previous poll has been received.
      return this._projectService
         .getProjectStatus(projectId).pipe(
            concat(
               timer(this._statusPollInterval).pipe(switchMap(() =>
                  this.pollProjectStatus(projectId)
               ))
            ),
            catchError(function onError(error): Observable<ProjectStatus> {
               if (this._errorHandler) {
                  this._errorHandler.handleError(error);
               }
               return of();
            })
         );
   }

   // Instructs the App service to generate drivetimes and schedules for a new project.
   public initialiseProject(
      projectId: number,
      callerCount: number,
      userId: number
   ): void {
      // Send initialiseProject to the service to start process of generating drivetimes and schedules.
      // Monitor progress of generating drivetimes and schedules until complete.

      // Set initial status of 'unknown'.
      let startStatus: ProjectStatus = {
         callerCount: -1,
         drivetimeCount: 0,
         scheduleCount: 0,
         scheduleMetricCount: 0,
         errorMessage: '',
         isProjectInitialising: false,
         projectInUse: false,
         projectInUseByUser: '',
         travelModelValid: false,
         exceedsConcurrentTravelModelAccess: false,
         travelModelUsedBy: '',
         projectId: 0,
         pendingProjectDrivetimesCount: 0,
         pendingProjectOptimisationsCount: 0,
         queuedProjectDrivetimesCount: 0,
         queuedProjectOptimisationsCount: 0,
         totalQueuedDrivetimesCount: 0,
         totalQueuedOptimisationsCount: 0,
         totalPendingOptimisationsCount: 0,
         totalPendingDrivetimesCount: 0,
         loadingProjectNeverOpened: false,
         travelModelUsed: '',
         projectStatusDetail: [],
      };
      this._projectStatus.next(startStatus);

      this._pollProjectStatusSubscription.unsubscribe();

      // We want to know how long the schedule generation takes, record the start time.
      this._scheduleStartTime = moment();

      // Reset the flag, only capture the schedule generation time once.
      this._pollingFirstAttempt = true;

      // Reset polling interval for each new create project.
      this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;

      // Trigger initalisation and poll to retrieve current status from app server.
      this._pollProjectStatusSubscription = this._projectService
         .initialiseProject(projectId).pipe(
            // switchMap to chain initialiseProject to pollInitialiseProjectStatus
            switchMap(() => this.pollProjectStatus(projectId)),
            concatMap((status: ProjectStatus) =>
               // 'takeWhile' on its own will not return the element matching this predicate, so this rather obtuse
               // concatMap is required to achieve the equivalent of 'takeWhileInclusive'.
               (status.drivetimeCount === status.callerCount &&
                  status.isProjectInitialising === false &&
                  status.scheduleCount === status.callerCount) ||
                  status.errorMessage.length > 0 //status.scheduleCount === status.callerCount
                  ? of(status, null)
                  : of(status)
            ),
            takeWhile((status: ProjectStatus) => status != null))
         .subscribe((status: ProjectStatus) => {
            //console.log(status);
            //console.log('initialiseProject - polling for', this._statusPollInterval);

            // Only adjust the polling interval when we have first caller scheduled.
            if (this._pollingFirstAttempt) {
               // Compare the previous schedule count with the current schedule count, if they are not the same
               // then that means the schedule has generated.
               if (
                  this._projectStatus.getValue().scheduleCount !==
                  status.scheduleCount
               ) {
                  this._statusPollInterval = moment().diff(
                     this._scheduleStartTime
                  );
                  //var duration = moment.duration(this._statusPollInterval);
                  //console.log('1 schedule generated in minutes:', duration.as('minutes'));
                  this._pollingFirstAttempt = false;
               }
            }

            // if (status.drivetimeCount == status.callerCount && status.isProjectInitialising == false && status.scheduleCount == status.callerCount) {
            //    // check if there are any schedule metrics if not delay the loading of the project as the metrics are being updated in the database
            //    if (/*status.drivetimeCount == status.callerCount && status.callerCount == status.scheduleMetricCount*/
            //       status.LoadingProjectNeverOpened) {
            //       //console.log("Polling status complete load project and open", status);
            //       this.loadProjectAndOpen(projectId, userId);
            //    }
            //    else if(!status.LoadingProjectNeverOpened){

            //       //console.log("Polling status complete load project and open - delayed as metric count does not match", status);
            //       Observable.timer(3000).subscribe(() => this.loadProjectAndOpen(projectId, userId));
            //    }
            // }
            // Checks if the project has been totally initialised
            if (!status.isProjectInitialising) {
               // Once initialised the project is loaded
               this.loadProjectAndOpen(projectId, userId);
            }
            this._projectStatus.next(status);
         });
   }

   // Instructs the App service to merge callpoints and callers
   public mergeImportedData(tempProjectId: number, importOptions: ImportOptions, projectName: string, userId: number): void {
      this._spinnerStore.showSpinner();
      this._projectService
         .mergeImportedData(tempProjectId, importOptions, projectName, userId)
         .subscribe(
            (response: any) => {

               if (response.updateFrequencyCallerIds && response.updateFrequencyCallerIds.length > 0) {
                  this.mergeImportUpdateFrequencyCallerIds.next(response.updateFrequencyCallerIds);
               }

               if (response.generateDrivetimes) {
                  // Handle this in the application store.
                  importOptions.originalProjectCopyId = response.copyProjectId;
                  importOptions.tempProjectId = tempProjectId;
                  this._mergeImportLocationChanged.next(importOptions);
               } else {
                  this.deleteProject(tempProjectId, false);
                  this.deleteProject(response.copyProjectId, false);
                  setTimeout(
                     () => this.importCompleted.next(importOptions),
                     500
                  );
                  //this.navigateToDashboard.next();
               }
               this._spinnerStore.hideSpinner();
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public rollbackProjectImportChanges(originalProjectId: number, copyProjectId: number): void {
      // delete original project.
      // make copy project permanent
      this._spinnerStore.showSpinner();
      this._projectService.rollbackProjectImportChanges(originalProjectId, copyProjectId)
         .subscribe(
            (response: boolean) => {
               if (response) {
                  this.resetImportOptions();
                  this.refreshProjectData();
                  this._spinnerStore.hideSpinner();
               }
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   // this function is used to update the project modified date
   // currently only used when set the selected project is called.
   // think carefully if you are going to use this else where, lets try and keep its use in a single location
   public updateProjectModifiedDate(project: Project, userId: number) {
      project.inUsebyUserId = userId;
      this._projectService.updateProject(project).subscribe(
         (res: boolean) => {
            if (res) {
               // update the prject list in the back ground
               this.refreshProjectData();
            }
         },
         (error) => this._errorHandler.handleError(error)
      );
   }

   public deleteProject(projectId: number, showSpinner: boolean = true) {
      //console.log('Deleting project:', projectId);

      if (showSpinner) {
         this._spinnerStore.showSpinner();
      }

      this._projectService.deleteProject(projectId).subscribe(
         (res: boolean) => {
            if (res) {
               this._spinnerStore.hideSpinner();
               //  save was successful
               // refresh the project data list
               this.refreshProjectData();
            }
         },
         (error) => {
            this._spinnerStore.hideSpinner();
            this._errorHandler.handleError(error);
         }
      );
   }

   public closeProject(projectId, userId) {
      this.setSelectedProject(null, userId);
      this.projectClosed.next(projectId);
   }

   public createUpdateProjectCalendar(
      projectId: number,
      projectEvents: Event[]
   ) {
      this._projectService
         .createUpdateProjectCalendar(projectId, projectEvents)
         .subscribe(
            (res: boolean) => {
               if (res) {
                  //  save was successful
                  this.eventsUpdated.next();
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   public loadInitialProjectData(openfirstProject: boolean, userId: number) {
      // need to get the project data
      this._projectService.getAllProjects().subscribe(
         (projects: Project[]) => {
            this._projects.next(projects);

            if (openfirstProject) {
               this.setSelectedProject(this._projects.getValue()[0], userId);
            }
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // this method returns the project status in an observable
   public getProjectStatus(project: Project): void {
      this._projectService.getProjectStatus(project.projectId).subscribe(
         (status: ProjectStatus) => {
            this._projectStatus.next(status);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // this method is similar to get project status.
   // it is needed because get project status can be used to poll a project status
   // we need a single atomic way of checking the status once in the store to see if it can be opened
   // typically used on the open projects workspace
   public isProjectAccessable(projectId: number): void {
      this._projectService.getProjectStatus(projectId).subscribe(
         (status: ProjectStatus) => {
            this._isProjectAccessable.next(status);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // this method returns the project status in an observable
   public getProjectStatusById(projectId: number): void {
      this._projectService.getProjectStatus(projectId).subscribe(
         (status: ProjectStatus) => {
            this._projectStatus.next(status);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // load the project and check the project status is all drivetimes and schedules generated
   public loadProjectAndCheckStatus(project: Project, userId: number): void {
      this._pollProjectStatusSubscription.unsubscribe();

      // We want to know how long the schedule generation takes, record the start time.
      this._scheduleStartTime = moment();

      // Reset the flag, only capture the schedule generation time once.
      this._pollingFirstAttempt = true;

      // Reset polling interval for each new create project.
      this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;

      // clear status status of nothing generated.
      let startStatus: ProjectStatus = {
         callerCount: -1,
         drivetimeCount: 0,
         scheduleCount: 0,
         scheduleMetricCount: 0,
         errorMessage: '',
         isProjectInitialising: false,
         projectInUse: false,
         projectInUseByUser: '',
         travelModelValid: false,
         exceedsConcurrentTravelModelAccess: false,
         travelModelUsedBy: '',
         projectId: 0,
         pendingProjectDrivetimesCount: 0,
         pendingProjectOptimisationsCount: 0,
         queuedProjectDrivetimesCount: 0,
         queuedProjectOptimisationsCount: 0,
         totalQueuedDrivetimesCount: 0,
         totalQueuedOptimisationsCount: 0,
         totalPendingOptimisationsCount: 0,
         totalPendingDrivetimesCount: 0,
         loadingProjectNeverOpened: false,
         travelModelUsed: '',
         projectStatusDetail: [],
      };
      this._projectStatus.next(startStatus);

      this._pollProjectStatusSubscription = this.pollProjectStatus(project.projectId)
         .pipe(
            concatMap((status: ProjectStatus) =>
               // 'takeWhile' on its own will not return the element matching this predicate, so this rather obtuse
               // concatMap is required to achieve the equivalent of 'takeWhileInclusive'.
               (status.drivetimeCount === status.callerCount && status.isProjectInitialising === false) ||
                  status.errorMessage.length > 0
                  ? of(status, null)
                  : of(status)
            ),
            takeWhile((status: ProjectStatus) => status != null)
         )
         .subscribe((status: ProjectStatus) => {
            // Only adjust the polling interval when we have first caller scheduled.
            if (this._pollingFirstAttempt) {
               // Compare the previous schedule count with the current schedule count, if they are not the same
               // then that means the schedule has generated.
               if (this._projectStatus.getValue().scheduleCount !== status.scheduleCount) {
                  this._statusPollInterval = moment().diff(this._scheduleStartTime);
                  //var duration = moment.duration(this._statusPollInterval);
                  //console.log('1 schedule generated in minutes:', duration.as('minutes'));
                  this._pollingFirstAttempt = false;

                  // There is a chance that the user opens the project while in the middle of the status
                  // change from the polling. Reset the poll interval and try again.
                  if (this._statusPollInterval < 1000) {
                     this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
                     this._pollingFirstAttempt = true;
                  }
               }
            }

            if (!status.isProjectInitialising && status.errorMessage.length === 0) {
               // fire event to open the project coordinated in the application store
               project.neverOpened = status.loadingProjectNeverOpened;
               this.projectOpen.next(project);
               setTimeout(() => {
                  this._router.navigate(['dashboard']);
               }, 50);
            }
            this._projectStatus.next(status);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // Starts polling to monitor the Project status. Used when Callpoint location is changed causing recomputation of
   // the Caller's drivetimes.
   public monitorProjectStatus(): void {
      this._pollProjectStatusSubscription.unsubscribe();

      // We want to know how long the schedule generation takes, record the start time.
      this._scheduleStartTime = moment();

      // Reset the flag, only capture the schedule generation time once.
      this._pollingFirstAttempt = true;

      // Reset polling interval for each new create project.
      this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;

      // clear status status of nothing generated.
      let startStatus: ProjectStatus = {
         callerCount: -1,
         drivetimeCount: 0,
         scheduleCount: 0,
         scheduleMetricCount: 0,
         errorMessage: '',
         isProjectInitialising: false,
         projectInUse: false,
         projectInUseByUser: '',
         travelModelValid: false,
         exceedsConcurrentTravelModelAccess: false,
         travelModelUsedBy: '',
         projectId: 0,
         pendingProjectDrivetimesCount: 0,
         pendingProjectOptimisationsCount: 0,
         queuedProjectDrivetimesCount: 0,
         queuedProjectOptimisationsCount: 0,
         totalQueuedDrivetimesCount: 0,
         totalQueuedOptimisationsCount: 0,
         totalPendingDrivetimesCount: 0,
         totalPendingOptimisationsCount: 0,
         loadingProjectNeverOpened: false,
         travelModelUsed: '',
         projectStatusDetail: [],
      };
      this._projectStatus.next(startStatus);

      // Use of concatMap with takeWhile explanation here:
      // https://stackoverflow.com/questions/44641246/rxjs-takewhile-but-include-the-last-value
      this._pollProjectStatusSubscription = this.pollProjectStatus(
         this.selectedProject.projectId
      ).pipe(
         concatMap((status: ProjectStatus) =>
            // 'takeWhile' on its own will not return the element matching this predicate, so this rather obtuse
            // concatMap is required to achieve the equivalent of 'takeWhileInclusive'.
            (status.drivetimeCount === status.callerCount &&
               status.isProjectInitialising === false
               && status.queuedProjectOptimisationsCount == 0
               && status.pendingProjectOptimisationsCount == 0) ||
               status.errorMessage.length > 0
               ? of(status, null)
               : of(status)
         ),
         takeWhile((status: ProjectStatus) => status != null))
         .subscribe((status: ProjectStatus) => {
            //console.log(status);
            //console.log('monitorProjectStatus - polling for', this._statusPollInterval);

            // Only adjust the polling interval when we have first caller scheduled.
            if (this._pollingFirstAttempt) {
               // Compare the previous schedule count with the current schedule count, if they are not the same
               // then that means the schedule has generated.
               if (
                  this._projectStatus.getValue().drivetimeCount !==
                  status.drivetimeCount
               ) {
                  this._statusPollInterval = moment().diff(
                     this._scheduleStartTime
                  );
                  //var duration = moment.duration(this._statusPollInterval);
                  //console.log('1 schedule generated in minutes:', duration.as('minutes'));
                  this._pollingFirstAttempt = false;

                  // There is a chance that the user opens the project while in the middle of the status
                  // change from the polling. Reset the poll interval and try again.
                  if (this._statusPollInterval < 1000) {
                     //console.log('Resetting interval to default.');

                     this._statusPollInterval =
                        CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
                     this._pollingFirstAttempt = true;
                  }
               }
            }
            this._projectStatus.next(status);
         });
   }

   public loadProjectFolders() {
      this._projectService.getAllProjectFolders().subscribe(
         (folders: string[]) => {
            this._projectFolders.next(folders);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // get the project object and trigger the open event
   public loadProjectAndOpen(projectId: number, userId: number) {
      // need to get the project data
      this._projectService.getProject(projectId).subscribe(
         (project: Project) => {
            project.neverOpened = false;
            this.projectOpen.next(project);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // return the project object, use the set selected project to load the project calendar , update the project modified date
   public loadProject(projectId: number, userId: number) {
      // need to get the project data
      this._projectService.getProject(projectId).subscribe(
         (project: Project) => {
            // set the selected project this function will take care of the project calendar and the project modified date
            this.setSelectedProject(project, userId);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // load the calendar events data, bank holidays team meetings extra
   // this is primarily used in the set selected project
   // it is used in the client to refresh just the project calendar in the control off the client is reverting the data
   // if it is to be used in some other function consider using the set selected project function might be best
   public loadProjectCalendar(projectId: number) {
      // need to get the project data
      this._projectService.getProjectEvents(projectId).subscribe(
         (projectEvents: Event[]) => {
            this._projectEvents.next(projectEvents);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public renameProject(projectId: number, newProjectName: string) {
      this._spinnerStore.showSpinner();

      //console.log("Project renamed");
      // get the project
      let renameproject = this.projects.find((p) => p.projectId == projectId);

      renameproject.name = newProjectName;

      this._projectService.updateProject(renameproject).subscribe(
         (project: boolean) => {
            // refrsh the project list
            this.refreshProjectData();
            this._spinnerStore.hideSpinner();
         },
         (error) => {
            this._spinnerStore.hideSpinner();
            this._errorHandler.handleError(error);
         }
      );
   }

   public copyProject(
      projectId: number,
      newProjectName: string,
      userId: number
   ) {
      this._spinnerStore.showSpinner();
      this._projectService
         .copyProject(projectId, newProjectName, userId)
         .subscribe(
            (project: boolean) => {
               // refrsh the project list
               this.refreshProjectData();
               this._spinnerStore.hideSpinner();
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public rollProject(
      projectId: number,
      newProjectName: string,
      userId: number,
      rollForwardDate: Date
   ) {
      this._spinnerStore.showSpinner();
      this._projectService
         .rollProject(projectId, newProjectName, userId, rollForwardDate)
         .subscribe(
            (newProjectId: number) => {
               // refrsh the project list
               this.refreshProjectData();
               this._spinnerStore.hideSpinner();
               if (this.loadProjectWithStatusCheck) {
                  setTimeout(() => {
                     this.isProjectAccessable(newProjectId);
                  }, 200);
               } else {
                  this.loadProjectAndOpen(newProjectId, userId);
                  this.eventsUpdated.next();
               }
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public updateCallpointSettings(callpointSettings: CallpointSettings) {
      this._projectService
         .updateCallpointSettings(
            this.selectedProject.projectId,
            callpointSettings
         )
         .subscribe(
            (res: boolean) => {
               if (res) {
                  this.selectedProject.callpointSettings = callpointSettings;
                  this._selectedProject.next(this.selectedProject);
                  this.refreshProjectData();
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   public updateCallerSettings(callerSettings: CallerSettings) {
      this._projectService
         .updateCallerSettings(this.selectedProject.projectId, callerSettings)
         .subscribe(
            (res: boolean) => {
               if (res) {
                  this.selectedProject.callerSettings = callerSettings;
                  this._selectedProject.next(this.selectedProject);
                  this.refreshProjectData();
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // Used by the Create New wizard to update the caller settings without emitting any events.
   public updateCreateNewProjectCallerSettings(
      projectId: number,
      callerSettings: CallerSettings
   ) {
      this._projectService
         .updateCallerSettings(projectId, callerSettings)
         .subscribe(
            (res: boolean) => {
               if (res) {
                  let updatedProject = this.projects.find(
                     (pr) => pr.projectId === projectId
                  );
                  if (updatedProject) {
                     updatedProject.callerSettings = callerSettings;
                  }
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // Used by the Create New wizard to update the callpoints settings without emitting any events.
   public updateCreateNewProjectEventsSettings(
      projectId: number,
      events: Event[]
   ) {
      this._projectService
         .createUpdateProjectCalendar(projectId, events)
         .subscribe(
            (res: boolean) => {
               if (res) {
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // Used by the Create New wizard to update the events without emitting any events.
   public updateCreateNewProjectCallpointSettings(
      projectId: number,
      callpointSettings: CallpointSettings
   ) {
      this._projectService
         .updateCallpointSettings(projectId, callpointSettings)
         .subscribe(
            (res: boolean) => {
               if (res) {
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // Used by the Create New wizard to update the project settings without emitting any events.
   public updateCreateNewProjectSettingSettings(
      projectId: number,
      projectSettings: ProjectSettings
   ) {
      this._projectService
         .updateProjectSettings(projectId, projectSettings)
         .subscribe(
            (res: boolean) => {
               if (res) {
               }
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // Update the project, if emitSelectedProject is true then broadcast the
   // updated project.
   public updateProject(project: Project, emitSelectedProject: boolean) {
      //console.log("Project store updating project");

      this._projectService.updateProject(project).subscribe(
         (res: boolean) => {
            if (res) {
               if (emitSelectedProject) {
                  this._selectedProject.next(project);
               }
               this.refreshProjectData();
            } else {
               this._errorHandler.handleError(
                  'Failed to update project with result: ' + res
               );
            }
         },
         (error) => this._errorHandler.handleError(error)
      );
   }

   // returns the project list and reloads the distinct list of project named folders
   public refreshProjectData() {
      // need to get the project data
      this._projectService.getAllProjects().subscribe(
         (projects: Project[]) => {
            this._projects.next(projects);
            this.loadProjectFolders();
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }
}
