<div class="container">

  <div class="expired-position">
    <div class="login-callsmart-logo">
      <img src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
    </div>

    <div class="login-container">
      <div class="form-box">
        <p>Your password is at least 90 days old. You must change your password now to login to Callsmart.</p>
        <br />
        <form (ngSubmit)="onSignin(form)" #form="ngForm" autocomplete="off">
          <div class="form-group row">
            <label class="control-label">Enter Email Address</label>
          </div>
          <div class="form-group row">
            <input required type="email" id="email" name="email" placeholder="Email" 
              pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" #loginEmail="ngModel"
              [(ngModel)]="email" class="form-control cs-form-control">
            <div [hidden]="loginEmail.valid || loginEmail.untouched">
              <div *ngIf="loginEmail.errors && loginEmail.errors.required">
                Email is required
              </div>
              <div *ngIf="loginEmail.errors && loginEmail.errors.pattern">
                Email is invalid
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="control-label">Enter Current Password</label>
          </div>
          <div class="form-group row">
            <input required type="password" id="oldPassword" name="oldPassword" placeholder="Current password" 
                 pattern="^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\\^&\\*])(.{12,})"
                 [(ngModel)]="oldPassword" class="form-control cs-form-control" #loginPassword="ngModel"> 
              <span *ngIf="loginPassword.touched && loginPassword.errors">
                <label *ngIf="loginPassword.errors.required">Current password is required</label>
                <label *ngIf="loginPassword.errors.pattern">Invalid password</label>
             </span>
         </div>

          <div class="form-group row">
            <label class="control-label">Enter New Password</label>
          </div>
          <div class="form-group row">
            <input required type="password" id="newPassword" name="newPassword" placeholder="New password" ngModel
              (change)="analyze(form.value.newPassword)" class="form-control cs-form-control">
          </div>

          <div class="form-group row">
            <label class="control-label">Re-enter New Password</label>
          </div>
          <div class="form-group row">
            <input required type="password" id="newPassword2" name="newPassword2" placeholder="Confirm new password"
              ngModel class="form-control cs-form-control">
          </div>

          <div class="form-group row">
            <div class="password-strength" [ngStyle]="{'background-color': elementColor}">{{passwordStrengthText}}</div>
          </div>

          <div class="row">
            <div class="alert alert-danger fade in" *ngIf="showError">
              {{errorMessage}}
            </div>
          </div>


          <div class="form-group row">
            <button class="btn cs-btn-primary-inverse" style="float:right; width:150px" type="submit"
              [disabled]="!form.valid || passwordStrengthText != 'Strong'">Save & Log in</button>
          </div>

        </form>
      </div>
      <div style="position: absolute;font-size: 16px;left: 4px;margin-top: 34px;">
        <a style="cursor: pointer;" [routerLink]="['/legal']">
          Legal
        </a>
      </div>

      <div class="login-caci-logo">
        <img class="caci-logo" src="./assets/icons/CACI_Logo_White.png" alt="CACI">
      </div>
    </div>
  </div>
</div>
