import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';
import * as moment from 'moment/moment';

/**
 * Custom converter used by the json2typescript mapping library to
 * map null values to undefined. See caller-settings.ts for usage example.
 */
@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
   serialize(value: Date): any {
       return value;
   }
   deserialize(value: string): Date {
      let auxDate : moment.Moment = moment(value);
      if(auxDate.isValid()) {
         return auxDate.toDate();
      }
      else {
         return null;
      }
   }
}
