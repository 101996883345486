import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SettingsService } from 'app/services/settings.service';
import { DayCombination } from 'app/models/settings/day-combination';
import { ErrorHandlerService } from 'app/services/error-handler.service';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class DefaultDayCombinationsStore {

   private _allDayCombinations: BehaviorSubject<ReadonlyArray<DayCombination>> = new BehaviorSubject<ReadonlyArray<DayCombination>>(null);
   public allDayCombinations$: Observable<ReadonlyArray<DayCombination>> = this._allDayCombinations.asObservable();

   private _allDayCombinationsWithWorkingDays: BehaviorSubject<ReadonlyArray<DayCombination>> = new BehaviorSubject<ReadonlyArray<DayCombination>>(null);
   public allDayCombinationsWithWorkingDays$: Observable<ReadonlyArray<DayCombination>> = this._allDayCombinationsWithWorkingDays.asObservable();

   private _recommendedCombinations: BehaviorSubject<ReadonlyArray<string>> = new BehaviorSubject<ReadonlyArray<string>>(null);
   public recommendedCombinations$: Observable<ReadonlyArray<string>> = this._recommendedCombinations.asObservable();

   private _currentProjectWorkingActiveDays: boolean[];

   constructor(private _settingsService: SettingsService,
      private _errorHandler: ErrorHandlerService) { }

   public loadAllDayCombinations() {
      this._settingsService.getAllDayCombinations()
         .subscribe(
            (combinations: DayCombination[]) => {
               this._allDayCombinations.next(combinations);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }


   public loadRecommendedDayCombinations() {
      this._settingsService.getRecommendedDayCombinations()
         .subscribe(
            (combinations: string[]) => {
               this._recommendedCombinations.next(combinations);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );

   }
}
