<!--component html goes here -->
<h6 *ngIf="callerSettingsModel == null">Loading...</h6>
<div *ngIf="callerSettingsModel">
   <form #callerSettingsForm="ngForm" class="form-horizontal" autocomplete="off">
      <div class="panel">
         <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
            <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
               <div *ngIf="!showOverrideButtons && displayHeading">
                  <h3>Caller defaults</h3>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.workingHoursMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.workingHoursMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="workingHoursMultiEdit" [(ngModel)]="callerSettingsModel.workingHoursMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Contracted working days and hours
                     </h6>
                     <p>The days and hours callers are contracted to work.</p>
                     <!-- This panel is greyed out based on the workingHoursMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple values set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.WorkingHours); else workingHoursMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.workingHoursMultiEdit}">

                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="workingHoursOverrideSwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.workingHoursDisabled"
                                 inverseColours="true" (onChange)="onWorkingDayOverrideSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the workingHoursOverrideSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.workingHoursDisabled}">

                           <div class="form-group">
                              <div class="col-xs-12">
                                 <callsmart-inputswitch name="sameOrDifferentHoursPerDay" onLabel="Same hours per day" offLabel="Different hours per day"
                                    [(ngModel)]="callerSettingsModel.sameWorkingHoursAllDays" inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                                 </callsmart-inputswitch>
                              </div>
                           </div>
                           <div class="row">
                              <div *ngIf="callerSettingsModel.sameWorkingHoursAllDays">
                                 <div style="display: flex;flex-direction: row;align-items: center;">
                                    <div class="panel-body form-group">
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[0] = !callerSettingsModel.workingDayActive[0]; workingDayActiveChanged(0)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[0] === false, 'cs-btn-amber': amberDays[0] === true,'active': callerSettingsModel.workingDayActive[0] === false}" class="btn">Monday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[1] = !callerSettingsModel.workingDayActive[1]; workingDayActiveChanged(1)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[1] === false, 'cs-btn-amber': amberDays[1] === true,'active': callerSettingsModel.workingDayActive[1] === false}" class="btn">Tuesday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[2] = !callerSettingsModel.workingDayActive[2]; workingDayActiveChanged(2)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[2] === false, 'cs-btn-amber': amberDays[2] === true,'active': callerSettingsModel.workingDayActive[2] === false}" class="btn">Wednesday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[3] = !callerSettingsModel.workingDayActive[3]; workingDayActiveChanged(3)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[3] === false, 'cs-btn-amber': amberDays[3] === true,'active': callerSettingsModel.workingDayActive[3] === false}" class="btn">Thursday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[4] = !callerSettingsModel.workingDayActive[4]; workingDayActiveChanged(4)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[4] === false, 'cs-btn-amber': amberDays[4] === true,'active': callerSettingsModel.workingDayActive[4] === false}" class="btn">Friday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[5] = !callerSettingsModel.workingDayActive[5]; workingDayActiveChanged(5)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[5] === false, 'cs-btn-amber': amberDays[5] === true,'active': callerSettingsModel.workingDayActive[5] === false}" class="btn">Saturday</button>
                                       <button type="button" (click)="callerSettingsModel.workingDayActive[6] = !callerSettingsModel.workingDayActive[6]; workingDayActiveChanged(6)"
                                          [ngClass]="{'cs-btn-tertiary': amberDays[6] === false, 'cs-btn-amber': amberDays[6] === true,'active': callerSettingsModel.workingDayActive[6] === false}" class="btn">Sunday</button>
                                    </div>
                                    <label style="margin-left: 15px" *ngIf="!isAnyWorkingDaySelected">At least 1 day must be available</label>
                                 </div>

                                 <div class="col-md-6">
                                    <callsmart-date-range-slider name="contractedHours" #contractedHours="ngModel" 
                                       [stepInMinutes]="1" 
                                       [boundMinDate]="boundsMin" 
                                       [boundMaxDate]="boundsMax"
                                       [(ngModel)]="callerSettingsModel.contractedWorkingHoursWeek"
                                       (userValueChanged)="onCheckLunchPeriod($event)">
                                    </callsmart-date-range-slider>
                                 </div>
                              </div>
                              <!-- Flex container -->
                              <!-- Left flex column.
                                    Using a flex column here as Bootstrap 3 doesn't seem to have any way to create a column that
                                    sizes to fit variable width content, (which these buttons are as their labels will be different
                                    lengths in different languages).
                                    The buttons must be wrapped n a form-group otherwise they wont inherit the correct vertical
                                    spacing, then because they are in a form group they must be given column specifier to counteract
                                    the default -1 column offset Bootstrap uses, otherwise they won't have the correct spacing on the
                                    left. -->
                              <!-- Show if setting hours for each day -->
                              <div *ngIf="!callerSettingsModel.sameWorkingHoursAllDays" style="display:flex">
                                    <div style="display:flex;flex-direction:column;justify-content:space-around;">
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[0] = !callerSettingsModel.workingDayActive[0]; workingDayActiveChanged(0)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[0] === false, 'cs-btn-amber': amberDays[0] === true,'active': callerSettingsModel.workingDayActive[0] === false}" class="btn form-control col-xs-offset-1"
                                             >Monday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[1] = !callerSettingsModel.workingDayActive[1]; workingDayActiveChanged(1)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[1] === false, 'cs-btn-amber': amberDays[1] === true,'active': callerSettingsModel.workingDayActive[1] === false}" class="btn form-control col-xs-offset-1"
                                             >Tuesday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[2] = !callerSettingsModel.workingDayActive[2]; workingDayActiveChanged(2)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[2] === false, 'cs-btn-amber': amberDays[2] === true,'active': callerSettingsModel.workingDayActive[2] === false}" class="btn form-control col-xs-offset-1"
                                             >Wednesday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[3] = !callerSettingsModel.workingDayActive[3]; workingDayActiveChanged(3)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[3] === false, 'cs-btn-amber': amberDays[3] === true,'active': callerSettingsModel.workingDayActive[3] === false}" class="btn form-control col-xs-offset-1"
                                             >Thursday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[4] = !callerSettingsModel.workingDayActive[4]; workingDayActiveChanged(4)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[4] === false, 'cs-btn-amber': amberDays[4] === true,'active': callerSettingsModel.workingDayActive[4] === false}" class="btn form-control col-xs-offset-1"
                                             >Friday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[5] = !callerSettingsModel.workingDayActive[5]; workingDayActiveChanged(5)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[5] === false, 'cs-btn-amber': amberDays[5] === true,'active': callerSettingsModel.workingDayActive[5] === false}" class="btn form-control col-xs-offset-1"
                                             >Saturday</button>
                                       </div>
                                       <div class="form-group">
                                          <button type="button" (click)="callerSettingsModel.workingDayActive[6] = !callerSettingsModel.workingDayActive[6]; workingDayActiveChanged(6)"
                                             [ngClass]="{'cs-btn-tertiary': amberDays[6] === false, 'cs-btn-amber': amberDays[6] === true,'active': callerSettingsModel.workingDayActive[6] === false}" class="btn form-control col-xs-offset-1"
                                             >Sunday</button>
                                       </div>
                                    </div>

                                 <!-- Right flex column.
                                    This right flex column expands to fill the remaining space and Bootstrap rows are then used to
                                    size the date range sliders so that they match the sizes of the other controls on the form.-->
                                    <!-- <div style="display:flex;flex-direction:row;"> -->
                                    <div style="display:flex;flex-direction:column;justify-content:space-around;flex-grow:1;">
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursMonday" #contractedWorkingHoursMonday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[0]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursMonday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursTuesday" #contractedWorkingHoursTuesday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[1]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursTuesday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursWednesday" #contractedWorkingHoursWednesday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[2]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursWednesday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursThursday" #contractedWorkingHoursThursday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[3]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursThursday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursFriday" #contractedWorkingHoursFriday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[4]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursFriday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursSaturday" #contractedWorkingHoursSaturday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[5]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursSaturday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                       <div class="form-group">
                                          <div class="col-xs-offset-1 col-xs-7">
                                                <callsmart-date-range-slider name="contractedWorkingHoursSunday" #contractedWorkingHoursSunday="ngModel" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                                [disabled]="!callerSettingsModel.workingDayActive[6]" [(ngModel)]="callerSettingsModel.contractedWorkingHoursSunday" (userValueChanged)="onCheckLunchPeriod($event)">
                                             </callsmart-date-range-slider>
                                          </div>
                                       </div>
                                    </div>
                                    <div style="display:flex;flex-direction:column;justify-content: center; width:250px">
                                          <label style="margin-right: 50px" *ngIf="!isAnyWorkingDaySelected">At least 1 day must be available</label>
                                    </div>

                                    <!-- </div> -->
                              </div>
                              <!-- flex container -->
                           </div>
                           <!-- <div class="row">
                                 <div class="col-12-sm" style="text-align: left; margin-bottom: 10px;">
                                    <label *ngIf="!isAnyWorkingDaySelected">At least 1 day must be available</label>
                                 </div>
                              </div> -->
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #workingHoursMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>

                  </div>
                  <!-- panel-body -->
               </div>
               <!-- panel -->

               <!-- PSN-2361 Commented out for now. Do not DELETE, required for future release. -->
               <!-- <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.workingHoursFlexibilityMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.workingHoursFlexibilityMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="workingHoursFlexibilityMultiEdit" [(ngModel)]="callerSettingsModel.workingHoursFlexibilityMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Working hours flexibility
                     </h6>
                     <p>The level of flexibility in the working hours to allow for days to slightly over-run.</p>
                     <div *ngIf="canShowInputs(mySettingSection.WorkingHoursFlexibility); else workingHoursFlexibilityMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.workingHoursFlexibilityMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="workingHoursFlexibilitySwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.workingHoursFlexibilityDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.workingHoursFlexibilityDisabled}">
                           <div class="form-group">
                              <div class="col-sm-1">
                                    <input name="flexibilityInWorkingHours" #flexibilityInWorkingHours="ngModel" class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.workingHoursFlexibility"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57" size="1"
                                       (ngModelChange)="onWorkingHoursFlexibilityInputValueChanged($event);">
                              </div>
                              <label for="flexibilityInWorkingHours" class="control-label cs-label-right">Minutes</label>
                              <div class="col-md-12">
                                 <callsmart-range-bar [fromDate]="callerSettingsModel?.contractedWorkingHoursWeek[0]" [toDate]="callerSettingsModel?.contractedWorkingHoursWeek[1]"
                                    [adjustmentValue]="callerSettingsModel?.workingHoursFlexibility">
                                 </callsmart-range-bar>
                              </div>
                           </div>
                        </div>
                     </div>
                     <ng-template #workingHoursFlexibilityMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div> -->

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.lunchWindowSwitchMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.lunchWindowSwitchMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="lunchWindowSwitchMultiEdit" [(ngModel)]="callerSettingsModel.lunchWindowSwitchMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Lunch window
                     </h6>
                     <p>The window that the lunch break can occur in. The window cannot be smaller than the lunch break. </p>
                     <!-- This panel is greyed out based on the lunchWindowSwitchMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.LunchWindow); else lunchWindowSwitchMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.lunchWindowSwitchMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="lunchWindowSwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.lunchWindowSwitchDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the lunchWindowSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.lunchWindowSwitchDisabled}">
                           <div>
                              <callsmart-inputswitch name="floatingLunch" [(ngModel)]="floatingLunch" onLabel="None" offLabel="Floating" 
                                 inverseColours="true" (onChange)="onLunchPeriodInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                           <div style="display: flex; align-items: center; justify-content: left;">
                              <div class="col-sm-6" style="padding-top:10px; padding-left:10px">
                                 <callsmart-date-range-slider name="lunchPeriod" [stepInMinutes]="1" [boundMinDate]="boundsMin" [boundMaxDate]="boundsMax"
                                    [(ngModel)]="callerSettingsModel.lunchPeriod" [disabled]="floatingLunch" (userValueChanged)="onCheckLunchPeriod($event)">
                                 </callsmart-date-range-slider>
                              </div>
                              <div class="col-sm-6">
                                 <div>
                                    <label *ngIf="!isLunchStartTimeValid" style="margin-left:10px; margin-bottom:0px">Incorrect start lunch window time. Please, check the contracted working hours</label>
                                 </div>
                                 <div>
                                    <label *ngIf="!isLunchEndTimeValid" style="margin-left:10px; margin-bottom:0px">Incorrect end lunch window time. Please, check the contracted working hours</label>
                                 </div>
                              </div>
                           </div>
                           <div style="display: flex;flex-direction: row;align-items: center;">
                              <label for="lunchDuration" class="control-label" style="padding: 0px; margin-right:10px">Lunch Duration:</label>
                              <div>
                                 <input name="lunchDurationInput" #lunchDurationInput="ngModel" required class="form-control cs-form-control" style="width: 50px"
                                    type="number" (input)="onLunchDurationInputValueChanged($event);"
                                    [(ngModel)]="callerSettingsModel.lunchDuration" value="60" size="1" disabled="{{floatingLunch}}"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                              </div>
                              <label for="lunchDurationInput" class="control-label" style="padding: 0px; margin-left:10px">Minutes</label>
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                 <span *ngIf="lunchDurationInput.touched && lunchDurationInput.errors">
                                    <label *ngIf="lunchDurationInput.errors.required" style="padding: 0px;margin-bottom: 0px; margin-left:10px">Please insert Lunch Duration</label>
                                    <!-- <label *ngIf="lunchDurationInput.errors.pattern" style="padding: 0px;margin-bottom: 0px; margin-left:10px">Please insert a value between 1 and 999</label> -->
                                 </span>
                                 <!-- If any of the previous errors are displayed then this error is not display -->
                                 <label *ngIf="!isLunchDurationValid && isLunchDurationFormatValid" style="padding: 0px;margin-bottom: 0px; margin-left:10px">Please insert a value between From and To in the lunch window time ({{lunchWindowDuration}} minutes)</label>
                              </div>
                           </div>
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #lunchWindowSwitchMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.maxCommuteTimeMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.maxCommuteTimeMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="maxCommuteTimeMultiEdit" [(ngModel)]="callerSettingsModel.maxCommuteTimeMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Maximum one-way commute time
                     </h6>
                     <p>Maximum one-way commuting time outside of contracted hours - any additional commuting time will be scheduled
                        within contracted hours.</p>
                     <!-- This panel is greyed out based on the maxCommuteTimeMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.MaxCommuteTime); else maxCommuteTimeMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.maxCommuteTimeMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="maxCommuteTimeSwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.maxCommuteTimeDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the maxCommuteTimeSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.maxCommuteTimeDisabled}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                              <input name="maxOnewayCommuteTimeInput" #maxOnewayCommuteTimeInput="ngModel" required class="form-control cs-form-control"
                                 style="width: 50px" [(ngModel)]="callerSettingsModel.maxOneWayCommuteTime" size="1" disabled="{{callerSettingsModel.noMaximum}}"
                                 type="number" (input)="onMaxOnewayCommuteTimeInputValueChanged($event);"
                                 pattern="^([3-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-3][0-9]{2}|14[0-3][0-9]|1440)$"
                                 value="60" onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                              <label for="maxOnewayCommuteTimeInput" class="control-label" style="padding: 0px; margin-left:10px; margin-right:20px">Minutes</label>
                              <div class="form-group-chkbox" style="margin-left: 10px;">
                                 <p-checkbox name="noMaximum" [(ngModel)]="callerSettingsModel.noMaximum" binary="true" label="No maximum"
                                             (onChange)="onAnyCheckboxChanged($event)">
                                 </p-checkbox>
                              </div>
                           </div>

                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="maxOnewayCommuteTimeInput.touched && maxOnewayCommuteTimeInput.errors">
                                 <label *ngIf="maxOnewayCommuteTimeInput.errors.required" style="padding: 0px;margin-bottom: 0px">Please insert maximum one-way commute time</label>
                                 <label *ngIf="maxOnewayCommuteTimeInput.errors.pattern" style="padding: 0px;margin-bottom: 0px">Please insert a value between 30 and 1440</label>
                              </span>
                              <!-- If any of the previous errors are displayed then this error is not display -->
                              <label *ngIf="maxOnewayCommuteTimeInput.touched && !isMaxOnewayCommuteTimeValid && isMaxOnewayCommuteTimeFormatValid && !callerSettingsModel.noMaximum"
                                    style="padding: 0px;margin-bottom: 0px">
                                 Please insert a value to fit the time before and after the contracted hours
                              </label>
                           </div>

                           <div *ngIf="!callerSettingsModel.noMaximum" class="col-md-12">
                              <callsmart-range-bar [fromDate]="callerSettingsModel.contractedWorkingHoursWeek[0]" [toDate]="callerSettingsModel.contractedWorkingHoursWeek[1]"
                                 [adjustmentValue]="callerSettingsModel.maxOneWayCommuteTime" [enableCommuteLabel]="true">
                              </callsmart-range-bar>
                           </div>
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #maxCommuteTimeMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.maxVisitsPerDayMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.maxVisitsPerDayMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="maxVisitsPerDayMultiEdit" [(ngModel)]="callerSettingsModel.maxVisitsPerDayMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Maximum visits allowed per day
                     </h6>
                     <!-- This panel is greyed out based on the maxVisitsPerDayMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.MaxVisitsPerDay); else maxVisitsPerDayMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.maxVisitsPerDayMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="maxVisitsPerDaySwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.maxVisitsPerDayDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the maxVisitsPerDaySwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.maxVisitsPerDayDisabled}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                              <input name="maxVisitsPerDayInput" #maxVisitsPerDayInput="ngModel" required [(ngModel)]="callerSettingsModel.maxVisitsPerDay"
                                 class="form-control cs-form-control" style="width: 50px" size="1" disabled="{{callerSettingsModel.visitsPerDayNoMaximum}}"
                                 type="number" pattern="^([1-9][0-9]{0,2}|999)$" (input)="onMaxVisitsPerDayInputValueChanged($event);"
                                 value="60" onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                              <div class="form-group-chkbox" style="margin-left: 40px">
                                 <p-checkbox name="visitsPerDayNoMaximum" [(ngModel)]="callerSettingsModel.visitsPerDayNoMaximum" binary="true"
                                             label="No maximum" (onChange)="onAnyCheckboxChanged($event)">
                                 </p-checkbox>
                              </div>
                           </div>
                           <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                              <span *ngIf="maxVisitsPerDayInput.touched && maxVisitsPerDayInput.errors">
                                 <label *ngIf="maxVisitsPerDayInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert Maximum visits allowed per day</label>
                                 <label *ngIf="maxVisitsPerDayInput.errors.pattern" style="padding: 0px;margin-bottom: 0px;">Please insert a value between 1 and 999</label>
                              </span>
                           </div>
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #maxVisitsPerDayMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="commuteWorkHrsToFirstVisitMultiEdit" [(ngModel)]="callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Commute during working hours only
                     </h6>
                     <!-- This panel is greyed out based on the commuteWorkHrsToFirstVisitMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.CommuteDuringWorkingHours); else commuteWorkHrsToFirstVisitMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="commuteWorkHrsToFirstVisitSwitch" onLabel="Default" offLabel="Override" [(ngModel)]="callerSettingsModel.commuteWorkHrsToFirstVisitDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the commuteWorkHrsToFirstVisitSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.commuteWorkHrsToFirstVisitDisabled}">
                           <div>
                              <div class="form-group">
                                 <label for="commuteWorkHrsToFirstVisit" class="col-sm-1 control-label" style="padding-right: 41px; padding-top:10px;">To first visit:</label>
                                 <callsmart-inputswitch name="commuteWorkHrsToFirstVisit" [(ngModel)]="callerSettingsModel.commuteWorkHrsToFirstVisit" onLabel="Yes"
                                    offLabel="No" (onChange)="onAnyInputSwitchChanged($event)">
                                 </callsmart-inputswitch>
                              </div>

                              <div class="form-group">
                                 <label for="commuteWorkHrsFormLastVisit" class="col-sm-1 control-label" style="padding-right: 28px; padding-top:10px;">From last visit:</label>
                                 <callsmart-inputswitch name="commuteWorkHrsFormLastVisit" [(ngModel)]="callerSettingsModel.commuteWorkHrsFormLastVisit" onLabel="Yes"
                                    offLabel="No" (onChange)="onAnyInputSwitchChanged($event)"> 
                                 </callsmart-inputswitch>
                              </div>
                           </div>
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #commuteWorkHrsToFirstVisitMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.outOfPhaseVisitsMultiEdit,
                                    'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.outOfPhaseVisitsMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="outOfPhaseVisitsMultiEdit"
                           [(ngModel)]="callerSettingsModel.outOfPhaseVisitsMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Out of phase visits
                     </h6>
                     <p>The number of days +/- a multiple frequency visit could be scheduled out of phase.</p>
                     <!-- This panel is greyed out based on the outOfPhaseVisitsMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.OutOfPhaseVisits); else outOfPhaseVisitsMultiValueMsg"
                        [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.outOfPhaseVisitsMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="outOfPhaseVisitsSwitch" onLabel="Default" offLabel="Override"
                                 [(ngModel)]="callerSettingsModel.outOfPhaseVisitsDisabled"
                                 inverseColours="true" (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>

                        <!-- This panel is greyed out based on the outOfPhaseVisitsSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.outOfPhaseVisitsDisabled}">
                           <div class="form-group" style="display: flex; flex-direction: row; align-items: center; justify-content: left;">

                                 <div style="padding-top: 8px; margin-right:100px; margin-left:15px">
                                    <label class="cs-radio custom-color-green">
                                       <input type="radio" name="outOfPhaseVisits" value="0" [(ngModel)]="outOfPhaseVisitsRadioSetting">
                                       <!-- <i></i> is used in CSS hack to get the label to align properly -->
                                       <span>
                                          <i></i>None</span>
                                       <!-- TODO: What is the point of '<i></i>'? -->
                                    </label>
                                 </div>

                                 <div  style="padding-top: 8px; margin-right:100px">
                                    <label class="cs-radio custom-color-green">
                                       <input type="radio" name="outOfPhaseVisits" value="1" [(ngModel)]="outOfPhaseVisitsRadioSetting">
                                       <span>
                                          <i></i>1 day either side</span>
                                    </label>
                                 </div>

                                 <div style="padding-top: 8px">
                                    <label class="cs-radio custom-color-green">
                                       <div style="display: flex; flex-direction: row; align-items: center; justify-content: left">
                                          <input type="radio" name="outOfPhaseVisits" value="N" [(ngModel)]="outOfPhaseVisitsRadioSetting">
                                          <span>
                                             <i></i>
                                          </span>

                                       <!-- <div style="display: inline-flex"> -->
                                          <input name="outOfPhaseVisitDaysInput" #outOfPhaseVisitDaysInput="ngModel" required
                                                class="form-control cs-form-control"
                                                [(ngModel)]="outOfPhaseVisitsNDays" size="1" style="width: 50px;"
                                                type="number" (input)="onOutOfPhaseInputValueChanged($event);"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                                                <label class="control-label"  style="padding: 0px; margin-left:10px">days either side</label>

                                          <div style="display: flex;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                                             <span *ngIf="outOfPhaseVisitDaysInput.touched && outOfPhaseVisitDaysInput.errors && outOfPhaseVisitsRadioSetting=='N'">
                                                <label *ngIf="outOfPhaseVisitDaysInput.errors.required" style="padding: 0px;margin-bottom: 0px; margin-left: 15px">Please insert Out of phase visits</label>
                                                <!-- <label *ngIf="outOfPhaseVisitDaysInput.errors.pattern" style="padding: 0px;margin-bottom: 0px; margin-left: 15px">Please insert a value between 2 and 99</label> -->
                                             </span>
                                             <!-- If any of the previous errors are displayed then this error is not display -->
                                             <label *ngIf="outOfPhaseVisitDaysInput.touched && !isOutOfPhaseVisitDaysValid && isOutOfPhaseVisitDaysFormatValid && outOfPhaseVisitsRadioSetting=='N'"
                                                      style="padding: 0px;margin-bottom: 0px; margin-left: 15px; margin-right: 5px; max-width: 250px">
                                                Please insert a value smaller than the number of calendar days in the call cycle length ({{projectCycleLength * 7}} days)
                                             </label>
                                          </div>
                                       </div>
                                    </label>
                                 </div>



                              <!-- This section commented out because the Scheduler does not currently support a
                                 'within the same week' phasing option. -->

                              <!-- A linebreak is not needed to make a div appear on a new line. The actual effect of this
                                 break is to increase the spacing between the rows of controls. I do not think it is a good
                                 idea to use breaks to control spacing, CSS has better techniques for handling spacing than
                                 using line breaks. -->

                              <!-- <br/>
                                 <div class="row">

                                    <div class="col-md-3" style="padding-top: 6px;">
                                       <label class="cs-radio custom-color-green">
                                          <input type="radio" name="outOfPhaseVisits"
                                             value="anyday"
                                             [(ngModel)]="callerSettingsModel.outOfPhaseVisits">
                                             <span><i></i>Any day within its week</span>
                                       </label>
                                    </div>

                                    <div class="col-sm-3">
                                       <label class="cs-radio custom-color-green">
                                          <input type="radio" name="outOfPhaseVisits"
                                             value="ndays"
                                             [(ngModel)]="callerSettingsModel.outOfPhaseVisits">
                                          <span><i></i></span>

                                          <div style="display: inline-flex">
                                             <input name="outOfPhaseVisitDays"
                                                class="form-control cs-form-control"
                                                [(ngModel)]="callerSettingsModel.outOfPhaseVisitDays"
                                                size="1"
                                                style="width: 40px;">
                                             <div class="setting-inline-radio-label">days either side</div>
                                          </div>
                                       </label>
                                    </div>
                                 </div>-->
                           </div>
                        </div>
                        <!-- cs-block-ui -->
                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #outOfPhaseVisitsMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.overnightsMultiEdit,
                                 'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.overnightsMultiEdit}">
                  <div class="panel-body">
                     <h6>
                        <p-checkbox *ngIf="editingMultipleCallers" name="overnightsMultiEdit"
                           [(ngModel)]="callerSettingsModel.overnightsMultiEdit"
                           binary="true" label="">
                        </p-checkbox>
                        Overnights
                     </h6>
                     <!-- This panel is greyed out based on the overnightsMultiEdit checkbox above -->
                     <!-- Also display the properties of this section if they are the same between all the selected callers,
                           otherwise display the 'Multiple valuese set' message-->
                     <div *ngIf="canShowInputs(mySettingSection.Overnights); else overnightsMultiValueMsg"
                        [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.overnightsMultiEdit}">
                        <div class="form-group" *ngIf="showOverrideButtons">
                           <div class="col-xs-12">
                              <callsmart-inputswitch name="overnightsSwitch" onLabel="Default" offLabel="Override"
                                 [(ngModel)]="callerSettingsModel.overnightsAllowedDisabled"
                                 inverseColours="true"
                                 (onChange)="onAnyInputSwitchChanged($event)">
                              </callsmart-inputswitch>
                           </div>
                        </div>


                        <!-- This panel is greyed out based on the overnightsSwitch above -->
                        <div [ngClass]="{'cs-block-ui': callerSettingsModel.overnightsAllowedDisabled}">
                           <p>Allow overnight stays</p>
                           <div class="col-xs-12" style="padding-bottom: 20px;">
                              <callsmart-inputswitch name="allowOvernightsSwitch" onLabel="Off" offLabel="On"
                                 [(ngModel)]="overnightsDisabled"
                                 inverseColours="true"
                                 (onChange)="onOvernightsChanged($event)">
                              </callsmart-inputswitch>
                           </div>

                           <!-- This panel is greyed out based on the allowOvernightsSwitch above -->
                           <div [ngClass]="{'cs-block-ui': callerSettingsModel.overnightsDisabled}">
                              <p>Maximum consecutive overnights</p>
                              <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                                 <input name="maxConsecutiveOvernightsInput" #maxConsecutiveOvernightsInput="ngModel" required
                                          class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.maxConsecutiveOvernights"
                                          disabled="{{callerSettingsModel.overnightsNoMaximum}}"
                                          size="1" style="width: 50px;"
                                          type="number" (input)="onMaxConsecutiveOvernightInputValueChanged($event);"
                                          onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                                 <div class="form-group-chkbox"  style="margin-left: 40px">
                                       <p-checkbox name="overnightsNoMaximum" [(ngModel)]="callerSettingsModel.overnightsNoMaximum"
                                          binary="true" label="No maximum" (onChange)="onAnyCheckboxChanged($event)">
                                       </p-checkbox>
                                 </div>
                              </div>
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                                 <span *ngIf="maxConsecutiveOvernightsInput.touched && maxConsecutiveOvernightsInput.errors">
                                    <label *ngIf="maxConsecutiveOvernightsInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert maximum consecutive overnights</label>
                                    <!-- <label *ngIf="maxConsecutiveOvernightsInput.errors.pattern" style="padding: 0px;margin-bottom: 0px;">Please insert a value between 0 and 365</label> -->
                                 </span>
                                 <!-- If any of the previous errors are displayed then this error is not display -->
                                 <label *ngIf="maxConsecutiveOvernightsInput.touched && !isMaxConsecutiveOvernightValid && isMaxConsecutiveOvernightFormatValid && !callerSettingsModel.overnightsNoMaximum"
                                       style="padding: 0px;margin-bottom: 0px">
                                       Please insert a value between 1 and smaller than the number of calendar days in the call cycle length ({{projectCycleLength * 7}} days)
                                 </label>
                              </div>


                              <p>Minimum commute time before overnight</p>
                              <!-- <p>The minimum return commute time before an overnight stay is considered by the optimiser.</p> -->
                              <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">

                                 <input name="minCommuteTimeBeforeOvernightInput" #minCommuteTimeBeforeOvernightInput="ngModel" required
                                       class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.minCommuteTimeBeforeOvernight"
                                       size="1" 
                                       style="width: 50px;" type="number" pattern="^([1-8][0-9]|9[0-9]|[1-3][0-9]{2}|4[0-7][0-9]|480)$"
                                       (input)="onMinCommuteTimeBeforeOvernightInputValueChanged($event);"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                                 <label for="minCommuteTimeBeforeOvernightInput" class="control-label"  style="padding: 0px; margin-left:10px">Minutes</label>

                              </div>
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                                 <span *ngIf="minCommuteTimeBeforeOvernightInput.touched && minCommuteTimeBeforeOvernightInput.errors">
                                    <label *ngIf="minCommuteTimeBeforeOvernightInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert maximum commute time before overnight</label>
                                    <label *ngIf="minCommuteTimeBeforeOvernightInput.errors.pattern" style="padding: 0px;margin-bottom: 0px;">Please insert a value between 10 and 480</label>
                                 </span>
                              </div>

                              <p>Maximum travel time in the event of an overnight</p>
                              <!-- <p>The maximum travel time to the first callpoint allowed after an overnight has occurred.</p> -->
                              <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;">
                                 <input name="maxTravelTimeInEventOfOvernightInput" #maxTravelTimeInEventOfOvernightInput="ngModel" required
                                       class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.maxTravelTimeInEventOfOvernight"
                                       size="1" disabled="{{callerSettingsModel.eventOvernightNoMaximum}}"
                                       style="width: 50px;" type="number" pattern="^([1-9]|[1-8][0-9]|9[0-9]|[1-6][0-9]{2}|7[01][0-9]|720)$"
                                       (keyup)="onMaxTravelTimeAfterOvernightInputValueChanged($event);"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57">

                                 <label for="maxTravelTimeInEventOfOvernightInput" class="control-label"  style="padding: 0px; margin-left:10px">Minutes</label>

                                 <div class="form-group-chkbox" style="margin-left:40px;">

                                 <p-checkbox name="eventOvernightNoMaximum" [(ngModel)]="callerSettingsModel.eventOvernightNoMaximum"
                                             (onChange)="onAnyCheckboxChanged($event)"binary="true" label="No maximum">
                                 </p-checkbox>
                                 </div>
                              </div>
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px">
                                 <span *ngIf="maxTravelTimeInEventOfOvernightInput.touched && maxTravelTimeInEventOfOvernightInput.errors">
                                    <label *ngIf="maxTravelTimeInEventOfOvernightInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert maximum travel time in the event of an overnight</label>
                                    <label *ngIf="maxTravelTimeInEventOfOvernightInput.errors.pattern" style="padding: 0px;margin-bottom: 0px;">Please insert a value between 1 and 720</label>
                                 </span>
                              </div>
                           </div>
                        </div>

                     </div>
                     <!-- multi edit cs-block-ui -->

                     <!-- Else block -->
                     <ng-template #overnightsMultiValueMsg>
                        <span class="multiple-values-set">Multiple values set</span>
                     </ng-template>
                  </div>
               </div>

               <!-- Latitude / Longitude -->
               <div *ngIf="displayCallerSpecificSettings">
                  <div [ngClass]="{'cs-setting-panel': !editingMultipleCallers || callerSettingsModel.locationMultiEdit,
                              'cs-setting-panel-no-bg': editingMultipleCallers && !callerSettingsModel.locationMultiEdit}">
                     <div class="panel-body">
                        <h6>
                           <p-checkbox *ngIf="editingMultipleCallers" name="locationMultiEdit"
                              [(ngModel)]="callerSettingsModel.locationMultiEdit"
                              binary="true"
                              label=""
                              (onChange)="setLocationPropertyToEmpty($event)">
                           </p-checkbox>
                           Location
                        </h6>
                        <!-- This panel is greyed out based on the frequencyMultiEdit checkbox above -->
                        <!-- Also display the properties of this section if they are the same between all the selected callpoints,
                           otherwise display the 'Multiple values set' message-->
                        <div *ngIf="canShowInputs(mySettingSection.Location); else locationMultiValueMsg" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.locationMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;; margin-bottom:10px">
                              <label for="latitudeInput" class="control-label"  style="padding: 0px; margin-right: 20px">Latitude</label>
                              <input name="latitudeInput" #latitudeInput="ngModel" required  tabindex="4"
                                    class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.latitude"
                                    size="10" style="width: 150px;"
                                    type="number" (input)="onLatitudeInputValueChanged($event);"
                                    pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px; margin-left: 10px">
                                 <span *ngIf="latitudeInput.touched && latitudeInput.errors">
                                    <label *ngIf="latitudeInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert Latitude</label>
                                    <label *ngIf="latitudeInput.errors.pattern">Please insert a valid Latitude value between -90 and 90</label>
                                 </span>
                              </div>

                              <label for="searchPlace" class="control-label"  style="padding: 0px; margin-right: 10px; margin-left:70px">Place</label>
                              <input name="searchPlace" id="searchPlace"  tabindex="6"
                                       class="form-control cs-form-control"
                                       size="10" style="width: 280px;"
                                       type="text" 
                                       (input)="onSearchPlaceInputValueChanged($event);">
                              <button type="button" class="btn cs-btn-tertiary pull-right" (click)="applyLocation()" [disabled]="searchPlaceApplyButtonDisabled" tabindex="7"
                                       style="margin-right: 8px; margin-left:10px">Update Coordinates</button>
                                 
                           </div>
                        </div><!-- multi edit cs-block-ui -->
                        <div *ngIf="canShowInputs(mySettingSection.Location);" [ngClass]="{'cs-block-ui': editingMultipleCallers && !callerSettingsModel.locationMultiEdit}">
                           <div style="display: flex; flex-direction: row; align-items: center; justify-content: left; margin-bottom:10px">
                              <label for="longitudeInput" class="control-label"  style="padding: 0px; margin-right: 10px">Longitude</label>
                              <input name="longitudeInput" #longitudeInput="ngModel" required tabindex="5"
                                     pattern="\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
                                     class="form-control cs-form-control" [(ngModel)]="callerSettingsModel.longitude"
                                     size="10" style="width: 150px;"
                                     type="number" (input)="onLongitudeInputValueChanged($event);">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-bottom:10px; margin-left: 10px">
                                 <span *ngIf="longitudeInput.touched && longitudeInput.errors">
                                    <label *ngIf="longitudeInput.errors.required" style="padding: 0px;margin-bottom: 0px;">Please insert Longitude</label>
                                    <label *ngIf="longitudeInput.errors.pattern">Please insert a valid Longitude value between -180 and 180</label>
                                 </span>
                              </div>
                           </div>               
                                       
                        </div><!-- multi edit cs-block-ui -->
                        <!-- Else block -->
                        <ng-template #locationMultiValueMsg>
                           <span class="multiple-values-set">Multiple values set</span>
                        </ng-template>
                     </div>
                  </div>
               </div>

            </perfect-scrollbar>
         </div>
      </div>
   </form>
</div>
