<div class="row" [ngStyle]="{'height.px': workspaceHeight}" style="margin-left:30px;margin-right:30px ;">
   <div class="black-workspace col-md-12" style="padding: 10px">
      <callsmart-project-list class="black-datatable no-horizontal-scrollbar" [scrollHeight]="scrollHeight"></callsmart-project-list>
   </div>
</div>

<div class="row">

   <hr style="margin-left:30px;margin-right:30px ;">
   <div style="float:right; margin-left:30px;margin-right:30px ; ">
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="onDeleteProject()"  [disabled]="projectList.selectedProjects == null">
            <i class="cs-icon icon-trash" style="font-size: 15px"></i> Delete</button>
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="onCopyProject()" [disabled]="projectList.selectedProjects == null">
            <i class="cs-icon icon-add-3" style="font-size: 15px"></i>  Copy</button>
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="onRollProject()" [disabled]="projectList.selectedProjects == null">
            <i class="cs-icon icon-repeat" style="font-size: 15px"></i>  Roll forward</button>
            <button type="button" class="btn cs-btn-secondary-inverse"(click)="onRenameProject()" [disabled]="projectList.selectedProjects == null" >
                  <i class="cs-icon icon-edit" style="font-size: 15px"></i> Rename</button>
      <button type="button" class="btn cs-btn-primary-inverse" (click)="onOpenProject()" [disabled]="projectList.selectedProjects == null" >Open</button>
   </div>
</div>


<ndc-dynamic *ngIf="showDeleteProjectDialog" [ndcDynamicComponent]="deleteProjectDialog" [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showCopyProjectDialog" [ndcDynamicComponent]="copyProjectDialog" [ndcDynamicInputs]="copyDialogInput"
   [ndcDynamicOutputs]="copyDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showRollProjectFowardDialog" [ndcDynamicComponent]="rollProjectForwardDialog" [ndcDynamicInputs]="rollProjectForwardDialogInput"
   [ndcDynamicOutputs]="rollProjectForwardDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showRenameProjectDialog" [ndcDynamicComponent]="renameProjectDialog" [ndcDynamicInputs]="renameDialogInput"
   [ndcDynamicOutputs]="renameDialogOutput">
</ndc-dynamic>
