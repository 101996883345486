<p-dialog
   header="{{ title }} "
   [(visible)]="display"
   modal="true"
   width="1000"
   height="calc(88vh);"
   positionTop="15"
   [draggable]="false"
   [resizable]="false"
   blockScroll="true"
   focusOnShow="true"
   (onHide)="onCancel()"
>
   <div style="height: calc(75vh);" #gridWrapper>
      <p-dataTable
         [loading]="loading"
         emptyMessage="Loading"
         [value]="callpoints"
         [resizableColumns]="true"
         [reorderableColumns]="false"
         scrollable="true"
         [scrollHeight]="scrollHeight"
         selectionMode="multiple"
         [(selection)]="selectedCallPoints"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowUnselect($event)"
         (onFilter)="onFilter($event)"
         [rowTrackBy]="trackByFn"
         [rows]="rows"
         (onLazyLoad)="loadDataLazy($event)"
         virtualScroll="virtualScroll"
         [lazy]="true"
         (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
         [totalRecords]="totalRecords"
         (onColResize)="onColResize($event)"
         #callpointdt
      >
         <p-header>
            <div style="float: left; margin-top: -4px;">
               <h4>{{ gridTitle }}</h4>
            </div>
            <div style="float: left; height: 40px; padding-left: 10px;">
               <button
                  id="clearFilter"
                  type="button"
                  class="btn cs-btn-secondary"
                  (click)="onFilterReset()"
                  *ngIf="hasFilters"
                  style="font-weight: bold;"
               >
                  <i class="glyphicon glyphicon-remove clear-filter-text"></i
                  >Clear filter
               </button>
            </div>

            <div style="text-align: right;">
               <div
                  class="btn-group"
                  style="
                     margin: 4px 0px 2px 0px;
                     overflow: auto;
                     display: inline-table;
                     overflow: auto;
                  "
               >
                  <button
                     type="button"
                     class="btn cs-btn-secondary dropdown-toggle cs-btn-secondary-dropdown"
                     data-toggle="dropdown"
                     aria-haspopup="true"
                     aria-expanded="false"
                  >
                     <span style="text-align: left; margin-right: 30px;"
                        >Actions
                     </span>
                     <span
                        style="text-align: right;"
                        class="cs-icon icon-arrow-down dropdown-button-icon"
                        style="position: absolute;"
                     ></span>
                  </button>
                  <ul
                     class="dropdown-menu cs-dropdown-inverse"
                     style="left: -84px; width: 220px;"
                  >
                     <li
                        [ngClass]="{
                           disabled: selectedCallPoints?.length == 0
                        }"
                     >
                        <a (click)="lockToDay()">
                           <span
                              style="margin-right: 5px;"
                              class="cs-icon icon-locked-5"
                           ></span>
                           Lock to day
                        </a>
                     </li>

                     <li
                        [ngClass]="{
                           disabled: selectedCallPoints?.length == 0
                        }"
                     >
                        <a (click)="lockToDayTime()">
                           <span
                              style="margin-right: 5px;"
                              class="cs-icon icon-locked-5"
                           ></span>
                           Lock to time & day
                        </a>
                     </li>

                     <li
                        [ngClass]="{
                           disabled: selectedCallPoints?.length == 0
                        }"
                     >
                        <a (click)="unlock()">
                           <span
                              style="margin-right: 5px;"
                              class="cs-icon icon-placeholders"
                           ></span>
                           Unlock
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </p-header>

         <!-- check box for selection -->
         <p-column
            [style]="{
               width: '40px',
               'min-width': '35px',
               'max-width': '40px',
               padding: '5px'
            }"
            selectionMode="multiple"
         ></p-column>

         <p-column
            *ngFor="let col of cols; let i = index"
            [field]="col.field"
            [header]="col.header"
            [filter]="col.filter"
            [filterPlaceholder]="col.filterPlaceholder"
            [filterMatchMode]="col.filterMatchMode"
            [sortable]="true"
            [style]="{
               width: '150px',
               'min-width': '80px',
               overflow: 'hidden'
            }"
         >
            <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
               <p-dropdown
                  [options]="this.comboFilterDataMap.get(col.field)"
                  [style]="{ width: '100%' }"
                  styleClass="ui-column-filter"
                  [(ngModel)]="filterSelectedValues[i]"
                  (onChange)="
                     callpointdt.filter(
                        $event.value,
                        col.field,
                        col.filterMatchMode
                     )
                  "
                  appendTo="body"
               ></p-dropdown>
            </ng-template>

            <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
               <p-multiSelect
                  [options]="this.comboFilterDataMap.get(col.field)"
                  [style]="{ width: '100%' }"
                  styleClass="ui-column-filter multiselect-filter-col"
                  displaySelectedLabel="true"
                  maxSelectedLabels="0"
                  [(ngModel)]="filterSelectedMultiValues[i]"
                  (onChange)="
                     callpointdt.filter(
                        $event.value,
                        col.field,
                        col.filterMatchMode
                     )
                  "
                  defaultLabel="All"
                  appendTo="body"
               ></p-multiSelect>
            </ng-template>
         </p-column>
      </p-dataTable>

      <div *ngIf="loading">
         <div style="float: left;">
            <h4>{{ loadingText }}</h4>
         </div>
         <div
            class="callponts-dialog-spinner"
            style="float: left; margin-top: 12px; margin-left: 10px;"
         >
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
         </div>
      </div>
   </div>

   <p-footer>
      <div
         style="
            float: left;
            padding-top: 10px;
            margin-left: 40px;
            height: 50px;
         "
      >
         {{ selectedCallPoints.length }} row(s) selected
      </div>

      <div style="padding-top: 10px; margin-right: 40px; height: 50px;">
         <button
            [disabled]="loading"
            type="button"
            class="btn cs-btn-primary-inverse pull-right"
            (click)="onSave()"
         >
            Ok
         </button>
         <!-- <button
            type="button"
            class="btn cs-btn-secondary-inverse pull-right"
            (click)="onCancel()"
            style="margin-right: 8px;"
         >
            Cancel
         </button> -->
      </div>
   </p-footer>
</p-dialog>
