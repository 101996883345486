<perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
   <p-accordion [multiple]="true" (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)" [activeIndex]="tabIndexes">
      <p-accordionTab>
         <p-header>
            <i class="cs-icon icon-users"></i>
            <span class="cs-accordian-tab-header">Caller</span>
         </p-header>
         <!-- Only one caller is selected so display the summary for that caller  -->
         <callsmart-caller-settings-summary *ngIf="selectedCallers.length <= 1" 
            [showScheduleExport]="showScheduleExport" 
            [showCallerExport]="showCallerExport"
            [showCallpointExport]="showCallpointExport"
            [disableCallpointExport]="selectedCallpoints.length == 0">
         </callsmart-caller-settings-summary>

         <!-- Multiple callers are selected, this component deals with that -->
         <callsmart-multi-selected-callers *ngIf="selectedCallers.length > 1" 
            [selectedCallers]="selectedCallers"
            [showScheduleExport]="showScheduleExport" 
            [showCallerExport]="showCallerExport" 
            [showCallpointExport]="showCallpointExport" 
            (deselectCaller)="deselectCallerFromMap($event)"
            (selectCaller)="setSingleSelectedCaller($event)">
         </callsmart-multi-selected-callers>
         
      </p-accordionTab>
      <p-accordionTab>
         <p-header>
            <i class="cs-icon icon-placeholders"></i>
            <span class="cs-accordian-tab-header">Callpoint</span>
         </p-header>
         <!-- Only one callpoint is selected so display the summary for that callpoint  -->
         <callsmart-callpoint-summary *ngIf="selectedCallpoints.length <= 1"
            [callpoint]="selectedCallpoints[0] === undefined ? null : selectedCallpoints[0]">
         </callsmart-callpoint-summary>

         <!-- Multiple callpoints are selected, this compoenent deals with that -->
         <callsmart-multi-selected-callpoints *ngIf="selectedCallpoints.length > 1"
            [selectedCallpoints]="selectedCallpoints"
            (deselectCallpoint)="deselectCallpointFromMap($event)"
            (selectCallpoint)="setSingleSelectedCallpoint($event)">
         </callsmart-multi-selected-callpoints>
      </p-accordionTab>
      <p-accordionTab>
         <p-header>
            <i class="cs-icon icon-calendar-3"></i>
            <span class="cs-accordian-tab-header">Day</span>
         </p-header>
         <callsmart-caller-day-summary
            [selectedDate]="selectedDiaryDay"
            [eventCollection]="selectedDayEvents"
            [startCycleDate]="startCycleDate"
            [callpointId]="selectedVisit?.selectedVisit.callpointId"
            [selectedContractedWorkingTime]="selectedContractedWorkingTime">
         </callsmart-caller-day-summary>
      </p-accordionTab>
      <p-accordionTab>
         <p-header>
            <i class="cs-icon icon-briefcase"></i>
            <span class="cs-accordian-tab-header">Visit</span>
         </p-header>
         <callsmart-caller-visits-summary 
            [startCycleDate]="startCycleDate"
            [selectedVisit]="selectedVisit">
         </callsmart-caller-visits-summary>
      </p-accordionTab>
   </p-accordion>
</perfect-scrollbar>
