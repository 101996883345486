import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Subscription } from "rxjs";

import { GoogleMapComponent } from "app/shared/google-map/google-map.component";
import { CallersListComponent } from "app/callers-workspace/callers-list/callers-list.component";
import { Caller } from "app/models/caller";
import { MapPoint } from "app/models/map-point";
import { ApplicationStore } from "app/stores/application-store";
import { BrowserWindowService } from "app/services/browser-window.service";
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceNavigationLogic } from 'app/shared/optimisation-logic/workspace-navigation-logic';


// The caller work space is used to present map and grid information to the user.
// it uses both the caller list control and map control to do this.
// additional information is shown using the contextual panel, showing and hiding of this is triggered through
// events fired from the other controls
@Component({
   selector: 'callsmart-callers-workspace',
   templateUrl: './callers-workspace.component.html',
   host: {
      '(document:keydown)': 'onKeyDown($event)',
      '(document:keyup)': 'onKeyUp($event)',
   }
})
export class CallersWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {
   @ViewChild(CallersListComponent)
   public callerList: CallersListComponent;

   // map properties
   public mapHeight: number = 250;
   public mapLastSyncSelection: any[] = [];
   public gridHeight: number = 250;
   public scrollHeight: string = "200px";
   public workspaceHeight: number;
   public marginTop: number = 18;
   public scrollWidth: string = "1200px"

   /*************** Hide/Show  Map variables********************/
   @ViewChild('hideMapButton') hideMapButton;
   @ViewChild('box1') splitterComponent;

   public isMapHidden: boolean = false;
   public isChangingMapVisiblity: boolean = false;
   public minMapSize: number = 0;
   public isCollapseMapButtonVisible: boolean = true;
   public clusterImagePath: string;
   public isCtrlKeyPressed: boolean = false;

   private horizontalSeparator = null;
   private firstIdle: boolean = true;
   private firstTilesLoaded: boolean = true;
   private resizing: boolean = false; // Needed when resizing the workspace to workaround a bug when releasing the splitter.
   private initialising: boolean = true; // Needed when resizing the workspace to workaround a bug when releasing the splitter.

   @ViewChild(GoogleMapComponent)
   private map: GoogleMapComponent;

   private _callers_subscription: Subscription;
   private _deselectCaller_subscription: Subscription;
   private _singleSelectCaller_subscription: Subscription;
   private _callerMapPointsSubscription: Subscription;

   constructor(
      private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _workspaceNavigationLogic: WorkspaceNavigationLogic) {

      this.clusterImagePath = this._applicationStore.mapsStore.clusterMarkerImagePathGreen;

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
      //subscribe to the window resize event
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth = (value - 260) + "px"; // 260 is the contextual panel width
      });
   }

   get optimiseMapMarkers(): boolean {
      return this._applicationStore.mapsStore.OptimiseMapMarkers;
   }

   get selectedCallerLocation(): { lat: number, lng: number } {
      if (this._applicationStore.callersStore.selectedCaller) {
         return {
            lat: this._applicationStore.callersStore.selectedCaller.latitude,
            lng: this._applicationStore.callersStore.selectedCaller.longitude
         };
      }
      else {
         return null;
      }
   }

   public ngOnInit(): void {

      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Callers);
      this._workspaceNavigationLogic.navigatingToWorkspace(WorkspaceViewType.Callers);
      this.horizontalSeparator = document.getElementsByTagName("horizontal-split-separator")[0];

      // The 'hide map' button located under the splitter is problematic when resizing.
      // If you are going at the right speed you'll find that instead of releasing the splitter,
      // the mouse still has hold of the splitter. This is because the cursor on release was over the up ^ arrow
      // and this prevents the drop notification from firing.
      // WORKAROUND:
      // * When user presses the mouse down the 'hide map' button is not displayed.
      // * When user releases the mouse (mouse up) the 'hide map' button is displayed in the right position.
      this.horizontalSeparator.onmousedown = (() => {
         this.hideMapButton.nativeElement.style.display = "none";
         this.marginTop = 0;
         this.resizing = true;
      });

      // The mouse can be released on any place inside the browser. That's the reason
      // to handle the 'mouseup' event of the document element
      document.onmouseup = (() => {
         if (this.resizing) {
            this.marginTop = 18;
            this.hideMapButton.nativeElement.style.display = "block";
            this.resizeMappingArea();
            this.resizing = false;
            // Internal funtion belonging to the SplitterComponent itself.
            this.splitterComponent.stopResizing();
         }
      });

      this.isMapHidden = this._applicationStore.mapsStore.isCallersMapHidden;
      this.subscribeToCallers();
      this.showHideMappingArea(this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight);
   }

   public ngAfterViewInit() {
      this.subscribeToDeselectCaller();
      this.subscribeToSingleSelectCaller();
   }

   public ngOnDestroy(): void {
      this._workspaceNavigationLogic.navigatingAwayFromActiveWorkspace(this._applicationStore.uiStore.getActiveView());

      if (this._callers_subscription) {
         this._callers_subscription.unsubscribe();
      }
      if (this._deselectCaller_subscription) {
         this._deselectCaller_subscription.unsubscribe();
      }
      if (this._singleSelectCaller_subscription) {
         this._singleSelectCaller_subscription.unsubscribe();
      }
      if (this._callerMapPointsSubscription) {
         this._callerMapPointsSubscription.unsubscribe();
      }
   }

   public onMarkerClicked(event) {
      let selectedData = this._applicationStore.callersStore.callers.find(c => c.guid == event.point.guid);
      if (this.isCtrlKeyPressed) {
         // code for handling multiple markers being selected
         // sync with the grid.
         if (event.selected) {
            this.callerList.selectedCallers.push(selectedData);
            this.forceListSync();
         } else {
            let index = this.callerList.selectedCallers.findIndex(x => x.callerId == selectedData.callerId);
            this.callerList.selectedCallers.splice(index, 1);
            this.forceListSync();
         }
      } else {
         // only allowing one map selection at a  time clear grid selections
         this.callerList.selectedCallers = [];
         this.callerList.selectedCallers.push(selectedData);
      }

      this.mapLastSyncSelection = this.callerList.selectedCallers.slice(); // clone the array
      this.syncSelectedCallerGlobally();
   }

   public onRowSelected(event) {

      if (!this.isMapHidden) {

         // bug in prime NG they are leaving a property on the data
         //https://github.com/primefaces/primeng/issues/1937
         delete event.data._$visited;

         //check if the last map selection is out of sync with current selection if out of sync then deselect this missing points
         let missing = this.mapLastSyncSelection.filter(item => this.callerList.selectedCallers.indexOf(item) < 0);

         missing.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, false);
         });

         this.map.SetMapSelectionByGuid(event.data.guid, true);

         // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
         this.mapLastSyncSelection = this.callerList.selectedCallers.slice(); // clone the array

         this.map.focusMapOnSelection();
      }

      this.syncSelectedCallerGlobally();
   }

   public onAllRowsSelected(event) {

      //check if the last map selection is out of sync with current selection if out of sync then deselect this missing points
      let missing = this.mapLastSyncSelection.filter(item => this.callerList.selectedCallers.indexOf(item) < 0);

      if (!this.isMapHidden) {
         missing.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, false);
         });
      }

      if (event.checked) {
         if (!this.isMapHidden) {
            this.callerList.selectedCallers = [];
            this.callerList.allCallers.forEach(c => {
               this.map.SetMapSelectionByGuid(c.guid, true);
               this.callerList.selectedCallers.push(c);
            });

            this.map.focusMapOnSelection();
         }
      }
      else {
         this._applicationStore.callersStore.setSelectedCaller(null);
      }

      // The caller store needs to know about the multiple selection of callers, this is how
      // the contextual panel is synced up.
      this._applicationStore.callersStore.setSelectedCallers(this.callerList.selectedCallers.slice());

      // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
      this.mapLastSyncSelection = this.callerList.selectedCallers.slice(); // clone the array
   }

   // Called from the datagrid to deselect the caller from the map.
   public onRowUnSelected(event) {
      this.deselectFromMap(event.data.guid)
      this.map.focusMapOnMarkers();
   }

   // Called from the multi select callers component to deselect the caller from the map.
   public deselectFromMap(guid: string) {
      if (!this.isMapHidden) {
         this.map.SetMapSelectionByGuid(guid, false);
      }

      //the deselected item in the grid will still show in the map selction untill the event has run its couse through the grid
      let selection = this.callerList.selectedCallers.slice();

      // delete the unselected item from the selection if it is still there
      let deSelectedIndex = selection.findIndex(x => x.guid == guid);

      if (deSelectedIndex > -1) {
         selection.splice(deSelectedIndex, 1);
      }

      if (selection.length > 0) {
         this._applicationStore.callersStore.setSelectedCaller(selection[0]);

         // The caller store needs to know about the multiple selection of callers, this is how
         // the contextual panel is synced up.
         this._applicationStore.callersStore.setSelectedCallers(selection);
      } else {
         this._applicationStore.callersStore.setSelectedCaller(null);
      }

      this.callerList.selectedCallers = selection.slice();

      this.forceListSync();

      // attempt to fix the grey box in map
      if (this.map) {
         this.map.resizeMap();
      }
   }

   public setMap(event) {
      this.map = event;
      this.subscribeToCallersMapPoints();
   }

   public onGoogleMapIdle(event) {
      // Sets selection on the caller list and map on load.
      if (this.callerList.callers.length > 0 && !this.firstTilesLoaded && this.firstIdle) {
         this.setSelectedCallerOnMapLoad();
         this.firstIdle = false;
      }

      this.firstIdle = false;
   }

   public onGoogleMapTilesLoaded(event) {
      // Sets selection on the caller list and map on load.
      if (this.callerList.callers.length > 0 && !this.firstIdle && this.firstTilesLoaded) {
         this.setSelectedCallerOnMapLoad();
         this.firstTilesLoaded = false;
      }

      this.firstTilesLoaded = false;

      if (!this.isMapHidden) {
         if (this.map) {
            this.map.checkSelectedIconCorrect();
         }
      }
   }

   public onGoogleMapResize(event) {
      if (this.map) {
         this.map.focusMapOnSelection();
      }
   }

   public onUseCallerDotIconSelected(event) {
      this._applicationStore.callersStore.RefreshCallersMapPointsIcons();
      this.map.clear();
      this.showCallersOnMap(this._applicationStore.callersStore.callers);

      // apply the selection on map
      this.callerList.selectedCallers.forEach(c => {
         this.map.SetMapSelectionByGuid(c.guid, true);
      });
   }

   public onHChange(event) {
      // Resize the map to fit new area the user has specified with the splitter.
      if (this.isChangingMapVisiblity && !this.isMapHidden) {
         this.mapHeight = this.splitterComponent.outerContainer.nativeElement.clientHeight - event.secondary;
      }
      else {
         this.mapHeight = event.primary;
      }

      if (this.map) {
         this.map.resizeMap();
      }

      this.gridHeight = event.secondary;

      if (this.callerList) {
         let headerHeightOffset = 140;
         if (this.callerList.headerHeight && this.callerList.tableHeaderHeight) {
            headerHeightOffset = (this.callerList.headerHeight + this.callerList.tableHeaderHeight);
         }
         this.scrollHeight = event.secondary - headerHeightOffset + 15 + "px";
      }

      // this point can be reached because either:
      // -. user is showing/hidding the mapping area or
      // -. user is performing a resizing action (which also includes loading this control the first time when the workspace is created).
      if (this.isChangingMapVisiblity) {
         // User is showing/hiding the mapping area
         this.showHideMappingArea(event.secondary);
      }
      else {
         if (this.initialising && this.hideMapButton) {
            this.hideMapButton.nativeElement.style.display = "block";
            this.initialising = false;
         }
         // Either user is resizing the mapping area using the splitter or
         // the splitter control is being loaded for the first time.
         this.resizeMappingArea();
      }

      if (this.isChangingMapVisiblity) {
         this.isChangingMapVisiblity = false;
      }
   }

   public onListColumnHeaderChanged(event) {
      let headerHeight: number = event.headerHeight;
      let tableHeaderHeight: number = event.tableHeaderHeight;

      let secondaryClientHeight = this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight;
      this.gridHeight = secondaryClientHeight;
      this.scrollHeight = secondaryClientHeight - (headerHeight + tableHeaderHeight) + 15 + "px";
      this.mapHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
      setTimeout(() => this.map.resizeMap());
   }

   public setSingleSelectedCaller(caller: Caller) {
      this.map.selectedPoints = [];
      // map selection
      this.map.SetMapSelectionByGuid(caller.guid, true);

      // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
      this.mapLastSyncSelection = this.callerList.selectedCallers.slice(); // clone the array

      this.map.focusMapOnSelection();

      this.callerList.selectedCallers = [];
      this.callerList.selectedCallers.push(caller);
   }

   // list not syncing possible hack
   private forceListSync() {
      let temp = this.callerList.selectedCallers.slice();
      this.callerList.selectedCallers = [];
      temp.forEach(element => {
         this.callerList.selectedCallers.push(element);
      });
   }


   private showCallersOnMap(callers: ReadonlyArray<Caller>): void {
      if (!this.isMapHidden) {
         if (this.map) {
            this.map.selectedPoints = [];
            this.map.addMarkers(this._applicationStore.callersStore.callersMapPoints);
         }
      }
   }

   private syncSelectedCallerGlobally() {
      let selection = this.callerList.selectedCallers.slice();

      if (selection.length > 0) {
         this._applicationStore.callersStore.setSelectedCaller(selection[0]);

         // The caller store needs to know about the multiple selection of callers, this is how
         // the contextual panel is synced up.
         this._applicationStore.callersStore.setSelectedCallers(selection);
      } else {
         this._applicationStore.callersStore.setSelectedCaller(null);
      }

      // attempt to fix the grey box in map
      if (!this.isMapHidden) {
         if (this.map) {
            this.map.resizeMap();
         }
      }
   }

   // Changes the visibility of the Map.
   public displayMap() {
      this.isMapHidden = !this.isMapHidden;
      this._applicationStore.mapsStore.isCallersMapHidden = this.isMapHidden;
      this.isChangingMapVisiblity = true;
   }

   // Adjust the height of the map and data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 69;
      this.calculateMapAndGridHeightsBasedonSplitter(this.splitterComponent);
   }

   private calculateMapAndGridHeightsBasedonSplitter(splitter) {
      if (this.splitterComponent) {
         let topPanelHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
         let bottomPanelHeight = this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight;

         // set the grid height
         this.gridHeight = bottomPanelHeight;
         this.scrollHeight = this.calculateGridScrollHeight(bottomPanelHeight)

         // set the Map height
         this.mapHeight = topPanelHeight
         setTimeout(() => this.map.resizeMap());
      }
   }

   private calculateGridScrollHeight(panelHeight) {
      let scrollheight = panelHeight - 140;
      if (this.callerList) {
         scrollheight = panelHeight - (this.callerList.headerHeight + this.callerList.tableHeaderHeight) + 15;
      }
      return scrollheight + "px";
   }

   // Collapses/Displays the mapping area.
   private showHideMappingArea(secondaryHeight: number) {
      // The collapse map button visibility changes.
      this.isCollapseMapButtonVisible = !this.isMapHidden;
      // Map is hidden.
      if (this.isMapHidden) {
         this.marginTop = 0;
         this.scrollHeight = this.calculateGridScrollHeight(secondaryHeight);
      }
      // Map is visible.
      else {
         // This setTimeout function is performed when the splitter becomes visible
         // since the "hideMapButton" element is still null... so need some time to be rendered
         this.marginTop = 18;
         this.styleHideMapButtonForDisplay();

         setTimeout(() => {
            this.map.resizeMap();
         }, 500);
      }
   }

   private styleHideMapButtonForDisplay() {
      setTimeout(() => {
         this.hideMapButton.nativeElement.style.display = "block"
         this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop + 18 + 7) + "px";
      }, 250);
   }

   // Resizes the mapping area using the splitter.
   private resizeMappingArea() {
      if (this.hideMapButton) {
         // User is using the splitter to resize the workspace
         if (this.resizing) {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop + 36 + 7) + "px";
         }
         else {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop + 18 + 7) + "px";
         }
      }
      if (this.firstIdle && this.firstTilesLoaded) {
         setTimeout(() => {
            if (this.map) {
               this.map.resizeMap();
            }
         }, 500);
      }
   }

   private subscribeToCallers() {
      this._callers_subscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            this.showCallersOnMap(callers);
         });
   }


   // set the selected caller for first use when map loads
   private setSelectedCallerOnMapLoad() {
      if (this._applicationStore.callersStore.selectedCaller) {

         this.map.selectedPoints = [];

         //clone the service selected caller
         let selected = Object.assign({}, this._applicationStore.callersStore.selectedCaller);

         // map selection
         this.map.SetMapSelectionByGuid(selected.guid, true);

         // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
         this.mapLastSyncSelection = this.callerList.selectedCallers.slice(); // clone the array

         this.map.focusMapOnSelection();
      }
   }

   // check which key pressed down
   private onKeyDown(e) {
      // check if control is pressed needed for multi selecting points on the map
      this.isCtrlKeyPressed = ((e.keyIdentifier == 'Control') || (e.ctrlKey == true));
   }

   // check which key pressed up
   private onKeyUp(e) {
      // check if control is pressed needed for multi selecting points on the map
      this.isCtrlKeyPressed = ((e.keyIdentifier == 'Control') || (e.ctrlKey == true));
   }

   private subscribeToDeselectCaller() {
      this._deselectCaller_subscription = this._applicationStore.callersStore.deselectCaller$.subscribe(
         (guid: string) => {
            if (guid) {
               this.deselectFromMap(guid);
            }
         });
   }

   private subscribeToSingleSelectCaller() {
      this._singleSelectCaller_subscription = this._applicationStore.callersStore.singleSelectCaller$.subscribe(
         (caller: Caller) => {
            if (caller !== null) {
               this.setSingleSelectedCaller(caller);
            }
         });
   }

   private subscribeToCallersMapPoints() {
      this._callerMapPointsSubscription = this._applicationStore.callersStore.callersMapPoints$.subscribe(
         (callersMapPoints: MapPoint[]) => {
            if (this.map) {
               this.map.clear();
            }
            if (callersMapPoints && callersMapPoints.length > 0) {
               this.showCallersOnMap(null);
            }
         });
   }
}
