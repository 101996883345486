<edit-callpoint-settings name="editCallpointSettings"
   [(ngModel)]="callpointSettings"
   [scrollHeight]="scrollHeight"
   [displayHeading]="true"      
   [allPossibleDayCombinations]="allPossibleDayCombinations"
   [recommendedDayCombinations]="recommendedDayCombinations"
   [projectActiveWorkingDays]="defaultProjectActiveWorkingDays"
   #editCallpointSettings>
</edit-callpoint-settings>

<div class="col-md-12">
   <div class="btn-toolbar" style="margin-right: -14px;">
      <input type="submit" [disabled]="!formValid || canDeactivate()" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save"
         (click)="submitForm()">
      <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel"
         (click)="onCancel()">
   </div>
</div>
<ndc-dynamic *ngIf="showErrorDialog" [ndcDynamicComponent]="errorDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
