<div class="panel">
   <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
      <perfect-scrollbar [ngStyle]="{'height.px':componentHeight-33}">
         <div class="row">
            <div class="cs-wizard-step-panel col-md-12">
               <div class="row">
                  <div class="col-md-12">
                     <div class="panel-body" style="padding: 0px" *ngIf="projectWizardModel?.isCalendarVisible">
                        <callsmart-calendar class="black-calendar" #calendar [scheduleEvents]="this.projectWizardModel?.projectEvents" 
                           [numberOfWeeks]="projectWizardModel?.numberOfWeeks"
                           [canSelectDayOfWeek]="false" 
                           [startDate]="projectWizardModel?.startCycleDate" 
                           (eventSelected)="onEventSelected($event)"
                           [activeWorkingDays]="projectWizardModel.activeWorkingDays"
                           [calendarHeight]="calendarHeight"
                           [datesClosed]="projectWizardModel.datesClosed"
                           [startWorkingTime]="projectWizardModel.startWorkingTime"
                           [endWorkingTime]="projectWizardModel.endWorkingTime"
                           >
                        </callsmart-calendar>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12" style="padding: 0px">
                     <div class="panel-body">
                        <button type="button" class="btn cs-btn-secondary-inverse pull-right" style="margin-top:10px; display: flex;flex-direction: row;"
                           (click)="onAddEvent()">
                           <div class="black calendar add-icon"></div> Add Event</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>         
      </perfect-scrollbar>
   </div>
</div>


<ndc-dynamic *ngIf="showEventDialog" [ndcDynamicComponent]="projectCalendarDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
