import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment/moment';

import { CallersStore } from 'app/stores/callers-store';
import { ProjectsStore } from 'app/stores/projects-store';
import { ScheduleService } from 'app/services/schedule.service';
import { Event } from 'app/models/diary/event';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { VisitSelection } from 'app/models/visit-selection';
import { LockingTypes } from 'app/models/diary/locking-Types';
import { VisitsStore } from './visits-store';
import { EventTypes } from '../models/diary/event-types';
import { ScheduleWarnings } from '../models/schedule-warnings';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';
import { CallpointsStore } from './callpoints-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { OptimisationStatus } from 'app/models/optimisationStatus';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { ActionGroupings } from 'app/models/action-groupings.enum';
import { WorkspaceAction } from 'app/models/workspace-action';
import { SpinnerStore } from './spinner-store';
import { EventsAndMetrics } from 'app/models/events-metrics';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// Excellent resource on type script MAP (dictionaries)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map/delete

// this store takes care of polling the server when a caller is sent for optimisation.
// a list of the callers being optimised can be found in the callersOptimising$ observable

// each time a caller is loaded in the aplication store, the callpoints are retrieved along with the schedule for the caller.
// to improve performance the schedules are cached in a dictionary _cachedCallerDiaries.
// there is a limit on the number of schedules cached currently set to 10 this is stored in the property maxNumberOfCachedDiaries

@Injectable()
export class ScheduleStore {
   // used by the application store to orchestrate interactions between stores
   public scheduleGenerationStarted: Subject<OptimisationStatus> = new Subject<OptimisationStatus>();
   public scheduleGenerationCompleted: Subject<OptimisationStatus> = new Subject<OptimisationStatus>();
   public multiScheduleGenerationCompleted: Subject<any> = new Subject<any>();
   public scheduleCleared: Subject<any> = new Subject<any>();
   public visitRemoved: Subject<any> = new Subject<any>();
   public errorOptimisingCaller: Subject<any> = new Subject<any>();
   public visitLocked: Subject<any> = new Subject<any>();
   public visitAdded: Subject<any> = new Subject<any>();
   public visitMovedFailed: Subject<any> = new Subject<any>();
   public callpointVisitsLocked: Subject<any> = new Subject<any>();
   public daySwapValidationResult: Subject<any> = new Subject<any>();
   public workspaceShouldRefreshDataOnNavigateAway: Subject<WorkspaceAction> = new Subject<WorkspaceAction>();

   // Schedules
   private _scheduleXml: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public scheduleXml$: Observable<string> = this._scheduleXml.asObservable();

   // Schedules
   private _scheduleRequestXml: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public scheduleRequestXml$: Observable<string> = this._scheduleRequestXml.asObservable();

   // Callers that are being obtimised
   private _callersOptimising: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
   public callersOptimising$: Observable<number[]> = this._callersOptimising.asObservable();

   private _callersOptimisingWithUpdatedFrequency: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
   public callersOptimisingWithUpdatedFrequency$: Observable<number[]> = this._callersOptimising.asObservable();

   // Diary events used in the calendar control in schedule workspace
   private _diaryEvents: BehaviorSubject<ReadonlyArray<Event>> = new BehaviorSubject<ReadonlyArray<Event>>([]);
   public diaryEvents$: Observable<ReadonlyArray<Event>> = this._diaryEvents.asObservable();

   public maxNumberOfCachedDiaries: number = 10;
   // try to reduce the number of calls to the server cache the diary events for the caller
   //private _cachedCallerDiaries: Map<number, ReadonlyArray<Event>> = new Map<number, ReadonlyArray<Event>>();

   //currently selected diary event (visit)
   private _selectedDiaryEvent: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
   public selectedDiaryEvent$: Observable<Event> = this._selectedDiaryEvent.asObservable();

   //currently selected diary day (visit)
   private _selectedDiaryDay: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
   public selectedDiaryDay$: Observable<Date> = this._selectedDiaryDay.asObservable();

   //currently selected diary day (visit)
   private _selectedDiaryDayAfterSwap: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
   public _selectedDiaryDayAfterSwap$: Observable<Date> = this._selectedDiaryDayAfterSwap.asObservable();

   //currently selected diary working time (visit)
   private _selectedContractedWorkingTime: BehaviorSubject<ReadonlyArray<Date>> = new BehaviorSubject<ReadonlyArray<Date>>([]);
   public selectedContractedWorkingTime$: Observable<ReadonlyArray<Date>> = this._selectedContractedWorkingTime.asObservable();

   // Diary events used in the calendar control in schedule workspace
   private _selectedDayDiaryEvents: BehaviorSubject<ReadonlyArray<Event>> = new BehaviorSubject<ReadonlyArray<Event>>([]);
   public selectedDayDiaryEvents$: Observable<ReadonlyArray<Event>> = this._selectedDayDiaryEvents.asObservable();

   // The selected visit in the schedule workspace.
   private _selectedVisit: BehaviorSubject<VisitSelection> = new BehaviorSubject<VisitSelection>(null);
   public selectedVisit$: Observable<VisitSelection> = this._selectedVisit.asObservable();

   // currently selected diary day index
   private _selectedDiaryDayIndex: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
   public selectedDiaryDayIndex$: Observable<number> = this._selectedDiaryDayIndex.asObservable();

   // Drivetime version
   private _drivetimeVersion: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public drivetimeVersion$: Observable<string> = this._drivetimeVersion.asObservable();

   // Optimiser version
   private _optimiserVersion: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public optimiserVersion$: Observable<string> = this._optimiserVersion.asObservable();

   // Callers that are being obtimised
   private _callersOptimisingStatus: BehaviorSubject<OptimisationStatus> = new BehaviorSubject<OptimisationStatus>(null);
   public callersOptimisingStatus$: Observable<OptimisationStatus> = this._callersOptimisingStatus.asObservable();

   public get selectedDiaryEvent() {
      return this._selectedDiaryEvent.getValue();
   }

   public get selectedDiaryDay() {
      return this._selectedDiaryDay.getValue();
   }

   public get selectedDiaryDayAfterSwap() {
      return this._selectedDiaryDayAfterSwap.getValue();
   }

   public get currentCallerDiaryEvents() {
      return this._diaryEvents.getValue();
   }

   public get callersOptimising() {
      return this._callersOptimising.getValue();
   }

   public get selectedContractedWorkingTime() {
      return this._selectedContractedWorkingTime.getValue();
   }

   public get selectedDayDiaryEvents() {
      return this._selectedDayDiaryEvents.getValue();
   }

   public get selectedVisit() {
      return this._selectedVisit.getValue();
   }

   public get selectedDiaryDayIndex() {
      return this._selectedDiaryDayIndex.getValue();
   }

   // list of all the callers that have been sent to the service to be optimised.
   // It is necessary to poll for there result
   //private _callersOptimising:number[] = [];

   // this is the handle to the polling timer, allowing us to stop the timer
   private _handlePollingTimer = null;

   // In order to determine the status poll interval, we need to capture the start time
   // of the schedule generation.
   private _callerScheduleStartTime: moment.Moment;

   // Number of milliseconds to wait for the status update poll. This defaults to 5 seconds
   // but will be adjusted based on the duration of the schedule generation of which ever
   // caller completes first. This hopefully will stop the client hammering the server
   // with status polls if the schedule generation takes a very long time.
   private _statusPollInterval: number =
      CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;

   constructor(
      private _scheduleService: ScheduleService,
      private _errorHandler: ErrorHandlerService,
      private _callersStore: CallersStore,
      private _callpointsStore: CallpointsStore,
      private _projectsStore: ProjectsStore,
      private _visitStore: VisitsStore,
      private _alertStore: AlertStore,
      private _spinnerStore: SpinnerStore
   ) { }

   /*public clearCachedDiaries() {
      if (this._cachedCallerDiaries) {
         this._cachedCallerDiaries.clear();
      }
   }*/

   public loadDiaryEvents(callerId: number, projectId: number, overrideCache: boolean) {
      this._scheduleService.getSchedule(projectId, callerId).subscribe(
         (events: Event[]) => {
            this._diaryEvents.next(events);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // add the diary events to the cache.
   // if the cache limit is exceeded remove a set of events from the cache and replace with the new caller and diary
   // we have a lot of things updating these days, I think caching the diary is causing inconsitnies in the data
   /*private addToCache(callerId: number, diaryEvents: ReadonlyArray<Event>) {

      if (this._cachedCallerDiaries.size >= this.maxNumberOfCachedDiaries) {
         // get first caller in dictionary
         let mapIterator = this._cachedCallerDiaries.entries();
         let firstKV = mapIterator.next().value;
         let firtKey: number = firstKV[0];
         var result = this._cachedCallerDiaries.delete(firtKey);
      }

      this._cachedCallerDiaries.set(callerId, diaryEvents);
   }*/

   public loadScheduleForCaller(callerId): void {
      this.loadXmlSchedule(callerId);
      this.loadXmlScheduleRequest(callerId, this.selectedDiaryDayIndex);
   }

   // xml schedule is primarily used in the test scheduel work space to debug schedule code
   private loadXmlSchedule(callerId) {
      this._scheduleService.getXmlSchedule(callerId).subscribe(
         (schedule: string) => {
            // TODO: XmlSchedule endpoint returns 'undefined' for non-existant schedule, whereas JsonSchedule
            // endpoint returns empty string. Unify.
            this._scheduleXml.next(schedule);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // xml schedule is primarily used in the test scheduel work space to debug schedule request code
   private loadXmlScheduleRequest(callerId, dayIndex: number) {
      this._scheduleService.getXmlScheduleRequest(callerId, dayIndex).subscribe(
         (schedule: string) => {
            // TODO: XmlSchedule endpoint returns 'undefined' for non-existant schedule, whereas JsonSchedule
            // endpoint returns empty string. Unify.
            this._scheduleRequestXml.next(schedule);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public generateSchedule(callerId: number, scheduleType: string) {
      // If there are no callers currently optimising then reset the interval to default value.
      if (this.callersOptimising.length == 0) {
         this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
      }

      // be optimistic in setting state
      this.addCallerToOptimisingObservable(callerId);

      // When executing an optimisation the diary events collection
      // must be cleared and then populated again after ending the proccess
      this._diaryEvents.next([]);
      //this.addToCache(callerId, []);

      this.scheduleGenerationStarted.next({ callerId, status: 'Optimisation started' });

      // When optimising a caller, the dayIndex must be -1 to allow the back end to generate a complete
      // schedule.
      this._selectedDiaryDayIndex.next(-1);
      this.setSelectedDiaryDay(null);

      // We want to know how long the schedule generation takes, record the start time.
      this._callerScheduleStartTime = moment();

      this._scheduleService.generateSchedule(callerId, scheduleType).subscribe(
         (schedule: string) => {
            //this.pollScheduleStatus();
            this.pollSingleOutstandingCallerSchedule(callerId);
         },
         (error) => {
            //check if a 401 unauthrorised
            // if unauthroised then return to the login screen
            if (error.status == 401 || error.status == 403) {
               this._errorHandler.handleError(error);
            }

            this.removeCallerToOptimisingObservable(callerId);
            let response = error.error;
            this.errorOptimisingCaller.next({
               callerId: callerId,
               errorMessage: response.message,
            });
            // console.log(error)
         }
      );
   }

   public generateScheduleForDay(callerId: number, scheduleType: string, dayIndex: number) {
      // If there are no callers currently optimising then reset the interval to default value.
      if (this.callersOptimising.length == 0) {
         this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
      }

      // be optimistic in setting state
      this.addCallerToOptimisingObservable(callerId);

      this._selectedDiaryDayIndex.next(dayIndex);

      // When executing an optimisation the diary events collection
      // must be cleared and then populated again after ending the proccess
      // Commented out the clearing of the diary for the day optimisation. Agreed with product owners as
      // part of PSN-2775 performance optimsiations.
      //this._diaryEvents.next([]);
      //this.addToCache(callerId, []);

      this.scheduleGenerationStarted.next({ callerId, status: 'Optimisation started' });

      // We want to know how long the schedule generation takes, record the start time.
      this._callerScheduleStartTime = moment();

      this._scheduleService.generateScheduleForDay(callerId, scheduleType, dayIndex)
         .subscribe(
            (schedule: string) => {
               //this.pollScheduleStatus();
               this.pollSingleOutstandingCallerSchedule(callerId);
            },
            (error) => {
               this.removeCallerToOptimisingObservable(callerId);
               let response = error.error;
               this.errorOptimisingCaller.next({
                  callerId: callerId,
                  errorMessage: response.message,
               });
               // console.log(error)
            }
         );
   }

   public generateScheduleWithUpdatedFrequency(callerId: number) {
      // If there are no callers currently optimising then reset the interval to default value.
      if (this.callersOptimising.length == 0) {
         this._statusPollInterval = CallsmartUtils.STATUS_POLL_DEFAULT_INTERVAL;
      }

      // be optimistic in setting state
      this.addCallerToOptimisingWithUpdatedFrequencyObservable(callerId);

      this.scheduleGenerationStarted.next({ callerId, status: 'Frequency update started' });

      // We want to know how long the schedule generation takes, record the start time.
      this._callerScheduleStartTime = moment();

      this._scheduleService.generateScheduleWithUpdatedFrequency(callerId)
         .subscribe(
            (schedule: string) => {
               //this.pollScheduleStatus();
               this.pollSingleOutstandingCallerScheduleWithUpdatedFrequency(callerId);
            },
            (error) => {
               this.removeCallerFromoOptimisingWithUpdatedFrequencygObservable(callerId)
               let response = error.error;
               this.errorOptimisingCaller.next({
                  callerId: callerId,
                  errorMessage: response.message,
               });
               // console.log(error)
            }
         );
   }

   // this clears all vists from the schedul leaving behind only locked visits
   public clearScheduleUnlocked(callerId: number) {
      this._scheduleService.clearScheduleUnlocked(callerId).subscribe(
         (data: any) => {
            this.scheduleCleared.next(callerId);
            //refresh the schedule
            //this.loadScheduleForCaller(callerId);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // this clears all vists from the schedul
   public clearSchedule(callerId: number) {
      this._scheduleService.clearSchedule(callerId).subscribe(
         (data: any) => {
            this.scheduleCleared.next(callerId);
            //refresh the schedule
            //this.loadScheduleForCaller(callerId);
            this._callersStore.loadAllCallers(
               this._projectsStore.selectedProject.projectId
            );
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public unscheduleVisit({ callerId, dayIndex, visitIndex, date, callpointId, view }: {
      callerId: number;
      dayIndex: number;
      visitIndex: number;
      date: Date;
      callpointId: string;
      view: WorkspaceViewType;
   }) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .removeVisitFromSchedule(callerId, dayIndex, visitIndex)
         .subscribe(
            (schedule: EventsAndMetrics) => {
              let events: Event[] = schedule.events;

               this._spinnerStore.hideSpinner();
               this.visitRemoved.next(date);
               this.loadDiaryEventsAfterAction(events, callerId, view);
               let callpoint = this._callpointsStore.callpoints.find(
                  (callpoint) => callpoint.reference === callpointId
               );
               if (callpoint) {
                  callpoint.scheduledVisits--;
               }
               this.setSelectedDiaryEvent(null);
               // clear the selected diary day in the schedule
               this.setSelectedDiaryDay(null);
               // clear the selected visit in the schedule
               this.setSelectedVisit(null);

               this._callersStore._selectedCallerMetrics.next(schedule.metrics);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public removeLunchFromSchedule(callerId: number, dayIndex: number, date: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .removeLunchFromSchedule(callerId, dayIndex)
         .subscribe(
            (events: Event[]) => {
               this._spinnerStore.hideSpinner();
               this.visitRemoved.next(date);
               this.loadDiaryEventsAfterAction(events, callerId, view);

               this.setSelectedDiaryEvent(null);
               // clear the selected diary day in the schedule
               this.setSelectedDiaryDay(null);
               // clear the selected visit in the schedule
               this.setSelectedVisit(null);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public addLunchToSchedule(callerId: number, dayIndex: number, date: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService.addLunchToSchedule(callerId, dayIndex).subscribe(
         (scheduleWarnings: ScheduleWarnings) => {
            this._spinnerStore.hideSpinner();
            if (
               scheduleWarnings.warnings &&
               scheduleWarnings.warnings.length > 0
            ) {
               let warningList: string = '';
               scheduleWarnings.warnings.forEach((warning) => {
                  warningList = warningList + warning + '\n';
               });
               this._alertStore.sendAlert(new Alert('Warning', warningList));
            }
            this.loadDiaryEventsAfterAction(
               scheduleWarnings.events,
               callerId,
               view
            );
            // Notify when the new visit starts
            this.visitAdded.next(date);
         },
         (error) => {
            this._spinnerStore.hideSpinner();
            this._errorHandler.handleError(error);
         }
      );
   }

   public addOvernightToSchedule(callerId: number, dayIndex: number, date: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .addOvernightToSchedule(callerId, dayIndex)
         .subscribe(
            (scheduleWarnings: ScheduleWarnings) => {
               this._spinnerStore.hideSpinner();
               if (
                  scheduleWarnings.warnings &&
                  scheduleWarnings.warnings.length > 0
               ) {
                  let warningList: string = '';
                  scheduleWarnings.warnings.forEach((warning) => {
                     warningList = warningList + warning + '\n';
                  });
                  this._alertStore.sendAlert(new Alert('Warning', warningList));
               }
               this.loadDiaryEventsAfterAction(
                  scheduleWarnings.events,
                  callerId,
                  view
               );
               // Notify when the new visit starts
               this.visitAdded.next(date);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
         );
   }

   public removeOvernightFromSchedule(callerId: number, dayIndex: number, date: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .removeOvernightFromSchedule(callerId, dayIndex)
         .subscribe(
            (scheduleWarnings: ScheduleWarnings) => {
               this._spinnerStore.hideSpinner();
               if (scheduleWarnings.warnings && scheduleWarnings.warnings.length > 0) {
                  let warningList: string = '';
                  scheduleWarnings.warnings.forEach((warning) => {
                     warningList = warningList + warning + '\n';
                  });
                  this._alertStore.sendAlert(new Alert('Warning', warningList));
               }
               this.loadDiaryEventsAfterAction(scheduleWarnings.events, callerId, view);
               // Notify when the new visit starts
               this.visitAdded.next(date);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
      );
   }

   public lockVisit(callerId: number, dayIndex: number, visitIndex: number, date: Date, lockingType: LockingTypes, view: WorkspaceViewType) {
      this._scheduleService
         .lockVisit(callerId, dayIndex, visitIndex, lockingType)
         .subscribe(
            (events: Event[]) => {
               this.reloadDiaryAfterLockingAction(date, events, callerId, view);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
      );
   }

   public lockCallpointVisits(callerId: number, callpointReferences: string[], lockingType: LockingTypes, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .lockCallpointVisits(callerId, callpointReferences, lockingType)
         .subscribe(
            (events: Event[]) => {
               this._spinnerStore.hideSpinner();
               this.callpointVisitsLocked.next();
               this.loadDiaryEventsAfterAction(events, callerId, view);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
      );
   }

   public addVisitToSchedule(callerId: number, callpointId: string, dayIndex: number, date: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .addVisitToSchedule(callerId, callpointId, dayIndex)
         .subscribe(
            (schedule: EventsAndMetrics) => {
               let events: Event[] = schedule.events;
               this._spinnerStore.hideSpinner();
               // gets all the visits for scheduled the same day as the added one.
               let iniDay: moment.Moment = moment(date).startOf('day');
               let endDay: moment.Moment = moment(date).endOf('day');
               let dayVisits: Event[] = events.filter((event) => {
                  let eventStartDate: moment.Moment = moment(event.start);
                  return (
                     eventStartDate > iniDay &&
                     eventStartDate < endDay &&
                     event.eventType === EventTypes.visit
                  );
               });
               // The added visit is always the last visit in a day
               let addedVisit: Event = dayVisits[dayVisits.length - 1];
               this.loadDiaryEventsAfterAction(events, callerId, view);

               // Find the callpoint and update the number of scheduled visits
               let visit = this._visitStore.visits.find(
                  (visit) => visit.callpointId === +callpointId
               );
               let callpoint = this._callpointsStore.callpoints.find(
                  (callpoint) =>
                     callpoint.reference === visit.callpointReference
               );
               if (callpoint) {
                  callpoint.scheduledVisits++;
               }

               // Notify when the new visit starts
               if (addedVisit) {
                  this.visitAdded.next(addedVisit.start);
               }

               this._callersStore._selectedCallerMetrics.next(schedule.metrics);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
      );
   }

   public moveVisitInSchedule(callerId: number, callpointId: number, fromDayIndex: number, fromVisitIndex: number, toDayIndex: number,
      toDate: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .moveVisitInSchedule(callerId, callpointId, fromDayIndex, fromVisitIndex, toDayIndex, toDate)
         .subscribe(
            (scheduleWarnings: ScheduleWarnings) => {
               this._spinnerStore.hideSpinner();
               if (scheduleWarnings.warnings && scheduleWarnings.warnings.length > 0) {
                  let warningList: string = '';
                  scheduleWarnings.warnings.forEach((warning) => {
                     warningList = warningList + warning + '\n';
                  });
                  this._alertStore.sendAlert(new Alert('Warning', warningList));
               }
               this.loadDiaryEventsAfterAction(scheduleWarnings.events, callerId, view);
               // Notify when the new visit starts
               this.visitAdded.next(toDate);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this.visitMovedFailed.next();
               this._errorHandler.handleError(error);
            }
         );
   }

   public validateSwapDaysInSchedule(callerId: number, fromDayIndex: number, toDayIndex: number) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .validateSwapDaysInSchedule(callerId, fromDayIndex, toDayIndex)
         .subscribe(
            (scheduleWarnings: ScheduleWarnings) => {
               this._spinnerStore.hideSpinner();
               let warningList: string = '';
               if (scheduleWarnings.warnings && scheduleWarnings.warnings.length > 0) {
                  warningList = scheduleWarnings.warnings.join('\n');
                  // scheduleWarnings.warnings.forEach(warning => {
                  //    warningList = warningList + warning + '\n';
                  // })
               }
               this.daySwapValidationResult.next(warningList);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
      );
   }

   public swapDaysInSchedule(callerId: number, fromDayIndex: number, toDayIndex: number, toDate: Date, view: WorkspaceViewType) {
      this._spinnerStore.showSpinner();
      this._scheduleService
         .swapDaysInSchedule(callerId, fromDayIndex, toDayIndex)
         .subscribe(
            (scheduleWarnings: ScheduleWarnings) => {
               this._spinnerStore.hideSpinner();
               let warningList: string = '';
               if (scheduleWarnings.warnings && scheduleWarnings.warnings.length > 0) {
                  warningList = scheduleWarnings.warnings.join('\n');
                  // scheduleWarnings.warnings.forEach(warning => {
                  //    warningList = warningList + warning + '\n';
                  // })
               }
               this.loadDiaryEventsAfterAction(scheduleWarnings.events, callerId, view);
               this.setSelectedDiaryDayAfterSwap(toDate);
            },
            (error) => {
               this._spinnerStore.hideSpinner();
               this._errorHandler.handleError(error);
            }
      );
   }

   public setSelectedDiaryEvent(event: Event) {
      // check if caller has changed
      if (!event) {
         if (this.selectedDiaryEvent) {
            this._selectedDiaryEvent.next(event);
         }
      }
      else {
         if (this.selectedDiaryEvent) {
            if (
               /*this.selectedDiaryEvent.callpointId !== event.callpointId &&*/ this
                  .selectedDiaryEvent.start !== event.start
            ) {
               this._selectedDiaryEvent.next(event);
            }
         }
         else {
            this._selectedDiaryEvent.next(event);
         }
      }
   }

   public setSelectedDiaryDay(selectedDate: Date) {
      if (this.selectedDiaryDay !== selectedDate) {
         this._selectedDiaryDay.next(selectedDate);
      }
   }

   public setSelectedDiaryDayAfterSwap(selectedDate: Date) {
      if (this.selectedDiaryDayAfterSwap !== selectedDate) {
         this._selectedDiaryDayAfterSwap.next(selectedDate);
      }
   }

   public setSelectedContractedWorkingTime(workingTime: Date[]) {
      if (this.selectedContractedWorkingTime !== workingTime) {
         this._selectedContractedWorkingTime.next(workingTime);
      }
   }

   public setSelectedDayDiaryEvents(events: Event[]) {
      if (this.selectedDayDiaryEvents !== events) {
         this._selectedDayDiaryEvents.next(events);
      }
   }

   public setSelectedVisit(visit: VisitSelection) {
      if (this.selectedVisit !== visit) {
         this._selectedVisit.next(visit);
      }
   }

   public getDrivetimeVersion() {
      this._scheduleService.getDrivetimeVersion().subscribe(
         (version: string) => {
            // console.log('drivetime version', version);

            this._drivetimeVersion.next(version);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public getOptimiserVersion() {
      this._scheduleService.getOptimiserVersion().subscribe(
         (version: string) => {
            // console.log('optimiser version', version);

            this._optimiserVersion.next(version);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   // Updates the diary events locking a visit.
   private reloadDiaryAfterLockingAction(date: Date, events: Event[], callerId: number, view: WorkspaceViewType) {
      this.visitLocked.next(date);
      this.loadDiaryEventsAfterAction(events, callerId, view);
   }

   // this method adds the caller id to the optimising observable
   // allowing other subscribers to then enable or diable functionality if the caler is a member of the
   // observable, alternatively they can show and hide progress
   private addCallerToOptimisingObservable(callerId: number) {
      let optimising = this._callersOptimising.getValue();
      optimising.push(callerId);
      this._callersOptimising.next(optimising);
   }

   // this method removes the caller id to the optimising observable
   // allowing other subscribers to then enable or disable functionality if the caller is a member of the
   // observable, alternatively they can show and hide progress
   private removeCallerToOptimisingObservable(callerId: number) {
      let optimising = this._callersOptimising.getValue();
      var index = optimising.findIndex((c) => c === callerId);
      if (index > -1) {
         optimising.splice(index, 1);
      }

      // If there are no more callers optimising then notify that all
      // callers have finished optimising.
      if (optimising.length == 0) {
         this.multiScheduleGenerationCompleted.next(callerId);
      }

      this._callersOptimising.next(optimising);
   }

   private addCallerToOptimisingWithUpdatedFrequencyObservable(callerId: number) {
      let optimising = this._callersOptimising.getValue();
      optimising.push(callerId);
      this._callersOptimisingWithUpdatedFrequency.next(optimising);
   }


   private removeCallerFromoOptimisingWithUpdatedFrequencygObservable(callerId: number) {
      let optimising = this._callersOptimisingWithUpdatedFrequency.getValue();
      var index = optimising.findIndex((c) => c === callerId);
      if (index > -1) {
         optimising.splice(index, 1);
      }

      this._callersOptimisingWithUpdatedFrequency.next(optimising);
   }

   // private pollScheduleStatus() {
   //    // if the handle is 0 then the timer is not running start the polling
   //    if (this._handlePollingTimer == null) {
   //       //When you use () => TypeScript preserves the lexical scope...
   //       //which just means this means the same inside the expression as it does outside of the expression.
   //       //this._handlePollingTimer = setInterval(() => this.pollOutstandingCallerSchedules(), 1000);
   //       setTimeout(() => {
   //          this.pollOutstandingCallerSchedules();
   //       }, 1000);
   //    }
   // }

   /*
      We stop using this method because everytime is called the pending optimasing callers are subscribe
      to the checkScheduleStatus method. So with this meth
   */
   // private pollOutstandingCallerSchedules() {

   //    let optimising = this._callersOptimising.getValue();

   //    if (optimising.length === 0) {
   //       clearInterval(this._handlePollingTimer);
   //       this._handlePollingTimer = null;
   //       return;
   //    }

   //    optimising.forEach(optCallerId => {
   //       if (optimising.includes(optCallerId)) {
   //          this._scheduleService.checkScheduleStatus(optCallerId)
   //             .subscribe((data: any) => {
   //                if (data.status == 'Ready') {
   //                   if (optimising.includes(optCallerId)) {
   //                      console.log("Schedule generated for: " + optCallerId);
   //                      // javascript is single threaded unless we use web workers
   //                      // there should be no concurrency when manipulating the collection.
   //                      // if this was c# i would use a concurrent collection
   //                      this.removeCallerToOptimisingObservable(optCallerId);
   //                      this.scheduleGenerationCompleted.next(optCallerId);
   //                      this.loadScheduleForCaller(optCallerId);
   //                   }
   //                }
   //                else if (data.status == 'Error') {
   //                   this.removeCallerToOptimisingObservable(optCallerId);
   //                   // let system know there was an error
   //                   this.errorOptimisingCaller.next(data);
   //                }
   //                else {
   //                   setTimeout(() => {
   //                      this.pollSingleOutstandingCallerSchedule(optCallerId);
   //                   }, 1000);
   //                }
   //                //console.log("id's pending to optimise: " + optimising.toString());
   //             },
   //                (error) => {
   //                   // stop polling for this caller
   //                   console.log("Schedule NOT generated for: " + optCallerId);
   //                   this.removeCallerToOptimisingObservable(optCallerId);

   //                   // let system know there was an error
   //                   this.errorOptimisingCaller.next(optCallerId);

   //                   console.log(error)
   //                });
   //       }
   //    });

   // }


   private pollSingleOutstandingCallerScheduleWithUpdatedFrequency(callerId: number) {
      let optimising = this._callersOptimisingWithUpdatedFrequency.getValue();

      if (optimising.includes(callerId)) {
         this._scheduleService.checkScheduleStatus(callerId).subscribe(
            (data: any) => {
               if (data.status == 'Ready') {
                  if (optimising.includes(callerId)) {
                     //console.log("Schedule generated for: " + callerId);
                     // javascript is single threaded unless we use web workers
                     // there should be no concurrency when manipulating the collection.
                     // if this was c# i would use a concurrent collection
                     this.removeCallerFromoOptimisingWithUpdatedFrequencygObservable(callerId);

                     this._statusPollInterval = moment().diff(
                        this._callerScheduleStartTime
                     );
                     //var duration = moment.duration(this._statusPollInterval);
                     //console.log('Completed in minutes:', duration.as('minutes'));

                     this.scheduleGenerationCompleted.next({ callerId: callerId, status: 'Frequency Update completed' });
                     //this.loadScheduleForCaller(callerId);
                     this._callersOptimisingStatus.next({
                        callerId: callerId,
                        status: 'Complete',
                     });
                  }
               }
               else if (data.status == 'Error') {
                  this.removeCallerFromoOptimisingWithUpdatedFrequencygObservable(callerId);
                  // let system know there was an error
                  this.errorOptimisingCaller.next(data);
                  this._callersOptimisingStatus.next({
                     callerId: callerId,
                     status: 'Error',
                  });
               }
               else {
                  //console.log('Polling...', this._statusPollInterval);
                  setTimeout(() => {
                     this.pollSingleOutstandingCallerScheduleWithUpdatedFrequency(callerId);
                  }, this._statusPollInterval);

                  this._callersOptimisingStatus.next({
                     callerId: callerId,
                     status: data.status,
                  });
               }

               //console.log("id's pending to optimise: " + optimising.toString());
            },
            (error) => {
               // stop polling for this caller
               // console.log("Schedule NOT generated for: " + callerId);
               this.removeCallerToOptimisingObservable(callerId);

               // let system know there was an error
               this.errorOptimisingCaller.next(callerId);
               this._callersOptimisingStatus.next({
                  callerId: callerId,
                  status: 'Error',
               });

               // console.log(error)
            }
         );
      }
   }

   // Each caller asks for its own status until the optimisation is finished/Error
   private pollSingleOutstandingCallerSchedule(callerId: number) {
      let optimising = this._callersOptimising.getValue();

      if (optimising.includes(callerId)) {
         this._scheduleService.checkScheduleStatus(callerId).subscribe(
            (data: any) => {
               if (data.status == 'Ready') {
                  if (optimising.includes(callerId)) {
                     //console.log("Schedule generated for: " + callerId);
                     // javascript is single threaded unless we use web workers
                     // there should be no concurrency when manipulating the collection.
                     // if this was c# i would use a concurrent collection
                     this.removeCallerToOptimisingObservable(callerId);

                     this._statusPollInterval = moment().diff(
                        this._callerScheduleStartTime
                     );
                     //var duration = moment.duration(this._statusPollInterval);
                     //console.log('Completed in seconds:', duration.as('seconds'));

                     this.scheduleGenerationCompleted.next({ callerId: callerId, status: 'Optimisation completed' });
                     //this.loadScheduleForCaller(callerId);
                     this._callersOptimisingStatus.next({
                        callerId: callerId,
                        status: 'Complete',
                     });
                  }
               }
               else if (data.status == 'Error') {
                  this.removeCallerToOptimisingObservable(callerId);
                  // let system know there was an error
                  this.errorOptimisingCaller.next(data);
                  this._callersOptimisingStatus.next({
                     callerId: callerId,
                     status: 'Error',
                  });
               }
               else {
                  //console.log('Polling...', this._statusPollInterval);
                  setTimeout(() => {
                     this.pollSingleOutstandingCallerSchedule(callerId);
                  }, this._statusPollInterval);

                  this._callersOptimisingStatus.next({
                     callerId: callerId,
                     status: data.status,
                  });
               }

               //console.log("id's pending to optimise: " + optimising.toString());
            },
            (error) => {
               // stop polling for this caller
               // console.log("Schedule NOT generated for: " + callerId);
               this.removeCallerToOptimisingObservable(callerId);

               // let system know there was an error
               this.errorOptimisingCaller.next(callerId);
               this._callersOptimisingStatus.next({
                  callerId: callerId,
                  status: 'Error',
               });

               // console.log(error)
            }
         );
      }
   }

   // This method loads the diary events after performing an action on the calendar like
   // unscheduling or locking a visit.
   private loadDiaryEventsAfterAction(events: Event[], callerId: number, view: WorkspaceViewType) {
      this._diaryEvents.next(events);

      // for any workspace other callers you should resunc the callers data straight away
      // else it can be delayed untill the user navigates off the workspace
      if (view === WorkspaceViewType.Callers) {
         this._callersStore.loadAllCallersWithoutMapPoints(this._projectsStore.selectedProject.projectId);
      }
      else {
         this.workspaceShouldRefreshDataOnNavigateAway.next({
            view: view,
            actionGroup: ActionGroupings.LoadAllCallersWithoutMapPoints,
         });
         this.workspaceShouldRefreshDataOnNavigateAway.next({
            view: view,
            actionGroup: ActionGroupings.LoadVisitsForCaller,
            data: [callerId]
         });
      }
   }
}
