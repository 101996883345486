import { Component, ElementRef, AfterViewInit, DoCheck, OnDestroy, Input, Output, ViewChild, EventEmitter, IterableDiffers } from '@angular/core';
import { Notification } from "../../models/notification";
import { DomHandler } from 'primeng/primeng';
import { Subscription } from 'rxjs';

// Some sort of a comment describing what this component is and what it does would be useful.
@Component({
   selector: 'callsmart-growl',
   templateUrl: './growl.component.html',
   providers: [DomHandler]
})
export class CSGrowlComponent implements AfterViewInit, DoCheck, OnDestroy {

   /* code modified from: https://github.com/primefaces/primeng/blob/master/src/app/components/growl/growl.ts */

   @Input() sticky: boolean;

   @Input() life: number = 3000;

   @Input() style: any;

   @Input() styleClass: string;

   @Output() onClick: EventEmitter<any> = new EventEmitter();

   @Output() onClose: EventEmitter<any> = new EventEmitter();

   @Output() onMouseOver: EventEmitter<any> = new EventEmitter();

   @Output() onMouseLeave: EventEmitter<any> = new EventEmitter();

   @ViewChild('container') containerViewChild: ElementRef;

   _value: Notification[];

   zIndex: number;

   container: HTMLDivElement;

   timeout: any;

   differ: any;

   subscription: Subscription;

   closeIconClick: boolean;


   constructor(public el: ElementRef, public domHandler: DomHandler, public differs: IterableDiffers) {
      this.zIndex = DomHandler.zindex;
      this.differ = differs.find([]).create(null);
   }

   ngAfterViewInit() {
      if (this.containerViewChild) {
         this.container = <HTMLDivElement>this.containerViewChild.nativeElement;

         if (!this.sticky) {
            this.initTimeout();
         }
      }
   }

   @Input() get value(): Notification[] {
      return this._value;
   }

   set value(val: Notification[]) {
      this._value = val;
   }

   ngDoCheck() {
      if (this.container) {
         let changes = this.differ.diff(this.value);
         if (changes) {
            //this.handleValueChange();
            changes.forEachAddedItem((notification) => {
               this.autoCloseNotification(notification.item);
            });
            changes.forEachRemovedItem((record) => {
              //  console.log('removed ' + record.item);
            });
         }
      }
   }

   private autoCloseNotification(notification: Notification) {
      if (!notification.isMouseOver) {
         notification.timeout = setTimeout(() => {
            if (this.value.includes(notification)) {
               this.removeItem(notification);
            }
         }, this.life);
      }
      else {
         if (this.value.length > 0) {
            notification.timeout = setTimeout(() => this.autoCloseNotification(notification), this.life);
         }
      }
   }

   initTimeout() {
      if (this.value) {
         this.value.forEach(notification => {
            if (notification.timeout) {
               clearTimeout(notification.timeout)
            }
         });
         this.value.forEach(notification => this.autoCloseNotification(notification));
      }
   }

   removeItem(notification: Notification) {
      this.remove(notification)
   }

   // remove(index: number, msgel: any) {
   //    this.closeIconClick = true;
   //    // setTimeout(() => {
   //       this.onClose.emit({ message: this.value[index] });
   //       this.value.splice(index, 1);
   //       this.domHandler.fadeOut(msgel, 250);
   //    // }, 250);
   // }

   remove(notification: Notification, /*msgel: any*/) {
      this.closeIconClick = true;
      // let indexToRemove = this.value.indexOf(notification);
      // let msgel = document.getElementsByClassName("ui-growl-item-container")[indexToRemove];
      // this.domHandler.fadeOut(msgel, 250);
         let indexToRemove = this.value.indexOf(notification);
         this.onClose.emit({ message: this.value[indexToRemove] });
         this.value.splice(indexToRemove, 1);
   }

   removeAll() {
      if (this.value && this.value.length) {
         this.domHandler.fadeOut(this.container, 250);

         setTimeout(() => {
            this.value.forEach((msg, index) => this.onClose.emit({ message: this.value[index] }));
            this.value.splice(0, this.value.length);
         }, 250);
      }
   }

   onMessageClick(i: number) {
      if (this.closeIconClick){
         this.closeIconClick = false;
      }
      else{
         this.onClick.emit({ message: this.value[i] });
      }
   }

   onMessageMouseOver(i: number) {
      let notification: Notification = this.value[i];
      if (notification) {
         notification.isMouseOver = true;
         clearTimeout(notification.timeout);
         this.autoCloseNotification(notification);
         this.onMouseOver.emit({ message: notification });
      }
   }

   onMessageMouseLeave(i: number) {
      let notification: Notification = this.value[i];
      if (notification) {
         notification.isMouseOver = false;
         this.onMouseLeave.emit({ message: notification });
      }
   }

   ngOnDestroy() {
      if (!this.sticky) {
         if (this.value) {
            this.value.forEach(notification => {
               if (notification.timeout) {
                  clearTimeout(notification.timeout)
               }
            });
         }
      }

      if (this.subscription) {
         this.subscription.unsubscribe();
      }
   }

}

