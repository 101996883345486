import { Component, OnInit } from '@angular/core';
import { ApplicationStore } from 'app/stores/application-store';

/**
 * Logout component for the /logout route. This is a solution to deal with the issue
 * of stopping the user logging out if the form has any dirty data in it. At the moment
 * the PendingChangesGuard is triggured on navigation from one route to another. Therefore to
 * allow the guard to block the logout the user option from the menu must result in a route navigation.
 * 
 * It's not great that we have to create a component with empty HTML template to serve
 * this purpose. Maybe there is a better way to achieve this.
 */
@Component({
   template: ''
})
export class LogoutComponent implements OnInit {
   constructor(private _applicationStore: ApplicationStore) { }

   public ngOnInit() {
      if(this._applicationStore.projectsStore.selectedProject !== null) {
         this._applicationStore.projectsStore.closeProject(this._applicationStore.projectsStore.selectedProject.projectId, 
                                                           this._applicationStore.authenticationStore.loggedInUser.userId);
      }
      this._applicationStore.authenticationStore.logout();
   }
}
