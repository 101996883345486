export class DataTableColumnHelper {

   // Gets the items which are repeated in all of array items from the bidimensional array passed as a parameter
   public static getEmptyValueIndexes(emptyColumnValues: number[][]): number[] {

      let emptyColumns: number[] = [];
      if (emptyColumnValues && emptyColumnValues.length > 0) {         

         let firtsRowEmptyIndexes: number[] = emptyColumnValues[0];
         //Searches if the empty cells for the same row are also empty in the rest of the rows
         if (emptyColumnValues.length > 1) {
            // loop through the first row items
            for (let j: number = 0; j <= firtsRowEmptyIndexes.length - 1; j++) {
               let emptyIndexNotFound: boolean = false;
               // loop throght the other rows to check if the cells with empty values matches
               for (let i: number = 1; i <= emptyColumnValues.length - 1; i++) {
                  if (emptyColumnValues[i].indexOf(firtsRowEmptyIndexes[j]) === -1) {
                     // The current row doesn't have an empty value in the same position as the first row.
                     // Breaks this loop.
                     emptyIndexNotFound = true;
                     break;
                  }
               }
               if (!emptyIndexNotFound) {
                  emptyColumns.push(firtsRowEmptyIndexes[j]);
               }
            }
         }
         else {
            emptyColumns = firtsRowEmptyIndexes;
         }
      }
      return emptyColumns;
   }
}
