<div class="container-fluid no-padding">
   <div class="row no-padding" *ngIf="isVisible" style="margin: 0px">
      <!-- <div class="col-lg-7 col-md-7" style="margin-top: 10px; color:#222222; padding: 0px; line-height:0"> -->
      <div class="no-padding display-flex-colum" style="margin-top: 20px; color:#222222; line-height:0; float:left;">
         <div *ngIf="currentView !=='cycleView' && selectedCycleWeek > 1" class="callsmart-link-active" (click)="back(primeCalendar)"
            style="display:inline; margin:0px; padding: 0px; text-align: left;background-image: none;">
            <i class="cs-icon icon-arrow-left callsmart-link-active" style="margin-left:36px" aria-hidden="true"></i>
         </div>

         <div *ngIf="currentView === 'cycleView'" style="margin:0px; padding: 0px; display: inline-block; vertical-align: 3px; width: 400px;">
            <span class="h4" style="margin-left: 59px;">Cycle</span>
            <span class="h5">|</span>
            <span class="h5">{{calendarTitle}}</span>
         </div>

         <div *ngIf="currentView !== 'cycleView'" style="margin:0px; padding: 0px; display: inline-block; vertical-align: 3px; text-align: center; width: 350px;"
            [ngStyle]="{'margin-left': selectedCycleWeek == 1 ? '59px' : '0px'}">
            <span class="h4" *ngIf="selectedCycleWeek == 1">Week {{selectedCycleWeek}}</span>
            <span class="h4" *ngIf="selectedCycleWeek > 1">Week {{selectedCycleWeek}}</span>
            <span class="h5">|</span>
            <span class="h5">{{calendarTitle}}</span>
         </div>

         <div *ngIf="currentView !=='cycleView' && selectedCycleWeek < numberOfWeeks" class="callsmart-link-active" (click)="next(primeCalendar)"
            style="display: inline; margin: 0px; padding: 0px; text-align: left;background-image: none;">
            <i class="cs-icon icon-arrow-right callsmart-link-active" aria-hidden="true"></i>
         </div>

      </div>
      
      <div *ngIf="numberOfWeeks > 1" class="no-padding display-flex-colum" style="margin-top: 10px; margin-right:20px; color:#222222; line-height:0; float:right;">
         <callsmart-inputswitch [(ngModel)]="isWeekViewSelected" onLabel="Week" offLabel="Cycle" (onChange)="changeCurrentView($event)">
         </callsmart-inputswitch>
      </div>
      <div *ngIf="numberOfWeeks > 1 && canMinimiseCalendar" class="no-padding display-flex-colum" 
            style="margin-top: 10px; margin-right: 15px; padding-right:15px; border-right: solid 1px #222222; color:#222222; line-height:0; float:right;">
         <callsmart-inputswitch [(ngModel)]="isFullCalendarMaximised" onLabel="Full diary" offLabel="Header only" 
                                [disabled]="showHeaderOnlyOptionDisabled"  (onChange)="minimiseCalendarToHeaders($event)">
         </callsmart-inputswitch>
      </div>
      <div *ngIf="numberOfWeeks === 1 && canMinimiseCalendar" class="no-padding display-flex-colum" 
            style="margin-top: 10px; margin-right: 15px; padding-right:15px; color:#222222; line-height:0; float:right;">
         <callsmart-inputswitch [(ngModel)]="isFullCalendarMaximised" onLabel="Full diary" offLabel="Header only" 
                                [disabled]="showHeaderOnlyOptionDisabled"  (onChange)="minimiseCalendarToHeaders($event)">
         </callsmart-inputswitch>
      </div>
      <div class="col-xs-12" style="margin-top: 10px; padding: 1px;">
         <p-schedule [events]="events" [header]="header" [eventLimit]="4" [nowIndicator]="false" weekNumbers="true" [allDaySlot]="false"
            timezone="local" [options]="options" slotDuration="00:30:00" [weekNumbers]="false" [contentHeight]="calendarHeight"
            [aspectRatio]="aspectRatio" [defaultView]="currentView" [businessHours]="businessHours" [defaultDate]="initialDate"
            [editable]="canEditEvents" [droppable]="canExternalDragDrop" [eventOverlap]="canInternalDragDrop" [eventStartEditable]="canInternalDragDrop"
            (onDayClick)="handleDayClick($event)" (onEventClick)="handleEventClick($event)" (onViewRender)="handleViewRender($event)"
            #primeCalendar></p-schedule>

      </div>
   </div>
</div>
