<div class="row">
   <div class="standard-workspace col-md-10" style="width: calc(100% - 260px);">

      <callsmart-caller-carousel ></callsmart-caller-carousel>

      <div class="collapsed-disabled-splitter-bar" *ngIf="isMapHidden">
         <div class="expand-splitter">
            <button type="button" (click)="displayMap()" class="expand-splitter-button" pTooltip="Show map" tooltipPosition="top">
               <i class="cs-icon icon-arrow-down callsmart-link-active show-hide-arrow-splitter" aria-hidden="true"></i>
            </button>
         </div>
      </div>

      <!-- Button attached to the splitter -->
      <div class="collapse-splitter" #hideMapButton *ngIf="isCollapseMapButtonVisible">
         <button type="button" (click)="displayMap()" class="collapse-splitter-button" pTooltip="Hide map" tooltipPosition="top">
            <i class="cs-icon icon-arrow-up callsmart-link-active show-hide-arrow-splitter" aria-hidden="true"></i>
         </button>
      </div>

      <div [ngStyle]="{'height.px': workspaceHeight, 'margin-top.px': marginTop}">
         <horizontal-split-pane #box1 [primary-component-minsize]="minMapSize" secondary-component-minsize="35" local-storage-key="split-pane"
            (on-change)="onHChange($event)" [secondary-component-toggled-off]="false" [primary-component-toggled-off]="isMapHidden">
            <div class="split-pane-content-primary">
               <div class="upper">
                  <div [style.height.px]="mapHeight">
                     <callsmart-google-map #callsmartMap *ngIf="!isMapHidden"
                        (markerClicked)="onMarkerClicked($event)"
                        (onMapReady)="setMap($event)"
                        (googleMapIdle)="onGoogleMapIdle($event)"
                        (googleMapTilesLoaded)="onGoogleMapTilesLoaded($event)"
                        (googleMapResize)="onGoogleMapResize($event)"
                        (showFrequencySelected)="onShowFrequencySelected($event)" 
                        [optimiseMarker]="optimiseMapMarkers"
                        [clusterMarkerImagePath] ="clusterImagePath"
                        [isCtrlKeyPressed]="isCtrlKeyPressed"
                        [containerHeight]="mapHeight"
                        [displayFrequencyOption]="true"
                        >
                     </callsmart-google-map>
                  </div>
               </div>
            </div>
            <div class="split-pane-content-secondary" style="height:100%;">
               <div class="lower" [style.height.px]="gridHeight">
                  <callsmart-callpoints-list 
                     [scrollHeight]="scrollHeight" 
                     [scrollWidth]="scrollWidth" 
                     (rowSelected)="onRowSelected($event)"
                     (rowUnSelected)="onRowUnSelected($event)" 
                     (rowMultiSelected)="onRowMultiSelected($event)"
                     (allRowsSelected)="allRowsSelected($event)" 
                     (columnChanged)="onListColumnHeaderChanged($event)">
                  </callsmart-callpoints-list>
               </div>
            </div>
         </horizontal-split-pane>
      </div>
   </div>

   <!-- Contextual Panel -->
   <!-- The separate contextual panel component has been removed since it only served as a
   wrapper to the following components. Any events raised by these subcomponents would have
   to be passed through the contextual panel component without any added benefit, removing it
   has simplified event handling. -->
   <div class="contextual-panel col-md-3">
      <div class="small-text cs-color-white contextualPanel-background-color">
         <contextual-panel
            [showScheduleExport]="false"
            [showCallerExport]="false"
            [showCallpointExport]="true">
         </contextual-panel>
      </div>
   </div>
</div>
