import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { User } from 'app/models/user';
import { environment } from 'environments/environment';
import { Company } from 'app/models/company';
import { HeartBeatData } from 'app/models/heartBeatData';

@Injectable()
export class AuthService {

   // user logine in
   private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
   public user$: Observable<User> = this._user.asObservable();

   user: User;
   acountUrl = `${environment.baseUrl}api/account`;

   constructor(private http: HttpClient) { }

   public get loggedInUser() {
      return this._user.getValue();
   }

   public login(email: string, password: string) {
      return this.http.post<User>(`${this.acountUrl}/Login`, { 'Email': email, 'Password': password })
         .pipe(retry(1)).pipe(
            catchError(error => observableThrowError(error)));
   }
   
   public expiredLogin(email: string, oldpassword: string, newpassword: string) {
      return this.http.post<User>(`${this.acountUrl}/ExpiredLogin`, 
         { 'Email': email, 'OldPassword': oldpassword, 'NewPassword': newpassword })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public changePassword(email: string, oldpassword: string, newpassword: string) {
      return this.http.post(`${this.acountUrl}/ChangePassword`,
         { 'Email': email, 'OldPassword': oldpassword, 'NewPassword': newpassword })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public setUserHeartBeat(userId: number, data: HeartBeatData) {
      return this.http.post(`${this.acountUrl}/users/${userId}/heartbeat`, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public clearUserProjectHeartBeat(userId: number, data: HeartBeatData) {
      return this.http.post(`${this.acountUrl}/users/${userId}/project/${data.projectId}/heartbeat`, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public resetPassword(username: string) {
      return this.http.post(`${this.acountUrl}/resetPassword/`, username)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public logout() {
      this.user = null;

      return this.http.get(`${this.acountUrl}/Logout`)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public isAuthenticated() {
      return this.http.get<User>(`${this.acountUrl}/isAuthenticated`)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   public setUser(user: User) {
      this._user.next(user);
   }


   public getUserCompany(userId: number): Observable<any> {
      let url: string = `${this.acountUrl}/users/${userId}/company`;
      return this.http.get(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let companyObjects: Company;
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise to Typescript objects
                  try {
                     companyObjects = jsonConvert.deserialize(response, Company);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }
                  return companyObjects;
               }),
            catchError(error => observableThrowError(error)));
   }
}
