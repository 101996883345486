/**
 * Model class for the main dashboard screen.
 */
export class MainDashboard {

   // Total travel time requires formatting in the HTML hence each of the time
   // components are held seperately.
   public totalTravelTimeDay: number = 0;
   public totalTravelTimeHour: number = 0;
   public totalTravelTimeMinute: number = 0;

   // Values for the summary and stat boxes at the bottom of the view.
   public avgVisitsPerDay: number = 0;
   public co2Footprint: string = '';
   public visitsBooked: number = 0;
   public visitsBookedPercentage: number = 0;
   public noOfVisits: number = 0;
   public overnights: number = 0;
   public travelDistance: number = 0;
   public distanceUnitLabel:string;
   public distanceUnitIsMiles:boolean;

   public avgTravelTimeBetweenVisit: number = 0;
   public avgTravelDistanceBetweenVisit: number = 0;
   public avgOneWayDailyCommuteTime: number = 0;
   public avgOneWayDailyCommuteDistance: number = 0;

   // Display symbol either grams (g) or tonnes (t) for Co2 emission figures.
   // Defaults to symbol for grams
   public co2Unit: string = 'g';
}
