import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment/moment';

import { Project } from 'app/models/project';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';

@Component({
   selector: 'callsmart-roll-project-forward-dialog',
   templateUrl: './roll-project-forward-dialog.component.html',
})
export class RollProjectForwardDialogComponent implements OnInit {

   public startCycleDate: Date;
   public endCycleDate: Date;
   public cycleStartDateStr: string;
   public cycleEndDateStr: string;
   public copyProjectName: string;
   public en: any; // contains new names for days of week and months

   public dayOfWeekError: boolean;
   public cycleDateInPastError: boolean;

   // Project model used by this dialog.
   @Input() project: Project;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() roll = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   public constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   public ngOnInit() {
      this.copyProjectName = this.project.name + ' - roll forward'

      if(this.project.scheduleEndDate.getDay() === this.project.scheduleStartDate.getDay()){
         this.startCycleDate = this.project.scheduleEndDate;
         this.cycleStartDateStr = this.formatDate(this.startCycleDate);
         this.endCycleDate = this.getCycleEndDate(moment(this.startCycleDate));
         this.cycleEndDateStr = this.formatDate(this.endCycleDate);
      }

      // This property customises all calendar names and formats to be displayed according to the design.
      this.en = {
         firstDayOfWeek: 1,
         dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
         dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
         monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         today: 'Today',
         clear: 'Clear'
      };

   }

   public onRollForward(form) {

      let newProjectName = form.value.name;
      let rollForwardDate = this.startCycleDate;

      this._applicationStore.projectsStore.rollProject(this.project.projectId, newProjectName,this._applicationStore.authenticationStore.loggedInUser.userId,rollForwardDate);
      this.display = false;
      this.roll.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Event handler triggered when the start cycle date textbox loses the focus.
   // Sets the start cycle date and works out the end cycle date.
   public onCallCycleStartDateLostFocus(event) {
      this.workOutCycleEndDate();
      this.validateStartDateDayOfWeek();
   }

   public workOutCycleEndDate() {
      let typedDate: moment.Moment = moment(this.cycleStartDateStr, "DD/MM/YYYY");
      // Checks if te typed date is valid
      if (typedDate.isValid()) {
         this.startCycleDate = typedDate.toDate();
         // Works out the end cycle date.
         this.endCycleDate = this.getCycleEndDate(typedDate);
         this.cycleEndDateStr = this.formatDate(this.endCycleDate);
      }
      else {
         this.cycleStartDateStr = this.startCycleDate
            ? this.formatDate(this.startCycleDate)
            : "";
      }
   }

   // Event handler triggered when a date is selected in the calendar.
   // Sets the start cycle date and works out the end cycle date.
   public onDateSelected(selectedDate: Date) {
      this.startCycleDate = selectedDate;
      this.cycleStartDateStr = this.formatDate(this.startCycleDate);
      // Sets the start cycle date as a string.
      let momentStartDate: moment.Moment = moment(this.startCycleDate.getDate() + '-' +
         (this.startCycleDate.getMonth() + 1) + '-' +
         this.startCycleDate.getFullYear(), "DD/MM/YYYY");
      // Works out the end cycle date.
      this.endCycleDate = this.getCycleEndDate(momentStartDate);
      // Sets the end cycle date as a string.
      this.cycleEndDateStr = this.formatDate(this.endCycleDate);

      this.validateStartDateDayOfWeek();
      this.validateStartDateNotInPast();
   }

   // Checks whether a date (formed by day, month and year)
   // is between a range defined by the start and end cycle dates.
   public isCalendarDateInRange(day, month, year) {
      let calendarDate = new Date(year, month, day);
      if (this.startCycleDate && this.endCycleDate) {
         return (calendarDate >= this.startCycleDate &&
            calendarDate <= this.endCycleDate) ? true : false;
      }
      return false;
   }


   // Returns the date passed as a parameter as a string with 'dd/mm/yyyy' format.
   private formatDate(date): string {
      var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) {
         month = '0' + month;
      }
      if (day.length < 2) {
         day = '0' + day;
      }

      return [day, month, year].join('/');
   }

   // Works out the end cycle date based on the start cycle date and the number of week.
   private getCycleEndDate(startDate: moment.Moment): Date {
      if (this.project.projectSettings.callCycleLength && this.isInt(this.project.projectSettings.callCycleLength) &&
         this.project.projectSettings.callCycleLength >= 1 && this.project.projectSettings.callCycleLength <= 52) {
         let momentEndDate: moment.Moment = startDate.clone().add(this.project.projectSettings.callCycleLength, 'w')
            .subtract(1, 'd');
         return momentEndDate.toDate();
      }
      return startDate.toDate();
   }

   // Checks if  value is an interger.
   private isInt(value) {
      if (isNaN(value)) {
         return false;
      }
      var x = parseFloat(value);
      return (x | 0) === x;
   }

   // validate the start date is the same day of the week as the base project
   private validateStartDateDayOfWeek() {
      this.dayOfWeekError = !(this.project.scheduleStartDate.getDay() == this.startCycleDate.getDay());
   }

   private validateStartDateNotInPast() {
      this.cycleDateInPastError = !(this.project.scheduleStartDate < this.startCycleDate);
   }
}
