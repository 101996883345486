<div class="small-text cs-color-white contextualPanel-background-color">
   <h2 class="contextualPanel-header-color">{{selectedCallpoints.length}} Callpoints Selected</h2>
   
   <div class="btn-group" style="margin-top: 10px; margin-bottom: 10px; display: table;overflow:auto; width:100%;">
      <button type="button" class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <span style="float: left">Actions</span>
         <span class="cs-icon icon-arrow-down dropdown-button-icon"></span>
      </button>
      <ul class="dropdown-menu cs-dropdown-inverse" style="width:218px;">
         <li>
            <a (click)="editCallpoints()">
               <span style="margin-right:5px;" class="cs-icon icon-edit"></span> Edit Callpoints</a>
         </li>
         <li style="border-left: #fff76d solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDay  || fullyDeferredCallpoints}">
            <a (click)="lockCallpointVisitsToDay()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDay  || fullyDeferredCallpoints}">
               <span style="margin-right:5px;" class="cs-icon icon-locked-5"></span>
               Lock visits to day
            </a>
         </li>
         <li style="border-left: #e90000 solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDayTime  || fullyDeferredCallpoints}">
            <a (click)="lockCallpointVisitsToDayTime()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDayTime  || fullyDeferredCallpoints}">
               <span style="margin-right:5px;" class="cs-icon icon-safe"></span>
               Lock visits to day & time
            </a>
         </li>
         <li [ngClass]="{'disabled': areAllVisitsUnlocked  || fullyDeferredCallpoints}">
            <a (click)="unlockCallpointVisits()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsUnlocked  || fullyDeferredCallpoints}">
               <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
               Unlock visits
            </a>
         </li>

         <li [ngClass]="{'disabled': areAllCallpointsDisabled}">
            <a (click)="disableCallpoints()" style="padding-left:16px">
               <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
               Disable Callpoints
            </a>
         </li>
         <li [ngClass]="{'disabled': areAllCallpointsEnabled}">
            <a (click)="enableCallpoints()" style="padding-left:16px">
               <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
               Enable Callpoints
            </a>
         </li>
      </ul>
   </div>

   <!-- If selected callpoints are less than 11 then list out the callpoints -->
   <div *ngIf="selectedCallpoints.length < 11">
      <ul class="list-group contextualPanel-background-color">
         <li class="list-group-item" *ngFor="let selectedCallpoint of selectedCallpoints;">
            <div (click)="selectSingleCallpoint(selectedCallpoint)">
               {{ selectedCallpoint.reference }} - <strong>{{ selectedCallpoint.name }}</strong>
            </div>

            <!-- Anchor tag is used here to represent the click box area for the close icon, it can be sized
             to make it as large as needed. If we add the click event to the span tag then it will be too small.-->
            <a (click)="removeCallpointFromSelection(selectedCallpoint)">
               <span style="padding-left: 7px;padding-top: 6px;" class="fa fa-fw fa-close"></span>
            </a>
         </li>
      </ul>
   </div>
</div>

<!-- This is where callpoint settings dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showCallpointSettings" 
   [ndcDynamicComponent]="editCallpointSettingsDialog"
   [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
