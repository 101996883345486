import { ChangeDetectorRef, Component, Input, forwardRef, SimpleChanges, ViewChild, OnInit, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

declare var google;  // allows google api namespace to work

// Set up a new provider to tell angular that this component supports
// ControlValueAccessor methods and forms
const EDIT_CALLER_SETTINGS_VALUE_ACCESSOR = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => EditCallerSettingsComponent),
   multi: true
};

enum CallerSettingSection {
   WorkingHours,
   WorkingHoursFlexibility,
   LunchWindow,
   MaxCommuteTime,
   MaxVisitsPerDay,
   CommuteDuringWorkingHours,
   OutOfPhaseVisits,
   Overnights,
   Location
}

/**
 * Reusable component for Caller settings. Supports Form validation and ngModel synchronisation.
 * Supports overriding of caller settings and editing of multiple caller settings.
 *
 * Usage example:
 *     <edit-caller-settings name="editCallerSettings"
 *        [(ngModel)]="callerSettingsModel"
 *        [scrollHeight]="200"
 *        [displayHeading]="true"
 *        [showOverrideButtons]="true"
 *        [editingMultipleCallers]="true">
 *     </edit-caller-settings>
 *
 * Properties:
 * name - Unique name for this tag, this is required by Angular when used in conjunction with ngModel.
 * ngModel - The model object that represents the form properties for this component.
 * scrollHeight - This is the height for the scroll panel.
 * showOverrideButtons - values: true | false, defaults to false. Toggles the visibility of the override buttons.
 * editingMultipleCallers - values: true | false, defaults to false. Toggles the visibility of the checkboxes used for
 * editing multiple callers.
 */
@Component({
   selector: 'edit-caller-settings',
   templateUrl: 'edit-caller-settings.component.html',
   providers: [EDIT_CALLER_SETTINGS_VALUE_ACCESSOR]
})
export class EditCallerSettingsComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges, AfterViewInit {

   // This is used by the scroll pane to determine the scroll height.
   @Input() scrollHeight: number;

   // Determine whether to show the override buttons or not.
   @Input() showOverrideButtons: boolean = false;

   // Determine whether to show the multi edit checkboxes for each section.
   // Used when multiple callers settings need to be edited.
   @Input() editingMultipleCallers: boolean = false;

   // Determine whether to show the panel heading.
   @Input() displayHeading: boolean = true;

   // Number of weeks in this project.
   @Input() projectCycleLength: number;

   // Determine whether to show caller specific settings.
   @Input() displayCallerSpecificSettings: boolean = false;

   // Create a reference to the global enum inside this component so the HTML can have
   // access to it.
   public mySettingSection = CallerSettingSection;

   // Model class used by this component.
   public callerSettingsModel: CallerSettings | CallerSettingsViewModel;

   // Minimum and maximum bounds for the date range slider.
   public boundsMin: Date = new Date('2017-05-19T00:00:00');
   public boundsMax: Date = new Date('2017-05-20T00:00:00');

   @ViewChild('lunchDurationInput') lunchDurationInput;
   @ViewChild('maxOnewayCommuteTimeInput') maxOnewayCommuteTimeInput;
   @ViewChild('maxVisitsPerDayInput') maxVisitsPerDayInput;
   @ViewChild('outOfPhaseVisitDaysInput') outOfPhaseVisitDaysInput;
   @ViewChild('maxConsecutiveOvernightsInput') maxConsecutiveOvernightsInput;
   @ViewChild('minCommuteTimeBeforeOvernightInput') minCommuteTimeBeforeOvernightInput;
   @ViewChild('maxTravelTimeInEventOfOvernightInput') maxTravelTimeInEventOfOvernightInput;
   @ViewChild('callerSettingsForm') callerSettingsForm;
   @ViewChild('latitudeInput') latitudeInput;
   @ViewChild('longitudeInput') longitudeInput;

   // Flag to check whether there is no working days selected
   public isAnyWorkingDaySelected: boolean = true;
   public isWorkingHoursFlexibilityValid: boolean = true;
   public isLunchStartTimeValid: boolean = true;
   public isLunchEndTimeValid: boolean = true;
   public isLunchDurationValid: boolean = true;
   public isLunchDurationFormatValid: boolean = true;
   public isMaxOnewayCommuteTimeValid: boolean = true;
   public isMaxOnewayCommuteTimeFormatValid: boolean = true;
   public isMaxVisitsPerDayFormatValid: boolean = true;
   public isOutOfPhaseVisitDaysValid: boolean = true;
   public isOutOfPhaseVisitDaysFormatValid: boolean = true;
   public isMaxConsecutiveOvernightValid: boolean = true;
   public isMaxConsecutiveOvernightFormatValid: boolean = true;
   public isMinCommuteTimeBeforeOvernightFormatValid: boolean = true;
   public isMaxTravelTimeAfterOvernightValid: boolean = true;
   public isMaxTravelTimeAfterOvernightFormatValid: boolean = true;
   public isLatitudeFormatValid: boolean = true;
   public isLongitudeFormatValid: boolean = true;
   public lunchWindowDuration: number;
   public overnightsDisabled: boolean = true;
   public searchPlaceApplyButtonDisabled: boolean = true;

   public amberDays: boolean[] = [false, false, false, false, false, false, false];
   public daysChanged: boolean[] = [false, false, false, false, false, false, false];

   // Event raised when the state of the Form changes.
   private _formStateChanged = new Subject<boolean>();
   public formState$ = this._formStateChanged.asObservable();

   // Subscription to the default caller settings.
   private _settings_subscription: Subscription;

   // Project default values used when the user re-enables the overnights, we need these values from the project defaults
   private defaultMaxConsecutiveOvernights: number;
   private defaultOvernightsNoMaximum: boolean;

   private searchPlaceLat: string;
   private searchPlaceLng: string;

   constructor(private _cdr: ChangeDetectorRef,
      private _applicationStore: ApplicationStore) {
   }

   ngOnInit() {
      this.searchPlaceLat = '';
      this.searchPlaceLng = '';
      this.searchPlaceApplyButtonDisabled = true;
      this.subscribeToDefaultCallerSettings();
      this._applicationStore.userCallerSettingsStore.loadDefaultSettings();
   }

   ngOnDestroy() {
      if (this._settings_subscription) {
         this._settings_subscription.unsubscribe();
      }
   }

   ngAfterViewInit() {
      setTimeout(() => this.getPlaceAutocomplete());
   }

   public workingDayActiveChanged(index: number) {
      this.callerSettingsForm.form.markAsDirty();
      this.isAnyWorkingDaySelected = this.callerSettingsModel.workingDayActive.findIndex(boolValue => boolValue === true) > -1;
      this._formStateChanged.next(this.formValid);
      if (this.callerSettingsModel instanceof CallerSettingsViewModel) {
         this.callerSettingsModel.setWorkingDayActive(this.callerSettingsModel.workingDayActive);
         this.amberDays[index] = false;
         this.daysChanged[index] = true;
      }
   }

   // Helper method to check if the inputs represented by the section passed in can be displayed.
   // If the callerSettingsModel is of type CallerSettings or we are not in multi edit mode then always return true since the inputs always need to be
   // shown.
   // Else we only show the inputs if the multi edit checkbox is set by the user or the properties are the same
   // between all the callers.
   public canShowInputs(section: CallerSettingSection) {
      if (this.callerSettingsModel instanceof CallerSettings || !this.editingMultipleCallers) {
         // The settings are either being displayed in the project settings or a single
         // caller is selected. In both cases always display the input fields.
         return true;
      }
      else {
         // Multiple callers are being edited so display the input fields if the user has selected
         // the multi edit checkbox or the properties are the same between all callers.
         let result: boolean = false;
         switch (section) {
            case CallerSettingSection.WorkingHours: {
               result = this.callerSettingsModel.workingHoursMultiEdit || this.callerSettingsModel.workingHoursSameValuesSet;
               break;
            }
            case CallerSettingSection.WorkingHoursFlexibility: {
               result = this.callerSettingsModel.workingHoursFlexibilityMultiEdit || this.callerSettingsModel.workingHoursFlexibilitySameValuesSet;
               break;
            }
            case CallerSettingSection.LunchWindow: {
               result = this.callerSettingsModel.lunchWindowSwitchMultiEdit || this.callerSettingsModel.lunchWindowSwitchSameValuesSet;
               break;
            }
            case CallerSettingSection.MaxCommuteTime: {
               result = this.callerSettingsModel.maxCommuteTimeMultiEdit || this.callerSettingsModel.maxCommuteTimeSameValuesSet;
               break;
            }
            case CallerSettingSection.MaxVisitsPerDay: {
               result = this.callerSettingsModel.maxVisitsPerDayMultiEdit || this.callerSettingsModel.maxVisitsPerDaySameValuesSet;
               break;
            }
            case CallerSettingSection.CommuteDuringWorkingHours: {
               result = this.callerSettingsModel.commuteWorkHrsToFirstVisitMultiEdit || this.callerSettingsModel.commuteWorkHrsToFirstVisitSameValuesSet;
               break;
            }
            case CallerSettingSection.OutOfPhaseVisits: {
               result = this.callerSettingsModel.outOfPhaseVisitsMultiEdit || this.callerSettingsModel.outOfPhaseVisitsSameValuesSet;
               break;
            }
            case CallerSettingSection.Overnights: {
               result = this.callerSettingsModel.overnightsMultiEdit || this.callerSettingsModel.overnightsSameValuesSet;
               break;
            }
            case CallerSettingSection.Location: {
               result = this.callerSettingsModel.locationMultiEdit || this.callerSettingsModel.locationSameValuesSet;
               break;
            }
         }
         return result;
      }
   }

   // This property is bound to the out of Phase Visits edit box. This value is used for
   // callerSettingsModel.outOfPhaseVisitDays when the radio button in the 'N' position.
   private _outOfPhaseVisitsNDays: number;
   public get outOfPhaseVisitsNDays(): number {
      return this._outOfPhaseVisitsNDays;
   }
   public set outOfPhaseVisitsNDays(days: number) {
      this._outOfPhaseVisitsNDays = days;
      if (this._outOfPhaseVisitsRadioSetting == 'N') {
         this.callerSettingsModel.outOfPhaseVisitDays = days;
      }
   }

   // This property is bound to the out of phase visits radio button. The first two positions corresponse to the values
   // zero days and one day, in the third 'N' position the value entered in the edit box is used.
   private _outOfPhaseVisitsRadioSetting: string;
   public get outOfPhaseVisitsRadioSetting(): string {
      return this._outOfPhaseVisitsRadioSetting;
   }
   public set outOfPhaseVisitsRadioSetting(visits: string) {
      this._outOfPhaseVisitsRadioSetting = visits;
      if (visits == '0') {
         this.callerSettingsModel.outOfPhaseVisitDays = 0;
      }
      else if (visits == '1') {
         this.callerSettingsModel.outOfPhaseVisitDays = 1;

      }
      else if (visits == 'N') {
         this.callerSettingsModel.outOfPhaseVisitDays = this._outOfPhaseVisitsNDays;
      }
      else {
         // Error.
      }
      if (this.outOfPhaseVisitDaysInput.errors) {
         this.isOutOfPhaseVisitDaysFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   // Hack to get the floating lunch toggle switch working the right way round.
   // The toggle switch is true when its left and false when it switches right, but the labels
   // are opposite, Matt wants them that way round.
   private _floatingLunch: boolean;
   public get floatingLunch(): boolean {
      return !this.callerSettingsModel.floatingLunch;
   }
   public set floatingLunch(newValue: boolean) {
      this.callerSettingsModel.floatingLunch = !newValue;
      this._formStateChanged.next(this.formValid);
   }

   public onWorkingHoursFlexibilityInputValueChanged(event: any) {
      // No validation rules so far... This piece of code is needed
      // in order to activate de save button when the
      // working hours flexibility has changed.
      this.isWorkingHoursFlexibilityValid = true;
      this._formStateChanged.next(this.formValid);
   }

   public onCheckLunchPeriod(event: Date[]) {
      if (event && event.length === 2) {
         this.validateLunchStartAndEndTimes();
      }
   }

   public onLunchPeriodInputSwitchChanged(event: any) {
      if (event.checked) {
         this.isLunchStartTimeValid = true;
         this.isLunchEndTimeValid = true;
      }
      this.validateLunchStartAndEndTimes();
   }

   public onLunchDurationInputValueChanged(event: any) {
      if (!this.lunchDurationInput.errors) {
         this.validateLunchDuration();
         this.isLunchDurationFormatValid = true;
      }
      else {
         this.isLunchDurationFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onMaxOnewayCommuteTimeInputValueChanged(event: any) {
      if (!this.maxOnewayCommuteTimeInput.errors) {
         this.validateMaxOnewayCommuteTime();
         this.isMaxOnewayCommuteTimeFormatValid = true;
      }
      else {
         this.isMaxOnewayCommuteTimeFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onMaxVisitsPerDayInputValueChanged(event: any) {
      this.isMaxVisitsPerDayFormatValid = !this.maxVisitsPerDayInput.errors ? true : false;
      this._formStateChanged.next(this.formValid);
   }

   public onAnyCheckboxChanged(event: any) {
      this.callerSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);
   }

   public onAnyInputSwitchChanged(event: any) {
      this.callerSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);
   }

   // The datesClosed array is a flatted 2D array where all the weeks are next to each other
   // in a consecutive fashion. We need to determine if all the days are the same for each week or not
   // so that the logic can be used to drive the state of the contracted working days buttons
   // in the Caller settings. If all the Mondays are different, for example week 1 Monday is selected
   // but week 2 Monday is not selected then we need to show the Monday button in amber colour to tell
   // the user that there are different selections for Monday for each week. This Monday comparison
   // will need to be done between all the weeks in the project cycle.
   public onWorkingDayOverrideSwitchChanged(event: any) {
      this.callerSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);

      // Only do the check if a single caller is being edited.
      if (!this.editingMultipleCallers) {
         // The toggle button logic is inverted, when it is checked it returns false.
         if (!event.checked) {
            if (this.callerSettingsModel instanceof CallerSettingsViewModel) {
               this.amberDays = CallsmartUtils.getAmberStateForWorkingDays(this.callerSettingsModel.getCaller().datesClosed,
                  this.projectCycleLength);
            }
         }
         else {
            this.amberDays = [false, false, false, false, false, false, false];

            // Since the working days are being reverted back to project defaults, the parent component
            // needs to make sure the closed days are calculated correctly so setting all days to true here.
            this.daysChanged = [true, true, true, true, true, true, true];
         }
      }

   }

   public onOutOfPhaseInputValueChanged(event: any) {
      if (this.callerSettingsModel.outOfPhaseVisitDays && this.callerSettingsModel.outOfPhaseVisitDays <= 1) {
         this.isOutOfPhaseVisitDaysFormatValid = true;
         this.isOutOfPhaseVisitDaysValid = true;
      }
      else if (!this.outOfPhaseVisitDaysInput.errors) {
         this.validateOutOfPhase();
         this.isOutOfPhaseVisitDaysFormatValid = true;
      }
      else {
         this.isOutOfPhaseVisitDaysFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onMaxConsecutiveOvernightInputValueChanged(event: any) {
      if (!this.maxConsecutiveOvernightsInput.errors) {
         this.validateiMaxConsecutiveOvernights();
         this.isMaxConsecutiveOvernightFormatValid = true;
      }
      else {
         this.isMaxConsecutiveOvernightFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onMinCommuteTimeBeforeOvernightInputValueChanged(event: any) {
      this.isMinCommuteTimeBeforeOvernightFormatValid = !this.minCommuteTimeBeforeOvernightInput.errors ? true : false;
      this._formStateChanged.next(this.formValid);
   }

   public onMaxTravelTimeAfterOvernightInputValueChanged(event: any) {
      this.isMaxTravelTimeAfterOvernightFormatValid = !this.maxTravelTimeInEventOfOvernightInput.errors ? true : false;
      this._formStateChanged.next(this.formValid);
   }

   public onOvernightsChanged(event) {
      if (event.checked) {
         // Overnights are disabled so set maxConsecutiveOvernights to zero, this will disable overnights
         // in the back end.
         this.callerSettingsModel.maxConsecutiveOvernights = 0;
         this.callerSettingsModel.overnightsNoMaximum = false;
      }
      else {
         // Overnights are enabled so provide project default values.
         this.callerSettingsModel.maxConsecutiveOvernights = this.defaultMaxConsecutiveOvernights;
         this.callerSettingsModel.overnightsNoMaximum = this.defaultOvernightsNoMaximum;
      }
      this.callerSettingsModel.overnightsDisabled = event.checked;
      this._formStateChanged.next(this.formValid);
   }

   public onLatitudeInputValueChanged(event: any) {
      if (!this.latitudeInput.errors) {
         this.isLatitudeFormatValid = true;
      }
      else {
         this.isLatitudeFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onLongitudeInputValueChanged(event: any) {
      if (!this.longitudeInput.errors) {
         this.isLongitudeFormatValid = true;
      }
      else {
         this.isLongitudeFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public setLocationPropertyToEmpty(checked: boolean) {
      if (checked) {
         if (this.callerSettingsModel instanceof CallerSettingsViewModel && !this.callerSettingsModel.locationSameValuesSet) {
            this.callerSettingsModel.latitude = '';
            this.callerSettingsModel.longitude = '';
            this.isLongitudeFormatValid = false;
            this.isLatitudeFormatValid = false;
            this._formStateChanged.next(this.formValid);
         }
      }
   }

   public applyLocation() {
      if (this.callerSettingsModel instanceof CallerSettingsViewModel) {
         this.callerSettingsModel.latitude = this.searchPlaceLat;
         this.callerSettingsModel.longitude = this.searchPlaceLng;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onSearchPlaceInputValueChanged(event) {
      if(event.target.value.length === 0) {
         this.searchPlaceApplyButtonDisabled = true;
      }
   }

   private validateLunchStartAndEndTimes() {
      // Determine the callers working hours.
      let startEndTimes: Date[] = [];
      if (this.callerSettingsModel.sameWorkingHoursAllDays) {
         startEndTimes = this.callerSettingsModel.contractedWorkingHoursWeek;
      }
      else {
         let allTimes = [];
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursMonday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursTuesday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursWednesday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursThursday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursFriday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursSaturday);
         allTimes.push(this.callerSettingsModel.contractedWorkingHoursSunday);

         // Sort the array to determine which of the days has the shortest working time (end time - start time)
         allTimes.sort((a: Date[], b: Date[]) => {
            return ((a[1].getTime() - a[0].getTime()) - (b[1].getTime() - b[0].getTime()));
         });

         // After the sort the one on the top of the list is the one used for validation.
         startEndTimes = allTimes[0];
      }

      let contractedHoursStartTime = moment(startEndTimes[0].toISOString());
      let contractedHoursEndTime = moment(startEndTimes[1].toISOString());
      let lunchWindowStartTime = moment(this.callerSettingsModel.lunchPeriod[0].toISOString());
      let lunchWindowEndTime = moment(this.callerSettingsModel.lunchPeriod[1].toISOString());

      if (this.callerSettingsModel.floatingLunch) {
         // Checks the start date: Must be at least 1 minute greater than the Contracted hours start time,
         //                        and at least 3 minutes earlier than the Contracted hours end time
         this.isLunchStartTimeValid = lunchWindowStartTime.diff(contractedHoursStartTime, "minutes") >= 1 &&
            lunchWindowStartTime.diff(contractedHoursEndTime, "minutes") <= -3;
         // Checks the end date: Must be at least 1 minute later than From,
         //                      and 1 minute earlier than the Contracted hours end time
         this.isLunchEndTimeValid = lunchWindowEndTime.diff(lunchWindowStartTime, "minutes") >= 1 &&
            lunchWindowEndTime.diff(contractedHoursEndTime, "minutes") <= -1;
      }
      else {
         this.isLunchStartTimeValid = true;
         this.isLunchEndTimeValid = true;

      }
      // Checks the lunch window duration when the start/end lunch time changes
      this.lunchWindowDuration = lunchWindowEndTime.diff(lunchWindowStartTime, 'minutes');
      this.isLunchDurationValid = this.lunchWindowDuration >= this.callerSettingsModel.lunchDuration ? true : false;
      this._formStateChanged.next(this.formValid);
   }

   private validateLunchDuration() {
      // Gets start/end lunch start time
      let lunchWindowStartTime = moment(this.callerSettingsModel.lunchPeriod[0].toISOString());
      let lunchWindowEndTime = moment(this.callerSettingsModel.lunchPeriod[1].toISOString());
      // Gets the lunch period
      this.lunchWindowDuration = lunchWindowEndTime.diff(lunchWindowStartTime, 'minutes');
      // Checks whether the typed lunch duration is smaller than the lunch period
      this.isLunchDurationValid = this.callerSettingsModel.lunchDuration && (this.lunchWindowDuration >= this.callerSettingsModel.lunchDuration) ? true : false;
   }

   private validateMaxOnewayCommuteTime() {
      // Gets the contracted hours start time
      let contractedHoursStartTime: moment.Moment = moment(this.callerSettingsModel.contractedWorkingHoursWeek[0].toISOString());
      // Gets the start of the day
      let startOfDayTime: moment.Moment = contractedHoursStartTime.clone().startOf('day');
      // Gets the amount of minutes between the start of the day and the contracted hours start time
      let minutesBeforeStartTime: number = contractedHoursStartTime.diff(startOfDayTime, 'minutes');

      // Gets the contracted hours end time
      let contractedHoursEndTime: moment.Moment = moment(this.callerSettingsModel.contractedWorkingHoursWeek[1].toISOString());
      // Gets the end of the day
      let endOfDayTime: moment.Moment = contractedHoursEndTime.clone().startOf('day').add(1, 'days');
      // Gets the amount of minutes between the contracted hours end time and end of the day
      let minuteAfterEndTime: number = endOfDayTime.diff(contractedHoursEndTime, 'minutes');

      // Gets the smaller time window out of the contracted hours
      let smallerWindowOutOfWContractedHours = minutesBeforeStartTime < minuteAfterEndTime ? minutesBeforeStartTime : minuteAfterEndTime;
      // Checks wheter the typed Maximum one-way commute time is smaller than the smaller time window out of the contracted hours
      this.isMaxOnewayCommuteTimeValid = this.callerSettingsModel.maxOneWayCommuteTime && (smallerWindowOutOfWContractedHours >= this.callerSettingsModel.maxOneWayCommuteTime);
   }

   private validateOutOfPhase() {
      if (this.callerSettingsModel.outOfPhaseVisitDays <= 1) {
         return;
      }
      this.isOutOfPhaseVisitDaysValid = this.callerSettingsModel.outOfPhaseVisitDays <= (this.projectCycleLength * 7);
   }

   private validateiMaxConsecutiveOvernights() {
      this.isMaxConsecutiveOvernightValid = this.callerSettingsModel.maxConsecutiveOvernights <= (this.projectCycleLength * 7)
         && this.callerSettingsModel.maxConsecutiveOvernights >= 1;
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      if (this.callerSettingsModel) {
         return this.isAnyWorkingDaySelected && this.isLunchStartTimeValid && this.isLunchEndTimeValid &&
            this.isLunchDurationValid && this.isLunchDurationFormatValid && this.isWorkingHoursFlexibilityValid &&
            (this.callerSettingsModel.noMaximum || (this.isMaxOnewayCommuteTimeValid && this.isMaxOnewayCommuteTimeFormatValid)) &&
            (this.isMaxVisitsPerDayFormatValid || this.callerSettingsModel.visitsPerDayNoMaximum) &&
            ((this.callerSettingsModel.outOfPhaseVisitDays != null && this.callerSettingsModel.outOfPhaseVisitDays <= 1) || (this.isOutOfPhaseVisitDaysValid && this.isOutOfPhaseVisitDaysFormatValid)) &&
            (this.callerSettingsModel.overnightsNoMaximum || (this.isMaxConsecutiveOvernightValid && this.isMaxConsecutiveOvernightFormatValid)) &&
            this.isMinCommuteTimeBeforeOvernightFormatValid &&
            (this.callerSettingsModel.eventOvernightNoMaximum || this.isMaxTravelTimeAfterOvernightFormatValid);
      }
      return false;
   }

   private subscribeToDefaultCallerSettings() {
      this._settings_subscription =this._applicationStore.userCallerSettingsStore.defaultSettings$.subscribe(
         (callerSettings: CallerSettings) => {
            if (callerSettings !== null) {
               this.defaultMaxConsecutiveOvernights = callerSettings.maxConsecutiveOvernights;
               this.defaultOvernightsNoMaximum = callerSettings.overnightsNoMaximum;
            }
         }
      );
   }

   private getPlaceAutocomplete() {
      // Google Autocomplete feature initialisation.
      let searchElement = document.getElementById('searchPlace');
      if(!searchElement) {
         return;
      }
      let autocomplete = new google.maps.places.Autocomplete(searchElement,
         {
            // We are only interested in lat lng data from Autocomplete
            types: ['geocode']  // 'establishment' / 'address' / 'geocode'
         });

      // Use the callers lat/lngs to reverse lookup the address
      // to get the Country code. This is then used to bias the search results towards that country.
      if (this.callerSettingsModel instanceof CallerSettingsViewModel) {
         let latlng = {lat: parseFloat(this.callerSettingsModel.latitude), lng: parseFloat(this.callerSettingsModel.longitude)};
         // Google Geocoder is used to do reverse look up to get the address information from the lat lng.
         let geocoder = new google.maps.Geocoder;
         // Pass the lat lng to the geocoder.
         geocoder.geocode({'location': latlng}, function(results, status) {
            if (status === 'OK') {
               if (results[0]) {
                  let data = results[0];
                  let countryCode = '';

                  // Find and set the country from the returned address data.
                  data.address_components.forEach(address => {
                     if(address.types[0] == 'country') {
                        countryCode = address.short_name;
                     }
                  });

                  // Restrict the autocomplete to search for the selected country.
                  autocomplete.setComponentRestrictions({'country': countryCode});

               }
               else {
                   // console.log('No results found');
               }
            }
            else {
               // console.log('Geocoder failed due to: ' + status);
            }
         });
      }

      // Only get the lat lng info for the search term.
      autocomplete.setFields(['geometry']);

      // Bias the results towards current map's viewport.
      // autocomplete.setBounds(this._applicationStore.mapsStore.mapBounds);

      // Add event handler to set the lat lngs.
      autocomplete.addListener('place_changed', () => {
          let place = autocomplete.getPlace();
          this.searchPlaceLat = place.geometry.location.lat();
          this.searchPlaceLng = place.geometry.location.lng();
          this.searchPlaceApplyButtonDisabled = false;
          this._cdr.detectChanges();
      });
   }

   // Detect changes in this step and fire a change event. Typically this
   // fires when the form state changes (from valid to invalid for examaple).
   ngOnChanges(changes: SimpleChanges): void {
      this._formStateChanged.next(this.formValid);
   }


   // Callback method to sync the form values with this model.
   writeValue(data: any): void {
      if (!data) {
         return;
      }

      this.callerSettingsModel = data;

      if (this.callerSettingsModel.outOfPhaseVisitDays == 0) {
         this._outOfPhaseVisitsRadioSetting = '0';
         this._outOfPhaseVisitsNDays = 2;
      }
      else if (this.callerSettingsModel.outOfPhaseVisitDays == 1) {
         this._outOfPhaseVisitsRadioSetting = '1';
         this._outOfPhaseVisitsNDays = 2;
      }
      else {
         this._outOfPhaseVisitsRadioSetting = 'N';
         this._outOfPhaseVisitsNDays = this.callerSettingsModel.outOfPhaseVisitDays;
      }

      this.overnightsDisabled = this.callerSettingsModel.overnightsDisabled;

      // Only do the check if a single caller is being edited.
      if (!this.editingMultipleCallers) {
         if (this.callerSettingsModel instanceof CallerSettingsViewModel && this.callerSettingsModel.getCallerSettings() !== null &&
            this.callerSettingsModel.getCallerSettings().workingDayActive !== undefined) {
            this.amberDays = CallsmartUtils.getAmberStateForWorkingDays(this.callerSettingsModel.getCaller().datesClosed,
               this.projectCycleLength);
         }
      }

      this._cdr.detectChanges();
   }

   // Registers a callback method to invoke when a change event happens.
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   // Registers a callback method to invoke when a touch event happens.
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   // Handler methods for ControlValueAccessor
   private onTouched = () => { };
   private onChange: (value: any[]) => {};
}
