import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
export class Maintenance {

    @JsonProperty('webServiceInMaintenance')
    public WebServiceInMaintenance: boolean = false;

    @JsonProperty('databaseInMaintenance')
    public DatabaseInMaintenance: boolean = false;
}