// this is a model opject to send caller and callpoint export data to the service
export class ExportParameters {

   constructor(selectedColumns:any, selectedItemsIds:number[]) {
      this.selectedColumns = selectedColumns;
      this.selectedItemsIds = selectedItemsIds;
   }

   public selectedColumns:any;
   public selectedItemsIds:number[];
}
