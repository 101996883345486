import { Component, OnInit, ViewChildren, QueryList, ContentChildren, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';
import { NewProjectCallersComponent } from 'app/new-project-workspace/new-project-callers/new-project-callers.component';
import { NewProjectCallpointsComponent } from 'app/new-project-workspace/new-project-callpoints/new-project-callpoints.component';
import { NewProjectEventsComponent } from 'app/new-project-workspace/new-project-events/new-project-events.component';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { NewProjectSettingsComponent } from 'app/new-project-workspace/new-project-settings/new-project-settings.component';
import { ProjectStatus } from 'app/models/projectStatus';
import { Alert } from 'app/models/alert';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ErrorStore } from 'app/stores/error-store';

@Component({
   selector: 'callsmart-new-project-workspace',
   templateUrl: './new-project-workspace.component.html'
})
export class NewProjectWorkspaceComponent implements OnInit, OnDestroy {

   private _project_created_subscription: Subscription;
   private _project_status_subscription: Subscription;
   private _errorDialog_subscription: Subscription;
   private _project: Project;
   private _isFinished:boolean = false;

   public activeIndex: number = 0;
   public stepLabel: string;
   public finishButtonLabel: string = 'Create Project';
   public projectWizardModel: NewProjectWizard;

   @ViewChild('newProjectSettings') settingsStep: NewProjectSettingsComponent;
   @ViewChild('newProjectCallers') uploadCallerStep: NewProjectCallersComponent;
   @ViewChild('newProjectCallpoints') uploadCallpointsStep: NewProjectCallpointsComponent;
   @ViewChild('newProjectEvents') eventsStep: NewProjectEventsComponent;

   constructor(private _applicationStore: ApplicationStore, 
               private router: Router,
               private _errorStore: ErrorStore) {
      this.projectWizardModel = new NewProjectWizard();
      this.projectWizardModel.hasStartedCallersUploadedToServer = false;
      this.projectWizardModel.hasCompletedCallersUploadedToServer = false;
      this.projectWizardModel.hasStartedCallpointsUploadedToServer = false;
      this.projectWizardModel.hasCompletedCallpointsUploadedToServer = false;
      this.projectWizardModel.callerUploadedToServerError = false;
      this.projectWizardModel.callpointsUploadedToServerError = false;
      this.projectWizardModel.fileMode='createnew';
      this.projectWizardModel.numberOfCallers = 0;
      this.projectWizardModel.numberOfCallpoints = 0;
      this.projectWizardModel.callersFileName = '';
      this.projectWizardModel.callpointsFileName = '';
      this.projectWizardModel.folder = this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules";
   }

   ngOnInit() {
      // Close the existing project when the user starts the new project wizard. If the user cancels
      // the wizard then they will be taken back to the open project page.
      this._applicationStore.projectsStore.setSelectedProject(null, this._applicationStore.authenticationStore.loggedInUser.userId);
      this._applicationStore.projectsStore.createEmptyProject(
         this._applicationStore.authenticationStore.loggedInUser.userId,
         this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules");
      this.subscribeToProjectCreated();
      this.subscribeToProjectStatus();
      this.subscribeToErrorDialogShown();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }

      if(this._project_status_subscription){
         this._project_status_subscription.unsubscribe();
      }

      if (this._errorDialog_subscription) {
         this._errorDialog_subscription.unsubscribe();
      }
   }

   onChange(obj: any) {
      this.ProjectSettingsStepNavigation(obj);
      this.ProjectCallersStepNavigation(obj);
      this.ProjectCallpointsStepNavigation(obj);
      this.ProjectEventsStepNavigation(obj);
   }

   onFinish() {
      this._isFinished = true;
      this._applicationStore.projectsStore.getProjectStatusById(this.projectWizardModel.projectId);
   }

   onCancel() {
      // delete the project if created and any of its sub components
      if (this.projectWizardModel.projectId > 0) {
         this._applicationStore.projectsStore.deleteProject(this.projectWizardModel.projectId);
         // TODO delete any project calendars
      }

      this.router.navigate(['open-project']);
   }

   // When the user edits the project settings, update the properties on the main page.
   public onProjectSettingsUpdated(settings: ProjectSettings) {
      // Needed to update these values on the summary page.
      this.projectWizardModel.fullOptimiserIteration = settings.fullOptimiserIteration;
      this.projectWizardModel.quickOptimiserIteration = settings.quickOptimiserIteration;
   }

   // When the user edits the caller settings, we need to update the max visits per day
   // on the summary page.
   public onCallerSettingsUpdated(settings: CallerSettings) {
      this.projectWizardModel.maxVisitsPerDay = settings.maxVisitsPerDay;
      this.projectWizardModel.visitsPerDayNoMaximum = settings.visitsPerDayNoMaximum;
      this.projectWizardModel.activeWorkingDays = settings.workingDayActive;

      let formattedClosedDates: string[] = [];
      for (let index = 0; index < this.projectWizardModel.numberOfWeeks; index++) {
         let week = settings.workingDayActive.map(x => {
            return x ? 'o' : 'x';
         });
         formattedClosedDates = formattedClosedDates.concat(week);
      }

      this.projectWizardModel.datesClosed = formattedClosedDates;
      
      if (settings.sameWorkingHoursAllDays) {
         this.projectWizardModel.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(true, settings);
         this.projectWizardModel.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(false, settings);
      }
      else {
         // Deal with multiple hours per day. This is a bit of a cheat, take hours for each day and create a pipe 
         // delimited string that can be passed to the calendar component, the calendar component will then unpack this.
         let startTime = '';
         startTime += CallsmartUtils.getWorkingTime(true, settings.contractedWorkingHoursMonday) + '|';
         startTime += CallsmartUtils.getWorkingTime(true, settings.contractedWorkingHoursTuesday) + '|';
         startTime += CallsmartUtils.getWorkingTime(true, settings.contractedWorkingHoursWednesday) + '|';
         startTime += CallsmartUtils.getWorkingTime(true, settings.contractedWorkingHoursThursday) + '|';
         startTime += CallsmartUtils.getWorkingTime(true, settings.contractedWorkingHoursFriday);
         this.projectWizardModel.startWorkingTime = startTime;

         let endTime = '';
         endTime += CallsmartUtils.getWorkingTime(false, settings.contractedWorkingHoursMonday) + '|';
         endTime += CallsmartUtils.getWorkingTime(false, settings.contractedWorkingHoursTuesday) + '|';
         endTime += CallsmartUtils.getWorkingTime(false, settings.contractedWorkingHoursWednesday) + '|';
         endTime += CallsmartUtils.getWorkingTime(false, settings.contractedWorkingHoursThursday) + '|';
         endTime += CallsmartUtils.getWorkingTime(false, settings.contractedWorkingHoursFriday);
         this.projectWizardModel.endWorkingTime = endTime;
         
      }
      
   }

   gotoSettings() {
      this.activeIndex = 0;
   }

   gotoCallers() {
      this.activeIndex = 1;
   }

   gotoCallpoints() {
      this.activeIndex = 2;
   }

   gotoEvents() {
      this.activeIndex = 3;
      this.eventsStep.redrawCalendar(); // Sets the scroll bar where it has to be.
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               project.temporary = true;
               this._project = project;
               this.projectWizardModel.projectId = project.projectId;
               this.projectWizardModel.numberOfWeeks = project.projectSettings.callCycleLength;
               this.projectWizardModel.quickOptimiserIteration = project.projectSettings.quickOptimiserIteration;
               this.projectWizardModel.fullOptimiserIteration = project.projectSettings.fullOptimiserIteration;
               this.projectWizardModel.maxVisitsPerDay = project.callerSettings.maxVisitsPerDay;
               this.projectWizardModel.visitsPerDayNoMaximum = project.callerSettings.visitsPerDayNoMaximum;
            }
         }
      );
   }

   private subscribeToProjectStatus(): void {
      this._project_status_subscription = this._applicationStore.projectsStore.projectStatus$
         .subscribe((status: ProjectStatus) => {
            if (status.projectInUse) {
               this._applicationStore.alertStore.sendAlert(new Alert('Warning', 'Project cannot be opened. In use by user ' + status.projectInUseByUser));
               this._isFinished =false;
            } else {
               if(status.exceedsConcurrentTravelModelAccess){
                  this._applicationStore.alertStore.sendAlert(new Alert('Warning', 'Number of concurrent users exceeds your company licence for project travel model.\nIn use by user(s) ' + status.travelModelUsedBy + + '.'));
                  this._isFinished =false;
               }else{
                  if (status.callerCount != -1) {
                     if (this._isFinished ) {
                        this._isFinished = false;
                        this._applicationStore.projectsStore.initialiseProject(this.projectWizardModel.projectId,
                           this.uploadCallerStep.rowsImported, this._applicationStore.authenticationStore.loggedInUser.userId);
                        this.router.navigate(['project-status']);
                     }
                  }
               }
            }
         });
   }

   private subscribeToErrorDialogShown(): void {
      this._errorDialog_subscription = this._errorStore.errorDialogShown.subscribe(
         (errorSource: string) => {
            if(errorSource)
            {
               if(errorSource == this.uploadCallerStep.errorSource) {
                  this.gotoCallers();
               }
               else if(errorSource == this.uploadCallpointsStep.errorSource) {
                  this.gotoCallpoints();
               }
            }
         }
      );
   }

   // this method provides aditional methods that must take place when the user clicks next on the callers step
   // in this case we need to trigger the file upload
   private ProjectCallersStepNavigation(obj: any) {
      if (obj.previouseIndex === 1 && obj.activeIndex > 1) {
         this.uploadCallerStep.uploadFileToServer();
      }
   }

   // this method provides aditional methods that must take place when the user clicks next on the callpoints step
   // in this case we need to trigger the file upload
   private ProjectCallpointsStepNavigation(obj: any) {
      if (obj.previouseIndex === 2 && obj.activeIndex > 2) {
         this.uploadCallpointsStep.uploadFileToServer();
      }
   }

   // this method provides aditional methods that must take place when the user clicks next on the events step.
   // In this case we need to trigger the calendar upload
   private ProjectEventsStepNavigation(obj: any) {

      if (obj.previouseIndex === 3) {
         // Calendar must be loaded when the event step is visible,
         // otherwise the 'firstDay' property would be initialised
         // when the wizard is loaded for the first time. This value cannot be changed afterwards.
         // That would mean that the 'firstDay' property couldn't be modified dinamically
         // since at that point the start cycle date is unknown.
         // (This is a known bug from the JQuery FullCalendar)
         this.projectWizardModel.isCalendarVisible = false;
         if (obj.activeIndex > 3) {
            this._applicationStore.projectsStore.updateCreateNewProjectEventsSettings(this.projectWizardModel.projectId,
               this.projectWizardModel.projectEvents);
         }
      }
      // Either 'previous' button has been pressed from Step 4 or breadcrumb path events step button has been pressed
      if (obj.activeIndex === 3) {
         this.projectWizardModel.isCalendarVisible = true;
         this.eventsStep.refreshCallers();
         this.eventsStep.redrawCalendar();
      }

      this.projectWizardModel.travelModel = this._project.projectSettings.travelModel.travelModelName;
   }

   // this method provides aditional methods that must take place when the user clicks next on the settings step
   // in this case we need to create or update the project information
   private ProjectSettingsStepNavigation(obj: any) {

      // the user has naviaged from the settings page create or update the project details
      if (obj.previouseIndex == 0) {

         // update the project details
         if (this.projectWizardModel.projectName &&
            this.projectWizardModel.numberOfWeeks &&
            this.projectWizardModel.startCycleDate) {

            this._project.modifiedDate = new Date();
            this._project.folder = this.projectWizardModel.folder;
            this._project.name = this.projectWizardModel.projectName;
            this._project.userId = this._applicationStore.authenticationStore.loggedInUser.userId;
            this._project.scheduleStartDate = this.projectWizardModel.startCycleDate;
            this._project.calendarId = 0;
            this._project.projectSettings.callCycleLength = this.projectWizardModel.numberOfWeeks;

            this._applicationStore.projectsStore.updateProject(this._project, false);
         }
      }
   }
}
