import { JsonObject, JsonProperty } from 'json2typescript';

import { NullConverter } from 'app/shared/json-converters/null-converter';
import { TravelModel } from 'app/models/travelModel';

/**
 * Project settings model class.
 */
@JsonObject
export class ProjectSettings {

   @JsonProperty('projectSettingsId', NullConverter, true)
   public projectSettingsId: number = undefined;

   @JsonProperty('callCycleLength', NullConverter, true)
   public callCycleLength: number = undefined;

   @JsonProperty('quickOptimiserIteration', NullConverter, true)
   public quickOptimiserIteration: number =undefined;

   @JsonProperty('fullOptimiserIteration', NullConverter, true)
   public fullOptimiserIteration: number = undefined;

   @JsonProperty('distanceUnitMiles', NullConverter, true)
   public distanceUnitMiles: boolean = undefined;

   @JsonProperty('currency', NullConverter, true)
   public currency: string = undefined;

   @JsonProperty('drivingCostPerUnit', NullConverter, true)
   public drivingCostPerUnit: number = undefined;

   @JsonProperty('averageUnitPerGallon', NullConverter, true)
   public averageUnitPerGallon: number = undefined;

   @JsonProperty('averageVehicleEmission', NullConverter, true)
   public averageVehicleEmission: number = undefined;

   @JsonProperty('underUtilisation', NullConverter, true)
   public underUtilisation: number = undefined;

   @JsonProperty('overUtilisation', NullConverter, true)
   public overUtilisation: number = undefined;

   public utilisationDefinition: number[] = undefined;

   @JsonProperty('travelModel', TravelModel, true)
   public travelModel: TravelModel = undefined;

   @JsonProperty('travelModelId', NullConverter, true)
   public travelModelId: number = undefined;

   @JsonProperty('diaryViewTime', NullConverter, true)
   public diaryViewTime: number = undefined;

   @JsonProperty('minTotalDrivingSelected', NullConverter, true)
   public minTotalDrivingSelected: boolean = undefined;

   @JsonProperty('minDrivingBetweenVisitsSelected', NullConverter, true)
   public minDrivingBetweenVisitsSelected: boolean = undefined;

   @JsonProperty('optimiserDrivingConfigCustomSelected', NullConverter, true)
   public optimiserDrivingConfigCustomSelected: boolean = undefined;

   @JsonProperty('idowhShift1', NullConverter, true)
   public idowhShift1: number = undefined;

   @JsonProperty('idowhShift2', NullConverter, true)
   public idowhShift2: number = undefined;

   @JsonProperty('idowhCommuteAM1', NullConverter, true)
   public idowhCommuteAM1: number = undefined;

   @JsonProperty('idowhCommuteAM2', NullConverter, true)
   public idowhCommuteAM2: number = undefined;

   @JsonProperty('idowhCommutePM1', NullConverter, true)
   public idowhCommutePM1: number = undefined;

   @JsonProperty('idowhCommutePM2', NullConverter, true)
   public idowhCommutePM2: number = undefined;

   @JsonProperty('iterationWeightWH', NullConverter, true)
   public iterationWeightWH: number = undefined;

   @JsonProperty('iterationWeightAM', NullConverter, true)
   public iterationWeightAM: number = undefined;

   @JsonProperty('iterationWeightPM', NullConverter, true)
   public iterationWeightPM: number = undefined;

}
