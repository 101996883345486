import { JsonObject, JsonProperty } from 'json2typescript';
import { NullConverter } from 'app/shared/json-converters/null-converter';



/**
 * Model class representing a the information neededto copy or roll a project
 */
@JsonObject
export class CopyRollProject {

   @JsonProperty('orginalProjectId')
   public orginalProjectId: number = undefined;

   @JsonProperty('newName')
   public newName: string = undefined;

   @JsonProperty('userId')
   public userId: number = undefined;

   @JsonProperty('RollForwardDateTime', NullConverter, true)
   public rollForwardDateTime: Date = undefined;
}
