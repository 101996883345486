import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Project } from 'app/models/project';

/*
   Delete project dialog is a model window that displays the project the user has selected and confirms they want to delete the project.
*/

@Component({
   selector: 'callsmart-delete-project-dialog',
   templateUrl: './delete-project-dialog.component.html'
})
export class DeleteProjectDialogComponent implements OnInit {

   projects: Project[] = [];

   // Project model used by this dialog.
   @Input() project: Project;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() delete = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngOnInit() {
      this.projects.push(this.project);
   }

   public onDelete() {
      // do the delete
      // check if the current open project is the one being deleted if it is close it before deleting
      if (this._applicationStore.projectsStore.selectedProject) {
         if (this._applicationStore.projectsStore.selectedProject.projectId == this.project.projectId) {
            this._applicationStore.projectsStore.setSelectedProject(null, this._applicationStore.authenticationStore.loggedInUser.userId);
         }
      }
      this._applicationStore.projectsStore.deleteProject(this.project.projectId);
      this.display = false;
      this.delete.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}
