<p-dialog header="" [(visible)]="display" modal="true" width="550" positionTop="200" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">

      <div style="margin-left: 15px;  margin-top: 0px;">
      <h3 style="margin-top: 0px;">Copying {{project?.name}} </h3>
      </div>

      <div class="panel-body">
         <h6>Name</h6>
         <div class="form-group ">
            <div class="col-sm-12">
               <input type="name" id="name" #name=ngModel name="name" required [(ngModel)]="copyProjectName" placeholder="Name of project copy"
                  class="form-control cs-form-control" focusDirective cssSelector="#name">
            </div>
         </div>
      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!form.valid" (click)="onCopy(form)">Create</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
