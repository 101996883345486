import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';
import { NewProjectCallersComponent } from 'app/new-project-workspace/new-project-callers/new-project-callers.component';
import { NewProjectCallpointsComponent } from 'app/new-project-workspace/new-project-callpoints/new-project-callpoints.component';
import { ImportOptions } from 'app/models/import-options';
import { ErrorStore } from 'app/stores/error-store';

/**
 * Workspace for the import data wizard.
 */
@Component({
   selector: 'import-data-workspace',
   templateUrl: 'import-data-workspace.component.html'
})
export class ImportDataWorkspaceComponent {
   private _project_created_subscription: Subscription;
   private _navigate_to_dashboard_subscription: Subscription;
   private _errorDialog_subscription: Subscription;
   private _project: Project;

   public activeIndex: number = 0;
   public stepLabel: string;
   public finishButtonLabel: string = 'Import data';
   public projectWizardModel: NewProjectWizard;
   public importOptions: ImportOptions;

   @ViewChild('importDataCallers') uploadCallerStep: NewProjectCallersComponent;
   @ViewChild('importDataCallpoints') uploadCallpointsStep: NewProjectCallpointsComponent;

   constructor(private _applicationStore: ApplicationStore, 
               private router: Router,
               private _errorStore: ErrorStore) {
      this.projectWizardModel = new NewProjectWizard();
      this.projectWizardModel.hasStartedCallersUploadedToServer = false;
      this.projectWizardModel.hasCompletedCallersUploadedToServer = false;
      this.projectWizardModel.hasStartedCallpointsUploadedToServer = false;
      this.projectWizardModel.hasCompletedCallpointsUploadedToServer = false;
      this.projectWizardModel.callerUploadedToServerError = false;
      this.projectWizardModel.callpointsUploadedToServerError = false;
      this.projectWizardModel.fileMode="updatemerge";
      this.projectWizardModel.numberOfCallers = 0;
      this.projectWizardModel.numberOfCallpoints = 0;
      this.projectWizardModel.callersFileName = '';
      this.projectWizardModel.callpointsFileName = '';
      this.projectWizardModel.folder = this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules";
   }

   ngOnInit() {
      this.subscribeToProjectCreated();
      this._applicationStore.projectsStore.createTemporaryProject(
         this._applicationStore.authenticationStore.loggedInUser.userId,
         this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules");
      this.importOptions = this._applicationStore.importDataStore.importOptions;
      this.subscribeToNavigateToDashboard();
      this.subscribeToErrorDialogShown();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }

      if (this._navigate_to_dashboard_subscription) {
         this._navigate_to_dashboard_subscription.unsubscribe();
      }

      if (this._errorDialog_subscription) {
         this._errorDialog_subscription.unsubscribe();
      }
      
   }

   onChange(obj: any) {
      if (this.importOptions.importCallers) {
         this.ProjectCallersStepNavigation(obj);
      }

      if (this.importOptions.importCallpoints) {
         this.ProjectCallpointsStepNavigation(obj);
      }
   }

   onFinish() {
      this.importOptions.originalProjectId = this._applicationStore.projectsStore.selectedProject.projectId;
      
      this._applicationStore.projectsStore.mergeImportedData(
         this.projectWizardModel.projectId,
         this.importOptions,
         this._applicationStore.projectsStore.selectedProject.name,
         this._applicationStore.authenticationStore.loggedInUser.userId);
   }

   onCancel() {
      // delete the project if created and any of its sub components
      if (this.projectWizardModel.projectId > 0) {
         this._applicationStore.projectsStore.deleteProject(this.projectWizardModel.projectId);
         // TODO delete any project calendars
      }

      this.router.navigate(['open-project']);
   }

   gotoCallers() {
      this.activeIndex = 0;
   }

   gotoCallpoints() {
      this.activeIndex = this.importOptions.importCallers ? 1 : 0;
   }

   // Event handler for the file upload component, at this point the file has been uploaded and can be processed,
   // work out how many records have been added, removed and/or updated. The import data summary component will
   // listen for these counts to display on the page.
   public onCallerUploadComplete() {
      this._applicationStore.callersStore.getCallerMergeCounts(this._applicationStore.projectsStore.selectedProject.projectId,
         this.projectWizardModel.projectId);
   }

   // Event handler for the file upload component, at this point the file has been uploaded and can be processed,
   // work out how many records have been added, removed and/or updated. The import data summary component will
   // listen for these counts to display on the page.
   public onCallpointUploadComplete() {
      this._applicationStore.callpointsStore.getCallpointMergeCounts(this._applicationStore.projectsStore.selectedProject.projectId,
         this.projectWizardModel.projectId);
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this._project = project;
               this.projectWizardModel.projectId = project.projectId;
               this.projectWizardModel.numberOfWeeks = project.projectSettings.callCycleLength;
               this.projectWizardModel.quickOptimiserIteration = project.projectSettings.quickOptimiserIteration;
               this.projectWizardModel.fullOptimiserIteration = project.projectSettings.fullOptimiserIteration;
               this.projectWizardModel.maxVisitsPerDay = project.callerSettings.maxVisitsPerDay;
               this.projectWizardModel.visitsPerDayNoMaximum = project.callerSettings.visitsPerDayNoMaximum;
            }
         }
      );
   }

   private subscribeToErrorDialogShown(): void {
      this._errorDialog_subscription = this._errorStore.errorDialogShown.subscribe(
         (errorSource: string) => {
            if(errorSource)
            {
               if(this.uploadCallerStep && errorSource == this.uploadCallerStep.errorSource) {
                  this.gotoCallers();
               }
               else if(this.uploadCallpointsStep && errorSource == this.uploadCallpointsStep.errorSource) {
                  this.gotoCallpoints();
               }
            }
         }
      );
   }

   // this method provides aditional methods that must take place when the user clicks next on the callers step
   // in this case we need to trigger the file upload
   private ProjectCallersStepNavigation(obj: any) {
      if (obj.previouseIndex < obj.activeIndex) {
         this.uploadCallerStep.uploadFileToServer();
      }
   }

   // this method provides aditional methods that must take place when the user clicks next on the callpoints step
   // in this case we need to trigger the file upload
   private ProjectCallpointsStepNavigation(obj: any) {
      if (obj.previouseIndex < obj.activeIndex) {
         this.uploadCallpointsStep.uploadFileToServer();
      }
   }

   private subscribeToNavigateToDashboard() {
      this._navigate_to_dashboard_subscription = this._applicationStore.projectsStore.navigateToDashboard.subscribe(
         () => this.router.navigate(['/dashboard'])
      );
   }

}
