<div>
   <form novalidate autocomplete="off">
      <callsmart-wizard wizardTitle="Create new project" [(activeIndex)]="activeIndex" (change)="onChange($event)" (finish)="onFinish()"
         (cancel)="onCancel()" [finishButtonLabel]="finishButtonLabel" #newProjectWizard>
         <callsmart-step label="Settings" [formValid]="newProjectSettings?.formValid">         
            <div class="cs-step-panel" style="width:80%; padding-right: 10px; float: left;">
               <callsmart-new-project-settings #newProjectSettings [componentHeight]="newProjectWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"></callsmart-new-project-settings>
            </div>
            <div class="cs-step-panel " style="width:20%; padding-right: 6px;">
               <div class="top-content-border bottom-content-border" style="padding-top: 5px;">
                  <callsmart-new-project-settings-setting
                     [componentHeight]="newProjectWizard?.workspaceHeight"
                     (projectSettingsUpdated)="onProjectSettingsUpdated($event)">
                  </callsmart-new-project-settings-setting>
               </div>
            </div>
         </callsmart-step>

         <callsmart-step label="Callers" [formValid]="newProjectCallers?.formValid">
            <div class="cs-step-panel" style="width:80%; padding-right: 10px; float: left;">
               <callsmart-new-project-callers #newProjectCallers [componentHeight]="newProjectWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"></callsmart-new-project-callers>
            </div>
            <div class="cs-step-panel " style="width:20%; padding-right: 6px;">
               <div class="top-content-border bottom-content-border" style="padding-top: 5px;">
                  <callsmart-new-project-caller-settings
                     [componentHeight]="newProjectWizard?.workspaceHeight"
                     (callerSettingsUpdated)="onCallerSettingsUpdated($event)">
                  </callsmart-new-project-caller-settings>
               </div>
            </div>
         </callsmart-step>

         <callsmart-step label="Callpoints" [formValid]="newProjectCallpoints?.formValid">         
            <div class="cs-step-panel" style="width:80%; padding-right: 10px; float: left;">
               <callsmart-new-project-callpoints #newProjectCallpoints [componentHeight]="newProjectWizard?.workspaceHeight" 
               [projectWizardModel]="projectWizardModel"></callsmart-new-project-callpoints>
            </div>
            <div class="cs-step-panel " style="width:20%; padding-right: 6px;">
               <div class="top-content-border bottom-content-border" style="padding-top: 5px;">
                  <callsmart-new-project-callpoint-settings
                     [componentHeight]="newProjectWizard?.workspaceHeight">
                  </callsmart-new-project-callpoint-settings>
               </div>
            </div>
         </callsmart-step>

         <callsmart-step label="Events" [formValid]="true">
            <div class="cs-step-panel" style="padding-right: 6px;">
               <callsmart-new-project-events #newProjectEvents [componentHeight]="newProjectWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel">                  
               </callsmart-new-project-events>
            </div>

         </callsmart-step>

         <callsmart-step label="Summary" [formValid]="newProjectSummary?.formValid">
            <div class="cs-step-panel" style="width: 100%; padding-right: 6px;">
               <callsmart-new-project-summary #newProjectSummary [componentHeight]="newProjectWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"
                  (settings)="gotoSettings()" (callers)="gotoCallers()" (callPoints)="gotoCallpoints()" (events)="gotoEvents()"></callsmart-new-project-summary>
            </div>

         </callsmart-step>
      </callsmart-wizard>
   </form>
</div>
