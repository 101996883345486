<p-dialog header="" [(visible)]="display" modal="true" width="550" positionTop="50" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">

      <div style="margin-left: 15px;  margin-top: 0px;">
         <h3 style="margin-top: 0px;">Roll Forward - {{project?.name}} </h3>
      </div>

      <div class="panel-body">
         <h6>Name</h6>
         <div class="form-group">
            <div class="col-sm-12">
               <input type="name" id="name" #name=ngModel name="name" required [(ngModel)]="copyProjectName" placeholder="Name of project copy"
                  class="form-control cs-form-control" focusDirective cssSelector="#name">
            </div>
         </div>
      </div>
      <div class="panel-body">
         <h6>Call cycle length</h6>
         <label  class="col-sm-1 control-label" style="padding-left: 0px"> Number of weeks: {{project.projectSettings.callCycleLength}}</label>
      </div>
      <div class="panel-body">
         <h6>Call cycle start date</h6>
         <div class="form-group">
            <div class="col-sm-1" style="margin-top:10px"style="margin-top:10px">
               <input required [(ngModel)]="cycleStartDateStr" pattern="^(?:(?:(?:0[1-9]|1\d|2[0-8])/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31/(?:0[13578]|1[02]))/[1-9]\d{3}|29/02/(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$"
                  class="form-control cs-form-control" placeholder="dd/mm/yyyy" size="1" (blur)="onCallCycleStartDateLostFocus($event)"
                  name="callCycleStartDateInput" #callCycleStartDateInput="ngModel" style="width: 110px">
               <span *ngIf="callCycleStartDateInput.touched && callCycleStartDateInput.errors">
                  <label *ngIf="callCycleStartDateInput.errors.required">Please insert cycle start date</label>
                  <label *ngIf="callCycleStartDateInput.errors.pattern">Cycle start date (dd/mm/yyyy)</label>
               </span>
               <label *ngIf="dayOfWeekError">New cycle must start on the same day of the week as the current project.</label>
               <label *ngIf="cycleDateInPastError">New cycle must start after current project cycle date.</label>

            </div>
            <div class="col-sm-4" style="margin-top:10px">
               <label for="callCycleStartDateInput" class="control-label">
                  <b>To:</b> {{cycleEndDateStr}}</label>
            </div>
         </div>
         <div class="form-group">
            <div class="col-sm-8" style="margin-top:10px">
               <p-calendar dateFormat="yy-mm-dd" [inline]="true" [(ngModel)]="startCycleDate" name="cycleCalendar" selectionMode="single"
                  selectOtherMonths="true" [style]="{'border':'none'}" (onSelect)="onDateSelected(startCycleDate)" [locale]="en">

                  <ng-template pTemplate="date" let-date>
                     <span [ngStyle]="{backgroundColor: isCalendarDateInRange(date.day, date.month, date.year) ? '#34bf84' : 'inherit', color:isCalendarDateInRange(date.day, date.month, date.year) ? '#222222' : '#d1d1d1', border: isCalendarDateInRange(date.day, date.month, date.year) ? 'inherit' : '1px solid #d1d1d1'}">{{date.day}}</span>
                  </ng-template>


               </p-calendar>
            </div>
         </div>

      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!form.valid || dayOfWeekError || cycleDateInPastError" (click)="onRollForward(form)">Create</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
