import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';

@Component({
   selector: 'callsmart-new-project-summary',
   templateUrl: './new-project-summary.component.html'
})

// This class represents the last stept of the New Project wizard.
export class NewProjectSummaryComponent implements OnInit, OnDestroy {

   @Input() componentHeight: number; // Heigth of the compoment.
   @Input() projectWizardModel: NewProjectWizard; // Model object which cached the info from the wizard.
   @ViewChild('summaryForm') form: NgForm; // Reference to the summary form.

   // Events triggered to inform the parent component about the step to navigate to.
   @Output() settings: EventEmitter<any> = new EventEmitter<any>();
   @Output() callPoints: EventEmitter<any> = new EventEmitter<any>();
   @Output() callers: EventEmitter<any> = new EventEmitter<any>();
   @Output() events: EventEmitter<any> = new EventEmitter<any>();

   public callpointProgressValue: number = 0;
   public callerProgressValue: number = 0;

   private _project_created_subscription: Subscription;

   private _callerInterval: any = null; // Timer used by the progress bar
   private _callpointInterval: any = null; // Timer used by the progress bar

   constructor(private _applicationStore: ApplicationStore) {
      this.subscribeToProjectCreated();
   }

   public ngOnInit() {

   }

   public ngOnDestroy(): void {
      if(this._project_created_subscription){
         this._project_created_subscription.unsubscribe();
      }

      // destroy the timmers
      clearInterval(this._callpointInterval);
      clearInterval(this._callerInterval);
   }

   public goToSettings() {
      this.settings.next();
   }

   public goToCallers() {
      this.callers.next();
   }

   public goToCallpoints() {
      this.callPoints.next();
   }

   public goToEvents() {
      this.events.next();
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      let callersloaded: boolean = false;
      let callpointsloaded: boolean = false;

      if (this.projectWizardModel) {
         callersloaded = this.projectWizardModel.hasCompletedCallersUploadedToServer && !this.projectWizardModel.callerUploadedToServerError
      }

      if (this.projectWizardModel) {
         callpointsloaded = this.projectWizardModel.hasCompletedCallpointsUploadedToServer && !this.projectWizardModel.callpointsUploadedToServerError
      }


      return callersloaded && callpointsloaded;
   }

   private startCallersProgressBar() {
      let maxValue = 100;
      this._callerInterval = setInterval(() => {
         if (this.callerProgressValue < maxValue) {
            this.callerProgressValue += 10;
         }
         else {
            this.callerProgressValue = 0;
         }

         if (this.projectWizardModel.hasCompletedCallersUploadedToServer) {
            this.stopCallerProgessBar();
         }
      }, 1000);
   }

   private startCallpointsProgressBar() {
      let maxValue = 100;
      this._callpointInterval = setInterval(() => {
         if (this.callpointProgressValue < maxValue) {
            this.callpointProgressValue += 5;
         }
         else {
            this.callpointProgressValue = 0;
         }

         if (this.projectWizardModel.hasCompletedCallpointsUploadedToServer) {
            this.stopCallpointsProgessBar();
         }
      }, 1000);
   }

   private stopCallpointsProgessBar() {
      this.callpointProgressValue = 100;
      clearInterval(this._callpointInterval);
   }

   private stopCallerProgessBar() {
      this.callerProgressValue = 100;
      clearInterval(this._callerInterval);
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this.projectWizardModel.projectId = project.projectId;
               this.startCallersProgressBar();
               this.startCallpointsProgressBar();
            }
         }
      );
   }
}
