import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/components/common/selectitem';
import * as moment from 'moment/moment';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { NewFolderDialogComponent } from 'app/shared/new-folder-dialog/new-folder-dialog.component';
import { ApplicationStore } from 'app/stores/application-store';


@Component({
   selector: 'callsmart-new-project-settings',
   templateUrl: './new-project-settings.component.html',
})

// This class represents the first step of the New Project wizard.
export class NewProjectSettingsComponent implements OnInit, OnDestroy {

   private _project_folders_subscription: Subscription;

   public cycleStartDateStr: string;
   public cycleEndDateStr: string;
   public en: any; // contains new names for days of week and months

   @Input() componentHeight: number; // Heigth of the compoment
   @Input() projectWizardModel: NewProjectWizard; // model object which cached the info from the wizard
   @ViewChild('settingsForm') form: NgForm; // Reference to the settings form

   // Determines whether to display the  dialog.
   public showNewFolderDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public newFolderDialog = NewFolderDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = { display: false, title: 'Create new folder' };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (folder) => this.onSaveEvent(folder),
      cancel: () => this.onCancelEvent()
   };

   public projectFolders: SelectItem[];
   public selectedFolder: string;

   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {

      this.subscribeToFolders();

      this.dialogInput = { display: false, title: 'Create folder' };

      // This property customises all calendar names and formats to be displayed according to the design.
      this.en = {
         firstDayOfWeek: 1,
         dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
         dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
         monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         today: 'Today',
         clear: 'Clear'
      };
   }

   ngOnDestroy() {

      if (this._project_folders_subscription) {
         this._project_folders_subscription.unsubscribe();
      }
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      return this.form.valid;
   }

   // Event handler triggered when a date is selected in the calendar.
   // Sets the start cycle date and works out the end cycle date.
   public onDateSelected(selectedDate: Date) {
      this.projectWizardModel.startCycleDate = selectedDate;
      this.cycleStartDateStr = this.formatDate(this.projectWizardModel.startCycleDate);
      // Sets the start cycle date as a string.
      let momentStartDate: moment.Moment = moment(this.projectWizardModel.startCycleDate.getDate() + '-' +
         (this.projectWizardModel.startCycleDate.getMonth() + 1) + '-' +
         this.projectWizardModel.startCycleDate.getFullYear(), "DD/MM/YYYY");
      // Works out the end cycle date.
      this.projectWizardModel.endCycleDate = this.getCycleEndDate(momentStartDate);
      // Sets the end cycle date as a string.
      this.cycleEndDateStr = this.formatDate(this.projectWizardModel.endCycleDate);
   }

   // Event handler triggered when the start cycle date textbox loses the focus.
   // Sets the start cycle date and works out the end cycle date.
   public onCallCycleStartDateLostFocus(event) {
      this.workOutCycleEndDate();
   }

   // Event handler triggered when the number of weeks textbox loses the focus.
   // Sets the number of weeks and works out the end cycle date
   // if the start cycle date has already been defined.
   public onNumberOfWeeksLostFocus(event) {
      if (!this.isInt(this.projectWizardModel.numberOfWeeks)) {
         return;
      }
      if (this.projectWizardModel.numberOfWeeks && this.projectWizardModel.startCycleDate) {
         let momentStartDate: moment.Moment = moment(this.projectWizardModel.startCycleDate.getDate() + '-' +
            (this.projectWizardModel.startCycleDate.getMonth() + 1) + '-' +
            this.projectWizardModel.startCycleDate.getFullYear(), "DD/MM/YYYY");
         this.projectWizardModel.endCycleDate = this.getCycleEndDate(momentStartDate);
         this.cycleEndDateStr = this.formatDate(this.projectWizardModel.endCycleDate);
      }
   }

   // Checks whether a date (formed by day, month and year)
   // is between a range defined by the start and end cycle dates.
   public isCalendarDateInRange(day, month, year) {
      let calendarDate = new Date(year, month, day);
      if (this.projectWizardModel.startCycleDate && this.projectWizardModel.endCycleDate) {
         return (calendarDate >= this.projectWizardModel.startCycleDate &&
            calendarDate <= this.projectWizardModel.endCycleDate) ? true : false;
      }
      return false;
   }

   public workOutCycleEndDate() {
      let typedDate: moment.Moment = moment(this.cycleStartDateStr, "DD/MM/YYYY");
      // Checks if te typed date is valid
      if (typedDate.isValid()) {
         this.projectWizardModel.startCycleDate = typedDate.toDate();
         // Works out the end cycle date.
         this.projectWizardModel.endCycleDate = this.getCycleEndDate(typedDate);
         this.cycleEndDateStr = this.formatDate(this.projectWizardModel.endCycleDate);
      }
      else {
         this.cycleStartDateStr = this.projectWizardModel.startCycleDate
            ? this.formatDate(this.projectWizardModel.startCycleDate)
            : "";
      }
   }

   public createFolder() {
      this.showNewFolderDialog = true;
      this.dialogInput.display = true;
   }

   // cancel button from the add\edit event dialog
   public onCancelEvent() {
      this.showNewFolderDialog = false;
      this.dialogInput.display = false;
   }

   // save button from the change password dialog
   public onSaveEvent(folder:string) {
      // save the new folder name
      this.projectWizardModel.folder = folder;

      // proactively add it to the list of folder names
      this.addNewFolderToComboData(folder)

      // select new folder
      this.selectedFolder = folder;

      this.showNewFolderDialog = false;
      this.dialogInput.display = false;
   }

   public folderChanged(){
      this.projectWizardModel.folder = this.selectedFolder;
   }

   // Returns the date passed as a parameter as a string with 'dd/mm/yyyy' format.
   private formatDate(date): string {
      var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) {
         month = '0' + month;
      }
      if (day.length < 2) {
         day = '0' + day;
      }

      return [day, month, year].join('/');
   }

   // Works out the end cycle date based on the start cycle date and the number of week.
   private getCycleEndDate(startDate: moment.Moment): Date {
      if (this.projectWizardModel.numberOfWeeks && this.isInt(this.projectWizardModel.numberOfWeeks)  &&
          this.projectWizardModel.numberOfWeeks >= 1 && this.projectWizardModel.numberOfWeeks <= 52 ) {
         let momentEndDate: moment.Moment = startDate.clone().add(this.projectWizardModel.numberOfWeeks, 'w')
            .subtract(1, 'd');
         return momentEndDate.toDate();
      }
      return startDate.toDate();
   }

   // Checks if  value is an interger.
   private isInt(value) {
      if (isNaN(value)) {
         return false;
      }
      var x = parseFloat(value);
      return (x | 0) === x;
   }

   private addNewFolderToComboData(folder:any){
      // check if exists
      let match = this.projectFolders.find(f => f.label == folder);

      if(match == null || match == undefined) {
         this.projectFolders.push({ label: folder, value: folder })
      }
   }

   private buildFolderComboData(folders: ReadonlyArray<string>) {

      let userFolderName = this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules";
      let defaultFolderExists = false;
      let userDefaultFolder = { label:userFolderName, value: userFolderName}

      if (folders.length > 0) {

         //clear list
         this.projectFolders = [];

         // build list
         folders.forEach(f => {
            if(f == userFolderName){
               defaultFolderExists = true;
            }

            this.projectFolders.push({ label: f, value: f })
         });

         // check if user default being used
         if(!defaultFolderExists){
            this.projectFolders.push(userDefaultFolder )
         }

         this.selectedFolder = this.projectWizardModel.folder;
      }
   }

   private subscribeToFolders() {
      this._project_folders_subscription = this._applicationStore.projectsStore.projectFolders$.subscribe(
         (folders: string[]) => {
            if (folders) {
               this.buildFolderComboData(folders);
            }
         }
      );
   }
}
