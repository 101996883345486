<p-dialog header="Reassign callpoint - {{callpoint.name}}, {{callpoint.reference}}" 
   modal="true" width="800" 
   [(visible)]="display" 
   [draggable]="false" 
   [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">
      <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">

         <div style="margin-top:5px;margin-bottom:5px;">
            Reassign callpoint to a different caller.
         </div>

         <div class="cs-black-panel" style="padding-left: 0px;">
            <div class="panel-body" style="padding-left: 0px;">
               <div class="row" style="display: flex;flex-direction: row;">
                  <div class="col-sm-5" style="display: flex;align-items: center; padding-left:0px;">
                     <p>
                        <span class="pull-left" *ngIf="isCallerSelectionEmpty"> *At least one caller must be selected</span>
                     </p>
                  </div>
               </div>

               <div class="search" style="margin-bottom: 5px;">
                  <i class="cs-icon icon-search-1"></i>
                  <input #gb type="text" role="textbox" class="ui-inputtext ui-widget ui-state-default ui-corner-all" placeholder="Search">

               </div>
               <div class="form-group">
                  <div class="col-xs-12 black-datatable no-horizontal-scrollbar">
                     <p-dataTable [value]="projectCallers" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="single" [(selection)]="selectedCaller"
                        scrollable="true" [scrollHeight]="gridScrollHeight" [globalFilter]="gb" #callerdt id="black-datatable"
                        ngClass-name>
                        <p-column field="territory" header="Code" [style]="{'width':'150px'}"></p-column>
                        <p-column field="name" header="Name" [style]="{'width':'200px'}"></p-column>
                     </p-dataTable>
                  </div>
               </div>
            </div>
         </div>

      </perfect-scrollbar>

      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!formValid" (click)="onSave()">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
