import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { environment } from 'environments/environment';
import { ApplicationStore } from 'app/stores/application-store';
import { User } from 'app/models/user';
import { PasswordResetDialogComponent } from 'app/login/password-reset-dialog/password-reset-dialog.component';
import { Maintenance } from 'app/models/maintenance';

/**
 * Login component, handles user authentication and password reset functionality.
 */
@Component({
   selector: 'callsmart-login',
   templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

   private _user_subscription: Subscription;
   private _loginError_subscription: Subscription;
   private _maintenance_subscription: Subscription;

   // Determine whether to display the password reset dialog.
   public showPasswordReset: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public passwordResetDialog = PasswordResetDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false
   };

   // Output parameters for the loaded component. This usually will be the
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      sendNewPassword: (email: string) => this.onPasswordReset(email),
      cancel: () => this.onCancelPasswordReset()
   };


   // Determines the visibility of the error message.
   public showError: boolean = false;

   // The error message to display.
   public errorMessage: string;

   public email: string;
   public password: string;

   public inMaintenance: boolean = false;
   public title: string = "CallSmart is undergoing maintenance, please come back later to use the application.";

   constructor(private router: Router, private _applicationStore: ApplicationStore, ) { }

   public ngOnInit() {
      this.subscribeToUser();
      this.subscribeToLoginError();
      this.subscribeToMaintenance();

      // check if the user has login before and the cookie is still valid
      this.checkIfAlreadySignedIn();
      this._applicationStore.sysAdminStore.getMaintenance();
   }

   public ngOnDestroy() {
      if (this._user_subscription) {
         this._loginError_subscription.unsubscribe();
      }

      if (this._loginError_subscription) {
         this._loginError_subscription.unsubscribe();
      }

      if (this._maintenance_subscription) {
         this._maintenance_subscription.unsubscribe();
      }
   }

   public onSignin(form: NgForm) {
      if (form.valid) {
         this._applicationStore.authenticationStore.login(this.email, this.password);
      }
   }

   public showPasswordResetDialog() {
      this.showPasswordReset = true;
      this.dialogInput.display = true;
   }

   public onPasswordReset(email: string) {
      this._applicationStore.authenticationStore.resetPassword(email);
   }

   public onCancelPasswordReset() {
      this.showPasswordReset = false;
      this.dialogInput.display = false;
   }

   private checkIfAlreadySignedIn() {
      let result = this._applicationStore.authenticationStore.isAuthenticated();
      if (result) {
         this.navigateToLandingPage();
      }
   }

   private subscribeToLoginError() {
      this._loginError_subscription = this._applicationStore.authenticationStore.loginError$.subscribe(
         (error: string) => {
            if (error) {
               this.showError = true;

               // remove the unauthorised url bit
               this.errorMessage = error;
            }
         }
      );
   }

   // this sets up the subscription to listen to the user when the user changes or is logged in
   private subscribeToUser() {
      this._user_subscription = this._applicationStore.authenticationStore.user$.subscribe(
         (user: User) => {
            if (user) {
               if (user.userId > 0) {
                  this.navigateToLandingPage();
               }
            }
         }
      );
   }

   // the landing page should be the open projects page.
   // for developers we are constantly refreshing our pages with builds, we want it to be the
   // dashboard and auto open the first project
   private navigateToLandingPage() {
      if (environment.loadFirstProject) {
         this.router.navigate(['dashboard']);
      } else {
         this.router.navigate(['open-project']);
      }
   }

   private subscribeToMaintenance() {
      this._maintenance_subscription = this._applicationStore.sysAdminStore.maintenance$.subscribe(
         (maintenance: Maintenance) => { this.maintenanceHandler(maintenance); });
   }

   private maintenanceHandler(maintenance: Maintenance){
      if(maintenance !== null){
         this.inMaintenance = maintenance.WebServiceInMaintenance || maintenance.DatabaseInMaintenance;

         if(this.inMaintenance){

            // if in maintenance, check every minute for change in status
            setTimeout(() => { this._applicationStore.sysAdminStore.getMaintenance(); }, 60 * 1000);
         }
      }
   }
}
