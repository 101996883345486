<div class="panel">
   <div class="panel-body" style="overflow:hidden;">
      <div class="row">
         <div class="col-md-12">
            <edit-caller-settings name="editCallerSettings" 
               [(ngModel)]="callerSettingsModel" 
               [scrollHeight]="scrollHeight" 
               [displayHeading]="false"   
               [projectCycleLength]="projectCycleLength"
               #editCallerSettings>
            </edit-caller-settings>
         </div>
      </div>

      <div class="row">
         <div class="col-md-12">
            <div class="btn-toolbar">
               <input type="submit" [disabled]="!formValid || canDeactivate()" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save"
                  (click)="submitForm()">
               <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel"
                  (click)="onCancel()">
            </div>
         </div>
      </div>
   </div>
</div>
