<p-dialog [header]="headerText" [(visible)]="display" modal="modal" width="1000" [draggable]="false" [resizable]="false" (onHide)="onCancel()">
   <form #form="ngForm" class="form-horizontal" (submit)="submitForm(form)" autocomplete="off" autocomplete="off">
      <div class="panel" style="border:none;">
         <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
            <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">

               <div class="cs-setting-panel">
                  <div class="panel-body">
                     <div class="row">
                        <div class="col-sm-12" style="padding-left:0px">
                           <h6>Event name</h6>
                        </div>
                     </div>
                     <div class="form-group row">
                        <div class="col-sm-4">
                           <input required name="eventName" #eventName="ngModel" id="eventName"
                           [(ngModel)]="calendarEvent.title" class="form-control cs-form-control" focusDirective cssSelector="#eventName">
                           <span *ngIf="eventName.touched && eventName.errors">
                              <label *ngIf="eventName.errors.required">Please insert event name</label>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="cs-setting-panel">
                  <div class="panel-body">
                     <div class="row">
                        <div class="col-sm-12" style="padding-left:0px">
                           <h6>Roll with call cycle</h6>
                           <p>Whether the event will move relatively when the call cycle is rolled forward.</p>
                        </div>
                     </div>
                     <div class="form-group row">
                        <div class="col-sm-4">
                           <callsmart-inputswitch name="canRollSwitch" onLabel="No" offLabel="Yes" [(ngModel)]="!calendarEvent.canRoll"
                                 inverseColours="true" >
                              </callsmart-inputswitch>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="cs-setting-panel">
                  <div class="panel-body">
                     <div class="row" style="display: flex;flex-direction: row;">
                        <div class="col-sm-2" style="display: flex;align-items: center;padding-left:0px">
                           <h6>Date/time</h6>
                        </div>
                        <div class="col-sm-10" style="display: flex;align-items: center;justify-content: flex-end;">
                           <span class="pull-right" *ngIf="!isDateTimeRangeValid"> *End date/time must be later than start date/time</span>
                           <span class="pull-right" *ngIf="!isDateInCycleValid"> *The date selected is not valid for this call cycle</span>
                        </div>
                     </div>
                     <div class="form-group row">
                        <div class="col-sm-1"></div>

                        <div class="col-sm-3" style="margin-left: 16px;">
                           <p-checkbox [(ngModel)]="isAllDayEvent" name="allDay" binary="true" label="All Day">
                           </p-checkbox>

                        </div>
                     </div>
                     <div class="form-group" style="display: flex;flex-direction: row;">
                        <label for="eventStartDate" class="control-label" style="margin-left: 15px;">Start:</label>
                        <div style="margin-left:15px">
                           <p-calendar inputStyleClass="dialog-caledar-width" class="calendar-height" [showIcon]="true" dateFormat="dd/mm/yy" [(ngModel)]="startDate"
                              [locale]="en" placeholder="dd/mm/yy" [minDate]="startCycleDate" [maxDate]="endCycleDate" selectOtherMonths="true"
                              name="eventStartDate" #eventStartDate="ngModel" required="true" (ngModelChange)="onStartDateChanged()"></p-calendar>

                           <span *ngIf="eventStartDate.touched && eventStartDate.errors">
                              <label *ngIf="eventStartDate.errors.required">Please enter start date</label>
                           </span>
                        </div>
                        <div style="margin-left:51px">
                           <callsmart-clock-time-picker [(ngModel)]="startTimeStr" [required]="true" [pattern]="'([0-1]?[0-9]|2[0-3]):[0-5][0-9]'" name="eventStartTime"
                              #eventStartTime="ngModel" [disabled]="isAllDayEvent"></callsmart-clock-time-picker>
                           <span *ngIf="eventStartTime.errors">
                              <label *ngIf="eventStartTime.errors.required">Please enter start time</label>
                              <label *ngIf="eventStartTime.errors.pattern">Please enter start time in the right format (hh:mm)</label>
                           </span>
                        </div>
                        <div style="margin-left: 15px">
                              <callsmart-clock-time-picker [(ngModel)]="endTimeStr" [required]="true" [pattern]="'([0-1]?[0-9]|2[0-3]):[0-5][0-9]'"
                                 textBoxWidth="100px" name="eventEndTime" #eventEndTime="ngModel" [disabled]="isAllDayEvent"></callsmart-clock-time-picker>
                              <span *ngIf="eventEndTime.errors">
                                 <label *ngIf="eventEndTime.errors.required">Please enter end time</label>
                                 <label *ngIf="eventEndTime.errors.pattern">Please enter end time in the right format (hh:mm)</label>
                              </span>
                           </div>
                     </div>
                     <!-- <div class="form-group row">
                        <label for="eventStartDate" class="col-sm-1 control-label" style="margin-left: 15px;">Start:</label>
                        <div class="col-sm-2">
                           <p-calendar inputStyleClass="dialog-caledar-width" class="calendar-height" [showIcon]="true" dateFormat="dd/mm/yy" [(ngModel)]="startDate"
                              [locale]="en" placeholder="dd/mm/yy" [minDate]="startCycleDate" [maxDate]="endCycleDate" selectOtherMonths="true"
                              name="eventStartDate" #eventStartDate="ngModel" required="true" (ngModelChange)="onStartDateChanged()"></p-calendar>

                           <span *ngIf="eventStartDate.touched && eventStartDate.errors">
                              <label *ngIf="eventStartDate.errors.required">Please enter start date</label>
                           </span>
                        </div>

                        <div class="col-sm-2" style="padding-right: 0px; padding-left:30px">
                           <callsmart-clock-time-picker [(ngModel)]="startTimeStr" [required]="true" [pattern]="'([0-1]?[0-9]|2[0-3]):[0-5][0-9]'" name="eventStartTime"
                              #eventStartTime="ngModel" [disabled]="isAllDayEvent"></callsmart-clock-time-picker>
                           <span *ngIf="eventStartTime.errors">
                              <label *ngIf="eventStartTime.errors.required">Please enter start time</label>
                              <label *ngIf="eventStartTime.errors.pattern">Please enter start time in the right format (hh:mm)</label>
                           </span>
                        </div>

                        <div class="col-sm-2" style="padding-left: 0px">
                           <callsmart-clock-time-picker [ngClass]="'pull-left'" [(ngModel)]="endTimeStr" [required]="true" [pattern]="'([0-1]?[0-9]|2[0-3]):[0-5][0-9]'"
                              textBoxWidth="100px" name="eventEndTime" #eventEndTime="ngModel" [disabled]="isAllDayEvent"></callsmart-clock-time-picker>
                           <span [ngClass]="'pull-left'" *ngIf="eventEndTime.errors">
                              <label *ngIf="eventEndTime.errors.required">Please enter end time</label>
                              <label *ngIf="eventEndTime.errors.pattern">Please enter end time in the right format (hh:mm)</label>
                           </span>
                        </div>


                     </div> -->

                     <!-- <div class="form-group row">

                        <label for="endDate" class="col-sm-1 control-label" style="margin-left: 20px;">End:</label>
                        <div class="col-sm-2">
                           <p-calendar inputStyleClass="dialog-caledar-width" class="calendar-height" [showIcon]="true" dateFormat="dd/mm/yy" [locale]="en"
                              [(ngModel)]="endDate" placeholder="dd/mm/yy" required="true" [disabled]="isAllDayEvent"
                              [minDate]="startCycleDate" [maxDate]="endCycleDate" selectOtherMonths="true"
                              name="eventEndDate" #eventEndDate="ngModel"></p-calendar>
                           <span *ngIf="eventEndDate.touched && eventEndDate.errors">
                                    <label *ngIf="eventEndDate.errors.required">Please insert end date</label>
                              </span>
                        </div>

                         <div class="col-sm-3">
                           <callsmart-clock-time-picker [(ngModel)]="endTimeStr"  [required]="true"
                              [pattern]="'([0-1]?[0-9]|2[0-3]):[0-5][0-9]'" textBoxWidth="100px" name="eventEndTime" #eventEndTime="ngModel"
                              [disabled]="isAllDayEvent"></callsmart-clock-time-picker>
                           <span *ngIf="eventEndTime.errors">
                                 <label *ngIf="eventEndTime.errors.required">Please enter end time</label>
                                 <label *ngIf="eventEndTime.errors.pattern">Please enter end time in the right format (hh:mm)</label>
                              </span>
                        </div>

                     </div> -->
                  </div>
               </div>

               <div class="cs-black-panel">
                  <div class="panel-body">
                     <div class="row" style="display: flex;flex-direction: row;">
                        <div class="col-sm-5" style="display: flex;align-items: center; padding-left:0px;">
                           <p>Selected Callers ({{selectedCallers.length}} selected)</p>
                        </div>
                        <div class="col-sm-7" style="display: flex;align-items: center;justify-content: flex-end;">
                           <span class="pull-right" *ngIf="isCallerSelectionEmpty"> *At least one caller must be selected</span>
                        </div>
                     </div>

                     <div class="search" style="margin-bottom: 5px;">
                        <i class="cs-icon icon-search-1"></i>
                        <input #gb type="text" role="textbox" class="ui-inputtext ui-widget ui-state-default ui-corner-all" placeholder="Search for name">

                     </div>
                     <div class="form-group">
                        <div class="col-xs-12 black-datatable no-horizontal-scrollbar">
                           <p-dataTable [value]="projectCallers" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="multiple" [(selection)]="selectedCallers"
                              scrollable="true" [scrollHeight]="gridScrollHeight" [globalFilter]="gb" #callerdt id="black-datatable"
                              ngClass-name>
                              <p-column [style]="{'width':'30px'}" selectionMode="multiple"></p-column>
                              <p-column field="territory" header="Code" [style]="{'width':'150px'}"></p-column>
                              <p-column field="name" header="Name" [style]="{'width':'200px'}"></p-column>
                           </p-dataTable>
                        </div>
                     </div>
                  </div>
               </div>


            </perfect-scrollbar>
         </div>
      </div>
      <p-footer>
         <div style="padding-top: 10px;">
            <input type="submit" [disabled]="!formValid" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save">
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onDelete()" style="margin-right: 8px;" *ngIf="showDelete">Delete event</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>
   </form>
</p-dialog>
