import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ImportOptions } from 'app/models/import-options';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// This store is responsible for providing the import options to registered subscribers. Used by the 
// Import Data wizard to determine which step to display.

@Injectable()
export class ImportDataStore {

   // Selected import options from the import dialog.
   private _importOptions: BehaviorSubject<ImportOptions> = new BehaviorSubject<ImportOptions>(null);
   public importOptions$: Observable<ImportOptions> = this._importOptions.asObservable();

   constructor() { }

   public get importOptions(): ImportOptions {
      return this._importOptions.getValue();
   }

   public setImportOptions(importOptions: ImportOptions) {
      this._importOptions.next(importOptions);
   }
}
