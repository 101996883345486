import { JsonObject, JsonProperty, Any } from 'json2typescript';

import { NullConverter } from 'app/shared/json-converters/null-converter';
import { DateArrayConverter } from 'app/shared/json-converters/date-array-converter';
import { DayCombinationConverter } from 'app/shared/json-converters/day-combination-converter';

/**
 * Callpoint settings view model class.
 */
@JsonObject
export class CallpointSettings {
   @JsonProperty('callpointSettingsId', NullConverter, true)
   public callpointSettingsId: number = undefined;

   @JsonProperty('projectId', NullConverter, true)
   public projectId: number = undefined;

   @JsonProperty('callpointId', NullConverter, true)
   public callpointId: number = undefined;

   @JsonProperty('userId', NullConverter, true)
   public userId: number = undefined;

   @JsonProperty('availability', DateArrayConverter, true)
   public availability: Date[] = undefined;

   @JsonProperty('allowAllDayCombination', NullConverter, true)
   public allowAllDayCombination: boolean = undefined;

   @JsonProperty('dayCombinations', DayCombinationConverter, true)
   public dayCombinations: string[] = undefined;

   @JsonProperty('priorityAmplifier', NullConverter, true)
   public priorityAmplifier: number = undefined;

   @JsonProperty('temporalPrioritisation', NullConverter, true)
   public temporalPrioritisation: number = undefined;

   @JsonProperty('priorityDisabled', NullConverter, true)
   public priorityDisabled: boolean = undefined;
}
