<div class="row top-content-border" [ngStyle]="{'height.px': workspaceHeight}" style="margin-left:30px;margin-right:30px ;">

   <div [ngStyle]="{'height.px': scrollHeight}">
      <form #form="ngForm" class="form-horizontal ed-form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">

         <div class="panel" style="border:none;">
            <div style="margin-top: 10px;">

               <div class="cs-setting-panel">
                  <div class="panel-body">
                     <h6>Date Range</h6>
                     <div class="row">
                        <div class="form-group">
                           <label class="col-sm-1">Start: </label>
                           <div class="col-sm-2">
                              <p-calendar inputStyleClass="dialog-caledar-width" class="calendar-height" [showIcon]="true" dateFormat="dd/mm/yy" [(ngModel)]="startDate"
                                 [locale]="en" placeholder="dd/mm/yy" selectOtherMonths="true" name="startDate" required="true"></p-calendar>
                           </div>
                           <label class="col-sm-1" >End: </label>
                           <div class="col-sm-2">
                              <p-calendar inputStyleClass="dialog-caledar-width" class="calendar-height" [showIcon]="true" dateFormat="dd/mm/yy" [(ngModel)]="endDate"
                                 [locale]="en" placeholder="dd/mm/yy" selectOtherMonths="true" name="endDate" required="true"></p-calendar>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="cs-setting-panel">
                  <div class="panel-body">
                     <h6>
                        Select the data you would like to export
                     </h6>
                     <br>

                     <div class="row">
                        <div>
                           <div class="col-sm-5">
                              <label>
                                 <p-checkbox [(ngModel)]="exportOptions.exportUserAccess" binary="true" name="exportUserAccess" label=""></p-checkbox>
                                 <i class="cs-icon icon-users export-filter-text" style="margin-left: 10px;"></i>User Access log</label>
                           </div>
                        </div>
                     </div>

                     <div class="row ">
                        <div>
                           <div class="col-sm-5">
                              <label>
                                 <p-checkbox [(ngModel)]="exportOptions.exportOptimisationLog" binary="true" name="exportOptimisationLog" label=""></p-checkbox>
                                 <i class="cs-icon icon-calendar-2 export-filter-text" style="margin-left: 10px;"></i>Optimisation log</label>
                           </div>
                        </div>
                     </div>

                     <div class="row ">
                        <div>
                           <div class="col-sm-5">
                              <label>
                                 <p-checkbox [(ngModel)]="exportOptions.exportServiceRequestLog" binary="true" name="exportServiceRequestLog" label=""></p-checkbox>
                                 <i class="cs-icon icon-calendar-2 export-filter-text" style="margin-left: 10px;"></i>Service Request log</label>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>

      </form>
   </div>
   <div class="row">

      <hr>
      <div style="float:right;">

         <button type="button" class="btn cs-btn-primary-inverse" (click)="onExport(form)" [disabled]="(!form.valid ||(!exportOptions.exportUserAccess && !exportOptions.exportOptimisationLog && !exportOptions.exportServiceRequestLog))">Export</button>
      </div>
   </div>

</div>
