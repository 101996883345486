<div style="display: inline-block; width: 100%">
      <h6 style="float:left;">Callpoint settings</h6>
      <div style="float: right; margin-top: 6px;">
         <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle" 
            aria-hidden="true" (click)="editSettings()"></i>
      </div>
   </div>
   <div *ngIf="callpointSettings">
      <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-77}">
   
         <div class="no-padding contextual-panel-list">
            <div class="no-padding create-new-settings-panel">
               <div class="contextualPanel-list-items-firstline">Callpoint availability:</div>
               <div class="contextualPanel-list-items-secondline">
                  <div class="display-flex-column display-flex-first-column">
                     {{getHoursAndMinutes(callpointSettings.availability[0])}} - {{getHoursAndMinutes(callpointSettings.availability[1])}}
                  </div>
               </div>
            </div>
         </div>

         <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Day combinations:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{ isAllowAllSelected ? 'Allow all' : 'Allow specific' }}

               </div>
            </div>
         </div>
      </div>
      
      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div *ngIf="!callpointSettings.priorityDisabled; else noPriority">
               <div class="contextualPanel-list-items-firstline">Priority amplifier:</div>
               <div class="contextualPanel-list-items-secondline">
                  <div class="display-flex-column display-flex-first-column">
                     {{callpointSettings.priorityAmplifier}}

                     <div class="contextualPanel-list-items-nested">
                        Temporal prioritisation:
                     </div>
                     {{callpointSettings.temporalPrioritisation}}

                  </div>
               </div>
            </div>
            <ng-template #noPriority>
               <div class="contextualPanel-list-items-firstline">Priority:</div>
               <div class="contextualPanel-list-items-secondline">
                  <div class="display-flex-column display-flex-first-column">
                     Off
                  </div>
               </div>
            </ng-template>
         </div>
      </div>
         
      </perfect-scrollbar>
   </div>
   
   <!-- This is where project settings dialog component will be loaded dynamically -->
   <ndc-dynamic *ngIf="showSettings" 
      [ndcDynamicComponent]="editCallpointSettingsDialog"
      [ndcDynamicInputs]="dialogInput"
      [ndcDynamicOutputs]="dialogOutput">
   </ndc-dynamic>
      <!-- This is where project settings dialog component will be loaded dynamically -->
   <ndc-dynamic *ngIf="showErrorDialog" 
      [ndcDynamicComponent]="ErrorDialog"
      [ndcDynamicInputs]="errorDialogInput"
      [ndcDynamicOutputs]="erroDialogOutput">
   </ndc-dynamic>
