import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';

import { BrowserWindowService } from "app/services/browser-window.service";
import { ApplicationStore } from 'app/stores/application-store';

/**
 * Component for the settings workspace.
 */
@Component({
   moduleId: module.id,
   selector: 'callsmart-settings-workspace',
   templateUrl: 'settings-workspace.component.html'
})
export class SettingsWorkspaceComponent implements OnInit {

   public menu: MenuItem[];
   public menuHeight: number;

   constructor(private windowService: BrowserWindowService, 
               private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.menuHeight = value - 182;
      });
   }

   ngOnInit() {
      this.menu = [{
         items: [
            { label: 'Settings defaults', routerLink: ['/settings/project'] },
            { label: 'Caller defaults', routerLink: ['/settings/callers'] },
            { label: 'Callpoint defaults', routerLink: ['/settings/callpoints'] }
         ]
      }];
   }
}
