
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';

import { Notification } from 'app/models/notification';


// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class NotificationsStore {

   // Notifications
   // All notifications.
   private _notifications: BehaviorSubject<ReadonlyArray<Notification>> = new BehaviorSubject<ReadonlyArray<Notification>>([]);
   private _cachedNotifications: Notification[] = [];
   public notifications$: Observable<ReadonlyArray<Notification>> = this._notifications.asObservable();

   public get notifications() {
      return this._notifications.getValue();
      //return this._cachedNotifications.slice();
   }

   public get notificationHistory() {
      return this._cachedNotifications;
   }

   constructor() {

   }

   // Sends a notification to be displayed in the toast panel.
   public sendNotification(notification: Notification) {
      this._notifications.next([notification]);
      this._cachedNotifications.push(notification);
   }

   // Sends several notifications in a row to be displayed in the toast panel.
   public sendNotifications(notifications: Notification[]) {
      this._notifications.next(notifications);
      this._cachedNotifications.push.apply(this._cachedNotifications, notifications);
   }

   // Creates a notificiation object.
   public createNotification(title: string, message: string) {
      let notification: Notification = new Notification();
      notification.id = this.generateGUID();
      notification.summary = title;
      notification.detail = message;
      notification.severity = 'info';
      notification.createDate = moment().toDate();
      return notification;
   }


   private generateGUID() {
      let d = new Date().getTime();
      let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         let r = (d + Math.random() * 16) % 16 | 0;
         d = Math.floor(d / 16);
         return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return guid;
   };
}
