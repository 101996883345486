import { Component, OnInit, OnDestroy } from '@angular/core';
import { Caller } from "app/models/caller";
import { Subscription } from "rxjs";
import { ApplicationStore } from "app/stores/application-store";

declare function require(name: string): any;  // requires is a way to reference javascript files
var Swiper = require('Swiper'); // use requires to reference the mswiper class in the js file

@Component({
   selector: 'callsmart-caller-carousel',
   templateUrl: './caller-carousel.component.html'
})
export class CallerCarouselComponent implements OnInit, OnDestroy {
   private _callersSubscription: Subscription;
   callers: ReadonlyArray<Caller> = [];
   mySwiper;
   firstLoad: Boolean;


   constructor(private _applicationStore: ApplicationStore) {

   }

   ngOnInit() {

      this.subscribeToCallers();
   }

   ngOnDestroy() {
      if (this.mySwiper) {
         // something going wrong with the swiper destroy and creation can end up with undefined
         if (this.mySwiper.destroy) {
            this.mySwiper.destroy(true, true);
            this.mySwiper = null;
         }
      }
      if (this._callersSubscription) {
         this._callersSubscription.unsubscribe();
      }

   }

   onKeyPress(swiper, kc) {
      if (this.callers) {
         let activeCallerIndex = this.callers.findIndex(x => x.callerId == this._applicationStore.callersStore.selectedCaller.callerId
         )
         // on key press we are going to set the actiive caller to the next person in the list
         // based on the arrow keys.
         if (kc === 37) {
            //left button reduce active index
            if (activeCallerIndex != 0) {
               this.setCallerActive(this.callers[activeCallerIndex - 1])
            }
         }
         if (kc === 39) {
            //right button increase active index

            if (activeCallerIndex != this.callers.length - 1) {
               this.setCallerActive(this.callers[activeCallerIndex + 1])
            }
         }

         event.cancelBubble = true;
      }
   }

   onSliderClick(event, caller: Caller, swiperWrapper) {

      // if the caller clicks on the active caller do nothing
      if (this._applicationStore.callersStore.selectedCaller.territory != caller.territory) {
         this.setCallerActive(caller);
      }
   }

   private defaultToActiveCallerSlide() {

      if (this.mySwiper) {
         if (this._applicationStore.callersStore.selectedCaller) {
            var index = this.callers.findIndex(c => c.territory == this._applicationStore.callersStore.selectedCaller.territory);

            this.callers[index].isActive = true;

            // something going wrong with the swiper destroy and creation
            if (this.mySwiper.slideTo) {
               this.mySwiper.slideTo(index, 1000, false);
            }
         } else {
            this.callers[0].isActive = true;
            this.setCallerActive(this.callers[0]);
            // something going wrong with the swiper destroy and creation
            if (this.mySwiper.slideTo) {
               this.mySwiper.slideTo(0, 1000, false);
            }
         }
      }
   }

   private setCallerActive(caller: Caller) {
      // clear any other callers that are active
      let allActiveCallers = this.callers.filter(c => c.isActive == true);
      allActiveCallers.forEach(e => {
         e.isActive = false;
      });

      if(this._applicationStore.callersStore.selectedCaller == null || 
         caller.callerId !== this._applicationStore.callersStore.selectedCaller.callerId) {
         // set the caller to active
         caller.isActive = true;
         this._applicationStore.callersStore.setSelectedCaller(caller);

      }
      
      // Need to remove any multi selected callers because the carousal only allows single selection of the caller.
      this._applicationStore.callersStore.setSelectedCallers([]);
   }

   private subscribeToCallers() {
      // As part of the initialisation all callers  needs to retrieve for the current project.
      // the caller service updates its callers through a subscription to the project service
      this._callersSubscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {

            if (callers) {
               if (callers.length > 0) {
                  this.loadSwiper();
               }
            }

            this.callers = callers;

            if (this._applicationStore.callersStore.selectedCaller) {
               //default to selected caller
               this.setCallerActive(this._applicationStore.callersStore.selectedCaller);
            }

         });

   }

   private loadSwiper() {
      this.firstLoad = true;

      // trying to ensure only one instance of swiper
      this.mySwiper = null;


      // load swiper
      this.mySwiper = new Swiper('.swiper-container', {
         // Optional parameters
         pagination: '.swiper-pagination',
         paginationClickable: true,
         nextButton: '.swiper-button-next',
         prevButton: '.swiper-button-prev',
         spaceBetween: 2,
         speed: 50,
         grabCursor: true,
         keyboardControl: true,
         mousewheelControl: true,
         //loop: true,
         observer: true,
         observeParents: true,
         runCallbacksOnInit: true,
         updateOnImagesReady: true,

         onKeyPress: (swiper, kc) => {
            this.onKeyPress(swiper, kc); // dont use this metod   //this.mySwiper.on("KeyPress", this.onKeyPress); as you lose the scope to the global varables
         },
         onAfterResize: (swiper) => {
            if (swiper.slides.length > 0 && this.firstLoad) {
               this.defaultToActiveCallerSlide();
               this.firstLoad = false;

            }
         },

         // Responsive breakpoints
         breakpoints: {
            // when window width is <= 320px
            320: {
               slidesPerView: 1
            },
            // when window width is <= 480px
            480: {
               slidesPerView: 2
            },
            // when window width is <= 640px
            640: {
               slidesPerView: 3
            },

            // when window width is <= 640px
            768: {
               slidesPerView: 3
            },

            1024: {
               slidesPerView: 5
            },

            1280: {
               slidesPerView: 6
            },

            1600: {
               slidesPerView: 8
            },

            2600: {
               slidesPerView: 8
            }

         }

      })

      // The slider gets created for each workspace
      // as it moves works space it gets destroyed. it is even set to null
      // yet on second initialisies it creates an array of 2 sliders have no idea why
      // for the slider back to a single
      if (this.mySwiper.length) {
         this.mySwiper[1].destroy();
         this.mySwiper = this.mySwiper[0];
      }
   }
}
