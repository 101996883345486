import { Component, Input, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { User } from 'app/models/user';

/**
 * Delete user dialog is a model window that displays the user the user has 
 * selected and confirms they want to delete the user.
 */
@Component({
  selector: 'callsmart-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html'
})
export class DeleteUserDialogComponent implements OnInit {

   // List of users to display in the delete list.
   public users: User[] = [];

   // user model used by this dialog.
   @Input() user: User;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() delete = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();


   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngOnInit() {
      this.users.push(this.user);
   }

   public onDelete() {
      // do the delete
      this._applicationStore.sysAdminStore.deleteUser(this.user.userId);
      this.display = false;
      this.delete.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}
