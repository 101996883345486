<div class="row">
   <div class="black-workspace col-md-12" style="padding: 10px">
      <!--<callsmart-initialise-project-progress></callsmart-initialise-project-progress>-->
      <div id="initialise-project-progress" style="display:flex; justify-content:center; margin:20px 150px 0px 150px">
         <div style="width:50%; margin-right:10px;">
            <h4>{{title}}</h4>

            <div *ngIf="initProjectUi" class="init-project-progress-panel">
               <p>
                  <i class="cs-initialise-project-progress-icon-green icon-check"></i>Importing Callers</p>
            </div>

            <div *ngIf="initProjectUi" class="init-project-progress-panel">
               <p>
                  <i class="cs-initialise-project-progress-icon-green icon-check"></i>Importing Callpoints</p>
            </div>

            <div class="init-project-progress-panel">
               <div *ngIf="errorMessage?.length === 0" class="project-status-spinner pull-right">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
               </div>
               <p>
                  <i [class]="drivetimeIconClass"></i>Computing drivetimes</p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Travel model: {{travelModel}}</p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Total number of drivetimes queued : {{totalQueuedDrivetimesCount}}</p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Number of drivetimes for current project queued : {{queuedProjectDrivetimesCount}} </p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Number of drivetimes calculating : {{pendingProjectDrivetimesCount}} </p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  {{drivetimeCount}} of {{callerCount}} drivetimes collated</p>
               <p-progressBar [value]="drivetimeProgress" [showValue]="false"></p-progressBar>
            </div>
   
            <div *ngIf="initProjectUi" class="init-project-progress-panel" style="overflow:auto">
               <div *ngIf="errorMessage?.length === 0" class="project-status-spinner pull-right">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
               </div>
               <p>
                  <i [class]="optimisingIconClass"></i>Fast optimising</p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Total number of optimisations queued : {{totalQueuedOptimisationsCount}}</p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Number of optimisations for current project queued : {{queuedProjectOptimisationsCount}} </p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  Number of optimisations calculating : {{pendingProjectOptimisationsCount}} </p>
               <p>
                  <i class="cs-initialise-project-progress-icon-spacer icon-placeholders"></i>
                  {{scheduleCount}} of {{callerCount}} schedules optimised</p>
               <p-progressBar [value]="optimiseProgress" [showValue]="false"></p-progressBar>
            </div>
            
            <div *ngIf="errorMessage?.length > 0" class="init-project-progress-panel" style="overflow:auto; color:red">
               <p>
                  <i class="cs-initialise-project-progress-icon icon-circle-backslash"></i>Error Messages</p>
               <p>{{errorMessage}}</p>
               <button type="button" class="btn cs-btn-primary-inverse pull-right" style="margin-right: 10px; margin-bottom: 15px;" (click)="onCancelProject()">Cancel</button>
            </div>

         </div>
         <div *ngIf="initProjectUi" class="init-project-progress-panel" style="width:50%; margin-top:57px; margin-left:10px;">
            <p-dataTable id="projectStatusTable" class="black-datatable no-horizontal-scrollbar" [value]="detail" [resizableColumns]="false"
               [reorderableColumns]="false" scrollHeight="450px" [scrollable]="true">
      
               <p-column field="callerKey" header="Caller key" [style]="{'width':'33%', 'overflow':'visible'}">
               </p-column>
      
               <p-column field="drivetimeStatus" header="Drivetimes status" [style]="{'width':'33%', 'overflow':'visible'}">
               </p-column>
      
               <p-column field="scheduleStatus" header="Optimiser status" [style]="{'width':'33%', 'overflow':'visible'}">
               </p-column>
      
            </p-dataTable>
         </div>
      </div>
   </div>
</div>

<!-- This is where route infill dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showRouteInfillDialog"
             [ndcDynamicComponent]="routeInfillDialog"
             [ndcDynamicInputs]="dialogInput"
             [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
   