<!-- <p-dataTable
             [rows]="rows"
            > -->

<!-- <p-dataTable [value]="visits" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="multiple"
                  [(selection)]="selectedVisits"
             [rows]="rows" (onLazyLoad)="loadDataLazy($event)" virtualScroll="virtualScroll" (onFilter)="onFilter($event)"  [lazy]="true"
             [totalRecords]="totalRecords"
             [scrollHeight]="visitTableHeight" [scrollWidth]="scrollWidth" [scrollable]="true"
             #visitdt> -->

<p-dataTable [value]="visits" [reorderableColumns]="false" [resizableColumns]="true" selectionMode="multiple"
             [(selection)]="selectedVisits" (onLazyLoad)="loadDataLazy($event)" (onFilter)="onFilter($event)"
             [scrollHeight]="visitTableHeight" [scrollable]="true"  [lazy]="true" [rows]="rows"
             [totalRecords]="totalRecords" virtualScroll="true"
             #visitdt>


   <p-header>
      <div style="float:left; margin-top: -4px;">
         <h4>{{gridTitle}}</h4>
      </div>
      <div style="float:left; height: 40px; padding-left: 10px;">
         <button type="button" id="clearFilter" type="button" class="btn cs-btn-secondary" (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
            <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
      </div>

      <div style="text-align:right">
         <!--span>No infite scrolling needs to come later problem with selection </span>-->
         <callsmart-multi-select [options]="columnOptions" [(ngModel)]="cols" [selectedItemsLabel]="selectedItemsLabel"
            [displaySelectedLabel]="displaySelectedLabel" [maxSelectedLabels]="maxSelectedLabels" [defaultLabel]="defaultLabel"
            [style]="{'width':'120px'}" [panelTitle]="panelTitle" [scrollHeight] = "filterColumnPanelHeight"
            (onChange)="setNewColumnSelection($event)"></callsmart-multi-select>

      </div>
   </p-header>

  <!-- check box for selection -->
   <p-column [style]="{'width':'40px', 'min-width':'35px','max-width':'40px', 'padding':'5px'}" selectionMode="multiple"></p-column>

   <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
   [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'width':'150px', 'min-width':'80px', 'overflow':'hidden'}">


      <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
         <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
            (onChange)="visitdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
         <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
            displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="visitdt.filter($event.value,col.field,col.filterMatchMode)"
            defaultLabel="All" appendTo="body"></p-multiSelect>
      </ng-template>

      <ng-container *ngIf="col.field == 'isScheduled'">
         <ng-template let-visit="rowData" pTemplate="body">
            <table>
               <tr>
                  <td style="text-align: center;">
                     <img *ngIf="visit.isScheduled===false" src='assets/icons/Cross.png' style='width:14px;height:14px;'>
                     <img *ngIf="visit.isScheduled===true" src='assets/icons/Check.png' style='width:14px;height:14px;'>
                  </td>
                  <td style="text-align: center;">
                     <!-- //Drag&Drop -->
                     <!-- .deferral class is needed in order to drag this image into the calendar -->
                     <img *ngIf="!visit.isScheduled && !visit.callpointIsDisabled" name="draggableImage"
                                                     [id]="visit.id"
                                                     src='assets/icons/Move.png'
                                                     class="deferral-item"
                                                     style='width:14px;height:14px;'>
                  </td>
               </tr>
            </table>
         </ng-template>
      </ng-container>

      <ng-template *ngIf="col.field == 'referenceAndOrder'" let-visit="rowData" pTemplate="body">
         <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.callpointReference}}_{{visit.order}}</span>
      </ng-template> -->

      <ng-template *ngIf="col.field == 'callpointReference'" let-visit="rowData" pTemplate="body">
         <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.callpointReference}}</span>
      </ng-template>

      <ng-template *ngIf="col.field == 'callpointDescription'" let-visit="rowData" pTemplate="body">
         <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.callpointDescription}}</span>
      </ng-template>

      <ng-template *ngIf="col.field == 'frequency'" let-visit="rowData" pTemplate="body">
         <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.frequency}}</span>
      </ng-template>

      <ng-template *ngIf="col.field == 'order'" let-visit="rowData" pTemplate="body">
         <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.order}}</span>
      </ng-template>

      <ng-template *ngIf="col.field == 'scheduleWeek'" let-visit="rowData" pTemplate="body">
         <span *ngIf="visit.scheduleWeek > 0">{{visit.scheduleWeek}}</span>
      </ng-template>

      <ng-container *ngIf="col.field == 'dayOfWeek'" >
         <ng-template let-visit="rowData" pTemplate="body">
            <span [style.color]="visit.isScheduled === false ? '#e90000' : '#222222'">{{visit.dayOfWeek}}</span>
         </ng-template>
      </ng-container>

      <!-- <ng-template *ngIf="col.field == 'date'" let-visit="rowData" pTemplate="body">
         <span *ngIf="visit.isScheduled">{{visit.date | date:'dd/MM/yyyy'}}</span>
         <span *ngIf="!visit.isScheduled">-</span>
      </ng-template> -->
      <ng-template *ngIf="col.field == 'shortDate'" let-visit="rowData" pTemplate="body">
         <span *ngIf="visit.isScheduled">{{visit.shortDate}}</span>
      </ng-template>

      <ng-template *ngIf="col.field == 'sequenceInDay'" let-visit="rowData" pTemplate="body">
         <span *ngIf="visit.sequenceInDay > 0">{{visit.sequenceInDay}}</span>

      </ng-template>

   </p-column>
</p-dataTable>
<div id="draggingMessage" style="font-size: 12px; font-weight: 600;position:absolute;display:none; z-index:999; background-color:white;border: solid black 1px; padding:5px">
   Drag visit to schedule
</div>
