<p-dialog
   [header]="headerText"
   [(visible)]="display"
   modal="true"
   width="750"
   positionTop="50"
   [draggable]="false"
   [resizable]="false"
   (onHide)="onCancel()"
>
   <form #form="ngForm" class="form-horizontal" autocomplete="off">
      <perfect-scrollbar [ngStyle]="{ 'height.px': scrollHeight }">
         <div>
            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Name</h6>
                  <div class="form-group">
                     <div class="col-sm-7">
                        <input
                           type="name"
                           id="name"
                           #name="ngModel"
                           name="name"
                           required
                           [ngModel]="company?.companyName"
                           class="form-control cs-form-control"
                        />
                     </div>
                  </div>

                  <div class="alert alert-danger fade in" *ngIf="showNameError">
                     Please enter a company name.
                  </div>
               </div>
            </div>
            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Licensing details</h6>
                  <div class="form-group">
                     <div class="col-sm-5">
                        <p-calendar
                           inputStyleClass="dialog-caledar-width"
                           class="calendar-height"
                           [showIcon]="true"
                           dateFormat="dd/mm/yy"
                           utc="true"
                           [(ngModel)]="company.licenseExpiryDate"
                           [locale]="en"
                           placeholder="dd/mm/yy"
                           selectOtherMonths="true"
                           name="licenseExpiryDate"
                           required="true"
                           (onBlur)="onLicenseDateSelected()"
                           (onSelect)="onLicenseDateSelected()"
                        >
                        </p-calendar>
                        <div
                           class="alert alert-danger fade in"
                           *ngIf="showLicenseError"
                        >
                           Date must be in the future.
                        </div>
                     </div>
                  </div>
                  <div class="form-group">
                     <div class="col-sm-5">
                        <p-checkbox
                           name="trialLicense"
                           id="trialLicense"
                           #trialLicense="ngModel"
                           [ngModel]="company.trialLicense"
                           binary="true"
                           label="Trial license"
                        ></p-checkbox>
                     </div>
                  </div>
                  <div class="form-group">
                     <label
                        for="name"
                        class="col-sm-2 control-label"
                        style="text-align: left;"
                        >Caller limit</label
                     >
                     <div class="col-sm-5">
                        <input
                           type="number"
                           id="trialLicenseCallerLimit"
                           #trialLicenseCallerLimit="ngModel"
                           name="trialLicenseCallerLimit"
                           [ngModel]="company?.trialLicenseCallerLimit"
                           class="form-control cs-form-control"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Number of Concurrent users</h6>
                  <div class="form-group">
                     <div class="col-sm-1">
                        <input
                           type="number"
                           id="concurrentUsers"
                           #concurrentUsers="ngModel"
                           name="concurrentUsers"
                           required
                           [ngModel]="company?.concurrentUsers"
                           class="form-control cs-form-control"
                           onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Travel Models</h6>
                  <p>Add travel models for this company</p>
                  <div class="row">
                     <callsmart-dropdown
                        name="travelModelToAdd"
                        [options]="travelModelComboList"
                        [(ngModel)]="selectedTravelModelToAdd"
                        class="col-sm-7"
                     >
                     </callsmart-dropdown>

                     <input
                        type="number"
                        id="travelModelUsers"
                        name="travelModelUsers"
                        [(ngModel)]="travelModelUsers"
                        class="form-control col-sm-3"
                        placeholder="No. Users"
                        style="width:25%; height:28px; padding-right:5px"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     />

                     <button
                        type="button"
                        class="btn cs-btn-primary-inverse col-sm-2 pull-right"
                        [disabled]="travelModelUsers <= 0"
                        (click)="onAddTravelModel()"
                     >
                        Add
                     </button>
                  </div>
                  <br />
                  <br />
                  <p>Configured travel models</p>
                  <p-dataTable
                     class="black-datatable no-horizontal-scrollbar"
                     [value]="company.travelModels"
                     [resizableColumns]="false"
                     [reorderableColumns]="false"
                     selectionMode="single"
                     [(selection)]="selectedCompanyTravelModels"
                     [scrollHeight]="scrollHeight"
                     [scrollable]="true"
                  >
                     <p-column
                        field="travelModelName"
                        header="Name"
                        [style]="{ width: '15%', overflow: 'visible' }"
                     >
                     </p-column>

                     <p-column
                        field="geocodeData"
                        header="Geo code"
                        [style]="{ width: '10%', overflow: 'visible' }"
                     >
                     </p-column>

                     <p-column
                        field="concurrentUsers"
                        header="Concurrent Users"
                        [style]="{ width: '10%', overflow: 'visible' }"
                     >
                     </p-column>
                     <p-column
                           header=""
                           [sortable]="false"
                           [style]="{ width: '5%', overflow: 'visible' }">
                        <ng-template pTemplate type="body" let-row="rowData">
                           <i
                              class="cs-icon icon-edit"
                              style="font-size: 15px"
                              (click)="onEditTravelModel(row)"
                           ></i>
                        </ng-template>
                     </p-column>
                     <p-column
                        header=""
                        [sortable]="false"
                        [style]="{ width: '5%', overflow: 'visible' }"
                     >
                        <ng-template pTemplate type="body" let-row="rowData">
                           <i
                              class="cs-icon icon-trash"
                              style="font-size: 15px"
                              (click)="onDeleteTravelModel(row)"
                           ></i>
                           <!--<button type="button" class="btn cs-btn-secondary-inverse" (click)="onDeleteTravelModel(row)">Remove</button>-->
                        </ng-template>
                     </p-column>
                  </p-dataTable>
               </div>
            </div>
         </div>
      </perfect-scrollbar>
      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button
               type="button"
               class="btn cs-btn-primary-inverse pull-right"
               [disabled]="
                  !form.valid ||
                  company?.travelModels.length == 0 ||
                  form?.value.concurrentUsers == 0 ||
                  showLicenseError
               "
               (click)="onSave(form)"
            >
               Save
            </button>
            <button
               type="button"
               class="btn cs-btn-secondary-inverse pull-right"
               (click)="onCancel()"
               style="margin-right: 8px;"
            >
               Cancel
            </button>
         </div>
      </p-footer>
   </form>
</p-dialog>

<p-dialog header="Edit Travel Model" [(visible)]="showTravelModal" modal="true" width="550" positionTop="200" [draggable]="false" [resizable]="false"
   (onHide)="onEditCancel()">
   <form #editForm="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">
      <div>
         <div class="cs-setting-panel">
            <div class="panel-body">
               <h6>Name</h6>
               <div class="form-group">
                  <div class="col-sm-11">
                     <input type="text" name="name" readonly [ngModel]="selectedTravelModelToEdit?.travelModelName" class="form-control cs-form-control">
                  </div>
               </div>
            </div>
            <div class="panel-body">
               <h6>Number of Concurrent users</h6>
               <div class="form-group">
                  <div class="col-sm-6">
                     <input type="number" name="concurrentUsers"
                     required [ngModel]="selectedTravelModelToEdit?.concurrentUsers" 
                     class="form-control cs-form-control"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                     <small *ngIf="travelModalHasError">{{travelModalError}}</small>
                  </div>
               </div>
            </div> 
         </div>
      </div>
      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!editForm.valid"  (click)="onEditSave(editForm)">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onEditCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>
   </form>
</p-dialog>