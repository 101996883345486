<p-dialog 
   id="editCallpointSettingsDialog"
   header="{{callpointHeading}} settings" 
   [(visible)]="display" 
   modal="true" 
   width="1000" 
   positionTop="15"
   [draggable]="false" 
   [resizable]="false"
   (onHide)="onCancel()">

<form #form="ngForm" class="form-horizontal" autocomplete="off">
   <p *ngIf="editingMultipleCallpoints">Changes will be made to the settings you select only. Unchecked settings will not be edited.</p>

   <edit-callpoint-settings name="editCallpointSettings"
      [(ngModel)]="callpointSettingsModel"
      [scrollHeight]="scrollHeight"
      [showOverrideButtons]="true"
      [displayCallpointSpecificSettings]="true"      
      [allPossibleDayCombinations]="allPossibleDayCombinations"
      [recommendedDayCombinations]="recommendedDayCombinations"
      [projectActiveWorkingDays]="projectActiveWorkingDays"
      [editingMultipleCallpoints]="editingMultipleCallpoints"
      [projectCycleLength]="projectCycleLength"      
      #editCallpointSettings>
   </edit-callpoint-settings>

   <p-footer>
      <div style="padding-top: 10px; margin-bottom: 30px;">
         <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!formValid || !showSaveButton()" (click)="onSave(form)">{{editingMultipleCallpoints ? 'Save All' : 'Save'}}</button>
         <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" 
            style="margin-right: 8px;">Cancel</button>
      </div>
   </p-footer>

</form>
</p-dialog>
