<p-dialog [header]="title" [width]="scrollWidth" [(visible)]="display" modal="modal" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">
   <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
      <div class="panel" style="border:none;">
         <div style="margin-top: 10px;">

            <div class="row">
               <div class="form-group row">
                  <div class="regular-text col-sm-12" style="padding-left: 0px">
                     <span style="white-space: pre-line;">{{bodyText}}</span>                     
                  </div>
               </div>
            </div>           
            <div class="row">
               <div class="form-group row">
                  <div class="col-sm-12" style="padding-left: 0px">                    
                     <ul style="width:100%">
                        <li *ngFor="let error of validationErrors">
                           <span class="regular-text">
                              {{error}}
                           </span>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </perfect-scrollbar>
   <div class="panel" style="margin-top:20px; margin-bottom:0px" *ngIf="bottomLink!=''">
      <div class="row">
         <div class="col-sm-12" style="padding-left: 0px">
            <a class="white-link-underline regular-text  pull-left" href="/data-templates/DataTemplates.xlsx" download="DataTemplates.xlsx">
               Download template
            </a>
         </div>
      </div>
   </div>
   <p-footer>
      <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onCancel()" style="margin-bottom: 20px; margin-right: 20px; min-width: 100px;">Ok</button>
   </p-footer>
</p-dialog>
