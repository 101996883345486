import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { JsonConvert } from 'json2typescript';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { Project } from 'app/models/project';
import { EditProjectCallpointSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-callpoint-settings-dialog/edit-project-callpoint-settings-dialog.component';
import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { DayCombination } from 'app/models/settings/day-combination';
import { ErrorDialogComponent } from 'app/shared/error-dialog/error-dialog.component';

/**
 * Callpoint settings component for the settings shown in the Create New wizard.
 * Also allows the settings to be edited.
 */
@Component({

   selector: 'callsmart-new-project-callpoint-settings',
   templateUrl: 'new-project-callpoint-settings.component.html'
})
export class NewProjectCallpointSettingsComponent implements OnInit, OnDestroy {

   // Subscribe to the project created event.
   private _project_created_subscription: Subscription;
   // Subscription for the day combinations.
   private _dayCombinations_subscription: Subscription;
   // Subscription for the recommended day combinations.
   private _recommendedDayCombinations_subscription: Subscription;
   // The new project that needs to be updated with these settings.
   private _project: Project;

   // Height of the component used by the scrollbar.
   @Input() componentHeight: number;

   // Caller settings model object.
   public callpointSettings: CallpointSettings;
   public isAllowAllSelected: boolean = true;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallpointSettingsDialog = EditProjectCallpointSettingsDialogComponent;

   // Project available days
   public projectActiveWorkingDays: boolean[] = [];
   // All possible day combinations
   public allPossibleDayCombinations: DayCombination[] = [];
   // Recommended combinations (string value) //TODO turn into a normal public array (no GET/SET )
   public recommendedDayCombinations: string[] = [];

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callpointSettingsModel: null,
      projectActiveWorkingDays: null,
      allPossibleDayCombinations: null,
      recommendedDayCombinations: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (settings: CallpointSettings) => this.onSaveEdit(settings),
      cancel: () => this.onCancelEdit()
   };

   // Determine whether to display the edit dialog.
   public showSettings: boolean = false;

   public ErrorDialog = ErrorDialogComponent; // Dialog to display validation errors.
   public showErrorDialog: boolean = false; // Sets the visibility of the error dialog component.

   // Properties for the error dialog dynamic loading
   public errorDialogInput = { display: false, title: '', bodyText: '',validationErrors:[]  };
   public errorDialogOutput = {
      cancel: () => this.onCancelAlertDialog()
   };

   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      this.subscribeToProjectCreated();
      this.subscribeToAllPossibleDayCombination();
      this.subscribeToRecommendedDayCombination();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }
      if (this._dayCombinations_subscription) {
         this._dayCombinations_subscription.unsubscribe();
      }
      if (this._recommendedDayCombinations_subscription) {
         this._recommendedDayCombinations_subscription.unsubscribe();
      }
   }

   // Clone the callpoint settings for the dialog and show it.
   public editSettings() {
      //It's necessary to carry out the deep clone
      let callpointSettingsAux = JSON.parse(JSON.stringify(this.callpointSettings))
      callpointSettingsAux.dayCombinations = JSON.stringify(callpointSettingsAux.dayCombinations)

      let settings = CallsmartUtils.deepClone<CallpointSettings>(callpointSettingsAux, CallpointSettings);

      this.dialogInput.callpointSettingsModel = settings;

      this.dialogInput.projectActiveWorkingDays = this.projectActiveWorkingDays;
      this.dialogInput.allPossibleDayCombinations = this.allPossibleDayCombinations;
      this.dialogInput.recommendedDayCombinations = this.recommendedDayCombinations;

      this.showSettings = true;
      this.dialogInput.display = true;
   }

   // Set the callpoint setting dialog to be hidden and assign the changed callpoint settings
   // to the callpoint settings model and save to backend.
   public onSaveEdit(savedSettings: CallpointSettings): void {
      if (savedSettings.dayCombinations) {
         let numberOfWorkingDays = this.projectActiveWorkingDays.filter(item => item).length;
         let emptySelectionDayCombinationGroups: number[]
         if (numberOfWorkingDays > 1) {
            emptySelectionDayCombinationGroups =
               CallsmartUtils.getEmptyDayCombinationGroups(numberOfWorkingDays, savedSettings.dayCombinations)            
         }         
         // Every DayCombination group has, at least, one selected item
         if (emptySelectionDayCombinationGroups.length === 0) {
            this.showSettings = false;
            this.dialogInput.display = false;
            this.callpointSettings = savedSettings;
            this._project.callpointSettings = savedSettings;
            this._applicationStore.projectsStore.updateCreateNewProjectCallpointSettings(this._project.projectId, savedSettings);
            this.setDisplayedDayCombinationValue();
         }
         else {
            this.showDayCombinationsError(emptySelectionDayCombinationGroups);
         }
      }      
   }

   // Set the callpoint setting dialog to be hidden.
   public onCancelEdit(): void {
      this.showSettings = false;
      this.dialogInput.display = false;
   }

   // cancel button from the add\edit event dialog
   public onCancelAlertDialog() {
      this.showErrorDialog = false;
      this.errorDialogInput.display = false;
   }

   // Angular 4.x and IE 11 do not show the date in the correct format when using the DatePipe from Angular.
   // This has been fixed in Angular 5 but for now using moment library to do the formatting in code to get the
   // desired result.
   public getHoursAndMinutes(date: Date): string {
      return CallsmartUtils.formatDate(date, "HH:mm");
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this._project = project;
               if (project) {
                  // Gets the project callpoint settings
                  this.callpointSettings = project.callpointSettings;
                  this.setDisplayedDayCombinationValue();
                  // Gets the working active days from the project caller settings
                  if (project.callerSettings) {
                     this.projectActiveWorkingDays = project.callerSettings.workingDayActive;
                  }
               }
            }
         }
      );
   }

   private subscribeToAllPossibleDayCombination() {
      this._dayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.allDayCombinations$.subscribe(
         (dayCombinations: DayCombination[]) => {
            this.allPossibleDayCombinations = CallsmartUtils.deepClone<DayCombination[]>(dayCombinations, DayCombination);
            this.setDisplayedDayCombinationValue();
         });
   }

   private subscribeToRecommendedDayCombination() {
      this._recommendedDayCombinations_subscription = this._applicationStore.defaultDayCombinationsStore.recommendedCombinations$.subscribe(
         (recommendedCombinations: string[]) => {
            this.recommendedDayCombinations = recommendedCombinations;
         });
   }

   private setDisplayedDayCombinationValue() {
      if (this.callpointSettings && this.callpointSettings.dayCombinations) {
         this.isAllowAllSelected = this.allPossibleDayCombinations.length == this.callpointSettings.dayCombinations.length;
      }
   }

   // Displays the alert dialog with the dayCombinations error.
   private showDayCombinationsError(emptySelectionDayCombinationGroups: number[]) {
      this.showErrorDialog = true;
      this.errorDialogInput.title = 'Project properties - Callpoints';
      this.errorDialogInput.bodyText = "The following groups do not have any selected day combination:"; 
      let validationErrors: string[] = [];
      if (emptySelectionDayCombinationGroups && emptySelectionDayCombinationGroups.length > 0) {        
         emptySelectionDayCombinationGroups.forEach(groupNumber => {
            validationErrors.push(groupNumber + ' visits per week');            
         })
         
      }
      this.errorDialogInput.validationErrors = validationErrors;      
      this.errorDialogInput.display = true;
   }
}
