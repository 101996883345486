import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { DayCombination } from 'app/models/settings/day-combination';

/**
 * Dialog component to show the Callpoint settings and allow the user to edit them.
 */
@Component({
   selector: 'edit-project-callpoint-settings-dialog',
   templateUrl: 'edit-project-callpoint-settings-dialog.component.html'
})
export class EditProjectCallpointSettingsDialogComponent {

   @ViewChild('editCallpointSettings') editCallpointSettings;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Settings model used by this dialog.
   @Input() callpointSettingsModel: CallpointSettings;

   // Project available days
   @Input() public projectActiveWorkingDays: boolean[] = [];
   // All possible day combinations
   @Input() public allPossibleDayCombinations: DayCombination[] = [];
   // Recommended combinations (string value) //TODO turn into a normal public array (no GET/SET )
   @Input() public recommendedDayCombinations: string[] = [];

   @Input() public projectCycleLength:number;

   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<CallpointSettings>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   constructor(private _windowService: BrowserWindowService) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallpointSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header. 
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Called when the user clicks on the Save button.
   public onSave(form: NgForm) {
      this.display = false;
      this.saved.next(this.callpointSettingsModel);
   }
}
