import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ProjectSettings } from 'app/models/settings/project-settings';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';


/**
 * Dialog component to show the Project settings and allow the user to edit them.
 */
@Component({
   selector: 'edit-project-settings-dialog',
   templateUrl: 'edit-project-settings-dialog.component.html'
})
export class EditProjectSettingsDialogComponent {

   @ViewChild('editProjectSettings') editProjectSettings;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Settings model used by this dialog.
   @Input() projectSettingsModel: ProjectSettings;

   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<ProjectSettings>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   constructor(private _windowService: BrowserWindowService) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editProjectSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header. 
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Called when the user clicks on the Save button.
   public onSave(form: NgForm) {
      this.display = false;

      this.projectSettingsModel.underUtilisation = Math.round(form.value.editProjectSettings.utilisationDefinition[0]);
      this.projectSettingsModel.overUtilisation = Math.round(form.value.editProjectSettings.utilisationDefinition[1]);

      this.saved.next(this.projectSettingsModel);
   }
}
