import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { SettingsService } from 'app/services/settings.service';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { ErrorHandlerService } from 'app/services/error-handler.service';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class UserProjectSettingsStore {

   //Default project settings
   private _defaultProjectSettings: BehaviorSubject<ProjectSettings> = new BehaviorSubject<ProjectSettings>(null);
   public defaultProjectSettings$: Observable<ProjectSettings> = this._defaultProjectSettings.asObservable();

   constructor(private _settingsService: SettingsService,
      private _errorHandler: ErrorHandlerService) { }


   public get userProjectSettings() {
      return this._defaultProjectSettings.getValue();
   }

   public loadUserProjectSettings() {
      this._settingsService.getUserProjectSettings()
         .subscribe(
            (settings: ProjectSettings) => {
               this._defaultProjectSettings.next(settings);               
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );

   }

   public updateUserProjectSettings(projectSettings) {
      this._settingsService.updateUserProjectSettings(projectSettings)
         .subscribe((res: boolean) => {
            if (res) {
               //succeful
               //refresh the settings
               this.loadUserProjectSettings();
            }
         },
            (error) => this._errorHandler.handleError(error));
   }
}
