<p-dialog [header]="title" [width]="scrollWidth" [(visible)]="display" modal="modal" [draggable]="false"
   [resizable]="false" (onHide)="onCancel()">
<perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
   <div class="panel" style="border:none;">
      <div style="margin-top:10px;">

         <div class="row">
            <div class="form-group row">
               <div class="small-text col-sm-12" style="padding-left:10px; white-space:pre-wrap">{{bodyText}}</div>
            </div>
         </div>
      </div>
   </div>
</perfect-scrollbar>

<p-footer>
   <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onCancel()" style="margin-bottom:20px;
      margin-right:20px; min-width:100px;">Ok</button>
</p-footer>
</p-dialog>
