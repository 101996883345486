import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { ApplicationStore } from 'app/stores/application-store';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'callsmart-expired-password',
  templateUrl: './expired-password.component.html'
})
export class ExpiredPasswordComponent implements OnInit, OnDestroy {

  private _user_subscription: Subscription;
  private _loginError_subscription: Subscription;

  public email: string;
  public oldPassword: string;

  public errorMessage: string = "";
  public showError: boolean = false;
  public passwordStrength: any;
  public passwordStrengthText: string = "Password Strength";
  public elementColor: string = "white";

  private strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})");
  private mediumRegex = new RegExp("^(((?=.*[a-zA-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{10,})");

  constructor(private router: Router, private _applicationStore: ApplicationStore,) {  }

  ngOnInit() {
    this.subscribeToUser();
    this.subscribeToExpiredPasswordError();
  }

  ngOnDestroy(): void {
    if (this._user_subscription) {
      this._loginError_subscription.unsubscribe();
   }

   if (this._loginError_subscription) {
      this._loginError_subscription.unsubscribe();
   }
  }

  public onSignin(form: NgForm) {
    this.showError = false;
    this.errorMessage = '';

    if (this.validation(form)) {
      this._applicationStore.authenticationStore.expiredLogin(form.value.email, form.value.oldPassword, form.value.newPassword);
    } else {
      this.showError = true;
    }
  }

  public analyze(pasword) {
    if (this.strongRegex.test(pasword)) {
      this.elementColor = "green";
      this.passwordStrengthText = "Strong";
    } else if (this.mediumRegex.test(pasword)) {
      this.elementColor = "orange";
      this.passwordStrengthText = "Medium";
    } else {
      this.elementColor = "red";
      this.passwordStrengthText = "Weak";
    }
  }

  private validation(form: NgForm) {

    if (!this.strongRegex.test(form.value.newPassword)) {
      this.errorMessage = "New password is not a strong password"
      return false;
    }

    if (form.value.newPassword != form.value.newPassword2) {
      this.errorMessage = "The Passwords you entered do not match"
      return false;
    }

    if (form.value.oldPassword == form.value.newPassword2) {
      this.errorMessage = "The Current password matches your new password."
      return false;
    }

    return form.valid;
  }

  private subscribeToExpiredPasswordError() {
    this._loginError_subscription = this._applicationStore.authenticationStore.loginError$.subscribe(
      (error: string) => {
        if (error) {
          this.showError = true;
          this.errorMessage = error;
        }
      }
    );
  }

  // this sets up the subscription to listen to the user when the user changes or is logged in
  private subscribeToUser() {
    this._user_subscription = this._applicationStore.authenticationStore.user$.subscribe(
      (user: User) => {
        if (user) {
          if (user.userId > 0) {
            this.navigateToLandingPage();
          }
        }
      }
    );
  }

  private navigateToLandingPage() {
    if (environment.loadFirstProject) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['open-project']);
    }
  }
}
