<p-dialog 
   id="editCallerSettingsDialog"
   header="{{callerName}} settings"
   [(visible)]="display"
   modal="true"
   width="1000"
   positionTop="15"
   [draggable]="false"
   [resizable]="false"
   (onHide)="onCancel()">


   <form #form="ngForm" class="form-horizontal" autocomplete="off">
      <p *ngIf="editingMultipleCallers">Changes will be made to the settings you select only. Unchecked settings will not be edited.</p>

      <edit-caller-settings name="editCallerSettings"
         [(ngModel)]="callerSettingsModel"
         [displayCallerSpecificSettings]="true"
         [scrollHeight]="scrollHeight"
         [showOverrideButtons]="true"
         [editingMultipleCallers]="editingMultipleCallers"
         [projectCycleLength] = "projectCycleLength"
         #editCallerSettings>
      </edit-caller-settings>

      <p-footer>
         <div style="padding-top: 10px; margin-bottom: 30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!formValid || !showSaveButton()" (click)="onSave(form)">{{editingMultipleCallers ? 'Save All' : 'Save'}}</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()"
               style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
