import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { SelectItem } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/primeng';

import { Caller } from 'app/models/caller';
import { Event } from 'app/models/diary/event';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { EventTypes } from 'app/models/diary/event-types';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

@Component({
   selector: 'callsmart-project-calendar-dialog',
   templateUrl: './project-calendar-dialog.component.html'
})
export class ProjectCalendarDialogComponent implements OnInit, OnDestroy, AfterViewInit {

   @Input() events: Event[]; // the list of events that the calendar that is used with this popup is bound
   @Input() eventToEdit: Event; // the event to edit
   private eventToEditRevert: Event;
   private _eventDialogMode: DialogMode;
   @Input()
   get eventDialogMode(): DialogMode {
      return this._eventDialogMode;
   }

   set eventDialogMode(mode: DialogMode) {
      this._eventDialogMode = mode;
      this.showDelete = mode == DialogMode.edit;
   }

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Gets and sets the number of weeks to be displayed in the calendar
   private _isAllDayEvent: boolean;
   @Input()
   get isAllDayEvent(): boolean {
      return this._isAllDayEvent;
   }
   set isAllDayEvent(isAllDayEvent: boolean) {
      this._isAllDayEvent = isAllDayEvent;
      // 'isAllDay' property is used instead of 'allDay' property because
      // 'allDay' makes the calendar component to hide an event.
      // 'allDay' events have a special treatment by the
      // third party Calendar control which cannot be modified.
      this.calendarEvent.isAllDay = this._isAllDayEvent;
      if (this.startDate && this._isAllDayEvent) {
         // Start and end times are the settings working hours limits.
         this.startTimeStr = this.getWorkingTime(true);
         //this.startTimeStr = '08:00';
         this.endTimeStr = this.getWorkingTime(false);
         // startDate and endDate are the same.
         this.endDate = this.startDate;
      }
   }

   // Gets and sets the start date
   private _startDate: Date;
   @Input()
   get startDate(): Date {
      return this._startDate;
   }
   set startDate(startDate: Date) {
      this._startDate = startDate;
      if (this._startDate) {
         this.endDate = this._startDate;
      }
   }

   // Gets and sets the start time as a string checking its format (hh:mm)
   private _startTimeStr: string;
   get startTimeStr(): string {
      return this._startTimeStr;
   }
   set startTimeStr(startTimeStr: string) {
      let regexp = new RegExp('([0-1]?[0-9]|2[0-3]):[0-5][0-9]');
      if (regexp.test(startTimeStr)) {
         this._startTimeStr = startTimeStr;
      }
   }

   // Gets and sets the end time as a string checking its format (hh:mm)
   private _endTimeStr: string;
   get endTimeStr(): string {
      return this._endTimeStr;
   }
   set endTimeStr(endTimeStr: string) {
      let regexp = new RegExp('([0-1]?[0-9]|2[0-3]):[0-5][0-9]');
      if (regexp.test(endTimeStr)) {
         this._endTimeStr = endTimeStr;
      }
   }

   // the dates need to be split into date and time
   @Input() public startCycleDate: Date;
   @Input() public endCycleDate: Date;
   @Input() public projectCallers: ReadonlyArray<Caller>;
   @Input() public callerSettings: CallerSettings

   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<Date>();
   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();
   // event to delete when delete clicked
   @Output() deleted = new EventEmitter<Event>();

   @ViewChild('form') form: NgForm; // Reference to the settings form

   public showDelete = false;
   public endDate: Date;
   public headerText = 'Add event';
   public calendarEvent: Event = null;

   // Flags to display error when submitting the form.
   public isDateTimeRangeValid: boolean = true;
   public isDateInCycleValid: boolean = true;
   public isCallerSelectionEmpty: boolean = false;

   public scrollHeight: number;
   public en: any;

   // Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];

   public hasFilters: boolean;
   public cols: any[];
   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedCallers: Caller[] = [];
   public gridScrollHeight: string;
   public action: string;

   // Checks whether the form is valid.
   public get formValid(): boolean {
      return this.form.valid;
   }

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService, private _confirmationService: ConfirmationService) {

      // subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 130;
         this.gridScrollHeight = (this.scrollHeight * 0.40) + 'px';
      });
   }

   ngOnInit() {
      // This property customises all calendar names and formats to be displayed according to the design.
      this.en = {
         firstDayOfWeek: 1,
         dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
         dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
         dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
         monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
         monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
         today: 'Today',
         clear: 'Clear'
      };

      this.configureTableColumns();
      this.initialisedStartEndDateTime();
   }

   ngAfterViewInit() {
      // autocomplete property doesn't work property within the Calendar picker.
      // The best workaround is deactive it for both controls.
      let startDateCalendar: HTMLElement = <HTMLElement>document.getElementsByName('eventStartDate')[0];
      let startDateInputText: HTMLInputElement = <HTMLInputElement>startDateCalendar.getElementsByTagName('Input')[0];
      startDateInputText.autocomplete = 'off';

      // let endDateCalendar: HTMLElement = <HTMLElement>document.getElementsByName('eventEndDate')[0];
      // let endDateInputText: HTMLInputElement = <HTMLInputElement>endDateCalendar.getElementsByTagName('Input')[0];
      // endDateInputText.autocomplete = 'off';
   }

   public ngOnDestroy(): void {
   }

   public onSave() {
      this.action = 'save';

      let formattedDate = CallsmartUtils.formatDate(this.calendarEvent.start, 'DD/MM/YYYY');
      let anyVisitOnTheDay = this._applicationStore.visitsStore.visits.find(v => v.shortDate === formattedDate);

      if(anyVisitOnTheDay)
      {
         // There are visits on the day so warn the user first before adding the event.
         this._confirmationService.confirm({
            message: 'Adding this event will cause all visits already scheduled on this day to be deferred. Do you want to continue?',
            accept: () => {
               this.saveEventAndNotify();
            },
            reject: () => {
               // The user doen not want to proceed so don't add the event.
            }
         });
      }
      else {
         // There are no events on the day so just add the event.
         this.saveEventAndNotify();
      }

   }

   public onDelete() {
      this.action = 'delete';

      this._confirmationService.confirm({
         message: 'Are you sure you want to delete this event?',
         header: 'Confirmation',
         icon: 'fa fa-question-circle',
         accept: () => {
            this.display = false;
            this.deleted.next(this.eventToEdit);
         },
         reject: () => {
            this.display = true;
         }
      });
   }

   public onCancel() {
      this.action = 'cancel';
      this.display = false;
      if (this._eventDialogMode == DialogMode.edit && this.eventToEditRevert) {
         this.eventToEdit.title = this.eventToEditRevert.title;
         this.eventToEdit.attendeesIds = this.eventToEditRevert.attendeesIds;
         this.eventToEdit.isAllDay  = this.eventToEditRevert.isAllDay;
         this.eventToEdit.start  = this.eventToEditRevert.start;
         this.eventToEdit.end  = this.eventToEditRevert.end;
      }

      this.cancel.next();
   }

   public submitForm(form: NgForm) {

      if (this.action == undefined) {
         this.action = 'save';
      }

      this.isDateTimeRangeValid = true;
      this.isCallerSelectionEmpty = false;
      this.isDateInCycleValid = true;

      let startDateTime: Date = new Date(this.startDate.toDateString() + ' ' + this.startTimeStr);
      let endDateTime: Date;

      if (this.isAllDayEvent) {
         // Same start and end date
         endDateTime = new Date(this.startDate.toDateString() + ' ' + this.endTimeStr);
      }
      else {
         endDateTime = new Date(this.endDate.toDateString() + ' ' + this.endTimeStr);
      }
      let isStartDateInCycle = startDateTime >= this.startCycleDate && startDateTime <= this.endCycleDate;
      let isEndDateInCycle = endDateTime >= this.startCycleDate && endDateTime <= this.endCycleDate;
      if (!isStartDateInCycle || !isEndDateInCycle) {
         this.isDateInCycleValid = false;
         return;
      }
      if (startDateTime >= endDateTime) {
         this.isDateTimeRangeValid = false;
      }
      if (this.selectedCallers.length === 0) {
         this.isCallerSelectionEmpty = true;
      }
      if (this.isCallerSelectionEmpty || !this.isDateTimeRangeValid) {
         return;
      }
      let callerIds: number[] = [];
      // console.log('Posted data: ', JSON.stringify(form.value));
      this.calendarEvent.title = form.value.eventName;
      this.calendarEvent.start = startDateTime;
      this.calendarEvent.end = endDateTime;
      this.calendarEvent.canRoll = !form.value.canRollSwitch; // not us used here and the switch binding as product owner want ed the switch reversed
      this.selectedCallers.forEach(c => {
         callerIds.push(c.callerId);
      });

      this.calendarEvent.attendeesIds = callerIds;
      this.calendarEvent.description = (this.calendarEvent.attendeesIds.length > 0) ? 'x ' + this.calendarEvent.attendeesIds.length : '';

      // all the buttons trigger a form submit save, cancel and delete
      if (this.action == 'save') {
         this.onSave();
      }
   }

   private saveEventAndNotify() {
      if (this.eventDialogMode == DialogMode.add) {
         this.events.push(this.calendarEvent)
      }

      this.display = false;
      this.saved.next(this.calendarEvent.start);
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      this.cols = [
         { field: 'territory', header: 'Code', disabled: true, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'name', header: 'Name', disabled: false, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false }

      ]

      this.allCols = [
         { field: 'territory', header: 'Code', disabled: true, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
         { field: 'name', header: 'Name', disabled: false, filter: false, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false }

      ];


      this.columnOptions = [];

      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({ label: this.allCols[i].header, value: this.allCols[i] });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems)
      }
   }

   public onStartDateChanged() {
      if (this.isAllDayEvent) {
         this.endDate = this.startDate
      }
   }

   private initialisedStartEndDateTime() {
      switch (this.eventDialogMode) {
         case DialogMode.add: {
            // set the new event to have the start date for the call cycle
            this.startDate = this.startCycleDate;
            this.endDate = this.startCycleDate;

            this.calendarEvent = new Event();
            this.calendarEvent.description = '';
            this.calendarEvent.start = this.startCycleDate;
            this.calendarEvent.end = this.startCycleDate;
            this.calendarEvent.eventType = EventTypes.project;
            this.calendarEvent.id = this._applicationStore.generateGUID();
            this.headerText = 'Add Event';
            this.calendarEvent.isAllDay = true;
            this.isAllDayEvent = this.calendarEvent.isAllDay;
            break;
         }
         case DialogMode.edit: {
            this.headerText = 'Edit Event';
            this.eventToEditRevert = Object.assign({}, this.eventToEdit);
            this.calendarEvent = this.eventToEdit;
            this.startDate = this.calendarEvent.start;
            this.endDate = this.calendarEvent.end;
            this.startTimeStr = ('0' + this.calendarEvent.start.getHours()).slice(-2) + ':' +
               ('0' + this.calendarEvent.start.getMinutes()).slice(-2);
            this.endTimeStr = ('0' + this.calendarEvent.end.getHours()).slice(-2) + ':' +
               ('0' + this.calendarEvent.end.getMinutes()).slice(-2);
            this.isAllDayEvent = this.calendarEvent.isAllDay;
            this.selectedCallers = [];
            this.calendarEvent.attendeesIds.forEach(id => {
               let caller: Caller = this.projectCallers.find(c => c.callerId == id);
               this.selectedCallers.push(caller);
            });
            break;
         }
         default: {
            this.headerText = 'Unknown cancel dialog';
            this.calendarEvent = new Event();
            this.calendarEvent.description = '';

            this.calendarEvent.start = new Date();
            this.calendarEvent.end = new Date();
            this.calendarEvent.isAllDay = false;

            this.startDate = this.calendarEvent.start;
            this.endDate = this.calendarEvent.end;

            this.startTimeStr = ('0' + this.calendarEvent.start.getHours()).slice(-2) + ':' +
               ('0' + this.calendarEvent.start.getMinutes()).slice(-2);
            this.endTimeStr = ('0' + this.calendarEvent.end.getHours()).slice(-2) + ':' +
               ('0' + this.calendarEvent.end.getMinutes()).slice(-2);

            this.isAllDayEvent = this.calendarEvent.isAllDay;
            this.calendarEvent.eventType = EventTypes.project;

            break;
         }
      }
   }

   // Gets the working time depending on the caller settings
   private getWorkingTime(checkStartTime: boolean): string {
      let workingTime: string = null;
      let hours: number;
      let minutes: number;

      // Checks whether working hours are the same for all working days
      if (this.callerSettings.sameWorkingHoursAllDays) {
         let weeklyWorkingTime: Date = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursWeek[0]) :
            new Date(this.callerSettings.contractedWorkingHoursWeek[1]);
         hours = weeklyWorkingTime.getHours();
         minutes = weeklyWorkingTime.getMinutes()
      }
      else {
         // Finds out which day of the week has been selected and
         // returns the start working time for that selected day
         switch (this.startDate.getDay()) {
            case 1:
               let mondayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursMonday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursMonday[1]);
               hours = mondayDate.getHours();
               minutes = mondayDate.getMinutes();
               break;
            case 2:
               let tuesdayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursTuesday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursTuesday[1]);
               hours = tuesdayDate.getHours();
               minutes = tuesdayDate.getMinutes();
               break;
            case 3:
               let wednesdayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursWednesday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursWednesday[1]);
               hours = wednesdayDate.getHours();
               minutes = wednesdayDate.getMinutes();
               break;
            case 4:
               let thursdayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursThursday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursThursday[1]);
               hours = thursdayDate.getHours();
               minutes = thursdayDate.getMinutes();
            case 5:
               let fridayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursFriday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursFriday[1]);
               hours = fridayDate.getHours();
               minutes = fridayDate.getMinutes();
            case 6:
               let saturdayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursSaturday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursSaturday[1]);
               hours = saturdayDate.getHours();
               minutes = saturdayDate.getMinutes();
               break;
            case 0:
               let sundayDate = checkStartTime ? new Date(this.callerSettings.contractedWorkingHoursSunday[0]) :
                  new Date(this.callerSettings.contractedWorkingHoursSunday[1]);
               hours = sundayDate.getHours();
               minutes = sundayDate.getMinutes();
               break;
            default:
               break;
         }
      }
      workingTime = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes);
      return workingTime;
   }
}
