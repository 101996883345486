import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { Visit } from 'app/models/visit';
import { Caller } from 'app/models/caller';
import { Event } from 'app/models/diary/event';
import { BrowserWindowService } from 'app/services/browser-window.service';

import { CalendarComponent } from 'app/shared/calendar/calendar.component';
import { ApplicationStore } from 'app/stores/application-store';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { Project } from 'app/models/project';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { EventTypes } from 'app/models/diary/event-types';
import { VisitSelection } from 'app/models/visit-selection';
import { Callpoint } from 'app/models/callpoint';
import { VisitsListV2Component } from 'app/visits-workspace/visits-list-v2/visits-list-v2.component';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceNavigationLogic } from 'app/shared/optimisation-logic/workspace-navigation-logic';

@Component({
   selector: 'callsmart-visits-workspace',
   templateUrl: './visits-workspace.component.html'
})
export class VisitsWorkspaceComponent implements OnInit, OnDestroy, AfterViewInit {

   @ViewChild('calendar') calendar;

   @ViewChild('box1') splitterComponent;
   @ViewChild('visitList') visitListPane: VisitsListV2Component;

   private _diaryEventsSubscription: Subscription;
   private _selectedCallerSubscription: Subscription;
   private _selectedProjectSubscription: Subscription;
   private _visitRemovedSubscription: Subscription;
   private _visitAddedSubscription: Subscription;
   private _visitLockedSubscription: Subscription;
   private _selectedVisitSubscription: Subscription;
   private _callpointVisitsLockedSubscription: Subscription;
   private _visitMovedFailedSubscription: Subscription;
   private _callerClosedDatesSubscription: Subscription;
   private _selectedDiaryDayAfterSwap_subscription: Subscription;
   private _scheduleGenerationCompletedSubscription: Subscription;

   private projectSettings: ProjectSettings;
   private selectedCallerSettings: CallerSettings = null;
   private callerProjectSettings: CallerSettings = null;
   private callerSettingsVm: CallerSettingsViewModel;

   private horizontalSeparator = null;
   private resizing: boolean = false; // Needed when resizing the workspace to workaround a bug when releasing the splitter.
   private selectingVisitFromList: boolean = false;

   public selectedContractedWorkingTime: Date[];
   public diaryEvents: Event[] = [];
   public caller: Caller;
   public scheduleCycleLength: number;
   public startCycleDate: Date;
   public startWorkingTime: string;
   public endWorkingTime: string;
   public activeWorkingDays: boolean[]; // Days which have to be eligible based on the caller settings
   public selectedVisit: VisitSelection;
   public selectedDate: Date;
   public eventCollection: Event[];
   public datesClosed: string[];
   public diaryViewTime: number = 60;

   public visitTableHeight: string;
   public visitPaneHeight: number;
   public workspaceHeight: number;
   public scrollWidth: string = "1200px"
   public pendingDaySelectionFromAction: boolean;

   constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService, private workspaceNavigationLogic : WorkspaceNavigationLogic) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
      //subscribe to the window resize event
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth = (value - 260) + "px"; // 260 is the contextual panel width
      });
   }

   ngOnInit() {
      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Visits);
      this.workspaceNavigationLogic.navigatingToWorkspace(WorkspaceViewType.Visits);

      this.horizontalSeparator = document.getElementsByTagName('horizontal-split-separator')[0];
      this.horizontalSeparator.onmousedown = (() => {
         this.resizing = true;
      });
      // The mouse can be released on any place inside the browser. That's the reason
      // to handle the 'mouseup' event of the document element
      document.onmouseup = (() => {
         if (this.resizing) {
            // Internal funtion belonging to the SplitterComponent itself.
            this.splitterComponent.stopResizing();
            this.resizing = false;
         }
      });

      this.subscribeToSelectedProjectSettings()
      this.subscribeToSelectedCaller();
      this.subscribeToDiaryEvents();
      this.subscribeToVisitRemoved();
      this.subscribeToVisitAdded();
      this.subscribeToSelectedVisit();
      this.subscribeToVisitLocked();
      this.subscribeToCallpointVisitsLocked();
      this.subscribeToVisitMovedFailed();
      this.subscribeToSelectedCallerClosedDates();
      this.subscribeToSelectedDiaryDayAfterSwapDay();
      this.subscribeToScheduleGenerationComplete();
   }

   ngOnDestroy() {

      this.workspaceNavigationLogic.navigatingAwayFromActiveWorkspace(this._applicationStore.uiStore.getActiveView());

      if (this._diaryEventsSubscription) {
         this._diaryEventsSubscription.unsubscribe();
      }

      if (this._selectedCallerSubscription) {
         this._selectedCallerSubscription.unsubscribe();
      }

      if (this._selectedProjectSubscription) {
         this._selectedProjectSubscription.unsubscribe();
      }
      if (this._visitRemovedSubscription) {
         this._visitRemovedSubscription.unsubscribe();
      }
      if (this._visitLockedSubscription) {
         this._visitLockedSubscription.unsubscribe();
      }
      if (this._selectedVisitSubscription) {
         this._selectedVisitSubscription.unsubscribe();
      }
      if (this._visitMovedFailedSubscription) {
         this._visitMovedFailedSubscription.unsubscribe();
      }
      if (this._callerClosedDatesSubscription) {
         this._callerClosedDatesSubscription.unsubscribe();
      }
      if (this._visitAddedSubscription) {
         this._visitAddedSubscription.unsubscribe();
      }
      if (this._callpointVisitsLockedSubscription) {
         this._callpointVisitsLockedSubscription.unsubscribe();
      }
      if (this._selectedDiaryDayAfterSwap_subscription) {
         this._selectedDiaryDayAfterSwap_subscription.unsubscribe();
      }
      if (this._scheduleGenerationCompletedSubscription) {
         this._scheduleGenerationCompletedSubscription.unsubscribe();
      }
   }

   public ngAfterViewInit() {
      setTimeout(() => {
         this.resizeListLayout();
      }, 600)
   }

   public onHChange(event, calendar: CalendarComponent) {

      this.visitPaneHeight = event.primary;
      // if(this.visitListPane){
      //    this.visitTableHeight = this.visitListPane.hasFilters ? (event.primary - 100) + "px" : (event.primary - 117) + "px"
      //    //this.visitTableHeight = (event.primary - 117) + "px";
      // }
      this.resizeListLayout();

      // set the caledar height
      calendar.calendarHeight = event.secondary - 33;
      calendar.aspectRatio = '';

      // Either user is resizing the mapping area using the splitter or
      // the splitter control is being loaded for the first time.
      calendar.setScrollWithFixHeight(calendar.calendarHeight - 30);
   }

   public resizeListLayout() {
      let firstClientHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
      // The Height of the scrollable area is worked out without the header table height (filter header) and
      // the pane header height (Where the 'Column' button is place)
      this.visitTableHeight = firstClientHeight - (this.visitListPane.headerHeight + this.visitListPane.tableHeaderHeight) + 1 + "px";
      this.visitPaneHeight = firstClientHeight;
   }

   public onVisitSelected(visitSelection: VisitSelection) {
      this.selectedVisit = (visitSelection && visitSelection.selectedVisit && visitSelection.selectedVisit.isSelected) ? visitSelection : null;
      if (this.selectedVisit) {
         this.selectedDate = this.selectedVisit.selectedVisit.start;

         // Gets the dayIndex (gets rid of time part of a date to make the diff method work properly)
         let selectedVisitDay: moment.Moment = moment(visitSelection.selectedVisit.start).startOf('day');
         let selectedVisitStartDate: moment.Moment = moment(visitSelection.selectedVisit.start);
         //let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');
         let dayIndex: number = this.getIndexDayInCycle(visitSelection.selectedVisit.start)

         // Gets the visitIndex.
         let visitList = this.diaryEvents.filter(visit => {
            let visitStartDate: moment.Moment = moment(visit.start).startOf('day');
            return visit.eventType == EventTypes.visit && selectedVisitDay.diff(visitStartDate, 'days') === 0
         })
         let visitIndex: number = visitList.findIndex(item => {
            let itemStartDate: moment.Moment = moment(item.start);
            return itemStartDate.diff(selectedVisitStartDate) === 0;
         });
         // Asigns the dayIndex and the visitIndex.
         this.selectedVisit.dayIndex = dayIndex;
         this.selectedVisit.eventIndex = visitIndex;
         // Caches the selected visit in the Schedule Store
         this._applicationStore.scheduleStore.setSelectedDiaryEvent(visitSelection.selectedVisit);

         /////////////Code to select that visit in the visit store
         let visits: ReadonlyArray<Visit> = this._applicationStore.visitsStore.visits;
         let visitFound: Visit = visits.find(item => {
            let itemDate: moment.Moment = moment(item.date);
            return itemDate.isSame(selectedVisitDay) && item.startTime == selectedVisitStartDate.format('HH:mm:ss')
         })
         if (visitFound && !this.selectingVisitFromList) {
            this.visitListPane.selectedVisits = [visitFound];
         }
         ///////////////////////////////////////////////////////////

      }
      else {
         this._applicationStore.scheduleStore.setSelectedDiaryEvent(null);
         this.visitListPane.selectedVisits = [];
      }
      // Notifies the Schedule_Store that a visit has been selected in the calendar
      // in order to be displayed on the contextual panel.
      // This object includes the following data: driveTime, phase, dayIndex, eventIndex and the selected diary Event
      this._applicationStore.scheduleStore.setSelectedVisit(this.selectedVisit);
   }

   public onDaySelected(event) {
      if (event.isSelected) {
         this.selectedDate = event.selectedDate;

         // store the selected date as state info in the store
         this._applicationStore.scheduleStore.setSelectedDiaryDay(this.selectedDate);

         this.eventCollection = event.events.slice();
         this._applicationStore.scheduleStore.setSelectedDayDiaryEvents(this.eventCollection);

         // this.selectedDate = event.isSelected ? event.selectedDate : null;
         // this.eventCollection = event.isSelected ? event.events.slice() : [];

         if (!this.selectedDate) {
            this.selectedContractedWorkingTime = null;
            return
         }
         if (this.callerSettingsVm.sameWorkingHoursAllDays) {
            this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursWeek;
         }
         else {
            let dayOfWeek: number = moment(this.selectedDate).day();
            switch (dayOfWeek) {
               case 0:
                  this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursSunday;
                  break;
               case 1:
                  this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursMonday;
                  break;
               case 2:
                  this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursTuesday;
                  break;
               case 3:
                  this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursWednesday;
                  break;
               case 4:
                  this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursThursday;
                  break;
               case 5:
                  this.selectedContractedWorkingTime = this.selectedCallerSettings.contractedWorkingHoursFriday;
                  break;
               case 6:
                  this.selectedContractedWorkingTime = this.selectedCallerSettings.contractedWorkingHoursSaturday;
                  break;
            }
         }
         this._applicationStore.scheduleStore.setSelectedContractedWorkingTime(this.selectedContractedWorkingTime);
      }
      else {
         if (!this.pendingDaySelectionFromAction) {
            this.eventCollection = [];
            this._applicationStore.scheduleStore.setSelectedDayDiaryEvents(this.eventCollection);

            this.selectedDate = null;

            this.selectedContractedWorkingTime = null
            this._applicationStore.scheduleStore.setSelectedContractedWorkingTime(this.selectedContractedWorkingTime);
         }

         // store the selected date as state info in the store
         this._applicationStore.scheduleStore.setSelectedDiaryDay(null);
      }
   }

   // Handle the height of the scrollable area after resizing a column (the number of lines
   // might change since the header text of a column is wrapped)
   public onListColumnHeaderChanged(event) {
      let headerHeight: number = event.headerHeight;
      let tableHeaderHeight: number = event.tableHeaderHeight;

      let firstClientHeight = this.splitterComponent.outerContainer.nativeElement.firstElementChild.clientHeight;
      this.visitTableHeight = firstClientHeight - (headerHeight + tableHeaderHeight) + 1 + "px";
      this.visitPaneHeight = firstClientHeight;
   }

   public onRowSelectionChanged(selectedVisits: Visit[]) {
      if (selectedVisits) {
         this._applicationStore.visitsStore.setSelectedVisits(selectedVisits);
         let selectedCallpoints: Callpoint[] = [];
         if (selectedVisits) {
            // Get all callpoints associated with selected visits
            let callpointIds: number[] = selectedVisits.map(visit => visit.callpointId);
            selectedCallpoints =
               this._applicationStore.callpointsStore.callpoints.filter(callpoint => callpointIds.includes(callpoint.callpointId));
         }
         this._applicationStore.callpointsStore.setSelectedCallpoints(selectedCallpoints);
         if (selectedVisits.length > 0) {
            // Gets the last selected visit on the grid to be display on the diary
            let lastSelectedVisit: Visit = selectedVisits[selectedVisits.length - 1];
            if (lastSelectedVisit.isScheduled) {
               this.selectedDate = lastSelectedVisit.date;
               let selectedVisitDateStr: string = moment(lastSelectedVisit.date.toISOString()).format("YYYY-MM-DD");
               let seletectVisitDateTime: moment.Moment = moment(selectedVisitDateStr + ' ' + lastSelectedVisit.startTime, "YYYY-MM-DD HH:mm:ss");
               // Gets the selected event from the schedule store based on the last visit selected on the visits grid
               let diaryVisit =
                  this._applicationStore.scheduleStore.currentCallerDiaryEvents
                     .find(event => event.eventType == EventTypes.visit &&
                        moment(event.start).isSame(seletectVisitDateTime));
               // The new diary visit is set as selected in the Schedule Store
               this._applicationStore.scheduleStore.setSelectedDiaryEvent(diaryVisit);
            }
            else {
               let referral: Event = new Event();
               referral.title = lastSelectedVisit.callpointDescription
               referral.callpointId = lastSelectedVisit.callpointReference;
               referral.isSelected = false;
               let visitSelection: VisitSelection = new VisitSelection();

               visitSelection.selectedVisit = referral;
               visitSelection.driveTime = 0;
               visitSelection.phase = 0;
               visitSelection.visitId = lastSelectedVisit.callpointReference + '_' + lastSelectedVisit.id;
               // This object includes the following data: driveTime, phase, dayIndex, eventIndex and the selected diary Event
               this._applicationStore.scheduleStore.setSelectedVisit(visitSelection);
               this.calendar.selectDay(this.calendar.selectedDate, false);
               this._applicationStore.scheduleStore.setSelectedDiaryEvent(null);
            }
         }
         else {
            this._applicationStore.scheduleStore.setSelectedVisit(null);
            //this.calendar.selectDay(this.selectedDate, true);
         }
      }
   }

   private syncSelectedVisitOnCalendar() {

      if (this._applicationStore.scheduleStore.selectedDiaryEvent) {
         this.calendar.selectVisit(this._applicationStore.scheduleStore.selectedDiaryEvent, true);
         return;
      }

      if (this._applicationStore.scheduleStore.selectedDiaryDay) {
         this.calendar.selectDay(this._applicationStore.scheduleStore.selectedDiaryDay, true);
      }

   }

   // Adjust the height of the map and data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 74.5 - 50;
   }

   private subscribeToSelectedCaller() {
      this._selectedCallerSubscription = this._applicationStore.callersStore.selectedCaller$.subscribe(
         (caller: Caller) => {
            if (caller) {
               let previousSelectedCaller = this.caller;
               this.caller = caller;
               this.selectedCallerSettings = caller.callerSettings;
               this.datesClosed = caller.datesClosed;

               if (this.callerProjectSettings) {
                  this.callerSettingsVm = new CallerSettingsViewModel(this.callerProjectSettings, this.selectedCallerSettings ? this.selectedCallerSettings : null, this.caller);
               }
               // Sets the start/end working time
               if (this.callerSettingsVm) {
                  if (this.callerSettingsVm.sameWorkingHoursAllDays) {
                     this.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(true, this.callerSettingsVm);
                     this.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(false, this.callerSettingsVm);
                  }
                  else {
                     // Deal with multiple hours per day. This is a bit of a cheat, take hours for each day and create a pipe
                     // delimited string that can be passed to the calendar component, the calendar component will then unpack this.
                     let startTime = '';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursMonday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursTuesday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursWednesday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursThursday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursFriday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursSaturday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursSunday);
                     this.startWorkingTime = startTime;

                     let endTime = '';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursMonday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursTuesday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursWednesday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursThursday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursFriday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursSaturday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursSunday);
                     this.endWorkingTime = endTime;

                  }
                  this.activeWorkingDays = this.callerSettingsVm.workingDayActive;
               }

               // When a different caller is selected in the carousel, the selected day selection needs to be kept, but when the new
               // diary of events is loaded the selection is lost, this code reselects the day again.
               if (this._applicationStore.scheduleStore.selectedDiaryDay) {

                  setTimeout(() => {
                     if (this._applicationStore.scheduleStore.selectedDiaryEvent) {
                        let selectedVisitsLength = this._applicationStore.visitsStore.selectedVisits
                           ? this._applicationStore.visitsStore.selectedVisits.length
                           : 0;
                        if (selectedVisitsLength > 0) {
                           // Finding the visit based on the selectedDiaryEvent from the Schedule Store.
                           // Both stores must be in sync regarding the selected visit
                           let selectedVisitInGrid: Visit = this._applicationStore.visitsStore.selectedVisits.
                              find(selectedGridVisit => {
                                 let selectedGridVisitDate = moment(selectedGridVisit.date);
                                 let visitStartTime = moment(selectedGridVisit.startTime, 'HH:mm:ss');
                                 selectedGridVisitDate = selectedGridVisitDate.hour(visitStartTime.get('hour'));
                                 selectedGridVisitDate = selectedGridVisitDate.minute(visitStartTime.get('minutes'));
                                 selectedGridVisitDate = selectedGridVisitDate.second(visitStartTime.get('seconds'));

                                 return moment(this._applicationStore.scheduleStore.selectedDiaryEvent.start).
                                    isSame(selectedGridVisitDate);
                              });

                           // Moving the selected visit from the Visits Store to the last position to became
                           // the selected visit in the calendar/diary too (only the last selected visit is hightlighted on the Diary)
                           if (selectedVisitInGrid) {
                              let selectedVisitsArray: Visit[] = this._applicationStore.visitsStore.selectedVisits.slice(0);
                              let index = selectedVisitsArray.indexOf(selectedVisitInGrid);
                              if (index > -1) {
                                 // Remove the visit which must be selected on the diary
                                 selectedVisitsArray.splice(index, 1);
                                 // Add the visit again in the last position (the diary will hightlight this item )
                                 selectedVisitsArray.push(selectedVisitInGrid);
                                 // Clear the current selection in the Schedule store.
                                 this._applicationStore.scheduleStore.setSelectedDiaryEvent(null);
                                 this.visitListPane.selectedVisits = selectedVisitsArray;
                              }
                           }

                        }
                     }
                     else{
                        this.calendar.reselectSameDay(this._applicationStore.scheduleStore.selectedDiaryDay,
                                                      this.caller != previousSelectedCaller ? true : false);
                     }
                  }, 100);

               }
            }
         });
   }

   private subscribeToDiaryEvents() {
      this._diaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (events: Event[]) => {
            this.diaryEvents = events;
            if (this._applicationStore.scheduleStore.selectedDiaryDayIndex === -1) {
               this.selectedDate = null;
            }
            else {
               this.pendingDaySelectionFromAction = true;
            }
         });
   }

   private subscribeToVisitRemoved() {
      this._visitRemovedSubscription = this._applicationStore.scheduleStore.visitRemoved.subscribe((date: Date) => {
         this.selectedDate = date;
         this.pendingDaySelectionFromAction = true;
         this._applicationStore.visitsStore.
            loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId, this.caller.callerId);
      });
   }

   private subscribeToVisitAdded() {
      this._visitAddedSubscription = this._applicationStore.scheduleStore.visitAdded.subscribe((date: Date) => {
         this.selectedDate = date;
         // A visit has been added... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionFromAction = true;
         // The visits list must be reloaded after adding a visit to the calendar
         this._applicationStore.visitsStore.
            loadVisitsForCaller(this._applicationStore.projectsStore.selectedProject.projectId, this.caller.callerId);
         this.calendar.selectDay(this.selectedDate, true);
      });
   }

   private subscribeToVisitLocked() {
      this._visitLockedSubscription = this._applicationStore.scheduleStore.visitLocked.subscribe((date: Date) => {
         this.selectedDate = date;
         this.pendingDaySelectionFromAction = true;
      });
   }

   private subscribeToCallpointVisitsLocked() {
      this._callpointVisitsLockedSubscription = this._applicationStore.scheduleStore.callpointVisitsLocked.subscribe(() => {
         // selected callpoint visits have been locked... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionFromAction = true;
      });
   }

   private subscribeToSelectedVisit() {
      this._selectedVisitSubscription = this._applicationStore.scheduleStore.selectedDiaryEvent$.subscribe((visit: Event) => {
         if (this.calendar) {
            if (visit) {
               this.selectingVisitFromList = true;
               this.calendar.selectVisit(visit, true);
               this.selectingVisitFromList = false;
            }
         }
      })
   }

   private subscribeToVisitMovedFailed() {
      this._visitMovedFailedSubscription = this._applicationStore.scheduleStore.visitMovedFailed.subscribe(() => {
         if (this.calendar) {
            this.calendar.draggingRevertFunction();
            this.calendar.draggingRevertFunction = null;
         }
      });
   }

   private subscribeToSelectedCallerClosedDates() {
      this._callerClosedDatesSubscription = this._applicationStore.callersStore._callerClosedDatesChanged$.subscribe(() => {
         // selected callpoint visits have been locked... The day for that visit must be selected after the calendar renders itself.
         this.datesClosed = this.caller.datesClosed;
      });
   }

   private subscribeToSelectedDiaryDayAfterSwapDay() {
      this._selectedDiaryDayAfterSwap_subscription = this._applicationStore.scheduleStore._selectedDiaryDayAfterSwap$.subscribe(
         (selectedDate: Date) => {
            this.selectedDate = selectedDate;
            // A visit has been added... The day for that visit must be selected after the calendar renders itself.
            this.pendingDaySelectionFromAction = true;
            // Select the day again.
            this.calendar.selectDay(this.selectedDate, true);
         }
      );
   }

   public onCalendarRendered(event) {
      //this.syncSelectedVisitOnCalendar();
      if (this.pendingDaySelectionFromAction) {
         if (this.diaryEvents) {
            let selectedVisit: Event = this.diaryEvents.find(visit => this.selectedDate != null &&
                                                                      visit.start.getTime() == this.selectedDate.getTime())
            if (selectedVisit) {
               this.calendar.selectVisit(selectedVisit, true);
               this._applicationStore.scheduleStore.setSelectedDiaryEvent(selectedVisit);
            }
            else {
               this.calendar.selectDay(this.selectedDate, true);
            }
         }

         //this.calendar.selectDay(this.selectedDate, true);
         this.pendingDaySelectionFromAction = false;
      }
   }

   public onVisitDropped(event) {
      if (event) {
         let dayIndex: number = this.getIndexDayInCycle(event.droppedDate);
         this._applicationStore.scheduleStore.addVisitToSchedule(this.caller.callerId,
            event.callpointId,
            dayIndex,
            event.droppedDate,
            this._applicationStore.uiStore.getActiveView());
      }
   }

   public onInternalVisitDropped(event) {
      if (event) {
         let originalDayIndex: number = this.getIndexDayInCycle(event.originalDate);
         let originalVisitIndex: number = this.getIndexVisitInCycle(event.originalDate)
         let droppedDayIndex: number = this.getIndexDayInCycle(event.droppedDate);

         let callpoint: Callpoint = this._applicationStore.callpointsStore.callpoints.find(callpoint => callpoint.reference == event.callpointReference)
         if (callpoint) {
            this._applicationStore.scheduleStore.moveVisitInSchedule(this.caller.callerId,
               callpoint.callpointId,
               originalDayIndex,
               originalVisitIndex,
               droppedDayIndex,
               event.droppedDate,
               this._applicationStore.uiStore.getActiveView());
         }
      }
   }

   private getIndexDayInCycle(deferralDate: Date): number {
      let iniCycleDate: moment.Moment = moment(this.startCycleDate).startOf('day');
      let selectedVisitDay: moment.Moment = moment(deferralDate).startOf('day');
      let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');
      return dayIndex;
   }

   private getIndexVisitInCycle(visitStartDate: Date): number {
      let selectedVisitDay: moment.Moment = moment(visitStartDate).startOf('day');
      let selectedVisitStartDate: moment.Moment = moment(visitStartDate);

      // Gets the visitIndex.
      let visitList = this.diaryEvents.filter(visit => {
         let visitStartDate: moment.Moment = moment(visit.start).startOf('day');
         return visit.eventType == EventTypes.visit && selectedVisitDay.diff(visitStartDate, 'days') === 0
      })
      let visitIndex: number = visitList.findIndex(item => {
         let itemStartDate: moment.Moment = moment(item.start);
         return itemStartDate.diff(selectedVisitStartDate) === 0;
      });
      return visitIndex
   }

   private subscribeToSelectedProjectSettings(): void {
      this._selectedProjectSubscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project) {
               this.projectSettings = project.projectSettings;
               this.callerProjectSettings = project.callerSettings;

               if (this.callerProjectSettings) {
                  this.callerSettingsVm = new CallerSettingsViewModel(this.callerProjectSettings, this.selectedCallerSettings ? this.selectedCallerSettings : null, this.caller);
               }
               // Sets the start/end working time
               if (this.callerSettingsVm) {
                  this.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(true, this.callerSettingsVm);
                  this.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(false, this.callerSettingsVm);
                  this.activeWorkingDays = this.callerSettingsVm.workingDayActive;
               }

               this.scheduleCycleLength = project.projectSettings.callCycleLength;
               this.startCycleDate = new Date(project.scheduleStartDate);
               this.diaryViewTime = project.projectSettings.diaryViewTime;
            }
         }
      );
   }

   // When the day optimisation completes, the schedule map needs to be updated to reflect the change
   // in the order of the visits. This code here simulates the day click event on the calendar to refresh
   // the map.
   private subscribeToScheduleGenerationComplete() {
      this._scheduleGenerationCompletedSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (diaryEvents: Event[]) => {
            if (diaryEvents.length > 0) {
               let selectedDiaryDate = this.selectedDate ? this.selectedDate : this._applicationStore.scheduleStore.selectedDiaryDay;
               if (selectedDiaryDate) {
                  let endDate: Date = new Date(selectedDiaryDate);
                  endDate.setDate(selectedDiaryDate.getDate() + 1)
                  let filteredEvents = diaryEvents.filter(item => item.start >= selectedDiaryDate && item.start <= endDate)
                  this.onDaySelected({
                     events: filteredEvents,
                     isSelected: selectedDiaryDate !== null,
                     selectedDate: selectedDiaryDate ? selectedDiaryDate : null
                  });
               }
            }
         }
      );
   }
}
