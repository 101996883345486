import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';

import { ScheduleService } from './../services/schedule.service';
import { ApplicationStore } from "app/stores/application-store";
import { Event } from 'app/models/diary/event';

// A development only view for testing the creation and retrieval of schedules from the server.
//
// == Tasks ==
// Add a button that just gets the schedule directly from the server.
// Create the schedule. ScheduleService requests creation of the schedule, then polls to check for completion.
// Support quick and full optimisation.
// Add a tab containing a calendar.
// Build the event data for the calendar from XML.
// Compare the time efficiency of building the calendar data from XML and JSON.
// Add a progress bar.
// Error handling.
@Component({
   selector: 'callsmart-schedule-test-workspace',
   templateUrl: './schedule-test-workspace.component.html'
})
export class ScheduleTestWorkspaceComponent implements OnInit, OnDestroy {

   // TODO: Replace with read only properties.
   public schedule: string = '';
   public scheduleRequest: string = '';
   public scheduleJson: string = '';
   public pendingOperation: boolean = false;
   public numDeferrals: string = '';
   public totalDriveDistance: string = '';
   public totalDriveTime: string = '';

   public progressAmount: string = '0%';

   public diaryEvents: Event[] = [];

   private _callersOptimisingSubscription :Subscription
   private _diaryEventsSubscription: Subscription;
   private _scheduleService_schedule: Subscription;
   private _scheduleService_scheduleRequest: Subscription;

   public constructor(private _scheduleService: ScheduleService,
      private _applicationStore: ApplicationStore,
      private _cdr: ChangeDetectorRef) { }

   public ngOnInit(): void {
      this._scheduleService_schedule = this._applicationStore.scheduleStore.scheduleXml$.subscribe(
         (schedule: string) => this.onScheduleService_schedule(schedule));

      this._scheduleService_scheduleRequest = this._applicationStore.scheduleStore.scheduleRequestXml$.subscribe(
         (schedule: string) => this.onScheduleService_scheduleRequest(schedule));

      this._applicationStore.scheduleStore.loadScheduleForCaller(this._applicationStore.callersStore.selectedCaller.callerId);
      this.subscribeToDiaryEvents();
      this.subscribetToCallersOptimising();
   }

   public ngOnDestroy(): void {
      this._scheduleService_schedule.unsubscribe();

      if (this._diaryEventsSubscription) {
         this._diaryEventsSubscription.unsubscribe();
      }

      if (this._callersOptimisingSubscription) {
         this._callersOptimisingSubscription.unsubscribe();
      }

      if(this._scheduleService_scheduleRequest){
         this._scheduleService_scheduleRequest.unsubscribe();
      }

   }

   private onScheduleService_schedule(schedule: any): void {
      if (schedule !== null) {
         schedule = schedule.scheduleXml.replace(/\\r\\n/g, '\n');
      }
      this.schedule = schedule;
      this.updateScheduleMetrics();
   }

   private onScheduleService_scheduleRequest(scheduleRequest: any): void {
      if (scheduleRequest !== null) {
         scheduleRequest = scheduleRequest.scheduleRequest.replace(/\\r\\n/g, '\n');
      }
      this.scheduleRequest = scheduleRequest;
      this.updateScheduleMetrics();
   }

   private updateScheduleMetrics() {

      if (!this.schedule) {
         this.numDeferrals = '';
         this.totalDriveDistance = '';
         this.totalDriveTime = '';
         return;
      }

      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(this.schedule, 'text/xml');

      // Count the number of deferrals.
      let deferralCount: XPathResult = xmlDoc.evaluate('count(/schedule/deferrals/callpoint)', xmlDoc, null,
         XPathResult.ANY_TYPE, null);
      this.numDeferrals = deferralCount.numberValue.toString();

      // Calculate the total drive distance.
      let driveDistanceNodes: XPathResult = xmlDoc.evaluate('//driveDistance', xmlDoc, null,
         XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
      let totalDriveDistance: number = 0;
      let driveDistanceNode = driveDistanceNodes.iterateNext();
      while (driveDistanceNode) {
         let driveDistance = driveDistanceNode.textContent;
         totalDriveDistance = totalDriveDistance + parseFloat(driveDistance);
         driveDistanceNode = driveDistanceNodes.iterateNext();
      }
      this.totalDriveDistance = totalDriveDistance.toFixed(1).toString() + ' miles';

      // Calculate the total drive time. The XPath selects the duration nodes of all appointments that have a
      // driveDistance node.
      let durationNodes: XPathResult = xmlDoc.evaluate('//appointment[driveDistance]/duration', xmlDoc, null,
         XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
      let totalDriveTime: moment.Duration = moment.duration();
      let durationNode = durationNodes.iterateNext();
      while (durationNode) {
         let driveTime: moment.Duration = moment.duration(durationNode.textContent);
         totalDriveTime.add(driveTime);
         durationNode = durationNodes.iterateNext();
      }
      // Neither builtin Date object nor Moment duration type have any support to format a duration. Have to do it
      // manually.
      this.totalDriveTime = Math.floor(totalDriveTime.asHours()) + 'h ' + totalDriveTime.minutes() + 'm';
   }

   private subscribeToDiaryEvents() {

      this._diaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (events: Event[]) => {
            this.diaryEvents = events;
         });
   }

   private subscribetToCallersOptimising() {
      this._callersOptimisingSubscription = this._applicationStore.scheduleStore.callersOptimising$.subscribe(
         (callerIds: number[]) => {
            // if caller id in the array then disable the buttons
            if (callerIds.find(c => c === this._applicationStore.callersStore.selectedCaller.callerId)) {
               this.pendingOperation = true;
            } else {
               this.pendingOperation = false;
            }
         }
      );
   }
 
}
