<div class="panel small-text cs-color-white contextualPanel-background-color" >
   <h2 *ngIf="selectedVisit" class="contextualPanel-header-color" pTooltip="{{selectedVisitTitle}}">{{selectedVisitTitle}}</h2>
   <h2 *ngIf="!selectedVisit" class="contextualPanel-header-color">No visit selected</h2>

   <span *ngIf="selectedVisit && selectedVisit.selectedVisit.start">Week {{week}} |  {{day}} , {{getFormattedDate()}}</span>

   <div [ngClass]="{'cs-block-ui': selectedVisit === null || (selectedVisit && selectedVisit.selectedVisit && !selectedVisit.selectedVisit.start)}">
      <div class="btn-group" style="margin-top: 15px; margin-bottom: 10px; display: table; overflow:auto; width:100%;">
         <button type="button" class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span style="float: left">Actions</span>
            <span class="cs-icon icon-arrow-down dropdown-button-icon" ></span>
         </button>
         <ul class="dropdown-menu cs-dropdown-inverse" style="width:220px;">
            <li [ngClass]="{'disabled': !selectedVisit}">
               <a (click)="unscheduleVisit()" [ngClass]="{'callsmart-link-no-active' : !selectedVisit}">
                  <span style="margin-right:5px;" class="cs-icon icon-calendar-8"></span> 
                  Unschedule visit
               </a>
            </li>
            <li style="border-left: #fff76d solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDay}">
               <a (click)="lockVisitToDay()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDay}">
                  <span style="margin-right:5px;" class="cs-icon icon-locked-5"></span>
                  Lock to day
               </a>
            </li>
            <li style="border-left: #e90000 solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDayTime}">
               <a (click)="lockVisitToDayTime()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDayTime}">
                  <span style="margin-right:5px;" class="cs-icon icon-safe"></span>
                  Lock to time & day
               </a>
            </li>
            <li [ngClass]="{'disabled': areAllVisitsUnlocked}">
               <a (click)="unlockVisit()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsUnlocked}">
                  <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
                  Unlock
               </a>
            </li>
         </ul>
      </div>
      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Driving time:</div>
            <div class="contextualPanel-list-items-secondline" >
               <div *ngIf="selectedVisit && selectedVisit.selectedVisit && selectedVisit.selectedVisit.start; else noDrivingTime" class="display-flex-column display-flex-first-column">
                  {{drivingTime}} mins
               </div>
               <ng-template #noDrivingTime>
                  - -
               </ng-template>
            </div>
         </div>
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Phase:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="selectedVisit && selectedVisit.selectedVisit && selectedVisit.selectedVisit.start; else noPhase" class="display-flex-column display-flex-first-column">
                  {{phase}}
               </div>
               <ng-template #noPhase>
                  - -
               </ng-template>
            </div>

         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Start time:</div>
            <div class="contextualPanel-list-items-secondline" >
               <div *ngIf="selectedVisit && selectedVisit.selectedVisit && selectedVisit.selectedVisit.start; else noStartTime" class="display-flex-column display-flex-first-column">
                  {{startTime}}
               </div>
               <ng-template #noStartTime>
                  - -
               </ng-template>
            </div>
         </div>
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Visit duration:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="selectedVisit && selectedVisit.selectedVisit && selectedVisit.selectedVisit.start; else noDuration" class="display-flex-column display-flex-first-column">
                  {{duration}} mins
               </div>
               <ng-template #noDuration>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</div>
