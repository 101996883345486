<p-dataTable 
   [value]="callpoints" 
   [resizableColumns]="true" 
   [reorderableColumns]="false" 
   scrollable="true" 
   [scrollHeight]="scrollHeight"
   selectionMode="multiple" 
   [(selection)]="selectedCallPoints" 
   (onRowSelect)="onRowSelect($event)" 
   (onRowUnselect)="onRowUnselect($event)"
   (onFilter)="onFilter($event)" 
   [rowTrackBy]="trackByFn" 
   [rows]="rows" 
   (onLazyLoad)="loadDataLazy($event)" 
   virtualScroll="virtualScroll"
   [lazy]="true" 
   (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" 
   [totalRecords]="totalRecords" 
   (onColResize)="onColResize($event)"
   #callpointdt>

   <p-header>
      <div style="float:left; margin-top: -4px;">
         <h4>{{gridTitle}}</h4>
      </div>
      <div style="float:left; height: 40px; padding-left: 10px;">
         <button id="clearFilter" type="button" class="btn cs-btn-secondary" (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
            <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
      </div>

      <div style="text-align:right">
         <!--span>No infite scrolling needs to come later problem with selection </span>-->
         <callsmart-multi-select [options]="columnOptions" [(ngModel)]="cols" [selectedItemsLabel]="selectedItemsLabel" [displaySelectedLabel]="displaySelectedLabel"
            [maxSelectedLabels]="maxSelectedLabels" [defaultLabel]="defaultLabel" [style]="{'width':'120px'}" [panelTitle]="panelTitle"
            [scrollHeight]="filterColumnPanelHeight" (onChange)="setNewColumnSelection($event)"></callsmart-multi-select>

      </div>
   </p-header>

   <!-- check box for selection -->
   <p-column [style]="{'width':'40px', 'min-width':'35px','max-width':'40px', 'padding':'5px'}" selectionMode="multiple"></p-column>

   <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
      [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'width':'150px', 'min-width':'80px', 'overflow':'hidden'}">

      <ng-template pTemplate="filter" let-col *ngIf="col.hasCombo">
         <p-dropdown [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter" [(ngModel)]="filterSelectedValues[i]"
            (onChange)="callpointdt.filter($event.value,col.field,col.filterMatchMode)" appendTo="body"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="filter" let-col *ngIf="col.hasMulti">
         <p-multiSelect [options]="this.comboFilterDataMap.get(col.field)" [style]="{'width':'100%'}" styleClass="ui-column-filter multiselect-filter-col"
            displaySelectedLabel="true" maxSelectedLabels="0" [(ngModel)]="filterSelectedMultiValues[i]" (onChange)="callpointdt.filter($event.value,col.field,col.filterMatchMode)"
            defaultLabel="All" appendTo="body"></p-multiSelect>
      </ng-template>


      <!-- Show progress indicator for all the following columns, doing the ngIf check on the
      ng-template tag doesn't work, hence why it is wrapped by the ng-container -->

      <ng-container *ngIf="col.field == 'driveDistanceToCaller'">
         <ng-template let-callpoint="rowData" pTemplate="body">
            <span>{{((callpoint !== null )? callpoint.driveDistanceToCaller : '') | distanceUnits:isDistanceUnitMiles
               | number:'1.0-0'}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'datesClosed'">
         <ng-template let-callpoint="rowData" pTemplate="body">
            <span>{{formatDateClosed(callpoint)}}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'startDate'">
         <ng-template let-callpoint="rowData" pTemplate="body">
            <span>{{callpoint.startDate | date:'y-MM-dd' }}</span>
         </ng-template>
      </ng-container>

      <ng-container *ngIf="col.field == 'endDate'">
         <ng-template let-callpoint="rowData" pTemplate="body">
            <span>{{callpoint.endDate | date:'y-MM-dd' }}</span>
         </ng-template>
      </ng-container>

   </p-column>
</p-dataTable>
