
<div style="display:flex;height:100vh">

   <!-- Left column fills remaining space. -->
   <div style="display:flex;flex-direction:column;flex:1">

      <!-- Top left row sizes to its contents. -->
      <div style="display:flex">
         <div style="flex:1">
            <p>Deferrals: {{numDeferrals}}</p>
            <p>Total Drive Distance: {{totalDriveDistance}}</p>
            <p>Total Drive Time: {{totalDriveTime}}</p>
         </div>
         <div style="flex:1">
            <p>Pending Operation: {{pendingOperation}}</p>
            <p style="font-size: 15px; color: red;">If the data looks out of date, use the Load Schedule button on the right to refresh.</p>
         </div>
      </div>

      <!-- Bottom left row uses remaining space. -->
      <div style="display:flex;flex-direction:column;flex:1">

         <div *ngIf="pendingOperation; else noPendingOperation" align="center">
            <h2>Magic is happening!</h2>
            <div class="progress progress-striped active" style="width:60%">
               <div class="progress-bar" style="width:100%"></div>
            </div>
         </div>
         <ng-template #noPendingOperation>
            <div style="display:flex;flex-direction:column;height:100%">

               <div>
                  <ul class="nav nav-tabs">
                     <li class="nav active" style="width: 100px;"><a data-toggle="tab" href="#sectionA">Diary View</a></li>
                     <li class="nav" style="width: 100px;"><a data-toggle="tab" href="#sectionB">Schedule Xml View</a></li>
                     <li class="nav" style="width: 100px;"><a data-toggle="tab" href="#sectionC">Schedule Request Xml View</a></li>
                  </ul>
               </div>

               <div class="tab-content" style="flex:1">
                  <div id="sectionA" class="tab-pane fade in active">
                     <callsmart-calendar class="white-calendar" #calendar [scheduleEvents]="diaryEvents"></callsmart-calendar>
                  </div>
               
                  <div id="sectionB" class="tab-pane fade" style="height:100%">
                     <textarea style="color:black;width:100%;height:100%">{{schedule}}</textarea>
                  </div>
               
                  <div id="sectionC" class="tab-pane fade" style="height:100%">
                     <textarea style="color:black;width:100%;height:100%">{{scheduleRequest}}</textarea>
                  </div>
                  
               </div>

            </div>
         </ng-template>

      </div>

   </div>

   <!-- Right column sizes to its contents. -->
   <div style="background-color:black">
      <callsmart-schedule-test-contextual-panel></callsmart-schedule-test-contextual-panel>
   </div>

</div>
