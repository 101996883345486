import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';
import * as moment from 'moment';

import { CallerService } from 'app/services/caller.service';
import { Caller } from 'app/models/caller';
import { MapPoint } from 'app/models/map-point';
import { ProjectsStore } from 'app/stores/projects-store';
import { Project } from 'app/models/project';
import { MainDashboard } from 'app/models/main-dashboard';
import { MapsStore } from 'app/stores/maps-store';
import { ExportParameters } from 'app/models/export-parameters';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { ImportCallerCounts } from 'app/models/import-caller-counts';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ScheduleMetrics } from 'app/models/scheduleMetrics';

export const MILES_TO_KM_FACTOR = 0.621371192;

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class CallersStore {

   private mapIconCaller: string = 'assets/icons/Caller.png';
   private mapIconCallerSelected: string = 'assets/icons/Caller_Selected.png';
   private mapIconCallerSmall: string = 'assets/icons/Caller_small.png';
   private mapIconCallerSelectedSmall: string = 'assets/icons/Caller_small_selected.png';

   public callerExportColumns: Map<string, string>;

   // used by the application store to orchestrate interactions between stores
   public selectedCallerChanged: Subject<any> = new Subject<any>();

   // Callers in the current project.
   private _callers: BehaviorSubject<ReadonlyArray<Caller>> = new BehaviorSubject<ReadonlyArray<Caller>>([]);
   public callers$: Observable<ReadonlyArray<Caller>> = this._callers.asObservable();

   // Callers Map points in the current project.
   private _callersMapPoints: BehaviorSubject<Array<MapPoint>> = new BehaviorSubject<Array<MapPoint>>([]);
   public callersMapPoints$: Observable<Array<MapPoint>> = this._callersMapPoints.asObservable();

   //currently selected caller
   private _selectedCaller: BehaviorSubject<Caller> = new BehaviorSubject<Caller>(null);
   public selectedCaller$: Observable<Caller> = this._selectedCaller.asObservable();

   public _selectedCallerMetrics: BehaviorSubject<ScheduleMetrics> = new BehaviorSubject<ScheduleMetrics>(null);
   public selectedCallerMetrics$: Observable<ScheduleMetrics> = this._selectedCallerMetrics.asObservable();

   //Currently selected list of callers, used by the contextual panel to sync the callers
   private _selectedCallers: BehaviorSubject<Array<Caller>> = new BehaviorSubject<Array<Caller>>([]);
   public selectedCallers$: Observable<Array<Caller>> = this._selectedCallers.asObservable();

   // Event for deselecting a caller from the list and map, used by contextual panel.
   private _deselectCaller: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public deselectCaller$: Observable<string> = this._deselectCaller.asObservable();

   // Event for single selecting a caller from a multi select, used by contextual panel multi select callers.
   private _singleSelectCaller: BehaviorSubject<Caller> = new BehaviorSubject<Caller>(null);
   public singleSelectCaller$: Observable<Caller> = this._singleSelectCaller.asObservable();

   private _callerLocationChanged: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
   public callerLocationChanged$: Observable<Array<number>> = this._callerLocationChanged.asObservable();

   private _callerClosedDatesChanged: Subject<void> = new Subject<void>();
   public _callerClosedDatesChanged$: Observable<void> = this._callerClosedDatesChanged.asObservable();

   public isDistanceUnitMiles: boolean = false;
   private distanceHeader: string = '';

   // Initialising the selected columns to be displayed in the callers grid. This array will allow that grid to
   // show the latest column selection when loading
   public selectedCols: any[] = [
      { field: 'territory', header: 'Code', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'name', header: 'Name', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'scheduledVisitsPc', header: 'Scheduled visits (%)', disabled: false, filter: true, filterPlaceholder: 'equals', filterMatchMode: 'equals', hasCombo: false, hasMulti: false },
      { field: 'numDeferredVisits', header: 'Deferred visits', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'scheduleMetrics.minCallsPerDay', header: 'Min visits per day', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'scheduleMetrics.avgCallsPerDay', header: 'Avg visits per day', disabled: false, filter: true, filterPlaceholder: 'equals', filterMatchMode: 'equals', hasCombo: false, hasMulti: false },
      { field: 'scheduleMetrics.maxCallsPerDay', header: 'Max visits per day', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'scheduleMetrics.utilisationPc', header: 'Utilisation (%)', disabled: false, filter: true, filterPlaceholder: 'equals', filterMatchMode: 'equals', hasCombo: false, hasMulti: false },
      { field: 'scheduleMetrics.driveDistanceKm', header: '', disabled: false, filter: true, filterPlaceholder: 'Starts with', filterMatchMode: 'startsWith', hasCombo: false, hasMulti: false }
   ]


   public get selectedCaller() {
      return this._selectedCaller.getValue();
   }

   public get selectedCallerMetrics() {
      return this._selectedCallerMetrics.getValue();
   }

   public get selectedCallers() {
      return this._selectedCallers.getValue();
   }

   public get callersMapPoints() {
      return this._callersMapPoints.getValue();
   }

   public get callers() {
      return this._callers.getValue();
   }

   // total callers based on orginal callers array, so no filters applied
   public get totalCallersCount() {
      return this._originalCallersList.length;
   }

   public get callerLatLngChangedIds() {
      return this._callerLocationChanged.getValue();
   }

   // Number of callers in the project with drive time data
   private _callersWithDrivetimeData: BehaviorSubject<number> = new BehaviorSubject<number>(null);
   public callersWithDrivetimeData$: Observable<number> = this._callersWithDrivetimeData.asObservable();

   // Number of under-utilised Callers in the current project.
   private _underUtilised: BehaviorSubject<number> = new BehaviorSubject<number>(null);
   public underUtilised$: Observable<number> = this._underUtilised.asObservable();

   // Number of over-utilised Callers in the current project.
   private _overUtilised: BehaviorSubject<number> = new BehaviorSubject<number>(null);
   public overUtilised$: Observable<number> = this._overUtilised.asObservable();

   // Number of fully-utilised Callers in the current project.
   private _fullyUtilised: BehaviorSubject<number> = new BehaviorSubject<number>(null);
   public fullyUtilised$: Observable<number> = this._fullyUtilised.asObservable();

   // Number of fully-utilised Callers in the current project.
   private _dashboardCalculations: BehaviorSubject<MainDashboard> = new BehaviorSubject<MainDashboard>(null);
   public dashboardCalculations$: Observable<MainDashboard> = this._dashboardCalculations.asObservable();

   private _callerMergeCounts: Subject<ImportCallerCounts> = new Subject<ImportCallerCounts>();
   public callerMergeCounts$: Observable<ImportCallerCounts> = this._callerMergeCounts.asObservable();

   private _infillRoutes: Subject<ReadonlyArray<string>> = new Subject<ReadonlyArray<string>>();
   public infillRoutes$: Observable<ReadonlyArray<string>> = this._infillRoutes.asObservable();

   // Over and under utilisation values.
   private _underUtilisedSetting: number = -1;
   private _overUtilisedSetting: number = -1;

   // Used for caching callers.
   private _originalCallersList: Caller[];

   // properties for filtering caller grid
   private _filterCallersByUnderUtilisation: boolean = false;
   public get filterCallersByUnderUtilisation() {
      return this._filterCallersByUnderUtilisation;
   }

   private _filterCallersByOverUtilisation: boolean = false;
   public get filterCallersByOverUtilisation() {
      return this._filterCallersByOverUtilisation;
   }

   private _filterCallersByFullyUtilisation: boolean = false;
   public get filterCallersByFullyUtilisation() {
      return this._filterCallersByFullyUtilisation;
   }

   // Subscription for the project.
   private _project_subscription: Subscription;

   constructor(private _callerService: CallerService,
      private _projectsStore: ProjectsStore,
      private _mapsStore: MapsStore,
      private _errorHandler: ErrorHandlerService) {

      // check if the local storage has caller columns
      let storedColumns = JSON.parse(localStorage.getItem("callersSelectedCols"));
      if (storedColumns) {
         if (storedColumns.length > 0) {
            this.selectedCols = storedColumns;
         }
      }

      this.subscribeToProject();
   }

   public setSelectedCaller(caller: Caller) {
      this._selectedCaller.next(caller);
      this.selectedCallerChanged.next(caller);

      if(caller != null){
        this._selectedCallerMetrics.next(caller.scheduleMetrics);
      }
   }

   public deselectCaller(guid: string) {
      this._deselectCaller.next(guid);
   }

   public singleSelectCaller(caller: Caller) {
      this._singleSelectCaller.next(caller);
      this._selectedCallers.next([caller]);
   }

   public setSelectedCallers(callers: Caller[]) {
      // check if callers have changed
      if (this.selectedCallers !== callers) {
         this._selectedCallers.next(callers);
      }
   }

   // Filters and emits all under utilised callers.
   public filterUnderUtilisedCallers(): void {
      this._filterCallersByUnderUtilisation = true;
      let filteredCallers = this.getUnderUtilisedCallers();
      this._callers.next(filteredCallers);
   }

   public getUnderUtilisedCallers(): Caller[] {
      if (this._originalCallersList) {
         return this._originalCallersList.filter((caller: Caller) =>
            caller.scheduleMetrics == null
            || caller.scheduleMetrics.utilisationPc <= this._underUtilisedSetting);
      }
      else return null;
   }

   // Filters and emits all over utilised callers.
   public filterOverUtilisedCallers(): void {
      this._filterCallersByOverUtilisation = true;
      let filteredCallers = this.getOverUtilisedCallers();
      this._callers.next(filteredCallers);
   }

   public getOverUtilisedCallers(): Caller[] {
      if (this._originalCallersList) {
         return this._originalCallersList.filter((caller: Caller) =>
            caller.scheduleMetrics != null
            && caller.scheduleMetrics.utilisationPc >= this._overUtilisedSetting);
      }
      else return null;
   }

   // Filters and emits all fully utilised callers.
   public filterFullyUtilisedCallers(): void {
      this._filterCallersByFullyUtilisation = true;
      let filteredCallers = this.getFullyUtilisedCallers();
      this._callers.next(filteredCallers);
   }

   public getFullyUtilisedCallers(): Caller[] {
      if (this._originalCallersList) {
         return this._originalCallersList.filter((caller: Caller) =>
            caller.scheduleMetrics != null
            && caller.scheduleMetrics.utilisationPc > this._underUtilisedSetting
            && caller.scheduleMetrics.utilisationPc < this._overUtilisedSetting);
      }
      else return null;
   }

   public resetUtilisationFilter() {
      this._filterCallersByFullyUtilisation = false;
      this._filterCallersByUnderUtilisation = false;
      this._filterCallersByOverUtilisation = false;

      this._callers.next(this._originalCallersList);
   }

   // QUERY: Why does this need to be parameterized to support being called for any projectId. Surely it's only ever
   // going to be used to get the callers for the loaded project, in which case making every client that calls this
   // have to locate the ProjectId is just a ballache that creates more potential for error.
   // A: this is called by the applicatin store this knows when the project has changed and what the loaded project is
   // that is why it is parametised the goal is to keep the stores sperate and orchestrated by the application store
   public loadAllCallers(projectId: number) {
      //console.log("Caller Store - load all callers for project id",projectId );

      this._callerService.getCallers(projectId)
         .subscribe((callers: Caller[]) => {
            this.refreshCallersCalculations(callers);
            if (!this.selectedCaller) {
               this.setSelectedCaller(callers[0]);

               //console.log("Caller Store - load all callers set the selected caller",callers[0]);
            }
            else {
               let c = callers.find(c => c.callerId == this.selectedCaller.callerId);
               this.setSelectedCaller(c);
               // this.selectedCaller.scheduleMetrics = c.scheduleMetrics;
               // this.selectedCaller.latitude = c.latitude;
               // this.selectedCaller.longitude = c.longitude;


               //console.log("Caller Store - load all callers update selected callers metrics",c);
            }
            this.buildCallersMapPoints(callers);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   // when the schedule or vists are changed they are updated in the database  and returned to the client.
   // no need to rebuild things like callers map points they would not have changed
   // goal here is to tweak performance
   public loadAllCallersWithoutMapPoints(projectId: number) {
      this._callerService.getCallers(projectId)
         .subscribe((callers: Caller[]) => {
            this.refreshCallersCalculations(callers);
            if (this.selectedCaller) {
               let c = callers.find(c => c.callerId == this.selectedCaller.callerId);
               this.selectedCaller.scheduleMetrics = c.scheduleMetrics;
            }
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }


   public getCallersByProjectId(projectId: number) {
      return this._callerService.getCallers(projectId)
   }

   public deleteCallersByProjectId(projectId: number) {
      this._callerService.deleteCallersByProjectId(projectId)
         .subscribe((result: any) => {
            //this._callers.next(null);
            // this._callersMapPoints.next(null);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   //export all callers schedules
   public exportAllCallersSchedules(projectId: number) {
      this._callerService.exportAllCallersSchedules(projectId)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - All Schedules.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   // export a single caller schedule
   public exportCallerSchedule(projectId: number, callerId: number, callerName: string) {
      this._callerService.exportCallerSchedule(projectId, callerId)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - ' + callerName + ' Schedule.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   //export all callers Data
   public exportAllCallersData(projectId: number, exportParamters: ExportParameters) {
      this._callerService.exportCallerData(projectId, exportParamters)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - All Callers.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   // export a selection of callers caller Data
   public exportSelectedCallersData(projectId: number, exportParamters: ExportParameters) {
      this._callerService.exportCallerData(projectId, exportParamters)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - ' + exportParamters.selectedItemsIds.length + ' selected callers.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }


   public computeUtilisation(): void {
      let callers: ReadonlyArray<Caller> = this._callers.value;

      let underUtilised = callers.filter((caller: Caller) => caller.scheduleMetrics == null
         || caller.scheduleMetrics.utilisationPc <= this._underUtilisedSetting).length;
      let overUtilised = callers.filter((caller: Caller) => caller.scheduleMetrics != null
         && caller.scheduleMetrics.utilisationPc >= this._overUtilisedSetting).length;
      let fullyUtilised = callers.length - underUtilised - overUtilised;

      this._underUtilised.next(underUtilised);
      this._overUtilised.next(overUtilised);
      this._fullyUtilised.next(fullyUtilised);
   }

   public updateClosedDates(caller: Caller, calendarClosedDates: string[]) {
      let oldCalendarClosedDates: string[] = caller.datesClosed;
      caller.datesClosed = calendarClosedDates;

      this._callerService.updateCallers(caller.projectId, [caller])
         .subscribe((response: any) => {
            // updateMultipleCallerSettings returns an array in which each element contains a callerId and a
            // callerSettingsId. These are the ids of any new CallpointSettings objects that have been added by
            // the update. The client must use these to update its local model objects otherwise subsequent updates
            // will fail or add erroneous extra objects to the database.
            response.callersAddedCallerSettings.forEach(i => {
               if (caller.callerId == i.callerId) {
                  caller.callerSettings.callerSettingsId = i.callerSettingsId;
               }
            });
            this._selectedCaller.next(this.selectedCaller);
            this._callerClosedDatesChanged.next(null);
         },
            (error) => {
               caller.datesClosed = oldCalendarClosedDates;
               this._errorHandler.handleError(error)
            }
         );
   }

   //to comment out
   /*public updateCallerSettings(callerSettings: CallerSettings) {
      this._callerService.updateCallerSettings(this.selectedCaller.callerId, callerSettings)
         .subscribe((response: Response) => {
            // Update the current caller with new settings and notify
            this.selectedCaller.callerSettings = callerSettings;
            this._selectedCaller.next(this.selectedCaller);
         },
         (error) => {
            this._errorHandler.handleError(error);
         });
   }

   // to comment out
   public updateMultipleCallerSettings(callerSettings: CallerSettings[]) {
      this._callerService.updateMultipleCallerSettings(callerSettings)
         .subscribe((response: Response) => {
            // Update the current caller with new settings and notify
            //this.selectedCaller.callerSettings = callerSettings;
            //this._selectedCaller.next(this.selectedCaller);
         },
         (error) => {
            this._errorHandler.handleError(error);
         });
   }*/

   // based on the callpoint store updateCallpoints by MNF:
   // updateCallers now does the job of old updateCallerSettings and the old updateMultipleCallerSettings.
   public updateCallers(projectId: number, updatedCallers: Caller[]) {
      this._callerService.updateCallers(projectId, updatedCallers)
         .subscribe((response: any) => {
            // updateMultipleCallerSettings returns an array in which each element contains a callerId and a
            // callerSettingsId. These are the ids of any new CallpointSettings objects that have been added by
            // the update. The client must use these to update its local model objects otherwise subsequent updates
            // will fail or add erroneous extra objects to the database.
            response.callersAddedCallerSettings.forEach(i => {
               let c = updatedCallers.find(c => c.callerId == i.callerId);
               c.callerSettings.callerSettingsId = i.callerSettingsId;
            });

            // Find the updated caller that has the same id as the currently selected caller and send that to the
            // listeners. This will update the UI with any changes to this caller.
            let updatedSelectedCaller = updatedCallers.find(c => c.callerId == this.selectedCaller.callerId);
            if (updatedSelectedCaller) {
               this._selectedCaller.next(updatedSelectedCaller);
            }

            if (response.callersLatLongChanged.length > 0) {
               // Handle this in the ApplicationStore.
               this._callerLocationChanged.next(response.callersLatLongChanged);
            }
         },
            (error) => this._errorHandler.handleError(error)
         );
   }

   // the user can toggle style of icons change the icons styles
   public RefreshCallersMapPointsIcons() {
      this.buildCallersMapPoints(this._callers.getValue().map(v => v))
   }

   public getCallerMergeCounts(currentProjectId: number, tempProjectId: number) {
      this._callerService.getCallerMergeCounts(currentProjectId, tempProjectId)
         .subscribe((response) => {
            let importCounts: ImportCallerCounts = new ImportCallerCounts();
            importCounts.callersAdded = response.addedCallerLength;
            importCounts.callersUpdate = response.updatedCallerLength;
            importCounts.callersDeleted = response.deletedCallerLength;
            importCounts.originalCallersCount = response.originalCallerLength;

            this._callerMergeCounts.next(importCounts);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   public getInfillRoutesForCallers(projectId: number, callerIds: number[]) {
      this._callerService.getInfillRoutesForCallers(projectId, callerIds)
         .subscribe((infillRoutes: string[]) => {
            this._infillRoutes.next(infillRoutes);
         },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   private refreshCallersCalculations(callers: Caller[]): void {
      // Cache a local copy for filtering use
      // TODO: Come up with a better mechanism for caching callers
      this._originalCallersList = callers;

      // Sort callers by name.
      callers.sort((lhs: Caller, rhs: Caller) => lhs.name.localeCompare(rhs.name));

      // Percentage of visits scheduled is currently calculated. This could be done by the SchedulerModule and
      // stored in the database. MNF: It could but that would be pointless. Far better would for this to be implemented
      // in the Caller class itself.
      callers.forEach((caller: Caller) => {
         if (caller.scheduleMetrics != null) {
            // Calculate deferred visits.
            caller.numDeferredVisits = Math.max(0, caller.scheduleMetrics.numDeferrals - caller.scheduleMetrics.numDisabledVisits);
            
            // Calculate scheduledVisitsPc
            // If scheduled visits is 0 then the percentage should be 0.
            // If scheduled is >0 then percentage should be calculated using:
            // (Total visits – disabled visits – deferred visits)/(Total visits – disabled visits)
            let pc = caller.scheduleMetrics.numVisitsToMake === 0 ? 0 :
               (caller.scheduleMetrics.numVisitsToMake - caller.scheduleMetrics.numDisabledVisits - caller.numDeferredVisits)
               / (caller.scheduleMetrics.numVisitsToMake - caller.scheduleMetrics.numDisabledVisits) * 100;
            
            // If all callpoints are disabled then pc must be 0;
            if (caller.scheduleMetrics.numCallpoints === caller.scheduleMetrics.numDisabledCallpoints) {
               pc = 0;
            }
            
            // Round to 1 decimal place.
            caller.scheduledVisitsPc = Math.round(pc * 10) / 10;

            // Calculate average commute time
            let totalCommuteTime: moment.Duration = moment.duration();
            totalCommuteTime.add(caller.scheduleMetrics.commuteTimeHrs, 'hours');

            // PSN-3010 avg commute time/distance = total commute (including overnight start and finish) / number of commutes
            // number of commutes can be calculated as (number of days with scheduled visits * 2)
            let noOfCommute = (caller.scheduleMetrics.numAvailableDays - caller.scheduleMetrics.numEmptyDays) * 2;

            let avgCommuteTime = totalCommuteTime.asMinutes() / noOfCommute;
            // Round to 1 decimal place.
            caller.avgCommuteTime = Math.round(avgCommuteTime * 10) / 10;

            // Calculate average commute distance
            let avgCommuteDistance = caller.scheduleMetrics.commuteDistanceKm / noOfCommute;
            // Round to 1 decimal place.
            caller.avgCommuteDistance = Math.round(avgCommuteDistance * 10) / 10;
         }
         else {
            caller.scheduledVisitsPc = 0;
            caller.avgCommuteTime = 0;
            caller.avgCommuteDistance = 0;
         }
      });

      // This would replace the contents of the existing array, which is usually better than creating a whole new
      // array from scratch. But changing the contents of the existing array doesn't trigger an update of the
      // datatable in the Callers view, so the array is replaced instead.
      //this._callers.length = 0;
      //this._callers.push(...callers);

      // This replaces the array with a new one.
      this._callers.next(callers);

      // if (this._underUtilisedSetting !== -1) {
      this.computeUtilisation();
      //}

      this.computeMainDashboard();
   }

   private computeMainDashboard(): void {
      if (!this._originalCallersList) {
         return;
      }

      let allCallers: ReadonlyArray<Caller> = this._originalCallersList;

      let totalDriveTime: moment.Duration = moment.duration();
      let totalDriveDistanceKm: number = 0;
      let avgCallsPerDay = 0;
      let totalVisits: number = 0;
      let totalVisitsBooked: number = 0;
      let avgTravelTimeBetweenVisitMin: number = 0; //moment.Duration = moment.duration();
      let avgTravelDistanceBetweenVisitKm: number = 0;
      let totalCommuteTime: moment.Duration = moment.duration();
      let totalCommuteDistanceKm: number = 0;
      let totalNumAvailableDays: number = 0;
      let totalEmptyDays: number = 0;

      allCallers.forEach((caller: Caller) => {
         if (caller.scheduleMetrics != null) {
            totalDriveTime.add(caller.scheduleMetrics.driveTimeHrs, 'hours');
            totalDriveDistanceKm += caller.scheduleMetrics.driveDistanceKm;
            avgCallsPerDay += caller.scheduleMetrics.avgCallsPerDay;
            totalVisits += caller.scheduleMetrics.numVisitsToMake;
            totalVisitsBooked += caller.scheduleMetrics.numVisitsBooked;
            avgTravelTimeBetweenVisitMin += caller.scheduleMetrics.avgDriveTimeBetweenVisitsMins;
            avgTravelDistanceBetweenVisitKm += caller.scheduleMetrics.avgDriveDistanceBetweenVisitsKm;

            totalCommuteTime.add(caller.scheduleMetrics.commuteTimeHrs, 'hours');
            totalCommuteDistanceKm += caller.scheduleMetrics.commuteDistanceKm;
            totalNumAvailableDays += caller.scheduleMetrics.numAvailableDays;
            totalEmptyDays += caller.scheduleMetrics.numEmptyDays;
         }
      });

      let dashboard = new MainDashboard();

      // Total travel time in the schedule for all callers.
      // format = 9d 17h 23m
      dashboard.totalTravelTimeDay = Math.floor(totalDriveTime.asDays());
      dashboard.totalTravelTimeHour = totalDriveTime.hours();
      dashboard.totalTravelTimeMinute = totalDriveTime.minutes();

      // pipe will convert to miles if needed in the view
      dashboard.travelDistance = totalDriveDistanceKm;

      // Total number of scheduled visits (i.e. visits currently in the diary for all callers) divided by
      // the total number of available days for all callers.
      dashboard.avgVisitsPerDay = avgCallsPerDay / this._originalCallersList.length;
      if (isNaN(dashboard.avgVisitsPerDay)) {
         dashboard.avgVisitsPerDay = 0;
      }

      // Total number of visits to be made by all callers, put another way it is the sum of the frequency
      // column in the Callpoints grid.
      dashboard.noOfVisits = totalVisits;

      // Total number of visits booked for all callers.
      dashboard.visitsBooked = totalVisitsBooked;

      // Total number of visits booked percentage.
      dashboard.visitsBookedPercentage = (totalVisitsBooked / totalVisits) * 100;
      if (isNaN(dashboard.visitsBookedPercentage)) {
         dashboard.visitsBookedPercentage = 0;
      }
      else {
         // Round down to 1dp.
         dashboard.visitsBookedPercentage = Math.floor(dashboard.visitsBookedPercentage * 10) / 10;
      }

      // Average travel time between visits.
      dashboard.avgTravelTimeBetweenVisit = avgTravelTimeBetweenVisitMin / allCallers.length;
      if (isNaN(dashboard.avgTravelTimeBetweenVisit)) {
         dashboard.avgTravelTimeBetweenVisit = 0;
      }

      // Average travel distance between visits
      dashboard.avgTravelDistanceBetweenVisit = avgTravelDistanceBetweenVisitKm / allCallers.length;
      if (isNaN(dashboard.avgTravelDistanceBetweenVisit)) {
         dashboard.avgTravelDistanceBetweenVisit = 0;
      }

      let daysWithAppointments = totalNumAvailableDays - totalEmptyDays;
      dashboard.avgOneWayDailyCommuteDistance = (totalCommuteDistanceKm / daysWithAppointments) / 2;
      if (isNaN(dashboard.avgOneWayDailyCommuteDistance)) {
         dashboard.avgOneWayDailyCommuteDistance = 0;
      }

      dashboard.avgOneWayDailyCommuteTime = (totalCommuteTime.asMinutes() / daysWithAppointments) / 2;
      if (isNaN(dashboard.avgOneWayDailyCommuteTime)) {
         dashboard.avgOneWayDailyCommuteTime = 0;
      }

      if (this._projectsStore.selectedProject) {
         dashboard.distanceUnitIsMiles = this._projectsStore.selectedProject.projectSettings.distanceUnitMiles;
         dashboard.distanceUnitLabel = ((this._projectsStore.selectedProject.projectSettings.distanceUnitMiles) ? 'miles' : 'km');
         // CO2 footprint = Travel miles * Average vehicle CO2 emissions (g/km)
         this.calculateCo2Emissions(dashboard);
      }

      this._dashboardCalculations.next(dashboard);
   }

   private calculateCo2Emissions(dashboard: MainDashboard): void {
      // Convert the travel distance from miles to kilometres because
      // CO2 is calculated in grams/km.

      let travelDistance = dashboard.travelDistance;

      // Calculate the co2.
      let co2InGrams: number = Math.round(travelDistance * this._projectsStore.selectedProject.projectSettings.averageVehicleEmission);

      // If the total co2 emissions is less than 1 tonne (1000 grams) then display as grams.
      if (co2InGrams < 1000) {
         dashboard.co2Footprint = co2InGrams.toString();
         dashboard.co2Unit = 'g';
      }
      else {
         // Else convert the value in to tonnes.
         let co2InTonnes: number = (co2InGrams / 1000000);

         // If the co2 emissions are less than 10,000, then we have to increase the number of
         // decimal places to 3 otherwise 0.00 value will be displayed when using 2dp.
         if (co2InGrams < 10000) {
            dashboard.co2Footprint = co2InTonnes.toFixed(3);
         }
         else {
            dashboard.co2Footprint = co2InTonnes.toFixed(2);
         }
         dashboard.co2Unit = 't';
      }
   }

   // build the caller map points once and cache the points in the store
   // On the large dater set there are 121 csallers this renders slow.
   // I believe this is due to he point with label
   // if there are callers greater than 50 then shift to a small point no label
   private buildCallersMapPoints(callers: Caller[]) {
      let points: MapPoint[] = [];
      let icon: string;
      let selectedIcon: string;

      if (callers.length > 50) {
         // if large data set defualt to the dot icon
         this._mapsStore.useCallerDotIcon = true;
      }

      // set the icons for the callers
      if (this._mapsStore.useCallerDotIcon) {
         icon = this.mapIconCallerSmall;
         selectedIcon = this.mapIconCallerSelectedSmall
      } else {
         icon = this.mapIconCaller
         selectedIcon = this.mapIconCallerSelected
      }

      callers.forEach(c => {
         let wndBody = '<strong>' + c.name + '</strong><br>' + c.territory + '<br>' + c.postcode;
         points.push(new MapPoint(c.latitude, c.longitude, c.name, wndBody, icon,
            selectedIcon, this.mapIconCallerSmall, c.guid, undefined))
      });

      this._callersMapPoints.next(points);
   }

   private subscribeToProject() {
      this._project_subscription = this._projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project !== null) {
               this._underUtilisedSetting = project.projectSettings.underUtilisation;
               this._overUtilisedSetting = project.projectSettings.overUtilisation;

               // Reset the filters
               this._filterCallersByFullyUtilisation = false;
               this._filterCallersByUnderUtilisation = false;
               this._filterCallersByOverUtilisation = false;

               this.computeUtilisation();
               this.computeMainDashboard();

               this.isDistanceUnitMiles = project.projectSettings.distanceUnitMiles;
               this.distanceHeader = ((this.isDistanceUnitMiles) ? 'Total driving distance (mi)' : 'Total driving distance (km)')

               let driveDistanceColumnIndex: number = this.selectedCols.findIndex(col => col.field == 'scheduleMetrics.driveDistanceKm');
               if (driveDistanceColumnIndex !== -1) {
                  this.selectedCols[driveDistanceColumnIndex].header = this.distanceHeader;
               }

               this.buildDefaultExportColumns();
            }
         });
   }

   private buildDefaultExportColumns() {
      let exportColumns = new Map<string, string>();
      this.selectedCols.forEach(c => {
         exportColumns.set(c.field, c.header);
      });

      exportColumns.set('territory','Key');
      this.callerExportColumns = exportColumns;
   }
}
