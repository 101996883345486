import {
   Component,
   OnInit,
   EventEmitter,
   Output,
   Input,
   OnDestroy,
   ViewChild
} from '@angular/core';

import { LazyLoadEvent } from 'primeng/components/common/api';
import { SelectItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { Caller } from 'app/models/caller';
import { Callpoint } from 'app/models/callpoint';

import { ApplicationStore } from 'app/stores/application-store';
import { DataTable } from 'primeng/components/datatable/datatable';
import {
   contains,
   compareValues,
   filter,
   compareFullyScheduled,
   getComboDataForSpecifiedProperty
} from 'app/shared/utils/callsmart-grid-util';

// Excellent resource on type script MAP (dictionaries)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map/delete

@Component({
   selector: 'callsmart-callpoints-list',
   templateUrl: './callpoints-list.component.html'
})

// Having a problem with the infinte scroll and selection when clicking on the map.
//Infinite scroll has been disabled till that story arrives from the backlog
export class CallpointsListComponent implements OnInit, OnDestroy {
   private _callpointsSubscription: Subscription;
   private _sortedAndFilteredData: Callpoint[];
   private _currentSortField: string;
   private _currentSortOrder: string;
   private _filterNum: number;
   private _allCallpointsForCaller: Callpoint[] = [];

   @ViewChild(DataTable)
   private callpointList: DataTable;

   @Output() rowUnSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() rowMultiSelected: EventEmitter<any> = new EventEmitter<any>();
   @Output() allRowsSelected: EventEmitter<any> = new EventEmitter<any>();
   @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();

   @Output() headerHeight: number;
   @Output() tableHeaderHeight: number;
   @Output() tableHeight: number;

   get caller(): Caller {
      return this._applicationStore.callersStore.selectedCaller;
   }

   private _scrollHeight = '200px';

   @Input()
   public get scrollHeight(): string {
      return this._scrollHeight;
   }
   public set scrollHeight(scrollHeight: string) {
      this._scrollHeight = scrollHeight;
      let scrollHeightStringValue: string = this._scrollHeight.substr(
         0,
         this._scrollHeight.length - 2
      );

      let scrollHeightValue: number;
      if (
         Number(scrollHeightStringValue) >= 400 &&
         Number(scrollHeightStringValue) <= 600
      ) {
         scrollHeightValue = Number(scrollHeightStringValue);
      } else if (Number(scrollHeightStringValue) < 400) {
         scrollHeightValue = 400;
      } else {
         scrollHeightValue = 600;
      }
      this.filterColumnPanelHeight = Number(scrollHeightValue) - 72 + 'px'; // 116px is the height of the header of the filter column panel;
   }
   public filterColumnPanelHeight: string;

   @Input() scrollWidth: string = '200px';

   //Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];
   public hasFilters: boolean;

   public totalRecords: number = 0;
   public rows: number = 30;
   public callpoints: Callpoint[] = [];

   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedItemsLabel: string = 'Columns';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns';
   public panelTitle: string = 'Columns';
   public gridTitle: string = 'Callpoints';
   public isDistanceUnitMiles: boolean = false;
   //public selectedCallPoints: Callpoint[] = [];

   private distanceHeader: string = '';

   // Gets and sets the selected callpoints.
   private previousCheckboxSelection: any[] = [];
   private _selectedCallPoints: Callpoint[] = this._applicationStore
      .callpointsStore.selectedCallpoints;
   get selectedCallPoints(): Callpoint[] {
      return this._selectedCallPoints;
   }
   set selectedCallPoints(points: Callpoint[]) {
      if (this.callpointList) {
         // has all been selected
         if (this.callpointList.allSelected && !this.hasFilters) {
            this._selectedCallPoints = this._allCallpointsForCaller;
         } else {
            this._selectedCallPoints = points;
         }

         // ask julian what this is for
         this.previousCheckboxSelection.forEach(item => {
            if (!contains(this.selectedCallPoints, item)) {
               this.selectedCallPoints.push(item);
            }
         });

         // clear previous selection
         this.previousCheckboxSelection = [];
      }
   }

   private _cols: any;
   get cols(): any[] {
      return this._cols;
   }
   set cols(selectedCols: any[]) {
      this._cols = selectedCols;
      this.buildExportColumnsDictionary();
   }

   constructor(private _applicationStore: ApplicationStore) {}

   public ngOnInit() {
      // check what distance unit to use for the distance column
      this.isDistanceUnitMiles = this._applicationStore.projectsStore.selectedProject.projectSettings.distanceUnitMiles;
      this.distanceHeader = this.isDistanceUnitMiles
         ? 'Drive Distance to Caller (mi)'
         : 'Drive Distance to Caller (km)';

      this.configureTableColumns();
      this.subscribeToCallPoints();
   }

   public ngAfterViewInit() {
      this.getTableHeaderHeightValues();

      /* WORKAROUND TO DISPLAY THE CORRECT COLUMNS WIDTH AFTER RESIZING.
        NOT THE BEST WAY TO DO IT, BUT THE ONLY ONE WE HAVE FOUND TO RESOLVE THIS ISSUE  */
      let colsAux = this._cols.slice();
      this.cols = this._cols.slice(0, this._cols.length - 1);

      setTimeout(() => {
         this.cols = colsAux;
      }, 500);
   }

   public ngDoCheck() {
      let tableDivContainer = this.callpointList.el.nativeElement
         .firstElementChild;
      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      if (tableHeaderlement) {
         if (tableHeaderlement.clientHeight !== this.tableHeaderHeight) {
            this.getTableHeaderHeightValues();
            this.columnChanged.emit({
               headerHeight: this.headerHeight,
               tableHeaderHeight: this.tableHeaderHeight,
               tableHeight: this.tableHeight
            });
         }
      }
   }

   public ngOnDestroy() {
      if (this._callpointsSubscription) {
         this._callpointsSubscription.unsubscribe();
      }
   }

   // the lazy loading is typically used handle large datasets by going to the server and retrieving paged data.
   // this can be done for virtual scroll or paginated tables.
   // in our case we have all the callpoints in memory, so we are not going to the server but splicing the array to only
   // return small numbers of rows, to speed up the grid.
   // as we are using lazy loading we need to hand roll our own sorting and filtering.
   // typically we would do this on the server with custom SQL commands
   public loadDataLazy(event: LazyLoadEvent) {
      if (this.caller) {
         let order = event.sortOrder > 0 ? 'asc' : 'desc';

         // only re-order or sort if the event values have changed
         if (
            this._currentSortField != event.sortField ||
            this._currentSortOrder != order
         ) {
            this._sortedAndFilteredData = this._allCallpointsForCaller.slice();

            //this._sortedAndFilteredData.sort(this.compareValues(event.sortField, order))
            if (event.sortField == 'fullyScheduled') {
               let xxxxx = compareFullyScheduled(
                  order,
                  this._sortedAndFilteredData
               );
               let yyyyy = Array.prototype.concat.apply([], xxxxx);
               this._sortedAndFilteredData = yyyyy;
               //this._sortedAndFilteredData.sort(this.compareValues('date', order))
            } else {
               if(event.sortField != null){
                  this._sortedAndFilteredData.sort(
                     compareValues(event.sortField, order)
                  );
               }
            }

            this._currentSortField = event.sortField;
            this._currentSortOrder = order;
         }

         if (Object.keys(event.filters).length > 0) {
            let filteredResults = filter(
               this._sortedAndFilteredData,
               event.filters,
               this.callpointList,
               this.allCols,
               this.totalRecords
            );
            this._filterNum = filteredResults.length;
            if (filteredResults.length > 0) {
               this.callpoints = filteredResults.slice(
                  event.first,
                  event.first + event.rows
               );
               this.totalRecords = filteredResults.length;
            } else {
               this.callpoints = filteredResults;
               this.totalRecords = filteredResults.length;
            }
         } else {
            this.callpoints = this._sortedAndFilteredData.slice(
               event.first,
               event.first + event.rows
            );
            this.totalRecords = this._sortedAndFilteredData.length;
         }
      }
   }

   public onRowSelect(event) {
      /// dirty Hack The prime ng shift select does not have a multi select event.
      /// it calls a method seletTange that then events for each row in the multi select.
      /// extend the control to change this behavior is proving diffictult. currently hack is not turned on

      /*if (this.callpointList.anchorRowIndex !== this.callpointList.rangeRowIndex) {
         // only fire the event once. On row select is called multiple times
         if (this.callpointList.selection.length == 1) {
            let data = this.getDataSelectedRange(this.callpointList.rangeRowIndex)
            this.rowMultiSelected.emit(data);
         }
      }
      else {
         this.rowSelected.emit(event);
      }*/

      // the checkbox selection doesnt seem to be adding the data to the selectedarray
      if (event.type == 'checkbox') {
         this.selectedCallPoints.push(event.data);
      }

      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }

      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }

      this.callpointList.reset();

      // have to clear all the custom filters
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.callpointList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // this keeps the orgingal sort
      this.callpointList.updateDataToRender(this.callpoints);
      this.hasFilters = false;
      this.gridTitle = 'Callpoints';
   }

   public onFilter(event) {
      if (Object.keys(this.callpointList.filters).length > 0) {
         this.gridTitle =
            'Callpoints (Showing ' +
            this._filterNum +
            ' of ' +
            this._allCallpointsForCaller.length +
            ' total)';
         this.hasFilters = true;
      } else {
         this.gridTitle = 'Callpoints';
         this.hasFilters = false;
      }
   }

   public trackByFn(index, item) {
      return item.guid; // index or item.id
   }

   public onHeaderCheckboxToggle(event) {
      this.allRowsSelected.emit(event);
   }

   // Saves the selected visible grid colunms on the callpoints store to be retrieved the next time the grid is loaded
   public setNewColumnSelection(event: any) {
      this._applicationStore.callpointsStore.selectedCols = this.cols;

      // save the selected columns to local storage
      localStorage.setItem('callpointsSelectedCols', JSON.stringify(this.cols));
   }

   public formatDateClosed(callpoint: Callpoint): string {
      return callpoint.datesClosed.join(',').toUpperCase();
   }

   private subscribeToCallPoints() {
      this._callpointsSubscription = this._applicationStore.callpointsStore.callpoints$.subscribe(
         (callpoints: Callpoint[]) => {
            this.totalRecords = callpoints.length;
            this._allCallpointsForCaller = callpoints;
            this._sortedAndFilteredData = this._allCallpointsForCaller.slice();

            setTimeout(() => this.delayDataBinding(callpoints), 30);
         }
      );
   }

   // Javascript is single threaded.
   // when dealing with large sets of callpoints delay the data binding
   // this allows the map to partially render and give the illusion of the page loading faster
   private delayDataBinding(callpoints: Callpoint[]) {
      this.buildFirstPage(callpoints);

      this.comboFilterDataMap.set(
         'frequency',
         getComboDataForSpecifiedProperty(
            callpoints,
            callpoint => callpoint.frequency,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'duration',
         getComboDataForSpecifiedProperty(
            callpoints,
            callpoint => callpoint.duration,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'fullyScheduled',
         getComboDataForSpecifiedProperty(
            callpoints,
            callpoint => callpoint.fullyScheduled,
            false,
            ''
         )
      );
   }

   private buildFirstPage(callpoints: Callpoint[]) {
      this.totalRecords = callpoints.length;
      this.callpointList.reset();
      this.callpointList.updateTotalRecords();

      this.callpoints = callpoints.slice(0, this.rows);
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      // Retrieves the selected columns from the callpoints store
      this.cols = this._applicationStore.callpointsStore.selectedCols;

      // The stored columns have stored the old 'code' header name for the first column,
      // change it to match the new header name.
      this.cols[0].header = 'Key';

      //ensure the distance unit is enforced on cashed columns
      let drivedistance = this.cols.find(
         c => c.field == 'driveDistanceToCaller'
      );
      if (drivedistance) {
         drivedistance.header = this.distanceHeader;
      }

      // Force header rename for cached column name.
      let fullyScheduled = this.cols.find(c => c.field == 'fullyScheduled');
      if (fullyScheduled) {
         fullyScheduled.header = 'Scheduled Status';
      }

      this.allCols = [
         {
            field: 'reference',
            header: 'Key',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'name',
            header: 'Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'frequency',
            header: 'Frequency',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'duration',
            header: 'Duration',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'driveTimeToCallerHms',
            header: 'Drive Time to Caller (hh:mm)',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'driveDistanceToCaller',
            header: this.distanceHeader,
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'fullyScheduled',
            header: 'Scheduled Status',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'address1',
            header: 'Address 1',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address2',
            header: 'Address 2',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address3',
            header: 'Address 3',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address4',
            header: 'Address 4',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'address5',
            header: 'Address 5',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'geocode',
            header: 'Postal Code',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'latitude',
            header: 'Latitude',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'longitude',
            header: 'Longitude',
            disabled: false,
            filter: true,
            filterPlaceholder: 'Starts with',
            filterMatchMode: 'startsWith',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'callerTerritory',
            header: 'Caller Key',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'salesValue',
            header: 'Value',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'grade',
            header: 'Category',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'priority',
            header: 'Priority',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'startDay',
            header: 'Start Day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'endDay',
            header: 'End Day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'startBreak',
            header: 'Start Lunch',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'endBreak',
            header: 'End Lunch',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'datesClosed',
            header: 'Dates Closed',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other1',
            header: 'Other 1',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other2',
            header: 'Other 2',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other3',
            header: 'Other 3',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other4',
            header: 'Other 4',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'other5',
            header: 'Other 5',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'startDate',
            header: 'Earliest Visit Date',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'endDate',
            header: 'Latest Visit Date',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'isDisabledText',
            header: 'Enabled/Disabled',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'critical',
            header: 'Critical',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         }
      ];

      this.comboFilterDataMap = new Map<string, any[]>();

      this.columnOptions = [];
      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i]
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }
   }

   private getDataSelectedRange(rowIndex: number) {
      let rangeOfCallPoints: Callpoint[] = [];

      let rangeStart, rangeEnd;

      if (this.callpointList.anchorRowIndex > rowIndex) {
         rangeStart = rowIndex;
         rangeEnd = this.callpointList.anchorRowIndex;
      } else if (this.callpointList.anchorRowIndex < rowIndex) {
         rangeStart = this.callpointList.anchorRowIndex;
         rangeEnd = rowIndex;
      } else {
         rangeStart = rowIndex;
         rangeEnd = rowIndex;
      }

      for (let i = rangeStart; i <= rangeEnd; i++) {
         let rangeRowData = this.callpointList.dataToRender[i];
         rangeOfCallPoints.push(rangeRowData);
      }

      return rangeOfCallPoints;
   }

   // store the users selected columns, dictionary of field & description needed for export
   private buildExportColumnsDictionary() {
      if (this._cols) {
         let exportColumns = new Map<string, string>();
         this._cols.forEach(c => {
            exportColumns.set(c.field, c.header);
         });
         this._applicationStore.callpointsStore.callpointExportColumns = exportColumns;
      }
   }

   private getTableHeaderHeightValues() {
      let tableDivContainer = this.callpointList.el.nativeElement
         .firstElementChild;
      let headerElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-header ui-widget-header'
      )[0];
      this.headerHeight = headerElement.clientHeight;

      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      this.tableHeaderHeight = tableHeaderlement.clientHeight;

      let tableElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-scrollable-view'
      )[0];
      this.tableHeight = tableElement.clientHeight;
   }

   public onColResize(event) {
      let x = event;
   }
}
