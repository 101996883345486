export class CalendarDay{
   date : Date;
   isPart1Open: boolean = false;
   isPart2Open: boolean = false;
   content: string;

   public isOpen(): boolean {
      return this.isPart1Open && this.isPart2Open;
   }

   public isClosed(): boolean {
      return !this.isPart1Open && !this.isPart2Open;
   }

   public isPart1OpenOnly(): boolean {
      return this.isPart1Open && !this.isPart2Open;
   }

   public isPart2OpenOnly(): boolean {
      return !this.isPart1Open && this.isPart2Open;
   }
}
