import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { CallpointSettingsViewModel } from 'app/models/view-models/callpoint-settings-view';
import { Callpoint } from 'app/models/callpoint';
import { DayCombination } from 'app/models/settings/day-combination';

/**
 * Dialog box component containing the callpoint settings.
 */
@Component({
   selector: 'edit-callpoint-settings-dialog',
   templateUrl: 'edit-callpoint-settings-dialog.component.html'
})
export class EditCallpointSettingsDialogComponent {

   @ViewChild('editCallpointSettings') editCallpointSettings;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Callpoint name and reference displayed in the dialog header.
   @Input() callpointHeading: string;

   // Settings model used by this dialog.
   @Input() callpointSettingsModel: CallpointSettingsViewModel;

   // Project available days
   @Input() public projectActiveWorkingDays: boolean[] = [];

   // All possible day combinations
   @Input() public allPossibleDayCombinations: DayCombination[] = [];

   // Recommended combinations (string value) //TODO turn into a normal public array (no GET/SET )
   @Input() public recommendedDayCombinations: string[] = [];

   // Determine if editing multiple callpoints.
   @Input() editingMultipleCallpoints: boolean = false;

   @Input() public projectCycleLength:number;


   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<Callpoint>();

   // Notifies the client when the dialog was closed with save button when editing multiple callpoints.
   @Output() multiCallpointsSaved = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   constructor(
      private _applicationStore: ApplicationStore,
      private _windowService: BrowserWindowService) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallpointSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Called when the user clicks on the Save button.
   public onSave(form: NgForm) {
      this.display = false;

      let callpoint: Callpoint = this.callpointSettingsModel.getCallpoint();
      let callpointSettings: CallpointSettings = this.callpointSettingsModel.getCallpointSettings();

      if (this.callpointSettingsModel instanceof CallpointSettingsViewModel){
         if(!this.callpointSettingsModel.availabilityDisabled){
            let startHours = callpointSettings.availability[0].getHours();
            let startMinutes = callpointSettings.availability[0].getMinutes()
            callpoint.startDay = Number( (startHours < 10 ? '0' + startHours : startHours) + "" + (startMinutes < 10 ? '0' + startMinutes : startMinutes));
            let endHours = callpointSettings.availability[1].getHours();
            let endMinutes = callpointSettings.availability[1].getMinutes()
            callpoint.endDay = Number( (endHours < 10 ? '0' + endHours : endHours) + "" + (endMinutes < 10 ? '0' + endMinutes : endMinutes));
         }
         else {
            callpoint.startDay = 0;
            callpoint.endDay = 0;
         }
      }

      if (callpointSettings) {
         callpoint.callpointSettings = callpointSettings;
         callpoint.callpointSettings.callpointId = callpoint.callpointId;
      }

      if (this.editingMultipleCallpoints) {
         this.multiCallpointsSaved.next(callpoint);
      }
      else {
         this._applicationStore.callpointsStore.updateCallpoints([callpoint]);
         this.saved.next(callpoint);
      }
   }

   // Show the save button if a single callpoint is being edited or if multiple callpoints are being edited
   // and the user has selected at least one multi edit checkbox.
   public showSaveButton(): boolean {
      if (this.editingMultipleCallpoints) {
         return this.callpointSettingsModel.availabilityMultiEdit ||
            this.callpointSettingsModel.frequencyMultiEdit ||
            this.callpointSettingsModel.durationMultiEdit ||
            this.callpointSettingsModel.locationMultiEdit ||
            this.callpointSettingsModel.priorityMultiEdit ||
            this.callpointSettingsModel.criticalMultiEdit;
      }
      else {
         return true;
      }
   }
}
