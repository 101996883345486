import { JsonObject, JsonProperty } from 'json2typescript';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { Author } from 'app/models/author';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { DateConverter } from 'app/shared/json-converters/date-converter';

import * as moment from 'moment/moment';

/**
 * Model class representing a Project.
 */
@JsonObject
export class Project {
   @JsonProperty('calendarId')
   public calendarId: number = undefined;

   @JsonProperty('projectId')
   public projectId: number = undefined;

   @JsonProperty('name')
   public name: string = undefined;

   @JsonProperty('folder')
   public folder: string = undefined;

   @JsonProperty('description')
   public description: string = undefined;

   @JsonProperty('scheduleStartDate', DateConverter, true)
   public scheduleStartDate: Date = undefined;

   @JsonProperty('modifiedDate', DateConverter, true)
   public modifiedDate: Date = undefined;

   @JsonProperty('callerSettings', CallerSettings, true)
   public callerSettings: CallerSettings = undefined;

   @JsonProperty('callpointSettings', CallpointSettings, true)
   public callpointSettings: CallpointSettings = undefined;

   @JsonProperty('projectSettings', ProjectSettings, true)
   public projectSettings: ProjectSettings = undefined;

   @JsonProperty('author', Author, true)
   public author: Author = undefined;

   @JsonProperty('companyId')
   public companyId: number = undefined;

   @JsonProperty('inUsebyUserId')
   public inUsebyUserId: number = undefined;

   @JsonProperty('inUseHeartBeat', DateConverter, true)
   public inUseHeartBeat: Date = undefined;

   @JsonProperty('temporary')
   public temporary: boolean;

   @JsonProperty('neverOpened')
   public neverOpened: boolean;

   public travelModelId: number;

   public fromCreateNew: boolean;

   //Not JSON mapped
   public userId: number = undefined;

   public get scheduleEndDate(): Date {
      let endDate: Date = new Date(this.scheduleStartDate);

      // scheduleEndDate needs to be calculated from the callcycle length found in the project settings.
      // this work to bring the project settings is still to come
      endDate.setDate(
         endDate.getDate() + (this.projectSettings.callCycleLength * 7 - 1)
      );
      return endDate;
   }

   // the data grid is formatting the data to be a specific format, so standard column filtering will use the underlying data format that is diffrerent to that displaed
   // provide a filter field in the expected format
   public get scheduleStartDateFilter(): string {
      // customised the date format according to the filter grid used in the app
      let isoDateFormatted: string = moment(this.scheduleStartDate).format(
         'YYYY-MM-DD'
      );
      return isoDateFormatted;
   }

   public get scheduleEndDateFilter(): string {
      // customised the date format according to the filter grid used in the app
      let isoDateFormatted: string = moment(this.scheduleEndDate).format(
         'YYYY-MM-DD'
      );
      return isoDateFormatted;
   }

   public get modifiedDateFilter(): string {
      // customised the date format according to the filter grid used in the app
      let isoDateFormatted: string = moment(this.modifiedDate).format(
         'YYYY-MM-DD HH:mm'
      );
      return isoDateFormatted;
   }
}
