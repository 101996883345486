import { Component, OnInit, OnDestroy } from '@angular/core';

import { Caller } from 'app/models/caller';
import { ApplicationStore } from "app/stores/application-store";
import { Subscription } from "rxjs";


@Component({
   selector: 'callsmart-schedule-test-contextual-panel',
   templateUrl: './schedule-test-contextual-panel.component.html'
})
export class ScheduleTestContextualPanelComponent implements OnInit, OnDestroy {
   private _callersSubscription: Subscription;
   callers: ReadonlyArray<Caller> = [];

   get caller(): Caller {
      return this._applicationStore.callersStore.selectedCaller;
   }

   get callerId(): number {
      return this._applicationStore.callersStore.selectedCaller.callerId;
   }

   set callerId(theCallerId) {
      let selectedCaller: Caller = this.callers.filter(c => c.callerId == theCallerId)[0];
      this._applicationStore.callersStore.setSelectedCaller(selectedCaller)
   }

   public constructor(private _applicationStore: ApplicationStore) { }

   public ngOnInit(): void {
      this.subscribeToCallers();
    }

   public ngOnDestroy() {
      this._callersSubscription.unsubscribe()
   }

   public getSchedule(): void {
      this._applicationStore.scheduleStore.loadScheduleForCaller(this.callerId);
      this._applicationStore.scheduleStore.loadDiaryEvents(this.callerId, this._applicationStore.projectsStore.selectedProject.projectId, false);
   }

   public clearSchedule(){
      this._applicationStore.scheduleStore.clearSchedule(this.callerId);
   }

   public clearScheduleUnlocked() {
      this._applicationStore.scheduleStore.clearScheduleUnlocked(this.callerId);
   }

   public quickOptimise(): void {
      this._applicationStore.scheduleStore.generateSchedule(this.callerId, 'Quick');
   }

   public fullOptimise(): void {
      this._applicationStore.scheduleStore.generateSchedule(this.callerId, 'Full');
   }

   private subscribeToCallers() {
      // As part of the initialisation all callers  needs to retrieve for the current project.
      // the caller service updates its callers through a subscription to the project service
      this._callersSubscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            this.callers = callers;
         });
   }
}
