<div>
   <p-dataTable [value]="travelModels" [resizableColumns]="true" [reorderableColumns]="false" selectionMode="single" [(selection)]="selectedTravelModel"
      (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onFilter)="onFilter($event)" [scrollHeight]="scrollHeight"
      [scrollable]="true" #projectdt>
      <p-header *ngIf="showGridCustomHeader">
         <div style="float:left; margin-top: -18px;">
            <h3>{{gridTitle}}</h3>
         </div>

         <div style="float:left; height: 40px; padding-left: 10px;">
            <button id="clearFilter" type="button" class="btn cs-btn-secondary " (click)="onFilterReset()" *ngIf="hasFilters" style="font-weight: bold;">
               <i class="glyphicon glyphicon-remove clear-filter-text"></i>Clear filter</button>
         </div>

         <div style="text-align:right; height: 40px;">
            <button type="button" class="btn cs-btn-primary-inverse" style="height:32px;" (click)="onCreateTravelModel()"> +
               Add </button>
         </div>
      </p-header>

      <p-column [style]="{'width':'40px', 'min-width':'35px', 'padding':'5px'}" selectionMode="multiple" *ngIf="allowMultipleSelection"></p-column>
      <p-column *ngFor="let col of cols; let i = index" [field]="col.field" [header]="col.header" [filter]="col.filter" [filterPlaceholder]="col.filterPlaceholder"
         [filterMatchMode]="col.filterMatchMode" [sortable]="true" [style]="{'overflow':'hidden'}">


      </p-column>

   </p-dataTable>
</div>
