/**
 * Model class representing information to import data to back end.
 */
export class ImportData {
   public originalProjectId: number;
   public tempProjectId: number;
   public importCallers: boolean;
   public importCallpoints: boolean;
   public projectName: string;
   public userId: number;
}