import { throwError as observableThrowError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { JsonConvert } from 'json2typescript';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { DayCombination } from 'app/models/settings/day-combination';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// this service is used purely for data acess CRUD operations to the data base
// the serbice is normally called from with in a store 
@Injectable()
export class SettingsService {

   defaultSettingsUrl = `${environment.baseUrl}api/defaultsettings`;
   accountSettingsUrl = `${environment.baseUrl}api/account`;

   constructor(private http: HttpClient) { }

   public getDefaultCallerSettings() {
      let url: string = `${this.defaultSettingsUrl}/callersettings`;
      return this.http.get<CallerSettings>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callerSettingsObject: CallerSettings;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  callerSettingsObject = jsonConvert.deserialize(response, CallerSettings);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callerSettingsObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateUserCallerSettings(data: CallerSettings) {
      let url: string = `${this.defaultSettingsUrl}/callersettings`;
      let transformedData = this.transformSettingsObject<CallerSettings>(data);

      return this.http.put(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getDefaultCallPointSettings() {
      let url: string = `${this.defaultSettingsUrl}/callpointsettings`;

      // There is only one global (default) settings object per project. So there is no point to this method returning
      // an array.
      return this.http.get<CallpointSettings>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callpointSettingsObject: CallpointSettings;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  callpointSettingsObject = jsonConvert.deserialize(response, CallpointSettings);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callpointSettingsObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateUserCallPointSettings(data) {
      let url: string = `${this.defaultSettingsUrl}/callpointsettings`;

      let jsonConvert: JsonConvert = new JsonConvert();
      let serializedData = jsonConvert.serialize(data)

      let transformedData = this.transformSettingsObject<CallpointSettings>(serializedData);
      return this.http.put(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getAllDayCombinations(): any {
      let url: string = `${this.defaultSettingsUrl}/alldaycombinations`;

      return this.http.get<DayCombination>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let dayCombinationObjects: DayCombination[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  dayCombinationObjects = jsonConvert.deserialize(response, DayCombination);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }

               return dayCombinationObjects;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getRecommendedDayCombinations(): any {
      let url: string = `${this.defaultSettingsUrl}/recommendeddaycombinations`;

      return this.http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateUserProjectSettings(data) {
      let url: string = `${this.defaultSettingsUrl}/projectsettings`;

      return this.http.put<any>(url, data)
         .pipe(retry(3)).pipe(
            map(() => {
               return true
            }),
            catchError(error => observableThrowError(error)));
   }

   public getUserProjectSettings() {
      let url: string = `${this.accountSettingsUrl}/projectsettings`;

      return this.http.get<ProjectSettings>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let projectSettingsObject: ProjectSettings;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  projectSettingsObject = jsonConvert.deserialize(response, ProjectSettings);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return projectSettingsObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getUserCallPointSettings() {
      let url: string = `${this.accountSettingsUrl}/callpointsettings`;
      return this.http.get<CallpointSettings>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callpointSettingsObject: CallpointSettings;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  callpointSettingsObject = jsonConvert.deserialize(response, CallpointSettings);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callpointSettingsObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getUserCallerSettings() {
      let url: string = `${this.accountSettingsUrl}/callersettings`;
      return this.http.get<CallerSettings>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let callerSettingsObject: CallerSettings;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  callerSettingsObject = jsonConvert.deserialize(response, CallerSettings);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return callerSettingsObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   private transformSettingsObject<T>(modelClass: T): T {
      // This is the most efficient way to deep clone an object in JavaScript
      // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      let clonedObject: T = JSON.parse(JSON.stringify(modelClass))

      CallsmartUtils.transformObjectDates(clonedObject);
      return clonedObject;
   }

}
