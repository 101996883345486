<div>
   <form novalidate>
      <callsmart-wizard wizardTitle="Import new data" [(activeIndex)]="activeIndex" (change)="onChange($event)" (finish)="onFinish()"
         (cancel)="onCancel()" [finishButtonLabel]="finishButtonLabel" #importDataWizard>
         
         <callsmart-step *ngIf="importOptions.importCallers" label="Callers" [formValid]="importDataCallers?.formValid">
            <div class="cs-step-panel" style="width:100%;">
               <callsmart-new-project-callers #importDataCallers 
                  [componentHeight]="importDataWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"
                  (uploadComplete)="onCallerUploadComplete()">
               </callsmart-new-project-callers>
            </div>
         </callsmart-step>

         <callsmart-step *ngIf="importOptions.importCallpoints" label="Callpoints" [formValid]="importDataCallpoints?.formValid">         
            <div class="cs-step-panel" style="width:100%;">
               <callsmart-new-project-callpoints #importDataCallpoints 
                  [componentHeight]="importDataWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"
                  [importDataMode]="true"
                  (uploadComplete)="onCallpointUploadComplete()">
               </callsmart-new-project-callpoints>
            </div>
         </callsmart-step>

         <callsmart-step label="Summary" [formValid]="importDataSummary?.formValid">
            <div class="cs-step-panel" style="width: 100%;">
               <import-data-summary #importDataSummary 
                  [componentHeight]="importDataWizard?.workspaceHeight" 
                  [projectWizardModel]="projectWizardModel"
                  (callers)="gotoCallers()" 
                  (callPoints)="gotoCallpoints()">
               </import-data-summary>
            </div>
         </callsmart-step>
      </callsmart-wizard>
   </form>
</div>
