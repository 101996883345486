import { Pipe, PipeTransform } from '@angular/core';

// truncate text to a certain length then  add ..
// length is always 14 including the ..

@Pipe({ name: 'carouselNameTruncation' })
export class CarouselNameTruncation implements PipeTransform {
   transform(value: string): string {
      const limit = 14;
      const trail =  '..';

      return value.length > limit ? value.substring(0, limit-2) + trail : value;
     }
   }
