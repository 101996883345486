<div class="panel small-text cs-color-white contextualPanel-background-color">
   <div *ngIf="callpoint !== null; else noCallpointSelected">
      <h2 class="contextualPanel-header-color" style="text-overflow: ellipsis; white-space: nowrap;overflow: hidden;" pTooltip="{{callpoint?.name}}, {{callpoint?.reference}}">
         {{callpoint?.name}}, {{callpoint?.reference}}
      </h2>
   </div>
   <ng-template #noCallpointSelected>
      <h3 class="contextualPanel-header-color">
         No callpoint selected
      </h3>
   </ng-template>
   <div [ngClass]="{'cs-block-ui': callpoint === null}">
      <div class="btn-group" style="margin-top: 10px; margin-bottom: 10px; display: table;overflow:auto; width:100%;">
         <button type="button" class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span style="float: left">Actions</span>
            <span class="cs-icon icon-arrow-down dropdown-button-icon"></span>
         </button>
         <ul class="dropdown-menu cs-dropdown-inverse" style="width:218px;">
            <li>
               <a (click)="editCallpoint()">
                  <span style="margin-right:5px;" class="cs-icon icon-edit"></span>
                  Edit callpoint
               </a>
            </li>
            <li>
               <a (click)="editCalendar()">
                  <span style="margin-right:5px;" class="cs-icon icon-circle-backslash"></span>
                  Edit calendar
               </a>
            </li>
            <li>
               <a (click)="reassignCaller()">
                  <span style="margin-right:5px;" class="cs-icon icon-edit"></span>
                  Reassign
               </a>
            </li>
            <li style="border-left: #fff76d solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDay || fullyDeferredCallpoints}">
               <a (click)="lockCallpointVisitsToDay()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDay || fullyDeferredCallpoints}">
                  <span style="margin-right:5px;" class="cs-icon icon-locked-5"></span>
                  Lock visits to day
               </a>
            </li>
            <li style="border-left: #e90000 solid 4px;" [ngClass]="{'disabled': areAllVisitsLockedToDayTime || fullyDeferredCallpoints}">
               <a (click)="lockCallpointVisitsToDayTime()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : areAllVisitsLockedToDayTime || fullyDeferredCallpoints}">
                  <span style="margin-right:5px;" class="cs-icon icon-safe"></span>
                  Lock visits to day & time
               </a>
            </li>
            <li [ngClass]="{'disabled': !areAllVisitsLocked || fullyDeferredCallpoints}">
               <a (click)="unlockCallpointVisits()" style="padding-left:16px" [ngClass]="{'callsmart-link-no-active' : !areAllVisitsLocked || fullyDeferredCallpoints}">
                  <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
                  Unlock visits
               </a>
            </li>
            <li [ngClass]="{'disabled': areAllCallpointsDisabled}">
               <a (click)="disableCallpoints()" style="padding-left:16px">
                  <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
                  Disable Callpoints
               </a>
            </li>
            <li [ngClass]="{'disabled': areAllCallpointsEnabled}">
               <a (click)="enableCallpoints()" style="padding-left:16px">
                  <span style="margin-right:5px;" class="cs-icon icon-placeholders"></span>
                  Enable Callpoints
               </a>
            </li>
         </ul>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Caller:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="caller !== null; else noName" class="display-flex-column display-flex-first-column">
                  {{caller?.name}}
               </div>
               <ng-template #noName>
                  - -
               </ng-template>
            </div>
         </div>
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Frequency:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="callpoint !== null; else noFrequency" class="display-flex-column display-flex-first-column">
                  {{callpoint?.frequency}}
               </div>
               <ng-template #noFrequency>
                  - -
               </ng-template>

            </div>
         </div>
      </div>
      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Duration:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="callpoint !== null; else noDuration" class="display-flex-column display-flex-first-column">
                  {{callpoint?.duration}} mins
               </div>
               <ng-template #noDuration>
                  - -
               </ng-template>

            </div>
         </div>
         <div class="no-padding contextualPanel-list-items" pTooltip="Overriden from project default" tooltipPosition="top" [tooltipDisabled]="!isAvailabilityOverriden()">
            <div class="contextualPanel-list-items-firstline">Availability:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="callpoint !== null; else noStatus" class="display-flex-column display-flex-first-column" [ngClass]="{'contextualPanel-overriden-color': isAvailabilityOverriden()}">
                  {{getHoursAndMinutes(callpointSettingsViewModel?.availability[0])}} - {{getHoursAndMinutes(callpointSettingsViewModel?.availability[1])}}
               </div>
               <ng-template #noStatus>
                  - -
               </ng-template>

            </div>
         </div>
      </div>
   </div>
</div>

<!-- This is where callpoint settings dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showCallpointSettings"
             [ndcDynamicComponent]="editCallpointSettingsDialog"
             [ndcDynamicInputs]="dialogInput"
             [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>

<!-- This is where callpoint settings dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showEditCalendar"
             [ndcDynamicComponent]="editCalendarDialog"
             [ndcDynamicInputs]="editCalendarDialogInput"
             [ndcDynamicOutputs]="editCalendarDialogOutput">
</ndc-dynamic>

<!-- This is where reassign callpoint  dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showReassignCallpointDialog"
             [ndcDynamicComponent]="reassignCallpointDialogComponent"
             [ndcDynamicInputs]="reassignCallpointDialogInput"
             [ndcDynamicOutputs]="reassignCallpointDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showAlertDialog" [ndcDynamicComponent]="alertDialog" 
                                     [ndcDynamicInputs]="alertDialogInput" 
                                     [ndcDynamicOutputs]="alertDialogOutput">
</ndc-dynamic>
