import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Alert } from 'app/models/alert';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

//Alert store is resonsiple for showing the custom alert box styled up and created on the app.compnent,html

@Injectable()
export class AlertStore {

   // Alerts.
   private _alert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>(null);
   public alert$: Observable<Alert> = this._alert.asObservable();

   constructor() {
   }

   // Sends a alert to be displayed.
   public sendAlert(alert: Alert) {
      this._alert.next(alert);
   }
}
