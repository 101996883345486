<!-- Slider main container -->
<div class="carousel-container" id="carousel-container">
   <div class="swiper-container" data-toggle="tooltip" data-placement="bottom" >
      <!-- pTooltip="You can also use the keyboard arrows to navigate between Callers." tooltipPosition="bottom" -->
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper" #swiperwrapper>
         <!-- Slides -->
         <div class="swiper-slide" [ngClass]="{'slide-active': c.isActive, 'slide-hover' : !c.isActive}" *ngFor="let c of callers"
            (click)="onSliderClick($event, c, swiperwrapper)">
            <i class="cs-icon icon-profile swiper-slide-icon "></i>
            <span class="font-b4-semi-bold swiper-slide-text"> {{c.name | carouselNameTruncation}}</span>
         </div>
      </div>

   </div>
   <!-- Add Arrows -->
   <div class="swiper-button-next" id="carousel-next"><i class="cs-icon-slider icon-arrow-right" aria-hidden="true"></i></div>
   <div class="swiper-button-prev" id="carousel-previous"><i class="cs-icon-slider icon-arrow-left" aria-hidden="true"></i></div>

   <hr style="margin-top:0px; margin-bottom:0px;">
</div>
