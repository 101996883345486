<p-dialog header="Delete project" [(visible)]="display" modal="true" width="700" positionTop="200" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;">

      <div style="margin-top:5px;margin-bottom:5px;">
          Are you sure you want to permanently delete this project?
      </div>

      <div class="black-datatable no-horizontal-scrollbar" style="margin-top:20px; margin-bottom:40px;">
         <p-dataTable [value]="projects" [resizableColumns]="false" [reorderableColumns]="false" selectionMode="single" [scrollable]="false">

            <p-column field="name" header="Name" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>

            <p-column field="author.fullname" header="Owner" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>

            <p-column field="scheduleStartDate" header="Cycle start" [style]="{'width':'10%', 'overflow':'visible'}">
               <ng-template let-row="rowData" pTemplate="body">
                  {{row.scheduleStartDate | date:'d/M/y'}}
               </ng-template>
            </p-column>

            <p-column field="scheduleEndDate" header="Cycle end" [style]="{'width':'10%', 'overflow':'visible'}">
               <ng-template let-row="rowData" pTemplate="body">
                  {{row.scheduleEndDate | date:'d/M/y'}}
               </ng-template>
            </p-column>

         </p-dataTable>
      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onDelete()">Delete</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
