<p-dialog [header]="headerText" [(visible)]="display" modal="true" width="700" positionTop="50" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;" autocomplete="off">
      <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
         <div>
            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Name</h6>
                  <div class="form-group">
                     <div class="col-sm-11">
                        <input type="name" id="name" #name=ngModel name="name" required [ngModel]="travelModel?.travelModelName" class="form-control cs-form-control">
                     </div>
                  </div>
               </div>

               <div class="panel-body">
                  <h6>Description</h6>
                  <div class="form-group">
                     <div class="col-sm-11">
                        <input type="description" name="travelModelDescription" [ngModel]="travelModel?.travelModelDescription" class="form-control cs-form-control">
                     </div>
                  </div>
               </div>

               <div class="panel-body">
                  <div class="form-group" style="padding-left: 15px;">
                     <p-checkbox name="geocodeData" [ngModel]="travelModel?.geocodeData" binary="true" label="Geocode data" >
                     </p-checkbox>
                  </div>
               </div>

            </div>


         </div>
      </perfect-scrollbar>
      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!form.valid"  (click)="onSave(form)">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
