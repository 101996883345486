import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { ProjectSettings } from 'app/models/settings/project-settings';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

@Component({
   templateUrl: './user-project-settings.component.html'
})
export class UserProjectSettingsComponent implements OnInit, CanComponentDeactivate {

   private _settings_subscription: Subscription;

   public scrollHeight: number;

   public projectSetting = new ProjectSettings();

   // Drives the enablement of the save button
   public formValid: boolean = false;

   @ViewChild('editProjectSettings') editProjectSettings;

   constructor(private _applicationStore: ApplicationStore,
               private windowService: BrowserWindowService,
               private _router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 185;
      });
   }

   ngOnInit() {
      this.subscribeToUserDefaultProjectSettings();
      this._applicationStore.userProjectSettingsStore.loadUserProjectSettings();
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editProjectSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editProjectSettings.projectSettingsForm.pristine;
    }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editProjectSettings.projectSettingsForm.form.markAsPristine();

      this.projectSetting.underUtilisation = Math.round(this.editProjectSettings.projectSetting.utilisationDefinition[0]);
      this.projectSetting.overUtilisation = Math.round(this.editProjectSettings.projectSetting.utilisationDefinition[1]);

      this._applicationStore.userProjectSettingsStore.updateUserProjectSettings(this.projectSetting);
   }

   public onCancel() {
      if(this._applicationStore.projectsStore.selectedProject === null) {
         this._router.navigate(['/open-project']);
      }
      else {
         this._router.navigate(['/dashboard']);
      }
   }

   private subscribeToUserDefaultProjectSettings() {
      this._settings_subscription = this._applicationStore.userProjectSettingsStore.defaultProjectSettings$.subscribe(
         (settings: ProjectSettings) => {
            if (settings !== null) {
               this.projectSetting = CallsmartUtils.deepClone<ProjectSettings>(settings, ProjectSettings);
               this.projectSetting.utilisationDefinition = [this.projectSetting.underUtilisation, this.projectSetting.overUtilisation];
            }
         }
      );
   }

}
