import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';

import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';
import { environment } from 'environments/environment';
import { CallsmartError } from 'app/models/callsmart-error';

/**
 * Service for handling the HTTP response errors mainly. Determines what to do the error and how to
 * display it.
 */
@Injectable()
export class ErrorHandlerService {

   public forceLogOut: Subject<any> = new Subject<any>();

   constructor(private _router: Router, private _alertStore: AlertStore, private _ngZone: NgZone) { }

   public handleError(error) {
      if (error["url"] != null) {
         // The error came from a HTTP response, handle that.
         this.handleServiceApiError(error);
      }
      else {
         // Error was thrown by some local code, handle that.
         this.handleLocalError(error);
      }
      console.log(error);
   }

   private handleServiceApiError(error: any) {

      // if unauthroised then return to the login screen
      if (error.status == 401 || error.status == 403) {
         this.forceLogOut.next(null);
         return;
      }
      // if ERR_CONNECTION_REFUSED
      else if(error.status == 0){
         this._alertStore.sendAlert(new Alert('Error',  'Attempt to connect to a remote server failed. Please contact your administrator.'));
         return;         
      }

      if (error.error.validationCode > 0) {         
         this._alertStore.sendAlert(new Alert('Error', error.error.message));
      }
      else {
         if (environment.production) {
            // In production only show generic error message.
            this._alertStore.sendAlert(new Alert('Error', 'There was an error processing your request. Please contact your administrator.'));
         }
         else {
            // In dev, show more detail.
            let callsmartError: CallsmartError = new CallsmartError();
            callsmartError.status = error.status;
            callsmartError.statusText = error.statusText;
            callsmartError.url = error.url;
            callsmartError.stackTraceMsg = error.error.message;
            callsmartError.stackTraceDetail = error.error.detail;
            let errorMessage: string = JSON.stringify(callsmartError);
            this._ngZone.run(() => {
               this._router.navigate(['/error'], { queryParams: { error: errorMessage } })
            });
         }
      }
   }

   private handleLocalError(error: any) {
      if (environment.production) {
         this._alertStore.sendAlert(new Alert('Error', error.message ? error.message : error + 'Please contact your administrator.'));
      }
      else {
         let callsmartError: CallsmartError = new CallsmartError();
         callsmartError.statusText = error.message ? error.message : error;
         let errorMessage: string = JSON.stringify(callsmartError);
         this._ngZone.run(() => {
            this._router.navigate(['/error'], { queryParams: { error: errorMessage } })
         });
      }
   }
}
