<div style="display: inline-block; width: 100%">
   <h6 style="float:left;">Project settings</h6>
   <div style="float: right; margin-top: 6px;">
      <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
         aria-hidden="true" (click)="editSettings()"></i>
   </div>
</div>
<div *ngIf="projectSettings">
   <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-77}">

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Optimiser iterations:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div>
                     <div style="float:left; padding-right:5px; font-weight:normal;">Fast:</div>
                     <div style="float:left; padding-right:10px;">{{projectSettings.quickOptimiserIteration}}</div>

                     <div style="float:left; padding-right:5px; font-weight:normal;">Full:</div>
                     <div>{{projectSettings.fullOptimiserIteration}}</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Optimiser driving settings:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div *ngIf="projectSettings.minTotalDrivingSelected">
                     <div style="float:left; padding-right:5px; font-weight:normal;">Least total driving</div>
                  </div>
                  <div *ngIf="projectSettings.minDrivingBetweenVisitsSelected">
                     <div style="float:left; padding-right:5px; font-weight:normal;">Least driving between visits</div>
                  </div>
                  <div *ngIf="projectSettings.optimiserDrivingConfigCustomSelected">
                     <div style="float:left; padding-right:5px; font-weight:normal;">Custom</div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Utilisation:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div>
                     <div style="float:left; padding-right:5px; font-weight:normal;">Under:</div>
                     <div style="float:left; padding-right:10px;">{{projectSettings.underUtilisation}}%</div>

                     <div style="float:left; padding-right:5px; font-weight:normal;">Over:</div>
                     <div>{{projectSettings.overUtilisation}}%</div>
                  </div>
               </div>
            </div>
         </div>
      </div> -->

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Distance units:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{projectSettings.distanceUnitMiles ? 'Miles' : 'Kilometres'}}
               </div>
            </div>
         </div>
      </div>

      <!-- <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Currency:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div>
                     <div style="float:left; padding-right:5px" [innerHTML]="projectSettings.currency | currencySymbol"></div>
                     <div> - {{projectSettings.currency | uppercase}}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Driving cost per {{projectSettings.distanceUnitMiles ? 'mile' : 'kilometre'}}:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div> {{projectSettings.drivingCostPerUnit | currency: projectSettings.currency:'symbol-narrow'}}</div>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">{{projectSettings.distanceUnitMiles ? 'Miles' : 'Kilometres'}} per gallon:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{projectSettings.averageUnitPerGallon}} {{projectSettings.distanceUnitMiles ? 'mpg' : 'kpg'}}
               </div>
            </div>
         </div>
      </div> -->

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Average vehicle CO
               <sub>2</sub> emissions:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{projectSettings.averageVehicleEmission}} g/Km
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Travel model:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{projectSettings.travelModel.travelModelName}}
               </div>
            </div>
         </div>
      </div>
   </perfect-scrollbar>
</div>

<!-- This is where project settings dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showSettings"
   [ndcDynamicComponent]="editProjectSettingsDialog"
   [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
