<p-dialog [width]="scrollWidth" [(visible)]="display" modal="modal" [draggable]="false" [resizable]="false"
          [showHeader]="false">   
   <div [ngStyle]="{'height.px': scrollHeight}">
      <img class="splash-logo" src="./assets/icons/CS_Logo_large_OnBlack.png" style="top:45%;margin-left:-60px" width="120" height="30"/>      
      <div class="spinner" style="top:35%; left: 45%;">
         <div class="bounce1"></div>
         <div class="bounce2"></div>
         <div class="bounce3"></div>
      </div>
      <span style="text-align: center;top: 60%;position: absolute;left: 36%;margin-top: 20px;">One moment please...</span>
   </div>   
</p-dialog>
