import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Company } from 'app/models/company';
import { User } from 'app/models/user';
import { AdminProjectListComponent } from '../admin-project-list/admin-project-list.component';
import { CloneProjectDialogComponent } from '../clone-project-dialog/clone-project-dialog.component';

@Component({
  selector: 'callsmart-admin-project-panel',
  templateUrl: './admin-project-panel.component.html',
})
export class AdminProjectPanelComponent implements OnInit {

  @ViewChild(AdminProjectListComponent)
  public projectList: AdminProjectListComponent;

  public scrollHeight: string = "200px";
  public workspaceHeight: number;
  public companyList = [];
  public selectedCompany: Company;
  private _companies_subscription: Subscription;

////////////////////////////////////////////////////////////////////

   // Determines whether to display the  dialog.
   public showCloneProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public cloneProjectDialog = CloneProjectDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public cloneDialogInput = {
      display: false,
      project: null,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public cloneDialogOutput = {
      clone: () => this.onCloneEvent(),
      cancel: () => this.onCancelCloneEvent(),
   };

   ////////////////////////////////////////////////////////////////////

  constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService) {
    //subscribe to the window resize event
    windowService.height$.subscribe((value: number) => {
      this.resizeWorkspace(value);
   });
   }

  ngOnInit() {
    this._companies_subscription = this._applicationStore.sysAdminStore.companies$.subscribe(
      (companies: ReadonlyArray<Company>) => {
         this.configureCompanyData(companies);
      });

    this.companyChanged();
  }

  public ngOnDestroy(): void {
    if (this._companies_subscription) {
       this._companies_subscription.unsubscribe();
    }
  }

  public get loggedInUser(): User {
    return this._applicationStore.authenticationStore.loggedInUser;
  }

  public companyChanged()
  {       
    this._applicationStore.sysAdminStore.loadProjects(this.selectedCompany);
  }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
    this.workspaceHeight = browserHeight - 220;
    this.scrollHeight = this.workspaceHeight * 0.80 + 'px';
  }

  private configureCompanyData(companies: ReadonlyArray<Company>) {
    companies.forEach(company =>  this.companyList.push( { label: company.companyName, value: company }));
    // defualt selection to first in the list
    if (companies.length > 0) {
      this.selectedCompany = companies[0];
    }
  }

  public onCloneProject() {
    this.showCloneProjectDialog = true;
    this.cloneDialogInput.display = true;
    this.cloneDialogInput.project = this.projectList.selectedProjects;
  }

  // cancel button from the clone project dialog
  public onCancelCloneEvent() {
    this.showCloneProjectDialog = false;
    this.cloneDialogInput.display = false;
 }

 // clone button from the clone project dialog
 public onCloneEvent() {
    this.showCloneProjectDialog = false;
    this.cloneDialogInput.display = false;
    if (this.companyList.length > 0) {
      this.selectedCompany = this.companyList[0].value;
    }
 }
}