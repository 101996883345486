import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ErrorData } from 'app/models/error-data';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

//Error store is responcable for showing the custom error box styled up and created on the app.compnent,html

@Injectable()
export class ErrorStore {

   // Errors.
   private _error: BehaviorSubject<ErrorData> = new BehaviorSubject<ErrorData>(null);
   public error$: Observable<ErrorData> = this._error.asObservable();

   public errorDialogShown: Subject<any> = new Subject<any>();

   constructor() {
   }

   // Sends a Error to be displayed.
   public sendError(errorData: ErrorData) {
      this._error.next(errorData);
   }

   public notify() {
      this.errorDialogShown.next(this._error.getValue().errorSource);
   }
}
