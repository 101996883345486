import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Project } from 'app/models/project';
import { ExportParameters } from 'app/models/export-parameters';

/*
   select the export options schedule . callers callpoints used on the dashbaord screen
*/

@Component({
   selector: 'callsmart-export-dialog',
   templateUrl: './export-dialog.component.html'
})
export class ExportDialogComponent {
   public exportOptions = {
      exportSchedules: false,
      exportSchedulesInSingleFiles: true,
      exportCallers: false,
      exportCallpoints: false
   };

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() export = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   public constructor(
      private _windowService: BrowserWindowService,
      private _applicationStore: ApplicationStore
   ) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   public onExport(form: NgForm) {
      // check what export options have been chosen
      if (form.value.exportSchedules) {
         if (form.value.exportSchedulesInSingleFiles) {
            this._applicationStore.callersStore.exportAllCallersSchedules(
               this._applicationStore.projectsStore.selectedProject.projectId
            );
         } else {
            this.exportEachCallersScheduleInASingleFile();
         }
      }

      if (form.value.exportCallers) {
         // set up the parameters the columns, null for the caller as we want all of them
         let exportCParameters = new ExportParameters(
            Array.from(this._applicationStore.callersStore.callerExportColumns),
            null
         );
         this._applicationStore.callersStore.exportAllCallersData(
            this._applicationStore.projectsStore.selectedProject.projectId,
            exportCParameters
         );
      }

      if (form.value.exportCallpoints) {
         // set up the parameters the columns, null for the points as we want all of them
         // always add the assigned caller or the user will never be avle to tell whos callpoints belong to who
         this._applicationStore.callpointsStore.callpointExportColumns.set(
            'callerTerritory',
            'Assigned Person'
         );
         let exportCPParameters = new ExportParameters(
            Array.from(
               this._applicationStore.callpointsStore.callpointExportColumns
            ),
            null
         );

         this._applicationStore.callpointsStore.exportAllCallpointData(
            this._applicationStore.projectsStore.selectedProject.projectId,
            exportCPParameters,
            this._applicationStore.callersStore.selectedCaller.territory
         );
      }

      this.display = false;
      this.export.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   private exportEachCallersScheduleInASingleFile() {
      this._applicationStore.callersStore.callers;
      this._applicationStore.callersStore.callers.forEach(caller => {
         this._applicationStore.callersStore.exportCallerSchedule(
            this._applicationStore.projectsStore.selectedProject.projectId,
            caller.callerId,
            caller.name
         );
      });
   }
}
