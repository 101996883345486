<p-dialog header="Delete travel model" [(visible)]="display" modal="true" width="700" positionTop="200" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;">

      <div style="margin-top:5px;margin-bottom:5px;">
         Are you sure you want to permanently delete this travel model?
      </div>

      <div class="black-datatable no-horizontal-scrollbar" style="margin-top:20px; margin-bottom:40px;">
         <p-dataTable [value]="travelModels" [resizableColumns]="false" [reorderableColumns]="false" selectionMode="single" [scrollable]="false">

            <p-column field="travelModelName" header="Travel Model Name" [style]="{'width':'20%', 'overflow':'visible'}">
            </p-column>


         </p-dataTable>
      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onDelete()">Delete</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
