import * as moment from 'moment/moment';

import { Project } from 'app/models/project';
import { TravelModel } from 'app/models/travelModel';

/**
 * View model class for the Project properties - settings page
 */
export class ProjectSettingsViewModel {

   public projectId: number = undefined;
   public calendarId: number = undefined;
   public projectSettingsId: number = undefined;

   public name: string = undefined;
   public folder: string = undefined;
   public scheduleStartDate: string = undefined;
   public scheduleEndDate: string = undefined;
   public callCycleLength: number = undefined;
   public quickOptimiserIteration: number =undefined;
   public fullOptimiserIteration: number = undefined;
   public distanceUnitMiles: boolean = undefined;
   public currency: string = undefined;
   public drivingCostPerUnit: number = undefined;
   public averageUnitPerGallon: number = undefined;
   public averageVehicleEmission: number = undefined;
   public underUtilisation: number = undefined;
   public overUtilisation: number = undefined;
   public utilisationDefinition: number[] = undefined;
   public travelModel: TravelModel = undefined;
   public travelModelId: number = undefined;
   public diaryViewTime: number = undefined;

   public minTotalDrivingSelected: boolean = undefined;
   public minDrivingBetweenVisitsSelected: boolean = undefined;
   public optimiserDrivingConfigCustomSelected: boolean = undefined;
   public idowhShift1: number = undefined;
   public idowhShift2: number = undefined;
   public idowhCommuteAM1: number = undefined;
   public idowhCommuteAM2: number = undefined;
   public idowhCommutePM1: number = undefined;
   public idowhCommutePM2: number = undefined;
   public iterationWeightWH: number = undefined;
   public iterationWeightAM: number = undefined;
   public iterationWeightPM: number = undefined;

   constructor(private _project: Project) {
      this.projectId = this._project.projectId;
      this.calendarId = this._project.calendarId;
      this.projectSettingsId = this._project.projectSettings.projectSettingsId;

      this.name = this._project.name;
      this.folder = this._project.folder;
      this.callCycleLength = this._project.projectSettings.callCycleLength;
      this.quickOptimiserIteration = this._project.projectSettings.quickOptimiserIteration;
      this.fullOptimiserIteration = this._project.projectSettings.fullOptimiserIteration;
      this.distanceUnitMiles = this._project.projectSettings.distanceUnitMiles;
      this.currency = this._project.projectSettings.currency;
      this.drivingCostPerUnit = this._project.projectSettings.drivingCostPerUnit;
      this.averageUnitPerGallon = this._project.projectSettings.averageUnitPerGallon;
      this.averageVehicleEmission = this._project.projectSettings.averageVehicleEmission;
      this.underUtilisation = this._project.projectSettings.underUtilisation;
      this.overUtilisation = this._project.projectSettings.overUtilisation;
      this.diaryViewTime = this._project.projectSettings.diaryViewTime;

      this.utilisationDefinition = [];
      this.utilisationDefinition[0] = this._project.projectSettings.underUtilisation;
      this.utilisationDefinition[1] = this._project.projectSettings.overUtilisation;

      this.travelModel = this._project.projectSettings.travelModel;
      this.travelModelId = this._project.projectSettings.travelModelId;

      this.minTotalDrivingSelected = this._project.projectSettings.minTotalDrivingSelected;
      this.minDrivingBetweenVisitsSelected = this._project.projectSettings.minDrivingBetweenVisitsSelected;
      this.optimiserDrivingConfigCustomSelected = this._project.projectSettings.optimiserDrivingConfigCustomSelected;
      this.idowhShift1 = this._project.projectSettings.idowhShift1;
      this.idowhShift2 = this._project.projectSettings.idowhShift2;
      this.idowhCommuteAM1 = this._project.projectSettings.idowhCommuteAM1;
      this.idowhCommuteAM2 = this._project.projectSettings.idowhCommuteAM2;
      this.idowhCommutePM1 = this._project.projectSettings.idowhCommutePM1;
      this.idowhCommutePM2 = this._project.projectSettings.idowhCommutePM2;
      this.iterationWeightWH = this._project.projectSettings.iterationWeightWH;
      this.iterationWeightAM = this._project.projectSettings.iterationWeightAM;
      this.iterationWeightPM = this._project.projectSettings.iterationWeightPM;
      
      this.initStartEndDates();

   }

   private initStartEndDates() {
      let scheduleStartDateObject = new Date(this._project.scheduleStartDate);
      this.scheduleStartDate = this.formatDate(scheduleStartDateObject);
      // Sets the start cycle date as a string.
      let momentStartDate: moment.Moment = moment(scheduleStartDateObject.getDate() + '-' +
                                                  (scheduleStartDateObject.getMonth() + 1) + '-' +
                                                  scheduleStartDateObject.getFullYear(), "DD/MM/YYYY");
      // Works out the end cycle date.
      let endCycleDate = this.getCycleEndDate(momentStartDate);
      // Sets the end cycle date as a string.
      this.scheduleEndDate = this.formatDate(endCycleDate);
   }

   // Returns the date passed as a parameter as a string with 'dd/mm/yyyy' format.
   private formatDate(date): string {
      var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [day, month, year].join('/');
   }

   // Works out the end cycle date based on the start cycle date and the number of week.
   private getCycleEndDate(startDate: moment.Moment): Date {
      if (this.callCycleLength) {
         let momentEndDate: moment.Moment = startDate.clone().add(this.callCycleLength, 'w').subtract(1, 'd');
         return momentEndDate.toDate();
      }
      return startDate.toDate();
   }
}
