import {
   Component,
   OnInit,
   ViewChild,
   Input,
   OnDestroy,
   Output,
   EventEmitter,
   AfterViewInit,
   DoCheck,
   ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTable } from 'primeng/components/datatable/datatable';
import { SelectItem } from 'primeng/components/common/selectitem';
import { LazyLoadEvent, FilterMetadata } from 'primeng/components/common/api';
import { Caller } from 'app/models/caller';
import { Visit } from 'app/models/visit';
import { ApplicationStore } from 'app/stores/application-store';

import { generateUniqueItems } from 'app/shared/utils/callsmart-grid-util';

declare var jQuery: any;

// This class represents the visits-workspace component which displays
// the visits belonging to a caller grouped by callpoint.
@Component({
   selector: 'callsmart-visits-list-v2',
   templateUrl: './visits-list-v2.component.html'
})
export class VisitsListV2Component
   implements OnInit, OnDestroy, AfterViewInit, DoCheck {
   private _visitsSubscription: Subscription;
   private _sortedAndFilteredData: Visit[];
   private _filterNum: number;
   private _allVisitsForCaller: Visit[] = [];
   private _currentSortField: string;
   private _currentSortOrder: string;

   private _visitTableHeight = '300px';
   @Input()
   public get visitTableHeight(): string {
      return this._visitTableHeight;
   }
   public set visitTableHeight(visitTableHeight: string) {
      this._visitTableHeight = visitTableHeight;
      let scrollHeightStringValue: string = this._visitTableHeight.substr(
         0,
         this._visitTableHeight.length - 2
      );
      let scrollHeightValue: number;
      if (
         Number(scrollHeightStringValue) >= 400 &&
         Number(scrollHeightStringValue) <= 600
      ) {
         scrollHeightValue = Number(scrollHeightStringValue);
      } else if (Number(scrollHeightStringValue) < 400) {
         scrollHeightValue = 400;
      } else {
         scrollHeightValue = 600;
      }
      this.filterColumnPanelHeight = Number(scrollHeightValue) - 72 + 'px'; // 116px is the height of the header of the filter column panel;
   }

   @Input() scrollWidth: string = '200px';

   public filterColumnPanelHeight: string;

   @Output() rowSelectionChanged: EventEmitter<Visit[]> = new EventEmitter<
      Visit[]
   >();
   @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();
   @Output() headerHeight: number;
   @Output() tableHeaderHeight: number;
   @Output() tableHeight: number;

   @ViewChild(DataTable) visitList: DataTable;

   get caller(): Caller {
      return this._applicationStore.callersStore.selectedCaller;
   }

   public previousCheckboxSelection: any[] = [];

   // Gets and sets the selected visits.
   private _selectedVisits: Visit[] = [];
   get selectedVisits(): Visit[] {
      return this._selectedVisits;
   }
   set selectedVisits(visits: Visit[]) {
      if (this.visitList.allSelected && !this.hasFilters && visits.length > 0) {
         this._selectedVisits = this._allVisitsForCaller;
      } else {
         this._selectedVisits = visits;
      }
      this.rowSelectionChanged.emit(this._selectedVisits.slice());
   }

   // Collection of visits retrieved from the service.
   public visits: Visit[] = [];
   public totalRecords: number = 0;
   public rows: number = 30;
   public allCols: any[];
   public cols: any[];
   public columnOptions: SelectItem[];
   public selectedItemsLabel: string = 'Columns';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns';
   public panelTitle: string = 'Columns';
   public gridTitle: string = 'Visits';

   public hasFilters: boolean;
   public filterSelectedValues = [];
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedMultiValues = [];
   // stores the current datatable component filters
   private dtFilters = null;
   private firstLoad = true;

   constructor(private _applicationStore: ApplicationStore, private _cdr: ChangeDetectorRef) { }

   public ngOnInit() {
      if (this._applicationStore.visitsStore.selectedVisits) {
         this._selectedVisits = this._applicationStore.visitsStore.selectedVisits.slice();
      }
      this.configureTableColumns();
      this.setDefaultFilter();
      this.subscribeToVisits();
   }

   public ngAfterViewInit() {
      this.getTableHeaderHeightValues();

      /* WORKAROUND TO DISPLAY THE CORRECT COLUMNS WIDTH AFTER RESIZING
         NOT THE BEST WAY TO DO IT, BUT THE ONLY ONE WE FOUND TO RESOLVE THIS PROBLEM  */
      let colsAux = this.cols.slice();
      this.cols = this.cols.slice(0, this.cols.length - 1);

      setTimeout(() => {
         this.cols = colsAux;
      }, 500);
   }

   public ngDoCheck() {
      let tableDivContainer = this.visitList.el.nativeElement.firstElementChild;
      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      if (tableHeaderlement) {
         if (tableHeaderlement.clientHeight !== this.tableHeaderHeight) {
            this.getTableHeaderHeightValues();
            this.columnChanged.emit({
               headerHeight: this.headerHeight,
               tableHeaderHeight: this.tableHeaderHeight,
               tableHeight: this.tableHeight
            });
         }
      }
   }

   public ngOnDestroy() {
      if (this._visitsSubscription) {
         this._visitsSubscription.unsubscribe();
      }
   }

   // Saves the selected visible grid colunms on the callpoints store to be retrieved the next time the grid is loaded
   public setNewColumnSelection(event: any) {
      this._applicationStore.visitsStore.selectedCols = this.cols;
      // save the selected columns to local storage
      localStorage.setItem('visitsSelectedCols', JSON.stringify(this.cols));
   }

   // Checks if a collection contains an item.
   private contains(array: Visit[], item: Visit): boolean {
      return array.filter(element => element.id === item.id).length > 0;
   }

   // Gets the expandible groups (callpoint reference) for the retreived visits.
   private getAvailableExpandedGroups(events: Visit[]): string[] {
      if (events && events.length > 0) {
         let callPointReferenceList: Array<string> = events.map(
            visit => visit.callpointReference
         );
         let callPointReferenceListOrdered: Array<string> = callPointReferenceList.sort(
            function (groupA: string, groupB: string) {
               return groupA.localeCompare(groupB);
            }
         );
         return callPointReferenceListOrdered.filter(function (item, pos, arr) {
            return !pos || item != arr[pos - 1];
         });
      } else {
         return [];
      }
   }

   //Gets the scheduled weeks grouped for a collection of visits.
   private getVisitsWeeks(events: Visit[]): number[] {
      if (events && events.length > 0) {
         // Gets al the week values.
         let visitWeekList: Array<number> = events.map(
            visit => visit.scheduleWeek
         );
         // Groups the week values sorted asc.
         let visitWeekListSorted: Array<number> = visitWeekList.sort(
            (a, b) => a - b
         );
         return visitWeekListSorted.filter(function (item, pos, arr) {
            return !pos || item != arr[pos - 1];
         });
      } else {
         return [];
      }
   }

   private buildVisitsList(events: Visit[]) {
      // Adds internal Id to the visits. That information is not returned from the service.
      let id: number = 1;
      events.forEach(visit => {
         visit.id = id++;
      });

      this.totalRecords = events.length;
      this._allVisitsForCaller = events;
      this._sortedAndFilteredData = this._allVisitsForCaller.slice();

      this.buildFirstPage(this._sortedAndFilteredData);

   }

   private subscribeToVisits() {
      this._visitsSubscription = this._applicationStore.visitsStore.visits$.subscribe(
         (events: Visit[]) => {
            let orderedVisits: Visit[] = this.sortVisitsByWeekAndDay(events);
            this.buildVisitsList(orderedVisits);
            this.buildScheduleData(orderedVisits);
         }
      );
   }

   //Sorts the visit list by callpointReference, scheduleWeek and numberOfDayOfWeek.
   private sortVisitsByWeekAndDay(visits: Visit[]): Visit[] {
      let sortedGroupedVisits: Visit[] = [];
      // Gets the callpoint names for the retreived visits.
      let expandedRowGroups: string[] = this.getAvailableExpandedGroups(visits);
      // Asigns the order value to the visits related to the same group.
      expandedRowGroups.forEach(group => {
         // Gets the the visits for an specific group .
         let groupedVisits = visits.filter(
            item => item.callpointReference === group
         );
         // Gets the week numbers in that group sorted asc.
         let weeksInGroup = this.getVisitsWeeks(groupedVisits);
         let orderedweeks = weeksInGroup.filter(item => item !== 0);
         let weeksWithNoValue = weeksInGroup.filter(item => item === 0);
         orderedweeks.push(...weeksWithNoValue);
         let index = 1;
         orderedweeks.forEach(numberOfWeek => {
            // Gets the visits for each week number.
            let groupedVisitsByWeek = groupedVisits.filter(
               item => item.scheduleWeek === numberOfWeek
            );
            // Sorts the visits for that specified week by numberOfDayWeek.
            let orderedGroupedVisits = groupedVisitsByWeek.sort(
               this.compareValues('numberOfDayOfWeek', 'asc')
            );
            orderedGroupedVisits.forEach(visit => {
               // Sets the order of the each visit.
               visit.order = index++;
               visit.referenceAndOrder =
                  visit.callpointReference + '_' + visit.order;
               // let callpoint: Callpoint = this._applicationStore.callpointsStore.callpoints.find(callpoint => callpoint.reference == visit.callpointReference);
               // if (callpoint) {
               //    visit.frequency = callpoint.frequency;
               // }
               // else {
               //    console.log("Undefined callpoint: " + visit.callpointReference)
               // }
               sortedGroupedVisits.push(visit);
            });
         });
      });
      return sortedGroupedVisits;
   }

   // function for dynamic sorting needed for hand rolled sorting
   // function from  might be faster ways
   // https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/
   private compareValues(key1, order = 'asc') {
      return function (a, b) {
         if (!a.hasOwnProperty(key1) || !b.hasOwnProperty(key1)) {
            // property doesn't exist on either object
            return 0;
         }

         const varA =
            typeof a[key1] === 'string' ? a[key1].toUpperCase() : a[key1];
         const varB =
            typeof b[key1] === 'string' ? b[key1].toUpperCase() : b[key1];

         let comparison = 0;
         if (varA > varB) {
            comparison = 1;
         } else if (varA < varB) {
            comparison = -1;
         }

         return order == 'desc' ? comparison * -1 : comparison;
      };
   }

   private buildFirstPage(visits: Visit[]) {
      if (!this.dtFilters) {
         this.totalRecords = visits.length;
         this.visitList.reset();
         this.visitList.updateTotalRecords();
         //this.visits = visits.slice(0, this.rows);
         this.visits = visits.slice();
      }
      else {
         let filteredResults = this.filter(
            this._sortedAndFilteredData,
            this.dtFilters
         );
         this._filterNum = filteredResults.length;
         if (filteredResults.length > 0) {
            this.visits = filteredResults.slice(0, this.rows);
            this.totalRecords = filteredResults.length;
            setTimeout(() => {
               this.setImagesDraggable();
            }, 300);
         }
         else {
            this.visits = filteredResults;
            this.totalRecords = filteredResults.length;
         }
         this.setGridTitleFilteredText();
      }

      this._cdr.detectChanges();
   }

   public onFilter(event) {
      if (Object.keys(this.visitList.filters).length > 0) {
         this.setGridTitleFilteredText();
         this.hasFilters = true;
      } else {
         this.gridTitle = 'Visits';
         this.hasFilters = false;
      }
   }

   // public trackByFn(index, item) {
   //    return item.guid; // index or item.id
   // }

   // Resets all filters after clicking the button on the UI
   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }
      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }
      // This assignment has to be done before reseting the visit list because
      // after that the loadDataLazy method is called and the dtFilters variable
      // must be already cleared
      this.dtFilters = null;
      this.visitList.reset();

      // have to clear all the custom filters
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.visitList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // this keeps the orgingal sort
      this.visitList.updateDataToRender(this.visits);
      this.hasFilters = false;
      this.gridTitle = 'Visits';
   }

   // the lazy loading is typically used handle large datasets by going to the server and retrieving paged data.
   // this can be done for virtual scroll or paginated tables.
   // in our case we have all the visits in memory, so we are not going to the server but splicing the array to only
   // return small numbers of rows, to speed up the grid.
   // as we are using lazy loading we need to hand roll our own sorting and filtering.
   // typically we would do this on the server with custom SQL commands
   public loadDataLazy(event: LazyLoadEvent) {
      if (this.caller) {
         let order = event.sortOrder > 0 ? 'asc' : 'desc';
         // only re-order or sort if the event values have changed
         if (
            this._currentSortField != event.sortField ||
            this._currentSortOrder != order
         ) {
            this._sortedAndFilteredData = this._allVisitsForCaller.slice();

            if (event.sortField) {
               if (event.sortField == 'shortDate') {
                  this._sortedAndFilteredData.sort(
                     this.compareValues('date', order)
                  );
               } else {
                  this._sortedAndFilteredData.sort(
                     this.compareValues(event.sortField, order)
                  );
               }
            } else {
               // When event.sortField is undefined the list must be sorted by referenceAndOrder
               this._sortedAndFilteredData.sort(
                  this.compareValues('referenceAndOrder', order)
               );
            }
            this._currentSortField = event.sortField;
            this._currentSortOrder = order;
         }

         if (Object.keys(event.filters).length > 0) {
            // list filters are stored to use them if the visist list is refreshed
            this.dtFilters = this.customiseIsScheduledFilter(event.filters);
            let filteredResults = this.filter(
               this._sortedAndFilteredData,
               this.dtFilters
            );
            this._filterNum = filteredResults.length;
            if (filteredResults.length > 0) {
               this.visits = filteredResults.slice(
                  event.first,
                  event.first + event.rows
               );
               this.totalRecords = filteredResults.length;
            } else {
               this.visits = filteredResults;
               this.totalRecords = filteredResults.length;
            }
         } else {
            // Reloading the visit list after the first loading to keep the filter selections
            if (this.dtFilters) {
               let filteredResults = this.filter(
                  this._sortedAndFilteredData,
                  this.dtFilters
               );
               this._filterNum = filteredResults.length;

               this.visits = filteredResults.slice(
                  event.first,
                  event.first + event.rows
               );

            } else {
               this.visits = this._sortedAndFilteredData.slice(
                  event.first,
                  event.first + event.rows
               );
               this.totalRecords = this._sortedAndFilteredData.length;
            }
         }
         setTimeout(() => {
            this.setImagesDraggable();
         }, 600);
      }
   }

   //Sets the deferrals icons draggagle to be able to drap&drop visits into the calendar (Drag&Drop)
   public setImagesDraggable() {

      // Gets the HTML elements to use them during the drag&drop proccess.
      let draggingMessage: HTMLElement = document.getElementById(
         'draggingMessage'
      );
      let draggableImages: NodeListOf<HTMLElement> = document.getElementsByName(
         'draggableImage'
      );

      // Gets all the rows elements of the visit list
      let rows: NodeListOf<Element> = document.querySelectorAll(
         '.ui-datatable-even, .ui-datatable-odd, .ui-rowgroup-header'
      );
      // Dragging logic is added to each draggable image in the UI.
      Array.prototype.slice.call(draggableImages).forEach(element => {
         // Sets the element draggable
         if (!jQuery(element).data('draggable')) {
            jQuery(element).draggable({
               revert: true, // immediately snap back to original position
               revertDuration: 0,
               helper: 'clone',
               // Fired when the element stars to be dragged
               start: (event, ui) => {
                  // Gets the visit related to the image dragged
                  let draggedVisit: Visit = this._allVisitsForCaller.find(
                     visit => visit.id == element.id
                  );
                  //////////////////////////////////////////////////////////////////////////////////////////
                  // this.selectedVisits = [draggedVisit];
                  //////////////////////////////////////////////////////////////////////////////////////////
                  // Add the visit to the event.target having transform it to a JSON object
                  let JSonDraggedVisit = JSON.stringify(draggedVisit);
                  jQuery(event.target).data('deferral', JSonDraggedVisit);
                  // console.log( 'START: ' + jQuery(event.target).data('deferral') );
               },
               // Fired when the element is being dragged
               drag: (event, ui) => {
                  // Sets position to the UI message next to the image when dragging
                  jQuery(draggingMessage).css({
                     left: event.clientX - 195,
                     top: event.clientY - 65,
                     display: 'inline'
                  });
                  // Sets the cursor (no-drop) when dragging over the visit list rows
                  jQuery(rows).css({
                     cursor: 'no-drop'
                  });

                  let headerCalendarWeekButtons: NodeListOf<Element> = document.querySelectorAll(
                     '.cs-btn-secondary'
                  );
                  jQuery(headerCalendarWeekButtons).css({
                     cursor: 'no-drop'
                  });
                  let headerCalendarDayLinks: NodeListOf<Element> = document.querySelectorAll(
                     '.week-header'
                  );
                  jQuery(headerCalendarDayLinks).css({
                     cursor: 'no-drop'
                  });
                  // Sets the cursor (move) when dragging over the events located in the calendar
                  let fullCalendarEvents: NodeListOf<Element> = document.querySelectorAll(
                     '.fc-event'
                  );
                  jQuery(fullCalendarEvents).css({
                     cursor: 'move'
                  });

                  let fcBodyElement: NodeListOf<Element> = document.querySelectorAll(
                     '.fc-body'
                  );
                  jQuery(fcBodyElement).css({
                     cursor: 'move'
                  });
               },
               // Fired when the element stop being dragged
               stop: (event, ui) => {
                  // Resets the cursor to its original state.
                  if (draggingMessage) {
                     jQuery(draggingMessage).css({
                        display: 'none'
                     });
                  }

                  if (rows) {
                     jQuery(rows).css({
                        cursor: 'pointer'
                     });
                  }

                  let headerCalendarButtons: NodeListOf<Element> = document.querySelectorAll(
                     '.cs-btn-secondary'
                  );
                  if (headerCalendarButtons) {
                     jQuery(headerCalendarButtons).css({
                        cursor: 'pointer'
                     });
                  }

                  let headerCalendarDayLinks: NodeListOf<Element> = document.querySelectorAll(
                     '.week-header'
                  );
                  if (headerCalendarDayLinks) {
                     jQuery(headerCalendarDayLinks).css({
                        cursor: 'pointer'
                     });
                  }

                  let fullCalendarEvents: NodeListOf<Element> = document.querySelectorAll(
                     '.fc-event'
                  );
                  if (fullCalendarEvents) {
                     jQuery(fullCalendarEvents).css({
                        cursor: 'pointer'
                     });
                  }
                  /* TESTING */
                  // console.log('DROP: ' + jQuery(event.target).data('deferral'));
                  let draggedVisit: Visit = this._allVisitsForCaller.find(
                     visit => visit.id == element.id
                  );
                  //////////////////////////////////////////////////////////////////////////////////////////
                  this.selectedVisits = [draggedVisit];
                  //////////////////////////////////////////////////////////////////////////////////////////
               },
               cursor: 'no-drop',
               cursorAt: { top: 5, left: 5 }
            });
         }
      });
   }

   // Builds the combos used for filtering data
   private buildScheduleData(visits: ReadonlyArray<Visit>) {
      this.buildFrequencyComboData(visits); // frequency combo
      this.buildVisitNumberComboData(visits); // order combo.
      this.buildWeekComboData(visits); // schedule Week combo.
      this.buildDayOfWeekComboData(visits); // day of week combo.
      this.buildScheduledComboData(visits); // scheduled combo.
   }

   // Callpoint frequency combo (Frequency).
   private buildFrequencyComboData(visits: ReadonlyArray<Visit>) {
      if (visits.length > 0) {
         let items = visits.map(function (v) {
            return v.frequency;
         });
         let data = generateUniqueItems('', items);
         data.sort(this.compareValues('value', 'asc'));
         this.comboFilterDataMap.set('frequency', data);
      }
   }

   // Order combo (Visit number).
   private buildVisitNumberComboData(visits: ReadonlyArray<Visit>) {
      if (visits.length > 0) {
         let items = visits.map(function (v) {
            return v.order;
         });
         let data = generateUniqueItems('', items);
         data.sort(this.compareValues('value', 'asc'));
         this.comboFilterDataMap.set('order', data);
      }
   }

   // Schedule Week combo (Week).
   private buildWeekComboData(visits: ReadonlyArray<Visit>) {
      if (visits.length > 0) {
         let items = visits.map(v => v.scheduleWeek);
         let data = generateUniqueItems('', items);

         // remove blank value
         let index = data.findIndex(c => c.value == undefined);
         if (index > -1) {
            data.splice(index, 1);
         }
         data.sort(this.compareValues('value', 'asc'));
         this.comboFilterDataMap.set('scheduleWeek', data);
         //this.weekFilterCombo.sort(this.compareValues("value", "asc"))
      }
   }

   // Day of week combo (Day).
   private buildDayOfWeekComboData(visits: ReadonlyArray<Visit>) {
      if (visits.length > 0) {
         let items = visits.map(function (v) {
            return v.dayOfWeek;
         });
         let data = generateUniqueItems('', items);
         this.comboFilterDataMap.set(
            'dayOfWeek',
            this.sortDaysOfWeekComboValues(data)
         );
      }
   }

   private sortDaysOfWeekComboValues(daysOfWeek: any[]): any[] {
      let sortedDaysOfWeek: any = [];

      let mondayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Monday'
      );
      if (mondayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[mondayIndex]);
      }
      let tuesdayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Tuesday'
      );
      if (tuesdayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[tuesdayIndex]);
      }
      let wednesdayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Wednesday'
      );
      if (wednesdayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[wednesdayIndex]);
      }
      let thursdayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Thursday'
      );
      if (thursdayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[thursdayIndex]);
      }
      let fridayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Friday'
      );
      if (fridayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[fridayIndex]);
      }
      let saturdayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Saturday'
      );
      if (saturdayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[saturdayIndex]);
      }
      let sundayIndex: number = daysOfWeek.findIndex(
         daysOfWeek => daysOfWeek.value == 'Sunday'
      );
      if (sundayIndex > -1) {
         sortedDaysOfWeek.push(daysOfWeek[sundayIndex]);
      }
      return sortedDaysOfWeek;
   }

   private setGridTitleFilteredText() {
      this.gridTitle = 'Visits (Showing ' + this._filterNum + ' of ' +
         this._allVisitsForCaller.length + ' total)';
   }
   // Scheduled combo (Visit or Deferral).
   private buildScheduledComboData(visits: ReadonlyArray<Visit>) {
      if (visits.length > 0) {
         let data = [];

         data.push({ label: 'All', value: 'All' });
         data.push({ label: 'Scheduled', value: 'Scheduled' });
         data.push({ label: 'Unscheduled', value: 'Unscheduled' });
         data.push({ label: 'Not fully scheduled', value: 'Part scheduled' });

         this.comboFilterDataMap.set('isScheduled', data);
      }
   }

   // hand rolled filter ripped from primeng datatable
   // need to do some special customisation for the visist filters
   // if one of the filters is "is scheduled" it is nessary to return all visits for a callpoint
   // not only the unscheduled visit
   private filter(value, filters?: { [s: string]: FilterMetadata }) {

      // console.log('filterSelectedValues', this.filterSelectedValues);

      // not catering for a global filter
      let globalFilter = false;
      let filteredValue = [];
      for (let i = 0; i < value.length; i++) {
         let localMatch = true;
         let globalMatch = false;
         for (let j = 0; j < this.allCols.length; j++) {
            let col = this.allCols[j];
            let filterMeta = filters[col.filterField || col.field];
            //local
            if (filterMeta) {
               let filterValue = filterMeta.value,
                  filterField = col.filterField || col.field,
                  filterMatchMode = filterMeta.matchMode || 'startsWith',
                  dataFieldValue = this.visitList.resolveFieldData(
                     value[i],
                     filterField
                  );
               let filterConstraint = this.visitList.filterConstraints[
                  filterMatchMode
               ];
               if (!filterConstraint(dataFieldValue, filterValue)) {
                  localMatch = false;
               }
               if (!localMatch) {
                  break;
               }
            }
            //global
            if (!col.excludeGlobalFilter && globalFilter && !globalMatch) {
               globalMatch = this.visitList.filterConstraints['contains'](
                  this.visitList.resolveFieldData(
                     value[i],
                     col.filterField || col.field
                  ),
                  globalFilter.value
               );
            }
         }
         let matches = localMatch;
         if (globalFilter) {
            matches = localMatch && globalMatch;
         }
         if (matches) {
            filteredValue.push(value[i]);
         }
      }

      if (filteredValue.length === value.length) {
         this.visitList.filteredValue = null;
      }
      if (this.visitList.paginator) {
         this.totalRecords = filteredValue
            ? filteredValue.length
            : value
               ? value.length
               : 0;
      }
      this.visitList.updateDataToRender(
         this.visitList.filteredValue || this.visitList.value
      );
      return filteredValue;
   }

   private customiseIsScheduledFilter(filters?: { [s: string]: FilterMetadata }) {

      // need to account for the concept of part scheduled
      if (filters['isScheduled']) {

         switch (filters['isScheduled'].value) {
            case 'All':
               filters['isScheduled'].value = null;
               filters = this.addUpdateIsPartScheduledFilter(null, filters);
               break;
            case 'Scheduled':
               filters['isScheduled'].value = true;
               filters = this.addUpdateIsPartScheduledFilter(null, filters);
               break;
            case 'Unscheduled':
               filters['isScheduled'].value = false;
               filters = this.addUpdateIsPartScheduledFilter(null, filters);
               break;
            case 'Part scheduled':
               filters['isScheduled'].value = null;
               filters = this.addUpdateIsPartScheduledFilter(true, filters);
               break;
            default:
               /* no defualt as the page lazy load will cycle through this a few times and the above would have been tweaked already */
               break;
         }

      }

      return filters;

   }

   private setDefaultFilter() {
      this.dtFilters = {
         isScheduled: { value: null, matchMode: "equals" },
         isPartScheduled: { matchMode: "equals", value: true }
      }

      this.hasFilters = true;
      this.filterSelectedValues = ["Part scheduled", "All", "All", "All", "All", "All", "All", "All", "All", "All", "All", "All"];

      this._currentSortField = 'referenceAndOrder';
      this._currentSortOrder = 'asc'
      this.visitList.filters = this.dtFilters;
   }


   private addUpdateIsPartScheduledFilter(isPartScheduleValue, filters?: { [s: string]: FilterMetadata }) {

      if (filters['isPartScheduled']) {
         filters['isPartScheduled'].value = isPartScheduleValue;
      }
      else {
         filters.isPartScheduled = { matchMode: "equals", value: isPartScheduleValue }
      }
      return filters
   }


   private configureTableColumns() {
      // Retrieves the selected columns from the callpoints store
      this.cols = this._applicationStore.visitsStore.selectedCols;

      this.allCols = [
         {
            field: 'isScheduled',
            header: 'Scheduled',
            disabled: true,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: true,
            hasMulti: false
         },
         {
            field: 'referenceAndOrder',
            header: 'Visit ID',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'callpointReference',
            header: 'Code',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'callpointDescription',
            header: 'Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'frequency',
            header: 'Frequency',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'order',
            header: 'Phase',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'scheduleWeek',
            header: 'Week',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'dayOfWeek',
            header: 'Day',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'sequenceInDay',
            header: 'Day Sequence',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'shortDate',
            header: 'Date',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'lockType',
            header: 'Status',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false
         },
         {
            field: 'isPartScheduled',
            header: 'Part Scheduled',
            disabled: false,
            filter: true,
            filterPlaceholder: 'equals',
            filterMatchMode: 'equals',
            hasCombo: false,
            hasMulti: false
         }
      ];

      this.comboFilterDataMap = new Map<string, any[]>();

      this.columnOptions = [];
      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i]
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }
   }

   private getTableHeaderHeightValues() {
      let tableDivContainer = this.visitList.el.nativeElement.firstElementChild;
      let headerElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-header ui-widget-header'
      )[0];
      this.headerHeight = headerElement.clientHeight;

      let tableHeaderlement: Element = tableDivContainer.getElementsByClassName(
         'ui-widget-header ui-datatable-scrollable-header'
      )[0];
      this.tableHeaderHeight = tableHeaderlement.clientHeight;

      let tableElement: Element = tableDivContainer.getElementsByClassName(
         'ui-datatable-scrollable-view'
      )[0];
      this.tableHeight = tableElement.clientHeight;
   }
}
