<div class="panel" [ngStyle]="{'height.px': workspaceHeight}">
   <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
      <perfect-scrollbar [ngStyle]="{'height.px': workspaceHeight-33}">
         <div class="row" style="margin-top: 10px; height:50px">
            <div class="cs-wizard-step-panel col-sm-12">
               <div class="panel-body col-sm-4 " style="padding: 15px 15px 15px 15px">
                  <h6>Notifications Centre</h6>
                  <div *ngFor="let msg of notifications" class="ui-growl-item-container ui-state-highlight ui-corner-all ui-shadow"
                     aria-live="polite" [ngClass]="{'ui-growl-message-info':msg.severity == 'info','ui-growl-message-warn':msg.severity == 'warn',
                      'ui-growl-message-error':msg.severity == 'error','ui-growl-message-success':msg.severity == 'success'}">
                     <div class="ui-growl-item">
                        <div class="ui-growl-message">
                           <span class="ui-growl-title">{{ msg.summary + ' - '}} {{'( ' + msg.relativeCreateDate + ' )'}} {{msg.createDate | date: 'dd/MM/yyyy hh:mm:ss'}} </span>
                           <p>{{ msg.detail }}</p>
                           <span class="ui-growl-title" *ngIf="msg.severity == 'error'">{{ msg.errorMessage }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </perfect-scrollbar>
   </div>
</div>
