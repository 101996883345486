import { Component, OnInit, ViewChild } from '@angular/core';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Router } from '@angular/router';
import { DataTable } from 'primeng/components/datatable/datatable';
import { ApplicationStore } from 'app/stores/application-store';

import { TravelModel } from 'app/models/travelModel';
import { TravelModelListComponent } from 'app/admin-workspace/travel-model-list/travel-model-list.component';
import { DeleteTravelModelDialogComponent } from 'app/admin-workspace/delete-travel-model-dialog/delete-travel-model-dialog.component';
import { AddEditTravelModelDialogComponent } from 'app/admin-workspace/add-edit-travel-model-dialog/add-edit-travel-model-dialog.component';
import { Alert } from 'app/models/alert';

@Component({
  selector: 'callsmart-admin-travel-model-panel',
  templateUrl: './admin-travel-model-panel.component.html'
})
export class AdminTravelModelPanelComponent implements OnInit {

   public scrollHeight: string = "200px";
   public workspaceHeight: number;

   @ViewChild(TravelModelListComponent)
   public travelModelList: TravelModelListComponent;


   ///--------------------delete dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showDeleteTravelModelDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public deleteTravelModelDialog = DeleteTravelModelDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      travelModel: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      delete: () => this.onDeleteEvent(),
      cancel: () => this.onCancelEvent()
   };

   ///--------------------add edit dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showAddEditTravelModelDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public addEditTravelModelDialog = AddEditTravelModelDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public addEditDialogInput = {
      display: false,
      travelModel: null,
      headerText: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public addEditDialogOutput = {
      save: () => this.onSaveAddEditEvent(),
      cancel: () => this.onCancelAddEditEvent()
   };


   constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService, private router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
   }

   public ngOnInit() {
   }

   public onDeleteTravelModel() {
      if(this.travelModelList.selectedTravelModel.travelModelName == 'UK Full TomTom') {
         this._applicationStore.alertStore.sendAlert(new Alert('Warning', "This travel model cannot be deleted as it is the system default travel model."));
         return;
      }
      // do we need a check before offering chance to delete travel model
      this.showDeleteTravelModelDialog = true;
      this.dialogInput.display = true;
      this.dialogInput.travelModel = this.travelModelList.selectedTravelModel;
   }

   // cancel button from the delete project  dialog
   public onCancelEvent() {
      this.showDeleteTravelModelDialog = false;
      this.dialogInput.display = false;
   }

   // delete button from the delete project  dialog
   public onDeleteEvent() {
      this.showDeleteTravelModelDialog = false;
      this.dialogInput.display = false;
   }

   // save button from the delete project  dialog
   public onSaveAddEditEvent() {
      this.showAddEditTravelModelDialog = false;
      this.dialogInput.display = false;
   }

   // cancel button from the addedit project  dialog
   public onCancelAddEditEvent() {
      this.showAddEditTravelModelDialog = false;
      this.dialogInput.display = false;
   }

   public onEditTravelModel() {
      this.showAddEditTravelModelDialog = true;
      this.addEditDialogInput.display = true;
      this.addEditDialogInput.travelModel = this.travelModelList.selectedTravelModel;
      this.addEditDialogInput.headerText = "Edit Travel Model";
   }

   public onCreateTravelModel() {
      var newTravelModel = new TravelModel();

      this.showAddEditTravelModelDialog = true;
      this.addEditDialogInput.display = true;
      this.addEditDialogInput.travelModel = newTravelModel;
      this.addEditDialogInput.headerText = "Add Travel Model";
   }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 220;
      this.scrollHeight = this.workspaceHeight * 0.80 + 'px';

   }

}
