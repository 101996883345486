<edit-caller-settings name="editCallerSettings"
   [(ngModel)]="callerSettingsModel"
   [scrollHeight]="scrollHeight"
   [projectCycleLength]="projectCycleLength"
   #editCallerSettings>
</edit-caller-settings>

<div class="col-md-12">
   <div class="btn-toolbar" style="margin-right: -14px;">
      <input type="submit" [disabled]="!formValid || canDeactivate()" class="btn cs-btn-primary-inverse pull-right" name="submit" value="Save"
         (click)="submitForm()">
      <input type="button" class="btn cs-btn-secondary-inverse pull-right" name="cancel" value="Cancel"
         (click)="onCancel()">
   </div>
</div>
