<form novalidate #summaryForm="ngForm" class="form-horizontal">
   <div class="panel">
      <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
         <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-33}">
            <div  style="display: -webkit-box; width:auto;">
               <!-- Callers summary -->
               <div *ngIf="importOptions.importCallers" class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box;overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Callers</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                 aria-hidden="true" (click)="goToCallers()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">File imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.callersFileName}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callers imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.numberOfCallers}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callers added:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallerCounts?.callersAdded}} ({{ callersAdded | percent:'1.1-1'}})</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callers updated:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallerCounts?.callersUpdate}} ({{ callersUpdated | percent:'1.1-1'}})</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callers deleted:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallerCounts?.callersDeleted}} ({{ callersDeleted | percent:'1.1-1'}})</label>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Uploading to server:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left;padding-left: 5px;">
                              <p-progressBar [value]="callerProgressValue" [showValue]="false" ></p-progressBar>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
               <div class="display-flex-column" style="width: 2px"></div>
               <!-- Callpoints summary -->
               <div *ngIf="importOptions.importCallpoints" class="new-project-summary-subpanel">
                  <div class="cs-wizard-step-panel">
                     <div class="panel-body">
                        <div style="display: -webkit-box;overflow:auto;width:auto;">
                           <div class="new-project-summary-subpanel-title-column">
                              <h6>Callpoints</h6>
                           </div>
                           <div class="new-project-summary-subpanel-icon-column">
                              <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle"
                                 aria-hidden="true" (click)="goToCallpoints()"></i>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">File imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.callpointsFileName}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callpoints imported:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{projectWizardModel?.numberOfCallpoints}}</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callpoints added:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallpointCounts?.callpointsAdded}} ({{ callpointsAdded | percent:'1.1-1'}})</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callpoints updated:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallpointCounts?.callpointsUpdate}} ({{ callpointsUpdated | percent:'1.1-1'}})</label>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Callpoints deleted:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left">
                              <label class="control-label-bold">{{importCallpointCounts?.callpointsDeleted}} ({{ callpointsDeleted | percent:'1.1-1'}})</label>
                           </div>
                        </div>

                        <div class="row">
                           <div class="col-md-4 col-lg-3 col-xl-3">
                              <label class="control-label">Uploading to server:</label>
                           </div>
                           <div class="col-md-8 col-lg-9 col-xl-9" style="text-align:left; padding-left: 5px;">
                              <p-progressBar [value]="callpointProgressValue" [showValue]="false" ></p-progressBar>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </perfect-scrollbar>
      </div>
   </div>
</form>
