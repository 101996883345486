import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrowserWindowService } from 'app/services/browser-window.service';

@Component({
   selector: 'callsmart-error-dialog',
   templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent implements OnInit {

   @Input() public title: string = "";
   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;
   @Input() validationErrors: string[] = []; // Collection of errors found during the validation proccess.
   @Input() bodyText: string = "";
   @Input() bottomLink:string="";

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   public scrollHeight: number;
   public scrollWidth: number;

   constructor(private windowService: BrowserWindowService) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value * 0.70;
      });
      windowService.width$.subscribe((value: number) => {
         this.scrollWidth =  value * 0.70;
      });
   }


   ngOnInit() {
   }

   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

}
