<p-dialog [(visible)]="display" modal="true" width="470" positionTop="50" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">
   <h4>ONLINE HELP</h4>
   <div>
      <a class="white-link-underline control-link" [href]="gitBookHelp" target="_blank">CallSmart help pages</a>
   </div>
   <div>
      <a class="white-link-underline control-link" [href]="userGuideHelp" target="_blank" download="CallSmart User Guide.pdf" >CallSmart user guide</a>
   </div>

   <div>
      <a class="white-link-underline control-link" [href]="gettingToGripsVideo" target="_blank" >Getting to grips with CallSmart</a>
   </div>
   <div class="form-group row">
      <a class="white-link-underline control-link" [href]="dataTemplate" download="DataTemplates.xlsx" >CallSmart data template</a>
   </div>

   <h4>REAL-TIME SUPPORT</h4>
   <div>Telephone: +44 (0)20 7605 6055</div>
   <div>Email: insitehelpline@caci.co.uk</div>

   <div style="height: 125px">
      <h4>ABOUT CALLSMART</h4>
      <ul style="list-style-type:none; padding-left: 0">
         <li>Application version: {{appVersion}}</li>
         <li>Drivetime version: {{drivetimeVersion}}</li>
         <li>Optimiser version: {{optimiserVersion}}</li>
      </ul>
   </div>

   <p-footer>
      <div style="padding-top: 10px; padding-bottom: 50px">
         <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onCancel()" style="margin-right: 30px;">Close</button>
      </div>
   </p-footer>


</p-dialog>
