import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SettingsService } from 'app/services/settings.service';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ErrorHandlerService } from 'app/services/error-handler.service';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class UserCallpointSettingsStore {

   // Default callpoint settings
   private _defaultCallpointSettings: BehaviorSubject<CallpointSettings> = new BehaviorSubject<CallpointSettings>(null);
   public defaultCallpointSettings$: Observable<CallpointSettings> = this._defaultCallpointSettings.asObservable();

   private _userCallPointSettingsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public userCallPointSettingsUpdated$: Observable<boolean> = this._userCallPointSettingsUpdated.asObservable();

   constructor(private _settingsService: SettingsService,
      private _errorHandler: ErrorHandlerService) {
      this._defaultCallpointSettings.next(null);
   }

   public loadUserCallPointSettings() {
      this._settingsService.getUserCallPointSettings()
         .subscribe(
            (settings: CallpointSettings) => {
               this._defaultCallpointSettings.next(settings);
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   public updateUserCallPointSettings(callpointSettings) {
      this._settingsService.updateUserCallPointSettings(callpointSettings)
         .subscribe((res: boolean) => {
            if (res) {
               //refresh the settings
               this._userCallPointSettingsUpdated.next(true);
            }
         },
         (error) => this._errorHandler.handleError(error));
   }
}
