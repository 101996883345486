<p-dialog [header]="title" [width]="scrollWidth" [(visible)]="display" modal="modal" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">
   <form #form="ngForm" class="form-horizontal" autocomplete="off">

         <div class="panel" style="border:none;">
            <div style="margin-top: 10px;">

               <div class="row">
                  <h6>Folder name</h6>
                  <div class="form-group">
                     <div class="col-sm-11">
                        <input [(ngModel)]="folder" id="folder" class="form-control cs-form-control" name="folder" focusDirective cssSelector="#folder">
                     </div>
                  </div>
               </div>

            </div>
         </div>


      <p-footer>
         <div style="padding-top: 10px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onSave(form)" [disabled] = "folder.trim().length == 0" >Create folder</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>
   </form>

</p-dialog>
