import { Injectable } from '@angular/core';

import { AuditService } from 'app/services/audit-service';
import { ReportParameters } from 'app/models/report-parameters';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// This store is used to provide access to auditing functionalitu such as audit reports
// report on user access, report on user running of optimisations//
@Injectable()
export class AuditStore {

   constructor(private _auditService: AuditService,
      private _errorHandler: ErrorHandlerService) {

   }

   // run a report that is exported to CSV for auditing users logins for a date range
   public exportUserAccessForDataRange(params: ReportParameters) {
      this._auditService.exportUserAccessForDataRange(params)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - User Access.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   // run a report that is exported to CSV for auditing executions of optimisations for a date range
   public exportOptimisationsLogForDataRange(params: ReportParameters) {
      this._auditService.exportOptimisationsLogForDataRange(params)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - Optimisations Log.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }

   // run a report that is exported to CSV for auditing executions of optimisations for a date range
   public exportServiceRequetsLogForDataRange(params: ReportParameters) {
      this._auditService.exportServiceRequetsLogForDataRange(params)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(response, 'CallSmart Export - Service Request Log.csv');
            },
            (error) => {
               this._errorHandler.handleError(error);
            });
   }
}
