import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';


@JsonConverter
export class NumberOfDayOfWeekConverter implements JsonCustomConvert<number> {
   serialize(value: number): string {
       return '';
   }
   deserialize(dayOfWeek: string): number {
       if(dayOfWeek === null) {
          return undefined;
       }
       else {
         switch (dayOfWeek) {
            case 'Monday':
               return 1;
            case 'Tuesday':
               return 2;
            case 'Wednesday':
               return 3;
            case 'Thursday':
               return 4;
            case 'Friday':
               return 5;
            case 'Saturday':
               return 6;
            case 'Sunday':
               return 0;
         }    
       }
   }
}
