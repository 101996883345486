<!--component html goes here -->
<div class="cs-wizard-title"><h3>{{wizardTitle}} - {{activeStepLabel}}</h3></div>
<p-steps [model]="items"
         [(activeIndex)]="activeIndex"
         [class]="styleClass"
         [readonly]="false">
</p-steps>

<div [ngStyle]="{'height.px': workspaceHeight}">
   <ng-content></ng-content>
<div>

<div class="col-md-12">
      <div class="btn-toolbar" style="margin-right:-6px; padding-top:10px; padding-right:5px;">
         <!-- These buttons will appear in reverse order due to the css class pull-right floating the buttons to the right -->
         <button type="button" class="btn cs-btn-primary-inverse pull-right"
            *ngIf="activeIndex == items.length - 1"
            [disabled]="disabled"
            (click)="onFinish()">{{finishButtonLabel}}</button>
         <button type="button" class="btn cs-btn-primary-inverse pull-right"
            *ngIf="activeIndex < items.length - 1"
            [disabled]="disabled"
            (click)="onNext()">Next</button>
         <button type="button" class="btn cs-btn-secondary-inverse  pull-right"
            *ngIf="activeIndex > 0"
            (click)="onPrevious()" >Previous</button>
         <button type="button" class="btn cs-btn-secondary-inverse  pull-right"
            (click)="onCancel()" >Cancel</button>
      </div>
</div>
