import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "primeng/primeng";
import { CSMultiSelectComponent } from "app/shared/multi-select/multi-select.component";

@NgModule({
    imports: [CommonModule, SharedModule],
    exports: [CSMultiSelectComponent, SharedModule],
    declarations: [CSMultiSelectComponent]
})
export class CSMultiSelectModule { }
