
<div class="map-zoom-panel" [style.top.px]="floatMapSettingsButtonsTop" [style.left.px]="floatButtonsLeft">

   <button type="button" class="btn btn-map btn-sm" (click)="toggleMapSettings()">
      <span class="cs-icon icon-settings-3" aria-hidden="true"></span>
      <div class="map-zoom-icon-text">Settings</div>
   </button>

</div>

<div class="map-configure-panel-large" *ngIf="showMapSettingsConfig"
   (mouseenter)="handleMouseOverMapSettingsConfig($event)" (mouseleave)="handleMouseLeaveMapSettingsConfig($event)"
   [style.top.px]="floatMapSettingsButtonsTop" [style.left.px]="floatConfigLeft">

   <div>
      <label class="map-config-label" aria-hidden="true" for="autoZoom">Map zoom:</label>
      <callsmart-inputswitch [(ngModel)]="isAutoZoom" onLabel="Auto" offLabel="Manual" name="autoZoom">
      </callsmart-inputswitch>
   </div>
   <div>
      <label class="map-config-label" aria-hidden="true" for="autoZoom">Map colour scale:</label>
      <callsmart-inputswitch [(ngModel)]="isMapBlackAndWhite" onLabel="Light" offLabel="Default" name="autoZoom">
      </callsmart-inputswitch>
   </div>
   <div>
      <label class="map-config-label" aria-hidden="true" for="cluster">Map cluster points:</label>
      <callsmart-inputswitch [(ngModel)]="useMapClusterPoints" onLabel="Standard" offLabel="Cluster" name="cluster">
      </callsmart-inputswitch>
   </div>
   <div>
      <label class="map-config-label" aria-hidden="true" for="direct">Map route lines:</label>
      <callsmart-inputswitch [(ngModel)]="useDirectRoutes" onLabel="Direct" offLabel="Travel" name="direct">
      </callsmart-inputswitch>
   </div>
   <div>
      <label class="map-config-label" aria-hidden="true" for="callerIcon">Caller style:</label>
      <callsmart-inputswitch [(ngModel)]="useCallerDotIcon" onLabel="Dot" offLabel="Pin and number" name="callerIcon">
      </callsmart-inputswitch>
   </div>
   <div>
      <label class="map-config-label" aria-hidden="true" for="callpointIcon">Visit style:</label>
      <callsmart-inputswitch [(ngModel)]="useVisitDotIcon" onLabel="Dot" offLabel="Pin and number" name="callpointIcon">
      </callsmart-inputswitch>
   </div>
   <div *ngIf="displayFrequencyOption">
      <label class="map-config-label" aria-hidden="true" for="frequency">Frequency:</label>
      <callsmart-inputswitch [(ngModel)]="showFrequency" onLabel="Show" offLabel="Hide" name="frequency">
      </callsmart-inputswitch>
   </div>
</div>



<p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}"
   (onMapReady)="setMap($event)" (onOverlayClick)="handleOverlayClick($event)" (onMapClick)="handleMapClick($event)">
</p-gmap>
