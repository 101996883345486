import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

/**
 * Dialog box component for the swap day feature in the Schedule workspace.
 */
@Component({
    selector: 'swap-days-dialog',
    templateUrl: 'swap-days-dialog.component.html'
})
export class SwapDaysDialogComponent implements OnInit, OnDestroy {

    // Controls the visibility of this dialog, clients can set this to true to display it.
    @Input() display: boolean = false;

    // The selected from day date.
    @Input() fromDate: Date;

    // Notifies the client when the dialog was closed with save button.
    @Output() saved = new EventEmitter<void>();

    // Notifies the client when the dialog was closed with cancel button.
    @Output() cancel = new EventEmitter<void>();

    // Flag to determine if there are validation errors.
    public hasValidationError = false;

    // Flag to determine if the error message contains overnight related
    // error message.
    public hasOvernightValidationError = false;

    // The error messages returned from the validation if any.
    public validationErrors: string[];

    // The target day to swap the day to.
    public toDate: Date;

    // The default date to use when displaying the toDate calendar.
    public toDateDefaultDate: Date;

    // Call cycle min and max dates.
    public minDate: Date;
    public maxDate: Date;

    // Contains en location information for the calendar.
    public en: any;

    // Array of dates that cannot be selected for the from calendar.
    public fromInvalidDates: Array<Date>

    // Array of dates that cannot be selected for the to calendar.
    public toInvalidDates: Array<Date>

    // Listen for validation events.
    private _validationSubscription: Subscription;

    constructor(
        private _applicationStore: ApplicationStore) {
    }

    public ngOnInit() {
        this.minDate = this._applicationStore.projectsStore.selectedProject.scheduleStartDate;
        this.maxDate = this._applicationStore.projectsStore.selectedProject.scheduleEndDate;
        this.subscribeToDaySwapValidationResult();

        // // Auto set the toDate to be the next day from the fromDate.
        // if(this.fromDate) {
        //     let momentDate = moment(this.fromDate, '"DD/MM/YYYY"');
        //     momentDate.add(1, 'days');
        //     this.toDate = momentDate.toDate();
        //     this.onToDateSelected(null);
        // }

        if(this.fromDate) {
            this.fromInvalidDates = [];
            this.toInvalidDates = [this.fromDate];

            this.toDateDefaultDate = new Date();
            this.toDateDefaultDate.setMonth(this.fromDate.getMonth());
            this.toDateDefaultDate.setFullYear(this.fromDate.getFullYear());
        }

        // This property customises all calendar names and formats to be displayed according to the design.
        this.en = {
            firstDayOfWeek: 1,
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: 'Today',
            clear: 'Clear'
        };
    }

    public ngOnDestroy(): void {
        if (this._validationSubscription) {
            this._validationSubscription.unsubscribe();
        }
    }

    // Called when the user clicks on the Cancel button or close icon in the
    // header.
    public onCancel() {
        this.display = false;
        this.cancel.next();
    }

    // Called when the user clicks on the Swap button.
    public onSave(form: NgForm) {
        this.display = false;
        this.saved.next();

        let fromDayIndex: number = CallsmartUtils.getIndexDayInCycle(this.fromDate, new Date(this.minDate));
        let toDayIndex: number = CallsmartUtils.getIndexDayInCycle(this.toDate, new Date(this.minDate));

        this._applicationStore.scheduleStore.swapDaysInSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
            fromDayIndex, toDayIndex, this.toDate, this._applicationStore.uiStore.getActiveView());
    }

    // Event handler for when the user selects the from date from the calendar.
    public onFromDateSelected(event: any) {
        this.validateSwap();
        this.toInvalidDates = [this.fromDate];
    }

    // Event handler for when the user selects the to date from the calendar.
    public onToDateSelected(event: any) {
        this.validateSwap();
        this.fromInvalidDates = [this.toDate];
    }

    // Perform validation between the two days.
    public validateSwap() {
        if (this.fromDate && this.toDate) {
            let fromDayIndex: number = CallsmartUtils.getIndexDayInCycle(this.fromDate, new Date(this.minDate));
            let toDayIndex: number = CallsmartUtils.getIndexDayInCycle(this.toDate, new Date(this.minDate));

            this._applicationStore.scheduleStore.validateSwapDaysInSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
                fromDayIndex, toDayIndex);

        }
    }

    private subscribeToDaySwapValidationResult() {
        this._validationSubscription = this._applicationStore.scheduleStore.daySwapValidationResult.subscribe(
            (scheduleWarnings: string) => {
                if (scheduleWarnings) {
                    this.hasValidationError = true;
                    this.validationErrors = scheduleWarnings.split('\n');

                    if (scheduleWarnings.toLowerCase().indexOf('overnight') >= 0 && this.validationErrors.length == 1) {
                        // Overnight error message should still allow the user to make the swap.
                        this.hasOvernightValidationError = true;
                        this.hasValidationError = false;
                    }
                    else {
                        this.hasOvernightValidationError = false;
                    }
                }
                else {
                    this.hasValidationError = false;
                }
            });
    }
}
