import { Component, ViewChild, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Project } from 'app/models/project';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';
import { Caller } from 'app/models/caller';

/**
 * Callers settings component for the Project.
 */
@Component({
   templateUrl: 'project-properties-callers.component.html'
})
export class ProjectPropertiesCallersComponent implements OnInit, CanComponentDeactivate {

   @ViewChild('editCallerSettings') editCallerSettings;

   // Subscription for the project.
   private _project_subscription: Subscription;

   // Caller settings model.
   public callerSettingsModel: CallerSettings;

   // Scroll height for the scroll panel.
   public scrollHeight: number;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   public projectCycleLength:number;

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _router: Router) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 200;
      });
   }

   ngOnInit() {
      this.subscribeToProjectCallerSettings();
   }

   ngOnDestroy() {
      if (this._project_subscription) {
         this._project_subscription.unsubscribe();
      }
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editCallerSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editCallerSettings.callerSettingsForm.pristine;
   }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editCallerSettings.callerSettingsForm.form.markAsPristine();
      this._applicationStore.projectsStore.updateCallerSettings(this.callerSettingsModel);

      
      // For any caller that is using default contracted working days, work out the closed dates and apply them.
      let updatedCallerList: Caller[] = [];
      this._applicationStore.callersStore.callers.forEach((caller: Caller) => {
         if (caller.callerSettings === null || caller.callerSettings === undefined || caller.callerSettings.workingDayActive === undefined) {
            let workingDays: boolean[] = this.callerSettingsModel.workingDayActive;

            let numOfWeeks = this._applicationStore.projectsStore.selectedProject.projectSettings.callCycleLength;
            let formattedClosedDates: string[] = CallsmartUtils.formatClosedDates(numOfWeeks, workingDays);

            caller.datesClosed = formattedClosedDates;
            if(caller.callerSettings != null ) {
               caller.callerSettings.contractedWorkingHoursFriday = this.callerSettingsModel.contractedWorkingHoursFriday;
               caller.callerSettings.contractedWorkingHoursMonday = this.callerSettingsModel.contractedWorkingHoursMonday;
               caller.callerSettings.contractedWorkingHoursTuesday = this.callerSettingsModel.contractedWorkingHoursTuesday;
               caller.callerSettings.contractedWorkingHoursWednesday = this.callerSettingsModel.contractedWorkingHoursWednesday;
               caller.callerSettings.contractedWorkingHoursThursday = this.callerSettingsModel.contractedWorkingHoursThursday;
               caller.callerSettings.contractedWorkingHoursSaturday = this.callerSettingsModel.contractedWorkingHoursSaturday;
               caller.callerSettings.contractedWorkingHoursSunday = this.callerSettingsModel.contractedWorkingHoursSunday;
            }
            updatedCallerList.push(caller);
         }
      });

      if(updatedCallerList.length > 0) {
         this._applicationStore.callersStore.updateCallers(this._applicationStore.projectsStore.selectedProject.projectId, updatedCallerList);
      }

   }

   public onCancel() {
      this._router.navigate(['/dashboard']);
   }

   private subscribeToProjectCallerSettings() {
      this._project_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project != null) {
               this.callerSettingsModel = CallsmartUtils.deepClone<CallerSettings>(project.callerSettings, CallerSettings);
               this.projectCycleLength = project.projectSettings.callCycleLength;
            }
         }
      );
   }
}
