import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Subscription } from 'rxjs';

import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { Company } from 'app/models/company';
import { ProjectSettings } from 'app/models/settings/project-settings';

/**
 * Dialog component allowing the user to select a travel model for project that had the travel model deleted. 
 * This can happen for example when the admin deletes a travel model.
 */
@Component({
   selector: 'travel-model-dialog',
   templateUrl: 'travel-model-dialog.component.html'
})
export class TravelModelDialogComponent implements OnDestroy {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() save = new EventEmitter<void>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // List of available travel models.
   public travelModelList: SelectItem[];

   // User selected travel model.
   public selectedTravelModel: any;

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   // The company belonging to the current project.
   private company: Company;

   // Project settings model object.
   public projectSettings: ProjectSettings

   private _user_company_subscription: Subscription;

   public constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });

      this._user_company_subscription = this._applicationStore.authenticationStore.userCompany$.subscribe(
         (company: Company) => {
            this.configureTravelModels(company);
         }
      );
   }

   public ngOnDestroy() {
      if (this._user_company_subscription) {
         this._user_company_subscription.unsubscribe();
      }
   }

   // Update the project settings with the new travel model and hide the dialog.
   public onSave(form: NgForm) {
      let travelModel = this.company.travelModels.find(t => t.travelModelId == this.selectedTravelModel.id);

      let projectSettings = this._applicationStore.projectsStore.selectedProject.projectSettings;
      projectSettings.travelModel = travelModel;
      projectSettings.travelModelId = travelModel.travelModelId;

      this._applicationStore.projectsStore.updateCreateNewProjectSettingSettings(this._applicationStore.projectsStore.selectedProject.projectId,
         projectSettings);

      this.display = false;
      this.save.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   private configureTravelModels(company: Company) {
      //Initialise travel file dropdown list
      this.travelModelList = [];
      this.company = company;
      if (company) {
         if (company.travelModels) {
            company.travelModels.forEach(t => {
               this.travelModelList.push({ label: t.travelModelName, value: { id: t.travelModelId, name: t.travelModelName } });
            });
            this.travelModelList.sort((a, b) => a.label.localeCompare(b.label));

            // Preselect the first travel model in the list.
            this.selectedTravelModel = this.travelModelList[0].value;
         }
      }
   }
}
