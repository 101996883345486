import { Component, OnInit } from '@angular/core';
import { SelectItem } from "primeng/primeng";
import {TooltipModule} from 'primeng/primeng';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';
import { ApplicationStore } from 'app/stores/application-store';

@Component({
   selector: 'style-guide',
   templateUrl: 'style-guide.component.html'
})

export class StyleGuideComponent implements OnInit {

   public testTime:string = "18:00";

   public mapStyleJson:any;

   contentList: SelectItem[];

   selectedcontent: string;

   selected: boolean = true;
   unselected: boolean = false;

   constructor(private _alertStore:AlertStore, private _applicationStore:ApplicationStore) {
      this.contentList = [];
     // this.contentList.push({ label: 'Select Content', value: null });
      this.contentList.push({ label: 'New York', value: { id: 1, name: 'New York', code: 'NY', disabled: false } });
      this.contentList.push({ label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' ,disabled: false } });
      this.contentList.push({ label: '&pound;', value: { id: 3, name: '&pound;', code: 'gbp' , disabled: false} });
      this.contentList.push({ label: '&yen;', value: { id: 4, name: '&yen;', code: 'yen' , disabled: false } });
      this.contentList.push({ label: 'Paris', value: { id: 5, name: 'Paris', code: 'PRS' , disabled: false} });
   }

   ngOnInit(): void {

   }

   public saveMapStyle(){
      this._applicationStore.mapsStore.customMapStyle = JSON.parse(this.mapStyleJson);
   }

   public dummyAlert(){
      this._alertStore.sendAlert(new Alert('Error', 'This is an example of the new the alert box'));
   }
}
