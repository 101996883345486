<div style="display:flex; flex-direction:column; justify-content:flex-start; padding-bottom:20px;">
   <div *ngFor="let week of calendarDates; let i = index" style="padding:20px; padding-bottom:4px">

      <span style="font-size:14px; font-weight:bold; margin-right:10px;">Week {{i+1}}</span>
      <span>{{momentStartCycleDate.clone().add(7*i,'days').format("DD/MM/YYYY")}} - {{momentStartCycleDate.clone().add(7*(i+1)-1,'days').format("DD/MM/YYYY")}}</span>
      <br>
      <div style="display:flex; flex-direction:row; align-items:stretch; margin-top:10px; margin-bottom:0px;">
         <div style="margin-top:9px; margin-right:10px;">Part 1</div>
         <div *ngFor="let day of week" style="flex:1">
            <button type="button" class="btn close-calendar-day-btn" (click)="onPart1ButtonClick(day)" 
                    [ngClass]="{'close-calendar-day-btn-selected': day.isPart1Open}">
               {{day.content}}
            </button>
         </div>
      </div>

      <div style="display:flex; flex-direction:row; align-items:stretch; margin-bottom:0px;">
            <div style="margin-top:9px; margin-right:10px;">Part 2</div>
            <div *ngFor="let day of week" style="flex:1">
               <button type="button" class="btn close-calendar-day-btn" (click)="onPart2ButtonClick(day)" 
                       [ngClass]="{'close-calendar-day-btn-selected': day.isPart2Open}">
                  {{day.content}}
               </button>
            </div>
         </div>
   </div>
</div>
