import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Component that represents a step in the Wizard.
 */
@Component({
   selector: 'callsmart-step',
   templateUrl: 'step.component.html'
})
export class StepComponent implements OnChanges{
   // Style property for this component.
   @Input() styleClass: string;

   // Label for this step.
   @Input() label: string;

   // The form state inside this step.
   @Input() formValid: boolean;

   // Determine whether this step is active or not.
   public active: boolean = false;

   // Determines whether this step has been visited before.
   public visited: boolean = false;

   // Event raised when the state of the Form changes.
   private _formStateChanged = new Subject<boolean>();
   public formState$ = this._formStateChanged.asObservable();

   constructor() {}

   // Detect changes in this step and fire a change event. Typically this
   // fires when the form state changes (from valid to invalid for examaple).
   ngOnChanges(changes: SimpleChanges): void {
      if(this.active) {
         this._formStateChanged.next(!this.formValid);
      }
   }
}