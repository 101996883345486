import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationStore } from 'app/stores/application-store';

/*
  this guard is to be used as a routing guard to prevent the user from navigating to pages without company  Admin  rights or being logged in
*/
@Injectable()
export class CompanyAdminGuardService {

   constructor(private _applicationStore: ApplicationStore) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // user has logged in
      if (this._applicationStore.authenticationStore.loggedInUser != null) {
         if (this._applicationStore.authenticationStore.loggedInUser.userId != 0) {
            return this._applicationStore.authenticationStore.loggedInUser.isCompanyAdmin
         }
      }

      return false;
   }
}
