import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ReportParameters } from 'app/models/report-parameters';

@Component({
   selector: 'callsmart-admin-audit-panel',
   templateUrl: './admin-audit-panel.component.html'
})
export class AdminAuditPanelComponent implements OnInit {

   public scrollHeight: number = 200;
   public workspaceHeight: number;
   public en: any;
   public startDate:Date;
   public endDate:Date;

   public exportOptions = {
      exportUserAccess: false,
      exportOptimisationLog: false,
      exportServiceRequestLog:false
   };

   public constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService, ) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
   }

   ngOnInit() {

       // This property customises all calendar names and formats to be displayed according to the design.
       this.en = {
         firstDayOfWeek: 1,
         dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
         dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
         monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         today: 'Today',
         clear: 'Clear'
      };

   }

   public onExport(form: NgForm) {

      let params = new ReportParameters();

      params.startDate = form.value.startDate;
      params.endDate = form.value.endDate;

      // check what export options have been chosen
      if (form.value.exportUserAccess) {
         this._applicationStore.auditStore.exportUserAccessForDataRange(params);
      }

      if (form.value.exportOptimisationLog) {
         this._applicationStore.auditStore.exportOptimisationsLogForDataRange(params);
      }

      if (form.value.exportServiceRequestLog) {
         this._applicationStore.auditStore.exportServiceRequetsLogForDataRange(params);
      }
   }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 220;
      this.scrollHeight = this.workspaceHeight * 0.985 ;
   }

}
