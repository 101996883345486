<div style="display: flex;flex-direction: row ;align-items: flex-start;width: 100%;height: 100%">

   <div class="dash-panel-left">
      <h3 style="color: #3cbe85">{{project?.name}}</h3>
      <hr style="width: 100%; height:1px; background: #575757">
      <div style="color: #222222">
         <div class="contextualPanel-list-items-firstline">Number of callers</div>
         <div class="dash-panel-list-items-secondline">{{totalCallers}}</div>

         <div class="contextualPanel-list-items-firstline">Number of callpoints</div>
         <div class="dash-panel-list-items-secondline">{{totalCallpoints}}</div>

         <div class="contextualPanel-list-items-firstline">Call Cycle length</div>
         <div class="dash-panel-list-items-secondline">{{project.projectSettings.callCycleLength}} weeks</div>

         <div class="contextualPanel-list-items-firstline">Travel model</div>
         <div class="dash-panel-list-items-secondline">{{project.projectSettings.travelModel == null ? '' : project.projectSettings.travelModel.travelModelName}}</div>
      </div>
      <!-- <hr style="width: 100%; height:1px; background: #575757"> -->
   </div>

   <div style="width: 100%;height: 100%; display: flex; flex-flow: column">
      <!-- Metric boxes -->
      <div style="display: flex;flex-direction: column; align-items: center;justify-content: center;">
         <div style="width: 100%">
            <div class="dash-panel grey" style="margin-top: 0px">
               <i class="cs-icon icon-briefcase"></i>
               <div class="big-text">{{dashboardModel.avgVisitsPerDay | number}}</div>
               <div class="xs-small-text">Avg visits per day</div>
            </div>
            <div class="dash-panel grey" style="margin-top: 0px">
               <i class="cs-icon icon-cloud"></i>
               <div class="big-text">{{dashboardModel.co2Footprint}}
                  <small>
                     <small>{{dashboardModel.co2Unit}}</small>
                  </small>
               </div>
               <div class="xs-small-text">CO
                  <sub>2</sub> footprint</div>
            </div>
            <div class="dash-panel grey" style="margin-top: 0px">
               <i class="cs-icon icon-calendar-6"></i>
               <div class="big-text">{{dashboardModel.noOfVisits | number}}

               </div>
               <div class="xs-small-text">Number of visits</div>
            </div>
            <div class="dash-panel grey" style="margin-top: 0px">
               <i class="cs-icon icon-calendar-6"></i>
               <div class="big-text">{{dashboardModel.visitsBooked | number}}

               </div>
               <div class="xs-small-text">Number of scheduled visits</div>
            </div>
         </div>
         <div style="width: 100%">
            <div class="dash-panel border">
               <i class="cs-icon icon-direction"></i>
               <div class="big-text">{{dashboardModel.visitsBookedPercentage }} %</div>
               <div class="xs-small-text">Percentage of scheduled visits</div>
            </div>
            <div class="dash-panel border">
               <i class="cs-icon icon-direction"></i>
               <div class="big-text">{{dashboardModel.travelDistance | distanceUnits:dashboardModel.distanceUnitIsMiles|
                  number:'1.0-0'}} {{dashboardModel.distanceUnitLabel}}</div>
               <div class="xs-small-text">Total travel distance </div>
            </div>
            <div class="dash-panel border">
               <i class="cs-icon icon-clock-2"></i>
               <div class="big-text">
                  {{dashboardModel.totalTravelTimeDay}}
                  <small>
                     <small>d</small>
                  </small>&nbsp; {{dashboardModel.totalTravelTimeHour}}
                  <small>
                     <small>h</small>
                  </small>&nbsp; {{dashboardModel.totalTravelTimeMinute}}
                  <small>
                     <small>m</small>
                  </small>&nbsp;
               </div>
               <div class="xs-small-text">Total travel time</div>
            </div>
         </div>
         <div style="width: 100%">
            <div class="dash-panel grey">
               <i class="cs-icon icon-briefcase"></i>
               <div class="big-text">{{dashboardModel.avgTravelTimeBetweenVisit | number:'1.0-1'}} minutes</div>
               <div class="xs-small-text">Avg travel time between visit</div>
            </div>
            <div class="dash-panel grey">
               <i class="cs-icon icon-cloud"></i>
               <div class="big-text">{{dashboardModel.avgTravelDistanceBetweenVisit | distanceUnits:dashboardModel.distanceUnitIsMiles
                  | number:'1.0-1'}} {{dashboardModel.distanceUnitLabel}}
               </div>
               <div class="xs-small-text">Avg travel distance between visit</div>
            </div>
            <div class="dash-panel grey">
               <i class="cs-icon icon-briefcase"></i>
               <div class="big-text">{{dashboardModel.avgOneWayDailyCommuteTime | number:'1.0-1'}} minutes</div>
               <div class="xs-small-text">Avg 1-way daily commute time</div>
            </div>
            <div class="dash-panel grey">
               <i class="cs-icon icon-cloud"></i>
               <div class="big-text">{{dashboardModel.avgOneWayDailyCommuteDistance | distanceUnits:dashboardModel.distanceUnitIsMiles
                  | number:'1.0-1'}} {{dashboardModel.distanceUnitLabel}}
               </div>
               <div class="xs-small-text">Avg 1-way daily commute distance</div>
            </div>
         </div>
      </div>
      <!-- Import/Export buttons -->
      <div style="display: flex;flex-direction: column; align-self:flex-end; width: 100%;flex:1">
         <!-- 'margin-top: auto' is the trick to push HR and Div down to the bottom of the page -->
         <hr style="width: 100%; height:1px; background: #575757; margin-top: auto">
         <div style="display: flex; flex-direction: row; justify-content: flex-end; min-width: 100%;margin-bottom: 20px;">
            <!-- 'flex-end' to start setting the items from right to left -->
            <h5 class="pull-right">Import new Caller and Callpoint data:</h5>
            <div style="float:right; margin-left:30px; margin-right:30px; border-right:1px solid #222222; padding-right:30px;">
               <button type="button" class="btn cs-btn-primary-inverse" (click)="onImportData()">
                  <i class="cs-icon icon-download export-filter-text"></i>Import</button>
            </div>

            <h5 class="pull-right">Export Callers, Callpoints and Schedules:</h5>
            <div style="float:right; margin-left:30px;margin-right:30px;">
               <button type="button" class="btn cs-btn-primary-inverse" (click)="onExportData()">
                  <i class="cs-icon icon-upload export-filter-text"></i>Export</button>
            </div>
         </div>
      </div>
   </div>

</div>
<ndc-dynamic *ngIf="showExportProjectDialog" [ndcDynamicComponent]="exportProjectDialog" [ndcDynamicInputs]="exportDialogInput"
   [ndcDynamicOutputs]="exportDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showImportProjectDialog" [ndcDynamicComponent]="importProjectDialog" [ndcDynamicInputs]="importDialogInput"
   [ndcDynamicOutputs]="importDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showTravelModelDialog" [ndcDynamicComponent]="travelModelDialog" [ndcDynamicInputs]="travelModelDialogInput"
   [ndcDynamicOutputs]="travelModelDialogOutput">
</ndc-dynamic>
