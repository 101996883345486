<h3 class="col-md-12" style="padding-bottom: 15px">
   New project defaults
</h3>
<div class="panel panel-default">
   <div class="panel-body">

      <div class="nav-left top-content-border bottom-content-border"
         [ngStyle]="{'height.px': menuHeight}">
         <p-menu [model]="menu"></p-menu>
      </div>
      
      <div class="col-md-9">
         <router-outlet></router-outlet>
      </div>
      
   </div>
</div>
