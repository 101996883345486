import { Component, Input, forwardRef, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';

import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { CallpointSettingsViewModel } from 'app/models/view-models/callpoint-settings-view';
import { DayCombination } from 'app/models/settings/day-combination';
import { ApplicationStore } from 'app/stores/application-store';

declare var google;  // allows google api namespace to work

// Set up a new provider to tell angular that this component supports
// ControlValueAccessor methods and forms
const EDIT_CALLPOINT_SETTINGS_VALUE_ACCESSOR = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => EditCallpointSettingsComponent),
   multi: true
};

enum CallpointSettingSection {
   Availability,
   DayCombination,
   Frequency,
   Duration,
   Location,
   Priority,
   Critical
}

/**
 * Reusable component for Callpoint settings. Supports Form validation and ngModel synchronisation.
 * Supports overriding of callpoint settings and editing of multiple callpoint settings.
 *
 * Usage example:
 *     <edit-callpoint-settings name="editCallpointSettings"
 *        [(ngModel)]="callpointSettingsModel"
 *        [scrollHeight]="scrollHeight"
 *        [showOverrideButtons]="true"
 *        [displayCallpointSpecificSettings]="true"
 *        [allPossibleDayCombinations]="allPossibleDayCombinations"
 *        [recommendedDayCombinations]="recommendedDayCombinations"
 *        [projectActiveWorkingDays]="projectActiveWorkingDays"
 *        [editingMultipleCallpoints]="editingMultipleCallpoints">
 *     </edit-callpoint-settings>
 *
 * Properties:
 * name - Unique name for this tag, this is required by Angular when used in conjunction with ngModel.
 * ngModel - The model object that represents the form properties for this component.
 * scrollHeight - This is the height for the scroll panel.
 * showOverrideButtons - values: true | false, defaults to false. Toggles the visibility of the override buttons.
 * displayCallpointSpecificSettings - values: true | false, defaults to false. Toggles the visibility of the callpoint properties.
 * allPossibleDayCombinations - All day combination data.
 * recommendedDayCombinations - Recommended day combination data.
 * projectActiveWorkingDays - Project working active days data.
 * editingMultipleCallpoints - values: true | false, defaults to false. Toggles the visibility of the checkboxes used for
 * editing multiple callpoints.
 */
@Component({
   selector: 'edit-callpoint-settings',
   templateUrl: 'edit-callpoint-settings.component.html',
   providers: [EDIT_CALLPOINT_SETTINGS_VALUE_ACCESSOR]
})
export class EditCallpointSettingsComponent implements ControlValueAccessor, OnInit, AfterViewInit {

   @ViewChild('frequencyInput') frequencyInput;
   @ViewChild('durationInput') durationInput;
   @ViewChild('latitudeInput') latitudeInput;
   @ViewChild('longitudeInput') longitudeInput;
   @ViewChild('priorityInput') priorityInput;
   @ViewChild('priorityAmplifierInput') priorityAmplifierInput;
   @ViewChild('temporalPrioritisationInput') temporalPrioritisationInput;

   // Provides access for the parent component to access the validation status of this form.
   @ViewChild('callpointSettingsForm') callpointSettingsForm;

   // This is used by the scroll pane to determine the scroll height.
   @Input() scrollHeight: number;

   // Determine whether to show the override buttons or not.
   @Input() showOverrideButtons: boolean = false;

   // Determine whether to show the multi edit checkboxes for each section.
   // Used when multiple callpoint settings need to be edited.
   @Input() editingMultipleCallpoints: boolean = false;

   // Determine whether to show the panel heading.
   @Input() displayHeading: boolean = true;

   @Input() displayCallpointSpecificSettings: boolean = false;

   // Hides the checkbox element when readonly list
   public _isDayCombinationsHidden: boolean;
   @Input()
   get isDayCombinationsHidden(): boolean {
      return this._isDayCombinationsHidden;
   }
   set isDayCombinationsHidden(isDayCombinationsHidden: boolean) {
      this._isDayCombinationsHidden = isDayCombinationsHidden;
      if (this._isDayCombinationsHidden) {
         // 'Allow all' dayCombinations options is selected.
         this._previousSpecificDayCombinations = this.callpointSettings.dayCombinations;
         // All dayCombinations are set
         this.callpointSettings.dayCombinations = this._allPossibleCombinations;
      }
      else if (!this.isDayCombinationsHidden && this.displayRecommended) {
         // Allow specific combinations is selected and the recommended must be displayed.
         this.selectRecommendedDayCombination();
         this.displayRecommended = false;
      }
      else {
         // Allow specific combinations is selected and the previous selection must be displayed.
         this.callpointSettings.dayCombinations = this._previousSpecificDayCombinations;
         this.selectCallpointSettingsDayCombinations();
      }
      this._formStateChanged.next(this.formValid);
   }

   // All possible day combinations to feed each DayCombination control
   private _allPossibleDayCombinations: DayCombination[] = [];
   @Input()
   get allPossibleDayCombinations(): DayCombination[] {
      return this._allPossibleDayCombinations;
   }
   set allPossibleDayCombinations(allPossibleDayCombinations: DayCombination[]) {
      this._allPossibleDayCombinations = allPossibleDayCombinations;

      if (this.allPossibleDayCombinations.length !== 0) {
         this._allPossibleCombinations = this.allPossibleDayCombinations.map(item => item.combination);
      }
   }

   // Day combinations with active working days
   public dayCombinationsWithActiveWorkingDays: DayCombination[] = [];

   // Recommended combinations (string value)
   @Input() recommendedDayCombinations: string[] = [];
   private _projectActiveWorkingDays: boolean[] = [];
   @Input()
   get projectActiveWorkingDays(): boolean[] {
      return this._projectActiveWorkingDays;
   }
   set projectActiveWorkingDays(projectActiveWorkingDays: boolean[]) {
      this._projectActiveWorkingDays = projectActiveWorkingDays;
      if (this._projectActiveWorkingDays) {
         this.dayCombinationsWithActiveWorkingDays = this.getDayCombinationsWithActiveWorkingDays(this._projectActiveWorkingDays);
      }
   }

   @Input() projectCycleLength: number;
   //@Input() callerDuration: number;

   // All dayCombinations grouped by visits per week to be displayed in the UI list
   public _allPossibleDayCombinationsGrouped: DayCombination[][] = [];
   get allPossibleDayCombinationsGrouped(): DayCombination[][] {
      return this._allPossibleDayCombinationsGrouped;
   }
   set allPossibleDayCombinationsGrouped(allPossibleDayCombinationsGrouped: DayCombination[][]) {
      if (allPossibleDayCombinationsGrouped) {
         // Only selected combinations are displayed when editing
         // the settings of specific callpoints
         if (this.showOverrideButtons) {
            let allSelectedDayCombinationsGrouped: DayCombination[][] = [];
            allPossibleDayCombinationsGrouped.forEach(group => {
               let visitsPerDay = group.filter(item => item.isSelected);
               if (visitsPerDay.length > 0) {
                  allSelectedDayCombinationsGrouped.push(visitsPerDay);
               }
            });
            this._allPossibleDayCombinationsGrouped = allSelectedDayCombinationsGrouped;
         }
         // Displaying all possible combinations.
         else {
            this._allPossibleDayCombinationsGrouped = allPossibleDayCombinationsGrouped;
         }
      }
   }

   public boundsMin: Date = new Date('2017-05-19T00:00:00');
   public boundsMax: Date = new Date('2017-05-20T00:00:00');
   public callpointSettings: CallpointSettings | CallpointSettingsViewModel;
   public displayRecommended: boolean = true;
   public displayReadOnlyDayCombinationsBasedOnFrequency: boolean = false;
   public callpointAvailabilityDuration: number;

   // Callpoint earliest date and latest date ranges.
   public minDate: Date;
   public maxDate: Date;

   public isAvailabilityValid: boolean = true;
   public isFrequencyValid: boolean = true;
   public isDurationValid: boolean = true;
   public isPriorityValid: boolean = true;
   public isPriorityAmplifierValid: boolean = true;
   public isTemporalPrioritisationValid: boolean = true;

   public isFrequencyFormatValid: boolean = true;
   public isDurationFormatValid: boolean = true;
   public isLatitudeFormatValid: boolean = true;
   public isLongitudeFormatValid: boolean = true;
   public isPriorityFormatValid: boolean = true;
   public isPriorityAmplifierFormatValid: boolean = true;
   public isTemporalPrioritisationFormatValid: boolean = true;
   public isCallpointStartDateValid: boolean = true;
   public isCallpointEndDateValid: boolean = true;
   public searchPlaceApplyButtonDisabled: boolean = true;

   // Contains en location information for the calendar.
   public en: any;

   // Checks whether the form is valid.
   public get formValid(): boolean {
      if (this.callpointSettings) {
         return this.isAvailabilityValid && this.isFrequencyValid && this.isFrequencyFormatValid &&
            this.isDurationValid && this.isDurationFormatValid && this.isLatitudeFormatValid &&
            this.isLongitudeFormatValid && this.isPriorityValid && this.isPriorityFormatValid &&
            this.isPriorityAmplifierValid && this.isPriorityAmplifierFormatValid &&
            this.isTemporalPrioritisationValid && this.isTemporalPrioritisationFormatValid &&
            this.isCallpointStartDateValid && this.isCallpointEndDateValid;
      }
      return false;
   }

   // Event raised when the state of the Form changes.
   private _formStateChanged = new Subject<boolean>();
   public formState$ = this._formStateChanged.asObservable();

   // Create a reference to the global enum inside this component so the HTML can have
   // access to it.
   public mySettingSection = CallpointSettingSection;

   // Stores all possible dayCombinations for 'Allow all' option
   private _allPossibleCombinations: string[] = [];
   // Stores the previous 'Allow specific' dayCombinations to be displayed again after changing the Allow all dayCombinatios
   private _previousSpecificDayCombinations: string[] = [];

   // Handler methods for ControlValueAccessor
   private onTouched = () => { };
   private onChange: (value: any[]) => {};

   private searchPlaceLat: string;
   private searchPlaceLng: string;

   private selectedCallpointLat: string;
   private selectedCallpointLng: string;

   constructor(private _cdr: ChangeDetectorRef,
      private _applicationStore: ApplicationStore) {
   }

   ngOnInit() {
      this.searchPlaceLat = '';
      this.searchPlaceLng = '';
      this.searchPlaceApplyButtonDisabled = true;

      // These only need to be set if the project properties are being set and not for
      // the default project settings.
      if(this._applicationStore.projectsStore.selectedProject) {
         this.minDate = this._applicationStore.projectsStore.selectedProject.scheduleStartDate;
         this.maxDate = this._applicationStore.projectsStore.selectedProject.scheduleEndDate;
      }

      // This property customises all calendar names and formats to be displayed according to the design.
      this.en = {
         firstDayOfWeek: 1,
         dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
         dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
         dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
         monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         today: 'Today',
         clear: 'Clear'
     };
   }

   ngAfterViewInit() {
      setTimeout(() => this.getPlaceAutocomplete());
   }

   // Callback method to sync the form values with this model.
   writeValue(data: any): void {
      this.callpointSettings = data;
      if (this.callpointSettings && this.callpointSettings.dayCombinations) {
         this.selectCallpointSettingsDayCombinations();
         this.setAllPossibleDayCombinationsGrouped();
         this._isDayCombinationsHidden = this.callpointSettings.dayCombinations.length === this._allPossibleDayCombinations.length;
         if (!this._isDayCombinationsHidden) {
            //this.selectCallpointSettingsDayCombinations();
            this.displayRecommended = false;
         }
         let availabilityStartTime = moment(this.callpointSettings.availability[0].toISOString());
         let availabilityEndTime = moment(this.callpointSettings.availability[1].toISOString());
         this.callpointAvailabilityDuration = availabilityEndTime.diff(availabilityStartTime, 'minutes');

         // When selecting multiple callpoints, the lat/lng in the setting view model is cleared for the UI
         // to display empty input boxes. The Google Places API cannot then localise the search. So we need
         // to copy the lat/lngs here and use these properties to intialise the Google Places API.
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            this.selectedCallpointLat = this.callpointSettings.latitude;
            this.selectedCallpointLng = this.callpointSettings.longitude;
            
         }
      }
   }

   // Registers a callback method to invoke when a change event happens.
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   // Registers a callback method to invoke when a touch event happens.
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   // Handles the selection of a dayCombination in the UI.
   public OnSelectedDayCombinationChanged(dayCombinationEvent: DayCombination) {
      if (dayCombinationEvent) {
         if (dayCombinationEvent.isSelected) {
            // Day combination has been selected...
            // New combination is added to the callpointsSettings.dayCombinations collection
            this.callpointSettings.dayCombinations.push(dayCombinationEvent.combination);
         }
         else {
            // Day combination has been deselected...
            // Te combination is removed from the callpointsSettings.dayCombinations collection
            let index: number = this.callpointSettings.dayCombinations.indexOf(dayCombinationEvent.combination);
            if (index > -1) {
               this.callpointSettings.dayCombinations.splice(index, 1);
            }
         }
         // Since the day combination subcomponent will not update the form, when the inputs change, the form
         // has to be marked as dirty here so that the user can be warned if they decide to navigate away
         // without saving first.
         this.callpointSettingsForm.form.markAsDirty();
         this._formStateChanged.next(this.formValid);
      }
   }

   // Helper method to check if the inputs represented by the section passed in can be displayed.
   // If the callpointSettings is of type CallpointSettings or we are not in multi edit mode then always return true since the inputs always need to be
   // shown.
   // Else we only show the inputs if the multi edit checkbox is set by the user or the properties are the same
   // between all the callers.
   public canShowInputs(section: CallpointSettingSection) {
      if (this.callpointSettings instanceof CallpointSettings || !this.editingMultipleCallpoints) {
         // The settings are either being displayed in the project settings or a single
         // callpoint is selected. In both cases always display the input fields.
         return true;
      }
      else {
         // Multiple callpoints are being edited so display the input fields if the user has selected
         // the multi edit checkbox or the properties are the same between all callpoints.
         let result: boolean = false;
         switch (section) {
            case CallpointSettingSection.Availability: {
               result = this.callpointSettings.availabilityMultiEdit || this.callpointSettings.availabilitySameValuesSet;
               break;
            }
            case CallpointSettingSection.Frequency: {
               result = this.callpointSettings.frequencyMultiEdit || this.callpointSettings.frequencySameValuesSet;
               break;
            }
            case CallpointSettingSection.Duration: {
               result = this.callpointSettings.durationMultiEdit || this.callpointSettings.durationSameValuesSet;
               break;
            }
            case CallpointSettingSection.DayCombination: {
               result = this.callpointSettings.frequencyMultiEdit || this.callpointSettings.frequencySameValuesSet;
               break;
            }
            case CallpointSettingSection.Location: {
               result = this.callpointSettings.locationMultiEdit || this.callpointSettings.locationSameValuesSet;
               break;
            }
            case CallpointSettingSection.Priority: {
               result = this.callpointSettings.priorityMultiEdit || this.callpointSettings.prioritySameValuesSet;
               break;
            }
            case CallpointSettingSection.Critical: {
               result = this.callpointSettings.criticalMultiEdit || this.callpointSettings.criticalSameValuesSet;
               break;
            }

         }
         return result;
      }
   }

   public onCheckCallpointAvailability(event: Date[]) {
      if (event && event.length === 2) {

         let availabilityStartTime = moment(this.callpointSettings.availability[0].toISOString());
         let availabilityEndTime = moment(this.callpointSettings.availability[1].toISOString());
         this.callpointAvailabilityDuration = availabilityEndTime.diff(availabilityStartTime, 'minutes');
         // Checks the availability duration
         this.isAvailabilityValid = this.callpointAvailabilityDuration > 0;
         this.validateDuration();
         this._formStateChanged.next(this.formValid);
      }
   }

   public onFrequencyInputValueChanged(event: any) {
      if (!this.frequencyInput.errors) {
         this.validateFrequency();
         this.isFrequencyFormatValid = true;
      }
      else {
         this.isFrequencyFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onDurationInputValueChanged(event: any) {
      if (!this.durationInput.errors) {
         this.validateDuration();
         this.isDurationFormatValid = true;
      }
      else {
         this.isDurationFormatValid = false;
         this.displayReadOnlyDayCombinationsBasedOnFrequency = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onLatitudeInputValueChanged(event: any) {
      if (!this.latitudeInput.errors) {
         this.isLatitudeFormatValid = true;
      }
      else {
         this.isLatitudeFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onLongitudeInputValueChanged(event: any) {
      if (!this.longitudeInput.errors) {
         this.isLongitudeFormatValid = true;
      }
      else {
         this.isLongitudeFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onPriorityInputValueChanged(event: any) {
      if (!this.priorityInput.errors) {
         this.validatePriority();
         this.isPriorityFormatValid = true;
      }
      else {
         this.isPriorityFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onPriorityAmplifierInputValueChanged(event: any) {
      if (!this.priorityAmplifierInput.errors) {
         this.validatePriorityAmplifier();
         this.isPriorityAmplifierFormatValid = true;
      }
      else {
         this.isPriorityAmplifierFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onTemporalPrioritisationInputValueChanged(event: any) {
      if (!this.temporalPrioritisationInput.errors) {
         this.validateTemporalPrioritisation();
         this.isTemporalPrioritisationFormatValid = true;
      }
      else {
         this.isTemporalPrioritisationFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onPriorityChanged(event) {
      if (this.callpointSettings instanceof CallpointSettings) {
         if (event.checked) {
            // Prioritisation is disabled so set priorityAmplifier to 1.
            this.callpointSettings.priorityAmplifier = 1;
            this.callpointSettings.temporalPrioritisation = 0;

            // Make the form state valid for the prioritisation fields.
            this.isPriorityAmplifierValid = true;
            this.isTemporalPrioritisationValid = true;
            this.isPriorityAmplifierFormatValid = true;
            this.isTemporalPrioritisationFormatValid = true;
            this._formStateChanged.next(this.formValid);
         }
         else {
            this.callpointSettings.priorityAmplifier = 50;
         }
         this._formStateChanged.next(this.formValid)
      }
   }

   public setDurationPropertyToEmpty(checked: boolean) {
      if (checked) {
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            this.callpointSettings.duration = '';
         }
      }
   }

   public setFrequencyPropertyToEmpty(checked: boolean) {
      if (checked) {
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            this.callpointSettings.frequency = '';
         }
      }
   }

   public setPriorityPropertyToEmpty(checked: boolean) {
      if (checked) {
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            this.callpointSettings.priority = '';
         }
      }
   }

   public setLocationPropertyToEmpty(checked: boolean) {
      if (checked) {
         if (this.callpointSettings instanceof CallpointSettingsViewModel && !this.callpointSettings.locationSameValuesSet) {
            this.callpointSettings.latitude = '';
            this.callpointSettings.longitude = '';
            this.isLongitudeFormatValid = false;
            this.isLatitudeFormatValid = false;
            this._formStateChanged.next(this.formValid);
            setTimeout(() => this.getPlaceAutocomplete());
         }
      }
   }

   public setCriticalProperty(checked: boolean) {
      if (checked) {
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            if (!this.callpointSettings.criticalSameValuesSet) {
               this.callpointSettings.critical = false;
            }
         }
      }
   }

   public onAnyInputSwitchChanged(event: any) {
      this.callpointSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);
   }

   public applyLocation() {
      if (this.callpointSettings instanceof CallpointSettingsViewModel) {
         this.callpointSettings.latitude = this.searchPlaceLat;
         this.callpointSettings.longitude = this.searchPlaceLng;
      }
      this.isLongitudeFormatValid = true;
      this.isLatitudeFormatValid = true;
      this._formStateChanged.next(this.formValid);
   }

   public onSearchPlaceInputValueChanged(event) {
      if(event.target.value.length === 0) {
         this.searchPlaceApplyButtonDisabled = true;
      }
   }

   // Event handler triggered when a date is selected in the calendar.
   // Validate the start date.
   public onStartDateSelected(value: any) {
      if(this.callpointSettings instanceof CallpointSettingsViewModel) {
         // Test start and end dates against each other.
         if(this.callpointSettings.startDate > this.maxDate) {
            this.isCallpointStartDateValid = false;
         }
         else {
            this.isCallpointStartDateValid = true;
         }

         if(this.callpointSettings.endDate < this.callpointSettings.startDate) {
            this.isCallpointEndDateValid = false;
         }
         else {
            this.isCallpointEndDateValid = true;
         }

      }

      this._formStateChanged.next(this.formValid);
   }

   // Event handler triggered when a date is selected in the calendar.
   // Validate the end date.
   public onEndDateSelected(value: any) {
      if(this.callpointSettings instanceof CallpointSettingsViewModel) {
         // Test start and end dates against each other.
         if(this.callpointSettings.endDate < this.callpointSettings.startDate) {
            this.isCallpointEndDateValid = false;
         }
         else {
            this.isCallpointEndDateValid = true;
         }

         if(this.callpointSettings.startDate > this.maxDate) {
            this.isCallpointStartDateValid = false;
         }
         else {
            this.isCallpointStartDateValid = true;
         }

      }
      this._formStateChanged.next(this.formValid);
   }

   public onCriticalInputSwitchChanged(event: any) {
      this._formStateChanged.next(this.formValid);
   }

   private getPlaceAutocomplete() {
      // Google Autocomplete feature initialisation.
      let searchPlaceElement = document.getElementById('searchPlace');
      if(!searchPlaceElement) return;
      let autocomplete = new google.maps.places.Autocomplete(searchPlaceElement,
         {
            // We are only interested in lat lng data from Autocomplete
            types: ['geocode']  // 'establishment' / 'address' / 'geocode'
         });

      // Use the callerpoints lat/lngs to reverse lookup the address
      // to get the Country code. This is then used to bias the search results towards that country.
      if (this.callpointSettings instanceof CallpointSettingsViewModel) {
         let latlng = {lat: parseFloat(this.selectedCallpointLat), lng: parseFloat(this.selectedCallpointLng)};
         // Google Geocoder is used to do reverse look up to get the address information from the lat lng.
         let geocoder = new google.maps.Geocoder;
         // Pass the lat lng to the geocoder.
         geocoder.geocode({'location': latlng}, function(results, status) {
            if (status === 'OK') {
               if (results[0]) {
                  let data = results[0];
                  let countryCode = '';

                  // Find and set the country from the returned address data.
                  data.address_components.forEach(address => {
                     if(address.types[0] == 'country') {
                        countryCode = address.short_name;
                     }
                  });

                  // Restrict the autocomplete to search for the selected country.
                  autocomplete.setComponentRestrictions({'country': countryCode});

               }
               else {
                   // console.log('No results found');
               }
            }
            else {
              //  console.log('Geocoder failed due to: ' + status);
            }
         });
      }

      // Only get the lat lng info for the search term.
      autocomplete.setFields(['geometry']);

      // Bias the results towards current map's viewport.
      //autocomplete.setBounds(this._applicationStore.mapsStore.mapBounds);

      // Add event handler to set the lat lngs.
      autocomplete.addListener('place_changed', () => {
          let place = autocomplete.getPlace();
          this.searchPlaceLat = place.geometry.location.lat();
          this.searchPlaceLng = place.geometry.location.lng();
          this.searchPlaceApplyButtonDisabled = false;
          this._cdr.detectChanges();
      });
   }

   private validateFrequency() {
      if (this.callpointSettings instanceof CallpointSettingsViewModel) {
         this.isFrequencyValid = this.callpointSettings.frequency >= 1 && this.callpointSettings.frequency <= (this.projectCycleLength * 7);
         if (this.isFrequencyValid) {
            this.checkReadOnlyDayCombinationsBasedOnFrequency();
         }
         else {
            this.displayReadOnlyDayCombinationsBasedOnFrequency = false;
         }
      }
   }

   private validateDuration() {
      if (this.callpointSettings instanceof CallpointSettingsViewModel) {
         this.isDurationValid = this.callpointSettings.duration >= 1 && this.callpointSettings.duration <= this.callpointAvailabilityDuration;
      }
   }

   private validatePriority() {
      if (this.callpointSettings instanceof CallpointSettingsViewModel) {
         this.isPriorityValid = this.callpointSettings.priority !== '' && this.callpointSettings.priority >= 0 && this.callpointSettings.priority <= 100;
      }
   }

   private validatePriorityAmplifier() {
      if (this.callpointSettings instanceof CallpointSettings) {
         this.isPriorityAmplifierValid = this.callpointSettings.priorityAmplifier >= 2 && this.callpointSettings.priorityAmplifier <= 50;
      }
   }

   private validateTemporalPrioritisation() {
      if (this.callpointSettings instanceof CallpointSettings) {
         this.isTemporalPrioritisationValid = this.callpointSettings.temporalPrioritisation >= 0 && this.callpointSettings.temporalPrioritisation <= 1440;
      }
   }

   // Sets the all dayCombinations collection grouped by visits per day to be displayed in the UI
   private setAllPossibleDayCombinationsGrouped() {
      // Sets the isRecommended property to dayCombinations collection
      this.setIsRecommendedToDayCombinations();
      // Grouping the dayCombinations by visits per week
      let dayCombinationsGroupByVisitsPerWeek: DayCombination[][] = this.groupBy(this.dayCombinationsWithActiveWorkingDays, 'visitsperWeek')
      let numberOfVisitValues = Object.keys(dayCombinationsGroupByVisitsPerWeek);
      // Gets a matrix [numberOfVisits][dayCombinations with those number of visits].
      let allPossibleDayCombinationsGrouped: DayCombination[][] = [];
      numberOfVisitValues.forEach(item => {
         let groupedNumberOfVisits: DayCombination[] = dayCombinationsGroupByVisitsPerWeek[item];
         allPossibleDayCombinationsGrouped.push(groupedNumberOfVisits.sort(this.sortDayCombinations));

      });
      this.allPossibleDayCombinationsGrouped = allPossibleDayCombinationsGrouped;
      if (this.showOverrideButtons) {
         this.checkReadOnlyDayCombinationsBasedOnFrequency();
      }
   }

   private checkReadOnlyDayCombinationsBasedOnFrequency() {
      this.displayReadOnlyDayCombinationsBasedOnFrequency = false;
      this.allPossibleDayCombinationsGrouped.forEach(group => {
         if (this.callpointSettings instanceof CallpointSettingsViewModel) {
            let displayDayCombination: boolean = group[0].visitsperWeek * this.projectCycleLength === this.callpointSettings.frequency;
            this.displayReadOnlyDayCombinationsBasedOnFrequency = this.displayReadOnlyDayCombinationsBasedOnFrequency || displayDayCombination;
         }
      });
   }

   // Selects the recommended dayCombinations when the initial state is 'Allow all'
   // and it's changed to 'Allow specific
   private selectRecommendedDayCombination() {
      // dayCombinations collection is cleared.
      this.clearSelectedDayCombinationsWithActiveWorkingDays();
      if (this.callpointSettings && this.recommendedDayCombinations) {
         // Filters the recommendedDayCombinations with active working days
         let recommendedCombinationsWithActiveWorkingDays: string[] = [];
         this.recommendedDayCombinations.forEach(recommnendedCombination => {
            if (this.dayCombinationsWithActiveWorkingDays.length !== 0) {
               let recommendedDayCombination: DayCombination =
                  this.dayCombinationsWithActiveWorkingDays.find(item => item.combination === recommnendedCombination)
               if (recommendedDayCombination) {
                  recommendedDayCombination.isSelected = true;
                  recommendedCombinationsWithActiveWorkingDays.push(recommendedDayCombination.combination);
               }
            }
         });
         // Adding recommended dayCombinations with working active days to the callpointSettings.dayCombinations array
         this.callpointSettings.dayCombinations = recommendedCombinationsWithActiveWorkingDays;
      }
   }

   // Selects the dayCombinations retrieved from the Callpoints settings object
   private selectCallpointSettingsDayCombinations() {
      // dayCombinations collection is cleared.
      this.clearSelectedDayCombinationsWithActiveWorkingDays();
      if (this.callpointSettings && this.callpointSettings.dayCombinations) {
         // Loops through the selected dayCombinations collection from the callpoint setttings object
         // and sets the selected UI dayCombination elements.
         this.callpointSettings.dayCombinations.forEach(selectedCombination => {
            if (this.dayCombinationsWithActiveWorkingDays.length !== 0) {
               let selectedDayCombination: DayCombination = this.dayCombinationsWithActiveWorkingDays.find(item => item.combination === selectedCombination)
               if (selectedDayCombination) {
                  selectedDayCombination.isSelected = true;
               }
            }
         });
      }
   }

   // Clears current dayCombination selection
   private clearSelectedDayCombinationsWithActiveWorkingDays() {
      this.dayCombinationsWithActiveWorkingDays.forEach(element => {
         element.isSelected = false;
      });
   }

   private setIsRecommendedToDayCombinations() {
      this.dayCombinationsWithActiveWorkingDays.forEach(dayCombination => {
         if (this.recommendedDayCombinations.indexOf(dayCombination.combination) > -1) {
            dayCombination.isRecommended = true;
         }
      })
   }

   private getDayCombinationsWithActiveWorkingDays(projectActiveWorkingDays: boolean[]): DayCombination[] {
      // Working out the dayCombinations with working days
      // Gets the non-active days
      let dayCombinationsWithActiveWorkingDays: DayCombination[] = [];
      let nonActiveWorkingDays: number[] = [];
      if (projectActiveWorkingDays.length !== 0) {
         // Work out the non-active working days.
         projectActiveWorkingDays.filter(function (value, index) {
            if (value === false) {
               return nonActiveWorkingDays.push(index);
            }
         });

         if (this.allPossibleDayCombinations) {
            // Filtering the DayCombinations by the available days using for that the non-active days.
            dayCombinationsWithActiveWorkingDays = this.allPossibleDayCombinations.filter(item => {
               return (!(item.mon && nonActiveWorkingDays.includes(0)) &&
                  !(item.tue && nonActiveWorkingDays.includes(1)) &&
                  !(item.wed && nonActiveWorkingDays.includes(2)) &&
                  !(item.thu && nonActiveWorkingDays.includes(3)) &&
                  !(item.fri && nonActiveWorkingDays.includes(4)) &&
                  !(item.sat && nonActiveWorkingDays.includes(5)) &&
                  !(item.sun && nonActiveWorkingDays.includes(6)));
            });
         }
      }
      return dayCombinationsWithActiveWorkingDays;
   }


   // Groups a collection based on the property specified
   private groupBy(collection: any[], prop: string) {
      return collection.reduce(function (groups, item) {
         var val = item[prop];
         groups[val] = groups[val] || [];
         groups[val].push(item);
         return groups;
      }, {});
   }

   // Sorts a dayCombination list, based on the combination property
   private sortDayCombinations(combA: DayCombination, combB: DayCombination) {
      var nameA = combA.combination.toUpperCase(); // ignore upper and lowercase
      var nameB = combB.combination.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
         return -1;
      }
      if (nameA > nameB) {
         return 1;
      }
      // names must be equal
      return 0;
   }
}
