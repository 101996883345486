import { Pipe, PipeTransform } from '@angular/core';

// The distance Units pipe takes a value and if the unit should be miles or kilometers
// Snowdon stores all its values in metric so if the property unitIsMiles false it will use the current value
//

@Pipe({name: 'distanceUnits'})
export class DistanceUnits implements PipeTransform {
  transform(value: number, unitIsMiles:boolean): number {
    const MILES_TO_KM_FACTOR = 0.621371192;

    if(unitIsMiles){
       return value * MILES_TO_KM_FACTOR;
    }else{
      return value;
    }

  }
}
