<div style="position: relative">
   <div class="ui-range-bar">
      <div *ngIf="enableCommuteLabel" class="ui-text ui-text-left">Commute</div>
      <div *ngIf="enableCommuteLabel" class="ui-text ui-text-right">Commute</div>
      <div [ngStyle]="{'left.px': labelLeftOffset}" class="ui-range-label ui-left-label-below">{{lowerLeftLabel}}</div>
      <div [ngStyle]="{'left.px': labelRightOffset}" class="ui-range-label ui-right-label-below">{{lowerRightLabel}}</div>
      <div class="ui-range-label ui-left-label-above">{{upperLeftLabel}}</div>
      <div class="ui-range-label ui-right-label-above">{{upperRightLabel}}</div>
      <div [ngStyle]="{'left.px': barLeftOffset, 'width.px':barWidth}" class="ui-range-bar-inner">
         <div class="ui-text">Contracted hours</div>
      </div>
   </div>
</div>   