import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { Caller } from 'app/models/caller';
import { Project } from 'app/models/project';
import { MainDashboard } from 'app/models/main-dashboard';
import { ExportDialogComponent } from 'app/dashboard-workspace/export-dialog/export-dialog.component';
import { ImportDialogComponent } from 'app/dashboard-workspace/import-dialog/import-dialog.component';
import { TravelModelDialogComponent } from 'app/dashboard-workspace/travel-model-dialog/travel-model-dialog.component';

/**
 * Component for the main dashboard view.
 */
@Component({
   selector: 'callsmart-dashboard',
   templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {

   // Subscription for the callers change notification.
   private callers_subscription: Subscription;

   // Subscription for the callpoints count change notification.
   private callpointCounts_subscription: Subscription;

   // Subscription for the Global call schedule change notification.
   private dashboard_subscription: Subscription;

   private project_subscription: Subscription;

   // List of all callers, used for various calculations.
   private allCallers: ReadonlyArray<Caller>;

   // Model object containing the values to be displayed in the view.
   public dashboardModel: MainDashboard = new MainDashboard();

   // selected project
   public project: Project;

   // Total number of callers.
   public totalCallers: number;

   // Total number of callpoints.
   public totalCallpoints: number;

   //------ Export dialog properties ---------------------------
   // Determines whether to display the  dialog.
   public showExportProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public exportProjectDialog = ExportDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public exportDialogInput = {
      display: false,
      project: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public exportDialogOutput = {
      export: () => this.onExportEvent(),
      cancel: () => this.onExportCancelEvent()
   };
   // ------------------------------------------------

   //------ Import dialog properties ---------------------------
   // Determines whether to display the  dialog.
   public showImportProjectDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public importProjectDialog = ImportDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public importDialogInput = {
      display: false,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public importDialogOutput = {
      import: () => this.onImportEvent(),
      cancel: () => this.onImportCancelEvent()
   };
   // ------------------------------------------------

   //------ Travel model dialog properties ---------------------------
   // Determines whether to display the  dialog.
   public showTravelModelDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public travelModelDialog = TravelModelDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public travelModelDialogInput = {
      display: false,
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public travelModelDialogOutput = {
      save: () => this.onSaveTravelModel(),
      cancel: () => this.onCancelTravelModel()
   };
   // ------------------------------------------------

   constructor(
      private _router: Router,
      private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      this.subscribeToDashboardCalculations();
      this.subscribeToCallers();
      this.subscribeToISelectedProject();
      this.subscribeToCallpointCount();
      this._applicationStore.callpointsStore.getCallpointsCountForProject(this._applicationStore.projectsStore.selectedProject.projectId);

   }

   private subscribeToCallers(): void {
      this.callers_subscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            if (callers.length > 0) {
               this.allCallers = callers;
               this.totalCallers = callers.length;
            }
         }
      );
   }

   private subscribeToCallpointCount(): void {
      this.callpointCounts_subscription = this._applicationStore.callpointsStore.callpointCountForProject$.subscribe(
         (count: number) => {
            if (count) {
               this.totalCallpoints = count;
            }
         }
      );
   }

   private subscribeToDashboardCalculations(): void {
      this.dashboard_subscription = this._applicationStore.callersStore.dashboardCalculations$.subscribe(
         (dashboard: MainDashboard) => {
            if (dashboard !== null) {
               this.dashboardModel = dashboard;
            }
         }
      );
   }

   private subscribeToISelectedProject(): void {
      this.project_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe((project: Project) => {
         this.project = project;

         if (project.projectSettings.travelModel == null) {
            this.onShowTravelModelDialog();
         }
      });
   }

   private onShowTravelModelDialog() {
      this.showTravelModelDialog = true;
      this.travelModelDialogInput.display = true;
   }

   ngOnDestroy(): void {
      this.callers_subscription.unsubscribe();
      this.dashboard_subscription.unsubscribe();
      this.project_subscription.unsubscribe();
      this.callpointCounts_subscription.unsubscribe();
   }

   public onExportData() {
      this.showExportProjectDialog = true;
      this.exportDialogInput.display = true;
      //this.dialogInput.project = this.projectList.selectedProjects;
   }

   public onImportData() {
      this.showImportProjectDialog = true;
      this.importDialogInput.display = true;
   }

   // cancel button from the export  dialog.
   public onExportCancelEvent() {

      this.showExportProjectDialog = false;
      this.exportDialogInput.display = false;
   }

   // export button from the export  dialog.
   public onExportEvent() {

      this.showExportProjectDialog = false;
      this.exportDialogInput.display = false;
   }

   // cancel button from the import  dialog.
   public onImportCancelEvent() {

      this.showImportProjectDialog = false;
      this.importDialogInput.display = false;
   }

   // import button from the import dialog.
   public onImportEvent() {
      this.showImportProjectDialog = false;
      this.importDialogInput.display = false;

      this._router.navigate(['/import-data']);
   }

   public onCancelTravelModel() {
      this.showTravelModelDialog = false;
      this._router.navigate(['/close-project']);
   }

   public onSaveTravelModel() {
      this.showTravelModelDialog = false;
   }

}
