<div class="row">
   <div class="col-md-12">
      <div class="cs-wizard-title" style="padding-left: 17px;">
         <h3>Project properties - {{activeStepLabel}}</h3>
      </div>

      <p-steps id="projectProperties" 
         [model]="items" 
         [(activeIndex)]="activeIndex" 
         [readonly]="false">
      </p-steps>
   </div>
</div>

<router-outlet></router-outlet>