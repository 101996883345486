<p-dialog header="Delete user"
   [(visible)]="display"
   modal="true"
   width="700"
   positionTop="200"
   [draggable]="false"
   [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal">

      <div style="margin-top:5px;margin-bottom:5px;">
         Are you sure you want to permanently delete this user?
      </div>

      <div class="black-datatable no-horizontal-scrollbar" style="margin-top:20px; margin-bottom:40px;">
         <p-dataTable [value]="users" [resizableColumns]="false" [reorderableColumns]="false" selectionMode="single" [scrollable]="false">

            <p-column field="username" header="User Name" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>

            <p-column field="fullname" header="Full Name" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>
         </p-dataTable>
      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onDelete()">Delete</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
