import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Event } from 'app/models/diary/event';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Project } from 'app/models/project';
import { EventTypes } from 'app/models/diary/event-types';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { ProjectCalendarDialogComponent } from 'app/project-settings-workspace/project-calendar-dialog/project-calendar-dialog.component';
import { CalendarComponent } from 'app/shared/calendar/calendar.component';
import { Caller } from 'app/models/caller';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CanComponentDeactivate, PendingChangesGuard } from 'app/services/pending-changes-guard.service';

@Component({
   templateUrl: './project-properties-event.component.html'
})

// The project settings workspace allows users to modify the current project settings
// (callers, callpoints, scheduling)
export class ProjectPropertiesEventComponent implements OnInit, OnDestroy, CanComponentDeactivate {

   @ViewChild(CalendarComponent)
   private calendar: CalendarComponent;
   private _events_subscription: Subscription;
   private _callers_subscription: Subscription;
   private _caller_settings_subscription: Subscription;
   private _project_subscription: Subscription;
   
   public hasEventChanged: boolean;
   public callerSettings: CallerSettings = null;
   public projectEvents: Event[] = [];
   public projectCallers: ReadonlyArray<Caller> = [];
   public startCycleDate: Date;
   public endCycleDate: Date;
   public callCycleLength: number;
   public eventDialogMode: DialogMode;
   public selectedEvent: Event;  // the event clicked on for editing
   public selectedEventIndex: number;  // the event clicked on for editing
   public workspaceHeight: number;
   public startWorkingTime: string; // Start working time to be showed in the calendar.
   public endWorkingTime: string; // End working time to be showed in the calendar.
   public activeWorkingDays: boolean[]; // Days which have to be eligible based on the caller settings   
   public datesClosed: string[];


   // Determines whether to display the edit caller dialog.
   public showEventDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public projectCalendarDialog = ProjectCalendarDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false, events: this.projectEvents, eventToEdit: this.selectedEvent, eventDialogMode: this.eventDialogMode,
      startCycleDate: null, endCycleDate: null, projectCallers: this.projectCallers, callerSettings: this.callerSettings
   }

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (eventDate: Date) => this.onSaveEvent(eventDate),
      cancel: () => this.onCancelEvent(),
      deleted: (event: Event) => this.onDeleteEvent(event)
   };

   constructor(private _applicationStore: ApplicationStore,
      private _windowService: BrowserWindowService,
      private _router: Router,
      private _changesGuard: PendingChangesGuard) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.workspaceHeight = value - 200;
         if (this.calendar) {
            this.calendar.calendarHeight = (this.workspaceHeight - 200) < 300 ? 300 : (this.workspaceHeight - 200);
         }
      });

      // Subscribe to the confirm dialog reponse. If the user accepts to navigate away
      // then undo the changes, in this case that would be the event deletion.
      _changesGuard.confirmDialogResponse$.subscribe((accepted: boolean) => {
         if(accepted) {
            this.onUserIgnoredChanges();
         }
      });
   }

   ngOnInit() {
      this.subscribeToSelectedProject();
      // When an event is deleted, the calendar component doesn't seem to be refreshing, this forces the events to be loaded
      // again so the calendar is forced to refresh.
      this._applicationStore.projectsStore.loadProjectCalendar(this._applicationStore.projectsStore.selectedProject.projectId);
      this.subscribeToProjectEvents();
      this.subscribeToCallers();
      this.subscribeToProjectCallerSettings();
      // this.subscribeToDiaryEvents();
   }

   ngOnDestroy() {
      if (this._events_subscription) {
         this._events_subscription.unsubscribe();
      }
      if (this._callers_subscription) {
         this._callers_subscription.unsubscribe();
      }
      if (this._project_subscription) {
         this._project_subscription.unsubscribe();
      }
   }

   canDeactivate(): boolean {
      // If the user has modified any events, then prompt the user to save any changes.
      return !this.hasEventChanged;
   }

   public onEventSelected(event: Event) {

      let eventIndex = this.projectEvents.findIndex(e => e.id === event.id)

      this.selectedEventIndex = eventIndex;
      this.selectedEvent = this.projectEvents[eventIndex];

      this.eventDialogMode = DialogMode.edit;
      this.dialogInput.eventDialogMode = DialogMode.edit;
      this.dialogInput.eventToEdit = this.selectedEvent;
      this.dialogInput.events = this.projectEvents;
      this.dialogInput.projectCallers = this.projectCallers;
      this.dialogInput.startCycleDate = this.startCycleDate;
      this.dialogInput.endCycleDate = this.endCycleDate;
      this.dialogInput.callerSettings = this.callerSettings;
      this.showEventDialog = true;
      this.dialogInput.display = true;
   }

   public onAddEvent() {
      this.eventDialogMode = DialogMode.add;
      this.dialogInput.eventDialogMode = DialogMode.add;
      this.dialogInput.events = this.projectEvents;
      this.dialogInput.projectCallers = this.projectCallers;
      this.dialogInput.startCycleDate = this.startCycleDate;
      this.dialogInput.endCycleDate = this.endCycleDate;
      this.dialogInput.callerSettings = this.callerSettings;
      this.showEventDialog = true;
      this.dialogInput.display = true;
   }

   // cancel button from the add\edit event dialog
   public onCancelEvent() {
      this.showEventDialog = false;
      this.dialogInput.display = false;
   }

   // delete button from dialog clicked
   public onDeleteEvent(event) {

      let index = this.projectEvents.findIndex(e => e.id == event.id);

      if (index > -1) {
         // if this is the last item left set array to empty
         if (this.projectEvents.length == 1) {
            this.projectEvents = [];
         } else {
            this.projectEvents.splice(index, 1);
            // take copy of new values
            let temp = this.projectEvents.slice();
            // kick calendar to refresh
            this.projectEvents = [];
            this.projectEvents = temp;
         }
      }

      this.hasEventChanged = true;
   }

   // save button from the add\edit event dialog
   public onSaveEvent(eventDate: Date) {
      if (this.eventDialogMode === DialogMode.add) {
         this.projectEvents = this.projectEvents.slice();
      }

      if (this.eventDialogMode === DialogMode.edit) {
         //this.projectEvents[this.selectedEventIndex].title = this.projectEvents[this.selectedEventIndex].title + "edit";
         this.calendar.updateEvent(this.projectEvents[this.selectedEventIndex])
      }
      this.showEventDialog = false;
      this.dialogInput.display = false;
      //this.calendar.setScroll(null);
      if (this.calendar && this.calendar.currentView === "agendaWeek") {
         this.calendar.gotoWeek(eventDate);
      }

      this.hasEventChanged = true;
   }

   private onUserIgnoredChanges(): void {
      // If the user has deleted an event and still decides to ignore the confirm dialog, 
      // just reload the events again.
      this._applicationStore.projectsStore.loadProjectCalendar(this._applicationStore.projectsStore.selectedProject.projectId);
   }

   private subscribeToCallers() {
      // Get the callers list from the callers service.
      this._callers_subscription = this._applicationStore.callersStore.callers$.subscribe(
         (callers: ReadonlyArray<Caller>) => {
            this.projectCallers = callers;

         }
      );
   }

   public subscribeToProjectCallerSettings() {
      this._caller_settings_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project != null) {
               this.callerSettings = project.callerSettings;
               this.activeWorkingDays = this.callerSettings.workingDayActive;

               if (this.callerSettings.sameWorkingHoursAllDays) {
                  this.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(true, this.callerSettings);
                  this.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(false, this.callerSettings);
               }
               else {
                  // Deal with multiple hours per day. This is a bit of a cheat, take hours for each day and create a pipe 
                  // delimited string that can be passed to the calendar component, the calendar component will then unpack this.
                  let startTime = '';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursMonday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursTuesday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursWednesday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursThursday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursFriday);
                  this.startWorkingTime = startTime;
         
                  let endTime = '';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursMonday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursTuesday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursWednesday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursThursday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursFriday);
                  this.endWorkingTime = endTime;
                  
               }
               
               let formattedClosedDates: string[] = [];
               for (let index = 0; index < this.callCycleLength; index++) {
                  let week = this.activeWorkingDays.map(x => {
                     return x ? 'o' : 'x';
                  });
                  formattedClosedDates = formattedClosedDates.concat(week);
               }
               this.datesClosed = formattedClosedDates;
            }
         }
      );
   }

   private subscribeToSelectedProject() {
      this._project_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (selectedProject: Project) => {
            if (selectedProject)
               this.startCycleDate = new Date(selectedProject.scheduleStartDate);
            this.endCycleDate = new Date(selectedProject.scheduleEndDate);
            this.callCycleLength = selectedProject.projectSettings.callCycleLength;
         }
      );
   }

   private subscribeToProjectEvents() {
      this._events_subscription = this._applicationStore.projectsStore.projectEvents$.subscribe(
         (projectEvents: Event[]) => {
            let prjEvents: Event[] = [];
            if (projectEvents) {
               projectEvents.forEach(event => {
                  event.description = (event.attendeesIds.length > 0) ? "x " + event.attendeesIds.length : "";
                  event.end = new Date(event.end);
                  event.start = new Date(event.start);
                  event.eventType = EventTypes.project;
               });
            }
            this.projectEvents = projectEvents;
         }
      );
   }

   public onCancel() {
      // Let the pending changes guard warn the user, this component will then be notified via confirmDialogResponse$
      // of the users decision.
      this._router.navigate(['/dashboard']);
   }

   public onSave() {
      // save the project calendar
      this._applicationStore.projectsStore.createUpdateProjectCalendar(this._applicationStore.projectsStore.selectedProject.projectId,
         this.projectEvents);
      this.hasEventChanged = false;

   }
}
