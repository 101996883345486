<div style="display: inline-block; width: 100%">
   <h6 style="float:left;">Caller settings</h6>
   <div style="float: right; margin-top: 6px;">
      <i class="cs-icon icon-edit callsmart-link-active icon-rounded-circle" 
         aria-hidden="true" (click)="editSettings()"></i>
   </div>
</div>
<div *ngIf="callerSettings">
   <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-77}">
      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Contracted working days and hours:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div>
                     {{workingDayText}}
                  </div>
                  <div *ngIf="callerSettings.sameWorkingHoursAllDays; else differentHours">
                     {{getHoursAndMinutes(callerSettings.contractedWorkingHoursWeek[0])}} - {{getHoursAndMinutes(callerSettings.contractedWorkingHoursWeek[1])}}
                  </div>
                  <ng-template #differentHours>
                        Different hours per day
                  </ng-template>
               </div>
            </div>
         </div>
      </div>

      <!-- PSN-2361 Commented out for now. Do not DELETE, required for future release. -->
      <!-- <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Working hours flexibility:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{callerSettings.workingHoursFlexibility}} mins
               </div>
            </div>
         </div>
      </div> -->

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Lunch Window:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div *ngIf="callerSettings.floatingLunch; else noFloatingLunch">
                     {{getHoursAndMinutes(callerSettings.lunchPeriod[0])}} - {{getHoursAndMinutes(callerSettings.lunchPeriod[1])}}
                  </div>
                  <ng-template #noFloatingLunch>
                        None
                  </ng-template>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list" *ngIf="callerSettings.floatingLunch">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Lunch Break:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  {{callerSettings.lunchDuration}} mins
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Maximum one-way commute time:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div *ngIf="callerSettings.noMaximum; else commuteTime">
                     No maximum
                  </div>
                  <ng-template #commuteTime>
                     {{callerSettings.maxOneWayCommuteTime}} mins
                  </ng-template>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Maximum visits allowed per day:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div *ngIf="callerSettings.visitsPerDayNoMaximum; else maxVisitsPerDay">
                     No maximum
                  </div>
                  <ng-template #maxVisitsPerDay>
                     {{callerSettings.maxVisitsPerDay}}
                  </ng-template>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Commute during working hours only:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div>
                     <div style="float:left; padding-right:5px; font-weight:normal;">To:</div>
                     <div style="float:left; padding-right:10px;">{{callerSettings.commuteWorkHrsToFirstVisit ? 'Yes' : 'No'}}</div>

                     <div style="float:left; padding-right:5px; font-weight:normal;">From:</div>
                     <div>{{callerSettings.commuteWorkHrsFormLastVisit ? 'Yes' : 'No'}}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div class="contextualPanel-list-items-firstline">Out of phase visits:</div>
            <div class="contextualPanel-list-items-secondline">
               <div class="display-flex-column display-flex-first-column">
                  <div *ngIf="callerSettings.outOfPhaseVisitDays == 0">
                     None
                  </div>
                  <div *ngIf="callerSettings.outOfPhaseVisitDays == 1">
                     1 day either side
                  </div>
                  <div *ngIf="callerSettings.outOfPhaseVisitDays > 1">
                     {{callerSettings.outOfPhaseVisitDays}} days either side
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding create-new-settings-panel">
            <div *ngIf="!callerSettings.overnightsDisabled; else noOvernights">
               <div class="contextualPanel-list-items-firstline">Maximum consecutive overnights:</div>
               <div class="contextualPanel-list-items-secondline">
                  <div class="display-flex-column display-flex-first-column">
                     <div *ngIf="callerSettings.overnightsNoMaximum; else maxConsecutiveOvernights">
                        No maximum
                     </div>
                     <ng-template #maxConsecutiveOvernights>
                        {{callerSettings.maxConsecutiveOvernights}}
                     </ng-template>

                     <div class="contextualPanel-list-items-nested">
                        Minimum commute time before overnight:
                     </div>
                     <div>
                        {{callerSettings.minCommuteTimeBeforeOvernight}} mins
                     </div>

                     <div class="contextualPanel-list-items-nested">
                        Maximum travel time in the event of an overnight:
                     </div>
                     <div *ngIf="callerSettings.eventOvernightNoMaximum; else maxTravelTimeInEventOfOvernight">
                        No maximum
                     </div>
                     <ng-template #maxTravelTimeInEventOfOvernight>
                        {{callerSettings.maxTravelTimeInEventOfOvernight}} mins
                     </ng-template>
                  </div>
               </div>
            </div>
            <ng-template #noOvernights>
               <div class="contextualPanel-list-items-firstline">Overnights:</div>
               <div class="contextualPanel-list-items-secondline">
                  <div class="display-flex-column display-flex-first-column">
                     Off
                  </div>
               </div>
            </ng-template>
         </div>
      </div>
   </perfect-scrollbar>
</div>

<!-- This is where project settings dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showSettings" 
   [ndcDynamicComponent]="editCallerSettingsDialog"
   [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
