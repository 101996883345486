import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
// this object is used to represent the travel models configured for the system. typically shown in the combo boxes
export class TravelModel {

   @JsonProperty('travelModelId')
   travelModelId: number = undefined;

   @JsonProperty('travelModelName')
   travelModelName: string = undefined;

   @JsonProperty('travelModelDescription')
   travelModelDescription: string = undefined;

   @JsonProperty('geocodeData')
   geocodeData: string = undefined;

}
