import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { Event } from 'app/models/diary/event';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { ProjectCalendarDialogComponent } from 'app/project-settings-workspace/project-calendar-dialog/project-calendar-dialog.component';
import { CalendarComponent } from 'app/shared/calendar/calendar.component';
import { ApplicationStore } from 'app/stores/application-store';
import { Caller } from 'app/models/caller';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { Project } from 'app/models/project';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

@Component({
   selector: 'callsmart-new-project-events',
   templateUrl: './new-project-events.component.html'
})

// This class represents one of the steps for the new project wizard.
// Manages the logic to display events in the calendar component as well as
// the ability to open de dialog event component in order to create and update
// events.
export class NewProjectEventsComponent implements OnInit {

   private _project_created_subscription: Subscription;
   private _componentHeight: number;
   @Input()
   get componentHeight(): number {
      return this._componentHeight;
   }
   set componentHeight(height: number) {
      this._componentHeight = height;
      this.calendarHeight = (this._componentHeight - 200) < 300 ? 300 : (this._componentHeight - 200);
   }
   @Input() public projectWizardModel: NewProjectWizard;  // Model object which cached the info from the wizard.

   public calendarHeight: number;
   public callerSettings: CallerSettings = null; // Settings for the callers imported.
   public projectCallers: Caller[] = []; // callers imported for the new project.
   public eventDialogMode: DialogMode;
   public selectedEvent: Event;  // the event clicked on for editing.
   public selectedEventIndex: number;  // the event clicked on for editing.
   public startWorkingTime: string; // Start working time to be showed in the calendar.
   public endWorkingTime: string; // End working time to be showed in the calendar.
   public activeWorkingDays: boolean[]; // Days which have to be eligible based on the caller settings
   public showEventDialog: boolean = false; // Determines whether to display the edit caller dialog.
   public projectCalendarDialog = ProjectCalendarDialogComponent; // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   // Input parameters for the loaded component.
   // This usually will be the @Input() properties.
   public dialogInput = {
      display: false,
      events: null,
      eventToEdit: this.selectedEvent,
      eventDialogMode: this.eventDialogMode,
      startCycleDate: null,
      endCycleDate: null,
      projectCallers: this.projectCallers,
      callerSettings: this.callerSettings
   };
   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (eventDate: Date) => this.onSaveEvent(eventDate),
      cancel: () => this.onCancelEvent(),
      deleted: (event: Event) => this.onDeleteEvent(event)
   };

   @ViewChild(CalendarComponent)
   private calendar: CalendarComponent;

   constructor(private _applicationStore: ApplicationStore) {
   }

   ngOnInit() {
      this.subscribeToProjectCreated();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }
   }

   // cancel button from the add\edit event dialog
   public onCancelEvent() {
      this.showEventDialog = false;
      this.dialogInput.display = false;
   }

   // delete button from dialog clicked
   public onDeleteEvent(event) {

      let index =  this.projectWizardModel.projectEvents.findIndex(e => e.id == event.id);

      if(this.projectWizardModel.projectEvents.length == 1){
         this.projectWizardModel.projectEvents =[];
      }else{
         this.projectWizardModel.projectEvents.splice(index, 1);
         // take copy of new values
         let temp = this.projectWizardModel.projectEvents.slice();
         // kick calendar to refresh
         this.projectWizardModel.projectEvents =[];
         this.projectWizardModel.projectEvents = temp;
      }

   }

   // save button from the add\edit event dialog
   public onSaveEvent(eventDate: Date) {
      if (this.eventDialogMode === DialogMode.add) {
         this.projectWizardModel.projectEvents = this.projectWizardModel.projectEvents.slice();
      }

      if (this.eventDialogMode === DialogMode.edit) {
         this.calendar.updateEvent(this.projectWizardModel.projectEvents[this.selectedEventIndex])
      }
      this.showEventDialog = false;
      this.dialogInput.display = false;
      if (this.calendar && this.calendar.currentView === 'agendaWeek') {
         this.calendar.gotoWeek(eventDate);
      }
   }

   // Opens the dialog event component with the info
   // of the selected event.
   public onEventSelected(event: Event) {
      // find the full event in the array
      // let eventIndex = this.projectWizardModel.projectEvents.findIndex(e => e.title === event.title &&
      //    e.start.toDateString() == event.start.toDateString() &&
      //    e.end.toDateString() == event.end.toDateString() &&
      //    e.start.toTimeString() == event.start.toTimeString() &&
      //    e.end.toTimeString() == event.end.toTimeString()
      // )

      let eventIndex = this.projectWizardModel.projectEvents.findIndex(e => e.id === event.id)

      this.selectedEventIndex = eventIndex;
      this.selectedEvent = this.projectWizardModel.projectEvents[eventIndex];
      this.eventDialogMode = DialogMode.edit;
      this.dialogInput.eventDialogMode = DialogMode.edit;
      this.dialogInput.eventToEdit = this.selectedEvent;
      this.dialogInput.events = this.projectWizardModel.projectEvents;
      this.showEventDialog = true;
      this.dialogInput.startCycleDate = this.projectWizardModel.startCycleDate;
      this.dialogInput.endCycleDate = this.projectWizardModel.endCycleDate;
      this.dialogInput.projectCallers = this.projectCallers;
      this.dialogInput.callerSettings = this.callerSettings;
      this.dialogInput.display = true;
   }

   // Opens the dialog event component to add a new event
   // to the project.
   public onAddEvent() {
      this.eventDialogMode = DialogMode.add;
      this.dialogInput.eventDialogMode = DialogMode.add;
      this.dialogInput.events = this.projectWizardModel.projectEvents;
      this.showEventDialog = true;
      this.dialogInput.startCycleDate = this.projectWizardModel.startCycleDate;
      this.dialogInput.endCycleDate = this.projectWizardModel.endCycleDate;
      this.dialogInput.projectCallers = this.projectCallers;
      this.dialogInput.callerSettings = this.callerSettings;
      this.dialogInput.display = true;
   }

   // Refreshes the available callers after importing the CSV file in the new-project-callers step.
   // This method is called from the new-project-workspace.component when user click on the Next button
   // to reach the Events step.
   public refreshCallers() {
      this._applicationStore.callersStore.getCallersByProjectId(this.projectWizardModel.projectId).subscribe(
         (callers: Caller[]) => {
            this.projectCallers = callers;
         });
   }

   // Subscribe to the project created event so that the caller settings can be loaded.
   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this.callerSettings = project.callerSettings;
               if (this.callerSettings.sameWorkingHoursAllDays) {
                  this.projectWizardModel.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(true, this.callerSettings);
                  this.projectWizardModel.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsModel(false, this.callerSettings);
               }
               else {
                  // Deal with multiple hours per day. This is a bit of a cheat, take hours for each day and create a pipe 
                  // delimited string that can be passed to the calendar component, the calendar component will then unpack this.
                  let startTime = '';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursMonday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursTuesday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursWednesday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursThursday) + '|';
                  startTime += CallsmartUtils.getWorkingTime(true, this.callerSettings.contractedWorkingHoursFriday);
                  this.projectWizardModel.startWorkingTime = startTime;
         
                  let endTime = '';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursMonday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursTuesday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursWednesday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursThursday) + '|';
                  endTime += CallsmartUtils.getWorkingTime(false, this.callerSettings.contractedWorkingHoursFriday);
                  this.projectWizardModel.endWorkingTime = endTime;
                  
               }
               
               let formattedClosedDates: string[] = [];
               for (let index = 0; index < this.projectWizardModel.numberOfWeeks; index++) {
                  let week = this.callerSettings.workingDayActive.map(x => {
                     return x ? 'o' : 'x';
                  });
                  formattedClosedDates = formattedClosedDates.concat(week);
               }
               this.projectWizardModel.datesClosed = formattedClosedDates;


            }
         }
      );
   }

   public redrawCalendar() {
      if (this.calendar) {
         this.calendar.goToCycleView();
      }
   }
}
