import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsInputSwitchComponent } from './input-switch.component';

@NgModule({
   imports: [CommonModule],
   declarations: [CsInputSwitchComponent],
   exports: [CsInputSwitchComponent]
})
export class CsInputSwitchModule {

}
