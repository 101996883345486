import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BrowserWindowService } from 'app/services/browser-window.service';

/**
 * Dialog box component for displaying route infill summary at the project status summary.
 */
@Component({
    selector: 'route-infill-dialog',
    templateUrl: 'route-infill-dialog.component.html'
})
export class RouteInfillDialogComponent {

    // Controls the visibility of this dialog, clients can set this to true to display it.
    @Input() display: boolean = false;

    // Controls the visibility of cancel button.
    @Input() displayCancelButton: boolean = true;

    // The list of caller/callpoints that had their routes infilled.
    @Input() routeInfillData: string[];

    // Notifies the client when the dialog was closed with save button.
    @Output() continue = new EventEmitter<void>();

    // Notifies the client when the dialog was closed with cancel button.
    @Output() cancel = new EventEmitter<void>();

    public scrollHeight: number;
    public scrollWidth: number;

    constructor(private windowService: BrowserWindowService) {

        //subscribe to the window resize event
        windowService.height$.subscribe((value: number) => {
            this.scrollHeight = value * 0.70;
        });
        windowService.width$.subscribe((value: number) => {
            this.scrollWidth = value * 0.70;
        });
    }

    // Called when the user clicks on the Cancel button or close icon in the
    // header.
    public onCancel() {
        this.display = false;
        this.cancel.next();
    }

    // Called when the user clicks on the Swap button.
    public onContinue() {
        this.display = false;
        this.continue.next();
    }
}
