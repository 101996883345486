import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';
import { NewProjectWizard } from 'app/models/newProjectWizard';
import { ImportOptions } from 'app/models/import-options';
import { ImportCallpointCounts } from 'app/models/import-callpoint-counts';
import { ImportCallerCounts } from 'app/models/import-caller-counts';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';

/**
 * Component for the import data summary page.
 */
@Component({
   selector: 'import-data-summary',
   templateUrl: 'import-data-summary.component.html'
})
export class ImportDataSummaryComponent implements OnInit, OnDestroy {

   @Input() componentHeight: number; // Heigth of the compoment.
   @Input() projectWizardModel: NewProjectWizard; // Model object which cached the info from the wizard.
   @ViewChild('summaryForm') form: NgForm; // Reference to the summary form.

   // Events triggered to inform the parent component about the step to navigate to.
   @Output() callPoints: EventEmitter<any> = new EventEmitter<any>();
   @Output() callers: EventEmitter<any> = new EventEmitter<any>();

   public callpointProgressValue: number = 0;
   public callerProgressValue: number = 0;
   public importOptions: ImportOptions;
   public importCallpointCounts: ImportCallpointCounts;
   public importCallerCounts: ImportCallerCounts;

   // Values used to display callpoint percentages on the summary page.
   public callpointsAdded: number = 0;
   public callpointsUpdated: number = 0;
   public callpointsDeleted: number = 0;

   // Values used to display caller percentages on the summary page.
   public callersAdded: number = 0;
   public callersUpdated: number = 0;
   public callersDeleted: number = 0;

   private _project_created_subscription: Subscription;
   private _callpoint_counts_subscription: Subscription;
   private _caller_counts_subscription: Subscription;

   private _callerInterval: any = null; // Timer used by the progress bar
   private _callpointInterval: any = null; // Timer used by the progress bar
   private _licenseValid: boolean = false; // Used for trial license validation
   private _callerMergeComplete: boolean = false; // caller merge response received from server
   private _callpointMergeComplete: boolean = false; // callpoint merge response received from server

   constructor(private _applicationStore: ApplicationStore, private _alertStore: AlertStore) {
      this.subscribeToProjectCreated();
      this.subscribeToCallpointMergeCounts();
      this.subscribeToCallerMergeCounts();
   }

   public ngOnInit() {
      this.importOptions = this._applicationStore.importDataStore.importOptions;
   }

   public ngOnDestroy(): void {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }

      if (this._callpoint_counts_subscription) {
         this._callpoint_counts_subscription.unsubscribe();
      }

      if (this._caller_counts_subscription) {
         this._caller_counts_subscription.unsubscribe();
      }

      // destroy the timmers
      clearInterval(this._callpointInterval);
      clearInterval(this._callerInterval);
   }

   public goToCallers() {
      this.callers.next();
   }

   public goToCallpoints() {
      this.callPoints.next();
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      let valid: boolean = false;

      if (this.importOptions) {
         if (this.projectWizardModel) {
            // If only callers are being updated.
            if (this.importOptions.importCallers && !this.importOptions.importCallpoints) {
               valid = (this.projectWizardModel.hasCompletedCallersUploadedToServer && !this.projectWizardModel.callerUploadedToServerError) &&
                  this._callerMergeComplete && this._licenseValid;
            }
            // If only callpoints are being updated.
            else if (!this.importOptions.importCallers && this.importOptions.importCallpoints) {
               valid = (this.projectWizardModel.hasCompletedCallpointsUploadedToServer && !this.projectWizardModel.callpointsUploadedToServerError) &&
                  this._callpointMergeComplete;
            }
            // If both are being updated.
            else if (this.importOptions.importCallers && this.importOptions.importCallpoints) {
               valid = (this.projectWizardModel.hasCompletedCallersUploadedToServer && !this.projectWizardModel.callerUploadedToServerError) &&
                  (this.projectWizardModel.hasCompletedCallpointsUploadedToServer && !this.projectWizardModel.callpointsUploadedToServerError) &&
                  this._callpointMergeComplete && this._callerMergeComplete && this._licenseValid;
            }
         }
      }

      return valid;
   }

   private calculateCallpointPercentages() {
      this.callpointsAdded = this.importCallpointCounts.callpointsAdded / this.importCallpointCounts.originalCallpointsCount;
      this.callpointsUpdated = this.importCallpointCounts.callpointsUpdate / this.importCallpointCounts.originalCallpointsCount;
      this.callpointsDeleted = this.importCallpointCounts.callpointsDeleted / this.importCallpointCounts.originalCallpointsCount;
   }

   private calculateCallerPercentages() {
      this.callersAdded = this.importCallerCounts.callersAdded / this.importCallerCounts.originalCallersCount;
      this.callersUpdated = this.importCallerCounts.callersUpdate / this.importCallerCounts.originalCallersCount;
      this.callersDeleted = this.importCallerCounts.callersDeleted / this.importCallerCounts.originalCallersCount;
   }

   private startCallersProgressBar() {
      let maxValue = 100;
      this._callerInterval = setInterval(() => {
         if (this.callerProgressValue < maxValue) {
            this.callerProgressValue += 10;
         }
         else {
            this.callerProgressValue = 0;
         }

         if (this.projectWizardModel.hasCompletedCallersUploadedToServer) {
            this.stopCallerProgessBar();
         }
      }, 1000);
   }

   private startCallpointsProgressBar() {
      let maxValue = 100;
      this._callpointInterval = setInterval(() => {
         if (this.callpointProgressValue < maxValue) {
            this.callpointProgressValue += 5;
         }
         else {
            this.callpointProgressValue = 0;
         }

         if (this.projectWizardModel.hasCompletedCallpointsUploadedToServer) {
            this.stopCallpointsProgessBar();
         }
      }, 1000);
   }

   private stopCallpointsProgessBar() {
      this.callpointProgressValue = 100;
      clearInterval(this._callpointInterval);
   }

   private stopCallerProgessBar() {
      this.callerProgressValue = 100;
      clearInterval(this._callerInterval);
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this.projectWizardModel.projectId = project.projectId;
               this.startCallersProgressBar();
               this.startCallpointsProgressBar();
            }
         }
      );
   }

   private subscribeToCallpointMergeCounts() {
      this._callpoint_counts_subscription = this._applicationStore.callpointsStore.callpointMergeCounts$.subscribe(
         (counts: ImportCallpointCounts) => {
            if (counts) {
               this._callpointMergeComplete = true;
               this.importCallpointCounts = counts;
               this.calculateCallpointPercentages();
               this.checkNoChangesInCallpointFile();
            }
         }
      );
   }

   private subscribeToCallerMergeCounts() {
      this._caller_counts_subscription = this._applicationStore.callersStore.callerMergeCounts$.subscribe(
         (counts: ImportCallerCounts) => {
            if (counts) {
               this._callerMergeComplete = true;
               this.importCallerCounts = counts;
               this.calculateCallerPercentages();
               this.checkNoChangesInCallerFile();
               this.checkLicenseCallerLimit();
            }
         }
      );
   }

   private checkNoChangesInCallpointFile() {
      if (this.projectWizardModel.hasCompletedCallpointsUploadedToServer && !this.projectWizardModel.callpointsUploadedToServerError &&
         this.importCallpointCounts.callpointsAdded == 0 && this.importCallpointCounts.callpointsDeleted == 0 &&
         this.importCallpointCounts.callpointsUpdate == 0) {
         this._alertStore.sendAlert(new Alert('No callpoint changes found.', 'The callpoint file you are importing has no changes, please select a different file.'));
      }
   }

   private checkNoChangesInCallerFile() {
      if (this.projectWizardModel.hasCompletedCallersUploadedToServer && !this.projectWizardModel.callerUploadedToServerError &&
         this.importCallerCounts.callersAdded == 0 && this.importCallerCounts.callersDeleted == 0 &&
         this.importCallerCounts.callersUpdate == 0) {
         this._alertStore.sendAlert(new Alert('No caller changes found.', 'The caller file you are importing has no changes, please select a different file.'));
      }
   }

   private checkLicenseCallerLimit() {
      if (this._applicationStore.authenticationStore.userCompany.trialLicense) {
         if (((this.importCallerCounts.callersAdded - this.importCallerCounts.callersDeleted) + this._applicationStore.callersStore.totalCallersCount) >
               this._applicationStore.authenticationStore.userCompany.trialLicenseCallerLimit) {
            this._alertStore.sendAlert(new Alert('Warning.','Your licence does not allow you to import more than ' +
            this._applicationStore.authenticationStore.userCompany.trialLicenseCallerLimit + ' callers.'));
            this._licenseValid = false;
         } else {
            this._licenseValid = true;
         }
      } else {
         this._licenseValid = true;
      }
   }
}
