import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CallsmartError } from 'app/models/callsmart-error';

/**
 * Component for displaying the error page.
 */
@Component({
   selector: 'errors-component',
   templateUrl: 'errors.component.html'
})
export class ErrorsComponent implements OnInit {
   public callsmartError: CallsmartError;

   private _routeParams;

   constructor(private _activatedRoute: ActivatedRoute) { }

   ngOnInit() {
      this._routeParams = this._activatedRoute.snapshot.queryParams;
      if(this._routeParams.error) {
         this.callsmartError = JSON.parse(this._routeParams.error);
      }
   }
}
