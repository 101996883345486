import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { Notification } from 'app/models/notification';
import { AlertDialogComponent } from 'app/shared/alert-dialog/alert-dialog.component';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { SpinnerDialogComponent } from './shared/spinner-dialog/spinner-dialog.component';
import { SpinnerStore } from './stores/spinner-store';
import { ErrorDialogComponent } from 'app/shared/error-dialog/error-dialog.component';
import { ErrorStore } from 'app/stores/error-store';
import { ErrorData } from 'app/models/error-data';

import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { User } from './models/user';
import { Router } from '@angular/router';

// We need a styled up version of an alert box.
// the ng prime doesnt provide this with the confirmation boxes, or with the ablity to select the confirmation box based on key
// This is call smarts confirmation box based on the ng prime dialog with the dynamic tags.
// it will be added to the app.component.html so only one in the dom.
// it will use an alert service to trigger the alerts from other parts of the application

@Component({
   selector: 'callsmart-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

   private _notification_subscription: Subscription;
   private _alert_subscription: Subscription;
   private _spinner_subscription: Subscription;
   private _error_subscription: Subscription;
   private _user_subscription: Subscription;
   private _idle_timeout: Subscription;

   public msgs: Message[] = [];
   public sticky: boolean = false;
   public lifetime: number = 3000;

   // properties for the alert box
   public alertDialog = AlertDialogComponent;
   // properties for the spinner box
   public spinnerDialog = SpinnerDialogComponent;

   public showAlertDialog: boolean = false;
   public showSpinnerDialog: boolean = false;

   // Properties for the error dialog dynamic loading
   public dialogInput = { display: false, title: '', bodyText: '' };
   public dialogOutput = {
      cancel: () => this.onCancelEvent()
   };

   // Properties for the error dialog dynamic loading
   public spinnerDialogInput = { display: false };

   public confirmDialogHeight: number = 400;
   public confirmDialogWidth: number = 600;

   public errorDialog = ErrorDialogComponent; // Dialog to display validation errors.
   public showErrorDialog: boolean = false; // Sets the visibility of the error dialog component.

   // Properties for the error dialog dynamic loading
   public errorDialogInput = { display: false, validationErrors: [], title: '', bodyText: '', bottomLink: '' };
   public errorDialogOutput = {
      cancel: () => this.onErrorDialogCancelEvent()
   };

   constructor(private _applicationStore: ApplicationStore, private _alertStore: AlertStore,
      private _spinnerStore: SpinnerStore, private _errorStore:ErrorStore , private windowService: BrowserWindowService,
      private idle: Idle, private _router: Router) {

      //subscribe to the window resize event
      windowService.width$.subscribe((value: number) => {
         this.confirmDialogWidth = value * 0.35;
      });
   }

   ngOnInit() {
      this.setIdleTimeOut();
      this.subscribetoNotifications();
      this.subscribeToAlerts();
      this.subscribeToSpinner();
      this.subscribeToErrors();
      this.subscribeToUser();
      this.subscribeToIdleTimeOut();
   }

   ngOnDestroy() {
      if (this._notification_subscription) {
         this._notification_subscription.unsubscribe();
      }
      if (this._alert_subscription) {
         this._alert_subscription.unsubscribe();
      }
      if (this._spinner_subscription) {
         this._spinner_subscription.unsubscribe();
      }
      if (this._error_subscription) {
         this._error_subscription.unsubscribe();
      }
      if (this._user_subscription) {
         this._user_subscription.unsubscribe();
      }
      if (this._idle_timeout) {
         this._idle_timeout.unsubscribe();
      }
   }

   showNotificacion(notifications: ReadonlyArray<Notification>) {
      if (notifications.length > 0) {

         notifications.forEach(notification => {
            this.msgs.unshift(notification);
         });

         // notifications.forEach(notification => {
         //    this.msgs.push({ severity: 'info', summary: notification.title, detail: notification.message });
         // });
         //  notifications.forEach(notification => {
         //    this.msgs= [...this.msgs,{ severity: 'info', summary: notification.title, detail: notification.message }];
         // });
      }
   }


   // cancel button from the dialog
   public onCancelEvent() {
      this.showAlertDialog = false;
      this.dialogInput.display = false;
   }

   public onErrorDialogCancelEvent() {
      this.showErrorDialog = false;
      this.errorDialogInput.display = false;
      this._errorStore.notify();
   }

   private subscribeToAlerts() {
      this._alert_subscription = this._alertStore.alert$.subscribe(
         (alert: Alert) => {
            if (alert) {
               this.showAlert(alert);
            }
         }
      );
   }

   private subscribeToErrors() {
      this._error_subscription = this._errorStore.error$.subscribe(
         (errorData: ErrorData) => {
            if (errorData) {
               this.showError(errorData);
            }
         }
      );
   }

   private subscribetoNotifications() {
      // Get the callers list from the callers service.
      this._notification_subscription = this._applicationStore.notificationsStore.notifications$.subscribe(
         (notifications: ReadonlyArray<Notification>) => {
            if (notifications) {
               this.showNotificacion(notifications);
            }
         }
      );
   }

   private subscribeToSpinner() {
      // Gets whether the spinner dialog has to be showed.
      this._spinner_subscription = this._spinnerStore.spinner$.subscribe(
         (visibility: boolean) => {
            this.showSpinner(visibility);
         }
      );
   }

   private subscribeToUser() {
      this._user_subscription = this._applicationStore.authenticationStore.user$.subscribe(
         (user: User) => {
            this.idle.watch();
         }
      );
   }
   private subscribeToIdleTimeOut() {
      this._idle_timeout = this.idle.onTimeout.subscribe(
         () => {
            this._router.navigate(['logout']);
         }
      );
   }

   private showAlert(alert: Alert) {
      this.showAlertDialog = true;
      this.dialogInput.title = alert.title;
      this.dialogInput.bodyText = alert.body;
      this.dialogInput.display = true;
   }

   private showError(errorData: ErrorData) {
      this.showErrorDialog = true;
      this.errorDialogInput.title = errorData.title;
      this.errorDialogInput.bodyText = errorData.bodyText;
      this.errorDialogInput.bottomLink = errorData.bottomLink;
      this.errorDialogInput.validationErrors = errorData.errors;
      this.errorDialogInput.display = true;
   }

   private showSpinner(visibility: boolean) {
      this.showSpinnerDialog = visibility;
      this.spinnerDialogInput.display = visibility;
   }

   private setIdleTimeOut() {
      this.idle.setIdle(10); // how long can they be inactive before considered idle, in seconds
      this.idle.setTimeout(60*60); // how long can they be idle before considered timed out, in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
      this.idle.watch();
   }

}
