import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { RangeBarComponent } from "app/shared/range-bar/range-bar.component";

/**
 * Module for Range Bar component
 */
@NgModule({
   imports: [CommonModule],
   exports: [RangeBarComponent],
   declarations: [RangeBarComponent]
})
export class RangeBarModule { }
