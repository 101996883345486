
import { DataTable } from 'primeng/primeng';
import { Callpoint } from 'app/models/callpoint';

import { Identifiable } from 'app/shared/interfaces/Identifiable.interface';

// takes an array  filters it to the distinct values
// returns these values in an array of objects with label abd value properties
// allowing for easy binding on a combo box or multi select box
export function generateUniqueItems(allLabel: string, items: any[]): any[] {
   let uniqueItems = Array.from(new Set(items));
   let data = [];

   if (allLabel.length > 0) {
      data.push({ label: allLabel, value: null });
   }

   uniqueItems.forEach(i => {
      data.push({ label: i, value: i });
   });

   return data;
}

export function contains<T extends Identifiable>(array: T[], item: T): boolean {
   return array.filter(element => element.guid === item.guid).length > 0;
}

export function groupBy(list, prop) {
   return list.reduce(function(groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
   }, {});
}

export function compareKeysFromKeyValueObject(key1, order = 'asc') {
   return function(a, b) {
      const varA =
         typeof a[0][key1] === 'string' ? a[0][key1].toUpperCase() : a[0][key1];
      const varB =
         typeof b[0][key1] === 'string' ? b[0][key1].toUpperCase() : b[0][key1];

      let comparison = 0;
      if (varA > varB) {
         comparison = 1;
      } else if (varA < varB) {
         comparison = -1;
      }

      return order == 'desc' ? comparison * -1 : comparison;
   };
}

// function for dynamic sorting needed for hand rolled sorting
// function from  might be faster ways
// https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/
export function compareValues(key1, order = 'asc') {

   if(key1 === null || key1 === undefined) return;

   // treat the values as an array rather than object in programming language
   var keys = key1.split('.');
   var keyLength = keys.length;

   return function(a, b) {
      const varA =  (keyLength === 1) ? typeof a[keys[0]] === 'string' ? a[keys[0]].toUpperCase() : a[keys[0]]
                                 : typeof a[keys[0]][keys[1]] === 'string' ? a[keys[0]][keys[1]].toUpperCase() : a[keys[0]][keys[1]];
      const varB =  (keyLength === 1) ? typeof b[keys[0]] === 'string' ? b[keys[0]].toUpperCase() : b[keys[0]]
                                 : typeof b[keys[0]][keys[1]] === 'string' ? b[keys[0]][keys[1]].toUpperCase() : b[keys[0]][keys[1]];

      let comparison = 0;
      if (varA > varB) {
         comparison = 1;
      } else if (varA < varB) {
         comparison = -1;
      }

      return order == 'desc' ? comparison * -1 : comparison;
   };
}

// hand rolled filter ripped from primeng datatable
export function filter(
   value,
   filters,
   datatable: DataTable,
   allCols: any[],
   totalRecords: number
) {
   // not catering for a global fiter
   let globalFilter = false;
   let filteredValue = [];

   for (let i = 0; i < value.length; i++) {
      let localMatch = true;
      let globalMatch = false;

      for (let j = 0; j < allCols.length; j++) {
         let col = allCols[j];
         let filterMeta = filters[col.filterField || col.field];
         //local
         if (filterMeta) {
            let filterValue = filterMeta.value,
               filterField = col.filterField || col.field,
               filterMatchMode = filterMeta.matchMode || 'startsWith',
               dataFieldValue = datatable.resolveFieldData(
                  value[i],
                  filterField
               );
            let filterConstraint = datatable.filterConstraints[filterMatchMode];

            if (!filterConstraint(dataFieldValue, filterValue)) {
               localMatch = false;
            }
            if (!localMatch) {
               break;
            }
         }
         //global
         if (!col.excludeGlobalFilter && globalFilter && !globalMatch) {
            globalMatch = datatable.filterConstraints['contains'](
               datatable.resolveFieldData(
                  value[i],
                  col.filterField || col.field
               ),
               globalFilter.value
            );
         }
      }

      let matches = localMatch;
      if (globalFilter) {
         matches = localMatch && globalMatch;
      }
      if (matches) {
         filteredValue.push(value[i]);
      }
   }
   if (filteredValue.length === value.length) {
      datatable.filteredValue = null;
   }
   if (datatable.paginator) {
      totalRecords = filteredValue
         ? filteredValue.length
         : value
         ? value.length
         : 0;
   }

   return filteredValue;
}

export function compareFullyScheduled(
   order,
   sortedAndFilteredData: Callpoint[]
) {
   let scheduleVisitsGroups: Callpoint[][] = groupBy(
      sortedAndFilteredData,
      'scheduledVisits'
   );

   //let sortItemsPerGroup : IterableIterator<Callpoint[]> = scheduleVisitsGroups.values();
   let scheduleVisitsGroupsArray = Object.keys(scheduleVisitsGroups).map(
      itm => scheduleVisitsGroups[itm]
   );
   scheduleVisitsGroupsArray.sort(
      compareKeysFromKeyValueObject('scheduledVisits', order)
   );

   scheduleVisitsGroupsArray.forEach(group => {
      group.sort(compareValues('frequency', order));
   });
   return scheduleVisitsGroupsArray;
}

export function getComboDataForSpecifiedProperty<T>(
   items: ReadonlyArray<T>,
   matchingFunc: (entity: T) => any,
   sortData: boolean,
   direction: string
) {
   let data = null;
   if (items.length > 0) {
      let itemsFiltered = items.map(item => matchingFunc(item));
      data = generateUniqueItems('', itemsFiltered);

      if (sortData) {
         data.sort(compareValues('value', direction));
      }
   }
   return data;
}
