<div style="margin:100px; margin-top: 50px;">
   <h2>CallSmart</h2>

   <div style="margin-left:25px; margin-top:25px;margin-right:25px">
      <h4 style="color: #3cbe85">
         CACI - CallSmart - Conditions of Use ("Conditions")
      </h4>
      <div class="small-text" style="margin-left:20px">
         These conditions of use govern your use of our site at https://callsmart.caci.co.uk/ (“the Site”) , on which
         the
         CallSmart Software is made available to you, at (“Site”).
         Please read these Condition before using the CallSmart Software. By using the CallSmart Software you will
         indicate your acceptance of these Conditions.
         CACI may revise these Conditions at any time by amending this page. Please check this page from time to time
         to
         take notice of any changes we made, as they are binding on you.
         In the event of any inconsistency, ambiguity or other conflict between these Conditions and the terms of the
         Agreement, these Conditions shall prevail.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         USE OF THE SITE
      </h4>
      <div class="small-text" style="margin-left:20px">
         You are permitted to use our Site to access and use the CallSmart Software for the duration of your licence
         Agreement with CACI for your company’s internal business purposes, provided always such use is in accordance
         with
         the terms of the Agreement. At the end of your licence Agreement with CACI you acknowledge that your use of
         the
         CallSmart Software and Site will terminate.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         ACCESSING THE SITE
      </h4>
      <div class="small-text" style="margin-left:20px">
         CACI does not guarantee that the Site, or any content on it, will always be available or be uninterrupted and
         also does not guarantee that the Site will be free from errors or omissions. Access to the Site is permitted
         for
         the duration of your licence Agreement with CACI.
         CACI may suspend, withdraw, discontinue or change all or any part of the Site without notice. CACI will not be
         liable to you if for any reason the Site is unavailable at any time or for any period.
         You are responsible for making all arrangements necessary for you to have access to the Site.
         You are also responsible for ensuring that all persons who access the Site through your internet connection
         are
         aware of these Conditions and other applicable terms and conditions and that they comply with them.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         INTELLECTUAL PROPERTY RIGHTS
      </h4>
      <div class="small-text" style="margin-left:20px">
         CACI is the owner or the licensee of all intellectual property rights in the CallSmart Software and the Site
         (including all material published in it). The CallSmart Software and the Site are protected by copyright laws
         and
         treaties around the world. All such rights are reserved. In using the CallSmart Software, you agree that: (a)
         you
         may not lease or rent the CallSmart Software or use, copy or modify the CallSmart Software in whole or part
         except as expressly permitted in this licence; (b) you may not reverse engineer, decompile or disassemble the
         CallSmart Software except to the extent that the foregoing restriction is expressly prohibited by law; (c) you
         may not copy the documentation provided with the CallSmart Software; and (d) you may not remove or obscure the
         CACI copyright or trademark notices. If you fail to comply with any of the terms within these Conditions, your
         licence will be automatically terminated. Such termination shall be in addition to and not in lieu of any
         other
         remedies available to CACI.
         In using the Site, you must not use any content of the Site (whether in whole or in part) for commercial
         purposes, without obtaining a licence to do so.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         YOUR ACCOUNT AND PASSWORD
      </h4>
      <div class="small-text;" style="margin-left:20px">
         If you are provided with login details to use the Site as part of our security procedures, you must treat such
         information as confidential and not disclose it to any third party.
         We have the right to disable any user identification code or password, whether chosen by you or allocated by
         us,
         at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these
         Conditions.
         If you know or suspect that anyone other than you knows your user identification code or password, you must
         promptly notify us.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         WARRANTIES AND LIABILITY
      </h4>
      <div class="small-text" style="margin-left:20px">
         CACI does not warrant that the functions contained in the CallSmart Software will meet your requirements or
         that
         the operation of the CallSmart Software will be entirely error free or appear entirely as described in the
         CallSmart help text. CACI’s entire liability and your exclusive remedy shall be the return of any monies paid
         for
         any CallSmart Software which, within 30 days from the date of its Delivery to the Client, does not meet CACI’s
         limited warranty and which is returned to CACI with full details.
         To the maximum extent permitted by law, CACI disclaims all other warranties, either express or implied,
         including, but not limited to, implied warranties of merchantability and fitness for any particular purpose
         relating to the CallSmart Software and associated documentation. Nothing in these Conditions excludes or
         limits
         CACI’s liability for death or personal injury arising from CACI’s negligence, or CACI’s fraud or fraudulent
         misrepresentation, or any other liability that cannot be excluded or limited by English law.
         CACI will not be liable to any user for any loss or damage, whether in contract, tort (including negligence),
         breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
         <ul>
            <li> Use of, or inability to use, the Site or the CallSmart Software; or</li>
            <li> Use of or reliance on any content displayed on the Site.
         </ul>         
         In particular, CACI will not be liable for:
         <ul>
            <li> Loss of profits, sales, business, or revenue;</li>
            <li> Business interruption;</li>
            <li> Loss of anticipated savings;</li>
            <li> Loss of business opportunity, goodwill or reputation; or</li>
            <li> Any indirect or consequential loss or damage.</li>
         </ul>
         CACI will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or
         other
         technologically harmful material that may infect your computer equipment, computer programs, data or other
         proprietary material due to your use of the Site or to your downloading of any content on it, or on any
         website
         linked to it.
      </div>

      <br>
      <h4 style="color: #3cbe85">
         VIRUSES
      </h4>
      <div class="small-text" style="margin-left:20px">
         CACI does not guarantee that the Site will be secure or free from bugs or viruses.
         You are responsible for configuring your information technology, computer programmes and platform in order to
         access the Site. You should use your own virus protection software.
         You must not misuse the Site by knowingly introducing viruses, trojans, worms, logic bombs or other material
         which is malicious or technologically harmful. You must not attempt to gain unauthorised access to the Site,
         the
         server on which the Site is stored or any server, computer or database connected to the Site. You must not
         attack
         the Site via a denial-of-service attack or a distributed denial-of service attack. By breaching this
         provision,
         you would commit a criminal offence under the Computer Misuse Act 1990. CACI will report any such breach to
         the
         relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity
         to
         them. In the event of such a breach, your right to use the Site and the CallSmart Software will cease
         immediately.
      </div>
   </div>
</div>
