import { Component, forwardRef } from '@angular/core';
import { Dropdown, DomHandler } from "primeng/primeng";
import { ObjectUtils } from "primeng/components/utils/objectutils";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

export const DROPDOWN_VALUE_ACCESSOR: any = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => CsDropdownComponent),
   multi: true
};

@Component({
   selector: 'callsmart-dropdown',
   templateUrl: 'dropdown.component.html',
   animations: [
      trigger('panelState', [
         state('hidden', style({
            opacity: 0
         })),
         state('visible', style({
            opacity: 1
         })),
         transition('visible => hidden', animate('400ms ease-in')),
         transition('hidden => visible', animate('400ms ease-out'))
      ])
   ],
   providers: [DomHandler, ObjectUtils, DROPDOWN_VALUE_ACCESSOR]
})

export class CsDropdownComponent extends Dropdown {}
