<!-- Taken from

https://github.com/primefaces/primeng/blob/master/src/app/components/multiselect/multiselect.ts
 -->

<div #container [ngClass]="{'ui-multiselect ui-widget ui-state-default ui-corner-all':true,'ui-state-focus':focus,'ui-state-disabled': disabled}"
   [ngStyle]="style" [class]="styleClass" (click)="onMouseclick($event,in)">
   <div class="ui-helper-hidden-accessible">
      <input #in type="text" readonly="readonly" [attr.id]="inputId" (focus)="onFocus($event)" (blur)="onInputBlur($event)" [disabled]="disabled"
         [attr.tabindex]="tabindex">
   </div>
   <div class="ui-multiselect-label-container" [title]="valuesAsString">
      <label class="ui-multiselect-label ui-corner-all">{{valuesAsString}}</label>
   </div>
   <div [ngClass]="{'ui-multiselect-trigger ui-state-default ui-corner-right':true}">
      <span class="fa fa-fw fa-caret-down ui-clickable"></span>
   </div>
   <div #panel [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow', panelStyleClass||'']" [ngStyle]="panelStyle"
      [style.display]="overlayVisible ? 'block' : 'none'" (click)="panelClick=true">
      <div class="  ui-multiselect-header ui-multiselect-panel-title">{{panelTitle}}</div>
      <div class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix">

         <div class="ui-multiselect-filter-container" *ngIf="filter">
            <input type="text" role="textbox" (input)="onFilter($event)" class="ui-inputtext ui-widget ui-state-default ui-corner-all">
            <span class="cs-icon icon-search-1" ></span>
         </div>

         <div class="ui-chkbox ui-widget multiselect-select-all-chk">
            <div class="ui-helper-hidden-accessible">
               <input #cb type="checkbox" readonly="readonly" [checked]="isAllChecked()">
            </div>
            <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" [ngClass]="{'ui-state-active':isAllChecked()}" (click)="toggleAll($event,cb)">
               <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'fa fa-check':isAllChecked()}"></span>
            </div>

         </div>
         <span>
               <label class="multiselect-select-all">Select all</label>
         </span>
         <!-- custom change remove close-->
         <!--<a class="ui-multiselect-close ui-corner-all" href="#" (click)="close($event)">
                        <span class="fa fa-close"></span>
                    </a>-->
      </div>
      <div class="ui-multiselect-items-wrapper">
         <ul class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" [style.max-height]="scrollHeight||'auto'" [style.background-color]="'#393939'">
            <li *ngFor="let option of options; let index = i" class="ui-multiselect-item ui-corner-all"
            (click)="!option.value.disabled && onItemClick($event,option.value)" [style.display]="isItemVisible(option) ? 'block' : 'none'"
               [ngClass]="{'ui-state-highlight':isSelected(option.value), 'ui-state-disabled':option.value.disabled}">
               <div class="ui-chkbox ui-widget" *ngIf="!option.value.disabled">
                  <div class="ui-helper-hidden-accessible">
                     <input type="checkbox" readonly="readonly" [checked]="isSelected(option.value)" >
                  </div>
                  <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                       [ngClass]="{'ui-state-active':isSelected(option.value),  'ui-state-disabled':option.value.disabled}" >
                     <span class="ui-chkbox-icon ui-clickable disabled" [ngClass]="{'fa fa-check':isSelected(option.value)}"></span>
                  </div>
               </div>
               <label *ngIf="!itemTemplate">{{option.label}}</label>
               <ng-template [pTemplateWrapper]="itemTemplate" [item]="option" [index]="i" *ngIf="itemTemplate"></ng-template>
            </li>
         </ul>
      </div>
   </div>
</div>
