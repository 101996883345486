<div class="small-text cs-color-white contextualPanel-background-color">
   <h2 class="contextualPanel-header-color">
      {{ selectedCallers.length }} Callers Selected
   </h2>

   <div style="margin-top: 10px; margin-bottom: 10px;">Optimise selected:</div>
   <div style="display: block; padding-bottom: 45px;">
      <button
         type="button"
         class="btn cs-btn-primary-inverse context-panel-optimise-buttons"
         style="float: left;"
         (click)="onFastOptimise()"
         [disabled]="optimiseButtonDisabled"
      >
         <i class="cs-icon icon-hourglass-3" style="font-size: 15px;"></i> Fast
      </button>
      <button
         type="button"
         class="btn cs-btn-primary-inverse context-panel-optimise-buttons"
         style="float: right;"
         (click)="onFullOptimise()"
         [disabled]="optimiseButtonDisabled"
      >
         <i class="cs-icon icon-hourglass" style="font-size: 15px;"></i> Full
      </button>
   </div>

   <div
      class="btn-group"
      style="
         margin-top: 10px;
         margin-bottom: 10px;
         display: table;
         overflow: auto;
         width: 100%;
      "
   >
      <button
         type="button"
         class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false"
      >
         <span style="float: left;">Actions</span>
         <span class="cs-icon icon-arrow-down dropdown-button-icon"></span>
      </button>
      <ul class="dropdown-menu cs-dropdown-inverse" style="width: 220px;">
         <li>
            <a (click)="editCallers()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-edit"
               ></span>
               Edit Callers</a
            >
         </li>
         <li>
            <a (click)="clearSchedule()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-calendar-9"
               ></span>
               Clear schedule: All</a
            >
         </li>
         <li>
            <a (click)="clearScheduleUnlocked()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-calendar-8"
               ></span>
               Clear schedule: Unlocked</a
            >
         </li>
         <li *ngIf="showScheduleExport">
            <a (click)="exportSchedule()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-upload"
               ></span>
               Export schedule</a
            >
         </li>
         <li *ngIf="showCallerExport">
            <a (click)="exportCallersData()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-upload"
               ></span>
               Export Caller(s)</a
            >
         </li>
         <li *ngIf="showCallpointExport">
            <a (click)="exportSchedule()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-upload"
               ></span>
               Export Callpoint(s)</a
            >
         </li>
         <li>
            <a (click)="editCallpointLocking()">
               <span
                  style="margin-right: 5px;"
                  class="cs-icon icon-edit"
               ></span>
               Mulitple Callpoints</a
            >
         </li>
      </ul>
   </div>

   <!-- If selected callers are less than 11 then list out the callers -->
   <div *ngIf="selectedCallers.length < 11">
      <ul class="list-group contextualPanel-background-color">
         <li
            class="list-group-item"
            *ngFor="let selectedCaller of selectedCallers"
         >
            <div (click)="selectSingleCaller(selectedCaller)">
               {{ selectedCaller.territory }} -
               <strong>{{ selectedCaller.name }}</strong>
            </div>

            <!-- Anchor tag is used here to represent the click box area for the close icon, it can be sized
             to make it as large as needed. If we add the click event to the span tag then it will be too small.-->
            <a (click)="removeCallerFromSelection(selectedCaller)">
               <span
                  style="padding-left: 7px; padding-top: 6px;"
                  class="fa fa-fw fa-close"
               ></span>
            </a>
         </li>
      </ul>
   </div>

   <!-- This is where caller settings dialog component will be loaded dynamically -->
   <ndc-dynamic
      *ngIf="showCallerSettings"
      [ndcDynamicComponent]="editCallerSettingsDialog"
      [ndcDynamicInputs]="dialogInput"
      [ndcDynamicOutputs]="dialogOutput"
   >
   </ndc-dynamic>

   <!-- This is where edit callpoint locking dialog component will be loaded dynamically -->
   <ndc-dynamic
      *ngIf="showEditCallpoints"
      [ndcDynamicComponent]="editCallpontsDialog"
      [ndcDynamicInputs]="editCallpointsDialogInput"
      [ndcDynamicOutputs]="editCallpointsDialogOutput"
   >
   </ndc-dynamic>
</div>
