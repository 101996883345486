import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { VisitsService } from 'app/services/visits.service';
import { Visit } from 'app/models/visit';
import { ErrorHandlerService } from 'app/services/error-handler.service';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class VisitsStore {

   // Visits .
   private _visits: BehaviorSubject<ReadonlyArray<Visit>> = new BehaviorSubject<ReadonlyArray<Visit>>([]);
   public visits$: Observable<ReadonlyArray<Visit>> = this._visits.asObservable();

   //currently selected visit
   private _selectedVisits: BehaviorSubject<ReadonlyArray<Visit>> = new BehaviorSubject<ReadonlyArray<Visit>>([]);
   public selectedVisits$: Observable<ReadonlyArray<Visit>> = this._selectedVisits.asObservable();

   public get visits(): ReadonlyArray<Visit> {
      return this._visits.getValue();
   }

   public get selectedVisits(): ReadonlyArray<Visit> {
      return this._selectedVisits.getValue();
   }

   public getCallpointVisits(callpointIds: number[]): Visit[] {
      return this._visits.getValue().filter(visit => callpointIds.includes(visit.callpointId));
   }

   // Initialising the selected columns to be displayed in the callpoints grid. This array will allow that grid to
   // show the latest column selection when loading
   public selectedCols = [
      { field: 'isScheduled', header: 'Scheduled', disabled: true, filter: true, filterPlaceholder: 'equals', filterMatchMode: 'equals', hasCombo: true, hasMulti: false },
      { field: 'referenceAndOrder', header: 'Visit ID', disabled: true, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'callpointReference', header: 'Code', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'callpointDescription', header: 'Name', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'frequency', header: 'Frequency', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'order', header: 'Phase', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'scheduleWeek', header: 'Week', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'dayOfWeek', header: 'Day', disabled: false, filter: true, filterPlaceholder: 'in', filterMatchMode: 'in', hasCombo: false, hasMulti: true },
      { field: 'sequenceInDay', header: 'Day Sequence', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'shortDate', header: 'Date', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
      { field: 'lockType', header: 'Status', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false },
   ];

   constructor(private _visitsService: VisitsService,
      private _errorHandler: ErrorHandlerService) {
      // check if the local storage has callpoint columns
      let storedColumns = JSON.parse(localStorage.getItem("visitsSelectedCols"));
      if (storedColumns) {
         if (storedColumns.length > 0) {
            this.selectedCols = storedColumns;
         }
      }
   }

   public setSelectedVisits(visits: Visit[]) {
      this._selectedVisits.next(visits);
      // does anything else need to refresh?
   }

   public loadVisitsForCaller(projectId: number, callerId: number) {
      this._visitsService.getVisits(projectId, callerId)
         .subscribe(
            (events: Visit[]) => {
               this._visits.next(events);
               // clear the selected visit
               this._selectedVisits.next([]);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );

   }

   public clearScheduledVisits() {
      let deferrals: Visit[] = this._visits.getValue().filter(item => !item.isScheduled)
      this._visits.next(deferrals);
   }
}
