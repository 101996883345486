<div class="panel small-text cs-color-white contextualPanel-background-color">
   <h2 *ngIf="dayTitle" class="contextualPanel-header-color">{{dayTitle}}</h2>
   <h2 *ngIf="!dayTitle" class="contextualPanel-header-color">No day selected</h2>
   <span>{{weekNumberTitle}} {{weekPeriodTitle}} </span>
   <!-- <div style="margin-top: 10px; margin-bottom: 10px;">Optimise</div>
   <div style="margin: 10px auto;display: block; overflow:auto; width:auto">
      <button type="button" class="btn cs-btn-primary context-panel-optimise-buttons" style="float: left;" (click)="onFastOptimise()"
         [disabled]="optimiseButtonDisabled">
         <i class="cs-icon icon-hourglass-3 " style="font-size: 15px"></i> Fast</button>
      <button type="button" class="btn cs-btn-primary context-panel-optimise-buttons" style="float: right;" (click)="onFullOptimise()"
         [disabled]="optimiseButtonDisabled">
         <i class="cs-icon icon-hourglass" style="font-size: 15px"></i> Full</button>
   </div> -->
   <div [ngClass]="{'cs-block-ui': !dayTitle}">
      <div class="btn-group" style="margin-top: 10px; margin-bottom: 10px; display: table; overflow:auto; width:100%;">
         <button type="button" class="btn cs-btn-secondary-inverse dropdown-toggle cs-btn-secondary-inverse-dropdown" 
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span style="float: left">Actions</span> 
            <span class="cs-icon icon-arrow-down dropdown-button-icon" ></span>
         </button>
         <ul class="dropdown-menu cs-dropdown-inverse" style="width:220px;">
            <li><a (click)="optimiseDay()"><span style="margin-right:5px;" class="cs-icon icon-hourglass-3"></span>Optimise</a></li>            
            <li><a (click)="swapDays()"><span style="margin-right:5px;" class="cs-icon icon-repeat"></span>Swap days</a></li>            
            <li [ngClass]="{'disabled': enableLunchOption() || isDayHalfClosed || totalVisits == 0}">
               <a (click)="addLunch()" [ngClass]="{'callsmart-link-no-active' : enableLunchOption() || isDayHalfClosed || totalVisits == 0}">
                  <span style="margin-right:5px;" class="cs-icon icon-hourglass-3"></span>Add lunch
               </a>
            </li>            
            <li [ngClass]="{'disabled': !enableLunchOption() || isDayHalfClosed || totalVisits == 0}">
               <a (click)="removeLunch()" [ngClass]="{'callsmart-link-no-active' : !enableLunchOption() || isDayHalfClosed || totalVisits == 0}">
                  <span style="margin-right:5px;" class="cs-icon icon-hourglass-3"></span>Remove lunch
               </a>
            </li>
            <li [ngClass]="{'disabled': enableOvernightOption() || totalVisits == 0}">
               <a (click)="addOvernight()" [ngClass]="{'callsmart-link-no-active' : enableOvernightOption() || totalVisits == 0}">
                  <span style="margin-right:5px;" class="cs-icon icon-hourglass-3"></span>Add Overnight
               </a>
            </li>
            <li [ngClass]="{'disabled': !enableOvernightOption() || totalVisits == 0}">
               <a (click)="removeOvernight()" [ngClass]="{'callsmart-link-no-active' : !enableOvernightOption() || totalVisits == 0}">
                  <span style="margin-right:5px;" class="cs-icon icon-hourglass-3"></span>Remove Overnight
               </a>
            </li>            
         </ul>
      </div>

      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Total visits:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="dayTitle && !isDayClosed; else noTotalVisits" class="display-flex-column display-flex-first-column">
                  {{totalVisits}}
               </div>
               <ng-template #noTotalVisits>
                  - -
               </ng-template>
            </div>
         </div>
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Total drive time:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="dayTitle && !isDayClosed; else noTotalDriveTime" class="display-flex-column display-flex-first-column">
                  {{totalDriveTime}} hrs
               </div>
               <ng-template #noTotalDriveTime>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
      <div class="no-padding contextual-panel-list">
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Total visit time:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="dayTitle && !isDayClosed; else noTotalVisitTime" class="display-flex-column display-flex-first-column">
                  {{totalVisitTime}} hrs
               </div>
               <ng-template #noTotalVisitTime>
                  - -
               </ng-template>
            </div>
         </div>
         <div class="no-padding contextualPanel-list-items">
            <div class="contextualPanel-list-items-firstline">Free working time:</div>
            <div class="contextualPanel-list-items-secondline">
               <div *ngIf="dayTitle && !isDayClosed; else noFreeWorkingTime" class="display-flex-column display-flex-first-column">
                  {{freeWorkingTime}} hrs
               </div>
               <ng-template #noFreeWorkingTime>
                  - -
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- This is where swap days dialog component will be loaded dynamically -->
<ndc-dynamic *ngIf="showSwapDaysDialog"
             [ndcDynamicComponent]="swapDaysDialog"
             [ndcDynamicInputs]="dialogInput"
             [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
