import { Component, OnInit } from '@angular/core';
import { ApplicationStore } from 'app/stores/application-store';
import { Router } from '@angular/router';

/**
 * Based on the Logout component needed for closing a project from nav bar
 */

@Component({
   template: ''
})

export class CloseProjectComponent implements OnInit {

   constructor(private _router: Router, private _applicationStore: ApplicationStore) { }

   public ngOnInit() {
      if(this._applicationStore.projectsStore.selectedProject) {
         this._applicationStore.projectsStore.closeProject(this._applicationStore.projectsStore.selectedProject.projectId, 
            this._applicationStore.authenticationStore.loggedInUser.userId);
      }
      this._router.navigate(['open-project']);
   }

}
