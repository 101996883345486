import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { Caller } from 'app/models/caller';
import { Callpoint } from 'app/models/callpoint';
import { ExportParameters } from 'app/models/export-parameters';
import { MapPoint } from 'app/models/map-point';
import { CallpointService } from 'app/services/callpoint.service';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { ImportCallpointCounts } from 'app/models/import-callpoint-counts';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { LockingTypes } from 'app/models/diary/locking-Types';
import { SpinnerStore } from './spinner-store';
import { CallersStore } from './callers-store';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

@Injectable()
export class CallpointsStore {
   private mapIconCallPoint: string = 'assets/icons/CP.png';
   private mapIconCallPointSelected: string = 'assets/icons/CP_Selected.png';
   private mapIconCallPointHighlighted: string =
      'assets/icons/CP_Highlighted.png';

   // callpoints in the currently selected  caller.
   private _callpoints: BehaviorSubject<
      ReadonlyArray<Callpoint>
   > = new BehaviorSubject<ReadonlyArray<Callpoint>>([]);
   public callpoints$: Observable<
      ReadonlyArray<Callpoint>
   > = this._callpoints.asObservable();

   // multiple callpoints for multiple selected callers Used in bulk editing callboints locking.
   private _callpointsForMultipleCallers: BehaviorSubject<ReadonlyArray<Callpoint>> = new BehaviorSubject<ReadonlyArray<Callpoint>>([]);
   public callpointsForMultipleCallers$: Observable<ReadonlyArray<Callpoint>> = this._callpointsForMultipleCallers.asObservable();

   // Callpoints Map points in the current project.
   private _callpointsMapPoints: BehaviorSubject<
      Array<MapPoint>
   > = new BehaviorSubject<Array<MapPoint>>([]);
   public callpointsMapPoints$: Observable<
      Array<MapPoint>
   > = this._callpointsMapPoints.asObservable();

   // currently selected  callpoints
   private _selectedCallpoints: BehaviorSubject<
      Array<Callpoint>
   > = new BehaviorSubject<Array<Callpoint>>(null);
   public selectedCallpoints$: Observable<
      Array<Callpoint>
   > = this._selectedCallpoints.asObservable();

   // closed days  in the currently selected  caller.
   private _closedDates: BehaviorSubject<
      ReadonlyArray<number>
   > = new BehaviorSubject<ReadonlyArray<number>>([]);
   public _closedDates$: Observable<
      ReadonlyArray<number>
   > = this._closedDates.asObservable();

   // Event for deselecting a caller from the list and map, used by contextual panel.
   private _deselectCallpoint: BehaviorSubject<string> = new BehaviorSubject<
      string
   >(null);
   public deselectCallpoint$: Observable<
      string
   > = this._deselectCallpoint.asObservable();

   // Event for single selecting a caller from a multi select, used by contextual panel multi select callers.
   private _singleSelectCallpoint: BehaviorSubject<
      Callpoint
   > = new BehaviorSubject<Callpoint>(null);
   public singleSelectCallpoint$: Observable<
      Callpoint
   > = this._singleSelectCallpoint.asObservable();

   private _callpointDisabledChanged: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
   public callpointDisabledChanged$: Observable<Array<number>> = this._callpointDisabledChanged.asObservable();

   private _callpointLocationChanged: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
   public callpointLocationChanged$: Observable<Array<number>> = this._callpointLocationChanged.asObservable();

   private _callpointCallerChanged: BehaviorSubject<Array<number>> = new BehaviorSubject<Array<number>>([]);
   public callpointCallerChanged$: Observable<Array<number>> = this._callpointCallerChanged.asObservable();

   private _callpointMergeCounts: Subject<ImportCallpointCounts> = new Subject<ImportCallpointCounts>();
   public callpointMergeCounts$: Observable<ImportCallpointCounts> = this._callpointMergeCounts.asObservable();

   private _callpointChanged: Subject<void> = new Subject<void>();
   public callpointChanged$: Observable<void> = this._callpointChanged.asObservable();

   // Number of total callpoints for the current project.
   private _callpointCountForProject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
   public callpointCountForProject$: Observable<number> = this._callpointCountForProject.asObservable();

   public callpointExportColumns: Map<string, string>;

   // Initialising the selected columns to be displayed in the callpoints grid. This array will allow that grid to
   // show the latest column selection when loading
   public selectedCols = [
      {
         field: 'reference',
         header: 'Code',
         disabled: true,
         filter: true,
         filterPlaceholder: 'contains',
         filterMatchMode: 'contains',
         hasCombo: false,
         hasMulti: false,
      },
      {
         field: 'name',
         header: 'Name',
         disabled: false,
         filter: true,
         filterPlaceholder: 'contains',
         filterMatchMode: 'contains',
         hasCombo: false,
         hasMulti: false,
      },
      {
         field: 'frequency',
         header: 'Frequency',
         disabled: false,
         filter: true,
         filterPlaceholder: 'in',
         filterMatchMode: 'in',
         hasCombo: false,
         hasMulti: true,
      },
      {
         field: 'duration',
         header: 'Duration',
         disabled: false,
         filter: true,
         filterPlaceholder: 'in',
         filterMatchMode: 'in',
         hasCombo: false,
         hasMulti: true,
      },
      {
         field: 'driveTimeToCallerHms',
         header: 'Drive Time to Caller (hh:mm)',
         disabled: false,
         filter: true,
         filterPlaceholder: 'equals',
         filterMatchMode: 'equals',
         hasCombo: false,
         hasMulti: false,
      },
      {
         field: 'fullyScheduled',
         header: 'Scheduled Status',
         disabled: false,
         filter: true,
         filterPlaceholder: 'in',
         filterMatchMode: 'in',
         hasCombo: false,
         hasMulti: true,
      },
   ];

   // return an array of selected callpoints
   public get selectedCallpoints() {
      return this._selectedCallpoints.getValue();
   }

   public get callpointsMapPoints() {
      return this._callpointsMapPoints.getValue();
   }

   public get callpoints() {
      return this._callpoints.getValue();
   }

   public get callpointDisabledChangedIds() {
      return this._callpointDisabledChanged.getValue();
   }

   public get callpointLatLngChangedIds() {
      return this._callpointLocationChanged.getValue();
   }

   public get callpointCallerChangedIds() {
      return this._callpointCallerChanged.getValue();
   }


   constructor(
      private _callpointService: CallpointService,
      private _errorHandler: ErrorHandlerService,
      private _spinnerStore: SpinnerStore,
      private _callerStore: CallersStore
   ) {
      // check if the local storage has callpoint columns
      let storedColumns = JSON.parse(
         localStorage.getItem('callpointsSelectedCols')
      );
      if (storedColumns) {
         if (storedColumns.length > 0) {
            this.selectedCols = this.deleteRedundantColumns(storedColumns);
         }
      }

      this.buildDefaultExportColumns();
   }

   public loadCallpointsForCaller(caller: Caller) {
      this._callpointService
         .getCallpointsAssignedToPerson(caller.callerId)
         .subscribe(
            (callpoints: Callpoint[]) => {
               this._callpoints.next(callpoints);
               this.buildCallpointsMapPoints();
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public loadCallpointsForMultipleCallers(
      projectId: number,
      callerIds: number[]
   ) {
      this._callpointService
         .getCallpointsAssignedToMultiplePeople(projectId, callerIds)
         .subscribe(
            (callpoints: Callpoint[]) => {
               this._callpointsForMultipleCallers.next(callpoints);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public updateCallpointsLockedStateForMultipleCallersAndRefreshData(
      projectId: number,
      callerIds: number[],
      callpointRefs: string[],
      lockingType: LockingTypes
   ) {
      this._callpointService
         .updateCallpointsLockedStateForMultipleCallers(
            projectId,
            callerIds,
            callpointRefs,
            lockingType
         )
         .subscribe(
            (response) => {
               this.loadCallpointsForMultipleCallers(projectId, callerIds);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public clearCallpointsForMultipleCallers() {
      this._callpointsForMultipleCallers.next([]);
   }

   public setSelectedCallpoints(callpoints: Callpoint[]) {
      this._selectedCallpoints.next(callpoints);
   }

   public deselectCallpoint(guid: string) {
      this._deselectCallpoint.next(guid);
   }

   public singleSelectCallpoint(callpoint: Callpoint) {
      this._singleSelectCallpoint.next(callpoint);
      this._selectedCallpoints.next([callpoint]);
   }

   public deleteCallpointsByProjectId(projectId: number) {
      this._callpointService.deleteCallpointsByProjectId(projectId).subscribe(
         (result: any[]) => {
            // clear the callpoints
            //this._callpoints.next(null);
            //this._callpointsMapPoints.next(null);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   public setCallpointsDisabledState(callpoints: Callpoint[], disable: boolean) {
      callpoints.forEach(callpoint => {
         callpoint.isDisabled = disable;
         if (disable) {
            callpoint.scheduledVisits = 0;
         }

      });
      this.updateCallpoints(callpoints);
   }

   // build the callpoint map points once and cache the points in the store
   public buildCallpointsMapPoints() {
      let points: MapPoint[] = [];

      this.callpoints.forEach((c) => {
         let wndBody =
            '<strong>' +
            c.reference +
            '-' +
            c.name +
            '</strong><br>' +
            c.geocode +
            '<br>Frequency = ' +
            c.frequency;
         points.push(
            new MapPoint(
               c.latitude,
               c.longitude,
               c.reference + '-' + c.name + '-(Frq - ' + c.frequency + ')' + '',
               wndBody,
               this.mapIconCallPoint,
               this.mapIconCallPointSelected,
               this.mapIconCallPoint,
               c.guid,
               c.frequency.toString()
            )
         );
      });

      this._callpointsMapPoints.next(points);
   }

   // MNF: updateCallpoints now does the job of both these methods and the old updateMultipleCallpointSettings.
   /*public updateCallpoint(callpoint: Callpoint) {
      this._callpointService.updateCallpoint(callpoint)
         .subscribe((res: Response) => { },
            (error) => this._errorHandler.handleError(error)
         );
   }

   public updateCallpointSettings(callpoint: Callpoint, callpointSettings: CallpointSettings) {
      this._callpointService.updateCallpointSettings(callpoint.callpointId, callpointSettings)
         .subscribe((id: string) => {
               // Hack - This is required until all user config settings are moved from Callpoint
               // object to CallpointSettings object. We get the id of the callpoint settings from the db and assign
               // that back to the callpoint settings object and then update the callpoint object
               // itself.
               callpoint.callpointSettings.callpointSettingsId = +id;
               this.updateCallpoint(callpoint);
            },
            (error) => this._errorHandler.handleError(error)
         );
   }*/

   public updateCallpoints(callpoints: Callpoint[]) {
      this._spinnerStore.showSpinner();
      this._callpointService.updateCallpoints(callpoints).subscribe(
         (response: any) => {
            this._spinnerStore.hideSpinner();
            // updateMultipleCallpointSettings returns an array in which each element contains a callpointId and a
            // callpointSettingsId. These are the ids of any new CallpointSettings objects that have been added by
            // the update. The client must use these to update its local model objects otherwise subsequent updates
            // will fail or add erroneous extra objects to the database.
            response.addedCallpointSettingsIds.forEach((i) => {
               let cp = callpoints.find(
                  (cp) => cp.callpointId == i.callpointId
               );
               cp.callpointSettings.callpointSettingsId = i.callpointSettingsId;
            });
            if (response.latLongChanged) {
               // Handle this in the ApplicationStore.
               this._callpointLocationChanged.next(response.regenerateCallerIdsDrivetime);
            }
            if (response.callerChanged) {
               // Handle this in the ApplicationStore.
               this._callpointCallerChanged.next(response.regenerateCallerIdsDrivetime);
            }
            if (response.isDisabledChanged) {
               // Handle this in the ApplicationStore.
               this._callpointDisabledChanged.next(null);
            }
            else {
               this._callpointChanged.next(null);
            }

            this._callerStore._selectedCallerMetrics.next(response.scheduleMetrics);
         },
         (error) => this._errorHandler.handleError(error)
      );
   }

   //export all callers callpoint Data send null in the export parameters for the selecied ids
   public exportAllCallpointData(
      projectId: number,
      exportParamters: ExportParameters,
      territory: string
   ) {
      this._callpointService
         .exportCallpointsData(projectId, exportParamters)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(
                  response,
                  'CallSmart Export - All Callpoints.csv'
               );
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   // export a selection of callers callpoint Data
   public exportSelectedCallpointsData(
      projectId: number,
      exportParamters: ExportParameters,
      territory: string
   ) {
      this._callpointService
         .exportCallpointsData(projectId, exportParamters)
         .subscribe(
            (response) => {
               CallsmartUtils.downloadCsvFile(
                  response,
                  'CallSmart Export - ' +
                  exportParamters.selectedItemsIds.length +
                  ' selected callpoints for ' +
                  territory +
                  '.csv'
               );
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public updateClosedDates(
      callpoint: Callpoint,
      calendarClosedDates: string[]
   ) {
      let oldCalendarClosedDates: string[] = callpoint.datesClosed;
      callpoint.datesClosed = calendarClosedDates;

      this._callpointService.updateCallpoints([callpoint]).subscribe(
         (response: any) => { },
         (error) => {
            callpoint.datesClosed = oldCalendarClosedDates;
            this._errorHandler.handleError(error);
         }
      );
   }

   public getCallpointMergeCounts(
      currentProjectId: number,
      tempProjectId: number
   ) {
      this._callpointService
         .getCallpointMergeCounts(currentProjectId, tempProjectId)
         .subscribe(
            (response) => {
               let importCounts: ImportCallpointCounts = new ImportCallpointCounts();
               importCounts.callpointsAdded = response.addedCallpointLength;
               importCounts.callpointsUpdate = response.updatedCallpointLength;
               importCounts.callpointsDeleted = response.deletedCallpointLength;
               importCounts.originalCallpointsCount =
                  response.originalCallpointsLength;

               this._callpointMergeCounts.next(importCounts);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   public getCallpointsCountForProject(projectId: number) {
      this._callpointService.getCallpointsCountForProject(projectId).subscribe(
         (count: number) => {
            this._callpointCountForProject.next(count);
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   private buildDefaultExportColumns() {
      let exportColumns = new Map<string, string>();
      this.selectedCols.forEach(c => {
         exportColumns.set(c.field, c.header);
      });
 
      this.callpointExportColumns = exportColumns;
   }

   private deleteRedundantColumns(storedColumns) {
      return storedColumns.filter((column) => column.field !== 'isDisabled');
   }
}
