<p-dialog header="Delete company" [(visible)]="display" modal="true" width="700" positionTop="200" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" style="margin-top:0px; margin-left:0px; margin-right:0px; margin-bottom:15px;">

      <div style="margin-top:5px;margin-bottom:5px;">
         Are you sure you want to permanently delete this company?
      </div>

      <div class="black-datatable no-horizontal-scrollbar" style="margin-top:20px; margin-bottom:40px;">
         <p-dataTable [value]="companies" [resizableColumns]="false" [reorderableColumns]="false" selectionMode="single" [scrollable]="false">

            <p-column field="companyName" header="Company Name" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>

            <p-column field="concurrentUsers" header="Concurrent Users" [style]="{'width':'10%', 'overflow':'visible'}">
            </p-column>

            <p-column field="licenseExpiryDate" header="Licence Expiry Date" [sortable]="true"    [style]="{'width':'10%', 'overflow':'visible'}">
               <ng-template let-row="rowData" pTemplate="body">
                  {{row.licenseExpiryDate | date:'dd/MM/y'}}
               </ng-template>
            </p-column>
         </p-dataTable>
      </div>


      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onDelete()">Delete</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>
