import { Component, OnChanges, Input } from '@angular/core';
import * as moment from 'moment';

/**
 * Component for displaying time difference between a start time and end time
 * given an adjustment parameter.
 */
@Component({
   moduleId: module.id,
   selector: 'callsmart-range-bar',
   templateUrl: 'range-bar.component.html',
   styleUrls: ['range-bar.component.less']
})
export class RangeBarComponent implements OnChanges {

   // Width of the widget bar in pixels.
   readonly barWidthConst: number = 350;

   // Left CSS offset used to align the bar in pixels.
   readonly barLeftOffsetConst: number = 10;

   // Offset used by the labels showing the time in pixels.
   readonly labelWidthOffsetConst = 15;

   // Input parameters for this component.
   @Input() fromDate: Date;
   @Input() toDate: Date;
   @Input() adjustmentValue: number;
   @Input() enableCommuteLabel: boolean;

   // Labels that will show the time on the widget.
   lowerLeftLabel: string;
   lowerRightLabel: string;
   upperLeftLabel: string;
   upperRightLabel: string;

   // Bar offset value that drives the CSS left property.
   barLeftOffset: number;

   // Bar width value that drives the CSS width property.
   barWidth: number;

   // Value that drives the CSS right property for the lower right label.
   labelRightOffset: number;

   // Value that drives the CSS left property for the lower left label.
   labelLeftOffset: number;

   // Called everytime the @Input() values change.
   ngOnChanges(): void {
      if (this.fromDate && this.toDate) {
         this.calculateLabels();
         this.calculateBar();
         this.calculateLabelOffsets();
      }
   }

   // Calculate the time value to display for all the labels
   private calculateLabels(): void {
      let from: Date = new Date(this.fromDate);
      let to: Date = new Date(this.toDate);

      this.calulateLowerLabels(from, to);
      this.calculateUpperLabels(from, to);
   }

   private calulateLowerLabels(from: Date, to: Date): void {
      this.lowerLeftLabel = from.toTimeString().substring(0, 5);
      this.lowerRightLabel = to.toTimeString().substring(0, 5);
   }

   private calculateUpperLabels(from: Date, to: Date): void {
      let dateFrom = moment(from);
      let dateTo = moment(to);

      let upperLeftDate = dateFrom.clone().subtract(this.adjustmentValue, 'minutes');
      let upperRightDate = dateTo.clone().add(this.adjustmentValue, 'minutes');
      this.upperLeftLabel = upperLeftDate.toDate().toTimeString().substring(0, 5);
      this.upperRightLabel = upperRightDate.toDate().toTimeString().substring(0, 5);
   }

   // Calculate the width and offset of the bar based on the input dates.
   private calculateBar(): void {
      let dateFrom = moment(this.fromDate);
      let dateTo = moment(this.toDate);

      let numberOfMinutes = dateTo.diff(dateFrom, 'minutes');
      let minutesPerPixel = this.barWidthConst / numberOfMinutes;

      this.barWidth = this.barWidthConst - ((this.adjustmentValue * 2) * minutesPerPixel);

      // We don't want a negative value for the bar, this will display the bar in the 
      // other direction, so reset to zero.
      if (this.barWidth < 0) {
         this.barWidth = 0;
      }

      this.barLeftOffset = ((this.barWidthConst - this.barWidth) / 2) + this.barLeftOffsetConst;
   }

   // Calculate the position of the lower labels based on the width of the bar and its offset.
   private calculateLabelOffsets(): void {
      this.labelLeftOffset = this.barLeftOffset - this.labelWidthOffsetConst;
      this.labelRightOffset = this.barWidth + this.barLeftOffset - this.labelWidthOffsetConst;
   }

}
