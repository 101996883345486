import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { environment } from 'environments/environment';
import { Project } from 'app/models/project';
import { Event } from 'app/models/diary/event';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { ProjectStatus } from 'app/models/projectStatus';
import { CopyRollProject } from 'app/models/copy-roll-project';
import { ImportOptions } from 'app/models/import-options';
import { ImportData } from 'app/models/import-data';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// this service is used purely for data acess CRUD operations to the data base
// the service is normally called from with in a store
@Injectable()
export class ProjectService {
   projectsUrl = `${environment.baseUrl}/api/Projects`;

   public constructor(private _http: HttpClient) { }

   public getAllProjects() {
      return this._http.get<Project[]>(this.projectsUrl)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let projectObjects: Project[];
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise the callers in to Typescript objects
                  try {
                     projectObjects = jsonConvert.deserialize(response, Project);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }

                  return projectObjects;
               }),
            catchError(error => observableThrowError(error)));
   }

   public getAllProjectFolders() {
      let url: string = `${this.projectsUrl}/folders`;
      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getProject(projectId: number) {
      let url: string = `${this.projectsUrl}/${projectId}`;
      return this._http.get<Project>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let projectObject: Project;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  projectObject = jsonConvert.deserialize(response, Project);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }

               return projectObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getProjectEvents(projectId: number) {
      let url: string = `${this.projectsUrl}/${projectId}/calendar`;
      return this._http.get<Event[]>(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let events: Event[];
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise the callers in to Typescript objects
                  try {
                     events = jsonConvert.deserialize(response, Event);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }
                  return events;
               }),
            catchError(error => observableThrowError(error)));
   }

   public createUpdateProjectCalendar(projectId: number, projectEvents: Event[]) {
      let url: string = `${this.projectsUrl}/${projectId}/calendar`;
      let transformedData = this.transformSettingsObject<Event[]>(projectEvents);

      return this._http.post(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }


   public deleteProject(projectId: number) {
      let url: string = `${this.projectsUrl}/${projectId}`;
      return this._http.delete(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public copyProject(projectId: number, newProjectName: string, userId: number) {
      let url: string = `${this.projectsUrl}/${projectId}?action=copy`;

      let dto = new CopyRollProject();
      dto.newName = newProjectName;
      dto.orginalProjectId = projectId;
      dto.userId = userId;

      return this._http.post(url, dto)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public rollProject(projectId: number, newProjectName: string, userId: number, rollForwardDateTime: Date) {
      let url: string = `${this.projectsUrl}/${projectId}?action=roll`;

      let dto = new CopyRollProject();
      dto.newName = newProjectName;
      dto.orginalProjectId = projectId;
      dto.userId = userId;
      dto.rollForwardDateTime = new Date(Date.UTC(rollForwardDateTime.getFullYear(), 
                                                  rollForwardDateTime.getMonth(), 
                                                  rollForwardDateTime.getDate(), 0, 0, 0));


      let transformedData = this.transformSettingsObject<CopyRollProject>(dto);

      return this._http.post(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public createProject(project: Project) {
      let url: string = `${this.projectsUrl}?createNew=${project.fromCreateNew.toString()}`;

      if (project.temporary) {
         url += '&type=temporary';
      }

      let transformedData = this.transformSettingsObject<Project>(project);

      return this._http.post<Project>(url, transformedData)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response === null) {
                  return observableThrowError('Returned project was null');
               }
               let projectObject: Project;
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the data in to Project objects
               try {
                  projectObject = jsonConvert.deserialize(response, Project);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }

               return projectObject;
            }),
            catchError(error => observableThrowError(error)));
   }

   public initialiseProject(projectId: number): Observable<any> {
      let url: string = `${this.projectsUrl}/${projectId}/initialise`;
      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));

   }

   public mergeImportedData(tempProjectId: number, importOptions: ImportOptions, projectName: string, userId: number) {
      let dto = new ImportData();
      dto.originalProjectId = importOptions.originalProjectId;
      dto.tempProjectId = tempProjectId;
      dto.importCallers = importOptions.importCallers;
      dto.importCallpoints = importOptions.importCallpoints;
      dto.userId = userId;
      dto.projectName = projectName;

      let url: string = `${this.projectsUrl}/merge-imported-data`;
      return this._http.put(url, dto)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public rollbackProjectImportChanges(originalProjectId: number, copyProjectId: number) {
      let url: string = `${this.projectsUrl}/rollback-project-update/${originalProjectId}/${copyProjectId}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getProjectStatus(projectId: number): Observable<ProjectStatus> {
      let url: string = `${this.projectsUrl}/${projectId}/status`;
      return this._http.get<ProjectStatus>(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   public updateProject(project: Project) {
      let jsonConvert: JsonConvert = new JsonConvert();
      let serializedProject = jsonConvert.serialize(project)

      let transformedData = this.transformSettingsObject<Project>(serializedProject);
      return this._http.put(this.projectsUrl, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateCallpointSettings(projectId: number, data: CallpointSettings) {
      let url: string = `${this.projectsUrl}/${projectId}/callpointsettings`;

      let jsonConvert: JsonConvert = new JsonConvert();
      let serializedData = jsonConvert.serialize(data)

      let transformedData: CallpointSettings = this.transformSettingsObject<CallpointSettings>(serializedData);
      return this._http.put(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateCallerSettings(projectId: number, data: CallerSettings) {
      let url: string = `${this.projectsUrl}/${projectId}/callersettings`;
      let transformedData = this.transformSettingsObject<CallerSettings>(data);

      return this._http.put(url, transformedData)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updateProjectSettings(projectId: number, data: ProjectSettings) {
      let url: string = `${this.projectsUrl}/${projectId}/projectsettings`;

      return this._http.put(url, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   private transformSettingsObject<T>(modelClass: T): T {
      // This is the most efficient way to deep clone an object in JavaScript
      // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      let clonedObject: T = JSON.parse(JSON.stringify(modelClass))

      CallsmartUtils.transformObjectDates(clonedObject);
      return clonedObject;
   }
}
