import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';

/**
 * Custom converter used by the json2typescript mapping library to
 * map null values to undefined. See caller-settings.ts for usage example.
 */
@JsonConverter
export class DayCombinationConverter implements JsonCustomConvert<string[]> {
   /*serialize(value: string[]): string {
      return JSON.stringify(value);
   }*/

   serialize(value: string[]): any {
      return value;
   }

   deserialize(value: any): string[] {
      if (value === null) {
         return undefined;
      } else {
         if (value instanceof Array) {
            value = JSON.stringify(value);
         }

         return JSON.parse(value);
      }
   }
}
