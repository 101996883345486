import { Component, OnInit, OnDestroy, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import { ProjectSettings } from 'app/models/settings/project-settings';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Project } from 'app/models/project';
import { ProjectSettingsViewModel } from 'app/models/view-models/project-settings-view';
import { NewFolderDialogComponent } from 'app/shared/new-folder-dialog/new-folder-dialog.component';
import { ApplicationStore } from 'app/stores/application-store';
import { Company } from 'app/models/company';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// Set up a new provider to tell angular that this component supports
// ControlValueAccessor methods and forms
const EDIT_PROJECT_SETTINGS_VALUE_ACCESSOR = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => EditProjectSettingsComponent),
   multi: true
};

@Component({
   selector: 'edit-project-settings',
   templateUrl: './edit-project-settings.component.html',
   providers: [EDIT_PROJECT_SETTINGS_VALUE_ACCESSOR]
})
export class EditProjectSettingsComponent implements OnDestroy {

   private company:Company;
   private MILES_TO_KM_FACTOR = 0.621371192;

   private _project_folders_subscription: Subscription;

   @Input() displayHeading: boolean = true;

   // This is used by the scroll pane to determine the scroll height.
   @Input() scrollHeight: number;

   @Input() disableTravelModel: boolean = false;

   @Input() displayCallCycleLength: boolean = true;

   @Input() displayProjectSpecificProperties: boolean = false;


   @ViewChild('callCycleLengthInput') callCycleLengthInput;
   @ViewChild('quickOptimiserIterationInput') quickOptimiserIterationInput;
   @ViewChild('fullOptimiserIterationInput') fullOptimiserIterationInput;
   @ViewChild('drivingCostPerUnitInput') drivingCostPerUnitInput;
   @ViewChild('averageUnitPerGallonInput') averageUnitPerGallonInput;
   @ViewChild('averageVehicleEmissionInput') averageVehicleEmissionInput;
   @ViewChild('diaryViewTimeInput') diaryViewTimeInput;

   @ViewChild('idowhShift1Input') idowhShift1Input;
   @ViewChild('idowhShift2Input') idowhShift2Input;
   @ViewChild('iterationWeightWHInput') iterationWeightWHInput;
   @ViewChild('idowhCommuteAM1Input') idowhCommuteAM1Input;
   @ViewChild('idowhCommuteAM2Input') idowhCommuteAM2Input;
   @ViewChild('iterationWeightAMInput') iterationWeightAMInput;
   @ViewChild('idowhCommutePM1Input') idowhCommutePM1Input;
   @ViewChild('idowhCommutePM2Input') idowhCommutePM2Input;
   @ViewChild('iterationWeightPMInput') iterationWeightPMInput;

   // Provides access for the parent component to access the validation status of this form.
   @ViewChild('projectSettingsForm') projectSettingsForm;

   public isCycleLengthFormatValid: boolean = true;
   public isOptimiserIterationValuesValid: boolean = true;
   public isQuickOptimiserIterationFormatValid: boolean = true;
   public isFullOptimiserIterationFormatValid: boolean = true
   public isUtilisationDefinitionValid: boolean = true;
   public isFromUtilisationValueValid: boolean = true;
   public isToUtilisationValueValid: boolean = true;
   public isDrivingCostPerUnitFormatValid: boolean = true;
   public isAverageUnitPerGallonFormatValid: boolean = true;
   public isAverageVehicleEmissionFormatValid: boolean = true;
   public isDiaryViewTimeFormatValid: boolean = true;

   public isIdowhShift1FormatValid: boolean = true;
   public isIdowhShift2FormatValid: boolean = true;
   public isIterationWeightWHFormatValid: boolean = true;
   public isIdowhCommuteAM1FormatValid: boolean = true;
   public isIdowhCommuteAM2FormatValid: boolean = true;
   public isIterationWeightAMFormatValid: boolean = true;
   public isIdowhCommutePM1FormatValid: boolean = true;
   public isIdowhCommutePM2FormatValid: boolean = true;
   public isIterationWeightPMFormatValid: boolean = true;

   private _projectSetting: ProjectSettings | ProjectSettingsViewModel;; // Model object which cached the info from the wizard.
   get projectSetting(): ProjectSettings | ProjectSettingsViewModel {
      return this._projectSetting;
   }
   set projectSetting(newProjectSetting: ProjectSettings | ProjectSettingsViewModel) {
      this._projectSetting = newProjectSetting;
      if (newProjectSetting) {
         this.subscribeToFolders();
         // console.log('is distance unit miles ' + this._projectSetting.distanceUnitMiles);
      }
   }


   public currencyList: SelectItem[];

   // The selected company by the user.
   public selectedTravelModel: any;
   public travelModelList: SelectItem[];
   public boundsMin: number = 0;
   public boundsMax: number = 100;

   // Determines whether to display the  dialog.
   public showNewFolderDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public newFolderDialog = NewFolderDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = { display: false, title: 'Create new folder' };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (folder) => this.onSaveEvent(folder),
      cancel: () => this.onCancelEvent()
   };

   // Checks whether the form is valid.
   public get formValid(): boolean {
      if (this.projectSetting) {
         return this.isCycleLengthFormatValid &&
            this.isOptimiserIterationValuesValid && this.isQuickOptimiserIterationFormatValid && this.isFullOptimiserIterationFormatValid &&
            this.isUtilisationDefinitionValid && this.isFromUtilisationValueValid && this.isToUtilisationValueValid &&
            this.isDrivingCostPerUnitFormatValid && this.isAverageUnitPerGallonFormatValid && this.isAverageVehicleEmissionFormatValid &&
            this.isDiaryViewTimeFormatValid && this.isIdowhShift1FormatValid && this.isIdowhShift2FormatValid && this.isIterationWeightWHFormatValid &&
            this.isIdowhCommuteAM1FormatValid && this.isIdowhCommuteAM2FormatValid && this.isIterationWeightAMFormatValid &&
            this.isIdowhCommutePM1FormatValid && this.isIdowhCommutePM2FormatValid && this.isIterationWeightPMFormatValid;
      }
      return false;
   }

   public projectFoldersList: SelectItem[];
   public selectedFolder: string;

   // Handler methods for ControlValueAccessor
   private onTouched = () => { };
   private onChange: (value: any[]) => {};

   // Event raised when the state of the Form changes.
   private _formStateChanged = new Subject<boolean>();
   private _user_company_subscription: Subscription;
   public formState$ = this._formStateChanged.asObservable();

   constructor(private _applicationStore: ApplicationStore) {
      // Initialise currency list
      this.currencyList = [];
      this.currencyList.push({ label: '&pound; - GBP', value: 'gbp' });
      this.currencyList.push({ label: '&euro; - Euro', value: 'euro' });
      this.currencyList.push({ label: '&#36; - USD', value: 'usd' });
      this.currencyList.push({ label: '&yen; - Yen', value: 'yen' });

      this._user_company_subscription = this._applicationStore.authenticationStore.userCompany$.subscribe(
         (company: Company) => {
            this.configureTravelModels(company);
         }
      );

   }

   public ngOnDestroy() {

      if (this._project_folders_subscription) {
         this._project_folders_subscription.unsubscribe();
      }

      if (this._user_company_subscription) {
         this._user_company_subscription.unsubscribe();
      }

   }

   // Callback method to sync the form values with this model.
   writeValue(data: any): void {
      this.projectSetting = data;

      // sync the combos
      this.syncCombos();
   }

   // Registers a callback method to invoke when a change event happens.
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   // Registers a callback method to invoke when a touch event happens.
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   public updateDrivingCostPerUnit(value) {
      if (value && this.isDrivingCostPerUnitFormatValid) {
         // cost is always stored in relation to KM
         this.projectSetting.drivingCostPerUnit = ((this.projectSetting.distanceUnitMiles) ? value * this.MILES_TO_KM_FACTOR : value);
      }
   }

   public createFolder() {
      this.showNewFolderDialog = true;
      this.dialogInput.display = true;
   }

   // cancel button from the add\edit event dialog
   public onCancelEvent() {

      this.showNewFolderDialog = false;
      this.dialogInput.display = false;
   }

   // save button from the change password dialog
   public onSaveEvent(folder: string) {

      // save the new folder name
      (this.projectSetting as ProjectSettingsViewModel).folder = folder;

      // proactively add it to the list of folder names
      this.addNewFolderToComboData(folder)

      // select new folder
      this.selectedFolder = folder;

      this.showNewFolderDialog = false;
      this.dialogInput.display = false;

      this.projectSettingsForm.form.markAsDirty();
   }

   public folderChanged() {
      (this.projectSetting as ProjectSettingsViewModel).folder = this.selectedFolder;
      this.projectSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);

   }

   public onTravelModelChanged() {
      let travelModel = this.company.travelModels.find(t=>t.travelModelId ==this.selectedTravelModel.id )

      this.projectSetting.travelModelId = travelModel.travelModelId;
      this.projectSetting.travelModel = travelModel;

      this._formStateChanged.next(this.formValid);
   }

   public onCallCycleLengthInputValueChanged(event: any) {
      this.isCycleLengthFormatValid = !this.callCycleLengthInput.errors ? true : false;
      this._formStateChanged.next(this.formValid);
   }

   public onQuickOptimiserIterationInputValueChanged(event: any) {
      if (!this.quickOptimiserIterationInput.errors) {
         this.validateOptimaserIterationValues();
         this.isQuickOptimiserIterationFormatValid = true;
      }
      else {
         this.isQuickOptimiserIterationFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onFullOptimiserIterationInputValueChanged(event: any) {
      if (!this.fullOptimiserIterationInput.errors) {
         this.validateOptimaserIterationValues();
         this.isFullOptimiserIterationFormatValid = true;
      }
      else {
         this.isFullOptimiserIterationFormatValid = false;
      }
      this._formStateChanged.next(this.formValid);
   }

   public onCheckUtilisationDefinition(event: any) {
      this.isUtilisationDefinitionValid = this.projectSetting.utilisationDefinition[0] < this.projectSetting.utilisationDefinition[1];
      this.isFromUtilisationValueValid = this.projectSetting.utilisationDefinition[0] >= 0 && this.projectSetting.utilisationDefinition[0] < 150;
      this.isToUtilisationValueValid = this.projectSetting.utilisationDefinition[1] >= 2 && this.projectSetting.utilisationDefinition[1] <= 150;
      this._formStateChanged.next(this.formValid);
   }

   public onDrivingCostPerUnitInputValueChanged(event: any) {
      this.isDrivingCostPerUnitFormatValid = !this.drivingCostPerUnitInput.errors;
      this._formStateChanged.next(this.formValid);
   }

   public onAverageUnitPerGallonInputValueChanged(event: any) {
      this.isAverageUnitPerGallonFormatValid = !this.averageUnitPerGallonInput.errors;
      this._formStateChanged.next(this.formValid);
   }

   public onAverageVehicleEmissionInputValueChanged(event: any) {
      this.isAverageVehicleEmissionFormatValid = !this.averageVehicleEmissionInput.errors;
      this._formStateChanged.next(this.formValid);
   }

   public onDiaryViewTimeInputValueChanged(event: any) {
      this.isDiaryViewTimeFormatValid = !this.diaryViewTimeInput.errors;
      this._formStateChanged.next(this.formValid);

   }

   public onCurrencyUnitInputChanged() {
      this.projectSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);
   }

   public onDistanceUnitInputSwitchChanged(event: any) {
      this.projectSettingsForm.form.markAsDirty();
      this._formStateChanged.next(this.formValid);

      this.projectSetting.drivingCostPerUnit = CallsmartUtils.distanceConvert(this.drivingCostPerUnitInput.value, this.projectSetting.distanceUnitMiles);
      this.projectSetting.averageUnitPerGallon = CallsmartUtils.distanceConvert(this.averageUnitPerGallonInput.value, this.projectSetting.distanceUnitMiles);
   }

   public onMinTotalDrivingClicked() {
      this.projectSettingsForm.form.markAsDirty();
      this.projectSetting.minTotalDrivingSelected = true;
      this.projectSetting.minDrivingBetweenVisitsSelected = false;
      this.projectSetting.optimiserDrivingConfigCustomSelected = false;

      this.projectSetting.idowhShift1 = 0;
      this.projectSetting.idowhShift2 = 2;
      this.projectSetting.idowhCommuteAM1 = 0;
      this.projectSetting.idowhCommuteAM2 = 1;
      this.projectSetting.idowhCommutePM1 = 0;
      this.projectSetting.idowhCommutePM2 = 1;
      this.projectSetting.iterationWeightWH = 1;
      this.projectSetting.iterationWeightAM = 1;
      this.projectSetting.iterationWeightPM = 1;


      this._formStateChanged.next(this.formValid);
   }

   public onMinDrivingBetweenVisitsClicked() {
      this.projectSettingsForm.form.markAsDirty();
      this.projectSetting.minTotalDrivingSelected = false;
      this.projectSetting.minDrivingBetweenVisitsSelected = true;
      this.projectSetting.optimiserDrivingConfigCustomSelected = false;

      this.projectSetting.idowhShift1 = 2;
      this.projectSetting.idowhShift2 = 4;
      this.projectSetting.idowhCommuteAM1 = 0;
      this.projectSetting.idowhCommuteAM2 = 1;
      this.projectSetting.idowhCommutePM1 = 0;
      this.projectSetting.idowhCommutePM2 = 1;
      this.projectSetting.iterationWeightWH = 1;
      this.projectSetting.iterationWeightAM = 0;
      this.projectSetting.iterationWeightPM = 0;

      this._formStateChanged.next(this.formValid);
   }

   public onoptimiserDrivingConfigCustomClicked() {
      this.projectSettingsForm.form.markAsDirty();
      this.projectSetting.minTotalDrivingSelected = false;
      this.projectSetting.minDrivingBetweenVisitsSelected = false;
      this.projectSetting.optimiserDrivingConfigCustomSelected = true;

      this._formStateChanged.next(this.formValid);
   }

   public onIdowShift1InputChanged(event: any) {
      this.isIdowhShift1FormatValid = !this.idowhShift1Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIdowShift2InputChanged(event: any) {
      this.isIdowhShift2FormatValid = !this.idowhShift2Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIterationWeightWHInputChanged(event: any) {
      this.isIterationWeightWHFormatValid = !this.iterationWeightWHInput.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIdowhCommuteAM1InputChanged(event: any) {
      this.isIdowhCommuteAM1FormatValid = !this.idowhCommuteAM1Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIdowhCommuteAM2InputChanged(event: any) {
      this.isIdowhCommuteAM2FormatValid = !this.idowhCommuteAM2Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIterationWeightAMInputChanged(event: any) {
      this.isIterationWeightAMFormatValid = !this.iterationWeightAMInput.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIdowhCommutePM1InputChanged(event: any) {
      this.isIdowhCommutePM1FormatValid = !this.idowhCommutePM1Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIdowhCommutePM2InputChanged(event: any) {
      this.isIdowhCommutePM2FormatValid = !this.idowhCommutePM2Input.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   public onIterationWeightPMInputChanged(event: any) {
      this.isIterationWeightPMFormatValid = !this.iterationWeightPMInput.errors;
      this.onoptimiserDrivingConfigCustomClicked();
   }

   // take the project setttings and set the combos values
   private syncCombos() {

      // travel model
      if (this.projectSetting && this.projectSetting.travelModel) {
         this.selectedTravelModel = { id: this.projectSetting.travelModel.travelModelId, name: this.projectSetting.travelModel.travelModelName };
      }

      // currency later when wired in
   }

   private validateOptimaserIterationValues() {
      this.isOptimiserIterationValuesValid = this.projectSetting.quickOptimiserIteration < this.projectSetting.fullOptimiserIteration;
   }

   private addNewFolderToComboData(folder: any) {
      // check if exists
      let match = this.projectFoldersList.find(f => f.label == folder);

      if (match == null || match == undefined) {
         this.projectFoldersList.push({ label: folder, value: folder })
      }
   }

   private buildFolderComboData(folders: ReadonlyArray<string>) {

      let userFolderName = this._applicationStore.authenticationStore.loggedInUser.fullname + "'s Schedules";
      let defaultFolderExists = false;
      let userDefaultFolder = { label: userFolderName, value: userFolderName }

      if (folders.length > 0) {

         //clear list
         this.projectFoldersList = [];

         // build list
         folders.forEach(f => {
            if (f == userFolderName) {
               defaultFolderExists = true;
            }

            this.projectFoldersList.push({ label: f, value: f })
         });

         // check if user default being used
         if (!defaultFolderExists) {
            this.projectFoldersList.push(userDefaultFolder)
         }

         this.selectedFolder = (this.projectSetting as ProjectSettingsViewModel).folder;

      }
   }

   private subscribeToFolders() {
      this._project_folders_subscription = this._applicationStore.projectsStore.projectFolders$.subscribe(
         (folders: string[]) => {
            if (folders) {
               this.buildFolderComboData(folders);
            }
         }
      );
   }


   private configureTravelModels(company: Company) {
      //Initialise travel file dropdown list
      this.travelModelList = [];
      this.company = company;
      if (company) {
         if (company.travelModels) {
            company.travelModels.forEach(t => {
               this.travelModelList.push({ label: t.travelModelName, value: { id: t.travelModelId, name: t.travelModelName } });
            });
            this.travelModelList.sort((a, b) => a.label.localeCompare(b.label));
         }
      }
   }

}
