import { forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Component, OnDestroy } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// Set up a new provider to tell angular that this component supports
// ControlValueAccessor methods and forms
const CLOCK_TIME_PICKER_VALUE_ACCESSOR = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => ClockTimePickerComponent),
   multi: true
};

// Declare jQuery reference variable here since Typescript doesn't know about the
// JQuery object and will throw compiler errors.
declare var jQuery: any;
declare var $: any;

/**
 * Angular wrapper for JQuery clock  plugin.
 * Supports two way data binding and forms.
 * http://weareoutman.github.io/clockpicker/?utm_source=jquer.in&utm_medium=website&utm_campaign=content-curation
 */

@Component({
   moduleId: module.id,
   selector: 'callsmart-clock-time-picker',
   template: `<div #csClockTimePicker class="input-group clockpicker" style="width:99px">
  <input #inputfield type="text" class="form-control"  (blur)="onInputBlur($event)" (input)="onUserInput($event)"
                     [required]="required" [pattern]="pattern" [disabled]="disabled" placeholder="hh:mm" style="width:60px"
                     [ngStyle]="{'opacity' : disabled ? '0.5':'1'}">
  <span class="input-group-addon" [ngStyle]="{'pointer-events' : disabled ?  'none' : 'auto', 'opacity' : disabled ? '0.5':'1'}">
      <span class="glyphicon glyphicon-time"></span>
  </span>
</div>`,
   providers: [CLOCK_TIME_PICKER_VALUE_ACCESSOR]
})
export class ClockTimePickerComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {

   private today = new Date();
   private onTouched = () => { };
   private onChange: (value: any[]) => {};

   @Output() onInput: EventEmitter<any> = new EventEmitter();
   @Output() onBlur: EventEmitter<any> = new EventEmitter();


   // Default values for inputs
   @Input() time: string = this.today.getHours() + ":" + this.today.getMinutes()
   @Input() required: boolean = false;
   @Input() pattern: string;
   @Input() disabled: boolean=false;
   @Input() textBoxWidth: string;


   // Reference to the DOM node that contains the jquery range slider.
   // The ViewChild attribute maps the '#csClockTimePicker' element from the template to this property.
   // The ElementRef type is a DOM node wrapper from Angular.
   @ViewChild('csClockTimePicker') clockPicker: ElementRef;

   @ViewChild('inputfield') inputfieldViewChild: ElementRef;

   // Angular lifecycle method called after the DOM has been initialised.
   // Creates the range slider using JQuery and intialises it with constructor
   // arguments.
   ngAfterViewInit() {
      jQuery(this.clockPicker.nativeElement).clockpicker({
         placement: 'right',
         align: 'left',
         donetext: 'Done',
         afterDone: () => {
            this.onChange(this.inputfieldViewChild.nativeElement.value);
            this.onTouched();
            this.onInput.emit(this.inputfieldViewChild.nativeElement.value);
         }


      });
   }

   // Callback method to sync model values with the JQuery clock.
   writeValue(value: any): void {

      if (value) {
         this.time = value;
      } else {
         this.time = this.today.getHours() + ":" + this.today.getMinutes()
      }

      this.inputfieldViewChild.nativeElement.value = this.time;


   }

   // Callback method to tell Angular that component value has changed.
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }


   onUserInput(event) {

      this.onChange(this.inputfieldViewChild.nativeElement.value);
      this.onInput.emit(event);
   }

   onInputBlur(event) {

      this.onChange(this.inputfieldViewChild.nativeElement.value);
      this.onBlur.emit(event);
   }


   // Clean up JQuery resources when this component is removed from DOM.
   ngOnDestroy(): void {
      jQuery(this.clockPicker.nativeElement).clockpicker('destroy');
      jQuery(this.clockPicker.nativeElement).clockpicker = null;

      // the jquery file does not destroy the pop over with the clock
      jQuery( "div" ).remove(".popover")
      this.clockPicker = null;
   }
}




