import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Maintenance } from 'app/models/maintenance';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'callsmart-admin-maintenance-panel',
  templateUrl: './admin-maintenance-panel.component.html'
})
export class AdminMaintenancePanelComponent implements OnInit, OnDestroy {
  
  public workspaceHeight: number;
  public gridTitle: string = 'Maintenance';
  public maintenance: Maintenance;
  private _maintenance_subscription: Subscription;
  public inMaintenance: boolean = false;

  public constructor(private windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
     //subscribe to the window resize event
     windowService.height$.subscribe((value: number) => {
      this.resizeWorkspace(value);
   });
 }

  ngOnInit() {
    this.subscribeToMaintenance();
    this._applicationStore.sysAdminStore.getMaintenance();
  }

  ngOnDestroy(): void {
    if (this._maintenance_subscription) {
      this._maintenance_subscription.unsubscribe();
   }
  }

  public onSave(form: NgForm) {
    this.maintenance.WebServiceInMaintenance = !form.value.webServiceOverrideSwitch;
    this.maintenance.DatabaseInMaintenance = !form.value.databaseOverrideSwitch;
    
    this._applicationStore.sysAdminStore.updateMaintenance(this.maintenance);
    
  }
  
  private subscribeToMaintenance() {
    this._maintenance_subscription = this._applicationStore.sysAdminStore.maintenance$.subscribe(
       (maintenance: Maintenance) => {
          this.maintenance = maintenance;
          this.inMaintenance = this.maintenance.WebServiceInMaintenance || this.maintenance.DatabaseInMaintenance;
       }
    );
 }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
    this.workspaceHeight = browserHeight - 200;
 }

}
