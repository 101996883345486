<div class="row top-content-border" [ngStyle]="{'height.px': workspaceHeight}" style="margin-left:30px;margin-right:30px ;">
   <div class="black-workspace col-md-12" style="padding: 10px">
      <callsmart-travel-model-list class="black-datatable no-horizontal-scrollbar top-content-border"
      (createTravelModel)="onCreateTravelModel()"
      [scrollHeight]="scrollHeight"></callsmart-travel-model-list>
   </div>
</div>

<div class="row">

   <hr style="margin-left:30px;margin-right:30px ;">
   <div style="float:right; margin-left:30px;margin-right:30px ; ">
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="onDeleteTravelModel()"  [disabled]="travelModelList.selectedTravelModel == null">
            <i class="cs-icon icon-trash" style="font-size: 15px"></i> Delete</button>

      <button type="button" class="btn cs-btn-primary-inverse" (click)="onEditTravelModel()" [disabled]="travelModelList.selectedTravelModel == null" >Edit</button>
   </div>
</div>


<ndc-dynamic *ngIf="showDeleteTravelModelDialog" [ndcDynamicComponent]="deleteTravelModelDialog" [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showAddEditTravelModelDialog" [ndcDynamicComponent]="addEditTravelModelDialog" [ndcDynamicInputs]="addEditDialogInput"
   [ndcDynamicOutputs]="addEditDialogOutput">
</ndc-dynamic>

