import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

/** 
 * Password reset dialog component.
*/
@Component({
   selector: 'password-reset-dialog',
   templateUrl: 'password-reset-dialog.component.html'
})
export class PasswordResetDialogComponent {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with send new password button.
   @Output() sendNewPassword = new EventEmitter<string>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Determine whether to display the error message.
   public showError: boolean = false;

   // Determine whether to display the success message.
   public showSuccess: boolean = false;

   // Determine whether to disable the submit button.
   public disableButton: boolean = false;

   constructor(private _applicationStore: ApplicationStore) { }

   // Called when the user clicks on the send new password button.
   public onResetPassword(form: NgForm) {
      this.showError = false;
      
      if (CallsmartUtils.validateEmail(form.value.email)) {
         this.sendNewPassword.next(form.value.email);
         this.showSuccess = true;
         this.disableButton = true;
      }
      else {
         this.showError = true;
         this.showSuccess = false;
         this.disableButton = false;
      }
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.showSuccess = false;
      this.disableButton = false;
      this.cancel.next();
   }
}
