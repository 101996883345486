import { Component, forwardRef, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { InputSwitch, DomHandler } from 'primeng/primeng';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const INPUTSWITCH_VALUE_ACCESSOR: any = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => CsInputSwitchComponent),
   multi: true
};

/**
 * Taken from PrimeNG InputSwitch and modified to match the style guide.
 */
@Component({
   moduleId: module.id,
   selector: 'callsmart-inputswitch',
   templateUrl: 'input-switch.component.html',
   styleUrls: ['input-switch.component.less'],
   providers: [INPUTSWITCH_VALUE_ACCESSOR, DomHandler]
})
export class CsInputSwitchComponent implements ControlValueAccessor, AfterViewInit, AfterViewChecked {

   @Input() onLabel: string = 'On';

   @Input() offLabel: string = 'Off';

   @Input() disabled: boolean;

   @Input() style: any;

   @Input() styleClass: string;

   @Input() tabindex: number;

   @Input() inputId: string;

   @Input() inlineLabel: boolean = false;

   @Input() inverseColours: boolean = false;

   @Input() ariaLabelTemplate: string = "InputSwitch {0}";

   @Output() onChange: EventEmitter<any> = new EventEmitter();

   checked: boolean = false;

   focused: boolean = false;

   onModelChange: Function = () => { };

   onModelTouched: Function = () => { };

   public container: any;

   public handle: any;

   public onContainer: any;

   public offContainer: any;

   public onLabelChild: any;

   public offLabelChild: any;

   public offset: any;

   public ariaLabel: string;

   public ariaLabelledBy: string;

   public slidingBar: any;

   private slidingBarMiddle: number;

   initialized: boolean = false;

   constructor(public el: ElementRef, public domHandler: DomHandler) { }

   ngAfterViewInit() {
      this.container = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-inputswitch');
      this.handle = this.domHandler.findSingle(this.container, 'div.ui-inputswitch-handle');
      this.onContainer = this.domHandler.findSingle(this.container, 'div.ui-inputswitch-on');
      this.offContainer = this.domHandler.findSingle(this.container, 'div.ui-inputswitch-off');
      this.onLabelChild = this.domHandler.findSingle(this.onContainer, 'span.ui-inputswitch-onlabel');
      this.offLabelChild = this.domHandler.findSingle(this.offContainer, 'span.ui-inputswitch-offlabel');
      this.slidingBar = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-inputswitch-bar');
   }

   ngAfterViewChecked() {
      if (this.container && this.container.offsetParent && !this.initialized) {
         this.render();
      }
   }

   render() {
       let onContainerWidth = this.domHandler.width(this.onContainer),
          offContainerWidth = this.domHandler.width(this.offContainer);

       this.slidingBarMiddle = this.domHandler.width(this.slidingBar) / 2;

       var containerWidth = onContainerWidth + offContainerWidth + this.domHandler.width(this.slidingBar);

      this.container.style.width = containerWidth + 'px';
      this.onLabelChild.style.width = onContainerWidth + 'px';
      this.offLabelChild.style.width = offContainerWidth + 'px';

      //position      
      this.offset = onContainerWidth + offContainerWidth;

      //default value
      if (this.checked) {         
         this.slidingBar.style.left = this.offset + 'px';
      }
      else {
         this.slidingBar.style.left = 0 + 'px';         
      }
      this.initialized = true;
   }

   toggle(event, checkbox) {
      if (!this.disabled) {
         if (this.checked) {
            this.checked = false;
            this.checkUI();
         }
         else {
            this.checked = true;
            this.uncheckUI();
         }

         this.onModelChange(this.checked);
         this.onChange.emit({
            originalEvent: event,
            checked: this.checked
         });
         checkbox.focus();
      }
   }

   checkUI() {      
      this.slidingBar.style.left = this.offset + 'px';
      this.updateAriaLabel();
      
      let container = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-inputswitch')
      container.style.borderRight = "0px solid transparent";    
      container.style.borderLeft = "1px solid #d1d1d1";     
   }

   uncheckUI() {
      this.slidingBar.style.left = 0 + 'px';
      this.updateAriaLabel();      
      let container = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-inputswitch')
      container.style.borderLeft = "0px solid transparent";      
      container.style.borderRight = "1px solid #d1d1d1";      
   }

   onFocus(event) {
      this.focused = true;
   }

   onBlur(event) {
      this.focused = false;
      this.onModelTouched();
   }

   writeValue(checked: any): void {
      this.checked = checked;

      if (this.initialized) {
         if (this.checked === true)
            this.uncheckUI();
         else
            this.checkUI();
      }
   }

   registerOnChange(fn: Function): void {
      this.onModelChange = fn;
   }

   registerOnTouched(fn: Function): void {
      this.onModelTouched = fn;
   }

   setDisabledState(val: boolean): void {
      this.disabled = val;
   }

   updateAriaLabel() {
      let pattern = /{(.*?)}/,
         value = this.checked ? this.onLabel : this.offLabel;

      this.ariaLabel = this.ariaLabelTemplate.replace(this.ariaLabelTemplate.match(pattern)[0], value);
   }
}
