import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Author {
   @JsonProperty('username')
   username: string = undefined;

   @JsonProperty('fullname')
   fullname: string = undefined;
}
