import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';

/**
 * Custom converter used by the json2typescript mapping library to 
 */

@JsonConverter
export class ClosedDatesArrayConverter implements JsonCustomConvert<string[]> {
   serialize(value: string[]): string {
      return value.join('');
      //return JSON.stringify(value);
   }
   deserialize(value: any): string[] {
      if (value instanceof Array) {
         return value;
      }
      else {
         return value.split('');
         
      }
   }
}
