 <div class="row top-content-border" >
    <h3 style="margin-left:30px;margin-right:30px ;">Companies</h3> 
    <div [ngStyle]="{'height.px': scrollHeight}">
        <div>
            <div class="panel-body">
                <div class="col-sm-3">
                    <callsmart-dropdown
                        name="companyList"
                        [options]="companyList"
                        [(ngModel)]="selectedCompany"
                        [style]="{ width: '100%' }"
                        (onChange)="companyChanged()">
                    </callsmart-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" style="margin-left:30px;margin-right:30px ;">
    <div class="black-workspace col-md-12" style="padding: 10px">
       <callsmart-admin-project-list class="black-datatable no-horizontal-scrollbar">
       </callsmart-admin-project-list>
    </div>
</div>

<div class="row">
    <hr style="margin-left:30px;margin-right:30px ;">
    <div style="float:right; margin-left:30px;margin-right:30px ; ">
       <button type="button" 
               class="btn cs-btn-primary-inverse"
               (click)="onCloneProject()"
               [disabled]="projectList.selectedProjects == null">
            Clone
        </button>
    </div>
</div>

<ndc-dynamic *ngIf="showCloneProjectDialog" 
            [ndcDynamicComponent]="cloneProjectDialog" 
            [ndcDynamicInputs]="cloneDialogInput"
            [ndcDynamicOutputs]="cloneDialogOutput">
</ndc-dynamic>



