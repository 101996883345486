import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { Notification } from "app/models/notification";
import { BrowserWindowService } from 'app/services/browser-window.service';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';

@Component({
   selector: 'callsmart-notification-workspace',
   templateUrl: './notification-workspace.component.html'
})
export class NotificationWorkspaceComponent implements OnInit, OnDestroy {

   private _notifications_subscription: Subscription;

   public workspaceHeight: number;
   public notifications:ReadonlyArray<Notification>;

   constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });

   }

   ngOnInit() {
      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Notifications);
      this.subscribeToNotifications();
   }

   ngOnDestroy() {
      if(this._notifications_subscription){
         this._notifications_subscription.unsubscribe();
      }
   }


   private subscribeToNotifications() {
      // Get the callers list from the callers service.
      this._notifications_subscription = this._applicationStore.notificationsStore.notifications$.subscribe(
         (notifications: ReadonlyArray<Notification>) => {

            // order the notifications decending this is slow
           /* this.notifications = this._applicationStore.notificationsStore.notificationHistory.sort(function(a, b) {
               return a.createDate >b.createDate ? -1 : a.createDate < b.createDate ? 1 : 0;
              })*/

           // try using the reverse function might be quicker
           this.notifications = this._applicationStore.notificationsStore.notificationHistory.reverse();

           //this.notifications = this._applicationStore.notificationsStore.notificationHistory;
         }
      );
   }


   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 74.5 ;
   }

}
