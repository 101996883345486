import {map} from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { Http, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';


// Accesses the DbAdmin app server api.
@Injectable()
export class DbAdminModel implements OnInit {

   constructor(private _http: Http) { }

   public ngOnInit() {
   }

   public DbTables: string[];

   public test(): Observable<string> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/test';
      let response: Observable<Response> = this._http.get(endpoint);
      let result: Observable<string> = response.pipe(map((r: Response) => { return r.text(); }));
      return result;
   }

   public initDb(): Observable<Response> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/builddb';
      let response: Observable<Response> = this._http.get(endpoint);
      return response;
   }

   public resetDb(): Observable<Response> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/resetdb';
      let response: Observable<Response> = this._http.get(endpoint);
      return response;
   }

   public populateDb(): Observable<Response> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/populatedb';
      let response: Observable<Response> = this._http.get(endpoint);
      return response;
   }

   public serverStatusReport(): Observable<any> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/Status';
      let response: Observable<Response> = this._http.get(endpoint);
      let result: Observable<any> = response.pipe(map((r: Response) => { return r.json(); }));
      return result;
   }

   public dbStatusReport(): Observable<Object[]> {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/DbStatusReport';
      let response: Observable<Response> = this._http.get(endpoint);
      let result: Observable<Object[]> = response.pipe(map((r: Response) => { return r.json(); }));
      return result;
   }

   public test404Error(): void {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/test404';
      let response: Observable<Response> = this._http.get(endpoint);
      response.subscribe();
   }

   public testGenerateMultipleSchedules(): void {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/TestGenerateMultipleSchedules';
      let response: Observable<Response> = this._http.get(endpoint);
      response.subscribe();
   }

   public testException(): void {
      let endpoint: string = environment.baseUrl + 'api/dbadmin/ThrowTestException';
      let response: Observable<Response> = this._http.get(endpoint);
      response.subscribe();
   }
}
