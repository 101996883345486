import { Component, OnInit} from '@angular/core';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceNavigationLogic } from 'app/shared/optimisation-logic/workspace-navigation-logic';


@Component({
   selector: 'callsmart-dashboard-workspace',
   templateUrl: './dashboard-workspace.component.html'
})
export class DashboardWorkspaceComponent implements OnInit {

   public workspaceHeight: number;

   constructor(private windowService: BrowserWindowService, private _applicationStore:ApplicationStore,
      private _workspaceNavigationLogic : WorkspaceNavigationLogic) {

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.workspaceHeight = value - 55;
      });

   }

   ngOnInit(): void {
      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Dashboard);
      this._workspaceNavigationLogic.navigatingToWorkspace(WorkspaceViewType.Dashboard);
   }

}
