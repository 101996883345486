import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Subscription } from 'rxjs';

import { CallerSettings } from 'app/models/settings/caller-settings';
import { EditProjectCallerSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-caller-settings-dialog/edit-project-caller-settings-dialog.component';
import { ApplicationStore } from 'app/stores/application-store';
import { Project } from 'app/models/project';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

/**
 * Caller settings component for the settings shown in the Create New wizard.
 * Also allows the settings to be edited.
 */
@Component({
   selector: 'callsmart-new-project-caller-settings',
   templateUrl: 'new-project-caller-settings.component.html'
})
export class NewProjectCallerSettingsComponent implements OnInit, OnDestroy {
   // Height of the component used by the scrollbar.
   @Input() componentHeight: number;
   
   // Notifies the client when caller settings have been updated.
   @Output() callerSettingsUpdated = new EventEmitter<CallerSettings>();

   // Caller settings model object.
   public callerSettings: CallerSettings

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public editCallerSettingsDialog = EditProjectCallerSettingsDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      callerSettingsModel: null,
      projectCycleLength: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: (settings: CallerSettings) => this.onSaveEdit(settings),
      cancel: () => this.onCancelEdit()
   };

   // Determine whether to display the edit dialog.
   public showSettings: boolean = false;

   // Subscribe to the project created event.
   private _project_created_subscription: Subscription;

   // The new project that needs to be updated with these settings.
   private _project: Project;
   
   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      this.subscribeToProjectCreated();
   }

   ngOnDestroy() {
      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }
   }

   // Clone the caller settings for the dialog and show it.
   public editSettings() {
      
      let settings = CallsmartUtils.deepClone<CallerSettings>(this.callerSettings, CallerSettings);

      this.dialogInput.callerSettingsModel = settings;
      this.dialogInput.projectCycleLength = this._project.projectSettings.callCycleLength;
      this.showSettings = true;
      this.dialogInput.display = true;
   }

   // Set the caller setting dialog to be hidden and assign the changed caller settings
   // to the caller settings model and save to backend.
   public onSaveEdit(savedSettings: CallerSettings): void {
      this.showSettings = false;
      this.dialogInput.display = false;
      this.callerSettings = savedSettings;
      this._project.callerSettings = savedSettings;
      this._applicationStore.projectsStore.updateCreateNewProjectCallerSettings(this._project.projectId, savedSettings);

      // Update the NewProjectWizard the caller settings so they can be displayed on summary page.
      this.callerSettingsUpdated.next(savedSettings);
   }

   // Set the caller setting dialog to be hidden.
   public onCancelEdit(): void {
      this.showSettings = false;
      this.dialogInput.display = false;
   }

   // Display the working days as abbreviated day names.
   public get workingDayText(): string {
      let workingDayText: string  = '';
      if(this.callerSettings) {
         if(this.callerSettings.workingDayActive[0]) {
            workingDayText += 'Mon';
         }
         if(this.callerSettings.workingDayActive[1]) {
            workingDayText += ' Tue';
         }
         if(this.callerSettings.workingDayActive[2]) {
            workingDayText += ' Wed';
         }
         if(this.callerSettings.workingDayActive[3]) {
            workingDayText += ' Thu';
         }
         if(this.callerSettings.workingDayActive[4]) {
            workingDayText += ' Fri';
         }
         if(this.callerSettings.workingDayActive[5]) {
            workingDayText += ' Sat';
         }
         if(this.callerSettings.workingDayActive[6]) {
            workingDayText += ' Sun';
         }
      }
      return workingDayText;
   }

   // Angular 4.x and IE 11 do not show the date in the correct format when using the DatePipe from Angular.
   // This has been fixed in Angular 5 but for now using moment library to do the formatting in code to get the
   // desired result.
   public getHoursAndMinutes(date: Date): string {
      return CallsmartUtils.formatDate(date, 'HH:mm');
   }

   private subscribeToProjectCreated() {
      this._project_created_subscription = this._applicationStore.projectsStore.ProjectCreated.subscribe(
         (project: Project) => {
            if (project) {
               this._project = project;
               this.callerSettings = project.callerSettings;
            }
         }
      );
   }
}
