import { JsonObject, JsonProperty, Any } from 'json2typescript';

import { NullConverter } from 'app/shared/json-converters/null-converter';
import { DateArrayConverter } from 'app/shared/json-converters/date-array-converter';

/**
 * Caller settings view model class. The json returned by the server has null values
 * for any properties that don't have a value specified in the database. The NullConverter is used to convert
 * any null values to undefined, since the CallerSettingsViewModel expects
 * the properties to be undefined if they are not overriden.
 */
@JsonObject
export class CallerSettings {

   @JsonProperty('callerSettingsId', NullConverter, true)
   public callerSettingsId: number = undefined;

   @JsonProperty('projectId', NullConverter, true)
   public projectId: number = undefined;

   @JsonProperty('callerId', NullConverter, true)
   public callerId: number = undefined;

   // Contracted working days and hours -----------------------------------
   @JsonProperty('workingDayActive', NullConverter, true)
   public workingDayActive: boolean[] = undefined;

   @JsonProperty('sameWorkingHoursAllDays', NullConverter, true)
   public sameWorkingHoursAllDays: boolean = undefined;

   @JsonProperty('contractedWorkingHoursWeek', DateArrayConverter, true)
   public contractedWorkingHoursWeek: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursMonday', DateArrayConverter, true)
   public contractedWorkingHoursMonday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursTuesday', DateArrayConverter, true)
   public contractedWorkingHoursTuesday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursWednesday', DateArrayConverter, true)
   public contractedWorkingHoursWednesday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursThursday', DateArrayConverter, true)
   public contractedWorkingHoursThursday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursFriday', DateArrayConverter, true)
   public contractedWorkingHoursFriday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursSaturday', DateArrayConverter, true)
   public contractedWorkingHoursSaturday: Date[] = undefined;

   @JsonProperty('contractedWorkingHoursSunday', DateArrayConverter, true)
   public contractedWorkingHoursSunday: Date[] = undefined;

   //Working hours flexibility ---------------------------------------------
   @JsonProperty('workingHoursFlexibility', NullConverter, true)
   public workingHoursFlexibility: number = undefined;

   // Lunch window --------------------------------------------------------
   @JsonProperty('lunchPeriod', DateArrayConverter, true)
   public lunchPeriod: Date[] = undefined;

   @JsonProperty('floatingLunch', NullConverter, true)
   public floatingLunch: boolean = undefined;

   @JsonProperty('lunchDuration', NullConverter, true)
   public lunchDuration: number = undefined;

   // Maximum one-way commute time ---------------------------------------
   @JsonProperty('noMaximum', NullConverter, true)
   public noMaximum: boolean = undefined;

   @JsonProperty('maxOneWayCommuteTime', NullConverter, true)
   public maxOneWayCommuteTime: number = undefined;

   // Maximum visits allowed per day ---------------------------------------------
   @JsonProperty('maxVisitsPerDay', NullConverter, true)
   public maxVisitsPerDay: number = undefined ;

   @JsonProperty('visitsPerDayNoMaximum', NullConverter, true)
   public visitsPerDayNoMaximum: boolean = undefined;

   // Commute during working hours only ---------------------------------
   @JsonProperty('commuteWorkHrsToFirstVisit', NullConverter, true)
   public commuteWorkHrsToFirstVisit: boolean = undefined;

   @JsonProperty('commuteWorkHrsFormLastVisit', NullConverter, true)
   public commuteWorkHrsFormLastVisit: boolean = undefined;

   // Out of phase visits ----------------------------------------------
   @JsonProperty('outOfPhaseVisitDays', NullConverter, true)
   public outOfPhaseVisitDays: number = undefined;

   // Overnights ------------------------------------------------------
   @JsonProperty('overnightsDisabled', NullConverter, true)
   public overnightsDisabled: boolean = undefined;

   @JsonProperty('maxConsecutiveOvernights', NullConverter, true)
   public maxConsecutiveOvernights: number = undefined;

   @JsonProperty('overnightsNoMaximum', NullConverter, true)
   public overnightsNoMaximum: boolean = undefined;

   @JsonProperty('minCommuteTimeBeforeOvernight', NullConverter, true)
   public minCommuteTimeBeforeOvernight: number = undefined;

   @JsonProperty('maxTravelTimeInEventOfOvernight', NullConverter, true)
   public maxTravelTimeInEventOfOvernight: number = undefined;

   @JsonProperty('eventOvernightNoMaximum', NullConverter, true)
   public eventOvernightNoMaximum: boolean = undefined;
}
