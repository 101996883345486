 <div style="display: inline-block" (click)="toggle($event, in)">
   <div *ngIf="!inlineLabel" [ngClass]="{'ui-inputswitch-label': true,
                                        'ui-left-label': true,
                                        'cs-color-white': inverseColours}">{{onLabel}}</div>
   <div [ngClass]="{'ui-inputswitch': true,
                     'ui-state-disabled': disabled,
                     'ui-inputswitch-checked':checked,
                     'ui-on-color': checked,
                     'ui-off-color': !checked && !inverseColours,
                     'ui-off-color-inverse': !checked && inverseColours}" 
         [ngStyle]="style"
         
         [class]="styleClass">
      
      <div [ngClass]="{'ui-inputswitch-off': true,
                        'ui-inverse': inverseColours}">
         <span *ngIf="!inlineLabel" class="ui-inputswitch-offlabel">&nbsp;&nbsp;&nbsp;&nbsp;</span>                  
         <span *ngIf="inlineLabel" class="ui-inputswitch-offlabel">{{onLabel}}</span>
      </div>
      <div class="ui-inputswitch-on">         
         <span *ngIf="!inlineLabel" class="ui-inputswitch-onlabel">&nbsp;&nbsp;&nbsp;&nbsp;</span>
         <span *ngIf="inlineLabel" class="ui-inputswitch-onlabel">{{offLabel}}</span>
      </div>
      <div [ngClass]="{'ui-inputswitch-handle ui-state-default':true, 'ui-state-focus':focused}"></div>
      
      <div class="ui-helper-hidden-accessible">
         <input #in type="checkbox" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy" 
            aria-live="polite" [attr.id]="inputId"
            (focus)="onFocus($event)" (blur)="onBlur($event)" readonly="readonly" [attr.tabindex]="tabindex" />
      </div>
      <div [ngClass]="{'ui-inputswitch-bar':true}"></div>
   </div>
   <!-- <div [ngClass]="{'ui-inputswitch-bar':true}"></div> -->
   <div *ngIf="!inlineLabel" [ngClass]="{'ui-inputswitch-label': true,
                                        'ui-right-label': true,
                                        'cs-color-white': inverseColours}">{{offLabel}}</div>
</div>
