import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceAction } from 'app/models/workspace-action';

@Injectable()
export class UiStore {

   private _activeView: BehaviorSubject<WorkspaceViewType> = new BehaviorSubject<WorkspaceViewType>(null);
   public activeView$: Observable<WorkspaceViewType> = this._activeView.asObservable();

   // to reduce unnessary calls to the data bases some workspace may only refresh data as they are navigated away.
   // An example of this is that for every drag of a visit to the calendar on the visit workspace requires the callers metrics to be refreshed
   // this cant be seen on the vists workspace, so only action this when navigate way from visist workspace.
   private _workspaceToRefreshDataOnNavigateAway: BehaviorSubject<WorkspaceAction[]> = new BehaviorSubject<WorkspaceAction[]>([]);
   public workspaceToRefreshDataOnNavigateAway$: Observable<WorkspaceAction[]> = this._workspaceToRefreshDataOnNavigateAway.asObservable();

   private _workspaceToRefreshDataOnNavigateTo: BehaviorSubject<WorkspaceAction[]> = new BehaviorSubject<WorkspaceAction[]>([]);
   public workspaceToRefreshDataOnNavigateTo$: Observable<WorkspaceAction[]> = this._workspaceToRefreshDataOnNavigateTo.asObservable();

   constructor() {
   }

   public setActiveView(view: WorkspaceViewType) {
      this._activeView.next(view);
   }

   public getActiveView() {
      return this._activeView.getValue();

   }

   public setWorkspaceToRefreshDataOnNavigateAway(wsa: WorkspaceAction) {
      const currentWA = this._workspaceToRefreshDataOnNavigateAway.getValue();

      // check for duplicates
      if (currentWA && currentWA.length > 0) {
         const match = currentWA.find(wa => wa.view === wsa.view && wa.actionGroup === wsa.actionGroup);

         // there should only be one caller data in this collection, each caller will have multiple action groups
         // when we hit the first one clear the list
         if (match !== undefined) {
            currentWA.length = 0;
            currentWA.push(wsa);
         }
         else {
            currentWA.push(wsa);
         }
      }
      else {
         currentWA.push(wsa);
      }
      // get the current values and add to the array
      this._workspaceToRefreshDataOnNavigateAway.next(currentWA);
   }

   public setWorkspaceToRefreshDataOnNavigateTo(wsa: WorkspaceAction) {
      const currentWA = this._workspaceToRefreshDataOnNavigateTo.getValue();

      // check for duplicates
      if (currentWA && currentWA.length > 0) {
         const match = currentWA.find(wa => wa.view === wsa.view && wa.actionGroup === wsa.actionGroup);

         if (match === undefined) {
            currentWA.push(wsa);
         }
      } 
      else {
         currentWA.push(wsa);
      }
      // get the current values and add to the array
      this._workspaceToRefreshDataOnNavigateTo.next(currentWA);
   }

   public getWorkspaceToRefreshDataOnNavigateAway() {
      return this._workspaceToRefreshDataOnNavigateAway.getValue();
   }

   public clearWorkspaceToRefreshDataOnNavigateAway() {
      this._workspaceToRefreshDataOnNavigateAway.next([]);
   }

   public getWorkspaceToRefreshDataOnNavigateTo() {
      return this._workspaceToRefreshDataOnNavigateTo.getValue();
   }

   public clearWorkspaceToRefreshDataOnNavigateTo() {
      this._workspaceToRefreshDataOnNavigateTo.next([]);
   }

}
