import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { environment } from 'environments/environment';
import { Visit } from 'app/models/visit';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';

// this service is used purely for data acess CRUD operations to the data base
// the serbice is normally called from with in a store

// This service class manages the data access and server comunication logic regarding the diary.
@Injectable()
export class VisitsService {

   visitsUrl = (projectId: number, callerId: number) =>
      `${environment.baseUrl}api/schedule/Projects/${projectId}/CallerId/${callerId}/visits`;

   constructor(private http: HttpClient, private _alertStore: AlertStore) { }

   getVisits(projectId: number, callerId: number): Observable<any> {
      return this.http.get<Visit[]>(this.visitsUrl(projectId, callerId))
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let visitsObjects: Visit[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the callers in to Typescript objects
               try {
                  visitsObjects = jsonConvert.deserialize(response, Visit);
               }
               catch (e) {
                  this._alertStore.sendAlert(new Alert('Error', (<Error>e).message));
               }
               return visitsObjects;
            }),
            catchError(error => observableThrowError(error)));
   }
}
