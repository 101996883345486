import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { ImportOptions } from 'app/models/import-options';

/**
 * Dialog component allowing the user to import Caller and/or Callpoint data.
 */
@Component({
   selector: 'import-dialog',
   templateUrl: 'import-dialog.component.html'
})
export class ImportDialogComponent {

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() import = new EventEmitter<void>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Import options the user selects from the dialog.
   public importOptions = new ImportOptions();

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   public constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {
      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });
   }

   // Set the import options in the store and tell the parent component to do the import.
   public onImport(form: NgForm) {
      this._applicationStore.importDataStore.setImportOptions(this.importOptions);
      this.display = false;
      this.import.next();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }
}
