<p-dialog 
    id="daySwapDialog"
    header="Swap days" 
    [(visible)]="display" 
    modal="true" 
    width="600" 
    [draggable]="false" 
    [resizable]="false"
    (onHide)="onCancel()">

<form #form="ngForm" class="form-horizontal" autocomplete="off">
   
    <div class="col-sm-12" style="padding-bottom: 20px; padding-top: 20px;">
        <div style="border: 1px solid #575757;width: 44%;float: left; padding: 10px">
            <h5>Swapping</h5>
            <p-calendar 
                id="fromDateCalendarInput"
                name="fromDateCalendarInput" 
                dateFormat="dd/mm/yy" 
                selectionMode="single"
                selectOtherMonths="true"
                [disabledDates]="fromInvalidDates" 
                [locale]="en"
                [showIcon]="true" 
                [(ngModel)]="fromDate" 
                [style]="{'border':'none'}" 
                [minDate]="minDate"
                [maxDate]="maxDate"
                [readonlyInput]="true"
                (onSelect)="onFromDateSelected($event)"
                >
            </p-calendar>
        </div>
        <div style="float: left;padding: 15px; margin-top: 23px;"><i class="cs-icon icon-repeat"></i></div>
        <div style="border: 1px solid #575757;width: 44%;float: left; padding:10px">
            <h5>For</h5>
            <p-calendar 
                id="toDateCalendarInput"  
                name="toDateCalendarInput" 
                dateFormat="dd/mm/yy" 
                selectionMode="single"
                selectOtherMonths="true"
                [defaultDate]="toDateDefaultDate"
                [disabledDates]="toInvalidDates"
                [locale]="en" 
                [showIcon]="true" 
                [(ngModel)]="toDate" 
                [style]="{'border':'none'}" 
                [minDate]="minDate"
                [maxDate]="maxDate"
                [readonlyInput]="true"
                (onSelect)="onToDateSelected($event)"
                >
            </p-calendar>
        </div>
    </div>
    <div style="width: 100%; float: left; padding:10px;">
        <div *ngIf="hasValidationError || hasOvernightValidationError">
            <div class="panel" style="border:none;">
                    <ul style="width:100%; padding-left: 20px;">
                        <li *ngFor="let error of validationErrors">
                           <span class="small-text">
                              {{error}}
                           </span>
                        </li>
                     </ul>
          
                   <!-- <div class="row">
                      <div class="form-group row">
                         <div class="small-text col-sm-12" style="padding-left:10px; white-space:pre-wrap">{{validationErrors}}</div>
                      </div>
                   </div> -->
             </div>
        </div>
    </div>

   <p-footer>
      <div style="padding-top: 10px; margin-bottom:30px; float: right;">
         <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="toDate === undefined || hasValidationError" (click)="onSave(form)">Swap</button>
         <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
      </div>
   </p-footer>

</form>
</p-dialog>
