import { JsonObject, JsonProperty } from 'json2typescript';
import { NumberOfDayOfWeekConverter} from 'app/models/numberOfDayOfWeekConverter';
import { DateConverter } from 'app/shared/json-converters/date-converter';
import { ShortDateConverter } from 'app/shared/json-converters/short-date-converter';

/**
 * Model class representing a Project.
 */
@JsonObject
export class Visit {
   @JsonProperty('id')
   id: number = undefined;

   @JsonProperty('callerId')
   callerId: number = undefined;

   @JsonProperty('callpointId')
   callpointId: number = undefined;

   @JsonProperty('callpointReference')
   callpointReference: string = undefined;

   @JsonProperty('callpointDescription')
   callpointDescription: string = undefined;

   @JsonProperty('callpointDuration')
   callpointDuration: number = undefined;

   @JsonProperty('callpointIsDisabled')
   callpointIsDisabled: boolean = undefined;

   @JsonProperty('address')
   address: string = undefined;

   @JsonProperty('geocode')
   geocode: string = undefined;

   @JsonProperty('date', DateConverter, true)
   date: Date = undefined;

   @JsonProperty('date', ShortDateConverter, true)
   shortDate: string = undefined; // needed to implement sorting within the DataTable component

   @JsonProperty('startTime')
   startTime: string = undefined;

   @JsonProperty('dayOfWeek')
   dayOfWeek: string = undefined;

   @JsonProperty('scheduleWeek')
   scheduleWeek: number = undefined;

   @JsonProperty('isScheduled')
   isScheduled: boolean = undefined;

   @JsonProperty('isPartScheduled')
   isPartScheduled: boolean = undefined;

   @JsonProperty('dayOfWeek', NumberOfDayOfWeekConverter, true)
   numberOfDayOfWeek: number = undefined;

   @JsonProperty('frequency')
   frequency: number = undefined;

   @JsonProperty('lockType')
   lockType: string = undefined;

   @JsonProperty('sequenceInDay')
   sequenceInDay: number = undefined;


   //Not JSON mapped
   order: number = undefined;
   referenceAndOrder = undefined; // needed to implement sorting within the DataTable component

   // public get numberOfDayOfWeek(): number {
   //    switch (this.dayOfWeek) {
   //       case 'Monday':
   //          return 1;
   //       case 'Tuesday':
   //          return 2;
   //       case 'Wednesday':
   //          return 3;
   //       case 'Thursday':
   //          return 4;
   //       case 'Friday':
   //          return 5;
   //       case 'Saturday':
   //          return 6;
   //       case 'Sunday':
   //          return 0;
   //    }
   // }
}
