
<div class="row top-content-border" [ngStyle]="{'height.px': workspaceHeight}" style="margin-left:30px;margin-right:30px ;">
   <div class="black-workspace col-md-12" style="padding: 10px">
      <callsmart-user-list class="black-datatable no-horizontal-scrollbar top-content-border"
      (createUser)="onCreateUser()"
      [scrollHeight]="scrollHeight"></callsmart-user-list>
   </div>
</div>

<div class="row">

   <hr style="margin-left:30px;margin-right:30px ;">
   <div style="float:right; margin-left:30px;margin-right:30px ; ">
      <button type="button" class="btn cs-btn-primary-inverse" (click)="onExport()" [disabled]="userList.users.length == 0" >Export Emails</button>
      <button type="button" class="btn cs-btn-secondary-inverse" (click)="onDeleteUser()"  [disabled]="userList.selectedUsers == null">
            <i class="cs-icon icon-trash" style="font-size: 15px"></i> Delete</button>

      <button type="button" class="btn cs-btn-primary-inverse" (click)="onEditUser()" [disabled]="userList.selectedUsers == null" >Edit</button>
   </div>
</div>


<ndc-dynamic *ngIf="showDeleteUserDialog" [ndcDynamicComponent]="deleteUserDialog" [ndcDynamicInputs]="deleteUserDialogInput"
   [ndcDynamicOutputs]="deleteUserDialogOutput">
</ndc-dynamic>

<ndc-dynamic *ngIf="showAddEditUserDialog" [ndcDynamicComponent]="addEditUserDialog" [ndcDynamicInputs]="addEditDialogInput"
   [ndcDynamicOutputs]="addEditDialogOutput">
</ndc-dynamic>
