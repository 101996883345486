import { Injectable } from '@angular/core';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// the map store is providing key map state information across maps.
// there is no map service to be incapsulated as this data at the moment is not stored in the database
@Injectable()
export class MapsStore {

   /// Maps Visibility
   public isCallersMapHidden: boolean = false;
   public isCallPointsMapHidden: boolean = false;
   public isScheduleMapHidden: boolean = false;
   // setting for maunal or auto zoom for all maps
   public isMapAutoZoom: boolean = true;

   // is map black and white
   public isBlackAndWhite: boolean = true;

   // alan needs to set a property to turn off map marker optimisation
   public OptimiseMapMarkers: boolean = true;

   // use map clustering
   public useMapClusterPoints: boolean = false;

   public useDirectRoutes:boolean = true;
   public useCallerDotIcon:boolean = false;
   public useVisitDotIcon:boolean = false;
   public showFrequency:boolean = false;

   // different image paths for map clustering
   public clusterMarkerImagePathOrginal: string = 'assets/icons/clustermarkers/orginals/m';
   public clusterMarkerImagePathPurple: string = 'assets/icons/clustermarkers/purple/m';
   public clusterMarkerImagePathGreen: string = 'assets/icons/clustermarkers/green/m';

   // using the style from https://snazzymaps.com/style/15/subtle-grayscale
   public customMapStyle:any = [ { 'featureType': 'all', 'elementType': 'all', 'stylers': [ { 'saturation': '-70' } ] },
   {  'featureType': 'poi', 'elementType': 'all', 'stylers': [  { 'visibility': 'off' } ] } ]

   // lat long of the  map when theuser is in manual mode
   // format is { lat: 51.491378, lng: -0.195999 }
   public manualMapCenter :any;
   public manualMapZoom :any;

   // The current maps bounds
   public mapBounds: any;

   /* black and white map style */
   /*[
      {
          'featureType': 'administrative',
          'elementType': 'all',
          'stylers': [
              {
                  'saturation': '-100'
              }
          ]
      },
      {
          'featureType': 'administrative',
          'elementType': 'labels',
          'stylers': [
              {
                  'saturation': '0'
              }
          ]
     },
      {
          'featureType': 'administrative.province',
          'elementType': 'all',
          'stylers': [
              {
                  'visibility': 'off'
              },
              {
                  'saturation': '-100'
              }
          ]
      },
      {
          'featureType': 'landscape',
          'elementType': 'all',
          'stylers': [
              {
                  'visibility': 'on'
              },
              {
                  'saturation': '33'
              },
              {
                  'lightness': '-10'
              },
              {
                  'color': '#d5d7cd'
              }
          ]
      },
      {
          'featureType': 'poi',
          'elementType': 'all',
          'stylers': [
              {
                  'saturation': '-100'
              },
              {
                  'lightness': '50'
              },
              {
                  'visibility': 'simplified'
              }
          ]
      },
      {
          'featureType': 'road',
          'elementType': 'all',
          'stylers': [
              {
                  'saturation': '-73'
              },
              {
                  'lightness': '18'
              }
          ]
      },
      {
          'featureType': 'road',
          'elementType': 'geometry',
          'stylers': [
              {
                  'saturation': '82'
              },
              {
                  'color': '#a2a5c3'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'all',
          'stylers': [
              {
                  'visibility': 'on'
              },
              {
                  'saturation': '-37'
              },
              {
                  'lightness': '28'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'geometry',
          'stylers': [
              {
                  'saturation': '-50'
              },
              {
                  'lightness': '-13'
              },
              {
                  'visibility': 'on'
              },
              {
                  'color': '#919dad'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'labels',
          'stylers': [
              {
                  'saturation': '-30'
              },
              {
                  'lightness': '-25'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'labels.icon',
          'stylers': [
              {
                  'saturation': '20'
              },
              {
                  'lightness': '10'
              }
          ]
      },
      {
          'featureType': 'road.arterial',
          'elementType': 'all',
          'stylers': [
              {
                  'lightness': '30'
              }
          ]
      },
      {
          'featureType': 'road.local',
          'elementType': 'all',
          'stylers': [
              {
                  'lightness': '40'
              }
          ]
      },
      {
          'featureType': 'transit',
          'elementType': 'all',
          'stylers': [
              {
                  'saturation': -100
              },
              {
                  'visibility': 'simplified'
              }
          ]
      },
      {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
              {
                  'hue': '#ffff00'
              },
              {
                  'lightness': -25
              },
              {
                  'saturation': -97
              }
          ]
      },
      {
          'featureType': 'water',
          'elementType': 'labels',
          'stylers': [
              {
                  'lightness': -25
              },
              {
                  'saturation': -100
              }
          ]
      }
  ]*/

}
