<div class="content" style="padding: 15px">
   <h1>Style guide</h1>
   <div class="panel panel-default">
      <div class="panel-heading">Fonts and text</div>
      <div class="panel-body">
         <h1>h1 Lato heavy 38px</h1>
         <h2>h2 Lato heavy 24px</h2>
         <h3>h3 Lato heavy 20px</h3>
         <h4>h4 Lato heavy 16px</h4>
         <h5>h5 Lato regular 16px</h5>
         <h6>h6 Lato heavy 14px</h6>

         <div class="regular-text">
            b1 Open Sans Regular 16px
            <blockquote>
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  at efficitur lorem, vel mollis risus. Aliquam erat volutpat.
                  Duis eros tellus, euismod id odio ut, malesuada hendrerit
                  augue. Duis eget enim et nisi condimentum fringilla. Aliquam
                  nec suscipit ex. Mauris viverra laoreet eros consectetur
                  vulputate. Mauris fermentum nisi ac enim maximus, at dignissim
                  nisi iaculis. Morbi ultrices nunc condimentum mattis bibendum.
                  Quisque feugiat aliquet ipsum, vitae feugiat libero pharetra
                  sed.
               </p>
            </blockquote>
         </div>

         <div class="regular-text bold">
            b2 Open Sans bold 16px
            <blockquote>
               <p>
                  Etiam vitae lacus tortor. Cras fermentum mi eu diam viverra
                  ullamcorper. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec id tincidunt
                  eros. Donec cursus turpis dolor, eu maximus lacus interdum
                  tempor. Vestibulum luctus faucibus lacus, porttitor
                  condimentum nisl maximus nec. Praesent pulvinar turpis ut urna
                  posuere suscipit. Quisque et magna ut neque suscipit luctus
                  sit amet non libero. Sed viverra libero vel aliquam laoreet.
                  Nam vestibulum ornare dapibus. Mauris vel risus hendrerit,
                  iaculis mauris sed, auctor mi. Donec facilisis leo id interdum
                  commodo. Ut varius et urna sit amet dapibus.
               </p>
            </blockquote>
         </div>

         <div class="small-text">
            b3 Open Sans Regular 12px
            <blockquote>
               <p class="small-text">
                  Etiam vitae lacus tortor. Cras fermentum mi eu diam viverra
                  ullamcorper. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec id tincidunt
                  eros. Donec cursus turpis dolor, eu maximus lacus interdum
                  tempor. Vestibulum luctus faucibus lacus, porttitor
                  condimentum nisl maximus nec. Praesent pulvinar turpis ut urna
                  posuere suscipit. Quisque et magna ut neque suscipit luctus
                  sit amet non libero. Sed viverra libero vel aliquam laoreet.
                  Nam vestibulum ornare dapibus. Mauris vel risus hendrerit,
                  iaculis mauris sed, auctor mi. Donec facilisis leo id interdum
                  commodo. Ut varius et urna sit amet dapibus.
               </p>
            </blockquote>
         </div>

         <div class="small-text bold">
            b4 Open Sans bold 12px
            <blockquote>
               <p class="small-text bold">
                  Etiam vitae lacus tortor. Cras fermentum mi eu diam viverra
                  ullamcorper. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec id tincidunt
                  eros. Donec cursus turpis dolor, eu maximus lacus interdum
                  tempor. Vestibulum luctus faucibus lacus, porttitor
                  condimentum nisl maximus nec. Praesent pulvinar turpis ut urna
                  posuere suscipit. Quisque et magna ut neque suscipit luctus
                  sit amet non libero. Sed viverra libero vel aliquam laoreet.
                  Nam vestibulum ornare dapibus. Mauris vel risus hendrerit,
                  iaculis mauris sed, auctor mi. Donec facilisis leo id interdum
                  commodo. Ut varius et urna sit amet dapibus.
               </p>
            </blockquote>
         </div>

         <div class="xs-small-text">
            b5 Open Sans 10px
            <blockquote>
               <p class="xs-small-text">
                  Etiam vitae lacus tortor. Cras fermentum mi eu diam viverra
                  ullamcorper. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec id tincidunt
                  eros. Donec cursus turpis dolor, eu maximus lacus interdum
                  tempor. Vestibulum luctus faucibus lacus, porttitor
                  condimentum nisl maximus nec. Praesent pulvinar turpis ut urna
                  posuere suscipit. Quisque et magna ut neque suscipit luctus
                  sit amet non libero. Sed viverra libero vel aliquam laoreet.
                  Nam vestibulum ornare dapibus. Mauris vel risus hendrerit,
                  iaculis mauris sed, auctor mi. Donec facilisis leo id interdum
                  commodo. Ut varius et urna sit amet dapibus.
               </p>
            </blockquote>
         </div>

         <div class="xs-small-text bold">
            b6 Open Sans bold 10px
            <blockquote>
               <p class="xs-small-text bold">
                  Etiam vitae lacus tortor. Cras fermentum mi eu diam viverra
                  ullamcorper. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec id tincidunt
                  eros. Donec cursus turpis dolor, eu maximus lacus interdum
                  tempor. Vestibulum luctus faucibus lacus, porttitor
                  condimentum nisl maximus nec. Praesent pulvinar turpis ut urna
                  posuere suscipit. Quisque et magna ut neque suscipit luctus
                  sit amet non libero. Sed viverra libero vel aliquam laoreet.
                  Nam vestibulum ornare dapibus. Mauris vel risus hendrerit,
                  iaculis mauris sed, auctor mi. Donec facilisis leo id interdum
                  commodo. Ut varius et urna sit amet dapibus.
               </p>
            </blockquote>
         </div>
      </div>
   </div>
   <br />
   <form class="form-horizontal">
      <div class="panel panel-default">
         <div class="panel-heading">Buttons</div>
         <div class="panel-body">
            <div class="form-group">
               <div class="col-sm-10">
                  <button type="button" class="btn cs-btn-primary">
                     Primary
                  </button>
                  <button type="button" class="btn cs-btn-primary" disabled>
                     P Disabled
                  </button>
                  <button type="button" class="btn cs-btn-secondary">
                     Secondary
                  </button>
                  <button type="button" class="btn cs-btn-secondary" disabled>
                     Sec Disabled
                  </button>
               </div>
            </div>
            <div class="form-group">
               <div class="col-sm-10" style="background: black; padding: 10px">
                  <button type="button" class="btn cs-btn-primary-inverse">
                     Primary
                  </button>
                  <button
                     type="button"
                     class="btn cs-btn-primary-inverse"
                     disabled
                  >
                     P Disabled
                  </button>
                  <button type="button" class="btn cs-btn-secondary-inverse">
                     Secondary
                  </button>
                  <button
                     type="button"
                     class="btn cs-btn-secondary-inverse"
                     disabled
                  >
                     Sec Disabled
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div class="panel panel-default">
         <div class="panel-heading">Links</div>
         <div class="panel-body">
            <div class="form-group">
               <div class="col-sm-10">
                  <a href="/styleguide">Text Link Medium</a>

                  <a class="col-sm-offset-2 link-disabled" href="javascript:;"
                     >Text Link Medium</a
                  >
                  (Disabled link)
               </div>
            </div>
         </div>
      </div>
      <div class="panel panel-default">
         <div class="panel-heading">Drop down buttons</div>
         <div class="panel-body">
            <div class="form-group">
               <div class="col-sm-10">
                  <div class="btn-group">
                     <button
                        type="button"
                        class="btn cs-btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        Primary
                        <span
                           class="cs-icon icon-arrow-down dropdown-button-icon"
                        ></span>
                     </button>
                     <ul class="dropdown-menu cs-dropdown">
                        <li>
                           <a href="/styleguide">Action</a>
                        </li>
                        <li>
                           <a href="/styleguide">Another action</a>
                        </li>
                        <li>
                           <a href="/styleguide">Something else here</a>
                        </li>
                        <li class="disabled">
                           <a href="/styleguide">Disabled link</a>
                        </li>
                     </ul>
                  </div>
                  <div class="btn-group">
                     <button
                        type="button"
                        class="btn cs-btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        Secondary
                        <span
                           class="cs-icon icon-arrow-down dropdown-button-icon"
                        ></span>
                     </button>
                     <ul class="dropdown-menu cs-dropdown-inverse">
                        <li>
                           <a href="/styleguide">Action</a>
                        </li>
                        <li>
                           <a href="/styleguide">Another action</a>
                        </li>
                        <li>
                           <a href="/styleguide">Something else here</a>
                        </li>
                        <li class="disabled">
                           <a href="/styleguide">Disabled link</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="panel panel-default">
         <div class="panel-heading">Form fields</div>
         <div class="panel-body">
            <p>Text boxes</p>
            <div class="row">
               <div class="form-group">
                  <div class="col-xs-2">
                     <input
                        type="text"
                        id="cycleLength"
                        class="form-control cs-form-control"
                        value="5"
                     />
                  </div>
                  <label for="cycleLength" class="control-label">Weeks</label>
               </div>
               <div class="form-group" style="background: black; padding: 10px">
                  <div class="col-xs-2">
                     <input
                        type="text"
                        id="cycleLength2"
                        class="form-control cs-form-control"
                        value="5"
                     />
                  </div>
                  <label for="cycleLength2" class="control-label cs-color-white"
                     >Weeks</label
                  >
               </div>
            </div>
            <p>Checkboxes</p>
            <div class="form-group">
               <div class="row">
                  <div class=" col-sm-2">
                     <p-checkbox
                        [(ngModel)]="selected"
                        name="checkbox"
                        binary="true"
                        label="Selected"
                     >
                     </p-checkbox>
                  </div>
                  <div class=" col-sm-2">
                     <p-checkbox
                        [(ngModel)]="unselected"
                        name="checkbox2"
                        binary="true"
                        label="Unselected"
                     >
                     </p-checkbox>
                  </div>
               </div>
            </div>
            <div class="form-group" style="background: black; padding: 10px">
               <div class="row">
                  <div class=" col-sm-2">
                     <p-checkbox
                        [(ngModel)]="selected"
                        name="checkbox3"
                        binary="true"
                        label="Selected"
                     >
                     </p-checkbox>
                  </div>
                  <div class=" col-sm-2">
                     <p-checkbox
                        [(ngModel)]="unselected"
                        name="checkbox4"
                        binary="true"
                        label="Unselected"
                     >
                     </p-checkbox>
                  </div>
               </div>
            </div>

            <p>Radio buttons</p>
            <div class="form-group">
               <div class="row">
                  <div class=" col-sm-2">
                     <label class="cs-radio custom-color-purple">
                        <input type="radio" name="inline-radio3" checked />
                        <span> <i></i>Selected</span>
                     </label>
                  </div>
                  <div class=" col-sm-2">
                     <label class="cs-radio custom-color-purple">
                        <input type="radio" name="inline-radio3" />
                        <span> <i></i>Unselected</span>
                     </label>
                  </div>
               </div>
               <div class="row" style="background: black; padding: 10px">
                  <div class=" col-sm-2">
                     <label
                        class="cs-radio cs-radio-inverse custom-color-purple"
                     >
                        <input type="radio" name="inline-radio3" checked />
                        <span> <i></i>Selected</span>
                     </label>
                  </div>
                  <div class=" col-sm-2">
                     <label
                        class="cs-radio cs-radio-inverse custom-color-purple"
                     >
                        <input type="radio" name="inline-radio3" />
                        <span> <i></i>Unselected</span>
                     </label>
                  </div>
               </div>
            </div>

            <p>Drop down lists</p>
            <div class="form-group">
               <div class="col-sm-3">
                  <callsmart-dropdown
                     name="contentList"
                     [options]="contentList"
                     [(ngModel)]="selectedcontent"
                     [style]="{ width: '100%' }"
                  >
                  </callsmart-dropdown>
               </div>

               <div class="col-sm-3">
                  <callsmart-dropdown
                     name="contentList2"
                     disabled
                     [options]="contentList"
                     [(ngModel)]="selectedcontent"
                     [style]="{ width: '100%' }"
                  >
                  </callsmart-dropdown>
               </div>
            </div>

            <div class="form-group">
               <div class="col-sm-3" style="background: black; padding: 10px">
                  <callsmart-dropdown
                     name="contentList3"
                     [options]="contentList"
                     [(ngModel)]="selectedcontent"
                     [style]="{ width: '100%' }"
                  >
                  </callsmart-dropdown>
               </div>
            </div>
            <p>Toggle button</p>
            <div class="panel-body">
               <div class="form-group">
                  <callsmart-inputswitch
                     name="toggle1"
                     [(ngModel)]="selected"
                     onLabel="No"
                     offLabel="Yes"
                  >
                  </callsmart-inputswitch>
               </div>
               <div class="form-group">
                  <callsmart-inputswitch
                     name="toggle2"
                     [(ngModel)]="selected"
                     onLabel="No"
                     offLabel="Yes"
                     inlineLabel="true"
                     disabled
                  >
                  </callsmart-inputswitch>
               </div>
               <div class="form-group" style="background: black; padding: 10px">
                  <callsmart-inputswitch
                     name="toggle3"
                     [(ngModel)]="selected"
                     onLabel="No"
                     offLabel="Yes"
                     inlineLabel="true"
                     inverseColours="true"
                  >
                  </callsmart-inputswitch>
               </div>
               <div class="form-group" style="background: black; padding: 10px">
                  <callsmart-inputswitch
                     name="toggle3"
                     [(ngModel)]="selected"
                     onLabel="No"
                     offLabel="Yes"
                     inverseColours="true"
                  >
                  </callsmart-inputswitch>
               </div>
            </div>

            <p>Clock time picker</p>
            <div class="panel-body">
               <div class="form-group">
                  <div class="col-xs-2">
                     <callsmart-clock-time-picker
                        name="clock"
                        [(ngModel)]="testTime"
                        (blur)="testTime = $event.target.value"
                     ></callsmart-clock-time-picker>
                  </div>
               </div>
            </div>

            <p>Tool Tip</p>
            <div class="panel-body">
               <div class="form-group">
                  <div class="col-xs-2">
                     <input type="text" pTooltip="Enter your username" />
                  </div>
               </div>

               <div class="form-group">
                  <div class="col-xs-2">
                     <div pTooltip="some data tooltip" tooltipPosition="top">
                        simple div with text apply tooltip
                     </div>
                  </div>
               </div>
            </div>

            <p>Custom Multi select lists</p>
            <div class="form-group">
               <div class="col-sm-3">
                  <callsmart-multi-select
                     name="contentList2"
                     [options]="contentList"
                     [(ngModel)]="selectedcontent"
                     [style]="{ width: '100%' }"
                  >
                  </callsmart-multi-select>
               </div>
            </div>

            <div class="form-group">
               <label>Example of the new Alert box </label>
               <br />
               <input
                  type="button"
                  class="btn cs-btn-primary"
                  value="Alert"
                  (click)="dummyAlert()"
               />
            </div>

            <div class="form-group">
               <label>Add custom Json Map Style </label>
               <br />
               <textarea
                  name="Text1"
                  cols="40"
                  rows="5"
                  style="color:black;"
                  [(ngModel)]="mapStyleJson"
               ></textarea>
               <input
                  type="button"
                  class="btn cs-btn-primary"
                  value="Save"
                  (click)="saveMapStyle()"
               />
            </div>

            <div class="form-group">
               <label>Day Combination </label>
               <br />
               <callsmart-day-combination></callsmart-day-combination>
               <div style="background-color: #393939">
                  <callsmart-day-combination></callsmart-day-combination>
               </div>
               <callsmart-day-combination></callsmart-day-combination>
               <div style="background-color: #393939">
                  <callsmart-day-combination></callsmart-day-combination>
               </div>
            </div>

            <div class="form-group">
               <label>Font awesome spinner </label>
               <i class="fas fa-spinner"></i>
            </div>

            <div class="form-group">
               <label>test spinner </label>
               <h4>
                  Loading data
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
               </h4>
               <div>
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
               </div>
            </div>

            <p>Icons as fonts</p>
            <div class="panel-body">
               <div class="form-group">
                  <div class="container">
                     <table class="table table-bordered">
                        <thead>
                           <tr>
                              <th>icon</th>
                              <th>Name</th>
                              <th>icon</th>
                              <th>Name</th>
                           </tr>
                        </thead>

                        <tbody>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-arrow-down"></span>
                              </td>
                              <td>icon-arrow-down</td>
                              <td>
                                 <span class="cs-icon icon-profile"></span>
                              </td>
                              <td>icon-profile</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-hourglass"></span>
                              </td>
                              <td>icon-hourglass</td>
                              <td>
                                 <span class="cs-icon icon-arrow-right"></span>
                              </td>
                              <td>icon-arrow-right</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-hourglass-3"></span>
                              </td>
                              <td>icon-hourglass-3</td>
                              <td>
                                 <span class="cs-icon icon-arrow-left"></span>
                              </td>
                              <td>icon-arrow-left</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-search-1"></span>
                              </td>
                              <td>icon-search-1</td>
                              <td>
                                 <span class="cs-icon icon-calendar-9"></span>
                              </td>
                              <td>icon-calendar-9</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-calendar-8"></span>
                              </td>
                              <td>icon-calendar-8</td>
                              <td>
                                 <span class="cs-icon icon-edit"></span>
                              </td>
                              <td>icon-edit</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-user-3"></span>
                              </td>
                              <td>cs-icon icon-user-3</td>
                              <td>
                                 <span class="cs-icon icon-settings-5"></span>
                              </td>
                              <td>cs-icon icon-settings-5</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-exit-1"></span>
                              </td>
                              <td>cs-icon icon-exit-1</td>
                              <td>
                                 <span class="cs-icon icon-stopwatch-3"></span>
                              </td>
                              <td>cs-icon icon-stopwatch-3</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-briefcase"></span>
                              </td>
                              <td>cs-icon icon-briefcase</td>
                              <td>
                                 <span class="cs-icon icon-calendar-2"></span>
                              </td>
                              <td>cs-icon icon-calendar-2</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-placeholders"></span>
                              </td>
                              <td>cs-icon icon-placeholders</td>
                              <td>
                                 <span class="cs-icon icon-users"></span>
                              </td>
                              <td>cs-icon icon-users</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-settings-3"></span>
                              </td>
                              <td>cs-icon icon-settings-3</td>
                              <td>
                                 <span class="cs-icon icon-Contrast"></span>
                              </td>
                              <td>cs-icon icon-Contrast</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-new-document"></span>
                              </td>
                              <td>cs-icon icon-new-document</td>
                              <td>
                                 <span class="cs-icon icon-trash"></span>
                              </td>
                              <td>cs-icon icon-trash</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-upload"></span>
                              </td>
                              <td>cs-icon icon-upload</td>

                              <td>
                                 <span class="cs-icon icon-alarm"></span>
                              </td>
                              <td>cs-icon icon-alarm</td>
                           </tr>

                           <tr>
                              <td>
                                 <span class="cs-icon icon-cloud"></span>
                              </td>
                              <td>cs-icon icon-cloud</td>

                              <td>
                                 <span class="cs-icon icon-calculator-1"></span>
                              </td>
                              <td>cs-icon icon-calculator-1</td>
                           </tr>

                           <tr>
                              <td>
                                 <span class="cs-icon icon-direction"></span>
                              </td>
                              <td>cs-icon icon-direction</td>

                              <td>
                                 <span class="cs-icon icon-bed"></span>
                              </td>
                              <td>cs-icon icon-bed</td>
                           </tr>

                           <tr>
                              <td>
                                 <span class="cs-icon icon-repeat"></span>
                              </td>
                              <td>cs-icon icon-repeat</td>

                              <td>
                                 <span class="cs-icon icon-add-3"></span>
                              </td>
                              <td>cs-icon icon-add-3</td>
                           </tr>
                           <tr>
                              <td>
                                 <span class="cs-icon icon-info"></span>
                              </td>
                              <td>cs-icon icon-info</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
</div>
