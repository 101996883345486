<div class="row">
   <div class="standard-workspace col-md-10" style="width: calc(100% - 260px);">
      <callsmart-caller-carousel></callsmart-caller-carousel>

      <div [ngStyle]="{'height.px': workspaceHeight}">
         <horizontal-split-pane #box1 secondary-component-minsize="300" primary-component-minsize="150" local-storage-key="split-pane" 
                                (on-change)="onHChange($event, calendar)"
            [secondary-component-toggled-off]="false">
            <div class="split-pane-content-primary">
               <div class="upper">
                  <div [style.height.px]="visitPaneHeight">               
                     <callsmart-visits-list-v2 [visitTableHeight]="visitTableHeight" 
                        [scrollWidth]="scrollWidth"
                        (rowSelectionChanged)="onRowSelectionChanged($event)"
                        (onListFiltered)="resizeListLayout()"
                        (columnChanged)="onListColumnHeaderChanged($event)"
                         #visitList>
                     </callsmart-visits-list-v2>                                             
                  </div>
               </div>

            </div>
            <div class="split-pane-content-secondary">
               <div class="lower">
                  <callsmart-calendar class="white-calendar" #calendar [scheduleEvents]="diaryEvents"                                      
                                      [startWorkingTime]="startWorkingTime" [endWorkingTime]="endWorkingTime"
                                      [activeWorkingDays]="activeWorkingDays"
                                      [numberOfWeeks]="scheduleCycleLength"
                                      [startDate]="startCycleDate"
                                      [canInternalDragDrop]="true"
                                      [canExternalDragDrop]="true" 
                                      [canEditEvents]="true"
                                      [datesClosed]="datesClosed"
                                      [diaryViewTime]="diaryViewTime"
                                      (daySelected)="onDaySelected($event)"
                                      (visitSelected)="onVisitSelected($event)"
                                      (calendarRendered)="onCalendarRendered($event)"                                      
                                      (externalVisitDropped)="onVisitDropped($event)"
                                      (internalVisitDropped)="onInternalVisitDropped($event)"></callsmart-calendar>
               </div>
            </div>
         </horizontal-split-pane>


      </div>
   </div>
   <div class="contextual-panel col-md-2">
      <contextual-panel
         [showScheduleExport]="true"
         [showCallerExport]="false"
         [showCallpointExport]="false">
      </contextual-panel>
   </div>
</div>
