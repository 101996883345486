import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import * as moment from 'moment';

// models
import { Notification } from 'app/models/notification';
import { Caller } from 'app/models/caller';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { User } from 'app/models/user';
import { Event } from 'app/models/diary/event';

//stores
import { VisitsStore } from 'app/stores/visits-store';
import { NotificationsStore } from 'app/stores/notifications-store';
import { ScheduleStore } from 'app/stores/schedule-store';
import { MapsStore } from 'app/stores/maps-store';
import { CallpointsStore } from 'app/stores/callpoints-store';
import { ProjectsStore } from 'app/stores/projects-store';
import { UserCallerSettingsStore } from 'app/stores/user-caller-settings-store';
import { UserCallpointSettingsStore } from 'app/stores/user-callpoint-settings-store';
import { UserProjectSettingsStore } from 'app/stores/user-project-settings-store';
import { CallersStore } from 'app/stores/callers-store';
import { AuthenticationStore } from 'app/stores/authentication-store';
import { DefaultDayCombinationsStore } from 'app/stores/default-day-combinations-store';
import { SysAdminStore } from 'app/stores/sys-admin-store';
import { AlertStore } from 'app/stores/alert-store';
import { AuditStore } from 'app/stores/audit-store';
import { ContextualPanelStore } from 'app/stores/contextual-panel-store';
import { ImportDataStore } from 'app/stores/import-data-store';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { ImportOptions } from 'app/models/import-options';
import { Callpoint } from 'app/models/callpoint';
import { Visit } from '../models/visit';
import { ErrorStore } from 'app/stores/error-store';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { Company } from 'app/models/company';
import { UiStore } from 'app/stores/ui-store';
import { WorkspaceAction } from 'app/models/workspace-action';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { ActionGroupings } from 'app/models/action-groupings.enum';
import { OptimisationStatus } from 'app/models/optimisationStatus';

// creating an application store that will control the collections and state data used in the application.
// such as callers, callpoints, schedules, selected caller, selected callpoint
// documentation on observable data services
// http://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/
// a better migration might be to move to ngrx-store
// http://blog.angular-university.io//angular-ngrx-store-and-effects-crash-course/

// purpose of this store
// is to setup all the other bussiness stores.
// this store orchestrates interaction before stores based on events emitted from stores

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// Key behaviours
// projectOpen and projectClose events  in the project store
// Are events that the application store listens for, trigger in  the following data to refresh
// the following stores call the load methods loads Schedule Settings, caller Setting, gloabl settings, call point settings. Callers data

// Changing the selected caller
// Loads all call points for the caller from callpoints store
// loads the visit data for the caller from visit  store
// loads the schedule for the caller from the schedule store
// loads the diary events for the caller from the diary store

// Cleare Schedule
// loads the schedule for the caller from the schedule store
// loads the diary events for the caller from the diary store
// loads all callers from the callers store

// Updateing  GlobalScheduleSettings
// updates the table and triggers a recalculation on the callers data and mini dash

// Admin data is only loaded when the user access the addmin workspace
// if you are looking for the load companies data & load users

@Injectable()
export class ApplicationStore implements OnDestroy {

   private _user_subscription: Subscription;
   private _selected_caller_changed_subscription: Subscription;
   private _project_created_subscription: Subscription;
   private _schedule_cleared_subscription: Subscription;
   private _schedule_error_optimising_caller_subscription: Subscription;
   private _schedule_workspace_should_refresh_data_on_navigate_away_subscription: Subscription;
   private _schedule_generation_started_subscription: Subscription;
   private _schedule_generation_completed_subscription: Subscription;
   private _global_schedule_settings_changed_subscription: Subscription;
   private _errorhandler_forceloggedout_subscription: Subscription;
   private _user_loggedout_subscription: Subscription;
   private _project_closed_subscription: Subscription;
   private _project_open_subscription: Subscription;
   private _callpointDisabledChanged: Subscription;
   private _callpointLocationChanged: Subscription;
   private _mergeImportUpdateFrequencyCallerIds_subscription:Subscription;
   private _callerLocationChanged: Subscription;
   private _callpointsCallerChanged: Subscription;
   private _mergeImportLocationChanged_subscription: Subscription;
   private _selected_callpoint_changed_subscription: Subscription;
   private _callpoints_changed_subscription: Subscription;
   private _callpointCallerChanged_subscription: Subscription;
   private _eventsUpdated_subscription: Subscription;
   private _user_company_subscription: Subscription;
   private _importCompleted_subscription: Subscription;

   private _default_user_projectsettings_subscription: Subscription; // user default settings
   private _default_user_callpointsettings_subscription: Subscription; // user default settings
   private _default_user_callersettings_subscription: Subscription; // user default settings

   private _currentUser: User;
   private _updatingCallpointDisabledChanged: boolean = false;
   private _updatingCallpointLocationChanged: boolean = false;
   private _updatingCallpointCallerChanged: boolean = false;
   private _updatingCallerLocationChanged: boolean = false;
   private _updatingMergeImportLocationChanged: boolean = false;

   constructor(public callersStore: CallersStore,
      public visitsStore: VisitsStore, public notificationsStore: NotificationsStore,
      public scheduleStore: ScheduleStore, public mapsStore: MapsStore, public callpointsStore: CallpointsStore,
      public projectsStore: ProjectsStore,
      public userCallerSettingsStore: UserCallerSettingsStore,
      public userCallpointSettingsStore: UserCallpointSettingsStore,
      public userProjectSettingsStore: UserProjectSettingsStore,
      public defaultDayCombinationsStore: DefaultDayCombinationsStore,
      public authenticationStore: AuthenticationStore, public sysAdminStore: SysAdminStore,
      public alertStore: AlertStore, public auditStore: AuditStore,
      public contextualPanelStore: ContextualPanelStore,
      public importDataStore: ImportDataStore, public errorStore: ErrorStore, public uiStore:UiStore,
      private _router: Router, private errorHandler: ErrorHandlerService) {

      this.subscribeToUser();
      this.subscribeToUserCompany();
      this.subscribeToUserLoggedOut();

      this.subscribeToForceLogOut();

      //project store subscriptions
      this.subscribeToProjectOpen();
      this.subscribeToMergeImportLocationChanged();
      this.subscribeToMergeImportUpdateFrequencyCallerIds();
      this.subscribeToProjectClosed();
      this.subscribeToEventsUpdated();

      // callers store subscriptions
      this.subscribeToCallerChanged();

      // Callpoints store subscriptions.
      this.subscribeToSelectedCallpointChanged();
      this.subscribeToCallpointDisabledChanged();
      this.subscribeToCallpointLocationChanged();
      this.subscribeToCallpointsChanged();
      this.subscribeToCallpointsCallerChanged();

      // caller store subscriptions
      this.subscribeToCallerLocationChanged();

      // schedule store subscriptions
      this.subscribeToScheduleCleared();
      this.subscribeToScheduleGenerationStarted();
      this.subscribeToScheduleGenerationCompleted();
      this.subscribeToMultiScheduleGenerationCompleted();
      this.subscribeToScheduleErrorOptimisingCaller();
      this.subscribeToWorkspaceShouldRefreshDataOnNavigateAway()

      // Allows the client app to update the modified
      // user projectSettings/callerSettings/callpointSettings properties
      // as needed.
      this.subscribeToDefaultUserProjectSettings()
      this.subscribeToDefaultUserCallerSettings()
      this.subscribeToDefaultUserCallpointSettings()
      this.subscribeToImportCompleted();

      // log the values in the enviroment files to ensure the system is using the correct file
      //console.log('base: ' + environment.baseUrl);
      // console.log('production: ' + environment.production);
      //console.log('loadFirstProject: ' + environment.loadFirstProject);
   }

   ngOnDestroy() {
      if (this._user_subscription) {
         this._user_subscription.unsubscribe();
      }

      if (this._user_company_subscription) {
         this._user_company_subscription.unsubscribe();
      }

      if (this._errorhandler_forceloggedout_subscription) {
         this._errorhandler_forceloggedout_subscription.unsubscribe();
      }

      if (this._schedule_generation_started_subscription) {
         this._schedule_generation_started_subscription.unsubscribe();
      }

      if (this._schedule_generation_completed_subscription) {
         this._schedule_generation_completed_subscription.unsubscribe();
      }

      if (this._schedule_cleared_subscription) {
         this._schedule_cleared_subscription.unsubscribe();
      }

      if (this._project_created_subscription) {
         this._project_created_subscription.unsubscribe();
      }

      if (this._global_schedule_settings_changed_subscription) {
         this._global_schedule_settings_changed_subscription.unsubscribe();
      }

      if (this._selected_caller_changed_subscription) {
         this._selected_caller_changed_subscription.unsubscribe();
      }

      if (this._user_loggedout_subscription) {
         this._user_loggedout_subscription.unsubscribe();
      }

      if (this._callpointDisabledChanged) {
         this._callpointDisabledChanged.unsubscribe();
      }

      if (this._callpointLocationChanged) {
         this._callpointLocationChanged.unsubscribe();
      }

      if (this._mergeImportUpdateFrequencyCallerIds_subscription) {
         this._mergeImportUpdateFrequencyCallerIds_subscription.unsubscribe();
      }

      if (this._callerLocationChanged) {
         this._callerLocationChanged.unsubscribe();
      }

      if (this._callpointsCallerChanged) {
         this._callpointsCallerChanged.unsubscribe();
      }

      if (this._default_user_projectsettings_subscription) {
         this._default_user_projectsettings_subscription.unsubscribe();
      }

      if (this._default_user_callersettings_subscription) {
         this._default_user_callersettings_subscription.unsubscribe();
      }

      if (this._default_user_callpointsettings_subscription) {
         this._default_user_callpointsettings_subscription.unsubscribe();
      }

      if (this._mergeImportLocationChanged_subscription) {
         this._mergeImportLocationChanged_subscription.unsubscribe();
      }

      if (this._project_closed_subscription) {
         this._project_closed_subscription.unsubscribe();
      }

      if (this._project_open_subscription) {
         this._project_open_subscription.unsubscribe();
      }

      if (this._callpoints_changed_subscription) {
         this._callpoints_changed_subscription.unsubscribe();
      }

      if (this._eventsUpdated_subscription) {
         this._eventsUpdated_subscription.unsubscribe();
      }

      if (this._importCompleted_subscription) {
         this._importCompleted_subscription.unsubscribe();
      }

      if (this._schedule_workspace_should_refresh_data_on_navigate_away_subscription) {
         this._schedule_workspace_should_refresh_data_on_navigate_away_subscription.unsubscribe();
      }

   }

   public generateGUID() {
      let d = new Date().getTime();
      let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         let r = (d + Math.random() * 16) % 16 | 0;
         d = Math.floor(d / 16);
         return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return guid;
   };

   public hasLocationChanged() {
      return this._callerLocationChanged || this._callpointLocationChanged || this._callpointsCallerChanged;
   }


   private subscribeToCallerChanged() {
      this._selected_caller_changed_subscription = this.callersStore.selectedCallerChanged.subscribe(
         (caller: Caller) => {
            if (caller) {
               if (this.uiStore.getActiveView() === WorkspaceViewType.Callers) {
                  this.uiStore.setWorkspaceToRefreshDataOnNavigateAway(
                     { view: WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadDiaryEvents, data: [caller.callerId, true] });
                  this.uiStore.setWorkspaceToRefreshDataOnNavigateAway(
                     { view: WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadVisitsForCaller, data: [caller.callerId] });
                  this.uiStore.setWorkspaceToRefreshDataOnNavigateAway(
                     { view: WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadCallpointsForCaller, data: [caller] });
               }
               else {
                  // caller changes refresh the call points data
                  this.callpointsStore.loadCallpointsForCaller(this.callersStore.selectedCaller);

                  // visits data
                  this.visitsStore.loadVisitsForCaller(this.projectsStore.selectedProject.projectId,
                     this.callersStore.selectedCaller.callerId);

                  // load diary events
                  this.scheduleStore.loadDiaryEvents(this.callersStore.selectedCaller.callerId,
                     this.projectsStore.selectedProject.projectId, false);
               }

               // This line of code is needed since this.callpointsStore.setSelectedCallpoints([]); fires
               // the subscription to the Callpoint changed subscription and clear the selectedDiaryDay
               let previousSelectedDiaryDay: Date = this.scheduleStore.selectedDiaryDay;
               // clear the selected callpoints
               this.callpointsStore.setSelectedCallpoints([]);

               // clear the selected diary event in the schedule
               this.scheduleStore.setSelectedDiaryEvent(null);

               // clear the selected diary day in the schedule
               // Selected day needs to be preserved between callers, PSN-2553
               this.scheduleStore.setSelectedDiaryDay(previousSelectedDiaryDay);

               // clear the selected visit in the schedule
               this.scheduleStore.setSelectedVisit(null);

            }
         }
      );
   }

   private subscribeToForceLogOut() {
      this._errorhandler_forceloggedout_subscription = this.errorHandler.forceLogOut.subscribe(
         (user: any) => {
            if (this.authenticationStore.loggedInUser) {
               let uId = this.authenticationStore.loggedInUser.userId;
               this.projectsStore.setSelectedProject(null, uId);
               this._currentUser = undefined;
               this.authenticationStore.forcedLogout();
            }
         }
      );
   }

   private subscribeToUserLoggedOut() {
      this._user_loggedout_subscription = this.authenticationStore.userLoggedOut.subscribe(
         (user: any) => {
            this._currentUser = undefined;
            // need to clear the selected project
            if (this.authenticationStore.loggedInUser) {
               this.projectsStore.setSelectedProject(null, this.authenticationStore.loggedInUser.userId);
            }
         }
      );
   }

   private subscribeToProjectClosed() {
      this._project_closed_subscription = this.projectsStore.projectClosed.subscribe(
         (projectId: any) => {

            this.authenticationStore.usersActiveProjectId = 0;
            // need to clear the users lock and heart beat on the project
            this.authenticationStore.clearUserProjectHeartBeat(this.authenticationStore.loggedInUser.userId, projectId);

            // clear any selected caller
            this.callersStore.setSelectedCaller(null);

            // clear any multi selected callers
            this.callersStore.setSelectedCallers([]);

            // Set the project id for the heart beat to use for active project
            this.authenticationStore.usersActiveProjectId = 0;

         }
      );
   }

   /// trying to unify how projects are Opened
   private subscribeToProjectOpen() {
      this._project_open_subscription = this.projectsStore.projectOpen.subscribe(
         (project: any) => {

            // load the latest list of callpoints with all the schedule metrics needed for the dashboard
            if (project) {

               // set the selected project this function will take care of the project calendar and the project modified date
               this.projectsStore.setSelectedProject(project, this.authenticationStore.loggedInUser.userId);

               //clear any selected callers
               this.callersStore.setSelectedCaller(null);

               // Set the project id for the heart beat to use for active project
               this.authenticationStore.usersActiveProjectId = project.projectId;

               // clear the selected diary day in the schedule
               this.scheduleStore.setSelectedDiaryDay(null);

               //////////////////////////////////////////////////////////
               // why are we loading callers twice in this if statement
               ////////////////////////////////////////////////////////
               this.callersStore.loadAllCallers(project.projectId);

               // The loadAllCallers method will set the selected caller if it is null if not null it will refresh the callers metrics
               /*if (this.callersStore.selectedCaller) {
                  // Refresh the diary events.
                  this.scheduleStore.loadDiaryEvents(this.callersStore.selectedCaller.callerId, project.projectId, true);
                  // Refresh the visits
                  this.visitsStore.loadVisitsForCaller(project.projectId, this.callersStore.selectedCaller.callerId);
                  // Refresh the callers.
                  this.callersStore.loadAllCallers(project.projectId);
                  // The optimisation updates the fully scheduled column so the callpoints need to be refreshed.
                  this.callpointsStore.loadCallpointsForCaller(this.callersStore.selectedCaller);
               }*/

            }

            if (this._updatingCallpointDisabledChanged) {
               this._updatingCallpointDisabledChanged = false;
            }

            if (this._updatingCallpointLocationChanged) {
               // Once drivetimes have been regenerated following the changing of a Callpoint location, reload the
               // schedule and regenerate the map points.
               this._updatingCallpointLocationChanged = false;
            }

            if (this._updatingCallerLocationChanged) {
               // Once drivetimes have been regenerated following the changing of a caller location, reload the
               // schedule and regenerate the map points.
               this._updatingCallerLocationChanged = false;
            }

            if (this._updatingCallpointCallerChanged) {
               // Once drivetimes have been regenerated following the changing of a caller , reload the
               // schedule and callpoints  regenerate the map points.
               this._updatingCallpointCallerChanged = false;

               // clear the selected callpoints
               this.callpointsStore.setSelectedCallpoints([]);

               // clear the selected diary event in the schedule
               this.scheduleStore.setSelectedDiaryEvent(null);

               // clear the selected diary day in the schedule
               this.scheduleStore.setSelectedDiaryDay(null);

               // clear the selected visit in the schedule
               this.scheduleStore.setSelectedVisit(null);

            }

            if (this._updatingMergeImportLocationChanged) {
               this._updatingMergeImportLocationChanged = false;
               // refresh the project data as a lot may have changed with a merge
               this.projectsStore.loadProject(project.projectId, this.authenticationStore.loggedInUser.userId);
            }
         }
      );
   }


   // this sets up the subscription to listen to the user when the user changes or is logged in
   // the system must load the project data and all dependancies
   private subscribeToUser() {
      this._user_subscription = this.authenticationStore.user$.subscribe(
         (user: User) => {
            if (user) {
               if (this._currentUser === undefined) {
                  this._currentUser = user;
                  this.projectsStore.loadInitialProjectData(environment.loadFirstProject, user.userId);
                  this.projectsStore.loadProjectFolders();

                  //load the day combinations once
                  this.defaultDayCombinationsStore.loadAllDayCombinations();
                  this.defaultDayCombinationsStore.loadRecommendedDayCombinations();

                  // start users heart beat
                  this.authenticationStore.pollUserHeartBeat(this._currentUser.userId);

               } else {
                  if (this._currentUser.userId !== user.userId) {
                     this._currentUser = user;
                     this.projectsStore.loadInitialProjectData(environment.loadFirstProject, user.userId);
                     this.projectsStore.loadProjectFolders();

                     // start users heart beat
                     this.authenticationStore.pollUserHeartBeat(this._currentUser.userId);
                  }
               }

               this.userProjectSettingsStore.loadUserProjectSettings();
            }
         }
      );
   }

   private subscribeToUserCompany(): void {
      this._user_company_subscription = this.authenticationStore.userCompany$.subscribe(
         (company: Company) => {
            if(company) {
               //Sync up the company travel model with user project settings if the company only has one travel model.
               if(company.travelModels.length == 1) {
                  let projectSettings = this.userProjectSettingsStore.userProjectSettings;
                  projectSettings.travelModel = company.travelModels[0];
                  this.userProjectSettingsStore.updateUserProjectSettings(projectSettings);
               }
            }
         }
      );
   }

   // this sets up the subscription to listen to the schedule store for schedule cleared
   private subscribeToScheduleCleared() {
      this._schedule_cleared_subscription = this.scheduleStore.scheduleCleared.subscribe(
         (callerId: number) => {
            if (callerId) {

               this.scheduleStore.loadDiaryEvents(callerId, this.projectsStore.selectedProject.projectId, true);
               //reload the callers data
               this.callersStore.loadAllCallers(this.projectsStore.selectedProject.projectId);
               this.visitsStore.loadVisitsForCaller(this.projectsStore.selectedProject.projectId, callerId);
               // clear the selected diary event in the schedule
               this.scheduleStore.setSelectedDiaryEvent(null);
               this.scheduleStore.setSelectedVisit(null);
               // clear the selected diary day in the schedule
               this.scheduleStore.setSelectedDiaryDay(null);
               // clearing the schedule changes the metrics associated with the callpoints, these need to be reloaded.
               //this.callpointsStore.loadCallpointsForCaller(this.callersStore.selectedCaller);
            }
         }
      );
   }

   // this sets up the subscription to listen to the schedule store for generation started
   private subscribeToScheduleGenerationStarted() {
      this._schedule_generation_started_subscription = this.scheduleStore.scheduleGenerationStarted.subscribe(
         (status: OptimisationStatus) => {
            if (status.callerId) {
               // Creates a notificication informing about the start of the process.
               let caller: Caller = this.callersStore.callers.find(item => {
                  return item.callerId === status.callerId
               });
               if (caller) {
                  let notification: Notification = this.notificationsStore.createNotification(status.status, caller.name);
                  // Sends the notification to be displayed in the toast panel.
                  this.notificationsStore.sendNotification(notification);
                  this.visitsStore.clearScheduledVisits();
               }

            }
         }
      );
   }

   // subscription to listen for any errors in the caller optimisation
   private subscribeToScheduleErrorOptimisingCaller() {
      this._schedule_error_optimising_caller_subscription = this.scheduleStore.errorOptimisingCaller.subscribe(
         (error: any) => {
            if (error.callerId) {

               // Creates a notificication informing about the end of the process.
               let caller: Caller = this.callersStore.callers.find(item => {
                  return item.callerId === error.callerId
               });
               if (caller) {
                  // main reason for error scheduler service down
                  let notification: Notification = this.notificationsStore.createNotification('Optimisation error', caller.name);
                  notification.severity = 'error';

                  notification.errorMessage = error.errorMessage || 'Check the Api service & the Scheduler Service are working.';

                  // Sends the notification to be displayed in the toast panel.
                  this.notificationsStore.sendNotification(notification);
               }
            }
         }
      );
   }

   // listen to event to makre the ui store , that this view has to do some actions when it navigates away
   private subscribeToWorkspaceShouldRefreshDataOnNavigateAway(){
      this._schedule_workspace_should_refresh_data_on_navigate_away_subscription= this.scheduleStore.workspaceShouldRefreshDataOnNavigateAway.subscribe(
         (workspaceAction: WorkspaceAction) => {
            this.uiStore.setWorkspaceToRefreshDataOnNavigateAway(workspaceAction)
         }
      );
   }

   // This sets up the subscription to listen to the schedule store for generation completed
   private subscribeToScheduleGenerationCompleted() {
      this._schedule_generation_completed_subscription = this.scheduleStore.scheduleGenerationCompleted.subscribe(
         (status: OptimisationStatus) => {
            if (status.callerId) {
               // get the caller
               let caller: Caller = this.callersStore.callers.find(item => {
                  return item.callerId === status.callerId
               });

               // Only refresh the caller data if the caller is currently selected. The user can start an optimisation
               // for one caller and then decide to view another caller so don't want to overwrite the data for the
               // wrong caller.
               let selectedCaller = this.callersStore.selectedCaller;
               if (selectedCaller !== null && status.callerId === selectedCaller.callerId) {

                  // Refresh the diary events.
                  this.refreshDiaryEvents(caller);
                  // Refresh visits for this caller.
                  this.refreshVisitsForCaller(caller);
                  // Refresh the callers.
                  this.refreshAllCallers(caller);
                  // The optimisation updates the fully scheduled column so the callpoints need to be refreshed.
                  this.refreshCallpointsForCaller(caller);

                  // clear the selected diary event in the schedule
                  this.scheduleStore.setSelectedDiaryEvent(null);
                  this.scheduleStore.setSelectedVisit(null);
                  // clear the selected diary day in the schedule
                  this.scheduleStore.setSelectedDiaryDay(null);
               }

               // Creates a notificication informing about the end of the process.
               if (caller) {
                  //console.log("Scheduled Generated - Notification Visible for: "+ caller.name + " - " + callerId);
                  let notification: Notification = this.notificationsStore.createNotification(status.status, caller.name);
                  // Sends the notification to be displayed in the toast panel.
                  this.notificationsStore.sendNotification(notification);
               }
            }
         }
      );
   }

   // If the optimisation was triggered from the Schedule workspace then load the diary events straight away but
   // delay the loading of callers, callpoints and visits until the user navigates away from the workspace.
   private refreshDiaryEvents(caller: Caller) {
      if(this.uiStore.getActiveView() === WorkspaceViewType.Schedule){
         this.scheduleStore.loadDiaryEvents(caller.callerId, this.projectsStore.selectedProject.projectId, true);
      }else{
         this.uiStore.setWorkspaceToRefreshDataOnNavigateTo({view : WorkspaceViewType.Schedule, actionGroup: ActionGroupings.LoadDiaryEvents, data:[caller.callerId, true]});
      }

      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Schedule, actionGroup: ActionGroupings.LoadAllCallersWithoutMapPoints});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Schedule, actionGroup: ActionGroupings.LoadVisitsForCaller, data:[caller.callerId]});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Schedule, actionGroup: ActionGroupings.LoadCallpointsForCaller, data:[caller]});
   }

   // If the optimisation was triggered from the Visits workspace then load the diary events and visits straight away but
   // delay the loading of callers and callpoints until the user navigates away from the workspace.
   private refreshVisitsForCaller(caller: Caller) {
      if(this.uiStore.getActiveView() === WorkspaceViewType.Visits){
         this.scheduleStore.loadDiaryEvents(caller.callerId, this.projectsStore.selectedProject.projectId, true);
         this.visitsStore.loadVisitsForCaller(this.projectsStore.selectedProject.projectId, caller.callerId);
      }else{
         this.uiStore.setWorkspaceToRefreshDataOnNavigateTo({view : WorkspaceViewType.Visits, actionGroup: ActionGroupings.LoadDiaryEvents, data:[caller.callerId, true]});
         this.uiStore.setWorkspaceToRefreshDataOnNavigateTo({view : WorkspaceViewType.Visits, actionGroup: ActionGroupings.LoadVisitsForCaller, data:[caller.callerId]});
      }

      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Visits, actionGroup: ActionGroupings.LoadAllCallersWithoutMapPoints});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Visits, actionGroup: ActionGroupings.LoadCallpointsForCaller, data:[caller]});
   }

   // If the optimisation was triggered from the Callers workspace then load the callers straight away but
   // delay the loading of diary events, visits and callpoints until the user navigates away from the workspace.
   private refreshAllCallers(caller: Caller) {
      if(this.uiStore.getActiveView() === WorkspaceViewType.Callers){
         this.callersStore.loadAllCallers(this.projectsStore.selectedProject.projectId);
      }
      else {
         this.uiStore.setWorkspaceToRefreshDataOnNavigateTo({view : WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadAllCallersWithoutMapPoints, data:[caller]});
      }

      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadDiaryEvents, data:[caller.callerId, true]});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadVisitsForCaller, data:[caller.callerId]});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callers, actionGroup: ActionGroupings.LoadCallpointsForCaller, data:[caller]});
   }

   // If the optimisation was triggered from the Callpoints workspace then load the callpoints straight away but
   // delay the loading of diary events, visits and callers until the user navigates away from the workspace.
   private refreshCallpointsForCaller(caller: Caller) {
      if(this.uiStore.getActiveView() === WorkspaceViewType.Callpoints){
         this.callpointsStore.loadCallpointsForCaller(caller);
      }
      else {
         this.uiStore.setWorkspaceToRefreshDataOnNavigateTo({view : WorkspaceViewType.Callpoints, actionGroup: ActionGroupings.LoadCallpointsForCaller, data:[caller]});
      }

      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callpoints, actionGroup: ActionGroupings.LoadAllCallersWithoutMapPoints});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callpoints, actionGroup: ActionGroupings.LoadDiaryEvents, data:[caller.callerId, true]});
      this.uiStore.setWorkspaceToRefreshDataOnNavigateAway({view : WorkspaceViewType.Callpoints, actionGroup: ActionGroupings.LoadVisitsForCaller, data:[caller.callerId]});
   }


   // All the callers have finished optimising so load the data for all callers.
   // This will also recalculate the metrics and dashboard figures.
   private subscribeToMultiScheduleGenerationCompleted() {
      this._schedule_generation_completed_subscription = this.scheduleStore.multiScheduleGenerationCompleted.subscribe(
         () => {
            // Refresh the callers.
            this.callersStore.loadAllCallers(this.projectsStore.selectedProject.projectId);
         }
      );
   }

   private subscribeToSelectedCallpointChanged() {
      this._selected_callpoint_changed_subscription = this.callpointsStore.selectedCallpoints$.subscribe((selectedCallpoints: Callpoint[]) => {
         let matchedVisits: Visit[] = [];
         if (selectedCallpoints && selectedCallpoints.length > 0) {
            // get all visits that match a call pointthis._allVisitsForCaller.filter(v => (selectedCallpoints.findIndex(c => c.callpointId == v.callpointId) > -1));
            let callpointIds: number[] = selectedCallpoints.map(callpoint => callpoint.callpointId);
            matchedVisits = this.visitsStore.getCallpointVisits(callpointIds);

            this.visitsStore.setSelectedVisits(matchedVisits);

            // The Schedule workspace already had a visit selected
            if (this.scheduleStore.selectedDiaryEvent) {
               let selectedVisitInDiary: Visit =
                  this.visitsStore.selectedVisits.find(selectedVisit => {
                     let selectedGridVisitDate = moment(selectedVisit.date);
                     let visitStartTime = moment(selectedVisit.startTime, 'HH:mm:ss');
                     selectedGridVisitDate = selectedGridVisitDate.hour(visitStartTime.get('hour'));
                     selectedGridVisitDate = selectedGridVisitDate.minute(visitStartTime.get('minutes'));
                     selectedGridVisitDate = selectedGridVisitDate.second(visitStartTime.get('seconds'));

                     return moment(this.scheduleStore.selectedDiaryEvent.start).isSame(selectedGridVisitDate);
                  });

               // The selected visit in the Schedule Workspace is the same which have to be still selected
               // The execution of this method does not carry on
               if (selectedVisitInDiary) {
                  return;
               }
            }
            // Set the last visit in the array as selected in the schedule store
            let visitToSelectOnScheduleStore: Event = this.getEventFromLastSelectedVisits();
            if (visitToSelectOnScheduleStore) {
               this.scheduleStore.setSelectedDiaryDay(visitToSelectOnScheduleStore.start);
               this.scheduleStore.setSelectedDiaryEvent(visitToSelectOnScheduleStore);
            }
            else {
               this.scheduleStore.setSelectedDiaryDay(null);
               this.scheduleStore.setSelectedDiaryEvent(null);
               this.scheduleStore.setSelectedVisit(null);
            }

         }
         else {
            //this.scheduleStore.setSelectedDiaryDay(null);
            this.scheduleStore.setSelectedDiaryEvent(null);
            this.scheduleStore.setSelectedVisit(null);
            this.visitsStore.setSelectedVisits(null);
         }
      });
   }

   private getEventFromLastSelectedVisits(): Event {

      if (this.visitsStore.selectedVisits.length === 0) {
         return null;
      }
      let selectedVisit = this.visitsStore.selectedVisits[this.visitsStore.selectedVisits.length - 1]
      let visitDayDate = moment(selectedVisit.date);
      let visitStartTime = moment(selectedVisit.startTime, 'HH:mm:ss');
      visitDayDate = visitDayDate.hour(visitStartTime.get('hour'));
      visitDayDate = visitDayDate.minute(visitStartTime.get('minutes'));
      visitDayDate = visitDayDate.second(visitStartTime.get('seconds'));
      let selectedDiaryVisit: Event = this.scheduleStore.currentCallerDiaryEvents.find(event => moment(event.start).isSame(visitDayDate));
      return selectedDiaryVisit;
   }

   private subscribeToCallpointDisabledChanged(): void {      
      this._callpointDisabledChanged = this.callpointsStore.callpointDisabledChanged$.subscribe(() => {
        
        let selectedCallpoints = this.callpointsStore.selectedCallpoints
        let selectedCaller = this.callersStore.selectedCaller;
        if (selectedCaller !== null && selectedCallpoints != null && selectedCallpoints.length > 0) {
            let isDisabled: boolean = selectedCallpoints[0].isDisabled;

            // Refresh the diary events.
            this.refreshDiaryEvents(selectedCaller);
            // Refresh visits for this caller.
            this.refreshVisitsForCaller(selectedCaller);
            // Refresh the callers.
            this.refreshAllCallers(selectedCaller);
            
            //  Refresh visits callpointIsDisabled flag - use for drag/droppable.
            let callpointIds: number[] = selectedCallpoints.map(callpoint => callpoint.callpointId);
            let matchedVisits: Visit[] = [];
            matchedVisits = this.visitsStore.getCallpointVisits(callpointIds);
            matchedVisits.forEach(visit => {
               visit.callpointIsDisabled = isDisabled;
            })
            
            // Refresh callpoints, need to clear selected items to get callpoints list / selections etc. in sync. 
            this.callpointsStore.setSelectedCallpoints([]);

            this.refreshCallpointsForCaller(selectedCaller);
         }
      });
   }

   private subscribeToCallpointLocationChanged(): void {
      this._callpointLocationChanged = this.callpointsStore.callpointLocationChanged$.subscribe((callerIdsChanged: number[]) => {
         if(callerIdsChanged.length > 0) {
            this._updatingCallpointLocationChanged = true;

            // Start monitoring project status to determine when the required drivetimes have been regenerated.
            this.projectsStore.monitorProjectStatus();

            // Show drivetime creation progress display.
            this._router.navigate(['updating-callpoints']);
         }
      });
   }

   private subscribeToCallpointsChanged(): void {
      this._callpoints_changed_subscription = this.callpointsStore.callpointChanged$.subscribe(() => {
         this.callpointsStore.loadCallpointsForCaller(this.callersStore.selectedCaller);
      });
   }

   // private subscribeToCallpointsCallerChanged(): void {
   //    this._callpointsCallerChanged = this.callersStore.callerLocationChanged$.subscribe(() => {
   //       this._updatingCallerLocationChanged = true;

   //       // Start monitoring project status to determine when the required drivetimes have been regenerated.
   //       this.projectsStore.monitorProjectStatus();

   //       // Show drivetime creation progress display.
   //       this._router.navigate(['updating-callers']);
   //    });
   // }

   // private subscribeToCallerLocationChanged(): void {
   //    this._callerLocationChanged = this.callpointsStore.callpointCallerChanged$.subscribe(() => {
   //       this._updatingCallpointCallerChanged = true;

   //       // Start monitoring project status to determine when the required drivetimes have been regenerated.
   //       this.projectsStore.monitorProjectStatus();

   //       // Show drivetime creation progress display.
   //       this._router.navigate(['updating-callers']);
   //    });
   // }

   private subscribeToCallpointsCallerChanged(): void {
      this._callpointsCallerChanged = this.callpointsStore.callpointCallerChanged$.subscribe((callerIdsChanged: number[]) => {
         if(callerIdsChanged.length > 0) {
            this._updatingCallpointCallerChanged = true;

            // Start monitoring project status to determine when the required drivetimes have been regenerated.
            this.projectsStore.monitorProjectStatus();

            // Show drivetime creation progress display.
            this._router.navigate(['updating-callpoints-callers']);

         }
      });
   }

   private subscribeToCallerLocationChanged(): void {
      this._callerLocationChanged = this.callersStore.callerLocationChanged$.subscribe((callersLatLngChanged: number[]) => {
         if(callersLatLngChanged.length > 0) {

            this._updatingCallerLocationChanged = true;

            // Start monitoring project status to determine when the required drivetimes have been regenerated.
            this.projectsStore.monitorProjectStatus();

            // Show drivetime creation progress display.
            this._router.navigate(['updating-callers']);
         }
      });
   }

   private subscribeToDefaultUserProjectSettings(): void {
      this._default_user_projectsettings_subscription = this.userProjectSettingsStore.defaultProjectSettings$.subscribe(
         (settings: ProjectSettings) => {
            if (this.authenticationStore.loggedInUser) {
               this.authenticationStore.loggedInUser.projectSettings = settings;
            }
         });
   }

   private subscribeToDefaultUserCallpointSettings(): void {
      this._default_user_callpointsettings_subscription = this.userCallpointSettingsStore.defaultCallpointSettings$.subscribe(
         (settings: CallpointSettings) => {
            if (this.authenticationStore.loggedInUser) {
               this.authenticationStore.loggedInUser.callpointSettings = settings;
            }
         });
   }

   private subscribeToDefaultUserCallerSettings(): void {
      this._default_user_callersettings_subscription = this.userCallerSettingsStore.defaultCallerSettings$.subscribe(
         (settings: CallerSettings) => {
            if (this.authenticationStore.loggedInUser) {
               this.authenticationStore.loggedInUser.callerSettings = settings;
            }
         });
   }

   private subscribeToMergeImportUpdateFrequencyCallerIds(): void {
      this._mergeImportUpdateFrequencyCallerIds_subscription = this.projectsStore.mergeImportUpdateFrequencyCallerIds.subscribe(
         (callerIds: number[]) => {
            callerIds.forEach(id => {
               this.scheduleStore.generateScheduleWithUpdatedFrequency(id);
            });
         });
   }

   private subscribeToMergeImportLocationChanged(): void {
      this._callpointLocationChanged = this.projectsStore.mergeImportLocationChanged$.subscribe(
         (importOptions: ImportOptions) => {
            if (importOptions) {
               this._updatingMergeImportLocationChanged = true;

               // Start monitoring project status to determine when the required drivetimes have been regenerated.
               this.projectsStore.monitorProjectStatus();

               // Show drivetime creation progress display based on what was displayed.
               if (importOptions.importCallpoints && !importOptions.importCallers) {
                  this._router.navigate(['merging-callpoints']);
               }
               else if (!importOptions.importCallpoints && importOptions.importCallers) {
                  this._router.navigate(['merging-callers']);
               }
               else {
                  this._router.navigate(['merging-callers-callpoints']);
               }
            }
         });
   }

   private subscribeToEventsUpdated(): void {
      this._eventsUpdated_subscription = this.projectsStore.eventsUpdated.subscribe(
         () => {
            this.visitsStore.loadVisitsForCaller(this.projectsStore.selectedProject.projectId,
               this.callersStore.selectedCaller.callerId);
            this.scheduleStore.loadDiaryEvents(this.callersStore.selectedCaller.callerId,
               this.projectsStore.selectedProject.projectId, false);
         }
      );
   }

   private subscribeToImportCompleted(): void {
      this._importCompleted_subscription = this.projectsStore.importCompleted.subscribe(
         (importOptions: ImportOptions) => {
            if (importOptions) {
               if (importOptions.importCallpoints ) {
                  this.callpointsStore.loadCallpointsForCaller(this.callersStore.selectedCaller);
                  this.scheduleStore.loadDiaryEvents(this.callersStore.selectedCaller.callerId,
                     this.projectsStore.selectedProject.projectId, false);

                  this.visitsStore.loadVisitsForCaller(this.projectsStore.selectedProject.projectId,
                     this.callersStore.selectedCaller.callerId);
               }

               if (importOptions.importCallers) {
                  this.callersStore.loadAllCallers(importOptions.originalProjectId);
               }

               this.projectsStore.navigateToDashboard.next();

            }
         });
   }
}
