import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Project } from 'app/models/project';
import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ProjectSettingsViewModel } from 'app/models/view-models/project-settings-view';
import { ProjectSettings } from 'app/models/settings/project-settings';
import { CanComponentDeactivate } from 'app/services/pending-changes-guard.service';
import { AlertStore } from 'app/stores/alert-store';

/**
 * Component for the Project settings.
 */
@Component({
   templateUrl: 'project-properties-settings.component.html'
})

export class ProjectPropertiesSettingsComponent implements OnInit, CanComponentDeactivate {

   @ViewChild('editProjectSettings') editProjectSettings;

   private MILES_TO_KM_FACTOR = 0.621371192;

   // Scroll height for the scroll panel.
   public scrollHeight: number;

   // Project settings model.
   public projectSettings: ProjectSettingsViewModel;

   // Drives the enablement of the save button
   public formValid: boolean = false;

   // Subscription for the project.
   private _project_subscription: Subscription;

   constructor(private _applicationStore: ApplicationStore,
      private windowService: BrowserWindowService,
      private _router: Router,
      private _alertStore: AlertStore) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 200;
      });
   }

   ngOnInit() {
      this.subscribeToProject();
   }

   ngAfterContentInit() {
      // Subscribe to the form state change event for each step.
      this.editProjectSettings.formState$.subscribe((newState: boolean) => {
         this.formValid = newState;
      });
   }

   canDeactivate(): boolean {
      // If the form is not pristine (ie dirty), then prompt the user to save any changes.
      return this.editProjectSettings.projectSettingsForm.pristine;
   }

   public submitForm() {
      // The user is saving the data so need to mark the form as pristine so that the PendingChangesGuard does not trigger
      // and stop the user from navigating away after they have saved.
      this.editProjectSettings.projectSettingsForm.form.markAsPristine();

      this.projectSettings.underUtilisation = Math.round(this.editProjectSettings.projectSetting.utilisationDefinition[0]);
      this.projectSettings.overUtilisation = Math.round(this.editProjectSettings.projectSetting.utilisationDefinition[1]);

      let project = this.updateProjectModel();
      let settings = this.createProjectSettingsModel();
      project.projectSettings = settings;

      this._applicationStore.projectsStore.updateProject(project, true);

   }

   public onCancel() {
      this._router.navigate(['/dashboard']);
   }

   private updateProjectModel(): Project {
      let project = this._applicationStore.projectsStore.selectedProject;
      project.modifiedDate = new Date();
      project.folder = this.projectSettings.folder;
      project.name = this.projectSettings.name;
      return project;
   }

   private createProjectSettingsModel(): ProjectSettings {
      let settings: ProjectSettings = new ProjectSettings();
      settings.projectSettingsId = this.projectSettings.projectSettingsId;
      settings.callCycleLength = this.projectSettings.callCycleLength;
      settings.quickOptimiserIteration = this.projectSettings.quickOptimiserIteration;
      settings.fullOptimiserIteration = this.projectSettings.fullOptimiserIteration;
      settings.distanceUnitMiles = this.projectSettings.distanceUnitMiles;
      settings.currency = this.projectSettings.currency;
      settings.drivingCostPerUnit = this.projectSettings.drivingCostPerUnit;
      settings.averageUnitPerGallon = this.projectSettings.averageUnitPerGallon;
      settings.averageVehicleEmission = this.projectSettings.averageVehicleEmission;
      settings.travelModelId = this.projectSettings.travelModelId;
      settings.travelModel = this.projectSettings.travelModel;
      settings.underUtilisation = this.projectSettings.underUtilisation;
      settings.overUtilisation = this.projectSettings.overUtilisation;
      settings.diaryViewTime = this.projectSettings.diaryViewTime;
      settings.minTotalDrivingSelected = this.projectSettings.minTotalDrivingSelected;
      settings.minDrivingBetweenVisitsSelected = this.projectSettings.minDrivingBetweenVisitsSelected;
      settings.optimiserDrivingConfigCustomSelected = this.projectSettings.optimiserDrivingConfigCustomSelected;
      settings.idowhShift1 = this.projectSettings.idowhShift1;
      settings.idowhShift2 = this.projectSettings.idowhShift2;
      settings.idowhCommuteAM1 = this.projectSettings.idowhCommuteAM1;
      settings.idowhCommuteAM2 = this.projectSettings.idowhCommuteAM2;
      settings.idowhCommutePM1 = this.projectSettings.idowhCommutePM1;
      settings.idowhCommutePM2 = this.projectSettings.idowhCommutePM2;
      settings.iterationWeightWH = this.projectSettings.iterationWeightWH;
      settings.iterationWeightAM = this.projectSettings.iterationWeightAM;
      settings.iterationWeightPM = this.projectSettings.iterationWeightPM;
      
      return settings;
   }

   private subscribeToProject() {
      this._project_subscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project !== null) {
               this.projectSettings = new ProjectSettingsViewModel(project);
            }
         });
   }


}
