import {
   Component,
   OnInit,
   OnDestroy,
   EventEmitter,
   Output,
   Input,
   ViewChild
} from '@angular/core';
import {
   SelectItem} from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { ApplicationStore } from 'app/stores/application-store';
import { DataTable } from 'primeng/components/datatable/datatable';

import { Router } from '@angular/router';
import { Company } from 'app/models/company';
import {
   getComboDataForSpecifiedProperty
} from 'app/shared/utils/callsmart-grid-util';

@Component({
   selector: 'callsmart-company-list',
   templateUrl: './company-list.component.html'
})
export class CompanyListComponent implements OnInit, OnDestroy {
   @ViewChild(DataTable)
   private companyList: DataTable;

   @Output() rowUnSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   @Output() createCompany: EventEmitter<any> = new EventEmitter<any>();

   @Input() scrollHeight = '500px';

   //Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];

   public hasFilters: boolean;
   public companies: ReadonlyArray<Company>;
   public cols: any[];
   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedCompanies: Company;
   public selectedItemsLabel: string = 'Columns'; // shows the count '{0} columns displayed';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns'; //'0 columns displayed'
   public panelTitle: string = 'Columns';

   public gridTitle: string = 'Companies';

   private _companies_subscription: Subscription;

   constructor(
      private _applicationStore: ApplicationStore,
      private router: Router
   ) {}

   public ngOnInit(): void {
      this.configureTableColumns();

      this._companies_subscription = this._applicationStore.sysAdminStore.companies$.subscribe(
         (companies: ReadonlyArray<Company>) => {
            this.configureCompanyData(companies);
         }
      );
   }

   public ngOnDestroy(): void {
      if (this._companies_subscription) {
         this._companies_subscription.unsubscribe();
      }
   }

   public onRowSelect(event) {
      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }

      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }

      this.companyList.reset();

      // have to clear all the custom filters (combos and multi selects)
      this.cols.forEach(col => {
         if (col.hasCombo || col.hasMulti) {
            this.companyList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // this keeps the orgingal sort
      this.companyList.updateDataToRender(this.companies);
      this.hasFilters = false;
      this.gridTitle = 'Companies';
   }

   public onFilter(event) {
      if (Object.keys(this.companyList.filters).length > 0) {
         this.buildStandardGridTitleWithBuiltInFilters();
      } else {
         this.gridTitle = 'Companies';
         this.hasFilters = false;
      }
   }

   public onCreateCompany() {
      this.createCompany.emit(event);
   }

   // builds up the data to be used in the combos for the columns that require combos to filter the data
   private configureCompanyData(companies: ReadonlyArray<Company>) {
      this.companies = companies;

      // this.buildCompanyComboData(companies);
      this.comboFilterDataMap.set(
         'companyName',
         getComboDataForSpecifiedProperty(
            companies,
            company => company.companyName,
            true,
            'asc'
         )
      );

      // defualt selection to first in the list
      if (companies.length > 0) {
         this.selectedCompanies = companies[0];
      }
   }

   private buildStandardGridTitleWithBuiltInFilters() {
      let filterNum = 0;
      if (this.companyList.filteredValue === null) {
         filterNum = this.companies.length;
      } else {
         filterNum = this.companyList.filteredValue.length;
      }

      this.gridTitle =
         'Companies (Showing ' +
         filterNum +
         ' of ' +
         this.companies.length +
         ' total)';
      this.hasFilters = true;
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      this.cols = [
         {
            field: 'companyName',
            header: 'Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'concurrentUsers',
            header: 'Concurrent Users',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'travelModels.length',
            header: 'Available travel models',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         }
         //{ field: 'licenseExpiryDate', header: 'license Expiry Date', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false, isButton: false },
      ];

      this.allCols = [
         {
            field: 'companyName',
            header: 'Company Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'concurrentUsers',
            header: 'Concurrent Users',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         },
         {
            field: 'travelModels.length',
            header: 'Available travel models',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
            isButton: false
         }
         //{ field: 'licenseExpiryDate', header: 'license Expiry Date', disabled: false, filter: true, filterPlaceholder: 'contains', filterMatchMode: 'contains', hasCombo: false, hasMulti: false, isButton: false },
      ];

      this.comboFilterDataMap = new Map<string, any[]>();
      this.columnOptions = [];

      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i]
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }
   }
}
