<div class="container">
   <div *ngIf="callsmartError">
      <div *ngIf="callsmartError.status; else genericMessage">
         <h4>Status code: {{callsmartError.status}}</h4>
         <h4>{{callsmartError.statusText}}</h4>
         <h4>{{callsmartError.url}}</h4>
         <h4>Stack Trace:</h4>
         <p>{{callsmartError.stackTraceMsg}}</p>
         <p style="white-space: pre-wrap;">{{callsmartError.stackTraceDetail}}</p>
      </div>
      <ng-template #genericMessage>
         <p>ERROR: {{ callsmartError.statusText }}</p>
      </ng-template>
      <a [routerLink]="'/'">
         <h5>Back</h5>
      </a>
   </div>
</div>