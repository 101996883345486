import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { GoogleMapComponent } from 'app/shared/google-map/google-map.component';
import { Caller } from 'app/models/caller';
import { MapPoint } from 'app/models/map-point';
import { Callpoint } from 'app/models/callpoint';
import { ApplicationStore } from 'app/stores/application-store';
import { Event } from 'app/models/diary/event';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { CalendarComponent } from "app/shared/calendar/calendar.component";
import { ProjectSettings } from 'app/models/settings/project-settings';
import { EventTypes } from 'app/models/diary/event-types';
import { Project } from 'app/models/project';
import { AlertStore } from 'app/stores/alert-store';
import { Alert } from 'app/models/alert';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { VisitSelection } from 'app/models/visit-selection';
import { DayOvernightTypes } from 'app/models/diary/day-overnight-types';
import { WorkspaceViewType } from 'app/models/workspace-type.enum';
import { WorkspaceNavigationLogic } from 'app/shared/optimisation-logic/workspace-navigation-logic';


declare var google;  // allows google api namespace to work

@Component({
   selector: 'callsmart-schedule-workspace',
   templateUrl: './schedule-workspace.component.html'
})

// The schedule work space is used to present map and diary information to the user.
// it uses both the diary control and map control to do this.
// additional information is shown using the contextual panel, showing and hiding of this is triggered through
// events fired from the other controls
export class ScheduleWorkspaceComponent implements OnInit, OnDestroy {

   private _callpointsMapPointsSubscription: Subscription;
   private _callpointsSubscription: Subscription;
   private _diaryEventsSubscription: Subscription;
   private _selectedCallerSubscription: Subscription;
   private _selectedProjectSubscription: Subscription;
   private _visitRemovedSubscription: Subscription;
   private _visitLockedSubscription: Subscription;
   private _visitAddedSubscription: Subscription;
   private _callpointVisitsLockedSubscription: Subscription;
   private _scheduleGenerationCompletedSubscription: Subscription;
   private _callerClosedDatesSubscription: Subscription;
   private _visitMovedFailedSubscription: Subscription;
   private _selectedDiaryDayAfterSwap_subscription: Subscription;

   private map: GoogleMapComponent;
   private previousRoutePoints: MapPoint[] = [];
   private allCallpointsForCaller: Callpoint[] = [];
   private projectSettings: ProjectSettings;
   private callerProjectSettings: CallerSettings;
   private selectedCallerSettings: CallerSettings;
   private callerSettingsVm: CallerSettingsViewModel;
   private visitPointRefs: string[] = [];
   // Selected Day overnights
   private selectedDayOvernightType: DayOvernightTypes;

   public diaryEvents: Event[] = [];
   public caller: Caller;
   public eventCollection: Event[];
   public selectedDate: Date;
   public selectedVisit: VisitSelection;
   public scheduleCycleLength: number;
   public startCycleDate: Date;
   public startWorkingTime: string;
   public endWorkingTime: string;
   public activeWorkingDays: boolean[]; // Days which have to be eligible based on the caller settings
   public datesClosed: string[];
   public selectedContractedWorkingTime: Date[];
   // map properties
   public mapHeight: number = 250;
   // Flag to select a day after being notified
   public pendingDaySelectionAfterAction: boolean;
   public diaryViewTime: number = 60;
   public marginTop: number = 18;

   public workspaceHeight: number;
   public calendarMinSize: number = 300;
   private resizing: boolean = false; // Needed when resizing the workspace to workaround a bug when releasing the splitter.
   private initialising: boolean = true; // Needed when resizing the workspace to workaround a bug when releasing the splitter.

   @ViewChild('calendar') calendar;

   /*************** Hide/Show  Map variables********************/

   @ViewChild('box1') splitterComponent;
   @ViewChild('googleMapPane') googleMapPane;
   @ViewChild('hideMapButton') hideMapButton;
   @ViewChild('disabledSplitterBar') disabledSplitterBar;

   public isMapHidden: boolean = false;
   public isChangingMapVisiblity: boolean = false;
   public minMapSize: number = 0;
   public isCollapseMapButtonVisible: boolean = true;
   public clusterImagePath: string;

   private horizontalSeparator = null;
   private firstLoad: boolean = true;

   /************************************************************/

   private firstIdle: boolean = true;
   private firstTilesLoaded: boolean = true;

   constructor(private _applicationStore: ApplicationStore, private _alertStore: AlertStore,
      private windowService: BrowserWindowService, private _workspaceNavigationLogic : WorkspaceNavigationLogic) {

      this.clusterImagePath = this._applicationStore.mapsStore.clusterMarkerImagePathPurple;

      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
   }

   get optimiseMapMarkers(): boolean {
      return this._applicationStore.mapsStore.OptimiseMapMarkers;
   }

   ngOnInit() {

      this._applicationStore.uiStore.setActiveView(WorkspaceViewType.Schedule);
      this._workspaceNavigationLogic.navigatingToWorkspace(WorkspaceViewType.Schedule);

      this.horizontalSeparator = document.getElementsByTagName('horizontal-split-separator')[0];

      // The 'hide map' button located under the splitter is problematic when resizing.
      // If you are going at the right speed you'll find that instead of releasing the splitter,
      // the mouse still has hold of the splitter. This is because the cursor on release was over the up ^ arrow
      // and this prevents the drop notification from firing.
      // WORKAROUND:
      // * When user perfoms the mouse down the 'hide map' button is not displayed.
      // * When user releases the mouse (mouse up) the 'hide map' button is displayed in the right position.
      this.horizontalSeparator.onmousedown = (() => {
         this.hideMapButton.nativeElement.style.display = "none";
         this.marginTop = 0;
         this.resizing = true;
      });
      // The mouse can be released on any place inside the browser. That's the reason
      // to handle the 'mouseup' event of the document element
      document.onmouseup = (() => {
         if (this.resizing) {
            this.marginTop = 18;
            this.hideMapButton.nativeElement.style.display = "block";
            this.resizeMappingArea(this.calendar);
            this.resizing = false;
            // Internal funtion belonging to the SplitterComponent itself.
            this.splitterComponent.stopResizing();
         }
      });

      this.isMapHidden = this._applicationStore.mapsStore.isScheduleMapHidden;
      this.subscribeToSelectedProjectSettings();
      this.subscribeToDiaryEvents();
      this.subscribeToVisitRemoved();
      this.subscribeToVisitLocked();
      this.subscribeToVisitAdded();
      this.subscribeToCallpointVisitsLocked();
      this.subscribeToScheduleGenerationComplete();
      this.subscribeToSelectedCallerClosedDates();
      this.subscribeToVisitMovedFailed();
      this.subscribeToSelectedDiaryDayAfterSwapDay();
      this.caller = this._applicationStore.callersStore.selectedCaller;

      // There is no caller selected so get the first caller and set that as the selected caller.
      if(this.caller == null) {
         this._applicationStore.callersStore.setSelectedCaller(this._applicationStore.callersStore.callers[0]);
         this.caller = this._applicationStore.callersStore.selectedCaller;
      }

      this.datesClosed = this.caller.datesClosed;

      this.subscribeToSelectedCaller();
   }

   ngOnDestroy() {

      this._workspaceNavigationLogic.navigatingAwayFromActiveWorkspace(this._applicationStore.uiStore.getActiveView());

      if (this._callpointsSubscription) {
         this._callpointsSubscription.unsubscribe();
      }
      if (this._diaryEventsSubscription) {
         this._diaryEventsSubscription.unsubscribe();
      }
      if (this._selectedCallerSubscription) {
         this._selectedCallerSubscription.unsubscribe();
      }
      if (this._callpointsMapPointsSubscription) {
         this._callpointsMapPointsSubscription.unsubscribe();
      }
      if (this._selectedProjectSubscription) {
         this._selectedProjectSubscription.unsubscribe();
      }
      if (this._visitRemovedSubscription) {
         this._visitRemovedSubscription.unsubscribe();
      }
      if (this._visitLockedSubscription) {
         this._visitLockedSubscription.unsubscribe();
      }
      if (this._visitAddedSubscription) {
         this._visitAddedSubscription.unsubscribe();
      }
      if (this._callpointVisitsLockedSubscription) {
         this._callpointVisitsLockedSubscription.unsubscribe();
      }
      if (this._scheduleGenerationCompletedSubscription) {
         this._scheduleGenerationCompletedSubscription.unsubscribe();
      }
      if (this._callerClosedDatesSubscription) {
         this._callerClosedDatesSubscription.unsubscribe();
      }
      if (this._visitMovedFailedSubscription) {
         this._visitMovedFailedSubscription.unsubscribe();
      }
      if (this._selectedDiaryDayAfterSwap_subscription) {
         this._selectedDiaryDayAfterSwap_subscription.unsubscribe();
      }


   }

   public onGoogleMapIdle(event) {
      // Sets selection for the  map on load for ant selected call points
      // schedule workspace has no way to interact with the call points selection currently.
      if (this._applicationStore.callpointsStore.selectedCallpoints.length > 0 && !this.firstTilesLoaded && this.firstIdle) {

         //this.showAllCallersCallpointsOnMap();
         this.setSelectedCallpointsOnMapLoad();

      }

      if ((this._applicationStore.scheduleStore.selectedDiaryDay != null || this._applicationStore.scheduleStore.selectedDiaryEvent != null) && !this.firstTilesLoaded && this.firstIdle) {
         this.syncSelectedVisitOnCalendar();
      }

      this.firstIdle = false;
   }

   public onGoogleMapTilesLoaded(event) {
      // Sets selection for the  map on load for ant selected call points
      // schedule workspace has no way to interact with the call points selection currently.
      if (this._applicationStore.callpointsStore.selectedCallpoints.length > 0 && !this.firstIdle && this.firstTilesLoaded) {

         //this.showAllCallersCallpointsOnMap();
         this.setSelectedCallpointsOnMapLoad();

      }

      if ((this._applicationStore.scheduleStore.selectedDiaryDay != null || this._applicationStore.scheduleStore.selectedDiaryEvent != null) && !this.firstTilesLoaded && this.firstIdle) {
         this.syncSelectedVisitOnCalendar();
      }

      this.firstTilesLoaded = false;
   }

   public setMap(event) {
      this.map = event;
      this.subscribeToCallpoints();
      this.subscribeToCallpointsMapPoints();
   }

   public onGoogleMapResize(event) {

      if (this.map) {
         if (!this.isMapHidden) {
            if (this.visitPointRefs.length > 0) {
               this.getCallpointsByRef(this.visitPointRefs);
            }
         }
      }
   }

   public onHChange(event, calendar: CalendarComponent) {

      // resize the map to fit new area the user has specified with the splitter
      if (this.isChangingMapVisiblity && !this.isMapHidden) {
         this.mapHeight = this.splitterComponent.outerContainer.nativeElement.clientHeight - event.secondary;
      }
      else {
         this.mapHeight = event.primary;
      }

      if (this.map) {
         this.map.resizeMap();
      }

      // set the caledar height
      calendar.calendarHeight = event.secondary - 33;

      calendar.aspectRatio = '';
      // this point can be reached because either:
      // -. user is showing/hidding the mapping area or
      // -. user is performing a resizing action (which also includes loading this control the first time when the workspace is created).
      if (this.isChangingMapVisiblity) {
         // User is showing/hiding the mapping area
         this.showHideMappingArea(event.secondary, calendar);
      }
      else {
         if (this.initialising && this.hideMapButton) {
            this.hideMapButton.nativeElement.style.display = "block";
            this.initialising = false;
         }
         // Either user is resizing the mapping area using the splitter or
         // the splitter control is being loaded for the first time.
         this.resizeMappingArea(calendar)
      }


      if (this.isChangingMapVisiblity) {
         this.isChangingMapVisiblity = false;
      }
   }

   public onClusteringModeSelected(event) {
      // routes and clustering have not mixed well.
      // map component will remove all route components if toggling to clustering
      // listen for this and add them back
      if (event) {
         if (!this.isMapHidden) {
            this.getCallpointsByRef(this.visitPointRefs);
         }
      }
   }

   public onDirectLinesModeSelected(event) {
      this.refreshMapSettings();
   }

   public onUseVisitDotIconSelected(event) {
      this.refreshMapSettings();
   }

   public onShowFrequencySelected(event) {
      this.refreshMapSettings();
   }

   public onGoogleDirectionServiceError(event) {
      this._alertStore.sendAlert(new Alert('Error', 'Google directions service failed to generate a travel line'));
   }

   public onUseCallerDotIconSelected(event) {
      // find the caller and change icons
      if (this.caller != undefined) {

         let callerPoint = this.map.overlays.find(c => c.point.guid == this.caller.guid)

         if (this._applicationStore.mapsStore.useCallerDotIcon) {
            callerPoint.icon == this.map.mapIconCallerSelectedSmall;
            callerPoint.selectIcon == this.map.mapIconCallerSelectedSmall;
         } else {
            callerPoint.icon == this.map.mapIconCallerSelected;
            callerPoint.selectIcon == this.map.mapIconCallerSelected;
         }

         this._applicationStore.callersStore.RefreshCallersMapPointsIcons();
         this.refreshMapSettings();
      }
   }

   public onVisitSelected(visitSelection: VisitSelection) {
      this.selectedVisit = (visitSelection && visitSelection.selectedVisit && visitSelection.selectedVisit.isSelected) ? visitSelection : null;
      if (this.selectedVisit) {
         this.selectedDate = this.selectedVisit.selectedVisit.start;

         // Gets the dayIndex (gets rid of time part of a date to make the diff method work properly)
         let iniCycleDate: moment.Moment = moment(this.startCycleDate).startOf('day');
         let selectedVisitDay: moment.Moment = moment(visitSelection.selectedVisit.start).startOf('day');
         let selectedVisitStartDate: moment.Moment = moment(visitSelection.selectedVisit.start);
         let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');

         // Gets the visitIndex.
         let visitList = this.diaryEvents.filter(visit => {
            let visitStartDate: moment.Moment = moment(visit.start).startOf('day');
            return visit.eventType == EventTypes.visit && selectedVisitDay.diff(visitStartDate, 'days') === 0
         })
         let visitIndex: number = visitList.findIndex(item => {
            let itemStartDate: moment.Moment = moment(item.start);
            return itemStartDate.diff(selectedVisitStartDate) === 0;
         });
         // Asigns the dayIndex and the visitIndex.
         this.selectedVisit.dayIndex = dayIndex;
         this.selectedVisit.eventIndex = visitIndex;
         this._applicationStore.scheduleStore.setSelectedDiaryEvent(visitSelection.selectedVisit);
         // set the selected call point if none selected,
         // need to get from the product owner if they want this clear previous callpoint selection or just addto it
         if (this._applicationStore.callpointsStore.selectedCallpoints.length <= 1) {
            this._applicationStore.callpointsStore.setSelectedCallpoints(this._applicationStore.callpointsStore.callpoints.filter(c => c.reference == visitSelection.selectedVisit.callpointId));
         }
      }
      else {
         this._applicationStore.scheduleStore.setSelectedDiaryEvent(null);
      }
      this._applicationStore.scheduleStore.setSelectedVisit(this.selectedVisit);
   }

   public onDaySelected(event) {
      if (event.isSelected) {

         //get the visits refs
         this.calculateVisitRefs(event.events);

         //Work out the overnights
         this.calculateSelectedDayOvernightType(event.events);

         if (!this.isMapHidden) {
            this.getCallpointsByRef(this.visitPointRefs);
         }

         // store the selected date as state info in the store
         this.storeSelectedDateAndStateInfo(event.selectedDate, event.events)

      } else {
         // clear the routes only show the callpoints for the caller
         if (this.map) {
            this.map.clear();
            this.showAllCallersCallpointsOnMap();
            this.setSelectedCallpointsOnMapLoad();
         }

         if (!this.pendingDaySelectionAfterAction) {
            this.eventCollection = [];
            this._applicationStore.scheduleStore.setSelectedDayDiaryEvents(this.eventCollection);

            this.selectedDate = null;
            // TODO: doesn't the store need to be updated here with the new selectedDate value?

            this.selectedContractedWorkingTime = null
            this._applicationStore.scheduleStore.setSelectedContractedWorkingTime(this.selectedContractedWorkingTime);
         };

         this._applicationStore.scheduleStore.setSelectedDiaryDay(null);
      }

   }


   /*public onCallerClicked(event) {
      this.caller = event;

   }*/

   /*public onCarousalLoaded(event) {
      if (!this.caller) {
         // event is the first caller in the carousal
         this.caller = event;
         //this.RefreshMapForCaller();
      }
   }*/

   // set the selected callpoints for first use when map loads
   private setSelectedCallpointsOnMapLoad() {
      if (this._applicationStore.callpointsStore.selectedCallpoints) {

         this.map.selectedPoints = [];

         //clone the service selected callpoints
         let selected = this._applicationStore.callpointsStore.selectedCallpoints.slice();

         // map selection
         selected.forEach(point => {
            this.map.SetMapSelectionByGuid(point.guid, true);
         });

         // the row unselect method is not always called, only called when unselect check box or ctrl click on highlighted row
         //this.mapLastSyncSelection = this.callPointsList.selectedCallPoints.slice(); // clone the array

         this.map.focusMapOnSelection();

      }
   }

   private syncSelectedVisitOnCalendar() {
      if (this._applicationStore.scheduleStore.selectedDiaryEvent) {
         this.calendar.selectVisit(this._applicationStore.scheduleStore.selectedDiaryEvent, true);
         return;
      }

      if (this._applicationStore.scheduleStore.selectedDiaryDay) {
         this.calendar.selectDay(this._applicationStore.scheduleStore.selectedDiaryDay, true);
      }

   }

   private refreshMapForCaller() {
      if (this.map) {
         this.map.clear();
         this.showAllCallersCallpointsOnMap();

         this.map.resizeMap();
         this.map.focusMapOnSelection();

      }
   }

   // method to rebuild the map with different settings but keep the existing data
   private refreshMapSettings() {
      if (this.map) {
         this.map.clear();
         this.showAllCallersCallpointsOnMap();
      }

      if (!this.isMapHidden) {
         this.getCallpointsByRef(this.visitPointRefs);
      }
   }

   private buildRouteFromCallpoints(callpoints: Callpoint[], map: GoogleMapComponent) {
      let points: MapPoint[] = [];

      callpoints.forEach(c => {

         if (this._applicationStore.mapsStore.useVisitDotIcon) {
            points.push(new MapPoint(c.latitude, c.longitude, c.name, c.geocode, this.map.mapIconVisitSmall,
               this.map.mapIconVisitSmall, this.map.mapIconVisitSmall, c.guid, c.frequency.toString()));
         } else {
            points.push(new MapPoint(c.latitude, c.longitude, c.name, c.geocode, this.map.mapIconCallPointHighlighted,
               this.map.mapIconCallPointHighlighted, this.map.mapIconCallPointHighlighted, c.guid, c.frequency.toString()));
         }
      });


      if (this.caller != undefined && points.length > 0) {

         switch (this.selectedDayOvernightType) {
            case DayOvernightTypes.NoOvernights: {
               let startPoint: MapPoint = this.getStartMapPoint();
               this.map.buildRoute(startPoint, points, false, !this._applicationStore.mapsStore.useVisitDotIcon, true, false);
               break;
            }
            case DayOvernightTypes.DayEndsInOvernight: {
               let startPoint: MapPoint = this.getStartMapPoint();
               // set the overnight icon
               points[points.length - 1].icon = this.map.mapIconCallPointOverNight;
               this.map.buildRoute(startPoint, points, false, !this._applicationStore.mapsStore.useVisitDotIcon, false, false);
               break;
            }
            case DayOvernightTypes.DayStartsAndEndsWithOvernight: {
               // set the overnight icon
               points[0].icon = this.map.mapIconCallPointOverNight;
               points[points.length - 1].icon = this.map.mapIconCallPointOverNight;
               this.map.buildRouteNoComuteLines(points, !this._applicationStore.mapsStore.useVisitDotIcon, false);
               break;
            }
            case DayOvernightTypes.DayStartsWithOvernight: {
               // set the overnight icon
               points[0].icon = this.map.mapIconCallPointOverNight;
               let callerBase: MapPoint = this.getStartMapPoint();
               this.map.buildRoute(callerBase, points, false, !this._applicationStore.mapsStore.useVisitDotIcon, true, true);
               break;
            }
            default: {
               let startPoint: MapPoint = this.getStartMapPoint();
               this.map.buildRoute(startPoint, points, false, !this._applicationStore.mapsStore.useVisitDotIcon, true, false);
               break;
            }
         }

         // save the routes points for clearing and maybe later showing multiple routes
         this.previousRoutePoints = points;
      }
   }

   // the caller is the start point return the map point with the correct icons
   private getStartMapPoint() {
      let startPoint: MapPoint;
      if (this._applicationStore.mapsStore.useCallerDotIcon) {
         startPoint = new MapPoint(this.caller.latitude, this.caller.longitude, this.caller.name,
            this.caller.postcode, this.map.mapIconCallerSelectedSmall, this.map.mapIconCallerSelectedSmall,
            this.map.mapIconCallerSelectedSmall, this.caller.guid, undefined);

      } else {
         startPoint = new MapPoint(this.caller.latitude, this.caller.longitude, this.caller.name,
            this.caller.postcode, this.map.mapIconCallerSelected, this.map.mapIconCallerSelected,
            this.map.mapIconCallerSelected, this.caller.guid, undefined);
      }

      return startPoint;
   }

   private clearRoute(points: MapPoint[]) {
      if (this.map) {

         // caller is the start point
         let startPoint: MapPoint = this.getStartMapPoint();

         if (points.length > 0) {
            this.map.clearRoute(startPoint, points);

            // add the points back as standard markers
            //update the points to standard
            points.forEach(p => {
               p.icon = this.map.mapIconCallPoint;
               p.orginalIcon = this.map.mapIconCallPoint;
               p.selectIcon = this.map.mapIconCallPoint;
            });

            this.map.addMarkers(points);
            this.map.addMarkersWithoutLabel([startPoint]);

            // add in any selected callpoints
            this.setSelectedCallpointsOnMapLoad();
            //this.map.enableClustering();
         }
      }
   }

   private showAllCallersCallpointsOnMap() {

      if (this.map) {
         let points: MapPoint[] = [];

         points = this._applicationStore.callpointsStore.callpointsMapPoints;

         if (this.caller != undefined) {
            // caller is the start point
            let startPoint: MapPoint = this.getStartMapPoint();

            this.map.addMarkersWithoutLabel([startPoint]);
         }

         this.map.addMarkers(points);
      }
   }

   // get a sub set of the call points based on the call point references
   getCallpointsByRef(refs: string[]) {

      let routeCallpoints: Callpoint[] = [];

      // get a sub set of the users call points, based on the callpoints the user is visiting
      refs.forEach(ref => {
         let matches = this.allCallpointsForCaller.filter(c => c.reference === ref);
         if (matches.length > 0) {
            routeCallpoints.push(matches[0]);
         }
      });

      if (this.previousRoutePoints.length > 0) {
         this.clearRoute(this.previousRoutePoints);
      }

      this.buildRouteFromCallpoints(routeCallpoints, this.map);


   }

   AddCallerToMap(caller: Caller) {
      if (caller) {

         let startPoint: MapPoint = this.getStartMapPoint();

         this.map.addMarkersWithoutLabel([startPoint]);
         this.map.removePointFromCluster(startPoint);
      }
   }


   private subscribeToCallpoints() {
      this._callpointsSubscription = this._applicationStore.callpointsStore.callpoints$.subscribe(
         (callpoints: Callpoint[]) => {
            this.allCallpointsForCaller = callpoints;
            //this.refreshMapForCaller();
         });
   }

   private subscribeToCallpointsMapPoints() {
      this._callpointsMapPointsSubscription = this._applicationStore.callpointsStore.callpointsMapPoints$.subscribe(
         (callpointsMapPoints: MapPoint[]) => {
            // Allow the map to draw first before plotting the points on it.
            setTimeout(() => this.refreshMapForCaller(), 100);
         });
   }

   private subscribeToDiaryEvents() {
      this._diaryEventsSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (events: Event[]) => {
            this.diaryEvents = events;
            if (this._applicationStore.scheduleStore.selectedDiaryDayIndex === -1) {
               this.selectedDate = null;
               // Clear any markers and route lines.
               this.clearMap();
            }
            else {
               this.pendingDaySelectionAfterAction = true;
               // The schedule has been cleared before carrying out a single day optimisation
               if (events && events.length === 0) {
                  this.clearRoute(this.previousRoutePoints);
               }
            }
         });
   }

   private subscribeToVisitRemoved() {
      this._visitRemovedSubscription = this._applicationStore.scheduleStore.visitRemoved.subscribe((date: Date) => {
         this.selectedDate = date;
         // A visit has been removed... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionAfterAction = true;
      });
   }

   private subscribeToVisitLocked() {
      this._visitLockedSubscription = this._applicationStore.scheduleStore.visitLocked.subscribe((date: Date) => {
         this.selectedDate = date;
         // A visit has been locked... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionAfterAction = true;
      });
   }

   private subscribeToVisitAdded() {
      this._visitAddedSubscription = this._applicationStore.scheduleStore.visitAdded.subscribe((date: Date) => {
         this.selectedDate = date;
         // A visit has been added... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionAfterAction = true;
         this.calendar.selectDay(this.selectedDate, true);
      });
   }

   private subscribeToCallpointVisitsLocked() {
      this._callpointVisitsLockedSubscription = this._applicationStore.scheduleStore.callpointVisitsLocked.subscribe(() => {
         // selected callpoint visits have been locked... The day for that visit must be selected after the calendar renders itself.
         this.pendingDaySelectionAfterAction = true;
      });
   }

   private subscribeToSelectedCallerClosedDates() {
      this._callerClosedDatesSubscription = this._applicationStore.callersStore._callerClosedDatesChanged$.subscribe(() => {
         // The selected caller datesClosed property has changed
         this.datesClosed = this.caller.datesClosed;
      });
   }

   private subscribeToVisitMovedFailed() {
      this._visitMovedFailedSubscription = this._applicationStore.scheduleStore.visitMovedFailed.subscribe(() => {
         if (this.calendar) {
            this.calendar.draggingRevertFunction();
            this.calendar.draggingRevertFunction = null;
         }
      });
   }

   private subscribeToSelectedDiaryDayAfterSwapDay() {
      this._selectedDiaryDayAfterSwap_subscription = this._applicationStore.scheduleStore._selectedDiaryDayAfterSwap$.subscribe(
         (selectedDate: Date) => {
            this.selectedDate = selectedDate;
            // A visit has been added... The day for that visit must be selected after the calendar renders itself.
            this.pendingDaySelectionAfterAction = true;
            // Select the day again.
            this.calendar.selectDay(this.selectedDate, true);
         }
      );
   }

   public onCalendarRendered(event) {
      if (this.pendingDaySelectionAfterAction) {
         // The day for the removed visit have to keep the selection after the renderisation of the calendare
         if (this.diaryEvents && this.selectedDate) {
            let selectedVisit: Event = this.diaryEvents.find(visit => visit.start.getTime() == this.selectedDate.getTime())
            if (selectedVisit) {
               this.calendar.selectVisit(selectedVisit, true);
               this._applicationStore.scheduleStore.setSelectedDiaryEvent(selectedVisit);
            }
            else {
               this.calendar.selectDay(this.selectedDate, true);
            }
         }
         this.pendingDaySelectionAfterAction = false;
      }
   }

   public onInternalVisitDropped(event) {
      if (event) {
         let originalDayIndex: number = this.getIndexDayInCycle(event.originalDate);
         let originalVisitIndex: number = this.getIndexVisitInCycle(event.originalDate)
         let droppedDayIndex: number = this.getIndexDayInCycle(event.droppedDate);

         let callpoint: Callpoint = this._applicationStore.callpointsStore.callpoints.find(callpoint => callpoint.reference == event.callpointReference)
         if (callpoint) {
            this._applicationStore.scheduleStore.moveVisitInSchedule(this.caller.callerId,
               callpoint.callpointId,
               originalDayIndex,
               originalVisitIndex,
               droppedDayIndex,
               event.droppedDate,
               this._applicationStore.uiStore.getActiveView());
         }
      }
   }

   // Resize the the workspace elements when showing/hiding the calendar grid
   public onShowCalendarHeadersOnly(event: any) {

      this.calendarMinSize = this.calendar.isFullCalendarMaximised ? 400 : 115;
      this.splitterComponent.dividerPosition(this.workspaceHeight - this.calendarMinSize);
      let availableWorkspaceHeight = this.workspaceHeight + 74.5 + 50;
      this.resizeWorkspace(availableWorkspaceHeight)

      // Calendar is fully visible
      if (this.calendar.isFullCalendarMaximised) {
         this.calendar.setScrollWithFixHeight(this.calendar.calendarHeight - 30);

         // Shows the standard splitter component bar.
         this.splitterComponent.outerContainer.nativeElement.children[1].style.display = ''
         // Hides the disabled splitter bar
         if (this.disabledSplitterBar) {
            this.disabledSplitterBar.nativeElement.style.top = 0 + "px";
            this.disabledSplitterBar.nativeElement.style.display = 'none';
         }
         // Restore the visibility of the button to hide the map
         if (this.hideMapButton) {
            this.hideMapButton.nativeElement.style.display = "block";
         }
      }
      // Only calendar headers are visible
      else {
         if (this.disabledSplitterBar) {
            // Make the disable splitterbar visible and place it in the right position.
            this.disabledSplitterBar.nativeElement.style.display = 'inherit';
            this.disabledSplitterBar.nativeElement.style.top = (this.horizontalSeparator.offsetTop + 18) + 'px';
         }

         if (this.hideMapButton) {
            this.hideMapButton.nativeElement.style.display = "none";
         }
         // Hides the standard splitter component bar.
         this.splitterComponent.outerContainer.nativeElement.children[1].style.display = 'none'
      }
   }

   private subscribeToSelectedCaller() {

      this._selectedCallerSubscription = this._applicationStore.callersStore.selectedCaller$.subscribe(
         (caller: Caller) => {
            if(caller) {
               // clear any visit refs as they are no longer valid for the caller
               this.visitPointRefs = [];

               let previousSelectedCaller = this.caller;
               // set the new caller
               this.caller = caller;
               this.selectedCallerSettings = caller.callerSettings;
               this.datesClosed = this.caller.datesClosed;

               if (this.callerProjectSettings) {
                  this.callerSettingsVm = new CallerSettingsViewModel(this.callerProjectSettings, this.selectedCallerSettings ? this.selectedCallerSettings : null, this.caller);
               }
               // Sets the start/end working time
               if (this.callerSettingsVm) {
                  if (this.callerSettingsVm.sameWorkingHoursAllDays) {
                     this.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(true, this.callerSettingsVm);
                     this.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(false, this.callerSettingsVm);
                  }
                  else {
                     // Deal with multiple hours per day. This is a bit of a cheat, take hours for each day and create a pipe
                     // delimited string that can be passed to the calendar component, the calendar component will then unpack this.
                     let startTime = '';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursMonday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursTuesday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursWednesday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursThursday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursFriday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursSaturday) + '|';
                     startTime += CallsmartUtils.getWorkingTime(true, this.callerSettingsVm.contractedWorkingHoursSunday);
                     this.startWorkingTime = startTime;

                     let endTime = '';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursMonday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursTuesday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursWednesday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursThursday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursFriday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursSaturday) + '|';
                     endTime += CallsmartUtils.getWorkingTime(false, this.callerSettingsVm.contractedWorkingHoursSunday);
                     this.endWorkingTime = endTime;

                  }
                  this.activeWorkingDays = this.callerSettingsVm.workingDayActive;
               }

               // When a different caller is selected in the carousel, the selected day selection needs to be kept, but when the new
               // diary of events is loaded the selection is lost, this code reselects the day again.
               if (this._applicationStore.scheduleStore.selectedDiaryDay) {
                  setTimeout(() => {
                     this.calendar.reselectSameDay(this._applicationStore.scheduleStore.selectedDiaryDay,
                        this.caller != previousSelectedCaller ? true : false);
                     // there is a previous interaction with the diary so the selectedVisit property has been set
                     if (this._applicationStore.scheduleStore.selectedVisit) {
                        this.calendar.selectVisit(this._applicationStore.scheduleStore.selectedVisit.selectedVisit, true);
                     }
                     else {
                        // check selectedDiaryEvent from schedule store
                        // seleccionar la visita correspondiente
                        this.calendar.selectVisit(this._applicationStore.scheduleStore.selectedDiaryEvent, true);
                     }

                  }, 1000);
               }
            }
         });

   }

   private subscribeToSelectedProjectSettings(): void {
      this._selectedProjectSubscription = this._applicationStore.projectsStore.selectedProject$.subscribe(
         (project: Project) => {
            if (project) {
               this.projectSettings = project.projectSettings;
               this.callerProjectSettings = project.callerSettings;

               if (this.callerProjectSettings) {
                  this.callerSettingsVm = new CallerSettingsViewModel(this.callerProjectSettings, this.selectedCallerSettings ? this.selectedCallerSettings : null, this.caller);
               }
               // Sets the start/end working time
               if (this.callerSettingsVm) {
                  this.startWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(true, this.callerSettingsVm);
                  this.endWorkingTime = CallsmartUtils.getWorkingTimeFromCallerSettingsViewModel(false, this.callerSettingsVm);
                  this.activeWorkingDays = this.callerSettingsVm.workingDayActive;
               }
               this.scheduleCycleLength = project.projectSettings.callCycleLength;
               this.diaryViewTime = project.projectSettings.diaryViewTime;
               this.startCycleDate = new Date(project.scheduleStartDate);
            }
         }
      );
   }

   // Changes the visibility of the Map.
   private displayMap() {
      this.isMapHidden = !this.isMapHidden;
      this._applicationStore.mapsStore.isScheduleMapHidden = this.isMapHidden;
      this.isChangingMapVisiblity = true;

      this.calendar.showHeaderOnlyOptionDisabled = this.isMapHidden;
   }

   // Adjust the height of the map and data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 74.5 - 50;
      if (this.splitterComponent) {

         // WORK-AROUND to resize workspace when pressing F11... SplitterComponent doesn't resize the height values properly
         // Those values need to be recalculated.
         let cssUpperPartHeight = this.splitterComponent.primaryComponent.nativeElement.style.height;
         if (cssUpperPartHeight.indexOf("%") !== -1) {
            let percentageUpperPartHeight = cssUpperPartHeight.substr(0, cssUpperPartHeight.length - 1);
            this.mapHeight = this.workspaceHeight * percentageUpperPartHeight / 100;

            // 7px is the height of splitter bar
            // 33px is the height of the carrousel which is not part of the Splitter component
            let secondaryClientHeight = this.workspaceHeight - this.mapHeight - 7 - 33;
            this.calendar.calendarHeight = secondaryClientHeight;
            if (this.hideMapButton) {
               // 7px is the height of splitter bar
               // 18px is the height of the 'hide map' button itself.
               // (Added this height to be places under the splitter bar, not above).
               // 1px 'margin top' of the 'hide map' button
               this.hideMapButton.nativeElement.style.top = (this.mapHeight + 18 + 7 + 1) + "px";
            }
            if (!this.calendar.isFullCalendarMaximised) {
               //this.splitterComponent.dividerPosition(this.workspaceHeight - this.calendarMinSize);
               this.disabledSplitterBar.nativeElement.style.top = (this.workspaceHeight - secondaryClientHeight + 7) + 'px';
            }
         }
         else {
            let secondaryClientHeight = this.splitterComponent.outerContainer.nativeElement.lastElementChild.clientHeight;
            this.calendar.calendarHeight = secondaryClientHeight - 33;
            this.mapHeight = cssUpperPartHeight;
         }
         if (!this.isMapHidden) {
            setTimeout(() => this.map.resizeMap(), 200);
         }
      }
   }

   // Collapses/Displays the mapping area.
   private showHideMappingArea(secondaryHeight: number, calendar: CalendarComponent) {
      // The collapse map button visibility changes.
      this.isCollapseMapButtonVisible = !this.isMapHidden;
      // Map is hidden.
      if (this.isMapHidden) {
         this.marginTop = 0;
         // The hide map button is also hidden.
         setTimeout(() => calendar.setScrollWithFixHeight(calendar.calendarHeight - 30), 200);
      }
      // Map is visible.
      else {
         this.marginTop = 18;
         // This setTimeout function is performed when the splitter becomes visible
         // since the "hideMapButton" element is still null... so need some time to be rendered
         setTimeout(() => {
            this.hideMapButton.nativeElement.style.display = "block"
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 18) + "px";
         }, 250);
         setTimeout(() => {
            this.refreshMapForCaller();
            //this.map.resizeMap();
         }, 500);
         setTimeout(() => calendar.setScrollWithFixHeight(calendar.calendarHeight - 30), 500);
      }
   }

   // Resizes the mapping area using the splitter.
   private resizeMappingArea(calendar: CalendarComponent) {
      calendar.setScrollWithFixHeight(calendar.calendarHeight - 30);
      if (this.hideMapButton) {
         //this.hideMapButton.nativeElement.style.display = "block"
         //this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 18) + "px";
         // User is using the splitter to resize the workspace
         if (this.resizing) {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 36) + "px";
         }
         else {
            this.hideMapButton.nativeElement.style.top = (this.horizontalSeparator.offsetTop - 44 + 18) + "px";
         }
      }
      if (this.firstLoad) {
         setTimeout(() => {
            if (this.map) {
               this.map.resizeMap();
            }
         }, 500);
         this.firstLoad = false;
      }
   }

   private errorHandler(error) {
      // console.log(error);
      this._alertStore.sendAlert(new Alert('Error', error));
   }

   // When the day optimisation completes, the schedule map needs to be updated to reflect the change
   // in the order of the visits. This code here simulates the day click event on the calendar to refresh
   // the map.
   private subscribeToScheduleGenerationComplete() {
      this._scheduleGenerationCompletedSubscription = this._applicationStore.scheduleStore.diaryEvents$.subscribe(
         (diaryEvents: Event[]) => {
            if (diaryEvents.length > 0) {
               let selectedDiaryDate = this.selectedDate ? this.selectedDate : this._applicationStore.scheduleStore.selectedDiaryDay;
               if (selectedDiaryDate) {
                  let endDate: Date = new Date(selectedDiaryDate);
                  endDate.setDate(selectedDiaryDate.getDate() + 1)
                  let filteredEvents = diaryEvents.filter(item => item.start >= selectedDiaryDate && item.start <= endDate)
                  this.onDaySelected({
                     events: filteredEvents,
                     isSelected: filteredEvents.length > 0,
                     selectedDate: selectedDiaryDate ? selectedDiaryDate : null
                  });
               }
            }
         }
      );
   }

   // go through a list of events and pull out the visit refs into a gloabal variable
   private calculateVisitRefs(events: Event[]) {
      //get the visits
      let visits = events.filter(item => item.eventType == EventTypes.visit);
      // get the call points for the visits
      this.visitPointRefs = [];
      visits.forEach(v => {
         this.visitPointRefs.push(v.callpointId)
      });

   }

   // work out if the list of events has an overnights this is needed for building route lines
   private calculateSelectedDayOvernightType(events: Event[]) {
      let firstEvent = events[0];
      let lastEvent = events[events.length - 1];
      if (firstEvent && lastEvent) {
         if (!((firstEvent.eventType == EventTypes.overnight) || firstEvent.overnight) && !(lastEvent.eventType == EventTypes.overnight)) {
            this.selectedDayOvernightType = DayOvernightTypes.NoOvernights;
            return
         }

         if (!((firstEvent.eventType == EventTypes.overnight) || firstEvent.overnight) && (lastEvent.eventType == EventTypes.overnight)) {
            this.selectedDayOvernightType = DayOvernightTypes.DayEndsInOvernight;
            return
         }

         if (((firstEvent.eventType == EventTypes.overnight) || firstEvent.overnight) && !(lastEvent.eventType == EventTypes.overnight)) {
            this.selectedDayOvernightType = DayOvernightTypes.DayStartsWithOvernight;
            return
         }

         if (((firstEvent.eventType == EventTypes.overnight) || firstEvent.overnight) && (lastEvent.eventType == EventTypes.overnight)) {
            this.selectedDayOvernightType = DayOvernightTypes.DayStartsAndEndsWithOvernight;
            return
         }
      }
   }

   private storeSelectedDateAndStateInfo(selectedDate, events: Event[]) {
      this.eventCollection = events.slice();
      this._applicationStore.scheduleStore.setSelectedDayDiaryEvents(this.eventCollection);

      this.selectedDate = selectedDate;

      // store the selected date as state info in the store
      this._applicationStore.scheduleStore.setSelectedDiaryDay(this.selectedDate);

      if (this.callerSettingsVm.sameWorkingHoursAllDays) {
         this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursWeek;
      }
      else {
         let dayOfWeek: number = moment(this.selectedDate.toISOString()).day();
         switch (dayOfWeek) {
            case 0:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursSunday;
               break;
            case 1:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursMonday;
               break;
            case 2:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursTuesday;
               break;
            case 3:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursWednesday;
               break;
            case 4:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursThursday;
               break;
            case 5:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursFriday;
               break;
            case 6:
               this.selectedContractedWorkingTime = this.callerSettingsVm.contractedWorkingHoursSaturday;
               break;
         }
      }
      this._applicationStore.scheduleStore.setSelectedContractedWorkingTime(this.selectedContractedWorkingTime);
   }

   private getIndexDayInCycle(visitDate: Date): number {
      let iniCycleDate: moment.Moment = moment(this.startCycleDate).startOf('day');
      let selectedVisitDay: moment.Moment = moment(visitDate).startOf('day');
      let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');
      return dayIndex;
   }

   private getIndexVisitInCycle(visitStartDate: Date): number {
      let selectedVisitDay: moment.Moment = moment(visitStartDate).startOf('day');
      let selectedVisitStartDate: moment.Moment = moment(visitStartDate);

      // Gets the visitIndex.
      let visitList = this.diaryEvents.filter(visit => {
         let visitStartDate: moment.Moment = moment(visit.start).startOf('day');
         return visit.eventType == EventTypes.visit && selectedVisitDay.diff(visitStartDate, 'days') === 0
      })
      let visitIndex: number = visitList.findIndex(item => {
         let itemStartDate: moment.Moment = moment(item.start);
         return itemStartDate.diff(selectedVisitStartDate) === 0;
      });
      return visitIndex
   }

   private clearMap() {
      if (this.map) {
         this.map.clear();
      }
   }

}
