﻿import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Response } from '@angular/http';
import { Observable, Subscription } from 'rxjs';

import { DbAdminModel } from '../models/DbAdminModel';
import { ProjectService } from "app/services/project.service";
import { SelectItem } from "primeng/components/common/selectitem";
import { Project } from "app/models/project";
import { ApplicationStore } from "app/stores/application-store";


// A configuration page that hosts functions for clearing and recreating the database.
// TODO:
// Refactor duplicate code into separate methods.
// When the DB is uninitialized display a message to that effect instead of just an empty table.
// Error handling.
@Component({
   selector: 'sys-admin-workspace',
   templateUrl: './sys-admin-workspace.component.html',
   providers: [DbAdminModel]
})
export class SysAdminWorkspaceComponent implements OnInit, OnDestroy {

   public cachedSchedules: number;

   get optimiseMapMarkers(): boolean {
      return this._applicationStore.mapsStore.OptimiseMapMarkers;
   }
   set optimiseMapMarkers(theCaller: boolean) {
      this._applicationStore.mapsStore.OptimiseMapMarkers = theCaller;
   }

   constructor(public _dbAdminModel: DbAdminModel,
               private _cdr: ChangeDetectorRef,
               private _applicationStore: ApplicationStore) { }

   public ngOnInit() {
      this.getServerStatusReport();
      this.getDbStatusReport();
      this.cachedSchedules = this._applicationStore.scheduleStore.maxNumberOfCachedDiaries;
   }

   public ngOnDestroy() {

   }

   public uiDisabled: boolean = true;

   public serverStatusReport: any;

   public dbStatusReport: Object[];

   public onGetStatusReport(): void {
      this.getServerStatusReport();
      this.getDbStatusReport();
   }

   public onInitDb(): void {
      this.uiDisabled = true;
      this._cdr.detectChanges();
      this.initDb();
   }

   public onPopulateDb(): void {
      this.uiDisabled = true;
      this._cdr.detectChanges();
      this.populateDb();
   }

   public onResetDb(): void {
      this.uiDisabled = true;
      this._cdr.detectChanges();
      this.resetDb();
   }

   public onTest404Error(): void {
      this.test404Error();
   }

   public onTestException(): void {
      this.testException();
   }

   public onTestMultipleSchedules(): void {
      this.generateMultipleSchedules();
   }

   public onChangeMaxCachedSchedules(): void {
      this._applicationStore.scheduleStore.maxNumberOfCachedDiaries = this.cachedSchedules;
   }

   public onLoadAllCallers(): void {
      this._applicationStore.callersStore.loadAllCallers(this._applicationStore.projectsStore.selectedProject.projectId);
   }

   private getServerStatusReport(): void {
      let response: Observable<any> = this._dbAdminModel.serverStatusReport();
      let observer = {
         next: (n: any) => {
            this.serverStatusReport = n;
            this._cdr.detectChanges();
         }
      }
      response.subscribe(observer);
   }

   private getDbStatusReport(): void {
      let response: Observable<Object[]> = this._dbAdminModel.dbStatusReport();
      let observer = {
         //next: this.getStatusReportCallback  // TODO: Why doesn't this work?
         next: (n: Object[]) => {
            this.dbStatusReport = n;
            this.uiDisabled = false;
            this._cdr.detectChanges();
         }
      };
      response.subscribe(observer);
   }

   private getStatusReportCallback(report: Object[]): void {
      this.dbStatusReport = report;
      this.uiDisabled = false;
      this._cdr.detectChanges();
   }

   private test404Error(): void {
      this._dbAdminModel.test404Error();
   }

   private testException(): void {
      this._dbAdminModel.testException();
   }

   private generateMultipleSchedules(): void {
      this._dbAdminModel.testGenerateMultipleSchedules();
   }

   private initDb(): void {
      // TODO: I doubt this is the best way to call a void observable.
      // TODO: Error handling.
      let response: Observable<Response> = this._dbAdminModel.initDb();
      let observer = {
         next: (n: Response) => { this.getDbStatusReport(); }
      };
      response.subscribe(observer);
   }

   private populateDb(): void {
      let response: Observable<Response> = this._dbAdminModel.populateDb();
      let observer = {
         next: (n: Response) => { this.getDbStatusReport(); }
      };
      response.subscribe(observer);
   }

   private resetDb(): void {
      let response: Observable<Response> = this._dbAdminModel.resetDb();
      let observer = {
         next: (n: Response) => { this.getDbStatusReport(); }
      };
      response.subscribe(observer);
   }
}
