import { Pipe, PipeTransform } from '@angular/core';
import { CallsmartUtils } from 'app/shared/callsmart-utils';

// Use moment date to get date formatting that works in all browsers. 

@Pipe({ name: 'mDate' })
export class MomentDate implements PipeTransform {
    transform(value: any, format: string = ""): string {
        return CallsmartUtils.formatDate(value, format);
    }
}
