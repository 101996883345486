import { JsonCustomConvert, JsonConverter, Any } from 'json2typescript';

/**
 * Custom converter used by the json2typescript mapping library to
 * map null values to undefined. See caller-settings.ts for usage example.
 */
@JsonConverter
export class NullConverter implements JsonCustomConvert<Any> {
   serialize(value: Any): any {
       return value;
   }
   deserialize(value: any): Any {
       if(value === null) {
          return undefined;
       }
       else {
          return value;
       }
   }
}