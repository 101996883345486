<h6 *ngIf="projectSetting == null">Loading...</h6>
<div *ngIf="projectSetting">
   <form #projectSettingsForm="ngForm" class="form-horizontal" autocomplete="off">
   <div class="panel">
      <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
         <perfect-scrollbar [ngStyle]="{'height.px': scrollHeight}">
            <div *ngIf="displayHeading">
               <h3>Settings defaults</h3>
            </div>

            <div class="cs-setting-panel" *ngIf="displayProjectSpecificProperties">
               <div class="panel-body">
                  <h6>Project name</h6>
                  <div class="form-group">
                     <div class="col-sm-5">
                        <input required [(ngModel)]="projectSetting.name" name="project_name" #project_name="ngModel" class="form-control cs-form-control">
                        <span *ngIf="project_name.touched && project_name.errors">
                           <label *ngIf="project_name.errors.required">Please insert project name</label>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel" *ngIf="displayProjectSpecificProperties">
               <div class="panel-body">
                  <h6>Folder</h6>
                  <div class="form-group">
                     <div class="col-sm-5">
                        <callsmart-dropdown name="projectFolders" #projectFolders="ngModel" [options]="projectFoldersList" [(ngModel)]="selectedFolder" [style]="{'width':'100%'}"
                           (onChange)="folderChanged()">
                        </callsmart-dropdown>
                     </div>
                     <a class="col-sm-5 white-link-underline control-link " (click)="createFolder()"> Create new folder</a>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel" *ngIf="displayProjectSpecificProperties">
               <div class="panel-body">
                  <h6>Call cycle length</h6>
                  <div class="form-group">
                     <label for="numberOfWeeksInput" class="col-sm-1 control-label"> Number of weeks:</label>
                     <div class="col-sm-1">
                        <input required [(ngModel)]="projectSetting.callCycleLength" type="number" pattern="^[1-4][0-9]?$|^5[0-2]$|^[5-9]$" class="form-control cs-form-control"
                           disabled style="width: 50px" name="numberOfWeeksInput" #numberOfWeeksInput="ngModel">
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel" *ngIf="displayProjectSpecificProperties">
               <div class="panel-body">
                  <h6>Call cycle start date</h6>
                  <div class="form-group">
                     <div class="col-sm-1">
                        <input required [(ngModel)]="projectSetting.scheduleStartDate" class="form-control cs-form-control" size="1" name="callCycleStartDateInput" #callCycleStartDateInput="ngModel"
                           style="width: 110px" disabled>
                     </div>
                     <div class="col-sm-2">
                        <label for="callCycleStartDateInput" class="control-label">
                           <b>To:</b> {{projectSetting.scheduleEndDate}}</label>
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel" *ngIf="displayCallCycleLength">
               <div class="panel-body">
                  <h6>Call cycle length</h6>
                  <p>Number of weeks in the call cycle.</p>
                  <div class="form-group">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:15px">
                        <input required [(ngModel)]="projectSetting.callCycleLength" type="number"
                               pattern="^[1-4][0-9]?$|^5[0-2]$|^[5-9]$" class="form-control cs-form-control"
                               style="width: 50px" name="callCycleLengthInput" #callCycleLengthInput="ngModel"
                               (input)="onCallCycleLengthInputValueChanged($event);"
                               onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <label for="callCycleLength" class="control-label" style="padding: 0px; margin-left:10px">Weeks</label>
                     </div>
                     <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:15px">
                        <span *ngIf="callCycleLengthInput.touched && callCycleLengthInput.errors">
                           <label *ngIf="callCycleLengthInput.errors.required">Please insert number of weeks</label>
                           <label *ngIf="callCycleLengthInput.errors.pattern">Please insert a value between 1 and 52</label>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Optimiser iterations</h6>
                  <p>Number of iterations the optimiser will run through. Higher numbers could provide more optimised results,
                     but will increase the optimisation time.</p>
                  <div class="form-group row">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:15px">
                        <label for="quickOptimiserIterationInput" class="control-label" style="padding: 0px; margin-right:10px">Fast</label>
                        <input required [(ngModel)]="projectSetting.quickOptimiserIteration" type="number"
                               pattern="^[1-9][0-9]?$|^100$" class="form-control cs-form-control"
                               style="width: 50px" name="quickOptimiserIterationInput" #quickOptimiserIterationInput="ngModel"
                               (input)="onQuickOptimiserIterationInputValueChanged($event);"
                               onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <label for="fullOptimiserIterationInput" class="control-label" style="padding: 0px; margin-right:10px;margin-left:20px">Full</label>
                        <input required [(ngModel)]="projectSetting.fullOptimiserIteration" type="number"
                               pattern="^[1-9][0-9]?$|^100$" class="form-control cs-form-control"
                               style="width: 50px" name="fullOptimiserIterationInput" #fullOptimiserIterationInput="ngModel"
                               (input)="onFullOptimiserIterationInputValueChanged($event);"
                               onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:15px">
                           <span *ngIf="quickOptimiserIterationInput.touched && quickOptimiserIterationInput.errors">
                              <label *ngIf="quickOptimiserIterationInput.errors.required">Please insert a Fast iterations value</label>
                              <label *ngIf="quickOptimiserIterationInput.errors.pattern">Please insert a Fast iterations value between 1 and 100</label>
                           </span>
                           <span *ngIf="fullOptimiserIterationInput.touched && fullOptimiserIterationInput.errors">
                              <label *ngIf="fullOptimiserIterationInput.errors.required">Please insert Full Iterations  value</label>
                              <label *ngIf="fullOptimiserIterationInput.errors.pattern">Please insert a Full iterations value between 1 and 100</label>
                           </span>
                           <label *ngIf="(quickOptimiserIterationInput.touched || fullOptimiserIterationInput.touched) && !isOptimiserIterationValuesValid && isQuickOptimiserIterationFormatValid && isFullOptimiserIterationFormatValid"
                                   style="padding: 0px;margin-bottom: 0px">
                                 Please insert a Fast Iteration value lower than the Full Iteration value
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Optimiser driving configuration</h6>
                  <div class="form-group row">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:15px">
                        <div class="panel-body form-group" style="padding-bottom: 0px;">
                           <button type="button" (click)="onMinTotalDrivingClicked()"
                              [ngClass]="{'active': !projectSetting.minTotalDrivingSelected}" class="btn cs-btn-tertiary">Least total driving</button>
                           <button type="button" (click)="onMinDrivingBetweenVisitsClicked()"
                              [ngClass]="{'active': !projectSetting.minDrivingBetweenVisitsSelected}" class="btn cs-btn-tertiary">Least driving between visits</button>
                           <button type="button" (click)="onoptimiserDrivingConfigCustomClicked()"
                              [ngClass]="{'active': !projectSetting.optimiserDrivingConfigCustomSelected}" class="btn cs-btn-tertiary">Custom</button>
                           
                        </div>
                     </div>
                  </div>
                  <p>Driving between visits</p>
                  <div class="form-group row">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:50px">
                           <label for="idowhShift1Input" class="control-label" style="padding: 0px; margin-right:10px">Fixed by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhShift1" type="number"
                              pattern="^(\d{0,9})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhShift1Input" #idowhShift1Input="ngModel"
                              (input)="onIdowShift1InputChanged($event);"
                              >
                           
                           <label for="idowhShift2Input" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Varying by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhShift2" type="number"
                              pattern="^(\d{0,9})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhShift2Input" #idowhShift2Input="ngModel"
                              (input)="onIdowShift2InputChanged($event);"
                              >

                           <label for="iterationWeightWHInput" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Weighting</label>
                           <input required [(ngModel)]="projectSetting.iterationWeightWH" type="number"
                              pattern="^(\d{0,9})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="iterationWeightWHInput" #iterationWeightWHInput="ngModel"
                              (input)="onIterationWeightWHInputChanged($event);"
                              >
                           
                     </div>
                     <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:50px">
                        <span *ngIf="idowhShift1Input.touched && idowhShift1Input.errors">
                           <label *ngIf="idowhShift1Input.errors.required">Please insert a Fixed by iteration value</label>
                           <label *ngIf="idowhShift1Input.errors.pattern">Please insert a Fixed by iteration value that is positive and 1 decimal place</label>
                        </span>
                        <span *ngIf="idowhShift2Input.touched && idowhShift2Input.errors">
                           <label *ngIf="idowhShift2Input.errors.required">Please insert Varying by iteration  value</label>
                           <label *ngIf="idowhShift2Input.errors.pattern">Please insert a Varying by iteration value that is positive and 1 decimal place</label>
                        </span>
                        <span *ngIf="iterationWeightWHInput.touched && iterationWeightWHInput.errors">
                           <label *ngIf="iterationWeightWHInput.errors.required">Please insert Weighting value</label>
                           <label *ngIf="iterationWeightWHInput.errors.pattern">Please insert a Weighting value that is positive and 1 decimal place</label>
                        </span>
                     </div>
                  </div>

                  <p>Commute to first visit</p>
                  <div class="form-group row">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:50px">
                           <label for="idowhCommuteAM1Input" class="control-label" style="padding: 0px; margin-right:10px">Fixed by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhCommuteAM1" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhCommuteAM1Input" #idowhCommuteAM1Input="ngModel"
                              (input)="onIdowhCommuteAM1InputChanged($event);"
                              >
                           
                           <label for="idowhCommuteAM2Input" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Varying by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhCommuteAM2" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhCommuteAM2Input" #idowhCommuteAM2Input="ngModel"
                              (input)="onIdowhCommuteAM2InputChanged($event);"
                              >

                           <label for="iterationWeightAMInput" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Weighting</label>
                           <input required [(ngModel)]="projectSetting.iterationWeightAM" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="iterationWeightAMInput" #iterationWeightAMInput="ngModel"
                              (input)="onIterationWeightAMInputChanged($event);"
                              >
                           
                     </div>
                     <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:50px">
                        <span *ngIf="idowhCommuteAM1Input.touched && idowhCommuteAM1Input.errors">
                           <label *ngIf="idowhCommuteAM1Input.errors.required">Please insert a Fixed by iteration value</label>
                           <label *ngIf="idowhCommuteAM1Input.errors.pattern">Please insert a Fixed by iteration value that is postive and 1 decimal place</label>
                        </span>
                        <span *ngIf="idowhCommuteAM2Input.touched && idowhCommuteAM2Input.errors">
                           <label *ngIf="idowhCommuteAM2Input.errors.required">Please insert Varying by iteration  value</label>
                           <label *ngIf="idowhCommuteAM2Input.errors.pattern">Please insert a Varying by iteration value that is postive and 1 decimal place</label>
                        </span>
                        <span *ngIf="iterationWeightAMInput.touched && iterationWeightAMInput.errors">
                           <label *ngIf="iterationWeightAMInput.errors.required">Please insert Weighting value</label>
                           <label *ngIf="iterationWeightAMInput.errors.pattern">Please insert a Weighting value that is postive and 1 decimal place</label>
                        </span>
                     </div>
                  </div>

                  <p>Commute from last visit</p>
                  <div class="form-group row">
                     <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:50px">
                           <label for="idowhCommutePM1Input" class="control-label" style="padding: 0px; margin-right:10px">Fixed by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhCommutePM1" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhCommutePM1Input" #idowhCommutePM1Input="ngModel"
                              (input)="onIdowhCommutePM1InputChanged($event);"
                              >
                           
                           <label for="idowhCommutePM2Input" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Varying by iteration</label>
                           <input required [(ngModel)]="projectSetting.idowhCommutePM2" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="idowhCommutePM2Input" #idowhCommutePM2Input="ngModel"
                              (input)="onIdowhCommutePM2InputChanged($event);"
                              >

                           <label for="iterationWeightPMInput" class="control-label" style="padding: 0px; margin-right:10px; margin-left: 35px;">Weighting</label>
                           <input required [(ngModel)]="projectSetting.iterationWeightPM" type="number"
                              pattern="^(\d{0,2})(\.[0-9]{1})?$" class="form-control cs-form-control"
                              style="width: 50px" name="iterationWeightPMInput" #iterationWeightPMInput="ngModel"
                              (input)="onIterationWeightPMInputChanged($event);"
                              >
                           
                     </div>
                     <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:50px">
                        <span *ngIf="idowhCommutePM1Input.touched && idowhCommutePM1Input.errors">
                           <label *ngIf="idowhCommutePM1Input.errors.required">Please insert a Fixed by iteration value</label>
                           <label *ngIf="idowhCommutePM1Input.errors.pattern">Please insert a Fixed by iteration value that is postive and 1 decimal place</label>
                        </span>
                        <span *ngIf="idowhCommutePM2Input.touched && idowhCommutePM2Input.errors">
                           <label *ngIf="idowhCommutePM2Input.errors.required">Please insert Varying by iteration  value</label>
                           <label *ngIf="idowhCommutePM2Input.errors.pattern">Please insert a Varying by iteration value that is postive and 1 decimal place</label>
                        </span>
                        <span *ngIf="iterationWeightPMInput.touched && iterationWeightPMInput.errors">
                           <label *ngIf="iterationWeightPMInput.errors.required">Please insert Weighting value</label>
                           <label *ngIf="iterationWeightPMInput.errors.pattern">Please insert a Weighting value that is postive and 1 decimal place</label>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

            <!--<div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Utilisation definition</h6>
                  <p>The boundaries between under, fully and over utilised.</p>
                  <div class="form-group">
                     <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:50px; width:500px; padding-top:10px;">
                        <callsmart-range-slider name="utilisationDefinition" #utilisationDefinition="ngModel" [boundMin]="boundsMin" [boundMax]="boundsMax"
                                                [usePercentageFormatter]="true"
                                                [max]="projectSetting.overUtilisation" [min]="projectSetting.underUtilisation"
                                                [(ngModel)]="projectSetting.utilisationDefinition"
                                                [showUtilisationLabels]="true"
                                                (userValueChanged)="onCheckUtilisationDefinition($event)">
                        </callsmart-range-slider>
                        <label *ngIf="!isUtilisationDefinitionValid"
                               style="margin-left:10px; margin-top:10px;">The From Utilisation value must be less than the To Utilisation value</label>
                        <label *ngIf="!isFromUtilisationValueValid"
                               style="margin-left:10px; margin-top:10px;">From Utilisation value must be between 0-149</label>
                        <label *ngIf="!isToUtilisationValueValid"
                               style="margin-left:10px; margin-top:10px;">From Utilisation value must be between 2-150</label>
                     </div>
                  </div>
               </div>
            </div> -->

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Distance units</h6>
                  <p>The units of distance CallSmart uses.</p>
                  <div class="form-group col-sm-8">
                     <callsmart-inputswitch name="distanceUnitMiles" #distanceUnitMiles="ngModel" [(ngModel)]="projectSetting.distanceUnitMiles" onLabel="Miles" offLabel="Kilometres"
                        inverseColours="true" (onChange)="onDistanceUnitInputSwitchChanged($event)">
                     </callsmart-inputswitch>
                  </div>
               </div>
            </div>


            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Average vehicle CO
                     <sub>2</sub> emissions</h6>
                  <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:15px">
                        <!-- The currencySymbol pipe is returning escaped HTML so we have to use the [innerHTML] property to render it properly-->
                     <input [(ngModel)]="projectSetting.averageVehicleEmission"
                              type="number" required
                              pattern="^\d{1,3}$" class="form-control cs-form-control"
                              style="width: 80px" name="averageVehicleEmissionInput" #averageVehicleEmissionInput="ngModel"
                              (input)="onAverageVehicleEmissionInputValueChanged($event)"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                     <label class="control-label cs-label-right" for="averageUnitPerGallon"
                            style="padding: 0px; margin-left:10px">g/km</label>
                  </div>
                  <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:15px">
                     <span *ngIf="averageVehicleEmissionInput.touched && averageVehicleEmissionInput.errors">
                        <label *ngIf="averageVehicleEmissionInput.errors.required">Please insert average vehicle CO<sub>2</sub> emissions</label>
                        <label *ngIf="averageVehicleEmissionInput.errors.pattern">Please insert a value lower than 1000</label>
                     </span>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Diary view</h6>
                  <p>Number of minutes visible either side of the contracted working hours.</p>
                  <div style="display: flex; flex-direction: row; align-items: center; justify-content: left;margin-left:15px">
                     <!-- The currencySymbol pipe is returning escaped HTML so we have to use the [innerHTML] property to render it properly-->
                     <input [(ngModel)]="projectSetting.diaryViewTime"
                              type="number" required
                              pattern="^([6-8][0-9]|9[0-9]|1[0-9]{2}|2[0-3][0-9]|240)$" class="form-control cs-form-control"
                              style="width: 80px" name="diaryViewTimeInput" #diaryViewTimeInput="ngModel"
                              (input)="onDiaryViewTimeInputValueChanged($event)"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                     <label class="control-label cs-label-right" for="averageUnitPerGallon"
                            style="padding: 0px; margin-left:10px">minutes</label>
                  </div>
                  <div style="display: flex;flex-direction: column;justify-content: flex-start;margin-top:10px; margin-left:15px">
                     <span *ngIf="diaryViewTimeInput.touched && diaryViewTimeInput.errors">
                        <label *ngIf="diaryViewTimeInput.errors.required">Please insert diary view time</label>
                        <label *ngIf="diaryViewTimeInput.errors.pattern">Please insert a value between 60 and 240</label>
                     </span>
                  </div>
               </div>
            </div>

            <div class="cs-setting-panel">
               <div class="panel-body">
                  <h6>Travel model</h6>
                  <p>Which travel model file the optimiser will use.</p>
                  <div class="form-group">
                     <div class="col-sm-11">
                        <callsmart-dropdown name="travelModelFile"  [options]="travelModelList" [(ngModel)]="selectedTravelModel" [style]="{'width':'60%'}"
                           [disabled]="disableTravelModel" (onChange)="onTravelModelChanged()" >
                        </callsmart-dropdown>
                     </div>
                  </div>
               </div>
            </div>

         </perfect-scrollbar>
      </div>
   </div>
   </form>
</div>

<ndc-dynamic *ngIf="showNewFolderDialog"
   [ndcDynamicComponent]="newFolderDialog"
   [ndcDynamicInputs]="dialogInput"
   [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
