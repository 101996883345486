<p-dialog header="Import" 
   [(visible)]="display" 
   modal="true"
   width="400" 
   positionTop="200" 
   [draggable]="false" 
   [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal ed-form-horizontal">

      <div style="margin-top:5px;margin-bottom:5px;">
         Select the data you would like to import
      </div>

      <div class="panel" style="border:none;">
         <div style="margin-top: 10px;">

            <div class="row">
               <div [ngClass]="{'form-group':true, 'ed-form-group':true, 'export-selected': importOptions.importCallers }">
                  <div class="col-sm-10">
                     <label>
                        <p-checkbox [(ngModel)]="importOptions.importCallers"  binary="true" name="importCallers" label=""></p-checkbox>
                        <i class="cs-icon icon-users export-filter-text" style="margin-left: 10px;"></i>Callers</label>
                  </div>
               </div>
            </div>

            <div class="row ">
               <div [ngClass]="{'form-group':true, 'ed-form-group':true, 'export-selected': importOptions.importCallpoints }">
                  <div class="col-sm-10">
                     <label>
                        <p-checkbox [(ngModel)]="importOptions.importCallpoints" binary="true" name="importCallpoints" label=""></p-checkbox>
                        <i class="cs-icon icon-placeholders export-filter-text" style="margin-left: 10px;"></i>Callpoints</label>
                  </div>
               </div>
            </div>

         </div>
      </div>

      <p-footer>
         <hr>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onImport(form)" [disabled] = "(!importOptions.importCallers && !importOptions.importCallpoints)">Import</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>
</p-dialog>
