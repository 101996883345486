// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// PrimeNG Imports
import { ButtonModule } from 'primeng/components/button/button';
import { StepsModule } from 'primeng/components/steps/steps';

// This Module's Components
import { StepComponent } from 'app/shared/wizard/step/step.component';
import { WizardComponent } from 'app/shared/wizard/wizard.component';

@NgModule({
   imports: [
      CommonModule, 
      ButtonModule, 
      StepsModule
   ],
   declarations: [
      StepComponent,
      WizardComponent,
   ],
   exports: [
      StepComponent,
      WizardComponent,
   ]
})
export class WizardModule {

}
