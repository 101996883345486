import {
   Component,
   Input,
   EventEmitter,
   Output,
   OnInit,
   OnDestroy,
   ViewChild,
   ElementRef,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { ApplicationStore } from 'app/stores/application-store';
import { Callpoint } from 'app/models/callpoint';
import { Project } from 'app/models/project';
import { DataTable, SelectItem, LazyLoadEvent } from 'primeng/primeng';

import {
   generateUniqueItems,
   contains,
   compareValues,
   filter,
   compareFullyScheduled,
   getComboDataForSpecifiedProperty,
} from 'app/shared/utils/callsmart-grid-util';
import { LockingTypes } from 'app/models/diary/locking-Types';

@Component({
   selector: 'callsmart-edit-callpoints-dialog',
   templateUrl: './edit-callpoints-dialog.component.html',
})
export class EditCallpointsDialogComponent implements OnInit, OnDestroy {
   private _subscriptions: Subscription = new Subscription();

   @ViewChild(DataTable)
   public callpointList: DataTable;

   private projectId: number = 0;

   // data grid variables
   private _filteredResults: Callpoint[];
   private _sortedAndFilteredData: Callpoint[];
   private _currentSortField: string;
   private _currentSortOrder: string;
   private _filterNum: number;
   private _allCallpointsForCaller: Callpoint[] = [];
   private _filterHistory;
   private _selectionHistory: number[] = [];

   //Combo Data typically distinct on the column data held in a dictionay in the case of type script a map object
   public comboFilterDataMap: Map<string, any[]> = new Map<string, any[]>();
   public filterSelectedValues = [];
   public filterSelectedMultiValues = [];
   public hasFilters: boolean;

   public totalRecords: number = 0;
   public rows: number = 30;
   public callpoints: Callpoint[] = [];

   public allCols: any[];
   public columnOptions: SelectItem[];
   public selectedItemsLabel: string = 'Columns';
   public displaySelectedLabel: boolean = true;
   public maxSelectedLabels: number = 0;
   public defaultLabel: string = 'Columns';
   public panelTitle: string = 'Columns';
   public gridTitle: string = 'Callpoints';

   public rowUnSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   public rowSelected: EventEmitter<any> = new EventEmitter<any>(); // Must be output to allow other components to bind to it.
   public rowMultiSelected: EventEmitter<any> = new EventEmitter<any>();
   public allRowsSelected: EventEmitter<any> = new EventEmitter<any>();
   public columnChanged: EventEmitter<any> = new EventEmitter<any>();

   public headerHeight: number;
   public tableHeaderHeight: number;
   public tableHeight: number;

   public loading: boolean = true;
   public loadingIcon = 'fa fa-spinner';
   public loadingText = 'Loading data';
   private _scrollHeight = '400px';

   @ViewChild('gridWrapper') elementView: ElementRef;

   public get scrollHeight(): string {
      return this._scrollHeight;
   }
   public set scrollHeight(scrollHeight: string) {
      this._scrollHeight = scrollHeight;
      let scrollHeightStringValue: string = this._scrollHeight.substr(
         0,
         this._scrollHeight.length - 2
      );

      let scrollHeightValue: number;
      scrollHeightValue = Number(scrollHeightStringValue);

      this.filterColumnPanelHeight = Number(scrollHeightValue) - 72 + 'px'; // 116px is the height of the header of the filter column panel;
   }
   public filterColumnPanelHeight: string;

   scrollWidth: string = '200px';

   // Gets and sets the selected callpoints.
   private previousCheckboxSelection: any[] = [];
   private _selectedCallPoints: Callpoint[] = [];
   get selectedCallPoints(): Callpoint[] {
      return this._selectedCallPoints;
   }
   set selectedCallPoints(points: Callpoint[]) {
      if (this.callpointList) {
         // has all been selected
         if (this.callpointList.allSelected && !this.hasFilters) {
            this._selectedCallPoints = this._allCallpointsForCaller;
         } else if (this.callpointList.allSelected && this.hasFilters){
            this._selectedCallPoints = this._filteredResults;
         }
         else {
            this._selectedCallPoints = points;
         }

         // ask julian what this is for
         this.previousCheckboxSelection.forEach((item) => {
            if (!contains(this.selectedCallPoints, item)) {
               this.selectedCallPoints.push(item);
            }
         });

         // clear previous selection
         this.previousCheckboxSelection = [];
      }
   }

   public cols: any[];

   @Input() public title: string = "Edit Multiple Callers' Callpoints";
   @Input() display: boolean = false;
   @Input() callerIds: number[] = [];

   @Output() cancel = new EventEmitter<void>();
   @Output() saved = new EventEmitter<void>();

   public constructor(private _applicationStore: ApplicationStore) {}

   ngOnInit(): void {
      this._selectedCallPoints = [];
      this._filterHistory = null;

      this.scrollHeight =
         this.elementView.nativeElement.offsetHeight - 100 + 'px';

      this.loadingText = 'Loading data';

      this.subscribeToSelectedProject();

      this.configureTableColumns();

      this.subscribeToCallpointsForMultipleCallers();

      this.loading = true;

      this._applicationStore.callpointsStore.loadCallpointsForMultipleCallers(
         this.projectId,
         this.callerIds
      );
   }

   ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   public onSave() {
      this.display = false;
      this.saved.next();
   }

   public lockToDay() {
      if (this.selectedCallPoints.length > 0) {
         this.lockCallpoints(LockingTypes.day);
      }
   }

   public lockToDayTime() {
      if (this.selectedCallPoints.length > 0) {
         this.lockCallpoints(LockingTypes.dayAndTime);
      }
   }

   public unlock(): void {
      if (this.selectedCallPoints.length > 0) {
         this.lockCallpoints(LockingTypes.none);
      }
   }

   // the lazy loading is typically used handle large datasets by going to the server and retrieving paged data.
   // this can be done for virtual scroll or paginated tables.
   // in our case we have all the callpoints in memory, so we are not going to the server but splicing the array to only
   // return small numbers of rows, to speed up the grid.
   // as we are using lazy loading we need to hand roll our own sorting and filtering.
   // typically we would do this on the server with custom SQL commands
   public loadDataLazy(event: LazyLoadEvent) {
      if (this.callerIds) {
         let order = event.sortOrder > 0 ? 'asc' : 'desc';

         // only re-order or sort if the event values have changed
         if (
            this._currentSortField != event.sortField ||
            this._currentSortOrder != order
         ) {
            this._sortedAndFilteredData = this._allCallpointsForCaller.slice();

            if (event.sortField == 'fullyScheduled') {
               let xxxxx = compareFullyScheduled(
                  order,
                  this._sortedAndFilteredData
               );
               let yyyyy = Array.prototype.concat.apply([], xxxxx);
               this._sortedAndFilteredData = yyyyy;
            } else {
               this._sortedAndFilteredData.sort(
                  compareValues(event.sortField, order)
               );
            }

            this._currentSortField = event.sortField;
            this._currentSortOrder = order;
         }

         if (Object.keys(event.filters).length > 0) {
            let filteredResults = filter(
               this._sortedAndFilteredData,
               event.filters,
               this.callpointList,
               this.allCols,
               this.totalRecords
            );

            this._filteredResults = filteredResults;

            this._filterNum = filteredResults.length;
            if (filteredResults.length > 0) {
               this.callpoints = filteredResults.slice(
                  event.first,
                  event.first + event.rows
               );
               this.totalRecords = filteredResults.length;
            } else {
               this.callpoints = filteredResults;
               this.totalRecords = filteredResults.length;
            }
         } else {
            this.callpoints = this._sortedAndFilteredData.slice(
               event.first,
               event.first + event.rows
            );
            this.totalRecords = this._sortedAndFilteredData.length;
         }
      }
   }

   public onRowSelect(event) {
      // the checkbox selection doesnt seem to be adding the data to the selectedarray
      if (event.type == 'checkbox') {
         this.selectedCallPoints.push(event.data);
      }

      this.rowSelected.emit(event); // fire event
   }

   public onRowUnselect(event) {
      this.rowUnSelected.emit(event); // fire event
   }

   public onFilterReset() {
      // clear all the combo boxes as prime ng does not clearthe custom fiters real pain
      for (let i = 0; i < this.filterSelectedValues.length; i++) {
         this.filterSelectedValues[i] = 'All';
      }

      for (let i = 0; i < this.filterSelectedMultiValues.length; i++) {
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues[i] = selecteditems;
      }

      this.callpointList.reset();

      // have to clear all the custom filters
      this.cols.forEach((col) => {
         if (col.hasCombo || col.hasMulti) {
            this.callpointList.filter(null, col.field, col.filterMatchMode);
         }
      });

      // this keeps the orgingal sort
      this.callpointList.updateDataToRender(this.callpoints);
      this.hasFilters = false;
      this.gridTitle = 'Callpoints';
   }

   public onFilter(event) {
      if (Object.keys(this.callpointList.filters).length > 0) {
         this.gridTitle =
            'Callpoints (Showing ' +
            this._filterNum +
            ' of ' +
            this._allCallpointsForCaller.length +
            ' total)';
         this.hasFilters = true;
      } else {
         this.gridTitle = 'Callpoints';
         this.hasFilters = false;
      }
   }

   public trackByFn(index, item) {
      return item.guid; // index or item.id
   }

   public onHeaderCheckboxToggle(event) {
      this.allRowsSelected.emit(event);
   }

   public onColResize(event) {
      let x = event;
   }

   private subscribeToCallpointsForMultipleCallers() {
      this._subscriptions.add(
         this._applicationStore.callpointsStore.callpointsForMultipleCallers$.subscribe(
            (callpoints: Callpoint[]) => {
               this.totalRecords = callpoints.length;
               this._allCallpointsForCaller = callpoints;
               this._sortedAndFilteredData = this._allCallpointsForCaller.slice();

               setTimeout(() => this.delayDataBinding(callpoints), 30);

               this.loading = false;
            }
         )
      );
   }

   // Javascript is single threaded.
   // when dealing with large sets of callpoints delay the data binding
   // this allows the map to partially render and give the illusion of the page loading faster
   private delayDataBinding(callpoints: Callpoint[]) {
      this.buildFirstPage(callpoints);

      /*this.comboFilterDataMap.set(
         'frequency',
         getComboDataForSpecifiedProperty(
            callpoints,
            callpoint => callpoint.frequency,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'duration',
         getComboDataForSpecifiedProperty(
            callpoints,
            callpoint => callpoint.duration,
            true,
            'asc'
         )
      );*/

      this.comboFilterDataMap.set(
         'fullyScheduled',
         getComboDataForSpecifiedProperty(
            callpoints,
            (callpoint) => callpoint.fullyScheduled,
            false,
            ''
         )
      );

      this.comboFilterDataMap.set(
         'lockedState',
         getComboDataForSpecifiedProperty(
            callpoints,
            (callpoint) => callpoint.lockedState,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'callerTerritory',
         getComboDataForSpecifiedProperty(
            callpoints,
            (callpoint) => callpoint.callerTerritory,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'grade',
         getComboDataForSpecifiedProperty(
            callpoints,
            (callpoint) => callpoint.grade,
            true,
            'asc'
         )
      );

      this.comboFilterDataMap.set(
         'priority',
         getComboDataForSpecifiedProperty(
            callpoints,
            (callpoint) => callpoint.priority,
            true,
            'asc'
         )
      );

      this.applyFilterState();
      this.applySelectionState();
      this.scrollGridToTop();
   }

   private applyFilterState() {
      if (this._filterHistory) {
         this.callpointList.filters = this._filterHistory;
         Object.keys(this._filterHistory).forEach((key) => {
            let filter = this._filterHistory[key];
            this.callpointList.filter(
               filter.value,
               filter.field,
               filter.filterMatchMode
            );
         });
      }
   }

   private applySelectionState() {
      if (this._selectionHistory && this._selectionHistory.length > 0) {
         this._selectedCallPoints = this._allCallpointsForCaller.filter(
            (point) => this._selectionHistory.includes(point.callpointId)
         );
      }
   }

   private scrollGridToTop() {
      const body = this.callpointList.el.nativeElement.getElementsByClassName(
         'ui-datatable-scrollable-body'
      )[0];
      body.scrollTop = 0;
   }

   private buildFirstPage(callpoints: Callpoint[]) {
      this.totalRecords = callpoints.length;
      this.callpointList.reset();
      this.callpointList.updateTotalRecords();

      this.callpoints = callpoints.slice(0, this.rows);
   }

   private subscribeToSelectedProject() {
      this._subscriptions.add(
         this._applicationStore.projectsStore.selectedProject$.subscribe(
            (project: Project) => {
               this.projectId = project.projectId;
            }
         )
      );
   }

   // when configuring the columns the defualt columns must match the all columns exactly with all the same properties and values
   // if this is not done,  when the columns are used in the multi select they will not show as selected.
   // do not have both hasCombo: true and , hasMulti: true these are mutually exclusive
   // when setting a combo the filtermatch mode is 'equals'
   // when setting a multi select the filtermatch mode is 'in'
   // if you are using multi select or combo ensure you have written a function to build out the data they should use
   private configureTableColumns() {
      this.allCols = [
         {
            field: 'reference',
            header: 'Key',
            disabled: true,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
         },

         {
            field: 'name',
            header: 'Name',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
         },

         {
            field: 'lockedState',
            header: 'Locked State',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
         },
         /*{
            field: 'frequency',
            header: 'Frequency',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },
         {
            field: 'duration',
            header: 'Duration',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true
         },*/
         {
            field: 'fullyScheduled',
            header: 'Scheduled Status',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
         },
         {
            field: 'callerTerritory',
            header: 'Caller Key',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
         },
         {
            field: 'salesValue',
            header: 'Value',
            disabled: false,
            filter: true,
            filterPlaceholder: 'contains',
            filterMatchMode: 'contains',
            hasCombo: false,
            hasMulti: false,
         },
         {
            field: 'grade',
            header: 'Category',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
         },
         {
            field: 'priority',
            header: 'Priority',
            disabled: false,
            filter: true,
            filterPlaceholder: 'in',
            filterMatchMode: 'in',
            hasCombo: false,
            hasMulti: true,
         },
      ];

      this.comboFilterDataMap = new Map<string, any[]>();

      this.columnOptions = [];
      for (let i = 0; i < this.allCols.length; i++) {
         this.columnOptions.push({
            label: this.allCols[i].header,
            value: this.allCols[i],
         });
      }

      // combos and multi select boxes are considered custom filters these do not get cleared with a table reset.
      // the grid data will reset but any values selected in the combo will stay.
      // to get round this we bind the ngmodel for the combo to the filterSelectedValues array, we reset this back to the defualt value of
      // all to reset the combos
      for (let i = 0; i < this.allCols.length; i++) {
         this.filterSelectedValues.push('All');
         let selecteditems: SelectItem[] = [];
         this.filterSelectedMultiValues.push(selecteditems);
      }

      this.cols = this.allCols;
   }

   private lockCallpoints(lockingType: LockingTypes) {
      // save the current filter & selection
      this._filterHistory = this.callpointList.filters;
      this._selectionHistory = this.selectedCallPoints.map(
         (p) => p.callpointId
      );

      this.loadingText = 'Applying changes';

      // refresh grid withs saved data
      this.loading = true;

      // apply the lock to selected callpoints
      this._applicationStore.callpointsStore.updateCallpointsLockedStateForMultipleCallersAndRefreshData(
         this.projectId,
         this.callerIds,
         this.selectedCallPoints.map((p) => p.reference),
         lockingType
      );
   }
}
