<p-dialog 
    id="routeInfillDialog" 
    header="Unable to calculate all routes" 
    [(visible)]="display" 
    modal="true" 
    width="700"
    [draggable]="false" 
    [resizable]="false" 
    (onHide)="onCancel()">

    <!-- <form #form="ngForm" class="form-horizontal" autocomplete="off"> -->

        <p>The following {{routeInfillData.length}} point(s) cannot have routes automatically calculated:</p>

        <perfect-scrollbar [ngStyle]="{'height.px': 300}" style="border: 1px solid #393939;">
            <div class="panel" style="border:none;">
               <div style="margin-top: 10px;">
                  
                  <div class="row">
                     <div class="form-group row">
                        <div class="col-sm-12" style="padding-left: 0px">                    
                           <ul style="width:100%">
                                <li *ngFor="let item of routeInfillData">
                                    <span class="regular-text">
                                    {{item}}
                                    </span>
                                </li>
                            </ul>
                            <!-- <div *ngFor="let item of routeInfillData" class="regular-text" style="padding-left: 10px;">
                            {{item}}
                            </div> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </perfect-scrollbar>

        <div *ngIf="displayCancelButton" style="padding-top: 15px;">Would you like to use in-filled routing to calculate estimated distance / time for these items?</div>

        <p-footer>
            <div style="padding-top: 10px; margin-bottom:30px; float: right; margin-right: 30px;">
                <button type="button" class="btn cs-btn-primary-inverse pull-right" (click)="onContinue()">Use in-filled routing</button>
                <button *ngIf="displayCancelButton" type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel create</button>
            </div>
        </p-footer>

    <!-- </form> -->
</p-dialog>