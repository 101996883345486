import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from 'app/shared/date-converter/date-converter';
import { CompanyTravelModel } from 'app/models/companyTravelModel';

@JsonObject
export class Company {

   @JsonProperty('companyId')
   companyId: number = undefined;

   @JsonProperty('companyName')
   companyName: string = undefined;

   @JsonProperty('concurrentUsers')
   concurrentUsers: number = undefined;

   @JsonProperty('licenseExpiryDate', DateConverter, true)
   licenseExpiryDate: Date = undefined;

   @JsonProperty('travelModels', [CompanyTravelModel], true)
   public travelModels: CompanyTravelModel[] = undefined;

   @JsonProperty('trialLicense')
   trialLicense: boolean = undefined;

   @JsonProperty('trialLicenseCallerLimit')
   trialLicenseCallerLimit: number = undefined;

}
