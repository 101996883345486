import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment/moment';

import { Event } from 'app/models/diary/event';
import { EventTypes } from 'app/models/diary/event-types';
import { ApplicationStore } from 'app/stores/application-store';
import { CallerSettingsViewModel } from 'app/models/view-models/caller-settings-view';
import { Alert } from 'app/models/alert';
import { SwapDaysDialogComponent } from 'app/shared/swap-days-dialog/swap-days-dialog.component';


// This class works out the contextual panel info to be display for the selected caller and the selected day.
@Component({
   selector: 'callsmart-caller-day-summary',
   templateUrl: './caller-day-summary.component.html'
})
export class CallerDaySummaryComponent implements OnInit, OnDestroy {

   public totalVisits: number;
   public totalVisitTime: number;
   public totalDriveTime: number;
   public freeWorkingTime: number;
   public dayTitle: string;
   public weekNumberTitle: string;
   public weekPeriodTitle: string;
   public isDayClosed: boolean;
   public isDayHalfClosed: boolean;

   // Determine whether to display the swap days dialog.
   public showSwapDaysDialog: boolean = false;

   // Tell the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public swapDaysDialog = SwapDaysDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public dialogInput = {
      display: false,
      fromDate: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public dialogOutput = {
      saved: () => this.onSaveSwapDays(),
      cancel: () => this.onCancelSwapDays()
   };

   private days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

   // Gets and sets the start cycle date.
   @Input() startCycleDate: Date;

   @Input() callpointId: string;

   // Gets and sets the selected event collection.
   private _eventCollection: Event[];
   @Input()
   get eventCollection(): Event[] {
      return this._eventCollection;
   }
   set eventCollection(events: Event[]) {
      this._eventCollection = events;
      this.setDaySummaryInfo();
   }

   // Gets and sets the selected date.
   private _selectedDate: Date = null;
   @Input()
   get selectedDate(): Date {
      return this._selectedDate;
   }
   set selectedDate(date: Date) {
      this._selectedDate = date;
      if (this._selectedDate) {
         this.setDayTitle(this._selectedDate);
         this.setWeekTitle(this._selectedDate);
         this.determineDayClosed();
         this.determineHalfDayClosed();
      }
      else {
         this.clearDateTitle();
      }
   }

   private _selectedContractedWorkingTime: Date[];
   @Input()
   get selectedContractedWorkingTime(): Date[]{
      return this._selectedContractedWorkingTime;
   }
   set selectedContractedWorkingTime(contractedWorkingTime: Date[]){
      this._selectedContractedWorkingTime = contractedWorkingTime;
      this.getFreeWorkingTime();
   }

   constructor(private _applicationStore: ApplicationStore) { }

   ngOnInit() {
      //this.subscribeToSelectedCaller();
   }

   ngOnDestroy() {
      //if (this._selectedCallerSubscription) {
      //   this._selectedCallerSubscription.unsubscribe();
      //}
   }

   // Optimising the day will use Fast optimisation.
   public optimiseDay(): void {
      if(this.allowedToOptimise()){
         this._applicationStore.scheduleStore.generateScheduleForDay(this._applicationStore.callersStore.selectedCaller.callerId, 'Quick', this.getIndexDayInCycle());
      }
      else {
         this._applicationStore.alertStore.sendAlert(new Alert('Error', 'The number of Visits on this Day exceeds the Maximum visits allowed per day property.'));
      }
   }

   public swapDays(): void {
      this.dialogInput.fromDate = this._selectedDate;
      this.showSwapDaysDialog = true;
      this.dialogInput.display = true;
   }

   public onSaveSwapDays(): void {
      this.showSwapDaysDialog = false;
      this.dialogInput.display = false;
   }

   public onCancelSwapDays(): void {
      this.showSwapDaysDialog = false;
      this.dialogInput.display = false;
   }

   public removeLunch(): void {
      this._applicationStore.scheduleStore.removeLunchFromSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
                                                       this.getIndexDayInCycle(),
                                                       this.selectedDate,
                                                       this._applicationStore.uiStore.getActiveView())
   }

   public addLunch(): void {
      this._applicationStore.scheduleStore.addLunchToSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
                                                        this.getIndexDayInCycle(),
                                                        this.selectedDate,
                                                        this._applicationStore.uiStore.getActiveView());
   }

   public enableLunchOption(): boolean {
      let lunchEvent = this.eventCollection.find(e => e.eventType == EventTypes.lunch);
      return lunchEvent !== undefined ? true : false;
   }

   public addOvernight(): void {
      this._applicationStore.scheduleStore.addOvernightToSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
                                                        this.getIndexDayInCycle(),
                                                        this.selectedDate,
                                                        this._applicationStore.uiStore.getActiveView());
   }

   public removeOvernight(): void {
      this._applicationStore.scheduleStore.removeOvernightFromSchedule(this._applicationStore.callersStore.selectedCaller.callerId,
                                                        this.getIndexDayInCycle(),
                                                        this.selectedDate,
                                                        this._applicationStore.uiStore.getActiveView());
   }

   public enableOvernightOption(): boolean {
      let overnightEvent = this.eventCollection.find(e => e.eventType == EventTypes.overnight);
      return overnightEvent !== undefined ? true : false;
   }

   private allowedToOptimise(): boolean {
      // Validate max visits per day. User is not allowed to optimise day if the total visits exceed max number visits allowed
      // in the caller settings.
      let model: CallerSettingsViewModel = new CallerSettingsViewModel(
         this._applicationStore.projectsStore.selectedProject.callerSettings,
         this._applicationStore.callersStore.selectedCaller.callerSettings, this._applicationStore.callersStore.selectedCaller);

      return this.totalVisits <= model.maxVisitsPerDay;

   }

   private setDaySummaryInfo() {
      if (this.eventCollection) {
         this.getTotalVisits();
         this.getTotalVisitTime();
         this.getTotalDriveTime();
         this.getFreeWorkingTime();
      }
   }

   // Gets the total visits in a day for a caller.
   private getTotalVisits() {
      if (this.eventCollection) {
         this.totalVisits = this.eventCollection.filter(item => item.eventType === EventTypes.visit).length;
      }
      else {
         this.totalVisits = 0;
      }
   }

   // Gets the visit time in a day for a caller.
   private getTotalVisitTime() {
      if (this.eventCollection) {
         let visits: Event[] = this.eventCollection.filter(item => item.eventType === EventTypes.visit);
         let totalCallTime: number = 0;
         this.totalVisitTime = this.getEventHours(visits);
      }
      else {
         this.totalVisitTime = 0;
      }
   }

   // Gets the drive time (drive time + commuting) in a day for a caller.
   private getTotalDriveTime() {
      if (this.eventCollection) {
         let drivetimes: Event[] = this.eventCollection.filter(item => item.eventType === EventTypes.travelTime || item.eventType === EventTypes.commute);
         let totalDriveTime: number = 0;
         this.totalDriveTime = this.getEventHours(drivetimes);
      }
      else {
         this.totalDriveTime = 0;
      }
   }

   // Gets the free time (contracted hours - (visit time + lunch time + drive time)) in a day
   private getFreeWorkingTime() {
      this.freeWorkingTime = 0;
      const ONE_HOUR = 1000 * 60 * 60;
      if (this.eventCollection && this.selectedContractedWorkingTime && this.selectedContractedWorkingTime.length === 2) {

         // Work out contracted working hours.
         let iniTime: Date = new Date(this.selectedContractedWorkingTime[0]);
         let endTime: Date = new Date(this.selectedContractedWorkingTime[1]);
         let workingHours = this.round(this.calculateMilisecondsBetweenDates(iniTime, endTime) / ONE_HOUR, 1);

         // Work out event hours.
         let eventsWithNoCommuting: Event[] = this.eventCollection.filter(item => item.eventType === EventTypes.visit ||
                                                                              item.eventType === EventTypes.travelTime ||
                                                                              item.eventType === EventTypes.lunch ||
                                                                              item.eventType === EventTypes.project);
         let eventWithNoCommunitngHours = this.getEventHours(eventsWithNoCommuting);

         this.freeWorkingTime = this.round(workingHours - eventWithNoCommunitngHours, 1);
      }
      else {
         this.freeWorkingTime = 0;
      }
   }

   // Sets the day title (Monday 25).
   private setDayTitle(date: any) {
      let dayOfMonth = date.getDate();
      let dayName = this.days[date.getDay()];
      this.dayTitle = dayName + ' ' + dayOfMonth;
   }

   // Sets the week title (Week 2: 15 - 21 Aug 2017).
   private setWeekTitle(date: Date) {
      let weekNumber = this.calculateWeeksBetweenDates(this.selectedDate, this.startCycleDate);
      this.weekNumberTitle = 'Week ' + (weekNumber + 1) + ' | ';
      this.weekPeriodTitle = this.getWeekPeriod(date);
   }

   // Clears the Date title.
   private clearDateTitle() {
      this.dayTitle = '';
      this.weekNumberTitle = '';
      this.weekPeriodTitle = '';
   }

   // Gets the total hours having a collection of events.
   private getEventHours(events: Event[]): number {
      const ONE_HOUR = 1000 * 60 * 60;
      // Accumulates the miliseconds by event and then round them in order to not to loose precision.
      let totalMiliseconds: number = 0;
      events.forEach(event => {
         totalMiliseconds = totalMiliseconds + this.calculateMilisecondsBetweenDates(event.start, event.end);
      });
      // Converting to hours and rounding with 1 decimal position.
      let totalHours = this.round(totalMiliseconds / ONE_HOUR, 1);
      return totalHours;
   }

   // Calculates the number of weeks between 2 dates.
   private calculateWeeksBetweenDates(date1: Date, date2: Date): number {
      // The number of milliseconds in one week
      const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
      // Convert both dates to milliseconds
      let date1_ms = date1.getTime();
      let date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      let difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to weeks and return hole weeks
      return Math.floor(difference_ms / ONE_WEEK);
   }

   // Calculates the number of miliseconds between 2 dates
   private calculateMilisecondsBetweenDates(date1, date2): number {
      // Convert both dates to milliseconds
      let date1_ms = date1.getTime();
      let date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      return Math.abs(date1_ms - date2_ms);
   }

   // Rounds a number with the specifying the number of decimal positions.
   private round(value, decimals): number {
      return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
   }

   // Gets the week period where the date passed as a parameter is in
   private getWeekPeriod(date: Date): string {
      // Using MomentJs objects since they are very powerful handling datetime calculations
      let momentDate: moment.Moment = moment(date.getFullYear()
         + '-' + (date.getMonth() + 1)
         + '-' + date.getDate(), 'YYYY-MM-DD');

      let startOfWeek: moment.Moment = momentDate.clone().startOf('isoWeek');
      let endOfWeek: moment.Moment = momentDate.clone().endOf('isoWeek').subtract(2, 'd');
      // start and end week text
      let startOfWeekMonth: string = startOfWeek.format('MMM');
      let endOfWeekMonth: string = endOfWeek.format('MMM');
      let showStartofWeekMonth: boolean = startOfWeekMonth !== endOfWeekMonth;
      // start and end year text
      let startOfWeekYear: number = startOfWeek.year();
      let endOfWeekYear: number = endOfWeek.year();
      let showStartofWeekYear: boolean = startOfWeekYear !== endOfWeekYear;
      //  Joining the week and year text worked out previously
      let startOfWeekstr = startOfWeek.date() + ' ' + (showStartofWeekMonth ? startOfWeekMonth : '') + ' ' + (showStartofWeekYear ? startOfWeekYear : '');
      let endOfWeekstr = endOfWeek.date() + ' ' + endOfWeekMonth + ' ' + endOfWeekYear;
      return startOfWeekstr + ' - ' + endOfWeekstr;
   }

   private getIndexDayInCycle(): number {
      let iniCycleDate: moment.Moment = moment(this.startCycleDate).startOf('day');
      let selectedVisitDay: moment.Moment = moment(this._selectedDate).startOf('day');
      let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');
      return dayIndex;
   }

   private determineDayClosed() {
      let indexInCycle = this.getIndexDayInCycle();
      let datesClosed = this._applicationStore.callersStore.selectedCaller.datesClosed;

      if (indexInCycle >= 0 && datesClosed[indexInCycle].toLowerCase() == 'x') {
         this.isDayClosed = true;
      }
      else {
         this.isDayClosed = false;
      }
   }

   private determineHalfDayClosed() {
      let indexInCycle = this.getIndexDayInCycle();
      let datesClosed = this._applicationStore.callersStore.selectedCaller.datesClosed;
      this.isDayHalfClosed = indexInCycle >= 0 && (datesClosed[indexInCycle].toLowerCase() == '1' || datesClosed[indexInCycle].toLowerCase() == '2');
   }

}
