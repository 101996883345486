import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { WizardModel } from './wizard-model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
   moduleId: module.id,
   selector: 'wizard-test',
   templateUrl: 'wizard-test.component.html'
})
export class WizardTestComponent {
   public activeIndex: number = 0;
   public model: WizardModel = new WizardModel();
   public finishButtonLabel: string = 'Create Project';

   constructor(private router: Router) {
   }

   // Example of change handler
   public onChange(label: any): void {
      // console.log(label);
   }

   public onFinish(): void {
      // console.log("Finish button pressed");
   }

   public onCancel(): void {
      this.router.navigate(['dashboard']);
   }

   public gotoSettings(): void {
      this.activeIndex = 0;
   }

   public gotoCallers(): void {
      this.activeIndex = 1;
   }

   public gotoCallpoints(): void {
      this.activeIndex = 2;
   }

   public gotoScheduling(): void {
      this.activeIndex = 3;
   }
}
